import { getIconByType } from "../../Components/Tree/TreeUtils";
import React from "react";
import "./ScopeManagement.scss";
import ClassNames from "classnames";

const ScopeManagementDropdownContainer = ({
  readOnly,
  isLoadingScopesData,
  withTitle,
  isNewScope,
  selectedScope,
  onClick,
  dropdownIndicator,
  placeholder,
  isDirty,
  disabled,
}) => {
  const classNameWrapper = ClassNames(`scope-management-wrap`, {
    "read-only": readOnly,
    "is-disabled": disabled,
  });

  return (
    <div className={classNameWrapper}>
      <div className={`scope-management-container`}>
        <div
          data-testid={`scope-management`}
          className={`scope-management-wrapper ${
            isLoadingScopesData ? "is-loading" : ""
          }`}
          onClick={(e) => {
            onClick(e);
          }}
        >
          {withTitle && (
            <>
              <div className={`scope-label`}>Scope</div>
              <div className={`scope-separator`} />
            </>
          )}
          <div
            className={`selected-scope-data`}
            title={
              isNewScope
                ? "New Scope"
                : !!selectedScope?.label
                ? selectedScope?.label
                : placeholder || "Select Scope"
            }
          >
            {isLoadingScopesData ? (
              <div className="dropdown-loading-indicator">
                <div className="loading-dot" />
                <div className="loading-dot" />
                <div className="loading-dot" />
              </div>
            ) : (
              <label
                className={`selected-resource-title ${
                  !selectedScope?.label ? "placeholder" : ""
                }`}
              >
                {selectedScope?.value?.type ? (
                  <i
                    className={`seem-icon ${
                      isNewScope
                        ? "seem-icon-scope"
                        : getIconByType(selectedScope?.value?.type)
                    } scope-icon`}
                  />
                ) : (
                  ""
                )}
                <span
                  className="selected-resource-title-text"
                  data-testid={`selected-scope-title`}
                >
                  {isNewScope
                    ? "New Scope"
                    : !!selectedScope?.label
                    ? `${isDirty ? "* " : ""}${selectedScope?.label}`
                    : placeholder || "Select Scope"}
                </span>
              </label>
            )}

            {!readOnly && (
              <div className={`dropdown-indicator`}>
                <i className={`seem-icon ${dropdownIndicator}`} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScopeManagementDropdownContainer;
