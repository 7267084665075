import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { findingsQueryParams } from "../FindingsUtils";

const useFindingsQueryParam = (queryParam) => {
  const location = useLocation();
  const { search } = location;
  const history = useHistory();
  const [param, setParam] = useState(null);

  const setStateByQueryParams = () => {
    const queryStringObj = queryString.parse(search);
    if (queryParam !== findingsQueryParams.GROUP_VALUE) {
      queryStringObj[queryParam]
        ? setParam(queryStringObj[queryParam])
        : setParam(null);
    } else if (queryParam === findingsQueryParams.GROUP_VALUE) {
      const groupValueProperty = Object.keys(queryStringObj).find((key) =>
        key.startsWith("groupByValue-")
      );

      groupValueProperty
        ? setParam(queryStringObj[groupValueProperty])
        : setParam(null);
    }
  };

  useEffect(() => {
    if (!!search) {
      setStateByQueryParams();
    } else if (!search && !!param) {
      setParam(null);
    }
  }, [search]);

  const clearQueryParam = (paramTypes) => {
    const queryObj = queryString.parse(search);

    for (let i = 0; i < paramTypes.length; i++) {
      switch (paramTypes[i]) {
        case findingsQueryParams.GROUP_VALUE:
          for (const key in queryObj) {
            if (key.startsWith("groupByValue-")) {
              delete queryObj[key];
            }
          }
          history.replace({ search: queryString.stringify(queryObj) });
          break;
        default:
          delete queryObj[paramTypes[i]];
          history.replace({ search: queryString.stringify(queryObj) });
      }
    }
  };

  return {
    param,
    search,
    clearQueryParam,
  };
};

export default useFindingsQueryParam;
