import CheckBox from "../../Forms/Controls/CheckBox/CheckBox";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import "./SLACustomization.scss";
import Button from "../../Forms/Controls/Button/Button";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { useMutation, useQuery } from "@apollo/client";
import { GET_SLA_PREFERENCES, UPDATE_SLA } from "./SLACustomizationAPI";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import {
  showDialog,
  showErrorDialog,
} from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { useHistory, useParams } from "react-router-dom";
import { DASHBOARD_TYPES } from "../../Dashboard/DashboardUtils";
import SLAFieldRow from "./SLAFieldRow";
import ReactTooltip from "react-tooltip";
import { GET_RMQ_PRIORITY_SETTINGS } from "../../RemediationQueues/RemediationQueueApi";
import { queuePriorities } from "../../RemediationQueues/RemediationQueuesUtils";

const SLACustomization = () => {
  const { closeModal, isOpen } = useContext(ModalContext);
  const methods = useForm({
    mode: "all",
  });
  const {
    control,
    formState: { isValid, dirtyFields },
    reset,
    getValues,
    setValue,
  } = methods;

  let { id } = useParams();
  let history = useHistory();
  const { data, loading } = useQuery(GET_SLA_PREFERENCES, {
    fetchPolicy: "network-only",
  });
  const { data: settingsData } = useQuery(GET_RMQ_PRIORITY_SETTINGS);
  const [updateSla] = useMutation(UPDATE_SLA, {
    refetchQueries: [
      "GetSlaPreferences",
      "GetSlaEnabled",
      { query: GET_RMQ_PRIORITY_SETTINGS },
    ],
  });

  const { fields } = useFieldArray({
    control,
    name: "slaPreferences",
  });

  const enabledPreferences = useWatch({
    control,
    name: `sla_group_enabled`,
  });

  useEffect(() => {
    if (!!settingsData) {
      const rmqPriority = settingsData?.settings?.edges?.[0]?.node;
      if (!!rmqPriority && rmqPriority?.value === queuePriorities.SLA) {
        setValue("prioritize-rmq-by-sla", true);
      }
    }
  }, [settingsData]);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    const preferences =
      data?.preferences?.customer_preferences?.sla_preferences?.edges?.map(
        (preference) => preference.node
      );
    const sla_group_enabled = preferences?.[0]?.group?.enabled;

    reset({
      ...getValues(),
      sla_group_enabled,
      slaPreferences: preferences,
    });
  }, [data]);

  const onSubmit = (data) => {
    setIsSubmittingForm(true);

    const formData = {
      severity: {
        enabled: data.sla_group_enabled,
        preferences: data.slaPreferences.map((preference) => {
          return {
            name: preference.preference_name,
            time_to_respond: preference.time_to_respond,
            enabled: preference.enabled,
          };
        }),
      },
    };

    const rmqShouldPrioritizeBySLA = data?.["prioritize-rmq-by-sla"];

    const vars = {};
    vars.dict_of_group_dicts = JSON.stringify(formData);
    vars.remediation_queue_priority_sla = !!rmqShouldPrioritizeBySLA;

    updateSla({ variables: vars })
      .then((res) => {
        if (!!res.data?.update_sla_settings?.ok) {
          closeModal();
          showToast({
            message: "SLA Customization Saved!",
          });

          if (id === DASHBOARD_TYPES.SLA) {
            history.push(`/dashboard/${DASHBOARD_TYPES.ORGANIZATIONAL}`);
          }

          setIsSubmittingForm(false);
        } else {
          setIsSubmittingForm(false);
          showDialog({
            title: "Error",
            type: "error",
            message: "An error occurred while trying to save SLA Preferences",
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        }
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showErrorDialog({
          message: err.message,
        });
      });
  };

  const checkGroup = (preference_name, newValue) => {
    let hasSlaEnabled = null;

    getValues()?.slaPreferences?.forEach((sla) => {
      if (sla.preference_name === preference_name) {
        if (hasSlaEnabled === null) {
          hasSlaEnabled = newValue;
        } else {
          hasSlaEnabled = hasSlaEnabled || newValue;
        }
      } else if (hasSlaEnabled === null) {
        hasSlaEnabled = sla.enabled;
      } else {
        hasSlaEnabled = hasSlaEnabled || sla.enabled;
      }
    });

    setValue("sla_group_enabled", hasSlaEnabled ? enabledPreferences : false, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const enableDisableGroup = (isGroupEnabled) => {
    getValues()?.slaPreferences?.forEach((sla, index) => {
      setValue(`slaPreferences[${index}].enabled`, isGroupEnabled, {
        shouldDirty: true,
        shouldValidate: true,
      });
    });
  };

  return (
    <div className={`sla-customization-wrapper`}>
      <div className={`sla-customization-description form-text`}>
        Define your organizational SLA per severity level. This will provide an
        SLA status for each finding in the system, allowing you to monitor your
        service level goals across the organization.
      </div>

      <FormProvider {...methods}>
        <form
          className={`sla-preferences-form  ${loading ? "is-loading" : ""}`}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {!loading ? (
            <div className={`sla-form-data`}>
              <div className={`sla-tracking-toggle-wrap`}>
                <Controller
                  name="sla_group_enabled"
                  render={({ field: { onChange, value, ref, onBlur } }) => (
                    <CheckBox
                      data-testid={"sla_group_enabled"}
                      inputRef={ref}
                      checked={value}
                      isToggle
                      label={"SLA Tracking"}
                      className={"sla-tracking-toggle"}
                      withHelp={
                        "Disabling SLA tracking will hide all mentions related to SLA in the system. This action is reversible, the decision can be changed at any given moment."
                      }
                      onChange={(val, newValue) => {
                        onChange(newValue);
                        enableDisableGroup(newValue);
                      }}
                    />
                  )}
                  defaultValue={true}
                  control={control}
                />
              </div>

              <div className={`sla-form-separator`} />

              <>
                <div className={`sla-item-row title-row`}>
                  <div
                    className={`sla-field-wrap sla-field-title sla-preference-name`}
                  >
                    Finding Severity
                  </div>
                  <div
                    className={`sla-field-wrap sla-field-title sla-preference-expected`}
                  >
                    Expected SLA (Days)
                  </div>
                  <div
                    className={`sla-field-wrap sla-field-title sla-preference-activated`}
                  >
                    Activated
                  </div>
                </div>

                {fields?.map((field, index) => (
                  <SLAFieldRow
                    {...field}
                    rowKey={field?.id}
                    control={control}
                    namePrefix={`slaPreferences[${index}]`}
                    index={index}
                    checkGroup={checkGroup}
                  />
                ))}
              </>

              <div className={`sla-form-separator`} />

              <div className={`sla-item-row title-row`}>
                <div className={"sla-field-wrap sla-field-title"}>
                  Remediation Queue
                </div>
              </div>
              <div className={`sla-item-row prioritize-rmq`}>
                <Controller
                  name="prioritize-rmq-by-sla"
                  render={({ field: { onChange, value } }) => (
                    <CheckBox
                      data-testid={"prioritize-rmq-by-sla"}
                      checked={enabledPreferences ? value : false}
                      label={
                        "Send tickets based on their SLA to the ticketing system"
                      }
                      onChange={(val, newValue) => onChange(newValue)}
                      disabled={!enabledPreferences}
                    />
                  )}
                  defaultValue={false}
                  control={control}
                />
                <i
                  className={"seem-icon seem-icon-help sla-popover-icon"}
                  data-tip
                  data-for="prioritizeTip"
                />
                <ReactTooltip
                  id="prioritizeTip"
                  place="top"
                  multiline={true}
                  className={"prioritize-tooltip"}
                >
                  The default for pushing tickets is according to the finding
                  Severity & Age. Checking the checkbox changes the sending
                  order to based on the finding SLA.
                </ReactTooltip>
              </div>
              <div className={"sidebar-display-area-footer"}>
                <Button
                  data-testid={"reset-to-default"}
                  className={"reset-to-default-button"}
                  type="button"
                  label={"Reset to Default"}
                  disabled={Object.keys(dirtyFields)?.length === 0}
                  onClick={() => reset()}
                />
                <div className={"action-buttons"}>
                  {isOpen ? (
                    <Button
                      data-testid={`cancel-sla`}
                      type="button"
                      label={"Cancel"}
                      onClick={() => closeModal()}
                    />
                  ) : null}
                  <Button
                    data-testid={`submit-sla`}
                    type="submit"
                    label={"Save"}
                    isSecondary
                    isSubmitting={isSubmittingForm}
                    rightIcon={
                      isSubmittingForm ? "seem-icon-spinner spinner" : ""
                    }
                    disabled={!isValid || isSubmittingForm}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Loader center img={Spinner} />
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default SLACustomization;
