import React from "react";
import "./ActivityItem.scss";
import { findingStatuses } from "../../../../../Findings/FindingsUtils";
import { ticketStatus } from "../../../../../Ticketing/TicketingUtils";
import TicketStatus from "../../../../../Findings/FindingsData/FindingsList/FindingsListItem/TicketStatus/TicketStatus";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET_LINK } from "../../../../../Findings/FindingDetailsPane/DetailsPaneApi";

export const activityEntities = {
  TICKET: "ticket",
  FINDING: "finding",
};

export const activityAttributes = {
  STATUS: "status",
  ASSIGNEE: "assignee",
  SPRINT: "sprint",
};

const ActivityItem = ({ activityData, className }) => {
  const [getTicketLink] = useLazyQuery(GET_TICKET_LINK, {
    onCompleted: (data) => {
      const link = data?.finding?.tickets?.edges?.[0]?.node?.link;
      window.open(link, "_blank");
    },
  });

  const getActivityData = () => {
    const {
      ticketId,
      old_value,
      new_value,
      finding,
      entity_type,
      attribute,
      findingId,
    } = activityData;
    switch (attribute) {
      case activityAttributes.STATUS:
        return entity_type === activityEntities.TICKET ? (
          <>
            <div className={`jira-states`}>
              {old_value && (
                <div className="ticket-url-wrap">
                  <TicketStatus status={old_value} withLabel={false} />
                  <i className={`seem-icon seem-icon-next-arrow`} />
                </div>
              )}
              <TicketStatus status={new_value} withLabel={false} />
            </div>
            <div className="ticket-url-wrap">
              <a
                className={`ticket-url`}
                alt="View ticket"
                title={`View Ticket`}
                href={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  getTicketLink({ variables: { id: findingId } });
                  e.stopPropagation();
                }}
              >
                {ticketId}
              </a>
            </div>
            {old_value
              ? ` moved from ${ticketStatus?.[old_value]?.text} to ${ticketStatus?.[new_value]?.text} `
              : ` created in ${ticketStatus?.[new_value]?.text}`}
          </>
        ) : (
          <>
            <Link
              to={`/findings/${findingId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`Finding-${finding}`}
            </Link>
            {` status was changed from ${findingStatuses?.[old_value]} to ${findingStatuses?.[new_value]}`}
          </>
        );

      case activityAttributes.ASSIGNEE:
        return (
          <>
            {`${new_value} was added as `}
            <a
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                getTicketLink({ variables: { id: findingId } });
                e.stopPropagation();
              }}
            >
              {ticketId}
            </a>
            {` assignee`}
          </>
        );

      case activityAttributes.SPRINT:
        return (
          <>
            <a
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                getTicketLink({ variables: { id: findingId } });
                e.stopPropagation();
              }}
            >
              {ticketId}
            </a>
            {` was moved from ${old_value} to ${new_value}`}
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div className={`activity-item ${className ? className : ""}`}>
      <div className={`activity-log-container`}>
        <div className={`activity-log-data`}>{getActivityData()}</div>
        <div className={`activity-log-time`}>{activityData.time}</div>
      </div>
    </div>
  );
};

export default ActivityItem;
