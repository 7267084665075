import React, { useContext } from "react";
import { modeOptions } from "../../RemediationQueuesUtils";
import QueueSettings from "./QueueSettings/QueueSettings";
import QueueOverview from "./QueueOverview/QueueOverview";
import QueueActivity from "./QueueActivity/QueueActivity";
import "./SettingsPanel.scss";
import { RemediationQueueContext } from "../RemediationQueueProvider";

const SettingsPanel = () => {
  const { mode } = useContext(RemediationQueueContext);

  const contentByMode = (rmqMode) => {
    switch (rmqMode) {
      case modeOptions.READ:
        return (
          <div className={"settings-panel-wrapper"}>
            <QueueOverview />
            <QueueActivity />
          </div>
        );
      default:
        return (
          <div className={"settings-panel-wrapper"}>
            <QueueSettings />
          </div>
        );
    }
  };

  return contentByMode(mode);
};

export default SettingsPanel;
