import { gql } from "@apollo/client";

export const GET_SLA_PREFERENCES = gql`
  query GetSlaPreferences {
    preferences {
      customer_preferences {
        sla_preferences {
          edges {
            node {
              preference_name: name
              group_name
              time_to_respond
              enabled
              group {
                enabled
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SLA = gql`
  mutation UpdateSLA(
    $dict_of_group_dicts: JSONString
    $remediation_queue_priority_sla: Boolean
  ) {
    update_sla_settings(
      dict_of_group_dicts: $dict_of_group_dicts
      remediation_queue_priority_sla: $remediation_queue_priority_sla
    ) {
      ok
      msg
    }
  }
`;

export const generateSLAData = ({ result = null, error = null }) => {
  let slaPreferencesMock = {
    request: {
      query: GET_SLA_PREFERENCES,
    },
  };

  if (error) {
    return {
      ...slaPreferencesMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...slaPreferencesMock,
      result: { data: result },
    };
  }

  return {
    ...slaPreferencesMock,
    result: {},
  };
};
