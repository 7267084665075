import React, { useContext } from "react";
import Button from "../../../Forms/Controls/Button/Button";
import "./IntegrationTicket.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";

const IntegrationTicket = ({ data, IntegrationContext }) => {
  const { handleViewIntegration, handleInstanceAddition } =
    useContext(IntegrationContext);
  const { t } = useTranslation();

  return (
    <div className={"integrations-ticket-wrap"}>
      <div className="integrations-ticket-header">
        <div
          className={`integrations-ticket-img`}
          style={{
            backgroundImage: `url(${data.imageLong})`,
          }}
        />
        <div className={`integrations-ticket-source`}>{data?.name}</div>
      </div>

      <div className="integrations-ticket-content">
        <div className={`integrations-ticket-prop`}>
          <div className={"integrations-ticket-def"}>Last Collection</div>
          <div className={"integrations-ticket-value"}>
            {data?.lastSynced ? (
              moment(data?.lastSynced).format("MMM Do YYYY, hh:mm")
            ) : (
              <div className={"not-connected"}>N/A</div>
            )}
          </div>
        </div>
        <div className={`integrations-ticket-prop`}>
          <div className={"integrations-ticket-def"}>Status</div>
          <div className={"integrations-ticket-value"}>
            {Boolean(data?.unavailable) || !data?.hasAtLeastOneInstance ? (
              <div className={"not-connected"}>N/A</div>
            ) : data?.isEnabled ? (
              <>
                <div className={"status-indicator is-enabled"} />
                {"Enabled"}
              </>
            ) : (
              <>
                <div className={"status-indicator is-disabled"} />
                {"Disabled"}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="integrations-ticket-footer">
        {Boolean(data.unavailable) ? (
          <Button
            data-testid={"open-integration"}
            textLink
            isSecondary
            disabled
            wrapperClassName={"integration-button"}
            label={t("Contact Support to Integrate")}
            type={"button"}
          />
        ) : !data?.hasAtLeastOneInstance ? (
          <Button
            data-testid={"open-integration"}
            textLink
            isSecondary
            wrapperClassName={"integration-button"}
            label={"Integrate"}
            type={"button"}
            onClick={() => handleInstanceAddition(data)}
          />
        ) : (
          <Button
            data-testid={"view-integration"}
            textLink
            label={"View Integration"}
            wrapperClassName={"integration-button"}
            type={"button"}
            onClick={() => handleViewIntegration(data)}
          />
        )}
      </div>
    </div>
  );
};

export default IntegrationTicket;
