import React, { useEffect, useState, useMemo } from "react";
import GenericField, { fieldTypeOptions } from "../GenericField";
import { GET_QUERY_FIELD } from "../../TicketingAPI";
import { useQuery } from "@apollo/client";
import { useWatch } from "react-hook-form";

const SearchQueryField = (props) => {
  const {
    control,
    selectedTicketProvider,
    selectedKeys,
    type,
    name,
    params,
    options: initialOptions,
    value,
  } = props;

  const [valueSearchInput, setValueSearchInput] = useState("");
  const [currOptions, setCurrOptions] = useState(initialOptions);

  const selectedFields = useWatch({
    control,
    name: `fields`,
  });

  const paramsObject = useMemo(() => {
    let obj;
    if (!!params) {
      obj = params?.reduce(
        (acc, param) => ({
          ...acc,
          [param]: selectedKeys?.[param] || selectedFields?.[param],
        }),
        {}
      );
    }

    return obj;
  }, [params, selectedFields, selectedKeys]);

  const { loading: loadingOptions, data: fieldOptions } = useQuery(
    GET_QUERY_FIELD,
    {
      variables: {
        params: JSON.stringify({
          ...paramsObject,
          search_string: valueSearchInput,
          limit: 10,
        }),
        field_name: name,
        ticket_provider_id: selectedTicketProvider,
      },
      skip: !name || !selectedTicketProvider || !valueSearchInput,
    }
  );

  useEffect(() => {
    if (!valueSearchInput && !currOptions?.length) {
      setCurrOptions(initialOptions);
    }
  }, [valueSearchInput, currOptions]);

  useEffect(() => {
    if (!!value) {
      if (initialOptions?.find((el) => el?.id === value)) {
        setCurrOptions(initialOptions);
      } else {
        setCurrOptions([
          ...initialOptions,
          currOptions?.find((el) => el?.id === value),
        ]);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!!fieldOptions) {
      setCurrOptions(fieldOptions?.get_query_fields?.values);
    }
  }, [fieldOptions]);

  switch (type) {
    case fieldTypeOptions.SELECT:
      return (
        <GenericField
          {...props}
          input={fieldTypeOptions.SELECT}
          options={currOptions}
          loading={loadingOptions}
          onInputChange={setValueSearchInput}
          searchable={true}
          setSingleOption={false}
        />
      );
    default:
      return <></>;
  }
};

export default SearchQueryField;
