import React from "react";
import { Link } from "react-scroll";
import "./LinksMenu.scss";

const LinksMenu = ({ menuItems, containerId }) => {
  return (
    <div className={`links-menu-wrapper`}>
      {menuItems.map((item) => {
        return (
          item.isVisible && (
            <Link
              className={`link-menu-item ${item.isDisabled ? "disabled" : ""}`}
              key={`link-menu-${item.to}`}
              activeClass="link-menu-item--active"
              to={item.to}
              spy={true}
              smooth={true}
              duration={250}
              containerId={containerId}
            >
              <span className="link-menu-item-text">{`${item.name} ${
                item.totalCount ? `(${item.totalCount})` : ""
              }`}</span>
            </Link>
          )
        );
      })}
    </div>
  );
};

export default LinksMenu;
