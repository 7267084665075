import React from "react";
import { showDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";

const PopupErrorFallback = ({ error, onRetry, onHomepage }) => {
  const dialogProps = {
    type: "error",
    message: error.message,
    buttons: [
      {
        isSecondary: true,
        label: "Try Again",
        onClick: onRetry,
      },
      {
        label: "Back to Homepage",
        onClick: onHomepage,
      },
    ],
    showCloseIcon: false,
  };

  return (
    <div className={"static-fallback-container"}>
      {showDialog({ ...dialogProps })}
    </div>
  );
};

export default PopupErrorFallback;
