import React, { useEffect, useState } from "react";
import SelectInput from "../../Forms/Controls/SelectInput/SelectInput";
import { getSinceText } from "./TrendsUtils";
import { timeScales } from "../DashboardUtils";

export const timeScaleValues = [
  {
    label: "Daily",
    value: timeScales.DAY,
  },
  {
    label: "Weekly",
    value: timeScales.WEEK,
  },
  {
    label: "Monthly",
    value: timeScales.MONTH,
  },
  {
    label: "Quarterly",
    value: timeScales.QUARTER,
  },
];

const TimeScaleSelector = ({ onTimeScaleChange, showSinceLabel = true }) => {
  const [selectedTimeScale, setSelectedTimeScale] = useState("MONTH");

  useEffect(() => {
    onTimeScaleChange(selectedTimeScale);
  }, [selectedTimeScale]);

  return (
    <div className={`trends-time-scale-container`}>
      <SelectInput
        SelectInputStyle={"bomba-style"}
        labelWidth="55px"
        name={`timeScale`}
        size="s"
        labelMini
        label={"Period"}
        menuWrapClassName="time-scale-menu"
        onChange={(selectedValue) => {
          setSelectedTimeScale(selectedValue.value);
        }}
        options={timeScaleValues}
        value={
          timeScaleValues?.filter(
            (selectedField) => selectedField.value === selectedTimeScale
          )?.[0] || null
        }
        defaultValue={null}
      />
      {showSinceLabel && (
        <label className={"trends-since-label"}>
          {getSinceText(selectedTimeScale).length
            ? `Since ${getSinceText(selectedTimeScale)}`
            : ""}
        </label>
      )}
    </div>
  );
};

export default TimeScaleSelector;
