import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import ProgramDashboard from "./ProgramDashboard/ProgramDashboard";
import TrendsDashboard from "./TrendsDashboard/TrendsDashboard";
import OrganizationalDashboard from "./OrganizationalDashboard/OrganizationalDashboard";
import TimeScaleSelector from "./TrendsDashboard/TimeScaleSelector";
import "./Dashboard.scss";
import { DASHBOARD_TYPES } from "./DashboardUtils";
import Tabs from "../Components/Tabs/Tabs";
import ProtectedRoute from "../AppRouter/ProtectedRoute";
import SLADashboard from "./SLADashboard/SLADashboard";
import Button from "../Forms/Controls/Button/Button";
import SLACustomization from "../AppSettings/SLACustomization/SLACustomization";
import { ModalContext } from "../Forms/Dialogs/Modal/ModalContext";
import AppView from "../AppView/AppView";
import { AppPreferencesContext } from "../General/AppPreferencesProvider";

const Dashboard = () => {
  let { id } = useParams();
  let history = useHistory();
  const { programDashboardPage, trendsPage } = useFlags();
  const [timeScale, setTimeScale] = useState(null);
  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (!id) {
      history.push(`/dashboard/${DASHBOARD_TYPES.ORGANIZATIONAL}`);
    }
  }, [history, id]);

  const timeScaleChangeHandler = (timeScale) => setTimeScale(timeScale);

  const menuItems = useMemo(() => {
    const menuItems = [
      {
        pageLink: DASHBOARD_TYPES.ORGANIZATIONAL,
        title: "Organizational",
      },
      ...(trendsPage
        ? [
            {
              pageLink: DASHBOARD_TYPES.TRENDS,
              title: "Trends",
            },
          ]
        : []),
      ...(programDashboardPage
        ? [
            {
              pageLink: DASHBOARD_TYPES.PROGRAM,
              title: "Program",
            },
          ]
        : []),
      ...(slaEnabled
        ? [
            {
              pageLink: DASHBOARD_TYPES.SLA,
              title: "SLA Compliance",
            },
          ]
        : []),
    ];

    return menuItems;
  }, [slaEnabled, programDashboardPage, trendsPage]);

  const onSelectTab = (item) => {
    history.push(`/dashboard/${item.pageLink}`);
  };

  const headerComponents = useMemo(() => {
    const components = [];
    if (id === DASHBOARD_TYPES.SLA) {
      components.push(
        <Button
          data-testid={`customize-sla`}
          className={"customize-sla-button"}
          type="button"
          label={"Customize SLA"}
          onClick={() => {
            openModal({
              title: "SLA Customization",
              size: "s",
              overlay: true,
              component: <SLACustomization />,
            });
          }}
        />
      );
    }

    return components;
  }, [id, openModal]);

  return (
    <div className={`dashboard-wrapper`} data-testid={`dashboard-div`}>
      <AppView editable={true} enableSharing={true}>
        <div className={`dashboard-controls-panel-line`} />
        <div className={`dashboard-controls-panel`}>
          <Tabs
            menuItems={menuItems}
            onSelectTab={onSelectTab}
            activeId={id}
            displayUnderline={false}
          />
          {(id === DASHBOARD_TYPES.TRENDS ||
            id === DASHBOARD_TYPES.PROGRAM ||
            id === DASHBOARD_TYPES.SLA) && (
            <div className={`trends-controls-wrapper`}>
              <TimeScaleSelector
                onTimeScaleChange={timeScaleChangeHandler}
                showSinceLabel={id !== DASHBOARD_TYPES.SLA}
              />
              {headerComponents?.slice()}
            </div>
          )}
        </div>

        <ProtectedRoute path={`/dashboard/${DASHBOARD_TYPES.ORGANIZATIONAL}`}>
          <OrganizationalDashboard />
        </ProtectedRoute>

        <ProtectedRoute path={`/dashboard/${DASHBOARD_TYPES.PROGRAM}`}>
          {programDashboardPage && <ProgramDashboard timeScale={timeScale} />}
        </ProtectedRoute>

        <ProtectedRoute path={`/dashboard/${DASHBOARD_TYPES.TRENDS}`}>
          {trendsPage && <TrendsDashboard timeScale={timeScale} />}
        </ProtectedRoute>

        <ProtectedRoute path={`/dashboard/${DASHBOARD_TYPES.SLA}`}>
          {slaEnabled && <SLADashboard timeScale={timeScale} />}
        </ProtectedRoute>
      </AppView>
    </div>
  );
};

export default Dashboard;
