import React, { useContext } from "react";
import TicketEndpointFields from "../../TicketEndpointFields/TicketEndpointFields";
import { ticketEndpointContextEnum } from "../../TicketingUtils";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import { useForm } from "react-hook-form";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";

const RmqTicketSettingsFields = ({
  endpointId,
  setValue,
  getValues,
  keysObject,
  selectedKeys,
  selectedTicketProvider,
  selectedFields,
  onGetProps,
}) => {
  const methods = useForm({
    mode: "all",
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const { closeModal } = useContext(ModalContext);

  const onSubmit = (formData) => {
    setValue("fields", formData?.fields, { shouldDirty: true });
    closeModal();
    showToast({
      message: "Field Mapping saved successfully",
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();

        handleSubmit(onSubmit)(e);
      }}
    >
      <>
        <TicketEndpointFields
          {...methods}
          getValues={getValues}
          errors={errors}
          shouldUnregisterOnUnmount={false}
          keysObject={keysObject}
          selectedKeys={selectedKeys}
          selectedFields={selectedFields}
          selectedTicketProvider={selectedTicketProvider}
          onGetProps={onGetProps}
          endpointId={endpointId}
          context={ticketEndpointContextEnum.RMQ}
        />
        <ModalButtonsContainer>
          <Button
            data-testid={"rmq-ticket-settings-submit"}
            type="submit"
            label={`Save`}
            isSecondary
            rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
            disabled={!isValid || isSubmitting}
          />
        </ModalButtonsContainer>
      </>
    </form>
  );
};

export default RmqTicketSettingsFields;
