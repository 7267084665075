import React, { useState, useMemo } from "react";
import Popover from "react-popover";
import { useTranslation } from "react-i18next";
import Avatar from "../Avatar/Avatar";
import "./ShowMoreInlineList.scss";
import ClassNames from "classnames";

const ShowMoreInlineList = ({
  list,
  max = 0,
  withListItemAvatar = false,
  listItemSize = "s",
  id = "",
  full = false,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { t } = useTranslation();

  const classNameWrapper = ClassNames("show-more-list-name", {
    "is-small": listItemSize === "s",
  });

  const popoverContent = useMemo(
    () => (
      <ul className={"show-more-list-popover-wrapper"}>
        {list?.slice(max).map((listItem) => (
          <li key={listItem.id} className={"show-more-list-popover-item"}>
            {withListItemAvatar && <Avatar name={listItem.name} size={"s"} />}
            {listItem.name}
          </li>
        ))}
      </ul>
    ),
    [list, max, withListItemAvatar]
  );

  return !full && list?.length ? (
    <div className={"show-more-list-wrapper"}>
      {list.slice(0, max).map((listItem, index) => {
        return (
          <>
            <span
              className={classNameWrapper}
              title={listItem.name}
              key={listItem.id}
              data-testid={`show-more-list-item-name-${listItem.id}`}
            >
              {listItem.name}
            </span>
            {index + 1 < max && index + 1 !== list?.length ? (
              <span>{", \u00A0"}</span>
            ) : (
              ""
            )}
          </>
        );
      })}
      {list?.length > max && (
        <Popover
          isOpen={isPopoverOpen}
          tipSize={0.01}
          place={"below"}
          onOuterAction={() => setIsPopoverOpen(false)}
          body={[
            <div
              key={`show-more-list-popover-${id}`}
              className={`popover-container`}
              onClick={(e) => e.stopPropagation()}
            >
              {popoverContent}
            </div>,
          ]}
        >
          <div
            className={"show-more-text"}
            data-testid={"show-more-list-text"}
            onClick={(event) => {
              event.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            {t(`+ ${list?.length - max} More`)}
          </div>
        </Popover>
      )}
    </div>
  ) : (
    popoverContent
  );
};

export default ShowMoreInlineList;
