import { useQuery } from "@apollo/client";
import { GET_SCOPES } from "../../Findings/FindingsFilters/FindingsFilterApi";

const useScopesData = () => {
  const { data: scopesData, loading: isLoadingScopesData } = useQuery(
    GET_SCOPES,
    { fetchPolicy: "network-only" }
  );

  return { isLoadingScopesData, scopesData };
};

export default useScopesData;
