export const MFAMethod = {
  TOTP: "TOTP",
  SMS: "SMS",
  NOMFA: "NOMFA",
};

export const MFAType = {
  SOFTWARE_TOKEN_MFA: "SOFTWARE_TOKEN_MFA",
  SMS_MFA: "SMS_MFA",
};
