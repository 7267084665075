import React from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import "./DynamicMappingField.scss";
import DynamicMappingFieldRow from "./DynamicMappingFieldRow/DynamicMappingFieldRow";

const DynamicMappingField = (props) => {
  const {
    control,
    formState: { errors },
    options,
    registerPrefix,
    label,
  } = props;

  const { fields, update, append, remove } = useFieldArray({
    control,
    name: registerPrefix,
  });

  const watchFieldArray = useWatch({
    control,
    name: registerPrefix,
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));

  return (
    <div className={"dynamic-mapping-field"}>
      <div className="title">{label}</div>
      <ul>
        {!!controlledFields?.length &&
          controlledFields?.map((field, index) => {
            return (
              <li key={field.id}>
                <DynamicMappingFieldRow
                  {...props}
                  {...field}
                  mappingOptions={options}
                  registerPrefix={registerPrefix}
                  label={label}
                  index={index}
                  updateRow={update}
                  control={control}
                  errors={errors}
                  removeRow={remove}
                  appendRow={append}
                  rowsNumber={controlledFields.length}
                  mappedFields={controlledFields
                    .filter((el) => !!el?.field)
                    .map((el) => el?.field)}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default DynamicMappingField;
