import SelectInput from "../../../../Forms/Controls/SelectInput/SelectInput";
import React from "react";
import { DropdownIndicator } from "../../../FindingsFilters/FilterHeaderStyleComponents/FilterHeaderStyleComponents";

export const findingStatus = [
  {
    label: "Open",
    value: "OPEN",
    leftIcon: "seem-icon-open",
  },
  {
    label: "Reviewed",
    value: "REVIEWED",
    leftIcon: "seem-icon-open-confirmed",
  },
  {
    label: "Fixed",
    value: "FIXED",
    leftIcon: "seem-icon-jira-icon-done",
  },
  {
    label: "Ignored",
    value: "IGNORED",
    leftIcon: "seem-icon-ignore",
  },
];

const FindingStatusSelect = ({
  selectedStatus,
  onChange,
  "data-testid": dataTestId,
}) => (
  <SelectInput
    data-testid={dataTestId}
    name={`status`}
    size="s"
    menuWrapClassName="status-menu"
    onChange={(selectedValue) => onChange(selectedValue.value)}
    options={findingStatus}
    value={
      findingStatus?.filter(
        (selectedField) => selectedField.value === selectedStatus
      )?.[0] || null
    }
    defaultValue={null}
    replaceableComponents={{ DropdownIndicator }}
  />
);

export default FindingStatusSelect;
