import { useContext, useMemo } from "react";
import { useFeatures } from "@paralleldrive/react-feature-toggles";
import { AuthContext } from "../Auth/AuthContext";

const FeatureFlag = ({ name, children }) => {
  const features = useFeatures();

  const {
    authState: { isDemoAccount },
  } = useContext(AuthContext);

  const isActive = useMemo(() => {
    const activeFeatures = features.flatMap((feature) =>
      feature.isActive || (feature.isOpenForDemo && isDemoAccount)
        ? [feature.name]
        : []
    );
    return !!activeFeatures?.includes(name);
  }, [name, features, isDemoAccount]);

  return isActive ? children : null;
};

export default FeatureFlag;
