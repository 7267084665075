import React, { useEffect, useMemo, useContext } from "react";
import "./FindingsList.scss";
import useFindingsListState from "./useFindingsListState";
import {
  findingsRowType,
  groupByTypes,
  getFindingSeverity,
  getGroupName,
  sortByOptions,
  findingsVisibleColumns,
  getGroupTitle,
  slaStatuses,
} from "../../FindingsUtils";
import { FindingsContext } from "../../FindingsContext";
import FindingsTable from "./FindingsTable/FindingsTable";
import { capitalize } from "lodash";
import { ScopesContext } from "../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { FiltersContext } from "../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import useFindingsViewChange from "../../useFindingsViewChange";

const FindingsList = ({ forwardedRef, groupBy, datasources }) => {
  const {
    findingsState: { dataStructure, groupsList, scrollToIndex, totalCount },
    updateFindingsState,
    selectFindingInList,
  } = useContext(FindingsContext);

  useFindingsViewChange({ queries: ["GetFindings", "GetFindingsByGroup"] });

  const getFindingsSortOrder = (groupBy) => {
    switch (groupBy?.selectedOptionValue?.groupBy) {
      case groupByTypes.CLOUD_ACCOUNT:
        return ["CLOUD_ACCOUNT_FRIENDLY_NAME_ASC", "CLOUD_PROVIDER_ASC"];

      case groupByTypes.SEVERITY:
        return ["SEVERITY_DESC"];

      case groupByTypes.SLA_STATUS:
        return ["SLA_STATUS_DESC"];

      default:
        return [`${groupBy.selectedOptionValue.groupBy.toUpperCase()}_ASC`];
    }
  };

  const { filtersState } = useContext(FiltersContext);
  const { selectedFilter, filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  const {
    errorData,
    findings,
    setFindings,
    loadingData,
    isLoadingFindings,
    resetList,
    findingsListData,
    handleScroll,
    onSelectedFinding,
    selectedRowState,
    checkedFindings,
    onCheckFinding,
  } = useFindingsListState(
    [...getFindingsSortOrder(groupBy), ...sortByOptions[0].value],
    { filterToExecute, scopeToExecute, selectedScope, selectedFilter },
    forwardedRef,
    scrollToIndex,
    false,
    totalCount
  );

  useEffect(() => {
    !!resetList &&
      !!onSelectedFinding &&
      updateFindingsState({ resetList, onSelectedFinding });
  }, []);

  useEffect(() => {
    let list = Array.from(dataStructure.keys()).reduce(function (r, k) {
      const items = dataStructure.get(k).hasOwnProperty("findings")
        ? dataStructure.get(k).findings
        : dataStructure.get(k);
      let groupTitle;

      switch (groupBy.selectedOptionValue.groupBy) {
        case groupByTypes.SEVERITY:
          groupTitle = capitalize(getFindingSeverity(k));
          break;

        case groupByTypes.SLA_STATUS:
          groupTitle = slaStatuses?.[k]?.title;
          break;

        case groupByTypes.DATASOURCE:
          groupTitle = datasources?.filter(
            (datasource) =>
              k === datasource.node.datasource_definition?.file_prefix
          )[0]?.node?.datasource_definition?.name;
          break;

        default:
          groupTitle = dataStructure.get(k).title;
      }

      const groupHeader = {
        groupTitle: groupTitle,
        groupId: dataStructure.get(k).groupId,
        groupCount: dataStructure.get(k).count,
        groupKey: dataStructure.get(k).name,
        rowType: findingsRowType.GROUP,
        isLoader: false,
      };
      return r.concat(groupHeader, items);
    }, []);

    setFindings(list);
  }, [dataStructure, datasources]);

  const generateGroupName = (selectedGroupBy, finding) => {
    let groupByKey;

    if (Array.isArray(selectedGroupBy)) {
      groupByKey = [];
      selectedGroupBy.map((el) => {
        groupByKey.push(finding[el]);
      });
      return JSON.stringify(groupByKey);
    }

    groupByKey = finding[selectedGroupBy]?.toString();

    return groupByKey;
  };

  useEffect(() => {
    let rowIndex = 0;
    let groupsDs = new Map(dataStructure);

    const hasGroups = groupsList?.findings_groups?.length;
    const hasFindings = findingsListData?.findings?.edges;

    if (hasGroups && hasFindings) {
      groupsList.findings_groups.forEach((groupByItem, index) => {
        if (
          groupByItem?.group_by?.[0]?.field ===
          groupBy?.selectedOptionValue?.groupBy
        ) {
          let groupName =
            getGroupName(groupByItem) ||
            groupBy?.selectedOptionValue?.noData + getGroupName(groupByItem);

          const groupTitle =
            getGroupTitle(groupByItem) || groupBy?.selectedOptionValue?.noData;

          if (!groupsDs.get(groupName)) {
            groupsDs.set(groupName, {
              groupByFields: groupByItem?.group_by,
              name: groupName,
              title: groupTitle,
              rowIndex: rowIndex,
              findings: new Array(groupByItem?.total_count).fill({
                isLoader: true,
              }),
              count: groupByItem.total_count,
              groupId:
                groupBy?.selectedOptionValue?.groupBy === groupByTypes.SEVERITY
                  ? groupName
                  : index,
            });
          }
          if (groupBy?.selectedOptionValue?.groupBy === groupByTypes.SEVERITY) {
            groupsDs.set(groupName, {
              ...groupsDs.get(groupName),
              groupIndex: index,
            });
          }
          rowIndex += groupByItem?.total_count;
        }
      });

      findingsListData.findings.edges.forEach((edge, index) => {
        if (!edge) {
          return;
        }
        const finding = edge.node;
        const tickets = finding.tickets?.edges;
        const groupName =
          generateGroupName(groupBy.selectedOptionValue.dbGroupBy, finding) ||
          groupBy?.selectedOptionValue?.noData +
            generateGroupName(groupBy.selectedOptionValue.dbGroupBy, finding);

        groupsDs?.get(groupName)?.findings?.forEach((finding) => {
          finding.isItemLoaded = true;
        });

        if (!!groupsDs?.get(groupName)) {
          groupsDs
            .get(groupName)
            .findings.splice(index - groupsDs.get(groupName).rowIndex, 1, {
              ...finding,
              remediation:
                !!finding.remediation && !finding.remediation.startsWith('"')
                  ? JSON.parse(finding.remediation)
                  : null,
              groupId: groupsDs.get(groupName).groupId,
              ticketStatus:
                !!tickets && !!tickets.length ? tickets[0].node.status : null,
              ticketId: tickets?.[0]?.node.external_id,
              isLoader: false,
              groupKey: groupName,
            });
        }
      });

      updateFindingsState({
        dataStructure: groupsDs,
      });
    }
  }, [groupsList, findingsListData]);

  const isLoadingFindingsData = useMemo(() => {
    return (
      loadingData ||
      (!loadingData &&
        !!findingsListData?.findings?.edges?.length &&
        !findings?.length) ||
      !selectedScope ||
      !selectedFilter
    );
  }, [loadingData, findingsListData, findings, selectedScope, selectedFilter]);

  return (
    <FindingsTable
      showFindingsActions
      isLoading={isLoadingFindingsData}
      isError={errorData}
      findings={findings}
      isLoadingFindings={isLoadingFindings}
      groupBy={groupBy}
      forwardedRef={forwardedRef}
      handleScroll={handleScroll}
      findingsListData={findingsListData}
      hasSelectedItem={selectedRowState.hasSelectedItem}
      onSelectedFinding={selectFindingInList}
      columns={findingsVisibleColumns}
      selectedFilterRow={selectedRowState.selectedFilterRow}
      checkedFindings={checkedFindings}
      onCheckFinding={onCheckFinding}
      multiSelect={true}
      totalCount={totalCount}
      filteringEnabled={true}
    />
  );
};

export default FindingsList;
