import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { GET_FINDING_BY_ID } from "./DetailsPaneApi";
import { getFindingSeverity } from "../FindingsUtils";
import { useParams } from "react-router-dom";
import FindingDetailsPane from "./FindingDetailsPane";

export const DetailsPaneContext = React.createContext({});

export const useDetailsPane = () => {
  const context = React.useContext(DetailsPaneContext);
  if (context === undefined) {
    throw new Error(`useDetailsPane must be used within a DetailsPaneContext`);
  }
  return context;
};

const DetailsPaneProvider = ({ isFullScreen, closeDetailsPane }) => {
  const [detailsPaneData, setDetailsPaneState] = useState({});
  let { id } = useParams();

  let { loading, data: findingData } = useQuery(GET_FINDING_BY_ID, {
    variables: { id: id },
  });

  useEffect(() => {
    if (!!findingData) {
      const finding = findingData?.finding || null;
      const repoLink =
        JSON.parse(findingData?.finding?.additional_data)?.repositoryLink || "";
      setDetailsPaneState({
        ...finding,
        repoLink,
        editable: finding?.editable,
        title: finding?.title,
        description: finding?.description,
        score: finding?.finding_score?.score,
        remediation: !!finding?.remediation && JSON.parse(finding?.remediation),
        discovered_time: finding?.discovered_time,
        cloud_account: finding?.cloud_account,
        original_score: finding?.finding_score?.original_score,
        original_finding_id: finding?.original_finding_id,
        resource_name: finding?.resource_name,
        resource_type: finding?.resource_type,
        tickets: finding?.tickets?.edges || null,
        original_finding_link: finding?.dynamic?.link || null,
        severity: getFindingSeverity(finding?.finding_score?.severity),
        ticketStatus: finding?.tickets?.edges?.[0]?.node.status || null,
        findingTicket: finding?.tickets?.edges?.[0]?.node || null,
        isFullScreen: isFullScreen,
        seem_tags: JSON.parse(finding?.seem_tags) || [],
        datasource_tags: JSON.parse(finding?.datasource_tags) || [],
      });
    }
  }, [findingData, isFullScreen, id]);

  const updateDetailsPaneData = useCallback(
    (state) => {
      setDetailsPaneState({ ...detailsPaneData, ...state });
    },
    [detailsPaneData]
  );

  const findingsContextValue = useMemo(
    () => ({
      detailsPaneData,
      loading,
      updateDetailsPaneData,
    }),
    [detailsPaneData, loading, updateDetailsPaneData]
  );

  return (
    <DetailsPaneContext.Provider value={findingsContextValue}>
      <FindingDetailsPane
        closeDetailsPane={closeDetailsPane}
        isFullScreen={isFullScreen}
      />
    </DetailsPaneContext.Provider>
  );
};

export default DetailsPaneProvider;
