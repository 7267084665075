import React, { useContext, useEffect } from "react";
import FindingsData from "./FindingsData/FindingsData";
import { FindingsContext } from "./FindingsContext";
import { GeneralAppContext } from "../General/GeneralAppProvider";
import AppView from "../AppView/AppView";

const Findings = () => {
  const { resetFindingsList } = useContext(FindingsContext);
  const { updateAppState } = useContext(GeneralAppContext);

  useEffect(() => {
    updateAppState({ resetFindingsList });
  }, [resetFindingsList]);

  return (
    <div className={"findings-page-wrapper"}>
      <AppView editable={true} enableSharing={true} enableDownload={true}>
        <FindingsData />
      </AppView>
    </div>
  );
};

export default Findings;
