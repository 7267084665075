import React, { useEffect, useMemo, useState, useContext } from "react";
import { Controller, useWatch, useForm, FormProvider } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import "./UserActions.scss";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import { userActions, roleOptions } from "../UsersListUtils";
import { EMAIL_PATTERN_REGEX } from "../../../Forms/FormValidations";
import UserEditableFields from "../../UserProfile/UserEditableFields/UserEditableFields";
import SelectInput from "../../../Forms/Controls/SelectInput/SelectInput";
import TagsSelect from "../../../Forms/Controls/TagsSelect/TagsSelect";
import AllowedScopes from "./AllowedScopes/AllowedScopes";
import CheckBox from "../../../Forms/Controls/CheckBox/CheckBox";

const UserActions = ({
  mode,
  data = null,
  onSubmit,
  profilesSuggestions,
  allowedDomains,
}) => {
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const [isAllowedScopesOpen, setIsAllowedScopesOpen] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const methods = useForm({
    mode: "onBlur",
  });
  const {
    control,
    handleSubmit,
    reset: formReset,
    formState: { errors, isValid, isDirty },
  } = methods;
  const withoutEmail = useWatch({
    control,
    name: `withoutEmail`,
    defaultValue: false,
  });

  const selectedProfiles = useWatch({ control, name: "profiles" });

  useEffect(() => {
    if (!!data && mode === userActions.EDIT) {
      formReset({
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        role: { value: data?.role?.id, label: data?.role?.name },
        profiles: data?.profiles,
      });
    }
  }, [mode, data]);

  const applySubmission = (formData) => {
    setIsSubmittingForm(true);

    onSubmit(
      mode === userActions.EDIT ? { ...formData, id: data.id } : formData
    ).then(() => {
      setIsSubmittingForm(false);
    });
  };

  const allowedScopesDisabled = useMemo(
    () =>
      !selectedProfiles?.length ||
      !selectedProfiles?.some((profile) => profile.hasScopes),
    [selectedProfiles]
  );

  return (
    <FormProvider {...methods}>
      {!isAllowedScopesOpen ? (
        <form
          className={"user-actions-form"}
          onSubmit={handleSubmit(applySubmission)}
        >
          <div className={"user-actions-email"}>
            <Controller
              name={"email"}
              defaultValue={null}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  placeholder={"Type email"}
                  type={"text"}
                  wrapperClassName={"user-actions-field"}
                  label={"Email"}
                  inputStyle={"bomba-style"}
                  labelTop
                  size={"custom"}
                  error={errors && errors.email ? errors.email : null}
                  value={value}
                  disabled={mode === userActions.EDIT}
                  onChange={(val) => onChange(val)}
                  onBlur={onBlur}
                  data-testid={"user-actions-email"}
                />
              )}
              control={control}
              rules={{
                required: "Required",
                validate: {
                  validEmail: (value) => {
                    const isValid = EMAIL_PATTERN_REGEX.test(value);
                    return isValid || t("Invalid Email");
                  },
                  allowedDomains: (value) => {
                    if (mode !== userActions.EDIT) {
                      const domain = value?.substring(
                        value?.lastIndexOf("@") + 1
                      );

                      if (domain) {
                        if (
                          allowedDomains?.find(
                            (allowedDomain) => allowedDomain === domain
                          )
                        ) {
                          return true;
                        }
                      }

                      return t(
                        "User’s domain is not allowed, change the domain or contact support"
                      );
                    } else {
                      return true;
                    }
                  },
                },
              }}
            />
          </div>
          <UserEditableFields />
          <Controller
            wrapperClassName={``}
            name={"role"}
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectInput
                data-testid={"user-role"}
                name={"role"}
                value={value}
                onBlur={onBlur}
                labelTop
                size="max"
                SelectInputStyle={"bomba-style"}
                onChange={(selectedValue) => onChange(selectedValue)}
                error={errors?.role ? errors.role : null}
                placeholder={"Choose user role"}
                isSearchable={true}
                label={"Role"}
                options={roleOptions.slice()}
              />
            )}
            control={control}
            rules={{ required: "Required" }}
          />
          <Controller
            wrapperClassName={``}
            name={"profiles"}
            defaultValue={""}
            render={({ field: { onChange, value, onBlur, onFocus } }) => (
              <TagsSelect
                data-testid={`user-profiles`}
                onChange={(tags) => onChange(tags)}
                value={value || ""}
                placeholder={`Search Profiles...`}
                suggestions={profilesSuggestions}
                label={"Profiles"}
                error={errors?.profiles ? errors?.profiles : null}
                inputStyle={"bomba-style"}
                labelTop
                onBlur={onBlur}
                onFocus={onFocus}
                name={"profiles"}
                size="max"
                minQueryLength={0}
              />
            )}
            control={control}
            rules={{ required: "Required" }}
          />

          <Button
            textLink
            wrapperClassName={"allowed-scopes-btn"}
            leftIcon={"seem-icon-scope"}
            isFullWidth
            disabled={allowedScopesDisabled}
            label={t(
              `${allowedScopesDisabled ? "No" : "View"} Allowed Groups & Scopes`
            )}
            data-testid={"allowed-scopes"}
            type={"button"}
            onClick={() => setIsAllowedScopesOpen(true)}
          />

          {mode === userActions.CNC_CREATE && (
            <>
              <Controller
                name={"withoutEmail"}
                render={({ field: { value, onChange } }) => {
                  return (
                    <CheckBox
                      name={"withoutEmail"}
                      data-testid={"withoutEmail"}
                      checked={value}
                      className={"send-email-cb"}
                      label={"Do not send an invitation email to the user"}
                      onChange={(val, newValue) => {
                        onChange(newValue);
                      }}
                      error={errors.withoutEmail ? errors.withoutEmail : null}
                    />
                  );
                }}
                control={control}
              />
              {withoutEmail && (
                <Controller
                  shouldUnregister={true}
                  name={"password"}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (
                      <TextInput
                        type={"text"}
                        value={value}
                        data-testid={"password"}
                        labelTop
                        onBlur={onBlur}
                        name={"password"}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        label={"Password"}
                        size="l"
                        inputStyle={"bomba-style"}
                        error={errors.password ? errors.password : null}
                      />
                    );
                  }}
                  rules={{
                    required: "Required",
                  }}
                  control={control}
                />
              )}
            </>
          )}

          <ModalButtonsContainer>
            <Button
              type="button"
              data-testid={"user-actions-cancel"}
              label={"Cancel"}
              onClick={() => closeModal()}
            />
            <Button
              data-testid={"user-actions-submit"}
              type="submit"
              label={"Save"}
              isSecondary
              isSubmitting={isSubmittingForm}
              rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
              disabled={!isValid || !isDirty || isSubmittingForm}
            />
          </ModalButtonsContainer>
        </form>
      ) : (
        <div className={"user-actions-form"}>
          <AllowedScopes
            onClosingAllowedScopes={() => setIsAllowedScopesOpen(false)}
            profiles={selectedProfiles}
          />
        </div>
      )}
    </FormProvider>
  );
};

export default UserActions;
