import { snakeCase, startCase } from "lodash";
import {
  cloudProviderIcons,
  findingSeverities,
  slaStatuses,
} from "../../FindingsUtils";
import { ticketStatus } from "../../../Ticketing/TicketingUtils";

export const conditionLabel = {
  lt: "<",
  lte: "<=",
  gt: ">",
  gte: ">=",
  range: "Range",
  eq: "=",
  like: "Like",
  notlike: "Not Like",
  in: "=",
  ilike: "I-Like",
  not_in: "!=",
  overlap: "=",
  likelist: "Like",
  notlikelist: "Not Like",
};

export const conditionDateLabel = {
  lt: "Before",
  gt: "After",
  within: "Within",
  recent: "=",
};

export const getPlaceholderByField = (field) => {
  switch (field) {
    case "age":
      return "Insert Custom Age";

    case "sla_remaining_time":
      return "Insert Custom Days";

    default:
      return "";
  }
};

export const dateOptions = () => {
  return [
    { label: "Today", value: "1", name: "1" },
    { label: "Last 7 Days", value: "7", name: "7" },
    { label: "Last 30 Days", value: "30", name: "30" },
    { label: "Last 90 Days", value: "90", name: "90" },
  ];
};

export const ageOptions = (field, onCustomOptionError) => {
  return [
    { label: "7 Days", value: "7", name: "7" },
    { label: "30 Days", value: "30", name: "30" },
    { label: "90 Days", value: "90", name: "90" },
    {
      customOption: {
        type: "number",
        component: "TEXT",
        placeholder: getPlaceholderByField(field),
        onError: onCustomOptionError,
      },
      value: "custom",
      name: "custom",
    },
  ];
};

export const dateFields = ["due_date", "last_reported_time", "discovered_time"];
export const ageFields = ["age", "sla_remaining_time"];

export const getRowOptions = (data, field) => {
  return data?.map((obj) => {
    let label = startCase(obj[field]);
    if (obj[field] === "tags_values") {
      label = "Tags";
    }
    if (obj[field] === "sla_status") {
      label = "SLA";
    }
    return { label: label, value: obj[field] };
  });
};

const getFieldValueLabel = (fieldValue, value) => {
  if (fieldValue === "severity") {
    return findingSeverities[value];
  } else if (fieldValue === "ticket_status") {
    return ticketStatus[value]?.text;
  } else if (fieldValue === "sla_status") {
    return slaStatuses[value]?.title;
  }

  return value;
};

export const getFieldValueOptions = (fieldValue, array) => {
  return array?.map((valueOption) => {
    let optionImg = null;
    let optionIcon = null;
    if (fieldValue === "cloud_provider") {
      optionImg = cloudProviderIcons[snakeCase(valueOption)];
    } else if (fieldValue === "ticket_status") {
      optionIcon = ticketStatus[valueOption]?.icon;
    }
    return {
      label: getFieldValueLabel(fieldValue, valueOption),
      value: [valueOption],
      leftImg: optionImg,
      leftIcon: optionIcon,
    };
  });
};
