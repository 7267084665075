import React, { useContext } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import FindingsNumberAndAvgScoreOfUserAccount from "./FindingsNumberAndAvgScoreOfUserAccount/FindingsNumberAndAvgScoreOfUserAccount";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import DashboardWidget from "../DashboardWidget/DashboardWidget";
import FindingsByDataSourceAndSeverity from "./FindingsByDataSourceAndSeverity/FindingsByDataSourceAndSeverity";
import FindingsByTicketStatus from "./FindingsByTicketStatus/FindingsByTicketStatus";
import AverageAgeBySeverity from "./AverageAgeBySeverity/AverageAgeBySeverity";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import { FiltersContext } from "../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import useFindingsViewChange from "../../Findings/useFindingsViewChange";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_spiritedaway);
am4core.addLicense("CH269599249");

const OrganizationalDashboard = () => {
  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  useFindingsViewChange({ queries: ["getDashboard"] });

  return !!selectedScope && !!filterToExecute ? (
    <>
      <div className="widgets-grid" data-testid={"widgets-div"}>
        <DashboardWidget
          className="num-and-avg-dashboard-widget"
          title={"Cloud Account Heatmap"}
        >
          <FindingsNumberAndAvgScoreOfUserAccount
            selectedScope={selectedScope}
            filterToExecute={filterToExecute}
            scopeToExecute={scopeToExecute}
          />
        </DashboardWidget>
        <DashboardWidget
          className="avg-age-severity-dashboard-widget"
          title={"Average Age By Severity"}
        >
          <AverageAgeBySeverity
            selectedScope={selectedScope}
            filterToExecute={filterToExecute}
            scopeToExecute={scopeToExecute}
          />
        </DashboardWidget>
        <DashboardWidget
          className="data-src-severity-dashboard-widget"
          title={"Findings By Data Source And Severity"}
        >
          <FindingsByDataSourceAndSeverity
            selectedScope={selectedScope}
            filterToExecute={filterToExecute}
            scopeToExecute={scopeToExecute}
          />
        </DashboardWidget>
        <DashboardWidget
          className="ticket-status-dashboard-widget"
          title={"Findings By Ticket Status"}
        >
          <FindingsByTicketStatus
            selectedScope={selectedScope}
            filterToExecute={filterToExecute}
            scopeToExecute={scopeToExecute}
            chartLocation={"dashboard"}
          />
        </DashboardWidget>
      </div>
    </>
  ) : (
    <Loader center img={Spinner} />
  );
};

export default OrganizationalDashboard;
