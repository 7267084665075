import { useForm, Controller } from "react-hook-form";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../Forms/Controls/Button/Button";
import React, { useContext, useState } from "react";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_DOMAIN } from "../CustomerProvisioningApi";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";

const AddDomain = ({ customerID }) => {
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;
  const [createNewDomain] = useMutation(CREATE_NEW_DOMAIN, {
    refetchQueries: ["get_customers"],
  });
  const { closeModal } = useContext(ModalContext);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    createNewDomain({
      variables: {
        customer_id: customerID,
        domain: data.domain,
      },
    })
      .then((res) => {
        setIsSubmittingForm(false);
        closeModal();
        showToast({
          message: res.data?.cnc_add_domain?.msg,
        });
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={"domain"}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <TextInput
              type={"text"}
              value={value}
              data-testid={"new-domain"}
              labelTop
              onBlur={onBlur}
              name={"domain"}
              onChange={(e) => {
                onChange(e);
              }}
              label={"New Domain"}
              size="l"
              inputStyle={"bomba-style"}
              error={errors.domain ? errors.domain : null}
            />
          );
        }}
        control={control}
        rules={{
          required: "Required",
        }}
      />
      <ModalButtonsContainer>
        <Button
          type="submit"
          label={"Save"}
          data-testid={"add-domain"}
          isSecondary
          rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
          isSubmitting={isSubmittingForm}
          disabled={!isValid || !isDirty || isSubmittingForm}
        />
      </ModalButtonsContainer>
    </form>
  );
};
export default AddDomain;
