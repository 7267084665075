import React from "react";
import "./TicketStatus.scss";
import { ticketStatus } from "../../../../../Ticketing/TicketingUtils";
import Badge from "../../../../../Components/Badge/Badge";

const TicketStatus = ({ status, className = "", withLabel = true }) => {
  const ticketStatusItem = ticketStatus?.[status];

  return (
    <Badge
      text={ticketStatusItem?.text}
      icon={ticketStatusItem?.icon}
      withLabel={withLabel}
      className={`${className} ${status?.toLowerCase()}`}
    />
  );
};

export default TicketStatus;
