import { useQuery } from "@apollo/client";
import { GET_AGGREGATED_GROUP } from "./AggregatedFindingsApi";
import React, { useContext, useEffect, useState } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";

export const AggregatedFindingsContext = React.createContext({});

const AggregatedFindingsProvider = ({ children }) => {
  const {
    detailsPaneData: { id, aggregation_group_id },
  } = useContext(DetailsPaneContext);

  const [relatedFindings, setRelatedFindings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [aggregationGroup, setAggregationGroup] = useState(null);

  const { data, loading } = useQuery(GET_AGGREGATED_GROUP, {
    variables: {
      id: id,
      first: 20,
      last: 20,
      after: "",
      sort: ["SYSTEM_STATUS_KEY_ASC"],
    },
    skip: !aggregation_group_id,
  });

  useEffect(() => {
    setAggregationGroup(null);
  }, [id, aggregation_group_id]);

  useEffect(() => {
    if (!!data) {
      setTotalCount(data?.finding?.aggregation_group.findings_count);
      setRelatedFindings(data?.finding?.aggregation_group.findings.edges);
      setAggregationGroup({
        findings_count: data?.finding?.aggregation_group.findings_count,
        open_findings_count:
          data?.finding?.aggregation_group.open_findings_count,
      });
    }
  }, [data]);

  return (
    <AggregatedFindingsContext.Provider
      value={{
        relatedFindings: relatedFindings,
        totalCount: totalCount,
        isLoadingFindings: loading,
        aggregationGroup: aggregationGroup,
      }}
    >
      {children}
    </AggregatedFindingsContext.Provider>
  );
};

export default AggregatedFindingsProvider;
