import Popover from "react-popover";
import React, { useMemo, useState } from "react";
import Tree from "../../../Components/Tree/Tree";
import {
  getGroupsTree,
  updateChildrenWithParentId,
} from "../../../Components/Tree/TreeUtils";
import ScopeManagementDropdownContainer from "../ScopeManagementDropdownContainer";
import "./LocationsTree.scss";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import FormFieldError from "../../../Forms/Controls/FormFieldError/FormFieldError";

const LocationsTree = ({
  value,
  onChange,
  label,
  root,
  isLoading,
  wrapperClassName,
  size,
  labelTop,
  error,
  popoverClassName,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const { t } = useTranslation();

  const onSelectScope = (node) => {
    toggleIsOpen(false);
    onChange(node);
  };

  const popoverBody = useMemo(() => {
    let groupsTree = root;
    if (!!root) {
      const newTree = updateChildrenWithParentId(root, null);
      groupsTree = getGroupsTree(newTree);
    }
    return (
      <Tree
        data={groupsTree}
        nodeOperations={{
          onEdit: () => {},
          onSelect: onSelectScope,
          onWarning: () => {},
        }}
        isSelectableRoot
        data-testid={"resources-tree"}
        selectedNode={value?.id}
        className={`dropdown-tree`}
      />
    );
  }, [root, value]);

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-select",
    wrapperClassName && wrapperClassName,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-size-custom": size === "custom",
    }
  );

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[popoverBody]}
      enterExitTransitionDurationMs={0}
      className={`group-location-select ${
        popoverClassName && popoverClassName
      }`}
    >
      <div
        className={`${classNameWrapper}`}
        data-testid={`scope-location-management`}
      >
        {!!label && <label className={`input-label`}>{`${t(label)}`}</label>}
        <div
          className={"locations-tree-wrapper"}
          onClick={() => toggleIsOpen(!isOpen)}
        >
          <ScopeManagementDropdownContainer
            readOnly={false}
            isLoadingScopesData={isLoading}
            withTitle={false}
            isNewScope={false}
            dropdownIndicator={"seem-icon-dropdown-arrow"}
            selectedScope={{
              label: value?.name,
              value: { id: value?.id, type: value?.type },
            }}
            onClick={() => {
              toggleIsOpen(!isOpen);
            }}
            placeholder={"Choose Parent"}
            isDirty={false}
            disabled={false}
          />
        </div>
        <div className="error-holder">
          {error && (
            <FormFieldError
              errorMessage={error.message}
              errorType={error.type}
              isOpen={!isOpen}
            />
          )}
        </div>
      </div>
    </Popover>
  );
};

export default LocationsTree;
