import React from "react";
import CheckBox from "../CheckBox/CheckBox";
import { difference, union } from "lodash";
import { useTranslation } from "react-i18next";
import "./CheckBoxList.scss";
import ClassNames from "classnames";
import FormFieldError from "../FormFieldError/FormFieldError";
import Loader from "../../../Components/Loader/Loader";
import noResultsImg from "../../../icons/images/no-saved-filters.svg";
import Spinner from "../../../images/loader.svg";

const CheckBoxList = ({
  options,
  value,
  onChange,
  label,
  disabled,
  inputStyle,
  direction = "row",
  dateSelector,
  withBorder,
  error,
  className,
  "data-testid": dataTestId = "",
  loading,
}) => {
  const { t } = useTranslation();
  const handleChange = (cbValue, isChecked) => {
    let newValue = [];

    if (Array.isArray(cbValue)) {
      //for actual_status
      if (isChecked) {
        newValue = union(value, cbValue);
      } else {
        newValue = difference(value, cbValue);
      }

      onChange(newValue);
    } else {
      const currentValue = value || [];
      if (isChecked) {
        onChange([...currentValue, cbValue]);
      } else {
        onChange(currentValue?.filter((cbVal) => cbVal !== cbValue) || []);
      }
    }
  };

  const classNameWrapper = ClassNames(
    "input-wrap",
    "checkbox-list-wrapper",
    direction,
    dateSelector ? "checkbox-list-wrapper-dateselect" : "",
    inputStyle && inputStyle
  );

  return (
    <div className={classNameWrapper}>
      {!!label && (
        <label className={`checkbox-list-label input-label`}>{`${t(
          label
        )}`}</label>
      )}
      {loading ? (
        <Loader center img={Spinner} />
      ) : !!options?.length ? (
        <div
          className={`checkbox-list-options-container ${
            withBorder ? "with-border" : ""
          } ${direction}`}
        >
          {options?.map((option, index) => (
            <div
              key={`cb-item-${index}`}
              className={`checkbox-list-option`}
              title={option?.label}
            >
              <CheckBox
                data-testid={`${option.value}${
                  !!dataTestId ? `-${dataTestId}` : ""
                }`}
                {...option}
                className={className}
                // disabled={disabled}
                // label={option.label}
                checked={value?.includes(
                  Array.isArray(option.value) ? option.value?.[0] : option.value
                )}
                // value={option.value}
                onChange={handleChange}
              />
            </div>
          ))}
        </div>
      ) : (
        <Loader
          className={"no-results"}
          flexcenter
          img={noResultsImg}
          subtitle={`No Results`}
        />
      )}
      {error && (
        <FormFieldError errorMessage={error.message} errorType={error.type} />
      )}
    </div>
  );
};

export default CheckBoxList;
