import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

const SIZER_STYLES = {
  position: "absolute",
  width: 0,
  height: 0,
  visibility: "hidden",
  overflow: "scroll",
  whiteSpace: "pre",
};

const STYLE_PROPS = [
  "fontSize",
  "fontFamily",
  "fontWeight",
  "fontStyle",
  "letterSpacing",
];

const TagsSelectInput = (
  {
    autoResize,
    autofocus,
    query,
    expandable,
    placeholder,
    listboxId,
    selectedIndex,
  },
  ref
) => {
  const [inputWidth, setInputWidth] = useState(null);
  const input = useRef();
  const sizer = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current.focus();
    },
  }));

  const copyInputStyles = () => {
    const inputStyle = window.getComputedStyle(input.current);
    STYLE_PROPS.forEach((prop) => {
      sizer.current.style[prop] = inputStyle[prop];
    });
  };

  useEffect(() => {
    let inputWidth;
    if (autoResize) {
      // scrollWidth is designed to be fast not accurate.
      // +2 is completely arbitrary but does the job.
      inputWidth = Math.ceil(sizer.current.scrollWidth) + 2;
    }
    if (inputWidth !== inputWidth) {
      setInputWidth(inputWidth);
    }
  }, [autoResize, setInputWidth]);

  useEffect(() => {
    if (autoResize) {
      copyInputStyles();
    }

    if (input.value !== query) {
      input.current.value = query;
    }

    if (autofocus) {
      input.current.focus();
    }
  }, [autofocus, autoResize, query]);

  const sizerText = query || placeholder;

  const selectedId = `${listboxId}-${selectedIndex}`;

  return (
    <div className="tags-select__search-input">
      <input
        ref={input}
        role="combobox"
        aria-autocomplete="list"
        aria-label={placeholder}
        aria-owns={listboxId}
        aria-activedescendant={selectedIndex > -1 ? selectedId : null}
        aria-expanded={expandable}
        placeholder={placeholder}
        style={{ width: inputWidth }}
      />
      <div ref={sizer} style={SIZER_STYLES}>
        {sizerText}
      </div>
    </div>
  );
};

export default forwardRef(TagsSelectInput);
