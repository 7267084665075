import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./UploadFile.scss";
import Button from "../Button/Button";

const UploadFile = ({
  disabled,
  onChange,
  "data-testid": dataTestId,
  accept,
  wrapperClassName,
}) => {
  const { t } = useTranslation();
  const BROWSE_DOCUMENT = t("Browse Document");
  const [uploadFileLabel, setUploadFileLabel] = useState(BROWSE_DOCUMENT);

  const onInputChange = (e) => {
    const newFile = e.target?.files[0];
    if (e.target?.files.length > 0) {
      setUploadFileLabel(newFile.name);
    } else {
      setUploadFileLabel(BROWSE_DOCUMENT);
    }
    e.target.value = null;
    onChange(newFile);
  };

  const deleteFile = () => {
    setUploadFileLabel(BROWSE_DOCUMENT);
    onChange(undefined);
  };

  return (
    <div className={`${wrapperClassName} ${disabled ? "is-disabled" : ""}`}>
      <label htmlFor="file" className="file-input-label">
        <i className={`seem-icon seem-icon-browse`} />
        {uploadFileLabel}
      </label>
      {uploadFileLabel !== BROWSE_DOCUMENT ? (
        <Button
          disabled={disabled}
          wrapperClassName={`remove-file`}
          type="button"
          isIcon="seem-icon-bin"
          isClean
          onClick={deleteFile}
        />
      ) : (
        <></>
      )}
      <input
        id={"file"}
        className={"input-file"}
        data-testid={`${dataTestId}-file-input`}
        type="file"
        onChange={(e) => onInputChange(e)}
        accept={accept}
      />
    </div>
  );
};

UploadFile.defaultProps = {
  handleFileInput: () => {},
  accept: "*",
  disabled: false,
};

UploadFile.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  handleFileInput: PropTypes.func,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UploadFile;
