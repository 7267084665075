import React from "react";
import useModal from "./useModal";
import { ModalContext } from "./ModalContext";
import { Modal } from "./Modal";

const ModalProvider = ({ children }) => {
  const {
    isOpen,
    openModal,
    closeModal,
    modalContent,
    hideCloseButton,
    showCloseButton,
  } = useModal();

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        modalContent,
        hideCloseButton,
        showCloseButton,
      }}
    >
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
