import React, { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import ActionsMenu from "../../../../../Components/ActionsMenu/ActionsMenu";
import Button from "../../../../../Forms/Controls/Button/Button";
import ScopeManagementMenuItem from "../ScopeManagementMenuItem/ScopeManagementMenuItem";
import CreateGroup from "../../CreateGroup/CreateGroup";
import { ModalContext } from "../../../../../Forms/Dialogs/Modal/ModalContext";
import ScopesList from "../../ScopesList/ScopesList";
import "./ScopeManagementMenu.scss";
import { ScopeManagementContext } from "../useScope";
import {
  scopePanelMode,
  ScopesContext,
} from "../../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { GeneralAppContext } from "../../../../../General/GeneralAppProvider";
import {
  AppPreferencesContext,
  userActionsEnum,
} from "../../../../../General/AppPreferencesProvider";

const ScopeManagementMenu = ({ editable }) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const { toggleIsOpen } = useContext(ScopeManagementContext);
  const {
    scopesState: { scopesList },
    updateScopeState,
    searchTree,
  } = useContext(ScopesContext);
  const scopeManagementMenuRef = useRef(null);
  const { isAllowed } = useContext(AppPreferencesContext);

  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const getMenuItems = useMemo(() => {
    const menuItems = [];
    menuItems.push({
      text: "Group",
      onClick: () => {
        toggleIsOpen(false);
        openModal({
          title: "Create a Group",
          icon: "seem-icon-scope-group",
          size: "s",
          overlay: true,
          component: (
            <CreateGroup
              root={!!scopesList ? scopesList : null}
              resetFindings={resetFindingsList}
              updateScopeState={updateScopeState}
              searchTree={searchTree}
            />
          ),
        });
      },
      component: (
        <ScopeManagementMenuItem
          data-testid={"create-group-menu-item"}
          title={"Group"}
          subTitle={"Contains multiple items which you want to group together"}
          icon={`seem-icon-scope-group`}
        />
      ),
    });

    menuItems.push({
      text: "Scope",
      onClick: () => {
        updateScopeState({ mode: scopePanelMode.SETTINGS, isNewScope: true });
      },
      component: (
        <ScopeManagementMenuItem
          data-testid={"create-scope-menu-item"}
          title={"Scope"}
          subTitle={
            "Contains collection of resources that have security findings associated with it"
          }
          icon={`seem-icon-scope`}
        />
      ),
    });

    return menuItems;
  }, [scopesList]);

  return (
    <div
      ref={scopeManagementMenuRef}
      className={`scope-management-menu-wrapper`}
    >
      <div className={`scope-management-header`}>
        <div className={`scope-management-header-items`}>
          <div className={`scopes-tree-header`}>Scopes</div>
          {isAllowed(userActionsEnum.CREATE_SCOPE) && editable && (
            <ActionsMenu
              tipSize={0.01}
              menuItems={getMenuItems}
              className={`scope-management-action-menu`}
            >
              <Button
                rightIcon="seem-icon-chevron-down"
                label={t("Create New")}
                isInvert
                wrapperClassName={`create-new-scope-btn`}
                data-testid={"create-scope"}
              />
            </ActionsMenu>
          )}
        </div>
        <div className={`scope-management-header-separator`} />
      </div>

      <div className={`scope-management-content`}>
        <ScopesList editable={editable} />
      </div>
    </div>
  );
};

export default ScopeManagementMenu;
