import React, { createContext, useCallback, useReducer } from "react";
import { resourceTypes } from "../../../Findings/FindingsUtils";

export const scopePanelMode = {
  MENU: "MENU",
  SETTINGS: "SETTINGS",
  CLOSED: "CLOSED",
};

export const scopesInitialValues = {
  scopesList: null,
  selectedScope: null,
  fieldsData: [],
  fieldValues: [],
  defaultScope: null,
  scopeToExecute: null,
  mode: scopePanelMode.MENU,
  isNewScope: false,
  isDirty: false,
};

export const ScopesContext = createContext(scopesInitialValues);

const ScopeProvider = ({ children }) => {
  const [scopesState, updateScopeState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    scopesInitialValues
  );

  function searchTree(node, id) {
    if (node?.id?.toString() === id) {
      return node;
    } else if (node?.children?.length) {
      let result = null;
      for (let i = 0; result == null && i < node?.children?.length; i++) {
        result = searchTree(node.children[i], id);
      }
      return result;
    }
    return null;
  }

  const getScopeValueById = useCallback(
    (scopeId) => {
      if (scopesState?.scopesList?.[0]) {
        const scope = searchTree(scopesState?.scopesList?.[0], scopeId);

        if (!!scope) {
          const selectedScope = {
            label: scope.name,
            value: {
              id: scope.id,
              editable: scope.editable,
              type: scope.type,
            },
          };

          if (scope.type === resourceTypes.SCOPE) {
            selectedScope.value.query = JSON.stringify(scope.filter_data);
          }

          return selectedScope;
        }
      }

      return null;
    },
    [scopesState?.scopesList]
  );

  const scopesContextValue = React.useMemo(
    () => ({
      scopesState,
      updateScopeState,
      getScopeValueById,
      searchTree,
    }),
    [scopesState]
  );

  return (
    <ScopesContext.Provider value={scopesContextValue}>
      {children}
    </ScopesContext.Provider>
  );
};

export default ScopeProvider;
