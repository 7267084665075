import React, { useState } from "react";
import FormFieldError from "../FormFieldError/FormFieldError";
import PropTypes from "prop-types";
import "./CheckBox.scss";
import ReactTooltip from "react-tooltip";

const CheckBox = ({
  checked,
  label,
  error,
  className,
  name,
  onChange,
  value,
  indeterminate,
  disabled,
  icon,
  isToggle,
  withHelp,
  onBlur,
  onFocus,
  inputRef,
  "data-testid": dataTestId,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (cbValue, isChecked) => {
    onChange(cbValue, isChecked);
  };

  const onFocusChange = () => {
    if (isFocused) {
      if (onBlur) {
        onBlur();
      }
    } else {
      if (onFocus) {
        onFocus();
      }
    }
    setIsFocused(!isFocused);
  };

  return (
    <div
      className={`input-wrap checkbox-input-wrap ${
        checked
          ? "is-checked"
          : indeterminate
          ? "is-indeterminate"
          : "is-unchecked"
      } ${className ? className : ""} ${
        isToggle ? "toggle-style" : "checkbox-style"
      } ${disabled ? "is-disabled" : ""}`}
    >
      <input
        data-testid={`${dataTestId}-checkbox`}
        className={`input-label checkbox-input`}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e) => handleChange(value, e.target.checked)}
        onBlur={onFocusChange}
        onFocus={onFocusChange}
        ref={inputRef}
      />
      {isToggle && <div className="checkbox-toggle" />}
      <label htmlFor={name} className="checkbox-label">
        <div className={`checkbox-label-data`}>
          {!!icon && <i className={`seem-icon ${icon}`} />}
          {label}
        </div>
      </label>

      {withHelp && (
        <div className="input-help-text">
          <ReactTooltip
            id={`${name}-help`}
            place="top"
            className={"input-help-tooltip"}
          >
            {withHelp}
          </ReactTooltip>
          <i
            data-tip
            data-for={`${name}-help`}
            className={`seem-icon seem-icon-help`}
          />
        </div>
      )}
      {error && (
        <FormFieldError errorMessage={error.message} errorType={error.type} />
      )}
    </div>
  );
};

CheckBox.defaultProps = {
  checked: false,
  isToggle: false,
  label: "",
  onChange: () => {},
  wrapperClassName: "",
  withHelp: "",
  onBlur: () => {},
  onFocus: () => {},
};

CheckBox.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  checked: PropTypes.bool,
  isToggle: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  withHelp: PropTypes.string,
};

export default CheckBox;
