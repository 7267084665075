import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Tree.scss";
import TreeNode from "./TreeNode/TreeNode";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import {
  search,
  TristateStateEnum,
  getNodeById,
  updateChildrenWithParentId,
} from "./TreeUtils";
import { useDebounce } from "react-use";
import FormFieldError from "../../Forms/Controls/FormFieldError/FormFieldError";
import Loader from "../Loader/Loader";
import noResultsImg from "../../icons/images/no-results.svg";
import Spinner from "../../images/loader.svg";

const Tree = ({
  data = null,
  nodeOperations,
  isSearchable,
  isSelectableRoot,
  "data-testid": dataTestId,
  editableItems,
  selectedNode,
  withCheckbox,
  error,
  tristate = false,
  onChange,
  initialExpandLevel = 1,
  placeholder,
  preCheckedNodes,
  className,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [treeData, setTreeData] = useState(null);
  const [manipulatedData, setManipulatedData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const [checkedNodes, setCheckedNodes] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);

  useEffect(() => {
    if (!!data) {
      const newTree = updateChildrenWithParentId(data, null);
      setManipulatedData(newTree);
    }
  }, [data]);

  useEffect(() => {
    if (!!preCheckedNodes?.length && !!treeData) {
      const updatedNodes = preCheckedNodes.map((node) => {
        return { ...node, ...getNodeById(treeData[0], node.id) };
      });
      handleNodeCheck(updatedNodes, checkedNodes);
    }
  }, [preCheckedNodes, treeData]);

  useEffect(() => {
    if (!!selectedNode && !!manipulatedData) {
      const selectedNodeBranch = getSelectedNodeBranch(selectedNode, []);
      setExpandedNodes(selectedNodeBranch);
    }
  }, [manipulatedData, selectedNode]);

  const getSelectedNodeBranch = (nodeId, branch) => {
    if (!nodeId) {
      return branch;
    }

    const node = getNodeById(manipulatedData[0], nodeId);
    const parent = node?.parentId;

    if (!!parent) {
      branch.push(parent);
    }

    return getSelectedNodeBranch(parent, branch);
  };

  useDebounce(
    () => {
      if (!!searchValue) {
        const res = search(manipulatedData, searchValue);
        setTreeData(res);
        setIsSearching(false);
      } else {
        setTreeData(manipulatedData);
        setIsSearching(false);
      }
    },
    200,
    [searchValue, manipulatedData]
  );

  const noDataState = useMemo(
    () => (
      <div className={"tree-nodes-wrap no-data"}>
        <Loader
          flexcenter
          img={noResultsImg}
          title={"No Scopes or Groups Found"}
          subtitle={"Try adjusting your search"}
        />
      </div>
    ),
    []
  );

  const loadingState = useMemo(
    () => (
      <div className={"tree-nodes-wrap is-loading"}>
        <Loader center img={Spinner} />
      </div>
    ),
    []
  );

  const handleNodeCheck = useCallback(
    (nodes, currChecked = []) => {
      const setParent = (parentId, currNodes) => {
        if (!parentId) {
          return currNodes;
        }

        const nodesCopy = currNodes.slice();
        const parent = !!treeData && getNodeById(treeData[0], parentId);
        let newNodes;
        if (!!parent) {
          if (
            parent.children.every((child) =>
              currNodes.find(
                (checkedNode) =>
                  checkedNode.id === child.id &&
                  checkedNode.checked === TristateStateEnum.CHECKED
              )
            )
          ) {
            newNodes = setChecked([parent], nodesCopy);
          } else if (
            parent.children.some((child) =>
              currNodes.find((checkedNode) => checkedNode.id === child.id)
            )
          ) {
            newNodes = setIndeterminate(parent, nodesCopy);
          } else if (
            parent.children.every(
              (child) =>
                !currNodes.find(
                  (checkedNode) =>
                    checkedNode.id === child.id &&
                    checkedNode.checked === TristateStateEnum.CHECKED
                )
            )
          ) {
            newNodes = setUnchecked([parent], nodesCopy);
          }

          newNodes = setParent(parent.parentId, newNodes);
          return newNodes;
        }
      };

      const setChecked = (nodes, currCheckedNodes) => {
        return nodes?.reduce((acc, node) => {
          const nodeCheckedIndex = acc.findIndex(
            (checkedNode) => checkedNode.id === node.id
          );
          if (nodeCheckedIndex === -1) {
            acc.push({ ...node, checked: TristateStateEnum.CHECKED });
          } else {
            acc[nodeCheckedIndex] = {
              ...node,
              checked: TristateStateEnum.CHECKED,
            };
          }

          if (tristate && node?.children?.length) {
            let newItems = setChecked(node?.children, acc);
            acc = [
              ...acc,
              ...newItems.filter(
                (node) => !acc.some((newNode) => newNode.id === node.id)
              ),
            ];
          }

          return acc;
        }, currCheckedNodes);
      };

      const setUnchecked = (nodes, currCheckedNodes) => {
        return nodes?.reduce((acc, node) => {
          const nodeCheckedIndex = acc.findIndex(
            (checkedNode) => checkedNode.id === node.id
          );
          if (nodeCheckedIndex > -1) {
            acc.splice(nodeCheckedIndex, 1);
          }

          if (tristate && node?.children?.length) {
            let newItems = setUnchecked(node?.children, acc);
            acc = newItems.filter((node) =>
              newItems.some((newNode) => newNode.id === node.id)
            );
          }

          return acc;
        }, currCheckedNodes);
      };

      const setIndeterminate = (node, currNodes) => {
        const nodesCopy = currNodes.slice();
        const nodeCheckedIndex = nodesCopy.findIndex(
          (checkedNode) => checkedNode.id === node.id
        );
        if (nodeCheckedIndex === -1) {
          nodesCopy.push({ ...node, checked: TristateStateEnum.INDETERMINATE });
        } else {
          nodesCopy[nodeCheckedIndex] = {
            ...node,
            checked: TristateStateEnum.INDETERMINATE,
          };
        }

        return nodesCopy;
      };

      let checkedNodesCopy = currChecked.slice();
      const toggle = nodes.length === 1;
      nodes.forEach((node) => {
        if (
          checkedNodesCopy.find(
            (checkedNode) =>
              checkedNode.id === node.id &&
              checkedNode.checked === TristateStateEnum.CHECKED
          ) &&
          toggle
        ) {
          checkedNodesCopy = setUnchecked([node], checkedNodesCopy.slice());
        } else {
          checkedNodesCopy = setChecked([node], checkedNodesCopy.slice());
        }

        checkedNodesCopy = tristate
          ? setParent(node.parentId, checkedNodesCopy.slice())
          : checkedNodesCopy.slice();
      });

      onChange(
        checkedNodesCopy.filter(
          (el) => el.checked === TristateStateEnum.CHECKED
        )
      );

      setCheckedNodes(checkedNodesCopy);
    },
    [treeData]
  );

  return (
    <>
      <div className={`tree-wrapper ${className}`} data-testid={dataTestId}>
        {isSearchable && (
          <TextInput
            data-testid={"tree-search"}
            value={searchValue}
            placeholder={placeholder || "Type to search..."}
            onChange={(e) => setSearchValue(e.target.value || "")}
            rightIcon={isSearching ? "seem-icon-spinner spinner" : ""}
          />
        )}
        {!!treeData && treeData.length ? (
          <div className={"tree-nodes-wrap"}>
            {treeData.map((node) => {
              return (
                <TreeNode
                  {...node}
                  checked={"false"}
                  nodeData={node}
                  parentId={node.parentId}
                  level={0}
                  nodeOperations={{
                    ...nodeOperations,
                    onCheck: handleNodeCheck,
                  }}
                  searchValue={searchValue}
                  isSelectableRoot={isSelectableRoot}
                  data-testid={`tree-node-${node.name}`}
                  editableNodes={editableItems}
                  selectedNode={selectedNode}
                  isLastChild={true}
                  withCheckbox={withCheckbox}
                  checkedNodes={checkedNodes}
                  initialExpandLevel={initialExpandLevel}
                  expandedNodes={expandedNodes}
                />
              );
            })}
          </div>
        ) : !!treeData && !treeData.length ? (
          noDataState
        ) : (
          loadingState
        )}
      </div>
      {error && (
        <FormFieldError errorMessage={error.message} errorType={error.type} />
      )}
    </>
  );
};

export default Tree;
