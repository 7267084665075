import React from "react";
import "./Loader.scss";
import PropTypes from "prop-types";
import Button from "../../Forms/Controls/Button/Button";

const Loader = ({
  flexcenter,
  center,
  text,
  title,
  subtitle,
  icon,
  action,
  secondaryAction,
  img,
  placeholder,
  className,
}) => {
  return (
    <div
      className={`loader-wrap ${className ? className : ""} ${
        center ? "is-center" : ""
      } ${flexcenter ? "flexcenter" : ""} ${
        placeholder ? "is-placeholder" : ""
      }`}
      data-testid={title || text ? "no-data" : "loader"}
    >
      <div className="loader">
        {img ? (
          <div className="loader-icon">
            <img src={img} />
          </div>
        ) : (
          ""
        )}
        {icon ? (
          <div className="loader-icon-wrap">
            <i className={`seem-icon ${icon}`} />
          </div>
        ) : (
          ""
        )}
        {text ? <div className="loader-text">{text ? text : ""}</div> : ""}
        {title ? <div className="title-text">{title ? title : ""}</div> : ""}
        {subtitle ? (
          <div className="subtitle-text">{subtitle ? subtitle : ""}</div>
        ) : (
          ""
        )}
        {!!secondaryAction || !!action ? (
          <div className={"form-button-group"}>
            {!!secondaryAction && (
              <Button
                data-testid={"loader-action"}
                wrapperClassName={"loader-action"}
                label={secondaryAction.label}
                isxl
                onClick={secondaryAction.onClick}
              />
            )}
            {!!action && (
              <Button
                data-testid={"loader-action"}
                wrapperClassName={"loader-action"}
                isSecondary
                label={action.label}
                isxl
                onClick={action.onClick}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

Loader.propTypes = {
  center: PropTypes.bool,
  flexcenter: PropTypes.bool,
  placeholder: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.object,
  text: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.object,
  secondaryAction: PropTypes.object,
  className: PropTypes.string,
};

export default Loader;
