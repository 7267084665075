import React, { useEffect, useRef, useState } from "react";
import RadioButtonsList from "../../../Forms/Controls/RadioButtonsList/RadioButtonsList";
import TimePicker, {
  initialTime,
} from "../../../Forms/Controls/TimePicker/TimePicker";
import CheckBoxList from "../../../Forms/Controls/CheckBoxList/CheckBoxList";
import { startCase, capitalize } from "lodash";
import cb from "cron-builder";
import "./ScheduleSettings.scss";
import { weekDays } from "../ScheduleUtils";
import { formatTime } from "../Schedule";
import { useClickAway } from "react-use";
import Button from "../../../Forms/Controls/Button/Button";
import moment from "moment";

const repeatValues = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
};

const numOfOptions = {
  WEEK: 7,
  MONTH: 31,
};

const ScheduleSettings = ({
  onChange,
  value,
  toggleSchedule,
  setToggleSchedule,
}) => {
  const [repeat, setRepeat] = useState(repeatValues.DAY);
  const [onTime, setOnTime] = useState([]);
  const [atTime, setAtTime] = useState(initialTime);
  const selectWrapperRef = useRef();

  useClickAway(
    selectWrapperRef,
    (e) => {
      if (toggleSchedule && !e?.target?.className?.includes("select__option")) {
        setToggleSchedule(false);
      }
    },
    ["click"]
  );

  const getOptions = (type, maxValue) => {
    const options = [];
    for (let i = 1; i <= maxValue; i++) {
      options.push({
        label: getOptionLabel(type, i),
        value: [i],
      });
    }

    return options;
  };

  const getOptionLabel = (type, option) => {
    if (type === repeatValues.WEEK) {
      return startCase(weekDays[option]);
    } else {
      return option;
    }
  };

  useEffect(() => {
    if (!!value) {
      const cronExp = new cb(value);
      const cronExpData = cronExp.getAll();
      let cronTime = null;
      if (cronExpData?.hour?.[0] || cronExpData?.minute?.[0]) {
        const localHour = moment(cronExpData?.hour?.[0].toString(), "hh:mm")
          .add(moment().utcOffset(), "minutes")
          .format("HH");

        cronTime = {
          hour:
            {
              label: localHour,
              value: parseInt(localHour),
            } || null,
          minutes:
            {
              label: formatTime(cronExpData?.minute?.[0]),
              value: parseInt(cronExpData?.minute?.[0]),
            } || null,
        };
      }

      if (cronExpData?.dayOfTheMonth?.[0] !== "*") {
        setRepeat(repeatValues.MONTH);
        setOnTime(
          cronExpData?.dayOfTheMonth?.[0]
            ?.split(",")
            .map((day) => parseInt(day))
        );
        setAtTime(cronTime);
      } else if (cronExpData?.dayOfTheWeek?.[0] !== "*") {
        setRepeat(repeatValues.WEEK);
        setOnTime(
          cronExpData?.dayOfTheWeek?.[0]?.split(",").map((day) => parseInt(day))
        );
        setAtTime(cronTime);
      } else if (cronExpData?.hour || cronExpData?.minute) {
        setRepeat(repeatValues.DAY);
        setAtTime(cronTime);
      }
    }
  }, []);

  useEffect(() => {
    if (!!atTime || !!onTime?.length || !!repeat) {
      handleChange();
    }
  }, [atTime, onTime, repeat]);

  const handleChange = () => {
    let cronExp = new cb();
    if (atTime?.hour) {
      const utcHour = moment(atTime?.hour?.value?.toString(), "hh:mm")
        .subtract(moment().utcOffset(), "minutes")
        .format("HH");
      cronExp.set("hour", [utcHour]);
    }
    // atTime?.hour && cronExp.set("hour", [atTime?.hour?.value?.toString()]);
    atTime?.minutes &&
      cronExp.set("minute", [atTime?.minutes?.value?.toString()]);
    if (repeat === repeatValues.WEEK && onTime?.length) {
      cronExp.set(
        "dayOfTheWeek",
        onTime?.map((item) => item.toString())
      );
    }
    if (repeat === repeatValues.MONTH && onTime?.length) {
      cronExp.set(
        "dayOfTheMonth",
        onTime?.map((item) => item.toString())
      );
    }

    const cronString = cronExp.build();
    onChange(cronString);
  };

  const getInitialOnTime = (repeat) => {
    switch (repeat) {
      case repeatValues.DAY:
        setOnTime([]);
        break;

      case repeatValues.WEEK:
        setOnTime([2]);
        break;

      case repeatValues.MONTH:
        setOnTime([1]);
        break;

      default:
        setOnTime([]);
    }
  };

  return (
    <div
      ref={selectWrapperRef}
      // onClick={(e) => {
      //   e.stopPropagation();
      // }}
      className={`schedule-settings-wrapper`}
    >
      <div className={`schedule-settings-every schedule-settings-container`}>
        <div className={`repeat-every-title schedule-part-title`}>
          Sync every
        </div>
        <RadioButtonsList
          options={Object.keys(repeatValues).map((item) => {
            return { label: capitalize(item.toLowerCase()), value: item };
          })}
          name={`schedule-repeat`}
          label={""}
          onChange={(val) => {
            getInitialOnTime(val);
            if (!atTime) {
              setAtTime(initialTime);
            }
            // setAtTime(null);
            setRepeat(val);
          }}
          value={repeat}
        />
      </div>
      {repeat && (
        <>
          {repeat !== repeatValues.DAY && (
            <div className={`schedule-settings-on schedule-settings-container`}>
              <div className={`repeat-every-title schedule-part-title`}>
                Select days
              </div>

              <CheckBoxList
                direction={"row"}
                dateSelector={repeat === repeatValues.MONTH}
                value={onTime}
                options={getOptions(repeat, numOfOptions[repeat])}
                onChange={(times) => {
                  setOnTime(times);
                }}
              />
            </div>
          )}
          <div className={`schedule-settings-time schedule-settings-container`}>
            <div className={`repeat-every-title schedule-part-title`}>
              Select time
            </div>
            <TimePicker
              onChange={(time) => {
                setAtTime(time);
              }}
              value={atTime}
            />
          </div>
        </>
      )}
      <div
        className={`schedule-settings-container schedule-settings-container-footer`}
      >
        <Button
          data-testid={"confirm-schedule"}
          label={"OK"}
          onClick={() => setToggleSchedule(false)}
        />
      </div>
    </div>
  );
};

export default ScheduleSettings;
