import React, { useEffect, useState, useCallback } from "react";
import { GET_TICKET_ENDPOINT_KEYS } from "../TicketingAPI";
import { useQuery } from "@apollo/client";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import GenericField from "../GenericField/GenericField";
import useSections from "../useSections/useSections";
import "./TicketEndpointKeys.scss";
import TicketEndpointPlaceholder from "../TicketEndpointPlaceholder/TicketEndpointPlaceholder";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";

const TicketEndpointKeys = ({
  disabled = false,
  endpointId = null,
  onFinalPhase = () => {},
  onGetProps,
  keysReady,
  keysObject,
  showPlaceholder = true,
}) => {
  const [fieldsSections, setFieldsSections] = useState(null);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectedTicketProvider = useWatch({
    name: `ticketProvider`,
  });
  const selectedTicketManager = useWatch({
    name: `ticketManager`,
  });

  const {
    data: ticketEndpointFieldsData,
    error: ticketEndpointFieldsError,
    loading: ticketEndpointFieldsLoading,
  } = useQuery(GET_TICKET_ENDPOINT_KEYS, {
    variables: {
      ticket_provider_id: selectedTicketProvider,
      selected_keys: JSON.stringify(keysObject),
      ticket_endpoint_id: endpointId,
    },
    skip: !selectedTicketProvider || !keysObject,
  });

  useEffect(() => {
    setFieldsSections(null);
  }, [selectedTicketProvider, selectedTicketManager]);

  useEffect(() => {
    if (!!ticketEndpointFieldsData) {
      setFieldsSections(
        ticketEndpointFieldsData?.endpoint_keys?.fields_sections
      );
      onFinalPhase(ticketEndpointFieldsData?.endpoint_keys?.is_final);
    }
  }, [ticketEndpointFieldsData]);

  useEffect(() => {
    if (!!ticketEndpointFieldsError) {
      onFinalPhase(false);
    }
  }, [ticketEndpointFieldsError]);

  const getField = (field) => {
    return field?.isSkeleton &&
      !!keysObject &&
      Object.keys(keysObject).some((key) => field?.precedents?.includes(key)) &&
      !ticketEndpointFieldsError ? (
      <div className="loading-row" data-testid={`keys-loader`} />
    ) : field?.isSkeleton ? (
      <></>
    ) : (
      <Controller
        shouldUnregister={true}
        name={`keys.${field?.name}`}
        render={({
          field: { onChange, onBlur, value, onFocus },
          fieldState: { isTouched },
        }) => {
          const fieldLoading =
            ticketEndpointFieldsLoading &&
            !!keysObject &&
            !Object.keys(keysObject)?.includes(field?.name);
          return (
            <GenericField
              onChange={(val) => {
                onChange(val);
                resetDescendants(field?.descendants);
              }}
              name={field?.name}
              clearable={false}
              searchable={true}
              onBlur={onBlur}
              value={value}
              error={isTouched ? errors?.keys?.[field?.name] : null}
              onFocus={onFocus}
              loading={fieldLoading}
              disabled={disabled || fieldLoading}
              key={field?.name}
              input={field?.input}
              label={field?.label}
              options={field?.options}
              placeholder={`Choose ${field?.label}`}
            />
          );
        }}
        rules={{ ...(field?.required && { required: "Required" }) }}
        defaultValue={field?.defaultValue}
        control={control}
      />
    );
  };

  const { getSections, getFieldPropsByFieldName, sections } = useSections(
    fieldsSections,
    ticketEndpointFieldsError,
    onGetProps,
    getField,
    null,
    selectedTicketProvider ? (
      <div className="loading-row" data-testid={"keys-loader"} />
    ) : undefined,
    <div className={"keys-no-data"} />
  );

  const resetDescendants = useCallback(
    (descendants) => {
      if (!!descendants && !!sections) {
        descendants?.forEach((descendant) => {
          const field = getFieldPropsByFieldName(descendant);
          if (!!field) {
            setValue(`keys.${descendant}`, field?.defaultValue);
          }
        });
        onFinalPhase(false);
      }
    },
    [sections]
  );

  return (
    <div
      className={`endpoint-keys ${
        !!sections && !sections.length ? "no-data" : ""
      }`}
    >
      {getSections()}
      {!selectedTicketManager ? (
        <Loader flexcenter img={Spinner} />
      ) : ticketEndpointFieldsError && showPlaceholder ? (
        <Loader center text="An error occurred." />
      ) : !keysReady && showPlaceholder ? (
        <TicketEndpointPlaceholder
          keysReady={keysReady}
          getFieldPropsByName={getFieldPropsByFieldName}
          onFinalPhase={onFinalPhase}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TicketEndpointKeys;
