import React, { useCallback } from "react";
import RadioButton from "../RadioButton/RadioButton";
import { useTranslation } from "react-i18next";
import "./RadioButtonsList.scss";
import ClassNames from "classnames";

export const CUSTOM_EMPTY = "custom_null";

const RadioButtonsList = ({
  label,
  options,
  disabled,
  value,
  onChange,
  inputStyle,
  "data-testid": dataTestId,
  direction = "row",
  wrapperClassName = "",
}) => {
  const { t } = useTranslation();

  const handleChange = (cbValue) => {
    onChange(cbValue);
  };

  const classNameWrapper = ClassNames(
    "radio-button-list-wrapper",
    "input-wrap",
    direction && direction,
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle
  );

  const isChecked = useCallback(
    (option) => {
      if (!value && value !== "") {
        onChange(options[0]?.value);
        return option.value === options[0]?.value;
        // clicked on "custom" input or selected value is not a part of the list (= custom option should be checked)
      } else if (
        !options?.filter((option) => option.value === value)?.length ||
        value === CUSTOM_EMPTY
      ) {
        return option.value === "custom";
      } else {
        return option.value === value;
      }
    },
    [options, value]
  );

  return (
    <div className={classNameWrapper} data-testid={`${dataTestId}-radio`}>
      {!!label && <label className={`input-label`}>{t(label)}</label>}
      {options.map((option, index) => (
        <div
          key={`radio-item-${index}`}
          className={`radio-button-list-option`}
          title={option?.label}
        >
          <RadioButton
            data-testid={option.value.toLowerCase()}
            disabled={option.disabled || disabled}
            label={option.label}
            checked={isChecked(option)}
            value={option.value}
            onChange={handleChange}
            name={option.name + index}
            leftImg={option.leftImg}
            customOption={option?.customOption}
            selectedValue={value}
            tooltipData={option?.tooltipData}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioButtonsList;
