import React, { useState, useMemo, useCallback } from "react";
import {
  FindingsContext,
  FindingsContextInitialValue,
} from "./FindingsContext";
import Findings from "./Findings";
import { useParams, useRouteMatch } from "react-router-dom";
import DetailsPaneProvider from "./FindingDetailsPane/DetailsPaneProvider";

const FindingsProvider = () => {
  let { id } = useParams();
  let match = useRouteMatch("/:item");

  const [findingsState, setFindingsState] = useState(
    FindingsContextInitialValue
  );

  const updateFindingsState = (state) => {
    setFindingsState({ ...findingsState, ...state });
  };

  const resetFindingsList = useCallback(() => {
    !!findingsState.resetList && findingsState.resetList();
    updateFindingsState({
      dataStructure: new Map(),
      groupsList: null,
    });
  }, [findingsState.resetList]);

  const selectFindingInList = useCallback(
    (finding, toggle) => {
      !!findingsState.onSelectedFinding &&
        findingsState.onSelectedFinding(finding, toggle);
      updateFindingsState({
        hasSelectedItem: toggle,
        selectedFilterRow: finding,
      });
    },
    [findingsState, findingsState.onSelectedFinding]
  );

  const findingsContextValue = useMemo(() => {
    return {
      findingsState,
      updateFindingsState,
      selectFindingInList,
      resetFindingsList,
    };
  }, [findingsState]);

  const getFindingsComponent = useMemo(() => {
    if (!!id && !findingsState.hasSelectedItem) {
      return (
        <DetailsPaneProvider
          isFullScreen={true}
          closeDetailsPane={() => {
            selectFindingInList(null, false);
          }}
        />
      );
    } else {
      return <Findings />;
    }
  }, [findingsState.hasSelectedItem, id, selectFindingInList]);

  return (
    <FindingsContext.Provider value={findingsContextValue}>
      {match?.params?.item === "findings" && getFindingsComponent}
    </FindingsContext.Provider>
  );
};

export default FindingsProvider;
