import React from "react";
import SidebarGroup from "./SidebarGroup/SidebarGroup";
import "./Sidebar.scss";

const Sidebar = ({ data }) => (
  <div className={"sidebar-wrapper"}>
    <div className={"sidebar-title"}>{data.title}</div>
    <div className={"sidebar-menu"}>
      {data?.items.map((item) => (
        <SidebarGroup key={item.reference} data={item} />
      ))}
    </div>
  </div>
);

export default Sidebar;
