import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import { createDateAxis, createValueAxis } from "../TrendsUtils";
import useTrend from "../useTrend";

const SERIES_COLORS = {
  new: "#607aff",
  closed: "#41425a",
  open: "#A56AF1",
};
const RiskPosture = ({
  selectedScope,
  filterToExecute,
  timeScale,
  scopeToExecute,
}) => {
  const {
    trendData: data,
    trendDataError,
    trendLoading,
  } = useTrend({
    variables: {
      selection: ["new", "closed_today", "open"],
      timescale: timeScale,
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute,
        scopesid: selectedScope?.value?.id,
        filters_fields_to_exclude: ["actual_status"],
      },
      aggregate_functions: ["SUM", "SUM", "SUM"],
      round_functions: ["ROUND", "ROUND", "ROUND"],
    },
  });

  const [trendData, setTrendData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();

  const updateDashboardData = (data, dataIndex) => {
    const dataObj = {};

    for (let i = 0; i < data.length; i += 1) {
      dataObj[data[i][0]] = data[i][dataIndex];
    }

    const dataArray = Object.keys(dataObj).map((el, index) => {
      const dataVal = {
        date: el,
        value: dataObj[el],
      };

      if (data?.length >= 2 && index === data?.length - 2) {
        dataVal.dashLength = 8;
      }

      return dataVal;
    });

    return dataArray;
  };

  const clearData = () => {
    setTrendData(null);
  };

  useEffect(() => {
    if (!!trendLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [trendLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!trendDataError) {
      setChartError(true);
      setTrendData({});
      stopLoadingChart();
    }
  }, [trendDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const dataNewArray = updateDashboardData(data, 1);
      const dataClosedArray = updateDashboardData(data, 2);
      const dataOpenArray = updateDashboardData(data, 3);

      if (
        dataNewArray?.length === 0 &&
        dataClosedArray?.length === 0 &&
        dataOpenArray?.length === 0
      ) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (
        dataNewArray?.length ||
        dataClosedArray?.length ||
        dataOpenArray?.length
      ) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      const dataObj = {
        new: dataNewArray,
        closed: dataClosedArray,
        open: dataOpenArray,
      };

      setTrendData(dataObj);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      let [chart, legendContainer] = createChart(
        "RiskPostureTrend",
        am4charts.XYChart
      );

      chart.cursor = new am4charts.XYCursor();

      chart.paddingRight = 30;
      chart.leftAxesContainer.layout = "vertical";

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      createDateAxis(dateAxis, timeScale);
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis, "Findings");
      valueAxis.getSeriesDataItem = function (series, position) {
        const key = this.axisFieldName + this.axisLetter;
        const value = this.positionToValue(position);
        const dataItem = series.dataItems.getIndex(
          series.dataItems.findClosestIndex(
            value,
            (x) => {
              return x[key] ? x[key] : undefined;
            },
            "any"
          )
        );
        return dataItem;
      };
      valueAxis.renderer.gridContainer.background.fill = am4core.color("#000");
      valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
      dateAxis.renderer.grid.template.disabled = true;

      const valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis2, "");
      valueAxis2.marginTop = 30;
      valueAxis2.renderer.gridContainer.background.fill = am4core.color("#000");
      valueAxis2.renderer.gridContainer.background.fillOpacity = 0.05;
      valueAxis2.getSeriesDataItem = function (series, position) {
        const key = this.axisFieldName + this.axisLetter;
        const value = this.positionToValue(position);
        const dataItem = series.dataItems.getIndex(
          series.dataItems.findClosestIndex(
            value,
            (x) => {
              return x[key] ? x[key] : undefined;
            },
            "any"
          )
        );
        return dataItem;
      };

      let label = dateAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 85;
      label.textAlign = "middle";

      function createSeries(s, name) {
        let seriesColor = am4core.color(SERIES_COLORS[s]);
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.name = name;
        series.strokeWidth = 3;
        series.tensionX = 0.8;
        series.stroke = seriesColor;
        series.propertyFields.strokeDasharray = "dashLength";

        series.tooltipText = "{name}: {value}";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = "white";
        series.fill = seriesColor;

        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        const bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.3;

        let segment = series.segments.template;
        segment.interactionsEnabled = true;

        let hoverState = segment.states.create("hover");
        hoverState.properties.strokeWidth = 3;

        let dimmed = segment.states.create("dimmed");
        dimmed.properties.stroke = am4core.color("#dadada");

        segment.events.on("over", function (event) {
          processOver(event.target.parent.parent.parent);
        });

        segment.events.on("out", function (event) {
          processOut(event.target.parent.parent.parent);
        });

        series.data = trendData[s];
        if (s === "open") {
          series.yAxis = valueAxis;
        } else {
          series.yAxis = valueAxis2;
        }

        return series;
      }

      createSeries("new", "New Findings");
      createSeries("closed", "Closed Findings");
      createSeries("open", "Open Findings");

      chart.legend.itemContainers.template.events.on("over", function (event) {
        processOver(event.target.dataItem.dataContext);
      });

      chart.legend.itemContainers.template.events.on("out", function (event) {
        processOut(event.target.dataItem.dataContext);
      });

      function processOver(hoveredSeries) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
          segment.setState("hover");
        });

        chart.series.each(function (series) {
          if (series !== hoveredSeries) {
            series.segments.each(function (segment) {
              segment.setState("dimmed");
            });
            series.bulletsContainer.setState("dimmed");
          }
        });
      }

      function processOut(hoveredSeries) {
        chart.series.each(function (series) {
          series.segments.each(function (segment) {
            segment.setState("default");
          });
          series.bulletsContainer.setState("default");
        });
      }

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dataExists, trendData]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"RiskPostureTrend"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default RiskPosture;
