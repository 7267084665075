import ReadMore from "../../../../Components/ReadMore/ReadMore";
import React from "react";

const ResourceDataItem = ({
  resource: { resource_name, resource_id, resource_type },
}) => {
  return (
    <div className={`data-table-row`}>
      {resource_name ? (
        <>
          <div className={`data-table-cell`}>{resource_name}</div>
          <div className={`data-table-cell`}>
            <ReadMore lines={2} more="More.." less="Less">
              {resource_id}
            </ReadMore>
          </div>
          <div className={`data-table-cell`}>{resource_type}</div>
        </>
      ) : (
        <>
          <div className={`data-table-cell`}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`}>
            <div className="loading-row" />
          </div>
        </>
      )}
    </div>
  );
};

export default ResourceDataItem;
