import React, { useEffect, useRef, useState } from "react";
import Popover from "react-popover";
import "./ActionsMenu.scss";

const ActionsMenu = ({
  menuItems,
  children,
  className,
  isHidden = false,
  tipSize,
  "data-testid": dataTestId,
  onMenuOpenChange = null,
  title,
  cleanListBg,
  disabled,
  initialIsOpen,
}) => {
  const [isOpen, toggleIsOpen] = useState(initialIsOpen);
  const actionsMenuWrapperRef = useRef(null);

  useEffect(() => {
    onMenuOpenChange && onMenuOpenChange(isOpen);
  }, [isOpen]);

  const getMenu = () => {
    const actions = menuItems.map((item, index) => (
      <li
        data-for={item?.tooltipData?.dataFor}
        data-tip={item?.tooltipData?.dataTip}
        data-testid={item?.["data-testid"]}
        className={`${item.className ? item.className : "action-menu-li"} ${
          item?.disabled ? "is-disabled" : ""
        }`}
        key={`${item.text}-${index}-${new Date().getTime()}`}
        onClick={(e) => {
          if (item.disabled) {
            return;
          }
          e.stopPropagation();
          toggleIsOpen(false);
          item?.onClick();
        }}
      >
        <span
          className={`action-menu-li-text ${
            item.isDisabled ? "is-disabled" : ""
          }`}
        >
          {item.component || item.text}
        </span>
      </li>
    ));
    return (
      <ul
        key="actions-menu-list"
        className={`action-menu-ul ${cleanListBg ? "clean-list-bg" : ""}`}
      >
        {actions}
      </ul>
    );
  };

  return (
    !isHidden && (
      <Popover
        isOpen={isOpen}
        tipSize={tipSize || 0.01}
        place={"below"}
        onOuterAction={() => setTimeout(() => toggleIsOpen(false), 200)}
        className={"actions-menu-popover"}
        body={[
          <div
            className={`header-popover-menu action-menu-items-wrapper ${
              className ? className : ""
            }`}
          >
            {!!title && <label className={`actions-menu-title`}>{title}</label>}
            {getMenu()}
          </div>,
        ]}
      >
        <div
          ref={actionsMenuWrapperRef}
          className="actions-menu-wrapper"
          data-testid={dataTestId}
          onClick={(event) => {
            if (!disabled) {
              event.stopPropagation();
              toggleIsOpen(!isOpen);
            }
          }}
        >
          {!!children ? (
            children
          ) : (
            <i className={`seem-icon seem-icon-more-actions`} />
          )}
        </div>
      </Popover>
    )
  );
};

export default ActionsMenu;
