import React, { useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { startCase, toLower } from "lodash";
import useChart from "../../../Components/Chart/useChart";
import Chart from "../../../Components/Chart/Chart";
import { ticketStatus } from "../../../Ticketing/TicketingUtils";
import { getFilterToExecute } from "../../DashboardUtils";
import useDashboardNavigator from "../../useDashboardNavigator";
import useSummary from "../../useSummary";

const getTicketColor = (type) => {
  switch (type) {
    case "BACKLOG":
      return "#8DC9F3";
    case "SCHEDULED":
      return "#5565f9";
    case "IN_PROGRESS":
      return "#A673F1";
    case "REJECTED":
      return "#ec2a3f";
    default:
      return "#2dbf9f";
  }
};

const FindingsByTicketStatus = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
  chartLocation,
  columnHeight,
}) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [totalFindings, setTotalFindings] = useState(0);
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id"],
      group_by: ["ticket.status"],
      aggregate_functions: ["COUNT"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const createYAxis = (yAxis) => {
    yAxis.dataFields.category = "category";
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.location = 0;
    yAxis.hide(); // for hiding all lines
  };

  const createXAxis = (xAxis) => {
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    xAxis.min = 0;
    xAxis.max = totalFindings;
    xAxis.strictMinMax = true;
    xAxis.renderer.grid.template.location = 0;
  };

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const filteredData = data?.filter((type) => type?.[0] !== "DELETED");

      let total = filteredData?.reduce((prev, next) => prev + next[1], 0);

      const statuses = [];
      for (let i = 0; i < filteredData.length; i += 1) {
        const type = filteredData[i][0];
        const findingsAmount = filteredData[i][1];
        const from = i === 0 ? 0 : statuses?.[i - 1]?.to;
        const to = from + findingsAmount;
        statuses[i] = {
          category: "",
          from,
          to,
          findingsAmount,
          name: startCase(toLower(type)),
          fill: getTicketColor(type),
        };
      }

      setTotalFindings(total);

      if (statuses?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (statuses?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(statuses);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      let [chart, legendContainer] = createChart(
        "ticketStatusDashboard",
        am4charts.XYChart
      );
      chart.data = dashboardData;

      let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      createYAxis(yAxis);
      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      createXAxis(xAxis);

      if (chartLocation === "remediation-queue") {
        xAxis.renderer.baseGrid.disabled = true;
      }

      chart.events.on("beforevalidated", (ev) => {
        let data = chart.data;
        for (let i = 0; i < data.length; i++) {
          let range = xAxis.axisRanges.create();
          range.value = data[i].to;
          range.grid.strokeOpacity = 0; // for hiding all lines
          range.label.text = data[i].findingsAmount;
          range.label.dy = chartLocation === "dashboard" ? -35 : 28;
          range.label.horizontalCenter = "right";
          range.label.inside = true;
          range.label.fontSize = 14;
          range.label.fontWeight = 500;
        }
      });

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "to";
      series.dataFields.openValueX = "from";
      series.dataFields.categoryY = "category";
      series.columns.template.propertyFields.fill = "fill";
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px]{categoryY} {findingsAmount}";
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = "white";
      series.columns.template.fillOpacity = 0.6;
      series.columns.template.strokeOpacity = 1;
      series.columns.template.propertyFields.stroke = "fill";
      series.columns.template.height = columnHeight || 50;
      series.columns.template.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;

      series.columns.template.events.on("hit", (e) => {
        let selectedStatus;
        for (let key in ticketStatus) {
          if (ticketStatus[key].text === e.target.dataItem.dataContext.name) {
            selectedStatus = key;
            break;
          }
        }
        dashboardNavigator(
          null,
          null,
          null,
          getFilterToExecute({
            value: selectedStatus,
            type: "ticket_status",
          })
        );
      });

      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      chart.legend.useDefaultMarker = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;
      chart.legend.data = chart.data;

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"ticketStatusDashboard"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default FindingsByTicketStatus;
