import React, { useContext } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";
import DashboardWidget from "../DashboardWidget/DashboardWidget";
import NewVSClosed from "./NewVSClosed/NewVSClosed";
import AvgAgeBySeverity from "./AvgAgeBySeverity/AvgAgeBySeverity";
import OneSeriesByDiscoveryDate from "./OneSeriesByDiscoveryDate/OneSeriesByDiscoveryDate";
import { oneSeriesTrendProperties } from "./TrendsUtils";
import "./TrendsDashboard.scss";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import FindingsByDataSource from "./FindingsByDataSource/FindingsByDataSource";
import FeatureFlag from "../../FeatureFlag/FeatureFlag";
import RiskPosture from "./RiskPosture/RiskPosture";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ScopesContext } from "../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { FiltersContext } from "../../AppView/FilterManagement/FilterProvider/FilterProvider";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_spiritedaway);

const TrendsDashboard = ({ timeScale }) => {
  const { filtersState } = useContext(FiltersContext);
  const { defaultFilter, filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  const { unifyTrends } = useFlags();

  return (
    <>
      {!!selectedScope &&
      !!filterToExecute &&
      !!timeScale &&
      unifyTrends !== undefined ? (
        <div className="widgets-grid widgets-grid-trends">
          {!unifyTrends && (
            <DashboardWidget
              className="findings-discovery-date-widget"
              title={oneSeriesTrendProperties.newFindingsByDiscoveryDate.title}
            >
              <OneSeriesByDiscoveryDate
                selectedScope={selectedScope}
                filterToExecute={filterToExecute}
                scopeToExecute={scopeToExecute}
                timeScale={timeScale}
                chartData={oneSeriesTrendProperties.newFindingsByDiscoveryDate}
              />
            </DashboardWidget>
          )}

          {!unifyTrends && (
            <DashboardWidget
              className="new-vs-closed-trend-widget"
              title={"New vs Closed Findings"}
            >
              <NewVSClosed
                selectedScope={selectedScope}
                filterToExecute={filterToExecute}
                scopeToExecute={scopeToExecute}
                timeScale={timeScale}
                defaultFilter={defaultFilter}
              />
            </DashboardWidget>
          )}

          {unifyTrends && (
            <DashboardWidget
              className="risk-posture-widget unify"
              title={`Risk Posture`}
              size={"s"}
            >
              <RiskPosture
                selectedScope={selectedScope}
                filterToExecute={filterToExecute}
                scopeToExecute={scopeToExecute}
                timeScale={timeScale}
                defaultFilter={defaultFilter}
              />
            </DashboardWidget>
          )}

          <DashboardWidget
            className={`avg-age-severity-trend-widget ${
              unifyTrends ? "unify" : ""
            }`}
            title={"Average Age By Severity"}
            size={"s"}
          >
            <AvgAgeBySeverity
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
              timeScale={timeScale}
            />
          </DashboardWidget>

          {!unifyTrends && (
            <DashboardWidget
              className="findings-discovery-date-widget"
              title={oneSeriesTrendProperties.openFindingsByDiscoveryDate.title}
            >
              <OneSeriesByDiscoveryDate
                selectedScope={selectedScope}
                filterToExecute={filterToExecute}
                scopeToExecute={scopeToExecute}
                timeScale={timeScale}
                chartData={oneSeriesTrendProperties.openFindingsByDiscoveryDate}
              />
            </DashboardWidget>
          )}

          <FeatureFlag name={`category-trends`}>
            <DashboardWidget
              className="avg-age-severity-trend-widget"
              title={"Open Findings By Data Source"}
              size={"s"}
            >
              <FindingsByDataSource
                selectedScope={selectedScope}
                filterToExecute={filterToExecute}
                scopeToExecute={scopeToExecute}
                timeScale={timeScale}
              />
            </DashboardWidget>
          </FeatureFlag>
        </div>
      ) : (
        <Loader center img={Spinner} />
      )}
    </>
  );
};

export default TrendsDashboard;
