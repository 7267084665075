import React from "react";

const Marker = ({ id, children }) => (
  <marker
    className="react-flow__arrowhead"
    id={id}
    markerWidth="12.5"
    markerHeight="12.5"
    viewBox="-10 -10 20 20"
    orient="auto"
    refX="0"
    refY="0"
  >
    {children}
  </marker>
);

const MarkerDefinitions = ({ id, color }) => {
  return (
    <svg>
      <defs>
        <Marker id={`${id}`}>
          <polyline
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            fill="none"
            points="-5,-4 0,0 -5,4"
          />
        </Marker>
      </defs>
    </svg>
  );
};

MarkerDefinitions.displayName = "MarkerDefinitions";

export default MarkerDefinitions;
