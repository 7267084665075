import { gql } from "@apollo/client";

export const UPDATE_STATUS_WITH_COMMENT = gql`
  mutation UpdateStatusWithComment(
    $filters_config: FiltersConfigInput!
    $user_status_key: Int!
    $user_comment_html: String!
    $user_comment_plain: String!
    $reopen_date: Date
    $apply_async: Boolean
  ) {
    set_user_status_and_comment_v2(
      filters_config: $filters_config
      user_status_key: $user_status_key
      user_comment_html: $user_comment_html
      user_comment_plain: $user_comment_plain
      reopen_date: $reopen_date
      apply_async: $apply_async
    ) {
      task_id
    }
  }
`;

export const UPDATE_STATUS_CACHE = gql`
  fragment ActualStatus on FindingsView {
    actual_status {
      key
      category
      type
    }
  }
`;

export const FINDING_UPDATE_STATUS_CACHE = gql`
  fragment MyFinding on Finding {
    actual_status
  }
`;
