import React, { useContext } from "react";
import "./AttachmentDataView.scss";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ClassNames from "classnames";
import { fileStatus } from "../AttachmentsUtils";
import { dataSourceIcons } from "../../../FindingsUtils";
import { DetailsPaneContext } from "../../DetailsPaneProvider";

const AttachmentDataView = ({
  file,
  handleFileDownload,
  removeAttachment,
  rowIndex,
}) => {
  const {
    detailsPaneData: { findingTicket },
  } = useContext(DetailsPaneContext);

  const classNameWrapper = ClassNames("data-table-row", {
    "is-error": file.status === fileStatus.ERROR,
    "is-uploading": file.status === fileStatus.UPLOAD,
    "is-downloading": file.status === fileStatus.DOWNLOAD,
  });

  const getStatusIndicator = () => {
    switch (file.status) {
      case fileStatus.UPLOAD: {
        return (
          <div className={`row-status`}>
            <i className={"seem-icon-spinner spinner"} />
            <span>Uploading</span>
          </div>
        );
      }

      case fileStatus.ERROR: {
        return (
          <>
            <ReactTooltip
              id={`file-error`}
              place="top"
              className={"copy-tooltip"}
            >
              {file.errorMessage}
            </ReactTooltip>
            <div className={`row-status`} data-tip data-for={`file-error`}>
              <i className={`seem-icon seem-icon-alert`} />
              <span>Error</span>
            </div>
          </>
        );
      }

      // case fileStatus.OK: {
      //   return <i className={`seem-icon seem-icon-bin`} />;
      // }

      case fileStatus.DOWNLOAD: {
        return (
          <div className={`row-status`}>
            <i className={"seem-icon-spinner spinner"} />
            <span>Downloading</span>
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <>
      <div className={classNameWrapper}>
        {file?.name ? (
          <>
            <div className={`data-table-cell attachment-name-container`}>
              <ReactTooltip
                id={`attachment-name-${rowIndex}`}
                place="top"
                className={"attachment-name-tooltip"}
              >
                {file.name}
              </ReactTooltip>
              <span data-tip data-for={`attachment-name-${rowIndex}`}>
                {file.name}
              </span>
              {!!findingTicket && file.synced_to_ticket && (
                <>
                  <ReactTooltip
                    id={`attachment-logo-${rowIndex}`}
                    place="top"
                    className={"attachment-name-tooltip"}
                  >
                    {`Synced with ${findingTicket?.ticket_provider?.type}`}
                  </ReactTooltip>
                  <img
                    data-tip
                    data-for={`attachment-logo-${rowIndex}`}
                    className={"ticket-logo"}
                    alt={`${findingTicket?.ticket_provider?.type}-logo`}
                    src={
                      dataSourceIcons[
                        findingTicket?.ticket_provider?.type?.toUpperCase()
                      ]?.S
                    }
                  />
                </>
              )}
            </div>
            <div className={`data-table-cell`}>
              {moment(file.creation_time).format("DD MMMM, YYYY, hh:mm A")}
            </div>
            {file.status === fileStatus.OK ? (
              <>
                <ReactTooltip
                  id={`remove-attachment-${rowIndex}`}
                  place="top"
                  className={"remove-attachment-tooltip"}
                >
                  Remove
                </ReactTooltip>
                <div
                  className={`data-table-cell`}
                  onClick={() => removeAttachment(file.id)}
                  data-tip
                  data-for={`remove-attachment-${rowIndex}`}
                >
                  <i className={`seem-icon seem-icon-bin`} />
                </div>
              </>
            ) : (
              <div className={`data-table-cell`}>{getStatusIndicator()}</div>
            )}
            {/*<ReactTooltip*/}
            {/*  id={`remove-attachment`}*/}
            {/*  place="top"*/}
            {/*  className={"remove-attachment-tooltip"}*/}
            {/*>*/}
            {/*  Remove*/}
            {/*</ReactTooltip>*/}

            {file.status === fileStatus.OK && (
              <>
                <ReactTooltip
                  id={`download-attachment-${rowIndex}`}
                  place="top"
                  className={"download-attachment-tooltip"}
                >
                  Download
                </ReactTooltip>
                <div
                  className={`data-table-cell`}
                  onClick={() => handleFileDownload([file])}
                  data-tip
                  data-for={`download-attachment-${rowIndex}`}
                >
                  <i className={`seem-icon seem-icon-download`} />
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={`data-table-cell`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell`}>
              <div className="loading-row" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AttachmentDataView;
