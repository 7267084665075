import React, { useContext } from "react";
import Button from "../../../Forms/Controls/Button/Button";
import "./RemediationQueueHeader.scss";
import { useHistory } from "react-router-dom";
import { RemediationQueueContext } from "../RemediationQueueProvider";
import moment from "moment";
import { modeOptions } from "../../RemediationQueuesUtils";
import { ticketEndpointTypeEnum } from "../../../Ticketing/TicketingUtils";
import { useMutation } from "@apollo/client";
import { DELETE_REMEDIATION_QUEUE } from "../../RemediationQueueApi";
import {
  showDialog,
  showErrorDialog,
} from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

const RemediationQueueHeader = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const {
    remediationQueueData: {
      id,
      title,
      updated_time,
      providerType,
      editable,
      ticketEndpointType,
    },
    mode,
    updateMode,
  } = useContext(RemediationQueueContext);

  const [deleteRemediationQueue] = useMutation(DELETE_REMEDIATION_QUEUE);

  const contentByMode = (rmqMode) => {
    switch (rmqMode) {
      case modeOptions.READ:
        return (
          <div
            className={`rmq-queue-header-content rmq-queue-header-content-read`}
          >
            <div className={`header-summary`}>
              <div
                data-testid={"return-to-rmq-list-btn"}
                className={`header-back-button`}
                onClick={() => history.push("/remediation/workflows")}
              >
                <i className="seem-icon-left-arrow" />
                <span className="header-back-button-text"> All Queues</span>
              </div>
              <div className={`header-queue-title`}>{title}</div>
              <div className={`header-update`}>
                Last Update {moment(updated_time).fromNow()}
              </div>
            </div>
            {editable && (
              <div className={"header-actions"}>
                <Button
                  data-testid={"modify-rmq"}
                  label={"Modify"}
                  onClick={() => updateMode(modeOptions.EDIT)}
                />
                <Button
                  data-testid={"delete-rmq"}
                  label={"Delete"}
                  noBorders
                  onClick={() => {
                    showDialog({
                      title: "Delete Queue?",
                      message: (
                        <>
                          <p>
                            Are you sure you want to delete the Remediation
                            Queue?
                          </p>
                          {ticketEndpointType ===
                          ticketEndpointTypeEnum.TICKET ? (
                            <>
                              <p>
                                {`Deleting this Queue will not remove the ${capitalize(
                                  providerType
                                )} items from the backlog,`}
                              </p>
                              <p>
                                new tickets will not be opened automatically.
                              </p>
                            </>
                          ) : null}
                        </>
                      ),
                      buttons: [
                        {
                          label: "Cancel",
                        },
                        {
                          "data-testid": "delete-rmq-confirm",
                          isSecondary: true,
                          label: "Delete",
                          onClick: () => {
                            deleteRemediationQueue({ variables: { id } })
                              .then(() => {
                                showToast({ message: "Queue Deleted!" });
                                history.push("/remediation/monitoring");
                              })
                              .catch((err) =>
                                showErrorDialog({ message: err.message })
                              );
                          },
                        },
                      ],
                    });
                  }}
                />
              </div>
            )}
          </div>
        );
      case modeOptions.EDIT:
        return (
          <div
            className={"rmq-queue-header-content rmq-queue-header-content-edit"}
          >
            <div className={"header-queue-title header-queue-title-edit"}>
              {t("Edit Remediation Queue")}
            </div>
          </div>
        );
      case modeOptions.CREATE:
        return (
          <div
            className={
              "rmq-queue-header-content rmq-queue-header-content-create"
            }
          >
            <div className={"header-queue-title"}>
              {t("Create New Remediation Queue")}
            </div>
          </div>
        );
    }
  };

  return (
    <div
      className={`rmq-queue-header ${
        mode === modeOptions.EDIT ? "rmq-queue-header-edit" : ""
      }`}
    >
      {contentByMode(mode)}
    </div>
  );
};

export default RemediationQueueHeader;
