import { gql } from "@apollo/client";

export const GET_REMEDIATION_QUEUES = gql`
  query remediation_queues($with_transitions: Boolean = false) {
    remediation_queues(filters: { state_ne: DELETED }) {
      edges {
        node {
          id
          title
          findings_count_by_ticket_status: findings_view_count_by_ticket_status
          ticket_endpoint {
            type
            ticket_provider {
              type
            }
          }
          finding_filter {
            name
            filter_data
            created_by
          }
          finding_scope {
            name
            created_by
          }
          finding_scope_group {
            name
          }
          scope_id
          updated_time
          state
          visible
          transitions @include(if: $with_transitions) {
            source
            target
          }
          editable
        }
      }
    }
  }
`;

export const GET_REMEDIATION_QUEUES_FINDINGS_COUNT = gql`
  query {
    remediation_queues(filters: { state_ne: DELETED }) {
      edges {
        node {
          id
          findings_count: findings_view_count
        }
      }
    }
  }
`;

export const GET_REMEDIATION_QUEUE = gql`
  query GetRemediationQueue($id: ID!) {
    remediation_queue(id: $id) {
      id
      id_int
      title
      description
      contact
      finding_filter {
        id
        name
        filter_data
        created_by
      }
      finding_scope {
        id
        name
        filter_data
        created_by
      }
      finding_scope_group {
        id
        name
      }
      finding_scope_id
      scope_id
      finding_filter_id
      ticket_endpoint {
        id
        type
        value_mapping
        ticket_provider {
          type
          id
        }
      }
      cron
      max_concurrent_opened_tickets
      created_by
      created_time
      updated_by
      state
      updated_time
      findings_count: findings_view_count
      priority
      editable
    }
  }
`;

export const DELETE_REMEDIATION_QUEUE = gql`
  mutation NewRemediationQueue($id: String!) {
    delete_remediation_queue(id: $id) {
      ok
    }
  }
`;

export const GET_RMQ_PRIORITY_SETTINGS = gql`
  query settings {
    settings(filters: { key: "remediation_queue_priority" }) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`;

export const generateRmqPrioritySettings = ({
  result = null,
  error = null,
}) => {
  let slaPreferencesMock = {
    request: {
      query: GET_RMQ_PRIORITY_SETTINGS,
    },
  };

  if (error) {
    return {
      ...slaPreferencesMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...slaPreferencesMock,
      result: { data: result },
    };
  }

  return {
    ...slaPreferencesMock,
    result: {},
  };
};
