import React, { createContext, useReducer } from "react";
import { filterTabs } from "../../AppViewUtils";

export const filtersInitialValues = {
  filtersList: [],
  selectedFilter: null,
  fieldsData: [],
  fieldValues: [],
  defaultFilter: null,
  filterToExecute: null,
  isEditFilter: false,
  filtersMenuTab: filterTabs.RECOMMENDED,
  shouldBlinkField: null,
};

export const FiltersContext = createContext(filtersInitialValues);

const FilterProvider = ({ children }) => {
  const [filtersState, updateFiltersState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    filtersInitialValues
  );

  const getFilterValueById = (filterId) => {
    return filtersState?.filtersList?.find(
      (filter) => filter.value?.id === filterId
    );
  };

  const filtersContextValue = React.useMemo(
    () => ({
      filtersState,
      updateFiltersState,
      getFilterValueById,
    }),
    [filtersState]
  );

  return (
    <FiltersContext.Provider value={filtersContextValue}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FilterProvider;
