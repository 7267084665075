import React from "react";
import "./FileInput.scss";
import PropTypes from "prop-types";

const FileInput = ({
  handleFileInput,
  isMulti,
  "data-testid": dataTestId,
  accept,
}) => {
  const onChange = (e) => {
    handleFileInput(e);
    e.target.value = null;
  };
  return (
    <div className="file-uploader">
      <input
        data-testid={`${dataTestId}-file-input`}
        type="file"
        onChange={(e) => onChange(e)}
        className={`custom-file-input`}
        multiple={isMulti}
        accept={accept}
      />
    </div>
  );
};

FileInput.defaultProps = {
  handleFileInput: () => {},
  isMulti: false,
};

FileInput.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  handleFileInput: PropTypes.func,
  accept: PropTypes.string,
};

export default FileInput;
