export const normalizeNumbers = (value) => {
  const absValue = !value ? 0 : Number(Math.abs(value));
  const typeUnits = ["k", "m", "b"];

  let abbr = "";

  const trillion = 1000000000000,
    billion = 1000000000,
    million = 1000000,
    thousand = 1000;
  let retNumber = absValue;

  if ((absValue >= 0 && absValue < 10) || (absValue < 0 && absValue > -10)) {
    abbr = "";
  } else if (
    (absValue >= 10 && absValue < 100) ||
    (absValue <= -10 && absValue > -100)
  ) {
    abbr = "";
  } else if (
    (absValue >= 100 && absValue < thousand) ||
    (absValue <= -100 && absValue > thousand)
  ) {
    abbr = "";
    retNumber = retNumber.toLocaleString();
  } else if (
    (absValue >= thousand && absValue < million) ||
    (absValue <= thousand && absValue > million)
  ) {
    abbr = typeUnits?.[0] || "";
    retNumber = calculateAndDivide(absValue);
  } else if (
    (absValue >= million && absValue < billion) ||
    (absValue <= million && absValue > billion)
  ) {
    abbr = typeUnits?.[1] || "";

    retNumber = calculateAndDivide(absValue / 1000);
  } else if (
    (absValue >= billion && absValue < trillion) ||
    (absValue <= billion && absValue > trillion)
  ) {
    abbr = typeUnits?.[2] || "";
    retNumber = calculateAndDivide(absValue / 1000000);
  }

  return `${retNumber}${abbr}`;
};

const calculateAndDivide = (value) => {
  if (value < 10000) {
    value = (value / 1000).toFixed(2);
  } else if (value < 100000) {
    value = (value / 1000).toFixed(1);
  } else {
    value = (value / 1000).toFixed(0);
  }
  return value;
};

export const daySuffix = (day) => {
  switch (day) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
