import { useMutation, useQuery } from "@apollo/client";
import { CNC_GET_CUSTOMER, DELETE_DOMAIN } from "../CustomerProvisioningApi";
import React, { useContext, useEffect, useState } from "react";
import Button from "../../Forms/Controls/Button/Button";
import {
  showDialog,
  showErrorDialog,
} from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import Table from "../../Components/Table/Table";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import ReactTooltip from "react-tooltip";
import AddDomain from "../AddDomain/AddDomain";

const AllowedDomainsModal = ({ customerID }) => {
  const [deleteDomain] = useMutation(DELETE_DOMAIN);
  const { openModal } = useContext(ModalContext);
  const [allowedDomains, setAllowedDomains] = useState([]);
  const { data: customerData } = useQuery(CNC_GET_CUSTOMER, {
    variables: {
      customer_id: customerID,
    },
  });

  useEffect(() => {
    if (!!customerData) {
      const allowedDomains = customerData.cnc_customer.domains.edges.map(
        (domain) => domain.node
      );
      setAllowedDomains(allowedDomains);
    }
  }, [customerData]);

  const columns = React.useMemo(
    () => [
      {
        id: "domain",
        Header: "Domain",
        accessor: "domain",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
      },
      {
        id: "id",
        Header: "",
        accessor: "domain",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
        Cell: (cellProps) => {
          return allowedDomains.length > 1 ? (
            <>
              <Button
                data-testid={"delete-domain"}
                isClean
                tooltipData={{
                  dataFor: `delete-domain-btn-${cellProps.value}`,
                  dataTip: true,
                }}
                isIcon="seem-icon-bin"
                type={`button`}
                onClick={() =>
                  showDialog({
                    title: "Delete Domain",
                    message: (
                      <div>
                        {`Are you sure you want to delete `}
                        <span
                          className={"user-name-bold"}
                        >{`${cellProps.value}`}</span>
                        {`?`}
                      </div>
                    ),
                    buttons: [
                      {
                        "data-testid": "cancel-delete",
                        label: "Cancel",
                      },
                      {
                        "data-testid": "confirm-delete",
                        isSecondary: true,
                        label: "Delete",
                        type: "submit",
                        onClick: () => handleDeletion(cellProps.value),
                      },
                    ],
                  })
                }
                title={"Delete"}
              />
              <ReactTooltip
                id={`delete-domain-btn-${cellProps.row?.id}`}
                place="top"
              >
                Delete Domain
              </ReactTooltip>
            </>
          ) : (
            <></>
          );
        },
      },
    ],
    [allowedDomains]
  );

  const handleDeletion = (domainToDelete) => {
    deleteDomain({
      variables: { domain: domainToDelete, customer_id: customerID },
      refetchQueries: ["get_customers", "cnc_get_customer"],
    })
      .then((res) => {
        showToast({ message: res.data?.cnc_delete_domain?.msg });
      })
      .catch((error) => {
        showErrorDialog({ message: error.message });
      });
  };

  return (
    <div className={"users-table-container"}>
      <Button
        isSecondary
        wrapperClassName={"sidebar-display-area-top-button"}
        label={"+ Allowed Domain"}
        isXl
        data-testid={"add-domain"}
        onClick={() =>
          openModal({
            title: "Add Domain",
            size: "xs",
            overlay: true,
            component: <AddDomain customerID={customerID} />,
          })
        }
        title={"Add"}
      />
      <Table
        tableWrapperClass={`domains-table`}
        columns={columns}
        data={allowedDomains}
        data-testid-body={"domains"}
      />
    </div>
  );
};
export default AllowedDomainsModal;
