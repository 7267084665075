import { useQuery } from "@apollo/client";
import { GET_FILTERS_DATA } from "../Findings/FindingsFilters/FindingsFilterApi";
import { useEffect, useState } from "react";
import { filtersMap } from "./AppViewUtils";
import { findingSeverities, findingStatuses } from "../Findings/FindingsUtils";

const useFiltersData = ({ filterType }) => {
  const { data: filtersData } = useQuery(GET_FILTERS_DATA, {
    variables: { filterType: filterType },
  });

  const [fieldsData, setFieldsData] = useState([]);

  useEffect(() => {
    let data = filtersData?.finding_filters_data;

    data = data?.map((filter) => {
      if (filter.field === "severity") {
        return {
          ...filter,
          ...filtersMap[filter.field],
          values: Object.keys(findingSeverities),
        };
      } else if (filter.field === "actual_status") {
        return {
          ...filter,
          ...filtersMap[filter.field],
          values: Object.keys(findingStatuses),
        };
      }

      return { ...filter, ...filtersMap[filter.field] };
    });

    setFieldsData(data);
  }, [filtersData]);

  return { fieldsData };
};

export default useFiltersData;
