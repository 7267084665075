import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../../Forms/Controls/TextInput/TextInput";
import { resourceTypes } from "../../../FindingsUtils";
import ModalButtonsContainer from "../../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../../Forms/Controls/Button/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  EDIT_GROUP,
  GET_GROUP_BY_ID,
} from "../ScopesApi";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import {
  showDialog,
  showErrorDialog,
} from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import "./CreateGroup.scss";
import { LS_SCOPE, SYSTEM } from "../../FilterUtils";
import Tree from "../../../../Components/Tree/Tree";
import { getQueuePluralsByCount } from "../../../../AppView/AppViewUtils";
import LocationsTree from "../../../../AppView/ScopeManagement/LocationsTree/LocationsTree";

const CreateGroup = ({
  groupId,
  root,
  resetFindings,
  updateScopeState,
  searchTree,
}) => {
  const methods = useForm({ mode: "all", reValidateMode: "onChange" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
    clearErrors,
  } = methods;
  const { closeModal } = useContext(ModalContext);

  const [createGroupMutation] = useMutation(CREATE_GROUP, {
    refetchQueries: ["scopes"],
  });
  const [editGroupMutation] = useMutation(EDIT_GROUP, {
    refetchQueries: ["scopes", "GetFindingsByGroup", "GetFindings"],
  });
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    refetchQueries: ["scopes"],
  });
  const [savedScopes, setSavedScopes] = useState([]);

  const { data: groupData, loading: isLoadingGroupData } = useQuery(
    GET_GROUP_BY_ID,
    {
      variables: { group_id: groupId },
      skip: !groupId,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!!groupData && !!groupId) {
      const data = JSON.parse(groupData?.scope_group_by_id?.group_data);

      const groups = data.child_groups.map((item) => {
        return {
          id: item.id,
          type: resourceTypes.GROUP,
        };
      });

      const scopes = data.child_scopes.map((item) => {
        return {
          id: item.id,
          type: resourceTypes.SCOPE,
        };
      });
      setSavedScopes([...groups, ...scopes]);

      const selectedLocation = searchTree(root?.[0], data.parent_group.id);

      reset({
        name: data.name,
        location: selectedLocation || root?.[0],
      });
    }
  }, [groupData]);

  const onSubmit = (data, overwrite) => {
    const groupData = {
      name: data.name,
      parent_group: data?.location?.id,
      child_scopes:
        data?.group_children
          ?.filter((node) => node.type === resourceTypes.SCOPE)
          .map((node) => node.id) || [],
      child_groups:
        data?.group_children
          ?.filter((node) => node.type === resourceTypes.GROUP)
          .map((node) => node.id) || [],
      overwrite,
    };

    if (!!groupId) {
      groupData.group_id = groupId;
    }

    saveGroup(
      !groupId ? createGroupMutation : editGroupMutation,
      groupData,
      overwrite
    );
  };

  const validateOnSave = () => {
    handleSubmit((data) => onSubmit(data, false))();
  };

  const saveGroup = (groupMutation, groupData, overwrite) => {
    groupMutation({ variables: groupData })
      .then((res) => {
        if (!!res.data?.create_scope_group?.ok) {
          const selectedScope = {
            label: groupData.name,
            value: {
              query: null,
              id: res.data?.create_scope_group?.group_id,
              editable: true,
              type: resourceTypes.GROUP,
            },
          };
          updateScopeState({
            scopeToExecute: null,
            selectedScope: selectedScope,
          });

          if (resetFindings) {
            resetFindings();
          }

          closeModal();
          localStorage.setItem(LS_SCOPE, JSON.stringify(selectedScope));
          showToast({
            message: "Group Saved!",
          });
        } else if (
          !overwrite &&
          !res?.data?.edit_scope_group?.ok &&
          res?.data?.edit_scope_group?.effected_queues?.length
        ) {
          showDialog({
            title: "Save Group",
            type: "warning",
            description: `The scope group "${
              groupData.name
            }" is part of an existing ${getQueuePluralsByCount(
              res?.data?.edit_scope_group?.effected_queues?.length
            )}.`,
            message: `Saving this scope group might change the findings in the ${getQueuePluralsByCount(
              res?.data?.edit_scope_group?.effected_queues?.length
            )} "${res?.data?.edit_scope_group?.effected_queues?.join(", ")}".`,
            buttons: [
              {
                isSecondary: false,
                label: "Cancel",
              },
              {
                isSecondary: true,
                label: "Save and update queue",
                onClick: () => {
                  groupData.overwrite = true;
                  saveGroup(editGroupMutation, groupData, true);
                },
              },
            ],
          });
        } else if (!!res.data?.edit_scope_group?.ok) {
          const selectedScope = {
            label: groupData.name,
            value: {
              query: null,
              id: groupId,
              editable: true,
              type: resourceTypes.GROUP,
            },
          };
          updateScopeState({
            scopeToExecute: null,
            selectedScope: selectedScope,
          });

          if (resetFindings) {
            resetFindings();
          }
          closeModal();
          localStorage.setItem(LS_SCOPE, JSON.stringify(selectedScope));
          showToast({
            message: "Group Updated!",
          });
        } else {
          showErrorDialog({
            message: "An error occurred while trying to create a group",
          });
        }
      })
      .catch((err) => showErrorDialog({ message: err.message }));
  };

  const treeData = useMemo(() => {
    const newRoot = { ...root?.[0] };
    if (newRoot?.children?.length) {
      newRoot.children = newRoot?.children?.filter(
        (node) => node?.editable && node?.created_by !== SYSTEM
      );
    }

    return [newRoot];
  }, [root]);

  const nodeHasWarning = (id = null) => {
    const { group_children } = getValues();
    let isChildrenListHasWarn;

    isChildrenListHasWarn = group_children?.find(
      (child) =>
        child.id === id &&
        child.parentId !== root?.[0]?.id &&
        child.parentId !== null &&
        !savedScopes.find((scope) => scope.id === id)
    );

    return !!isChildrenListHasWarn;
  };

  const treeHasWarning = useCallback(() => {
    const { group_children } = getValues();
    let isChildrenListHasWarn;

    isChildrenListHasWarn = group_children?.some((child) =>
      nodeHasWarning(child.id)
    );

    return !!isChildrenListHasWarn;
  }, [nodeHasWarning]);

  return (
    <form
      className="create-group-form-wrapper"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="create-group-form-content">
        <Controller
          name={`name`}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            return (
              <TextInput
                data-testid={`group-name`}
                labelTop
                onChange={(e) => onChange(e)}
                inputRef={ref}
                onBlur={onBlur}
                value={value}
                error={errors?.name ? errors.name : null}
                label={"Name"}
                placeholder={"Type a name"}
                size="max"
                className="create-group-name"
                inputStyle={"bomba-style"}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
          }}
        />

        <Controller
          wrapperClassName={``}
          name={`location`}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <LocationsTree
                name={`location`}
                onChange={(node) => {
                  onChange(node);
                }}
                error={errors?.location ? errors.location : null}
                label={"Location"}
                value={value}
                isLoading={isLoadingGroupData}
                root={root}
                size={"max"}
                labelTop
                wrapperClassName={"bomba-style"}
                popoverClassName={"tree-in-new-group-menu"}
              />
            );
          }}
          rules={{
            required: "Required",
            validate: {
              validChildren: (value) => {
                const { group_children } = getValues();

                const isChildrenListContainsLocation = group_children?.filter(
                  (child) => child.id === value?.id
                )?.length;

                if (!isChildrenListContainsLocation) {
                  clearErrors("group_children");
                }

                return (
                  !isChildrenListContainsLocation ||
                  `You chose ${value?.label} as your Location, it cannot also appear under your group. \r\n ֿPlease change your selection`
                );
              },
            },
          }}
          control={control}
        />

        <div className={`group-children-wrapper`}>
          <div className={`input-wrap bomba-style has-label-top`}>
            <label className={`input-label`}>
              Select scopes for this group
            </label>
            <div className={`group-children-container`}>
              <Controller
                wrapperClassName={``}
                name={`group_children`}
                render={({ field: { onChange } }) => (
                  <Tree
                    data={!!root ? treeData : null}
                    isSelectableRoot={false}
                    onChange={(nodes) =>
                      onChange(
                        nodes.map((node) => {
                          return {
                            id: node.id,
                            type: node.type,
                            parentId: node.parentId,
                          };
                        })
                      )
                    }
                    nodeOperations={{
                      onEdit: () => {},
                      onSelect: () => {},
                      onWarning: nodeHasWarning,
                    }}
                    data-testid={"group-children-tree"}
                    withCheckbox
                    preCheckedNodes={savedScopes}
                    error={
                      errors && errors.group_children
                        ? errors.group_children
                        : null
                    }
                  />
                )}
                control={control}
                rules={{
                  validate: {
                    validChildren: (value) => {
                      const { location } = getValues();

                      const isChildrenListContainsLocation = value?.filter(
                        (child) => child.id === location?.id
                      )?.length;

                      if (!isChildrenListContainsLocation) {
                        clearErrors("location");
                      }

                      return (
                        !isChildrenListContainsLocation ||
                        `You chose ${location?.name} as your Location, it cannot also appear under your group. \r\n ֿPlease change your selection`
                      );
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className={`warning-wrapper`}>
          {treeHasWarning() && (
            <div className={`warning-message-container`}>
              <i className={`seem-icon seem-icon-warning`} />
              <span>
                Scopes that already associated with a group will be moved to the
                new group
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="create-group-form-buttons">
        <ModalButtonsContainer>
          <div
            className={`create-group-buttons ${
              !!groupId ? "delete-enabled" : ""
            }`}
          >
            {!!groupId && (
              <Button
                label={"Delete Group"}
                isDanger
                onClick={() => {
                  showDialog({
                    title: "Delete Group?",
                    message: "Are you sure you want to delete the group?",

                    buttons: [
                      {
                        label: "Cancel",
                      },
                      {
                        isSecondary: true,
                        label: "Delete",
                        onClick: () => {
                          deleteGroup({ variables: { group_id: groupId } })
                            .then(() => {
                              updateScopeState({
                                scopeToExecute: null,
                                selectedScope: null,
                              });
                              localStorage.removeItem(LS_SCOPE);

                              if (resetFindings) {
                                resetFindings();
                              }
                              closeModal();
                              showToast({ message: "Group Deleted!" });
                            })
                            .catch((err) =>
                              showErrorDialog({ message: err.message })
                            );
                        },
                      },
                    ],
                  });
                }}
              />
            )}
            <Button
              data-testid={"create-group"}
              label={!groupId ? "Create" : "Update"}
              isSecondary
              disabled={!isValid || !isDirty}
              onClick={() => validateOnSave()}
            />
          </div>
        </ModalButtonsContainer>
      </div>
    </form>
  );
};

export default CreateGroup;
