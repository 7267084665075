import React, { useContext } from "react";
import DashboardWidget from "../DashboardWidget/DashboardWidget";
import "./SLADashboard.scss";
import Compliance from "./Compliance/Compliance";
import FixedFindings from "./FixedFindings/FixedFindings";
import Performances from "./Performances/Performances";
import AvgFixTimeBySeverity from "./AvgFixTimeBySeverity/AvgFixTimeBySeverity";
import OverdueFindingsBySeverity from "./OverdueFindingsBySeverity/OverdueFindingsBySeverity";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import ExpectedWorkload from "./ExpectedWorkload/ExpectedWorkload";
import { getTitleFromTimescale, timeScales } from "../DashboardUtils";
import { capitalize } from "lodash";
import { ScopesContext } from "../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { FiltersContext } from "../../AppView/FilterManagement/FilterProvider/FilterProvider";
import useFindingsViewChange from "../../Findings/useFindingsViewChange";

const SLADashboard = ({ timeScale }) => {
  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  useFindingsViewChange({ queries: ["getDashboard"] });

  return (
    <>
      {!!selectedScope && !!filterToExecute && !!timeScale ? (
        <div className="widgets-grid widgets-grid-sla">
          <DashboardWidget
            className="sla-compliance-widget"
            title={"All Findings"}
            size={"xs"}
          >
            <Compliance
              selectedScope={selectedScope}
              timeScale={timeScale}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
          <DashboardWidget
            className="sla-fixed-findings-widget"
            title={`Fixed Findings`}
            size={"xs"}
          >
            <FixedFindings
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
              size={"xs"}
            />
          </DashboardWidget>
          <DashboardWidget
            className="sla-performances-widget"
            title={`Fixed Findings ${
              timeScale === timeScales.DAY
                ? getTitleFromTimescale(timeScale)
                : `this ${getTitleFromTimescale(timeScale)}`
            }`}
            size={"xs"}
          >
            <Performances
              timeScale={timeScale}
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
          <DashboardWidget
            className="sla-avg-fix-by-severity-widget"
            title={`Avg. Time to Remediation by Severity`}
            size={"xs"}
          >
            <AvgFixTimeBySeverity
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
          <DashboardWidget
            className="sla-expected-workload-widget"
            title={`Expected Workload by ${capitalize(timeScale)}`}
          >
            <ExpectedWorkload
              selectedScope={selectedScope}
              timeScale={timeScale}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
          <DashboardWidget title={`Overdue Open Findings - By Severity`}>
            <OverdueFindingsBySeverity
              selectedScope={selectedScope}
              timeScale={timeScale}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
        </div>
      ) : (
        <Loader center img={Spinner} />
      )}
    </>
  );
};

export default SLADashboard;
