import { Handle } from "react-flow-renderer";
import React, { useMemo } from "react";
import {
  getTicketManagerFriendlyName,
  ticketManagersEnum,
} from "../../../Ticketing/TicketingUtils";
import { dataSourceIcons } from "../../../Findings/FindingsUtils";
import "./RMQNode.scss";
import { queueStates } from "../../../RemediationQueues/RemediationQueuesUtils";
import { capitalize, uniqBy } from "lodash";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";
import { useMutation } from "@apollo/client";
import { DELETE_REMEDIATION_QUEUE } from "../../../RemediationQueues/RemediationQueueApi";
import { useHistory } from "react-router-dom";
import Button from "../../../Forms/Controls/Button/Button";

const EDGE_SPACE = 34;
const NODE_HEIGHT = 144;
const BADGE_HEIGHT = 24;

const RMQNode = ({ data }) => {
  const {
    id,
    title,
    scope,
    filter,
    ticket_endpoint,
    state,
    transitions,
    editable,
  } = data;

  const [deleteRemediationQueue] = useMutation(DELETE_REMEDIATION_QUEUE, {
    refetchQueries: ["remediation_queues"],
  });
  let history = useHistory();

  const endpoint = useMemo(() => {
    return ticket_endpoint?.ticket_provider?.type;
  }, [ticket_endpoint]);

  const action = useMemo(() => {
    switch (endpoint) {
      case ticketManagersEnum.SEEMPLICITY:
        return "Review in Seemplicity";

      case ticketManagersEnum.SLACK:
        return "Send notifications to Slack";

      case ticketManagersEnum.JIRA:
      case ticketManagersEnum.MONDAY:
      case ticketManagersEnum.SERVICENOW:
      case ticketManagersEnum.FIBERY:
      case ticketManagersEnum.ZENDESK:
        return `Send findings to ${getTicketManagerFriendlyName(endpoint)}`;

      default:
        return "";
    }
  }, [ticket_endpoint]);

  const uniqueEdges = useMemo(() => {
    return uniqBy(transitions, "source");
  }, [transitions]);

  const topFirstTransition = useMemo(() => {
    const numOfEdges =
      ticket_endpoint?.ticket_provider?.type === ticketManagersEnum.SEEMPLICITY
        ? uniqueEdges?.length
        : uniqueEdges?.length - 1;

    const edgesHeight = numOfEdges * EDGE_SPACE + BADGE_HEIGHT;
    const top = (NODE_HEIGHT - edgesHeight) / 2;
    return top + BADGE_HEIGHT / 2;
  }, [uniqueEdges?.length, ticket_endpoint]);

  const addStatusTop = useMemo(() => {
    return (
      uniqueEdges?.length * EDGE_SPACE + topFirstTransition - BADGE_HEIGHT / 2
    );
  }, [topFirstTransition, uniqueEdges?.length]);

  return (
    <div className={`rmq-node-wrapper`}>
      <div className={`rmq-node`}>
        <Handle
          id={`${id}`}
          type="target"
          position="left"
          className={`target-handle`}
        />
        <div className={`rmq-node-data is-editable`}>
          <div className={`rmq-node-header nodrag`}>
            <div
              className={`rmq-name`}
              title={title}
              onClick={() => history.push(`/remediationQueue/${id}`)}
            >
              {title}
            </div>
            {editable && (
              <div className={`rmq-node-operations`}>
                <Button
                  isClean
                  className={`edit-queue-btn`}
                  isIcon="seem-icon-edit"
                  isIconGhost
                  type={`button`}
                  title={"Edit Queue"}
                  onClick={() => history.push(`/remediationQueue/${id}`)}
                />
                <Button
                  isClean
                  isIcon="seem-icon-bin"
                  isIconGhost
                  type={`button`}
                  title={"Delete Queue"}
                  onClick={() => {
                    showDialog({
                      title: "Delete Queue?",
                      message: (
                        <>
                          <p>
                            Are you sure you want to delete the Remediation
                            Queue?
                          </p>
                          {endpoint === ticketManagersEnum.JIRA ||
                          endpoint === ticketManagersEnum.MONDAY ||
                          endpoint === ticketManagersEnum.SERVICENOW ? (
                            <>
                              <p>
                                {`Deleting this Queue will not remove the ${capitalize(
                                  endpoint
                                )} items from the backlog,`}
                              </p>
                              <p>
                                new tickets will not be opened automatically.
                              </p>
                            </>
                          ) : null}
                        </>
                      ),
                      buttons: [
                        {
                          label: "Cancel",
                        },
                        {
                          "data-testid": "delete-rmq-confirm",
                          isSecondary: true,
                          label: "Delete",
                          onClick: () => {
                            deleteRemediationQueue({ variables: { id: id } })
                              .then(() => {
                                showToast({ message: "Queue Deleted!" });
                              })
                              .catch((err) => {
                                showDialog({
                                  title: "Error",
                                  type: "error",
                                  message: err.message,
                                  buttons: [
                                    {
                                      isSecondary: true,
                                      label: "Close",
                                    },
                                  ],
                                });
                              });
                          },
                        },
                      ],
                    });
                  }}
                  data-testid={"delete-rmq"}
                />
              </div>
            )}
          </div>
          <div className={`separator`} />
          <ul className={`rmq-node-items`}>
            <li className={`rmq-node-item`}>
              <i className={`seem-icon seem-icon-scope rmq-node-item-icon`} />
              <span className={"rmq-node-item-span"}>{scope}</span>
            </li>
            <li className={`rmq-node-item`}>
              <i
                className={`seem-icon seem-icon-filter-saved rmq-node-item-icon`}
              />
              <span className={"rmq-node-item-span"}>{filter}</span>
            </li>
          </ul>
          <div className={`separator`} />
          <div className={`rmq-node-action`}>
            <div className={`rmq-node-action-info`}>
              {ticket_endpoint?.ticket_provider?.type ===
              ticketManagersEnum.SEEMPLICITY ? (
                <i
                  className={`seem-icon seem-icon-seemplicity-mark rmq-node-action-icon`}
                />
              ) : (
                <img
                  className={`rmq-node-action-icon`}
                  alt={`${ticket_endpoint?.ticket_provider?.type}`}
                  src={
                    dataSourceIcons[ticket_endpoint?.ticket_provider?.type]?.S
                  }
                />
              )}
              <span className={`action-name`}>{`${action}`}</span>
            </div>
            <div
              className={`rmq-status`}
              title={state === queueStates.ENABLED ? "Synced" : "Not-Synced"}
            >
              <i
                className={`seem-icon-${
                  state === queueStates.ENABLED ? "synced" : "not-synced"
                }`}
              />
            </div>
          </div>
        </div>
        {
          <>
            {uniqueEdges?.map((edge, index) => {
              // Only one handle is required for source
              return (
                <Handle
                  type={"source"}
                  position={"right"}
                  id={edge.source}
                  style={{
                    top: `${index * EDGE_SPACE + topFirstTransition}px`,
                  }}
                  className={`source-handle`}
                />
              );
            })}
          </>
        }
        {ticket_endpoint?.ticket_provider?.type ===
          ticketManagersEnum.SEEMPLICITY && (
          <>
            <Handle
              type={"source"}
              position={"right"}
              id={`add-queue-source`}
              style={{ top: `${addStatusTop}px` }}
              className={`source-handle`}
            />
            <Handle
              id={`add-queue-target`}
              type="target"
              position="right"
              style={{ top: `${addStatusTop}px` }}
              className={`target-handle`}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RMQNode;
