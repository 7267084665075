import { gql } from "@apollo/client";

export const GET_PREFERENCES = gql`
  query {
    preferences {
      ws_ticket
      customer_preferences {
        sla_preferences {
          edges {
            node {
              group {
                enabled
              }
            }
          }
        }
      }
      user_preferences {
        user_actions {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
