import Button from "../../../../Forms/Controls/Button/Button";
import React, { useContext } from "react";
import "./TicketingSystemDetailsInstanceRow.scss";
import { useTranslation } from "react-i18next";

const TicketingSystemDetailsInstanceRow = ({
  instance,
  IntegrationContext,
}) => {
  const { handleInstanceEditing } = useContext(IntegrationContext);
  const { t } = useTranslation();

  return (
    <div className={"ts-instance-wrapper"}>
      <div
        className={"ts-instance-cell ts-instance-name"}
        title={instance.known_identifier}
      >
        {instance.known_identifier}
      </div>
      <div className={"ts-instance-cell ts-instance-sync"}>
        {instance.is_installed ? (
          t("Installed")
        ) : (
          <Button
            wrapperClassName={"ts-instance-details"}
            data-testid={"ts-instance-details"}
            type={"button"}
            label={t("Continue Installation")}
            textLink
            onClick={() => {
              handleInstanceEditing(instance.id);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TicketingSystemDetailsInstanceRow;
