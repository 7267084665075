import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, FormProvider } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import DateInput from "../../../Forms/Controls/DateInput/DateInput";
import { startCase } from "lodash";
import "./ManualFinding.scss";
import { manualFindingModes, filterTypes } from "../../FindingsUtils";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_FINDINGS, GET_FIELDS } from "../ManualFindingsApi";
import FilterDataField from "./FilterDataField/FilterDataField";
import CheckBox from "../../../Forms/Controls/CheckBox/CheckBox";
import moment from "moment";
import Loader from "../../../Components/Loader/Loader";
import Spinner from "../../../images/loader.svg";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import SeemaOK from "../../../Forms/Dialogs/Toast/SeemaOK";
import ReactTooltip from "react-tooltip";
import { Collapse } from "react-collapse";
import SliderInput from "../../../Forms/Controls/Slider/Slider";
import { useHistory } from "react-router-dom";
import { GET_FINDING_BY_ID } from "../../FindingDetailsPane/DetailsPaneApi";
import { GET_FINDINGS } from "../../FindingsData/FindingsList/FindingsListApi";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";

const ManualFinding = ({ mode, findingData = null }) => {
  const methods = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      cloud_account: null,
      resource_name: null,
      resource_type: null,
      score: "",
      date: moment().format("YYYY-MM-DD"),
      description: "",
      remediationText: "",
      remediationLink: "",
    },
  });

  const { control, formState, reset: formReset } = methods;
  const { isValid, errors } = formState;
  const { closeModal } = useContext(ModalContext);
  const modalRef = useRef();
  let history = useHistory();
  const additionalDataRef = useRef(null);

  const [filterFields, setFiltersFields] = useState(null);
  const [scopeFields, setScopesFields] = useState(null);
  const [creatingAnother, setCreatingAnother] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [createdFinding, setCreatedFinding] = useState(null);
  const [additionalDataIsOpen, toggleAdditionalData] = useState(false);

  const { data: filtersFieldsData } = useQuery(GET_FIELDS, {
    variables: { filterType: filterTypes.FILTER },
  });
  const { data: scopesFieldsData } = useQuery(GET_FIELDS, {
    variables: { filterType: filterTypes.SCOPE },
  });

  const queryArray = useMemo(
    () => [
      { query: GET_FINDINGS, variables: { with_total_count: false } },
      "GetFindingsByGroup",
      ...(mode === manualFindingModes.EDIT && !!findingData
        ? [{ query: GET_FINDING_BY_ID, variables: { id: findingData?.id } }]
        : []),
    ],
    [findingData?.id]
  );

  const [createManualFindings] = useMutation(CREATE_FINDINGS, {
    refetchQueries: queryArray,
  });

  const getLabelText = (field, required) => {
    let text = startCase(field);
    if (!required) {
      text += " (Optional)";
    }

    return text;
  };

  useEffect(() => {
    if (!!filtersFieldsData && filtersFieldsData.finding_filters_data.length) {
      const fieldsArr = filtersFieldsData.finding_filters_data.map(
        (el) => el.field
      );

      setFiltersFields(fieldsArr);
    }
  }, [filtersFieldsData]);

  useEffect(() => {
    if (!!scopesFieldsData && scopesFieldsData.finding_filters_data.length) {
      const fieldsArr = scopesFieldsData.finding_filters_data.map(
        (el) => el.field
      );

      setScopesFields(fieldsArr);
    }
  }, [scopesFieldsData]);

  const getTextInputField = (
    field,
    label,
    placeholder,
    required,
    showTooltip = false,
    withHelp
  ) => {
    let fieldRules = {};
    if (required) {
      fieldRules.required = "Required";
    }

    return (
      <Controller
        wrapperClassName={``}
        name={field}
        defaultValue={""}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <>
            {showTooltip && !!value && (
              <ReactTooltip
                id={`enter-${field}`}
                place="top"
                className={"copy-tooltip"}
              >
                {value}
              </ReactTooltip>
            )}
            <TextInput
              withHelp={withHelp}
              labelTop
              tooltipData={{ dataTip: true, dataFor: `enter-${field}` }}
              onBlur={onBlur}
              inputRef={ref}
              name={`enter-${field}`}
              wrapperClassName={``}
              className={`create-finding-${field}-field`}
              data-testid={field}
              onChange={(e) => onChange(e)}
              error={errors && errors[field] ? errors[field] : null}
              label={label}
              size="max"
              value={value}
              inputStyle={"bomba-style"}
              placeholder={placeholder}
            />
          </>
        )}
        control={control}
        rules={{ ...fieldRules }}
      />
    );
  };

  const getScoreField = () => (
    <Controller
      name={`score`}
      defaultValue={""}
      render={({ field: { value, onChange } }) => (
        <SliderInput
          data-testid={`score`}
          min={1}
          max={10}
          step={1}
          defaultValue={null}
          onChange={(val) => onChange(val)}
          value={value}
          label={"Score"}
          inputStyle={"bomba-style"}
          className="create-finding-score-field"
          error={errors && errors.score ? errors.score : null}
          labelTop
          marks={{
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
          }}
        />
      )}
      control={control}
      rules={{
        required: "Required",
        pattern: {
          value: /^(?:[1-9]|10)?(?:\.\d+)?$/,
          message: "Invalid Score",
        },
      }}
    />
  );

  const getDateField = () => (
    <Controller
      name={`date`}
      defaultValue={moment().format("YYYY-MM-DD")}
      render={({ field: { value, onChange, onBlur, ref } }) => (
        <DateInput
          onBlur={onBlur}
          inputRef={ref}
          name={"enter-date"}
          labelTop
          wrapperClassName={``}
          className={"create-finding-date-field"}
          data-testid={`date-input`}
          onChange={(e) => onChange(e)}
          maxDate={moment().format("YYYY-MM-DD")}
          error={errors && errors.date ? errors.date : null}
          label={"Discovery Date"}
          value={value}
          inputStyle={"bomba-style"}
          size={"max"}
        />
      )}
      control={control}
      rules={{ required: "Required" }}
    />
  );

  useEffect(() => {
    if (mode === manualFindingModes.EDIT && !!findingData) {
      const findingExistingData = {};
      for (const key in findingData) {
        if (key !== "resource_type" && key !== "cloud_account") {
          findingExistingData[key] = findingData[key];
        } else {
          findingExistingData[key] = {
            label: findingData[key],
            value: findingData[key],
          };
        }
      }
      formReset(findingExistingData);
    }
  }, [findingData, mode]);

  useEffect(() => {
    if (additionalDataIsOpen) {
      additionalDataRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [additionalDataIsOpen]);

  const fieldExist = (field, type) => {
    if (type === "FILTER") {
      return !!filterFields && filterFields.includes(field);
    }

    if (type === "SCOPE") {
      return !!scopeFields && scopeFields.includes(field);
    }
  };

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    let mutationData = {
      title: data?.title,
      description: data?.description,
      original_score: parseInt(data?.score),
      discovered_time: data?.date,
      remediation: {
        text: data?.remediationText,
        url: data?.remediationLink,
      },
      resources: [
        {
          resource_name: data?.resource_name,
          resource_type: data?.resource_type?.value || "other",
        },
      ],
    };

    if (data?.cloud_account?.value) {
      mutationData.resources.unshift({
        resource_name: data?.cloud_account?.value,
        resource_type: "cloud_account",
      });
    }

    if (mode === manualFindingModes.EDIT && !!findingData && findingData?.id) {
      mutationData.id = findingData?.id;
    }

    createManualFindings({ variables: { manual_findings: [mutationData] } })
      .then((res) => {
        if (creatingAnother) {
          setIsSubmittingForm(false);
          formReset();
          modalRef?.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setCreatedFinding({
            link: `findings/${res?.data?.add_manual_findings?.findings?.[0]?.id}`,
            idInt: res?.data?.add_manual_findings?.findings?.[0]?.id_int,
          });
        } else {
          closeModal();
          showToast({
            message: `${
              mode === manualFindingModes.CREATE
                ? "Finding Created Successfully"
                : "Finding Updated"
            } `,
          });
          setIsSubmittingForm(false);
          history.push(
            `/findings/${res?.data?.add_manual_findings?.findings?.[0]?.id}`
          );
        }
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showDialog({
          title: "Error",
          type: "error",
          message: err.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };

  const handleKeyDown = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className={`create-finding-wrapper`}>
      {!filterFields?.length && !scopeFields?.length ? (
        <div className={"create-finding-form"}>
          <Loader center img={Spinner} />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            className="create-finding-form"
            onSubmit={methods.handleSubmit(onSubmit)}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            <div className="create-finding-form-content" ref={modalRef}>
              {!!createdFinding ? (
                <div className={"create-finding-msg-wrapper"}>
                  <div className={`create-finding-msg`}>
                    <SeemaOK />
                    <div>
                      {"Finding "}
                      <a
                        href={createdFinding.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {createdFinding.idInt}
                      </a>
                      {`  has been successfully created.`}
                    </div>
                  </div>
                  <i
                    className={"seem-icon-close"}
                    onClick={() => setCreatedFinding(null)}
                  />
                </div>
              ) : (
                <div className={"create-finding-msg-wrapper not-visible"} />
              )}

              {getTextInputField(
                "title",
                getLabelText("title", true),
                `Type your finding’s title`,
                true
              )}
              {getTextInputField(
                "resource_name",
                "Resource / URL",
                "Paste a URL or search for an existing resource",
                true,
                true,
                "Enter the Resource Name or the relevant URL coming from PT or Bug Bounty"
              )}
              {getDateField()}
              {getScoreField()}
              {getTextInputField(
                "description",
                getLabelText("description", false),
                "Describe your finding",
                false,
                true
              )}

              <div className={`additional-data-container`}>
                <div
                  className={`additional-data-item-toggle`}
                  onClick={() => toggleAdditionalData(!additionalDataIsOpen)}
                  data-testid={`create-finding-additional-data`}
                >
                  <div
                    className={`additional-data-item-toggle-icon-wrap ${
                      additionalDataIsOpen ? "is-open" : "is-closed"
                    }`}
                  >
                    <i className="seem-icon-right-arrow" />
                  </div>
                  <span className="additional-data-title">
                    Additional Data (Optional)
                  </span>
                  {!additionalDataIsOpen && (
                    <span className={`additional-data-sub-title`}>
                      Cloud Account, Resource Type, Remediation Text,
                      Remediation Link
                    </span>
                  )}
                </div>

                <Collapse isOpened={additionalDataIsOpen}>
                  <div
                    className={`additional-data-fields`}
                    ref={additionalDataRef}
                  >
                    {fieldExist("cloud_account", "SCOPE") && (
                      <FilterDataField
                        label={`Cloud Account`}
                        field={"cloud_account"}
                        isCreateable
                      />
                    )}

                    {fieldExist("resource_type", "SCOPE") && (
                      <FilterDataField
                        label={`Resource Type`}
                        field={"resource_type"}
                        isCreateable
                      />
                    )}
                    {getTextInputField(
                      "remediationText",
                      "Remediation Text",
                      "Type Remediation Text",
                      false,
                      true
                    )}
                    {getTextInputField(
                      "remediationLink",
                      "Remediation Link",
                      "Insert Remediation Link",
                      false
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className="create-finding-form-footer">
              <ModalButtonsContainer>
                {mode === manualFindingModes.CREATE && (
                  <CheckBox
                    data-testid={"create-finding-checkbox"}
                    value={creatingAnother}
                    checked={creatingAnother}
                    label={"Create another"}
                    className={"create-finding-checkbox-field"}
                    onChange={() => setCreatingAnother(!creatingAnother)}
                  />
                )}
                <Button
                  data-testid={"submit-finding"}
                  type="submit"
                  label={`${mode === "create" ? "Create" : "Save"}`}
                  isSecondary
                  disabled={!isValid || isSubmittingForm}
                />
              </ModalButtonsContainer>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default ManualFinding;
