import React from "react";
import PropTypes from "prop-types";

const SeemaOK = ({ className, isLarge }) => {
  return (
    <div
      className={`seema-wrap ${isLarge ? "is-large" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="seema-success-wrap">
        <div className="seema-face" />
        <div className="seema-twiches" />
        <div className="seema-thumb" />
      </div>
    </div>
  );
};

SeemaOK.defaultProps = {
  className: "",
  isLarge: false,
};

SeemaOK.propTypes = {
  className: PropTypes.string,
  isLarge: PropTypes.bool,
};

export default SeemaOK;
