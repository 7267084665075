import React, { useState } from "react";
import TextEditor from "../../../Components/TextEditor/TextEditor";
import Tabs from "../../../Components/Tabs/Tabs";
import "../SAMLSettings.scss";
import "./SAMLInstructions.scss";
import { instructionsOptions } from "./SAMLInstructionsUtils";

const SAMLInstructions = () => {
  const [activeItem, setActiveItem] = useState(instructionsOptions[0]);

  const handleSelectTab = (item) => {
    setActiveItem(item);
  };

  return (
    <div className={"saml-instructions-wrapper"}>
      <div className={"sub-title"}>Instructions</div>
      <Tabs
        menuItems={instructionsOptions}
        onSelectTab={handleSelectTab}
        activeId={`${activeItem?.pageLink}`}
      />
      <TextEditor
        customHeight={"380px"}
        wrapperClassName={"saml-instructions-editor"}
        data-testid={"saml-instructions-editor"}
        toolbar={[]}
        disabled={true}
        readOnly={true}
        size="max"
        value={activeItem?.content}
        onChange={() => {}}
        labelTop
        inputStyle={"bomba-style"}
      />
    </div>
  );
};

export default SAMLInstructions;
