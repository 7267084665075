import { gql } from "@apollo/client";

export const GET_FINDING_FILTERS = gql`
  query filters {
    finding_filters(filters: { visible: true }) {
      edges {
        node {
          name
          filter_data
          filter_type
          id
          editable
          created_by
          system
          popular
          default
        }
        cursor
      }
      pageInfo {
        hasNextPage
        startCursor
      }
    }
  }
`;

export const GET_SCOPES = gql`
  query scopes {
    scope_hierarchy {
      scope_hierarchy
    }
  }
`;

export const GET_FILTERS_DATA = gql`
  query getFiltersData($filterType: FindingFilterTypeEnum!) {
    finding_filters_data(filter_type: $filterType) {
      field
      conditions
      type
    }
  }
`;

export const SAVE_FILTER = gql`
  mutation SaveFilter(
    $name: String!
    $filterData: JSONString!
    $filterType: FindingFilterTypeEnum!
    $scope_group: String
  ) {
    new_finding_filter(
      finding_filter: {
        name: $name
        filter_data: $filterData
        filter_type: $filterType
        scope_group: $scope_group
      }
    ) {
      finding_filter {
        name
        filter_data
        id
      }
    }
  }
`;

export const UPDATE_FILTER = gql`
  mutation UpdateFilter(
    $name: String!
    $filterData: JSONString!
    $filterType: FindingFilterTypeEnum!
    $id: String!
    $scope_group: String
    $overwrite: Boolean
  ) {
    update_finding_filter(
      finding_filter: {
        name: $name
        filter_data: $filterData
        filter_type: $filterType
        scope_group: $scope_group
      }
      finding_filter_id: $id
      overwrite: $overwrite
    ) {
      ok
      effected_queues
    }
  }
`;

export const DELETE_FILTER = gql`
  mutation DeleteFilter($id: String!) {
    delete_finding_filter(finding_filter_id: $id) {
      ok
    }
  }
`;

export const GET_FILTER_ROW_VALUES = gql`
  query getFilterRowValues(
    $filterName: String!
    $contains: String
    $limit: Int
  ) {
    finding_filter_data(
      filter_name: $filterName
      contains: $contains
      limit: $limit
    ) {
      values
      type
      conditions
      field
    }
  }
`;
