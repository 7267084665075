import Popover from "react-popover";
import React, { useCallback, useState } from "react";
import ScheduleSettings from "./ScheduleSettings/ScheduleSettings";
import { getCronText } from "./ScheduleUtils";
import "./Schedule.scss";
import ClassNames from "classnames";
import PropTypes from "prop-types";

export const formatTime = (time) => {
  return !time ? time : time < 10 ? `0${time}` : time;
};

const Schedule = (props) => {
  const [toggleSchedule, setToggleSchedule] = useState(false);

  const {
    onChange,
    value,
    label,
    inputStyle,
    labelTop,
    size,
    required,
    disabled,
    readOnly,
    labelWidth,
    wrapperClassName,
    error,
  } = props;

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-schedule",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-required": required,
      "is-disabled": disabled,
      "has-read-only": readOnly,
      "is-focused": toggleSchedule,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "has-error": !!error,
    },
    props.className
  );
  const getCronExpressionText = useCallback(() => {
    if (!!value) {
      return getCronText(value);
    }
  }, [value]);

  return (
    <Popover
      isOpen={toggleSchedule}
      tipSize={0.01}
      place={"below"}
      body={[
        <ScheduleSettings
          toggleSchedule={toggleSchedule}
          setToggleSchedule={setToggleSchedule}
          onChange={onChange}
          value={value}
        />,
      ]}
    >
      <div
        className={`input-wrap input-wrap-schedule has-label has-size-m ${classNameWrapper} ${
          toggleSchedule ? "is-focused" : ""
        }`}
      >
        {label && <label className={`input-label`}>{label}</label>}
        <div
          // ref={selectWrapperRef}
          className={`input-holder schedule-input-container`}
          onClick={(e) => {
            setToggleSchedule(!toggleSchedule);
            e.stopPropagation();
          }}
          data-testid={"open-schedule-btn"}
        >
          <span className={`schedule-value-label`}>
            {getCronExpressionText() ? (
              getCronExpressionText()
            ) : (
              <span className="placeholder-text">Define a schedule</span>
            )}
          </span>
          <i className={`seem-icon seem-icon-schedule`} />
        </div>
      </div>
    </Popover>
  );
};

Schedule.defaultProps = {
  // type: "text",
  labelTop: false,
  size: "m",
  required: false,
  disabled: false,
  readOnly: false,
  labelWidth: false,
  inputStyle: "",
  onChange: () => {},
  placeholder: "",
  min: null,
};

Schedule.propTypes = {
  wrapperClassName: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  labelTop: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  labelWidth: PropTypes.string,
  inputStyle: PropTypes.string,
};

export default Schedule;
