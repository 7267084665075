import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import "./LoginPage.scss";
import { signIn, NEW_PASSWORD_REQUIRED } from "../AuthApi";
import { AuthContext } from "../AuthContext";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import { EMAIL_PATTERN_REGEX } from "../../Forms/FormValidations";
import { useTranslation } from "react-i18next";
import Button from "../../Forms/Controls/Button/Button";
import { MFAType } from "../MFA/MFAUtils";

const SEEMPLICITY = "seemplicity.io";
const LoginPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { updateAuthState, authState } = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  let history = useHistory();

  const onSubmit = (data) => {
    login(data);
  };

  const login = (data) => {
    const { username, password } = data;

    setIsSubmitting(true);
    signIn(username?.toLowerCase(), password)
      .then((user) => {
        const email = username;
        const domain = email?.substring(email?.lastIndexOf("@") + 1);
        const isDemoAccount = domain?.toLowerCase() === SEEMPLICITY;
        updateAuthState({
          ...authState,
          user: user,
          challengeName: user.challengeName,
          username: username.toLowerCase(),
          domain: domain,
          isDemoAccount: isDemoAccount,
          mfaType: user?.preferredMFA,
        });

        if (user.challengeName === NEW_PASSWORD_REQUIRED) {
          history.push("/changePassword");
        } else if (user.challengeName === MFAType.SOFTWARE_TOKEN_MFA) {
          history.push("/mfa");
        } else {
          history.push("/dashboard/organizational");
        }

        reset();
      })
      .catch((err) => {
        updateAuthState({ ...authState, error: err.code });
        setIsSubmitting(false);

        if (
          err.code === "NotAuthorizedException" ||
          err.code === "UserNotFoundException"
        ) {
          setError("password", {
            type: "error",
            message: t("Invalid Username/Password"),
          });
        } else {
          setError("password", {
            type: "error",
            message: t("An error occurred. Login failed."),
          });
        }
      });
  };

  return (
    <>
      <h2 className="login-page-title">{t("Sign in to Your Account")}</h2>
      <form className="login-page-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          wrapperClassName="login-username"
          name={"username"}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextInput
                placeholder={"USERNAME"}
                size="max"
                isLarge
                type={"text"}
                error={errors && errors.username ? errors.username : null}
                disabled={isSubmitting}
                value={value}
                onChange={(val) => onChange(val)}
                onBlur={onBlur}
                autoComplete={true}
                data-testid={"login-username"}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
            pattern: {
              value: EMAIL_PATTERN_REGEX,
              message: t("Invalid username"),
            },
          }}
          disabled={isSubmitting}
        />
        <Controller
          wrapperClassName="login-password"
          name={"password"}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextInput
                disabled={isSubmitting}
                error={errors && errors.password ? errors.password : null}
                placeholder={"PASSWORD"}
                size="max"
                isLarge
                type={"password"}
                value={value}
                onChange={(val) => onChange(val)}
                onBlur={onBlur}
                autoComplete={true}
                data-testid={"login-password"}
              />
            );
          }}
          control={control}
          rules={{ required: "Required" }}
          disabled={isSubmitting}
        />

        <Button
          data-testid={"login-submit"}
          type="submit"
          label={"Login"}
          rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
          isSecondary
          disabled={isSubmitting}
          className="login-btn"
          isLarge
        />
      </form>
    </>
  );
};

export default LoginPage;
