import React from "react";
import LinksMenu from "../../../Components/LinksMenu/LinksMenu";

const NavigationMenu = ({ menuItems }) => (
  <div className={`details-pane-navigator-wrapper`}>
    <LinksMenu
      menuItems={menuItems}
      containerId={`details-pane-data-container`}
    />
  </div>
);

export default NavigationMenu;
