import React, { useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { startCase, toLower } from "lodash";
import useChart from "../../../Components/Chart/useChart";
import Chart from "../../../Components/Chart/Chart";
import { ticketStatus } from "../../../Ticketing/TicketingUtils";
import { getFilterToExecute } from "../../DashboardUtils";
import useDashboardNavigator from "../../useDashboardNavigator";
import useSummary from "../../useSummary";

const getTicketColor = (type) => {
  switch (type) {
    case "BACKLOG":
      return "#8DC9F3";
    case "SCHEDULED":
      return "#5565f9";
    case "IN_PROGRESS":
      return "#A673F1";
    case "REJECTED":
      return "#ec2a3f";
    default:
      return "#2dbf9f";
  }
};

const FindingsByTicketStatus = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
}) => {
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id"],
      group_by: ["finding.category", "ticket.status"],
      aggregate_functions: ["COUNT"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });
  const [dashboardData, setDashboardData] = useState(null);
  const [totalFindings, setTotalFindings] = useState(0);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const createYAxis = (yAxis) => {
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.minGridDistance = 20;
    yAxis.paddingBottom = 14;
  };

  const createXAxis = (xAxis) => {
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;
    xAxis.min = 0;
    xAxis.max = totalFindings;
  };

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const categories = [];
      const dataObj = {};

      for (let i = 0; i < data.length; i += 1) {
        const category = data[i][0] || "Missing Category";
        if (!categories.includes(category)) {
          categories.push(category);
          dataObj[category] = {};
        }

        const status = data[i][1];

        const findingsAmount = data[i][2];

        dataObj[category][status] = findingsAmount;
      }

      const dataArray = [];
      let max = 0;

      for (const category in dataObj) {
        let total = Object.keys(dataObj[category]).reduce(
          (prev, next) => prev + dataObj[category][next],
          0
        );
        if (total > max) {
          max = total;
        }

        const statuses = [];
        for (let i = 0; i < Object.keys(dataObj[category]).length; i += 1) {
          const statusType = Object.keys(dataObj[category])[i];
          const findingsAmount = dataObj[category][statusType];
          if (statusType === "DELETED") {
            total = total - findingsAmount;
          } else {
            const from = i === 0 ? 0 : statuses[i - 1].to;
            const to = from + findingsAmount;
            statuses[i] = {
              category:
                category !== "Missing Category"
                  ? category.toUpperCase()
                  : category,
              from,
              to,
              findingsAmount,
              name: startCase(toLower(statusType)),
              fill: getTicketColor(statusType),
            };
          }
        }
        dataArray.push(...statuses);
      }

      setTotalFindings(max);

      if (dataArray?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (dataArray?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(dataArray);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      let [chart, legendContainer] = createChart(
        "ticketStatusCategoryDashboard",
        am4charts.XYChart
      );
      chart.data = dashboardData;

      const legendKeys = [];
      const legendData = [];
      dashboardData?.forEach((el) => {
        if (!legendKeys.includes(el.name)) {
          legendKeys.push(el.name);
          legendData.push(el);
        }
      });

      let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      createYAxis(yAxis);
      yAxis.dataFields.category = "category";
      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      createXAxis(xAxis);

      let label = yAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 120;
      label.textAlign = "middle";

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "to";
      series.dataFields.openValueX = "from";
      series.dataFields.categoryY = "category";
      series.columns.template.height = am4core.percent(50);
      series.columns.template.maxHeight = 40;
      series.columns.template.propertyFields.fill = "fill";
      series.columns.template.tooltipText =
        "[bold]{name}[/]\n[font-size:14px] {findingsAmount}";
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = "white";
      series.columns.template.fillOpacity = 0.6;
      series.columns.template.strokeOpacity = 1;
      series.columns.template.propertyFields.stroke = "fill";
      series.columns.template.cursorOverStyle =
        am4core.MouseCursorStyle.pointer;

      series.columns.template.events.on("hit", (e) => {
        let selectedStatus;
        for (let key in ticketStatus) {
          if (ticketStatus[key].text === e.target.dataItem.dataContext.name) {
            selectedStatus = key;
            break;
          }
        }
        dashboardNavigator(
          null,
          null,
          null,
          getFilterToExecute({
            value: selectedStatus,
            type: "ticket_status",
          })
        );
      });

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.dy = 33;
      labelBullet.dx = -10;
      labelBullet.label.text = "{findingsAmount}";

      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      chart.legend.useDefaultMarker = true;
      chart.legend.data = legendData;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"ticketStatusCategoryDashboard"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default FindingsByTicketStatus;
