import React, { useState } from "react";
export const GeneralAppContext = React.createContext({
  appClass: "",
  layoutClass: "",
});

const GeneralAppProvider = ({ children }) => {
  const [appContext, setAppContext] = useState({
    appClass: "",
    layoutClass: "",
    isFiltersToggleOpen: false,
  });

  const updateAppState = (state) => {
    setAppContext({ ...appContext, ...state });
  };

  return (
    <GeneralAppContext.Provider value={{ appContext, updateAppState }}>
      {children}
    </GeneralAppContext.Provider>
  );
};

export default GeneralAppProvider;
