import Popover from "react-popover";
import React, { useEffect, useMemo, useState } from "react";
import {
  conditionDateLabel,
  conditionLabel,
} from "../../../../../Findings/FindingsFilters/FilterRow/FilterRowUtils";
import { useFormContext, useWatch } from "react-hook-form";
import "./FilterCondition.scss";
import ClassNames from "classnames";

const FilterCondition = ({
  namePrefix,
  onChange,
  value,
  fieldsData,
  isEditable,
  setMenuOpen,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const { control } = useFormContext();
  const fieldValue = useWatch({
    control,
    name: `${namePrefix}.field`,
  });

  const optionsList = fieldsData?.filter(
    (selectedField) => selectedField.field === fieldValue
  )?.[0];

  const conditionOptions = useMemo(() => {
    if (fieldValue === "actual_status") {
      return [
        {
          label: "=",
          value: "in",
        },
      ];
    }
    return optionsList?.conditions
      ?.filter((conditionOption) => conditionOption !== "range")
      .map((conditionOption) => {
        if (
          optionsList?.field === "due_date" ||
          optionsList?.field === "discovered_time" ||
          optionsList?.field === "last_reported_time"
        ) {
          return {
            label: conditionDateLabel[conditionOption],
            value: conditionOption,
          };
        }
        return {
          label: conditionLabel[conditionOption],
          value: conditionOption,
        };
      });
  }, [optionsList?.conditions, optionsList?.field]);

  const conditionData = useMemo(() => {
    if (value === "actual_status") {
      return {
        label: "=",
        value: "in",
      };
    }
    return (
      conditionOptions?.filter(
        (selectedCondition) => selectedCondition.value === value
      )?.[0] || null
    );
  }, [conditionOptions, value]);

  useEffect(() => {
    if (!!setMenuOpen) {
      setMenuOpen({ condition: isOpen });
    }
  }, [isOpen]);

  const classNameWrapper = ClassNames("condition-selected-value", {
    "is-active": isOpen,
  });

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[
        <div className={`filter-dropdown-wrap condition-filter`}>
          {conditionOptions?.map((field) => (
            <div
              className={`filter-dropdown-item-wrap ${
                value === field.value ? "is-selected" : ""
              }`}
              onClick={() => {
                onChange(field);
                toggleIsOpen(false);
              }}
              title={field.label}
            >
              <div className="filter-dropdown-item">{field.label}</div>
            </div>
          ))}
        </div>,
      ]}
      enterExitTransitionDurationMs={0}
      onOuterAction={() => setTimeout(() => toggleIsOpen(false), 200)}
    >
      <div
        title={conditionData?.label}
        className={`${classNameWrapper} condition-selected-value ${
          !isEditable ? "is-disabled" : ""
        } ${isOpen ? "is-active" : ""}`}
        onClick={() => {
          if (isEditable) {
            toggleIsOpen(!isOpen);
          }
        }}
      >
        {conditionData?.label}
      </div>
    </Popover>
  );
};

export default FilterCondition;
