import React, { useContext, useEffect, useMemo } from "react";
import { FindingsContext } from "../../../FindingsContext";
import FindingNavigationCarousel from "./FindingNavigationCarousel/FindingNavigationCarousel";
import { capitalize } from "lodash";
import FindingsNavigationItem from "./FindingsNavigationItem/FindingsNavigationItem";
import {
  getFindingSeverity,
  groupByTypes,
  findingsQueryParams,
  NAVIGATION_ITEM_WIDTH,
  getGroupName,
  NAVIGATION_ITEMS_PAGE_SIZE,
  getGroupTitle,
} from "../../../FindingsUtils";
import CloudAccountNavigationTreemap from "./CloudAccountNavigationTreemap/CloudAccountNavigationTreemap";
import useFindingsQueryParam from "../../useFindingsQueryParam";

const FindingsGroupsResults = ({ groupBy, datasources }) => {
  const {
    findingsState: { dataStructure, groupsList, hasSelectedItem },
    updateFindingsState,
    selectFindingInList,
  } = useContext(FindingsContext);
  const {
    clearQueryParam,
    search,
    param: groupByValueFromURL,
  } = useFindingsQueryParam(findingsQueryParams.GROUP_VALUE);

  useEffect(() => {
    if (!!groupByValueFromURL && dataStructure.size) {
      const groupKey = Array.isArray(groupByValueFromURL)
        ? JSON.stringify(groupByValueFromURL)
        : groupByValueFromURL;
      let rowIndex;
      let groupIndex;
      if (typeof dataStructure?.get(groupKey)?.groupId === "string") {
        groupIndex = dataStructure?.get(groupKey)?.groupIndex;
      } else {
        groupIndex = dataStructure?.get(groupKey)?.groupId;
      }
      rowIndex = dataStructure?.get(groupKey)?.rowIndex + groupIndex;
      const groupRowCursor = btoa(
        `arrayconnection:${
          dataStructure?.get(groupKey)?.rowIndex - 50 > 0
            ? dataStructure?.get(groupKey)?.rowIndex - 50
            : 0
        }`
      );
      const groupRowObj = { index: rowIndex, cursor: groupRowCursor };

      onGroupChange(groupRowObj);
    }
  }, [groupByValueFromURL, dataStructure]);

  const getCarouselWidth = useMemo(() => {
    if (groupsList?.findings_groups) {
      let items =
        groupsList?.findings_groups?.length <= NAVIGATION_ITEMS_PAGE_SIZE
          ? groupsList?.findings_groups?.length
          : NAVIGATION_ITEMS_PAGE_SIZE;
      return items * NAVIGATION_ITEM_WIDTH;
    }

    return 0;
  }, [groupsList]);

  const onGroupChange = (rowIndex) => {
    if (!rowIndex.index && rowIndex.index !== 0) {
      return;
    }

    updateFindingsState({
      scrollToIndex: rowIndex,
    });
  };

  const onGroupClicked = (rowIndex) => {
    if (!!search) {
      clearQueryParam([findingsQueryParams.GROUP_VALUE]);
    }
    hasSelectedItem && selectFindingInList(null, false);
    onGroupChange(rowIndex);
  };

  const getGroupsNavigationData = () => {
    return groupsList?.findings_groups?.map((groupItem, groupIndex) => {
      let groupName =
        getGroupName(groupItem) || groupBy?.selectedOptionValue?.noData;
      let groupTitle =
        getGroupTitle(groupItem) || groupBy.selectedOptionValue.noData;
      if (groupBy.selectedOptionValue.groupBy === groupByTypes.SEVERITY) {
        groupName = groupItem.group_by[0].value
          ? capitalize(getFindingSeverity(groupItem.group_by[0].value))
          : groupBy.selectedOptionValue.noData;
      }
      let cloudProviderIcon = null;
      if (
        groupBy?.selectedOptionValue?.groupBy === groupByTypes.CLOUD_ACCOUNT
      ) {
        cloudProviderIcon = groupItem?.group_by.filter(
          (selectedItem) => selectedItem.field === groupByTypes.CLOUD_PROVIDER
        )?.[0].value;
      } else if (
        groupBy?.selectedOptionValue?.groupBy === groupByTypes.CLOUD_PROVIDER
      ) {
        cloudProviderIcon =
          getGroupName(groupItem) || groupBy?.selectedOptionValue?.noData;
      }

      let datasourceIcon = null;
      let dataSourceName = null;
      if (groupBy?.selectedOptionValue?.groupBy === groupByTypes.DATASOURCE) {
        if (datasources !== null) {
          datasources?.map((datasource) => {
            if (
              datasource.node.datasource_definition.file_prefix ===
              groupItem.group_by[0].value
            ) {
              datasourceIcon =
                datasource.node.datasource_definition.file_prefix;
              dataSourceName = datasource.node.datasource_definition.name;
            }
          });
        }
      }

      let groupKey =
        getGroupName(groupItem) ||
        groupBy?.selectedOptionValue?.noData + getGroupName(groupItem);

      const groupRowIndex = dataStructure?.get(groupKey)?.rowIndex + groupIndex;
      const groupRowCursor = btoa(
        `arrayconnection:${
          dataStructure?.get(groupKey)?.rowIndex - 50 > 0
            ? dataStructure?.get(groupKey)?.rowIndex - 50
            : 0
        }`
      );

      const groupRowObj = { index: groupRowIndex, cursor: groupRowCursor };

      return {
        groupRowObj,
        groupIndex,
        groupItem,
        groupName,
        groupKey,
        groupTitle,
        cloudProviderIcon,
        datasourceIcon,
        dataSourceName,
        onGroupClick: (groupRowObj) => {
          onGroupClicked(groupRowObj);
        },
      };
    });
  };

  const getGroupsNavigationList = () => {
    const data = getGroupsNavigationData();
    return data.map((navItem) => {
      const {
        groupRowObj,
        groupIndex,
        groupItem,
        groupName,
        groupKey,
        groupTitle,
        cloudProviderIcon,
        datasourceIcon,
        dataSourceName,
      } = navItem;
      return (
        <FindingsNavigationItem
          groupRow={groupRowObj}
          key={groupIndex}
          datasourceIcon={datasourceIcon}
          dataSourceName={dataSourceName}
          groupBy={groupBy.selectedOptionValue.groupBy}
          group={groupItem}
          groupName={groupName}
          groupTitle={groupTitle}
          groupKey={groupKey}
          cloudProviderIcon={cloudProviderIcon}
          onGroupClick={(groupRowObj) => {
            onGroupClicked(groupRowObj);
          }}
        />
      );
    });
  };

  return (
    <div className={`findings-navigation-bar`}>
      {!!groupsList &&
      !!groupsList.findings_groups &&
      !!groupsList.findings_groups.length ? (
        groupBy?.selectedOptionValue?.groupBy === groupByTypes.CLOUD_ACCOUNT &&
        groupsList?.findings_groups?.length > NAVIGATION_ITEMS_PAGE_SIZE ? (
          <CloudAccountNavigationTreemap data={getGroupsNavigationData()} />
        ) : (
          <FindingNavigationCarousel
            itemsCount={groupsList?.findings_groups?.length}
            width={getCarouselWidth}
          >
            {getGroupsNavigationList().map((item) => item)}
          </FindingNavigationCarousel>
        )
      ) : (
        <div className="carousel-wrapper carousel-wrapper-no-results">
          <div className="findings-navigator-carousel">
            <div className="react-multi-carousel-list groups-carousel-list groups-carousel-container">
              <ul className="react-multi-carousel-track">
                <li className="react-multi-carousel-item react-multi-carousel-item--active group-carousel-item">
                  <div className="findings-carousel-item group-by-severity">
                    <div className="findings-carousel-item-top">
                      <div className="sevirity-level sevirity-level-nothing" />0
                    </div>
                    <div className="findings-carousel-item-bottom">
                      <div className="findings-carousel-item-bottom-name">
                        No results
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul className="react-multi-carousel-dot-list ">
              <li data-index="0" className="react-multi-carousel-dot">
                <button aria-label="Go to slide 1" />
              </li>
              <li data-index="1" className="react-multi-carousel-dot ">
                <button aria-label="Go to slide 2" />
              </li>
              <li data-index="2" className="react-multi-carousel-dot ">
                <button aria-label="Go to slide 3" />
              </li>
              <li data-index="3" className="react-multi-carousel-dot ">
                <button aria-label="Go to slide 4" />
              </li>
              <li data-index="4" className="react-multi-carousel-dot ">
                <button aria-label="Go to slide 5" />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default FindingsGroupsResults;
