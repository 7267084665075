import React, { useContext, useCallback, useEffect, useState } from "react";
import Table from "../../Components/Table/Table";
import Avatar from "../../Components/Avatar/Avatar";
import "./UsersList.scss";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Button from "../../Forms/Controls/Button/Button";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import ReactTooltip from "react-tooltip";
import {
  showDialog,
  showErrorDialog,
} from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import UserActions from "./UserActions/UserActions";
import { userActions } from "./UsersListUtils";
import {
  DELETE_USER,
  EDIT_USER,
  GET_USERS,
  INVITE_USER,
  REINVITE_USER,
  GET_CUSTOMER,
} from "./UsersListApi";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import moment from "moment";
import ShowMoreInlineList from "../../Components/ShowMoreInlineList/ShowMoreInlineList";
import { GET_PROFILES } from "../Permissions/PermissionsApi";

const UsersList = () => {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      filters: { not: { activity_status: "DELETED" } },
    },
  });
  const { data: profilesData } = useQuery(GET_PROFILES);
  const [inviteUser] = useMutation(INVITE_USER);
  const [editUser] = useMutation(EDIT_USER);
  const [reinviteUser] = useMutation(REINVITE_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const [usersList, setUsersList] = useState([]);
  const [profilesSuggestions, setProfilesSuggestions] = useState([]);
  const { openModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();

  const handleReinvitation = (id) => {
    reinviteUser({
      variables: { user_id: id },
    })
      .then((res) => {
        if (res.data?.mgnt_reset_user_password?.ok) {
          showToast({ message: res.data?.mgnt_reset_user_password?.msg });
        } else {
          showErrorDialog({ message: res.data?.mgnt_reset_user_password?.msg });
        }
      })
      .catch((error) => {
        showErrorDialog({ message: error.message });
      });
  };
  const handleAddition = ({ email, firstName, lastName, role, profiles }) => {
    return inviteUser({
      variables: {
        user: {
          first_name: firstName,
          last_name: lastName ? lastName : "",
          email: `${email}`,
          role_id: role?.value,
          profile_ids: Array.isArray(profiles)
            ? profiles.map((el) => el.id)
            : undefined,
        },
      },
      refetchQueries: [{ query: GET_PROFILES }],
      update(cache, { data }) {
        cache.modify({
          fields: {
            users(existingUsers) {
              if (data?.invite_user?.ok) {
                const newUserRef = cache.writeFragment({
                  data: { ...data?.invite_user?.new_user },
                  fragment: gql`
                    fragment NewUser on User {
                      id
                      first_name
                      last_name
                      email
                      role {
                        id
                        name
                      }
                      profiles {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      last_login
                    }
                  `,
                });

                return { edges: [...existingUsers.edges, newUserRef] };
              }
            },
          },
        });
      },
    })
      .then((res) => {
        if (res.data?.invite_user?.ok) {
          closeModal();
          showDialog({
            title: "User Added",
            message: (
              <div className={"user-added-success-msg"}>
                <i className={"seem-icon seem-icon-add-user"} />
                <span className={"user-name-bold"}>{`${firstName} ${
                  lastName ? lastName : ""
                }`}</span>
                {` will receive an invitation email with a temporary password.`}
              </div>
            ),
            buttons: [
              {
                "data-testid": "close-addition-success",
                label: "Close",
              },
            ],
          });
        } else {
          showErrorDialog({ message: res.data?.invite_user?.msg });
        }
      })
      .catch((error) => showErrorDialog({ message: error.message }));
  };

  const handleEditing = ({ id, firstName, lastName, role, profiles }) => {
    return editUser({
      variables: {
        user_id: id,
        first_name: firstName,
        last_name: lastName,
        role_id: role?.value,
        profile_ids: Array.isArray(profiles)
          ? profiles.map((el) => el.id)
          : undefined,
      },
      refetchQueries: [{ query: GET_PROFILES }],
      update(cache, { data }) {
        cache.modify({
          fields: {
            users(existingUsers, { readField }) {
              if (data?.change_user_fields?.ok) {
                const editedUserRef = cache.writeFragment({
                  id,
                  data: {
                    first_name: firstName,
                    last_name: lastName,
                    role: { id: role.value, name: role.label },
                    profiles: {
                      edges: profiles.map((profile) => {
                        return {
                          node: { id: profile.id, name: profile.name },
                        };
                      }),
                    },
                    __typename: "User",
                  },
                  fragment: gql`
                    fragment EditedUser on User {
                      first_name
                      last_name
                      role {
                        id
                        name
                      }
                      profiles {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  `,
                });

                return {
                  edges: [
                    ...existingUsers.edges.filter(
                      (user) => id !== readField("id", user)
                    ),
                    editedUserRef,
                  ],
                };
              }
            },
          },
        });
      },
    })
      .then((res) => {
        if (res.data?.change_user_fields?.ok) {
          closeModal();
          showToast({ message: `${res.data?.change_user_fields?.msg}` });
        } else {
          showErrorDialog({ message: res.data?.change_user_fields?.msg });
        }
      })
      .catch((error) => showErrorDialog({ message: error.message }));
  };

  const handleDeletion = (id) => {
    deleteUser({
      variables: { user_id: id },
      refetchQueries: [{ query: GET_PROFILES }],
      update(cache, { data }) {
        if (data?.delete_user?.ok) {
          cache.modify({
            fields: {
              users(existingUsers, { readField }) {
                return {
                  edges: [
                    ...existingUsers.edges.filter(
                      (user) => id !== readField("id", user)
                    ),
                  ],
                };
              },
            },
          });
          cache.evict({ id: `User:${id}` });
          cache.gc();
        }
      },
    })
      .then((res) => {
        if (res.data?.delete_user?.ok) {
          showToast({ message: "User Deleted Successfully" });
        } else {
          showErrorDialog({ message: res.data?.delete_user?.msg });
        }
      })
      .catch((error) => {
        showErrorDialog({ message: error.message });
      });
  };

  const sortByTime = useCallback((rowA, rowB, columnId) => {
    const dateA = new Date(rowA.values[columnId]);
    const dateB = new Date(rowB.values[columnId]);
    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    }
    return 0;
  }, []);

  useEffect(() => {
    if (!!profilesData) {
      const formattedProfiles = profilesData?.profiles?.edges?.map(
        (profile) => {
          return {
            id: profile?.node?.id,
            name: profile?.node?.name,
            hasScopes:
              profile?.node?.scopes?.edges?.length ||
              profile?.node?.scope_groups?.edges?.length,
          };
        }
      );

      setProfilesSuggestions(formattedProfiles);
    }
  }, [profilesData]);

  useEffect(() => {
    if (!!data) {
      const nodes = data?.users?.edges?.map((user) => {
        return {
          email: user?.node?.email,
          firstName: user?.node?.first_name,
          lastName: user?.node?.last_name,
          login: user?.node?.last_login,
          disabled: !Boolean(user?.node?.last_login),
          id: user?.node?.id,
          role: user?.node?.role,
          profiles: user?.node?.profiles?.edges?.map((profile) => {
            return {
              id: profile.node.id,
              name: profile.node.name,
              hasScopes:
                profile?.node?.scopes?.edges?.length ||
                profile?.node?.scope_groups?.edges?.length,
            };
          }),
        };
      });

      setUsersList(nodes);
    }
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: (d) => `${d?.firstName} ${d?.lastName}`,
        disableFilters: true,
        Cell: (value) => {
          return (
            <div className={"users-list-name users-list-cell"}>
              <Avatar
                name={value.value}
                disabled={!value.row?.values?.login}
                size={"s"}
              />
              {value.value}
            </div>
          );
        },
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        width: 150,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Role",
        id: "role",
        width: 60,
        accessor: (d) => d?.role?.name,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Profiles",
        id: "profiles",
        width: 130,
        disableFilters: true,
        accessor: (d) => d?.profiles,
        Cell: (value) => {
          return value.value?.length ? (
            <ShowMoreInlineList
              list={value.value.slice()}
              max={1}
              listItemSize={"m"}
              id={value.row?.original?.id}
            />
          ) : (
            ""
          );
        },
      },
      {
        Header: "Last Login",
        id: "login",
        accessor: (d) => d.login || null,
        sortType: sortByTime,
        disableFilters: true,
        Cell: (value) =>
          value?.value ? (
            moment(value.value).format("DD MMMM YYYY, hh:mm A")
          ) : (
            <div className={"users-list-cell"}>---</div>
          ),
      },
      {
        id: "actions",
        Header: "",
        width: 100,
        disableFilters: true,
        Cell: (value) => {
          const email = value.row?.values?.email;
          const firstName = value.row?.original?.firstName;
          const lastName = value.row?.original?.lastName;
          const userId = value.row?.original?.id;
          const role = value?.row?.original?.role;
          const profiles = value?.row?.original?.profiles;

          return (
            <div className={"users-list-actions users-list-cell"}>
              {value.row?.values?.login ? (
                <>
                  <Button
                    data-testid={"edit-user"}
                    isClean
                    tooltipData={{
                      dataFor: `edit-user-btn-${value.row?.id}`,
                      dataTip: true,
                    }}
                    isIcon="seem-icon-edit"
                    type={`button`}
                    onClick={() =>
                      openModal({
                        title: "Edit User",
                        size: "xs",
                        overlay: true,
                        component: (
                          <UserActions
                            mode={userActions.EDIT}
                            data={{
                              email,
                              firstName,
                              lastName,
                              id: userId,
                              role,
                              profiles,
                            }}
                            profilesSuggestions={profilesSuggestions}
                            onSubmit={handleEditing}
                          />
                        ),
                      })
                    }
                    title={t("Edit")}
                  />
                  <ReactTooltip
                    id={`edit-user-btn-${value.row?.id}`}
                    place="top"
                  >
                    Edit User
                  </ReactTooltip>
                </>
              ) : (
                <></>
              )}
              <>
                <Button
                  data-testid={"resend-user"}
                  isClean
                  tooltipData={{
                    dataFor: `resend-user-btn-${value.row?.id}`,
                    dataTip: true,
                  }}
                  isIcon="seem-icon-refresh"
                  type={`button`}
                  onClick={() =>
                    handleReinvitation(userId, value.row?.values?.name)
                  }
                  title={t("Invite")}
                />
                <ReactTooltip
                  id={`resend-user-btn-${value.row?.id}`}
                  place="top"
                >
                  Reset Password
                </ReactTooltip>
              </>
              <Button
                data-testid={"delete-user"}
                isClean
                tooltipData={{
                  dataFor: `delete-user-btn-${value.row?.id}`,
                  dataTip: true,
                }}
                isIcon="seem-icon-bin"
                type={`button`}
                onClick={() =>
                  showDialog({
                    title: "Delete User",
                    message: (
                      <div>
                        {`Are you sure you want to delete `}
                        <span
                          className={"user-name-bold"}
                        >{`${value.row?.values?.name}`}</span>
                        {`?`}
                      </div>
                    ),
                    buttons: [
                      {
                        "data-testid": "cancel-delete",
                        label: "Cancel",
                      },
                      {
                        "data-testid": "confirm-delete",
                        isSecondary: true,
                        label: "Delete",
                        type: "submit",
                        onClick: () => handleDeletion(userId),
                      },
                    ],
                  })
                }
                title={t("Delete")}
              />
              <ReactTooltip id={`delete-user-btn-${value.row?.id}`} place="top">
                Delete User
              </ReactTooltip>
            </div>
          );
        },
      },
    ],
    [usersList, customerData]
  );

  return (
    <div className="users-list-container">
      <Button
        isSecondary
        wrapperClassName={"sidebar-display-area-top-button"}
        label={t("+ Add User")}
        isXl
        data-testid={"add-user"}
        onClick={() =>
          openModal({
            title: "Add User",
            size: "xs",
            overlay: true,
            component: (
              <UserActions
                mode={userActions.CREATE}
                allowedDomains={
                  customerData?.customer?.domains?.edges?.map(
                    (domain) => domain.node.domain
                  ) || []
                }
                profilesSuggestions={profilesSuggestions}
                onSubmit={handleAddition}
              />
            ),
          })
        }
        title={t("Add")}
      />
      <Table
        loading={loading}
        type={"secondary"}
        columns={columns}
        data={usersList}
        initialSortBy={[{ id: "login" }, { id: "name" }]}
      />
    </div>
  );
};

export default UsersList;
