import React, { useEffect, useLayoutEffect, useState } from "react";
import useChart from "../../../Components/Chart/useChart";
import Chart from "../../../Components/Chart/Chart";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { SLA, SLA_STATUS_NA } from "../SLAUtils";
import { getFilterToExecute } from "../../DashboardUtils";
import useDashboardNavigator from "../../useDashboardNavigator";
import useSummary from "../../useSummary";

const Compliance = ({ selectedScope, filterToExecute, scopeToExecute }) => {
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id"],
      group_by: ["finding.sla_status"],
      aggregate_functions: ["COUNT"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute || null,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });
  const [dashboardData, setDashboardData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(true);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const sortedData = data
        ?.filter((sla) => sla[0] !== SLA_STATUS_NA)
        ?.map((sla) => {
          const slaValue = SLA[sla[0]];
          return {
            slaStatus: slaValue.title,
            value: sla[1],
            fill: slaValue.fill,
            sort: slaValue.sort,
          };
        })
        .sort((a, b) => a.sort - b.sort);

      if (sortedData.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (sortedData.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(sortedData);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      let [chart, legendContainer] = createChart(
        "slaComplianceDiv",
        am4charts.PieChart,
        { isScrollable: false }
      );

      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "slaStatus";
      pieSeries.slices.template.propertyFields.fill = "fill";
      pieSeries.labels.template.disabled = true;
      chart.innerRadius = am4core.percent(45);
      pieSeries.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      pieSeries.slices.template.events.on("hit", (e) => {
        let selectedStatus;
        for (let key in SLA) {
          if (SLA[key].title === e.target.dataItem.dataContext.slaStatus) {
            selectedStatus = key;
            break;
          }
        }
        dashboardNavigator(
          null,
          null,
          null,
          getFilterToExecute({
            value: selectedStatus,
            type: "sla_status",
          })
        );
      });

      pieSeries.slices.template.events.on("hit", (e) => {
        let selectedStatus;
        for (let key in SLA) {
          if (SLA[key].title === e.target.dataItem.dataContext.slaStatus) {
            selectedStatus = key;
            break;
          }
        }
        dashboardNavigator(
          null,
          null,
          null,
          getFilterToExecute({
            value: selectedStatus,
            type: "sla_status",
          })
        );
      });

      pieSeries.legendSettings.labelText =
        "{value.percent.formatNumber('#.#')}% {category}";
      chart.legend.valueLabels.template.disabled = true;

      chart.legend.useDefaultMarker = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;
      chart.legend.data = chart.data;
      chart.legend.align = "left";
      chart.legend.contentAlign = "left";
      chart.legend.contentValign = "top";

      chart.data = dashboardData;

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"slaComplianceDiv"}
      chartError={dataError}
      chartData={dataExists}
      legendTitle={"SLA Status"}
    />
  );
};

export default Compliance;
