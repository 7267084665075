import React, { useMemo } from "react";
import Actions from "../Actions/Actions";
import { capitalize } from "lodash";
import "./Header.scss";

const Header = ({
  id,
  isFullScreen,
  editable,
  closePane,
  type,
  title,
  headerTopComponents = [],
  headerBottomComponents = [],
}) => {
  const topComponentsWithId = useMemo(() => {
    const topComponentsCopy = [...headerTopComponents];
    topComponentsCopy.unshift(<span>{`ID / ${id}`}</span>);
    return topComponentsCopy;
  }, [headerTopComponents, id]);

  return (
    <div className={"details-pane-header-container"}>
      <div className={"details-pane-header-top-wrapper"}>
        <div className={`details-pane-header-top-components`}>
          {topComponentsWithId?.slice()}
        </div>
        <Actions
          isFullScreen={isFullScreen}
          editable={editable}
          type={type}
          expandLink={`/${type}/${btoa(
            `${capitalize(type.slice(0, -1))}:${id}`
          )}`}
          closePane={closePane}
        />
      </div>
      <div className={`details-pane-header-title`}>{title}</div>
      <div className={"details-pane-header-bottom-components"}>
        {headerBottomComponents?.slice()}
      </div>
    </div>
  );
};

export default Header;
