import { Auth } from "aws-amplify";
import { gql } from "@apollo/client";

export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";

export const signIn = (username, password) => {
  return Auth.signIn(username, password);
};

export const getAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const getCurrentSession = () => {
  return Auth.currentSession();
};

export const confirmSignIn = (user, code, mfaType) => {
  return Auth.confirmSignIn(user, code, mfaType);
};

export const completeNewPassword = (user, newPassword) => {
  return Auth.completeNewPassword(user, newPassword);
};

export const federatedSignIn = (options) => {
  return Auth.federatedSignIn(options);
};

export const UPDATE_LAST_LOGIN = gql`
  mutation ($email: String!) {
    login(email: $email) {
      ok
      msg
    }
  }
`;
