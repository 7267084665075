import React from "react";
import "./Tag.scss";
import ClassNames from "classnames";

const Tag = ({
  tag,
  onDelete,
  wrapperClassName,
  onClick,
  tooltipData = {},
}) => {
  const classNameWrapper = ClassNames(
    "tag-wrapper",
    wrapperClassName && wrapperClassName,
    tag?.type || "",
    {
      "is-disabled": tag?.disabled,
    }
  );
  return (
    <div
      className={`${classNameWrapper}`}
      onClick={(e) => onClick(e)}
      data-for={tooltipData?.dataFor}
      data-tip={tooltipData?.dataTip}
      data-testid={"tag-wrapper"}
    >
      <span title={`${tag.name}`} className={`tag-name`}>
        {tag.name}
      </span>
      {!tag.disabled && (
        <i className={`seem-icon seem-icon-close`} onClick={onDelete} />
      )}
    </div>
  );
};

export default Tag;

Tag.defaultProps = {
  onClick: () => {},
};
