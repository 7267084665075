import React from "react";
import { useTranslation } from "react-i18next";
import "./Header.scss";

const Header = ({ isEmpty }) => {
  const { t } = useTranslation();

  return isEmpty ? (
    <>
      <div className={`new-queue-action-trigger`}>
        <div className={`new-queue-trigger-data`}>
          <span className={`new-queue-title`}>
            {t("Create a new queue to get started")}
          </span>
          <span className={`new-queue-description`}>
            {t(
              "Remediation Queues allows you to automate the creation of tickets and notifications based on your rules."
            )}
          </span>
        </div>
      </div>
      <div className={`builtin-queue-intro`}>
        <i className={`seem-icon seem-icon-info`} />
        <span className={`builtin-queue-title`}>
          {t(
            "We’ve created 2 queues for you to help you manage findings by exception."
          )}
        </span>
        <span className={`builtin-queue-sub-title`}>
          {" "}
          {t(
            "These queues can be connected to other queues you create, to form a unified workflow."
          )}
        </span>
      </div>
    </>
  ) : null;
};

export default Header;
