import { useCallback, useState } from "react";
import { fieldTypeOptions } from "../GenericField/GenericField";

const useTicketEndpointMutationData = () => {
  const [getPropsFromField, setGetPropsFromField] = useState(() => {});

  const getMutationArray = useCallback(
    (data) => {
      const mutationArray = [];
      if (!!data && Object.keys(data).length) {
        Object.keys(data).forEach((key) => {
          if (!!data[key] || data[key] === "") {
            if (
              getPropsFromField(key)?.input !== fieldTypeOptions.DYNAMIC_MAPPING
            ) {
              mutationArray.push({
                key: key,
                value: data[key],
              });
            } else {
              data[key]?.forEach((dynamicMappingEl) => {
                if (!!dynamicMappingEl?.value) {
                  mutationArray.push({
                    key: dynamicMappingEl?.field,
                    value: dynamicMappingEl?.value,
                  });
                }
              });
            }
          }
        });
      }
      return mutationArray;
    },
    [getPropsFromField]
  );

  return {
    getPropsFromField,
    setGetPropsFromField,
    getMutationArray,
  };
};

export default useTicketEndpointMutationData;
