import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./FindingNavigationCarousel.scss";
import { NAVIGATION_ITEMS_PAGE_SIZE } from "../../../../FindingsUtils";

const CustomLeftArrow = ({ onClick }) => (
  <i onClick={() => onClick()} className="seem-icon seem-icon-back-arrow" />
);
const CustomRightArrow = ({ onClick }) => {
  return (
    <i className="seem-icon seem-icon-next-arrow" onClick={() => onClick()} />
  );
};

const CustomButtonGroupAsArrows = ({ next, previous, width, itemsCount }) => {
  const navigationButtonWidth = 22 + 8;
  return itemsCount > NAVIGATION_ITEMS_PAGE_SIZE ? (
    <div
      className={`carousel-custom-buttons`}
      style={{ width: `${width + navigationButtonWidth * 2}px` }}
    >
      <div className={`custom-arrow-container`}>
        <CustomLeftArrow onClick={previous}>Prev</CustomLeftArrow>
      </div>
      <div className={`custom-arrow-container`}>
        <CustomRightArrow onClick={next}>Next</CustomRightArrow>
      </div>
    </div>
  ) : null;
};

const FindingNavigationCarousel = ({ children, width, itemsCount }) => {
  return (
    <div
      className={`carousel-wrapper ${
        itemsCount > NAVIGATION_ITEMS_PAGE_SIZE ? "has-arrows" : ""
      }`}
    >
      <div
        className={`findings-navigator-carousel ${
          width ? "" : "findings-navigator-carousel-hidden"
        }`}
        style={{ width: `${width}px` }}
      >
        <Carousel
          customButtonGroup={
            <CustomButtonGroupAsArrows
              width={width}
              itemsCount={children.length}
            />
          }
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={false}
          className="groups-carousel-container"
          containerClass="groups-carousel-list"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass="group-carousel-item"
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside
          renderDotsOutside={true}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 320,
              },
              items: NAVIGATION_ITEMS_PAGE_SIZE,
            },
          }}
          showDots={itemsCount > NAVIGATION_ITEMS_PAGE_SIZE}
          sliderClass=""
          slidesToSlide={NAVIGATION_ITEMS_PAGE_SIZE}
          swipeable
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};

export default FindingNavigationCarousel;
