import Button from "../Controls/Button/Button";
import React from "react";

export const getDialogButtons = (buttons, closeDialog) => {
  return buttons.map((btn, index) => {
    const props = {
      ...btn,
      onClick: () => {
        if (!!btn?.onClick) {
          btn?.onClick();
        }

        closeDialog();
        // e.stopPropagation();
      },
    };
    return (
      <Button
        data-testid={btn?.["data-testid"]}
        key={`dialog-btn-${index}`}
        {...props}
      />
    );
  });
};
