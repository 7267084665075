import React from "react";
import { snakeCase } from "lodash";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";
import SLAStatus from "../../../../SLAStatus/SLAStatus";
import FilterInOut from "../../../../FilterInOut/FilterInOut";
import { findingsColumns } from "../../../../../FindingsUtils";

const SLANavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  groupRow,
}) => {
  return (
    <div
      className={`findings-carousel-item group-by-${groupBy}`}
      onClick={() => onGroupClick(groupRow)}
    >
      <div className={`findings-carousel-item-top`}>
        {groupName && (
          <div
            className={`sevirity-level sevirity-level-${snakeCase(groupName)}`}
          />
        )}
        {normalizeNumbers(group.total_count)}
      </div>

      <div className={`findings-carousel-item-bottom`}>
        <SLAStatus sla_status={groupName} />
      </div>

      <FilterInOut
        field={findingsColumns.SLA_STATUS}
        value={snakeCase(groupName).toUpperCase()}
      />
    </div>
  );
};

export default SLANavigationItem;
