import React from "react";
import Trow from "../Trow/Trow";

const Tbody = ({
  rows,
  getTableBodyProps,
  prepareRow,
  onRowClick,
  onRowExpand,
  "data-testid": dataTestId,
}) => {
  return (
    <tbody className={`tbody`} {...getTableBodyProps()}>
      {rows.map((row, i) => {
        return (
          <Trow
            row={row}
            prepareRow={prepareRow}
            onRowClick={onRowClick}
            onRowExpand={onRowExpand}
            data-testid={dataTestId}
          ></Trow>
        );
      })}
    </tbody>
  );
};

export default Tbody;
