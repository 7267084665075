import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import { createDateAxis, createValueAxis } from "../TrendsUtils";
import useTrend from "../useTrend";

const OneSeriesByDiscoveryDate = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
  timeScale,
  chartData,
}) => {
  const [trendData, setTrendData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const {
    trendData: data,
    trendDataError,
    trendLoading,
  } = useTrend({
    variables: {
      selection: chartData.queryNameVariable,
      timescale: timeScale,
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute,
        scopesid: selectedScope?.value?.id,
        filters_fields_to_exclude: ["actual_status"],
      },
      aggregate_functions: ["SUM"],
      round_functions: ["ROUND"],
    },
  });

  const clearData = () => {
    setTrendData(null);
  };

  useEffect(() => {
    if (!!trendLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [trendLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!trendDataError) {
      setChartError(true);
      setTrendData({});
      stopLoadingChart();
    }
  }, [trendDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      let findingsByTimeScale = [];
      findingsByTimeScale = data.map((timeScale, index) => {
        const dataVal = {
          date: timeScale[0],
          value: timeScale[1],
        };

        if (data?.length >= 2 && index === data?.length - 2) {
          dataVal.dashLength = 8;
        }
        return dataVal;
      });

      if (findingsByTimeScale?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (findingsByTimeScale?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setTrendData(findingsByTimeScale);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      let [chart, legendContainer] = createChart(
        `${chartData.title.replaceAll(/\s/g, "")}Trend`,
        am4charts.XYChart
      );

      chart.cursor = new am4charts.XYCursor();
      chart.paddingRight = 30;

      chart.data = trendData;

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      createDateAxis(dateAxis, timeScale);
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis, "Findings");

      let label = dateAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 85;
      label.textAlign = "middle";

      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value";
      series.dataFields.dateX = "date";
      series.tooltipText = "{name}: {value}";
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = "white";
      series.strokeWidth = 3;
      series.minBulletDistance = 15;
      series.tensionX = 0.8;
      series.name = chartData.legend;
      series.strokeWidth = 2;
      series.propertyFields.strokeDasharray = "dashLength";
      series.showOnInit = true;

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = 4;
      bullet.circle.fill = am4core.color("#fff");

      const bullethover = bullet.states.create("hover");
      bullethover.properties.scale = 1.3;

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [trendData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={`${chartData.title.replaceAll(/\s/g, "")}Trend`}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default OneSeriesByDiscoveryDate;
