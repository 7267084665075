import { useState, useRef, useLayoutEffect, useMemo } from "react";
import { useInterval } from "react-use";
import { sendMessageTypes } from "./WebSocketUtils";
import useLogger from "../Logger/useLogger";

const useWebSocket = (ID, URL, protocol, options) => {
  const {
    reconnectTimeout,
    maxReconnectAttempts,
    keepAliveSocket,
    keepAliveTimeout,
  } = options;
  const [webSocketState, setWebSocketState] = useState({
    status: wsStatus.OFFLINE,
    value: undefined,
    readyState: wsReadyState.CLOSED,
    keepAlive: false,
    shouldReconnect: false,
  });
  const { logInfo } = useLogger();
  const socket = useRef(null);

  const attemptsCounter = (attemptsNum) => {
    let counter = attemptsNum;
    return () => {
      counter = counter - 1;
      return counter;
    };
  };

  const attemptsCounterRef = useMemo(() => {
    console.log(maxReconnectAttempts, "maxReconnectAttempts");
    return attemptsCounter(maxReconnectAttempts);
  }, [maxReconnectAttempts]);

  useInterval(
    () => {
      const reconnectAttempts = attemptsCounterRef();
      if (
        reconnectAttempts > 0 &&
        socket &&
        socket.current &&
        socket.current.readyState !== wsReadyState.OPEN
      ) {
        createWebSocket();
      } else if (reconnectAttempts === 0) {
        setWebSocketState({ ...webSocketState, shouldReconnect: false });
      }
    },
    webSocketState.shouldReconnect && !webSocketState.keepAlive
      ? reconnectTimeout
      : null
  );

  useInterval(
    () => {
      if (!!socket && socket.current.readyState === wsReadyState.CLOSED) {
        createWebSocket();
      } else if (!!socket && socket.current.readyState === wsReadyState.OPEN) {
        socket.current.send(
          JSON.stringify({
            type: sendMessageTypes.KEEPALIVE,
          })
        );
      }
    },
    webSocketState.keepAlive ? keepAliveTimeout : null
  );

  useLayoutEffect(() => {
    if (ID && socket?.current?.readyState !== wsReadyState.OPEN) {
      console.log(`WebSocket creation should start ${ID}`);
      logInfo(`WebSocket creation should start...`);
      createWebSocket();
    }
    return () => {
      if (socket && socket.current) {
        socket.current.onmessage = null;
        socket.current.close();
      }
    };
  }, [ID]);

  const reconnectSocket = () => {
    setWebSocketState({ ...webSocketState, shouldReconnect: false });
    createWebSocket();
  };

  const createWebSocket = () => {
    setWebSocketState({
      ...webSocketState,
      shouldReconnect: false,
      keepAlive: false,
      value: null,
    });
    socket.current = new WebSocket(`${URL}?ticket=${ID}`, protocol);

    socket.current.onopen = () => {
      console.log("WS opened successfully");
      logInfo(`WS opened successfully`);
      socket.current.send(
        JSON.stringify({
          type: sendMessageTypes.OPEN_CONNECTION,
        })
      );

      setWebSocketState({
        ...webSocketState,
        shouldReconnect: false,
        keepAlive: keepAliveSocket,
        status: wsStatus.ONLINE,
      });
    };

    socket.current.onerror = (e) => {
      console.log("WS error", e);
      // logError({ message: "WS Error" });
    };

    socket.current.onmessage = (e) => {
      console.log(`WS Message: ${e?.data}`);
      // logInfo(`WS Message: ${e?.data}`);
      const data = e.data;

      setWebSocketState({
        ...webSocketState,
        value: data,
        keepAlive: keepAliveSocket,
        status: wsStatus.ONLINE,
      });
    };

    socket.current.onclose = (e) => {
      console.log("WS close", e, socket.current);
      // logError({ message: `WS Closed: ${e?.code} ${e?.reason}` });
      setWebSocketState({
        ...webSocketState,
        readyState: wsReadyState.CLOSED,
        value: null,
        shouldReconnect: true,
        status: wsStatus.OFFLINE,
      });
    };
  };

  const sendMessage = (message) => {
    if (socket && socket.current) {
      socket.current.send(message);
    }
  };

  const closeSocket = () => {
    if (socket && socket.current) {
      socket.current.close();
    }
  };
  return {
    value: webSocketState.value,
    sendMessage,
    status: webSocketState.status,
    closeSocket,
    readyState: webSocketState.readyState,
    reconnectSocket,
    socket: socket.current,
  };
};

export default useWebSocket;

// See https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
export const wsReadyState = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

const wsStatus = {
  ONLINE: "Online",
  OFFLINE: "Offline",
};
