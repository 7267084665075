import React from "react";
import { snakeCase } from "lodash";
import { cloudProviderIcons } from "../../../../../FindingsUtils";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";

const CloudProviderNavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  cloudProviderIcon,
  groupRow,
}) => (
  <div
    className={`findings-carousel-item is-row group-by-${groupBy}`}
    onClick={() => onGroupClick(groupRow)}
  >
    <div className={`findings-carousel-item-left`}>
      {!!cloudProviderIcon && (
        <div className={`findings-carousel-item-bottom-icon`}>
          {" "}
          <img
            alt={`${groupName}`}
            src={cloudProviderIcons[snakeCase(cloudProviderIcon)]}
          />
        </div>
      )}
    </div>
    <div className={`navigation-item-separator`} />
    <div className={`findings-carousel-item-right`}>
      <div
        className={`findings-carousel-item-count`}
        data-testid={"findings-count"}
      >
        {groupName && (
          <div
            className={`sevirity-level sevirity-level-${snakeCase(groupName)}`}
          />
        )}
        {normalizeNumbers(group.total_count)}
      </div>
      <div className={`findings-carousel-item-bottom-name`}>{`Findings`}</div>
    </div>
  </div>
);

export default CloudProviderNavigationItem;
