import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query getCustomer {
    customer {
      id
      schema
      domains {
        edges {
          node {
            id
            domain
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($filters: UserFilter) {
    users(filters: $filters) {
      edges {
        node {
          id
          first_name
          last_name
          email
          last_login
          profiles {
            edges {
              node {
                id
                name
                scopes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                scope_groups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation inviteUser($send_email: Boolean, $user: UserInput!) {
    invite_user(send_email: $send_email, user: $user) {
      ok
      msg
      new_user {
        id
        email
        last_name
        first_name
        last_login
      }
    }
  }
`;

export const generateAddUser = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let addUserMock = {
    request: {
      query: INVITE_USER,
      variables,
    },
  };

  if (error) {
    return {
      ...addUserMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...addUserMock,
      result: { data: result },
    };
  }

  return {
    ...addUserMock,
    result: {},
  };
};

export const REINVITE_USER = gql`
  mutation reinviteUser($user_id: String!) {
    mgnt_reset_user_password(user_id: $user_id) {
      ok
      msg
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($user_id: String!) {
    delete_user(user_id: $user_id) {
      ok
      msg
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser(
    $user_id: String!
    $first_name: String!
    $last_name: String!
    $role_id: String
    $profile_ids: [String]
  ) {
    change_user_fields(
      user_id: $user_id
      first_name: $first_name
      last_name: $last_name
      role_id: $role_id
      profile_ids: $profile_ids
    ) {
      ok
      msg
    }
  }
`;

export const generateEditUser = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let editUserMock = {
    request: {
      query: INVITE_USER,
      variables,
    },
  };

  if (error) {
    return {
      ...editUserMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...editUserMock,
      result: { data: result },
    };
  }

  return {
    ...editUserMock,
    result: {},
  };
};
