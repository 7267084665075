import "./Performances.scss";
import React, { useEffect, useMemo, useState } from "react";
import { capitalize, snakeCase } from "lodash";
import useTrend from "../../TrendsDashboard/useTrend";
import Loader from "../../../Components/Loader/Loader";
import Spinner from "../../../images/loader.svg";
import { timeScales } from "../../DashboardUtils";
// import noResultsImg from "../../../../src/icons/images/no-results.svg";

const DIRECTION = {
  UP: "Up",
  DOWN: "Down",
  EQ: "No Change",
};
const Performances = ({
  timeScale,
  filterToExecute,
  selectedScope,
  scopeToExecute,
}) => {
  const [numOfFixedFindings, setNumOfFixedFindings] = useState(0);
  const [lastPeriodFixedFindings, setLastPeriodFixedFindings] = useState(0);
  const [direction, setDirection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const { trendData: data, trendDataError } = useTrend({
    variables: {
      selection: ["closed_today"],
      timescale: timeScale,
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute,
        scopesid: selectedScope?.value?.id,
      },
      aggregate_functions: ["SUM"],
      round_functions: ["ROUND"],
    },
  });

  useEffect(() => {
    if (!!trendDataError) {
      setIsLoading(false);
    }
  }, [trendDataError]);

  useEffect(() => {
    if (data?.length) {
      setNoData(false);
      const trendsData = data?.reverse();
      const fixedFindings = trendsData?.[0]?.[1];
      setNumOfFixedFindings(fixedFindings || 0);

      const lastPeriodFixedFindings = trendsData?.[1]?.[1];
      setLastPeriodFixedFindings(lastPeriodFixedFindings || 0);

      const direction =
        fixedFindings === lastPeriodFixedFindings
          ? DIRECTION.EQ
          : fixedFindings > lastPeriodFixedFindings
          ? DIRECTION.UP
          : DIRECTION.DOWN;
      setDirection(direction);
      setIsLoading(false);
    } else {
      setNoData(true);
      setIsLoading(false);
    }
  }, [data]);

  const timeScaleText = useMemo(() => {
    return timeScale !== timeScales.DAY
      ? `Last ${capitalize(timeScale)}`
      : "Yesterday";
  }, [timeScale]);

  return (
    <div className={`performances-widget-wrapper ${noData ? "no-data" : ""}`}>
      {isLoading ? (
        <Loader center img={Spinner} />
      ) : noData ? (
        <Loader center text={"No match for the selected filter"} />
      ) : (
        <>
          <div
            className={`performance-icon-container ${
              !numOfFixedFindings ? "is-empty" : ""
            }`}
          >
            <i
              className={`seem-icon ${
                numOfFixedFindings
                  ? "seem-icon-jira-icon-done"
                  : "seem-icon-findings"
              }`}
            />
          </div>

          {numOfFixedFindings === 0 && (
            <div
              data-testid={`no-performance`}
              className={`no-performance`}
            >{`No Fixed Findings`}</div>
          )}

          {!!numOfFixedFindings && (
            <>
              <div
                data-testid={`fixed-findings`}
                className={`performance-data`}
              >{`${numOfFixedFindings} Fixed Findings`}</div>
              <div
                className={`performance-statistics performance-${snakeCase(
                  direction?.toLowerCase()
                )}`}
              >
                {!!direction && (
                  <>
                    {direction !== DIRECTION.EQ && (
                      <i
                        className={`seem-icon seem-icon-performance-${direction?.toLowerCase()}`}
                      />
                    )}
                    <span
                      data-testid={`diff-content`}
                      className={`diff-indicator-${snakeCase(
                        direction?.toLowerCase()
                      )}`}
                    >
                      {direction === DIRECTION.EQ
                        ? `No change from ${timeScaleText}`
                        : `Compared to ${lastPeriodFixedFindings} that were fixed ${timeScaleText}`}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Performances;
