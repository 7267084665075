import React, { useContext } from "react";
import SettingsPanel from "./SettingsPanel/SettingsPanel";
import SyncedQueueFindings from "./Findings/SyncedQueueFindings/SyncedQueueFindings";
import { RemediationQueueContext } from "./RemediationQueueProvider";
import "./RemediationQueue.scss";
import FindingsView from "./Findings/FindingsView/FindingsView";
import RemediationQueueHeader from "./RemediationQueueHeader/RemediationQueueHeader";
import { modeOptions, queueStates } from "../RemediationQueuesUtils";
import { ticketManagersEnum } from "../../Ticketing/TicketingUtils";

const RemediationQueue = () => {
  const {
    remediationQueueData: { state, providerType },
    mode,
  } = useContext(RemediationQueueContext);

  const renderFindingsTable = () => {
    return providerType !== ticketManagersEnum.SLACK &&
      state === queueStates.ENABLED ? (
      <SyncedQueueFindings />
    ) : mode !== modeOptions.READ || state === queueStates.DISABLED ? (
      <FindingsView />
    ) : null;
  };

  return (
    <div className={"remediation-queue-wrapper"}>
      <RemediationQueueHeader />
      <div className={`rmq-queue-data`}>
        <SettingsPanel />
        {renderFindingsTable()}
      </div>
    </div>
  );
};

export default RemediationQueue;
