import { gql } from "@apollo/client";

export const GET_REPORTS = gql`
  mutation run_system_report($name: String!) {
    run_system_report(name: $name) {
      data
    }
  }
`;

export const GET_NAMES = gql`
  mutation {
    get_all_system_report_names {
      data
    }
  }
`;
