import { aggregatedStatus } from "../Findings/FindingsUtils";

const fieldTypes = {
  LIST: "list",
  NUMBER: "number",
};

export const filtersMap = {
  source: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  sub_category: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  category: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  severity: {
    type: fieldTypes.LIST,
    searchable: false,
  },
  original_status: {
    type: fieldTypes.LIST,
    searchable: false,
  },
  score: {
    type: fieldTypes.NUMBER,
    searchable: false,
  },
  due_date: {
    type: fieldTypes.NUMBER,
    searchable: false,
  },
  title: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  ticket_status: {
    type: fieldTypes.LIST,
    searchable: false,
  },
  sla_status: {
    type: fieldTypes.LIST,
    searchable: false,
  },
  sla_remaining_time: {
    type: fieldTypes.NUMBER,
    searchable: false,
  },
  cloud_provider: {
    type: fieldTypes.LIST,
    searchable: false,
  },
  cloud_account: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  cloud_account_friendly_name: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  resource_name: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  resource_type: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  owner_email: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  owner_name: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  tags_values: {
    type: fieldTypes.LIST,
    searchable: true,
  },
  ticket_external_id: {
    type: fieldTypes.LIST,
    searchable: true,
  },
};

export const processFilter = (filter, obj = []) => {
  let lastStatus = aggregatedStatus.OPEN;
  if (filter?.operator === "or") {
    processFilter(filter?.operands?.[0], obj);
    processFilter(filter?.operands?.[1], obj);
  } else if (
    filter?.operator === "and" &&
    filter?.operands?.[0]?.field === "actual_status"
  ) {
    obj.push({
      field: filter?.operands?.[0]?.field,
      condition: "in",
      value: filter?.operands?.[0]?.value.value,
    });
    !!filter?.operands?.[1] && processFilter(filter?.operands?.[1], obj);
  } else if (filter?.operator) {
    processFilter(filter?.operands?.[0], obj);
    !!filter?.operands?.[1] && processFilter(filter?.operands?.[1], obj);
  } else {
    if (!!filter) {
      if (filter?.field === "actual_status") {
        obj.push({
          field: filter?.field,
          condition: "in",
          value: filter?.value?.value,
        });
      } else {
        obj.push(filter);
      }
    } else {
      obj.push({});
    }
  }

  return { filters: obj, lastStatus };
};

export const getConditions = (filters) => {
  return filters?.map((filterPanel) => {
    const conditions = [];
    for (let i = 0; i < filterPanel?.conditions?.length; i++) {
      let conditionData = filterPanel?.conditions?.[i];
      if (conditionData?.operands) {
        conditions.push(conditionData?.operands?.[0]);
        conditions.push(conditionData?.operands?.[1]);
      } else {
        conditions.push(conditionData);
      }
    }

    return {
      ...filterPanel,
      conditions: conditions,
    };
  });
};

export const filterTabs = {
  RECOMMENDED: "recommended",
  SAVED: "saved",
  RECENT: "recent",
};

export const getQueuePluralsByCount = (count) => {
  if (count === 1) {
    return "queue";
  } else if (count > 1) {
    return "queues";
  } else {
    return "";
  }
};

export const getFilterJson = (data) => {
  let filtersJson = data?.filterPanels?.reduce((panelRes, item, index) => {
    let operands = {
      value: item.value,
      field: item.field,
      condition: item.condition,
    };

    if (item.field === "actual_status") {
      operands = {
        field: item.field,
        condition: "",
        value: {
          field: "key",
          condition: "in",
          value: item.value,
        },
      };
    }

    let jsonFilter;
    if (index === 0) {
      jsonFilter = operands;
    } else {
      jsonFilter = { operator: "and", operands: [panelRes, operands] };
    }

    return jsonFilter;
  }, {});

  let filter = !!filtersJson ? JSON.stringify(filtersJson) : "";
  return filter;
};
