import { includes } from "lodash";
import { resourceTypes } from "../../Findings/FindingsUtils";

export const TristateStateEnum = {
  CHECKED: "checked",
  INDETERMINATE: "indeterminate",
};

export const getNodeById = (node, id) => {
  if (node?.id?.toString() === id) {
    return node;
  } else if (node?.children?.length) {
    let result = null;
    for (let i = 0; result == null && i < node?.children?.length; i++) {
      result = getNodeById(node.children[i], id);
    }
    return result;
  }
  return null;
};

export const updateChildrenWithParentId = (nodes, parentId) => {
  return nodes.reduce((acc, node) => {
    if (node?.children?.length) {
      let newItems = updateChildrenWithParentId(node.children, node.id);
      if (newItems?.length) {
        acc.push({
          ...node,
          children: newItems,
          parentId,
        });
      }
    } else {
      acc.push({ ...node, parentId });
    }
    return acc;
  }, []);
};

export const search = (treeData, searchValue) => {
  if (!searchValue) {
    return treeData;
  }
  return treeData.reduce((acc, node) => {
    if (includes(node?.name?.toLowerCase(), searchValue?.toLowerCase())) {
      let newItems = node?.children
        ? search(node?.children, searchValue)
        : node?.children;
      acc.push({ ...node, children: newItems, expanded: false });
    } else if (node?.children?.length > 0) {
      let newItems = search(node.children, searchValue);
      if (newItems?.length > 0) {
        newItems[0].expanded = true;
        acc.push({
          ...node,
          children: newItems,
          expanded: true,
        });
      }
    }

    return acc;
  }, []);
};

export const getGroupsTree = (treeData) => {
  return treeData.reduce((acc, node) => {
    if (
      node?.type === resourceTypes.ROOT ||
      node?.type === resourceTypes?.GROUP
    ) {
      let newItems = node?.children
        ? getGroupsTree(node?.children)
        : node?.children;
      acc.push({ ...node, children: newItems });
    } else if (node?.children?.length > 0) {
      let newItems = getGroupsTree(node.children);
      if (newItems?.length > 0) {
        acc.push({
          ...node,
          children: newItems,
        });
      }
    }

    return acc;
  }, []);
};

export const getIconByType = (type) => {
  switch (type) {
    case resourceTypes.GROUP:
      return "seem-icon-scope-group";

    case resourceTypes.SCOPE:
      return "seem-icon-scope";

    case resourceTypes.ROOT:
      return "seem-icon-root";

    default:
      return "";
  }
};
