import React, { useContext, useState } from "react";
import "./Instructions.scss";
import TextEditor from "../../../Components/TextEditor/TextEditor";
import Button from "../../../Forms/Controls/Button/Button";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useForm } from "react-hook-form";

const Instructions = ({ onSideDrawerClose, IntegrationContext, onSave }) => {
  const {
    currentIntegration: { instructions, id: dataSourceDefinitionId },
  } = useContext(IntegrationContext);

  const { editDatasource: editDataSourceDefinition } = useFlags();
  const [editMode, setEditMode] = useState(false);
  const methods = useForm({
    defaultValues: {
      instructions: instructions ?? "",
    },
    mode: "all",
  });
  const {
    control,
    reset: formReset,
    formState: { isValid, isDirty },
    handleSubmit,
  } = methods;

  const onSubmit = (formData) => {
    const mutationData = {
      id: dataSourceDefinitionId,
      instructions: formData.instructions,
    };

    onSave(mutationData).then(() => {
      setEditMode(false);
    });
  };

  return (
    <div className={"instructions-wrap"}>
      <Button
        data-testid={"close-pane"}
        isClean
        wrapperClassName={"instructions-close"}
        isLarge
        isSecondary
        isIcon="seem-icon-close"
        type={`button`}
        title={"Close"}
        onClick={() => onSideDrawerClose(false)}
      />
      <div className={"instructions-setup"}>
        <div className={"instructions-title"}>How To Setup?</div>
        {editDataSourceDefinition && !!onSave && (
          <Button
            data-testid={"edit-data-src-definition"}
            type="button"
            label={"Edit"}
            onClick={() => setEditMode(true)}
          />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={"instructions-form"}>
        <Controller
          name="instructions"
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <TextEditor
                disabled={!editMode}
                readOnly={!editMode}
                data-testid={`instructions-editor`}
                wrapperClassName={
                  !editMode
                    ? `instructions-editor`
                    : `instructions-editor --edit`
                }
                size="max"
                label={editMode ? "Instructions" : ""}
                labelTop
                name={"instructions"}
                onBlur={onBlur}
                value={value}
                onChange={(val) => onChange(val)}
                inputStyle={"bomba-style"}
              />
            );
          }}
          rules={{ required: "Required" }}
          control={control}
        />
        {editMode ? (
          <div className={"instruction-form-buttons"}>
            <Button
              data-testid={"cancel-edit-instructions"}
              type="button"
              label={"Cancel"}
              onClick={() => {
                setEditMode(false);
                formReset();
              }}
            />
            <Button
              data-testid={"save-edit-instructions"}
              type="submit"
              label={"Save"}
              isSecondary
              disabled={!isValid || !isDirty}
            />
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default Instructions;
