import SidebarLayout from "../../Components/SidebarLayout/SidebarLayout";
import React, { useContext, useMemo } from "react";
import UserProfile from "../UserProfile/UserProfile";
import SLACustomization from "../SLACustomization/SLACustomization";
import UsersList from "../UsersList/UsersList";
import { useFlags } from "launchdarkly-react-client-sdk";
import SAMLSettings from "../SAMLSettings/SAMLSettings";
import DataSources from "../Integrations/DataSources/DataSources";
import TicketingSystems from "../Integrations/TicketingSystems/TicketingSystems";
import {
  AppPreferencesContext,
  userActionsEnum,
} from "../../General/AppPreferencesProvider";
import Permissions from "../Permissions/Permissions";

const SettingsMenu = () => {
  const { integrationsPage } = useFlags();
  const { isAllowed } = useContext(AppPreferencesContext);

  const menu = useMemo(() => {
    return {
      title: "Settings",
      items: [
        {
          title: "My Account",
          reference: "myAccount",
          icon: "account",
          items: [
            {
              title: "Profile",
              reference: `profile`,
              component: <UserProfile />,
            },
          ],
        },
        {
          title: "Authentication",
          reference: "authentication",
          icon: "key",
          items: [
            ...(isAllowed(userActionsEnum.MANAGE_USERS)
              ? [
                  {
                    title: "User Management",
                    reference: `userManagement`,
                    component: <UsersList />,
                  },
                ]
              : []),
            ...(isAllowed(userActionsEnum.MANAGE_PERMISSIONS)
              ? [
                  {
                    title: "Permissions",
                    reference: `permissions`,
                    component: <Permissions />,
                  },
                ]
              : []),
            ...(isAllowed(userActionsEnum.MANAGE_SAML)
              ? [
                  {
                    title: "SAML Settings",
                    reference: `samlSettings`,
                    component: <SAMLSettings />,
                  },
                ]
              : []),
          ],
        },
        {
          title: "Integrations",
          reference: "integrations",
          icon: "connections",
          items: [
            ...(integrationsPage
              ? [
                  {
                    title: "Data Sources",
                    reference: `dataSources`,
                    component: <DataSources />,
                  },
                ]
              : []),
            {
              title: "Ticketing & Notifications",
              reference: `ticketingSystems`,
              component: <TicketingSystems />,
            },
          ],
        },
        {
          title: "Risk Customization",
          reference: "riskCustomization",
          icon: "shield",
          items: [
            {
              title: "SLA Rules",
              component: <SLACustomization />,
              reference: `slaRules`,
            },
            // {
            //   title: "Scoring Configuration",
            //   reference: `scoringConfiguration`,
            // },
          ],
        },
      ],
    };
  }, [integrationsPage, isAllowed]);

  return <SidebarLayout menu={menu} />;
};

export default SettingsMenu;
