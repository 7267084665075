import { useQuery } from "@apollo/client";
import {
  GET_TICKET_PROVIDER_DEFINITIONS,
  GET_TICKET_PROVIDERS,
} from "./TicketingSystemsApi";
import Loader from "../../../Components/Loader/Loader";
import Spinner from "../../../images/loader.svg";
import React, { useEffect, useMemo, useState } from "react";
import IntegrationTicket from "../IntegrationTicket/IntegrationTicket";
import "./TicketingSystems.scss";
import useIntegrationManagement from "../useIntegrationManagement";
import { dataSourceIcons } from "../../../Findings/FindingsUtils";
import { ticketManagersEnum } from "../../../Ticketing/TicketingUtils";
import IntegrationDetails from "../IntegrationDetails/IntegrationDetails";
import TicketingSystemDetailsInstanceRow from "./TicketingSystemDetailsInstanceRow/TicketingSystemDetailsInstanceRow";
import NewTicketingSystemInstance from "./NewTicketingSystemInstance/NewTicketingSystemInstance";

const TicketingSystems = () => {
  const { loading: loadingDefinitions, data: ticketingSystemsDefinitionsData } =
    useQuery(GET_TICKET_PROVIDER_DEFINITIONS);
  const { loading: loadingProviders, data: ticketingSystemsData } = useQuery(
    GET_TICKET_PROVIDERS,
    {
      variables: {
        filters: { not: { known_identifier: "seemplicity" } },
      },
    }
  );

  const {
    integrationContext: IntegrationContext,
    currentIntegration,
    activeComponent,
    handleBackToMainPage,
    handleViewIntegration,
    handleInstanceAddition,
    handleInstanceEditing,
    setCurrentIntegration,
  } = useIntegrationManagement();
  const SYSTEMS_DEFAULTS = {
    ticketing: [
      {
        type: ticketManagersEnum.JIRA,
        imageLong: dataSourceIcons.JIRA?.L,
        imageShort: dataSourceIcons.JIRA?.S,
        name: "Jira",
        unavailable: true,
        instances: [],
      },
      {
        type: ticketManagersEnum.MONDAY,
        imageLong: dataSourceIcons.MONDAY?.L,
        imageShort: dataSourceIcons.MONDAY?.S,
        name: "monday.com",
        unavailable: true,
        instances: [],
      },
      {
        type: ticketManagersEnum.SERVICENOW,
        imageLong: dataSourceIcons.SERVICENOW?.L,
        imageShort: dataSourceIcons.SERVICENOW?.S,
        name: "ServiceNow",
        unavailable: true,
        instances: [],
      },
      {
        type: ticketManagersEnum.FIBERY,
        imageLong: dataSourceIcons.FIBERY?.L,
        imageShort: dataSourceIcons.FIBERY?.S,
        name: "Fibery",
        unavailable: true,
        instances: [],
      },
      {
        type: ticketManagersEnum.ZENDESK,
        imageLong: dataSourceIcons.ZENDESK?.L,
        imageShort: dataSourceIcons.ZENDESK?.S,
        name: "ZenDesk",
        unavailable: true,
        instances: [],
      },
    ],
    notifications: [
      {
        type: ticketManagersEnum.SLACK,
        imageLong: dataSourceIcons.SLACK?.L,
        imageShort: dataSourceIcons.SLACK?.S,
        name: "Slack",
        unavailable: true,
        instances: [],
      },
    ],
  };

  const [ticketingSystems, setTicketingSystems] = useState(
    SYSTEMS_DEFAULTS.ticketing
  );
  const [notificationsSystems, setNotificationsSystems] = useState(
    SYSTEMS_DEFAULTS.notifications
  );
  const layoutComponents = (integration) => {
    if (!!IntegrationContext) {
      return [
        {
          reference: "view",
          component: (
            <IntegrationDetails
              instances={
                <div className={"instances-wrapper"}>
                  {integration.instances?.map((instance) => (
                    <TicketingSystemDetailsInstanceRow
                      instance={instance}
                      IntegrationContext={IntegrationContext}
                    />
                  ))}
                </div>
              }
              buttonData={{
                text: "Back to Ticketing & Notifications",
              }}
              IntegrationContext={IntegrationContext}
            />
          ),
        },
        {
          reference: "create",
          component: (
            <NewTicketingSystemInstance
              IntegrationContext={IntegrationContext}
            />
          ),
        },
        {
          reference: "edit",
          component: (
            <NewTicketingSystemInstance
              IntegrationContext={IntegrationContext}
            />
          ),
        },
      ];
    }
  };

  const getMainComponent = useMemo(() => {
    return (
      !!ticketingSystems && (
        <div className={"ticketing-systems-wrapper"}>
          {!!IntegrationContext && !loadingDefinitions && !loadingProviders ? (
            <>
              <div className={"integrations-sub-title"}>Ticketing Systems</div>
              <ul className={`integrations-section-wrap`}>
                {ticketingSystems.map((ticketingSystem) => (
                  <li key={ticketingSystems.file_prefix}>
                    <IntegrationTicket
                      IntegrationContext={IntegrationContext}
                      data={ticketingSystem}
                      data-testid={`ticket-${ticketingSystem.file_prefix}`}
                    />
                  </li>
                ))}
              </ul>
              <div className={"integrations-sub-title"}>Notifications</div>
              <ul className={`integrations-section-wrap`}>
                {notificationsSystems.map((ns) => (
                  <li key={ticketingSystems.file_prefix}>
                    <IntegrationTicket
                      IntegrationContext={IntegrationContext}
                      data={ns}
                      data-testid={`ticket-${ns.file_prefix}`}
                    />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Loader center img={Spinner} />
          )}
        </div>
      )
    );
  }, [loadingDefinitions, loadingProviders, ticketingSystems]);

  useEffect(() => {
    if (!ticketingSystemsDefinitionsData) {
      return;
    }

    let updatedTicketingSystems = [...ticketingSystems];
    let updatedNotificationsSystems = [...notificationsSystems];
    ticketingSystemsDefinitionsData.ticket_provider_definitions.edges.forEach(
      (def) => {
        let currentSystem = updatedTicketingSystems.find(
          (ts) => ts.type === def?.node?.type
        );

        if (!currentSystem) {
          currentSystem = updatedNotificationsSystems.find(
            (ns) => ns.type === def?.node?.type
          );
        }

        if (!!currentSystem) {
          currentSystem.unavailable = false;
          currentSystem.id = def?.node?.id;
          currentSystem.sections = def?.node?.sections;
          currentSystem.instructions = def?.node?.instructions;
        }
      }
    );

    setNotificationsSystems(updatedNotificationsSystems);
    setTicketingSystems(updatedTicketingSystems);
  }, [ticketingSystemsDefinitionsData]);

  useEffect(() => {
    if (!ticketingSystemsData) {
      return;
    }

    let updatedTicketingSystems = [...ticketingSystems];
    let updatedNotificationsSystems = [...notificationsSystems];

    ticketingSystemsData.ticket_providers.edges.forEach((providerInstance) => {
      let currentSystem = updatedTicketingSystems.find(
        (ts) => ts.type === providerInstance.node.type
      );

      if (!currentSystem) {
        currentSystem = updatedNotificationsSystems.find(
          (ns) => ns.type === providerInstance.node.type
        );
      }

      if (!!currentSystem) {
        if (currentSystem.instances?.length === 0) {
          currentSystem.hasAtLeastOneInstance = true;
          currentSystem.isEnabled = true;
        }
        currentSystem.instances.push(providerInstance.node);
      }
    });

    setTicketingSystems(updatedTicketingSystems);
  }, [ticketingSystemsData]);

  return (
    !!IntegrationContext && (
      <IntegrationContext.Provider
        value={{
          currentIntegration,
          activeComponent,
          handleBackToMainPage,
          handleViewIntegration,
          handleInstanceAddition,
          handleInstanceEditing,
          setCurrentIntegration,
        }}
      >
        {(!!currentIntegration &&
          layoutComponents(currentIntegration).find(
            (el) => el.reference === activeComponent
          )?.component) ||
          getMainComponent}
      </IntegrationContext.Provider>
    )
  );
};

export default TicketingSystems;
