import { dataSourceIcons } from "../Findings/FindingsUtils";
import moment from "moment";
import {
  CRITICAL,
  HIGH,
  JIRA,
  LOW,
  MEDIUM,
  SEEM,
  NTTGN,
  ZENDESK,
} from "../icons/TicketingIcons/TicketingIcons";

export const ticketEndpointTypeEnum = {
  REPORT: "REPORT",
  TICKET: "TICKET",
  INTERNAL: "INTERNAL",
};

export const ticketEndpointContextEnum = {
  MANUAL_TICKET: "CREATE_TICKET",
  RMQ: "CREATE_RMQ",
};

export const ticketingIcons = {
  "jira-mark": { S: JIRA, name: "Jira" },
  "nttgn-mark": { S: NTTGN, name: "NTTGN" },
  "zendesk-mark": { S: ZENDESK, name: "ZENDESK" },
  "seemplicity-mark": { S: SEEM, name: "Seemplicity" },
  red_button: { S: CRITICAL, name: "Critical" },
  orange_button: { S: HIGH, name: "High" },
  yellow_button: { S: MEDIUM, name: "Medium" },
  green_button: { S: LOW, name: "Low" },
};

export const FieldMappingFieldsTemplate = {
  TITLE: "finding_title",
  URL: "finding_url",
  DESCRIPTION: "finding_description",
  SEVERITY: "finding_severity",
  ID: "finding_id",
  CLOUDACCOUNT: "finding_cloud_account",
  REMEDIATIONTXT: "remediation_text",
  REMEDIATIONURL: "remediation_url",
  RESOURCENAME: "resource_name",
  DATASOURCE: "datasource",
};

export const ticketManagersEnum = {
  JIRA: "JIRA",
  MONDAY: "MONDAY",
  SERVICENOW: "SERVICENOW",
  SLACK: "SLACK",
  FIBERY: "FIBERY",
  ZENDESK: "ZENDESK",
  NTTGN: "NTTGN",
  SEEMPLICITY: "SEEMPLICITY",
};

export const ticketManagers = [
  {
    label: "",
    value: ticketManagersEnum.SEEMPLICITY,
    name: ticketManagersEnum.SEEMPLICITY,
    leftImg: dataSourceIcons?.SEEMPLICITY.L,
    friendlyName: "Seemplicity",
  },
  {
    label: "",
    value: ticketManagersEnum.JIRA,
    name: ticketManagersEnum.JIRA,
    leftImg: dataSourceIcons?.JIRA.L,
    friendlyName: "Jira",
  },
  {
    label: "",
    value: ticketManagersEnum.MONDAY,
    name: ticketManagersEnum.MONDAY,
    leftImg: dataSourceIcons?.MONDAY.L,
    friendlyName: "Monday",
  },
  {
    label: "",
    value: ticketManagersEnum.SERVICENOW,
    name: ticketManagersEnum.SERVICENOW,
    leftImg: dataSourceIcons?.SERVICENOW.L,
    friendlyName: "Service Now",
  },
  {
    label: "",
    value: ticketManagersEnum.SLACK,
    name: ticketManagersEnum.SLACK,
    leftImg: dataSourceIcons?.SLACK.L,
    friendlyName: "Slack",
  },
  {
    label: "",
    value: ticketManagersEnum.FIBERY,
    name: ticketManagersEnum.FIBERY,
    leftImg: dataSourceIcons?.FIBERY.L,
    friendlyName: "Fibery",
  },
  {
    label: "",
    value: ticketManagersEnum.ZENDESK,
    name: ticketManagersEnum.ZENDESK,
    leftImg: dataSourceIcons?.ZENDESK.L,
    friendlyName: "Zendesk",
  },
  {
    label: "",
    value: ticketManagersEnum.NTTGN,
    name: ticketManagersEnum.NTTGN,
    leftImg: dataSourceIcons?.NTTGN.L,
    friendlyName: "NTTGN",
  },
];

export const ticketStatus = {
  BACKLOG: {
    text: "Backlog",
    icon: "jira-icon-backlog",
  },
  SCHEDULED: {
    text: "Scheduled",
    icon: "jira-icon-scheduled",
  },
  IN_PROGRESS: {
    text: "In Progress",
    icon: "jira-icon-in-progress",
  },
  DONE: {
    text: "Done",
    icon: "jira-icon-done",
  },
  DELETED: {
    text: "Deleted",
    icon: "bin",
  },
  REJECTED: {
    text: "Rejected",
    icon: "close",
  },
};

export const getTicketManagerFriendlyName = (ticketManagerValue) =>
  ticketManagers.find((el) => el.value === ticketManagerValue)?.friendlyName;

export const getDefaultDueDateBySeverity = (severity) => {
  switch (severity) {
    case "low":
      return moment().add(720, "days").format("YYYY-MM-DD");

    case "medium":
      return moment().add(360, "days").format("YYYY-MM-DD");

    case "high":
      return moment().add(180, "days").format("YYYY-MM-DD");

    case "critical":
      return moment().add(60, "days").format("YYYY-MM-DD");

    default:
      return moment().add(1, "days").format("YYYY-MM-DD");
  }
};
