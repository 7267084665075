import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import "./UserProfile.scss";
import SetupMFA from "../../Auth/MFA/SetupMFA";
import { Controller, useForm, FormProvider } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import { GET_USERS, EDIT_USER } from "../UsersList/UsersListApi";
import { useQuery, useMutation, gql } from "@apollo/client";
import { MFAType } from "../../Auth/MFA/MFAUtils";
import { AuthContext } from "../../Auth/AuthContext";
import { useTranslation } from "react-i18next";
import Button from "../../Forms/Controls/Button/Button";
import UserEditableFields from "./UserEditableFields/UserEditableFields";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { showDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import ChangeOwnPassword from "./ChangeOwnPassword/ChangeOwnPassword";

const UserProfile = () => {
  const {
    authState: { username, mfaType },
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const methods = useForm({
    mode: "all",
  });
  const [editUser] = useMutation(EDIT_USER);
  const [userId, setUserId] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset: formReset,
    getValues,
  } = methods;
  const { data } = useQuery(GET_USERS, {
    variables: { filters: { email: username } },
    skip: !username,
  });

  useEffect(() => {
    if (!!data) {
      formReset({
        ...getValues(),
        firstName: data?.users?.edges[0]?.node?.first_name,
        lastName: data?.users?.edges[0]?.node?.last_name,
      });
      setUserId(data?.users?.edges[0]?.node?.id);
    }
  }, [data]);

  useEffect(() => {
    if (!!username) {
      formReset({
        ...getValues(),
        email: username,
      });
    }
  }, [username]);

  const onSubmit = (data) => {
    editUser({
      variables: {
        user_id: userId,
        first_name: data?.firstName,
        last_name: data?.lastName,
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            users(existingUsers, { readField }) {
              if (data?.change_user_fields?.ok) {
                const editedUserRef = cache.writeFragment({
                  id: `User:${userId}`,
                  data: {
                    first_name: data?.firstName,
                    last_name: data?.lastName,
                  },
                  fragment: gql`
                    fragment EditedUser on User {
                      first_name
                      last_name
                    }
                  `,
                });

                return {
                  edges: [
                    ...existingUsers.edges.filter(
                      (user) => userId !== readField("id", user)
                    ),
                    editedUserRef,
                  ],
                };
              }
            },
          },
        });
      },
    })
      .then((res) => {
        if (res.data?.change_user_fields?.ok) {
          showToast({ message: `${res.data?.change_user_fields?.msg}` });
        } else {
          showDialog({
            title: "Error",
            type: "error",
            message: `${res.data?.change_user_fields?.msg}`,
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        }
      })
      .catch((error) =>
        showDialog({
          title: "Error",
          type: "error",
          message: error.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        })
      );
  };

  return (
    <div className={"user-profile-wrapper"}>
      <div className={"settings-section user-profile-details"}>
        <div className={"title"}>Details</div>
        <FormProvider {...methods}>
          <form
            className={"user-profile-details-form"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={"user-profile-details-fields"}>
              <UserEditableFields />
              <Controller
                name={"email"}
                defaultValue={null}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    type={"text"}
                    wrapperClassName={"user-actions-field"}
                    label={"Email"}
                    disabled
                    inputStyle={"bomba-style"}
                    labelTop
                    size={"custom"}
                    error={errors && errors.email ? errors.email : null}
                    value={value}
                    onChange={(val) => onChange(val)}
                    onBlur={onBlur}
                    data-testid={"user-profile-email"}
                  />
                )}
                rules={{ required: "Required" }}
                control={control}
              />
            </div>
            <Button
              textLink
              isInForm
              isFullWidth
              label={t("Change Password")}
              data-testid={"reset-pw-btn"}
              title={t("Change Password")}
              type={"button"}
              onClick={() =>
                openModal({
                  title: "Change Password",
                  size: "xs",
                  overlay: true,
                  component: <ChangeOwnPassword />,
                })
              }
            />
            <Button
              isSecondary
              label={t("Save")}
              wrapperClassName={"save-user-profile-details"}
              isSmall
              data-testid={"save-user-profile-details"}
              title={t("Save")}
              type={"submit"}
            />
          </form>
        </FormProvider>
      </div>
      <div className={"settings-section user-profile-mfa"}>
        <div className={"title user-profile-sub-title"}>
          Multi Factor Authentication
        </div>
        <div className={"subtitle"}>
          Secure your account by adding phone verification to your password.
        </div>
        {mfaType !== MFAType.SOFTWARE_TOKEN_MFA ? (
          <Button
            textLink
            isInForm
            isFullWidth
            label={t("Enable MFA")}
            data-testid={"enable-mfa-btn"}
            title={t("Enable MFA")}
            type={"button"}
            onClick={() =>
              openModal({
                title: "Setup TOTP",
                size: "s",
                className: "setup-totp-modal",
                component: <SetupMFA />,
              })
            }
          />
        ) : (
          <div className={"mfa-already-enabled"}>
            <i className={`seem-icon seem-icon-jira-icon-done`} />
            <span>MFA is Enabled</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
