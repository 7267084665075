import "./SideDrawer.scss";
import React from "react";
import { createPortal } from "react-dom";
import { animated, config, useSpring } from "react-spring";
import ClassNames from "classnames";

const SideDrawer = ({
  children,
  isFullScreen,
  target = "",
  width,
  shadow = "l",
  minWidth,
}) => {
  const animationStyle = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, width: "0%" },
    to: { opacity: 1, width: "100%" },
  });

  const classNameWrapper = ClassNames("side-drawer-wrapper", {
    "has-shadow --l": shadow === "l",
    "has-shadow --s": shadow === "s",
  });

  const drawerComponent = () => (
    <animated.div
      className={`${classNameWrapper} ${isFullScreen ? "is-full-screen" : ""}`}
      style={
        !isFullScreen
          ? { ...animationStyle, width, minWidth, maxWidth: width }
          : {}
      }
    >
      {children}
    </animated.div>
  );

  return target
    ? createPortal(drawerComponent(), document.getElementById(target))
    : drawerComponent();
};

export default SideDrawer;
