import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import Button from "../../../../Forms/Controls/Button/Button";
import Tree from "../../../../Components/Tree/Tree";
import { GET_SCOPE_HIERARCHY_BY_PROFILES } from "../../../Permissions/PermissionsApi";
import { SYSTEM } from "../../../../Findings/FindingsFilters/FilterUtils";
import "./AllowedScopes.scss";

const AllowedScopes = ({ onClosingAllowedScopes, profiles = null }) => {
  const { data } = useQuery(GET_SCOPE_HIERARCHY_BY_PROFILES, {
    variables: {
      profile_ids: profiles?.map((profile) => profile.id),
    },
    skip: !profiles,
    fetchPolicy: "network-only",
  });

  const treeData = useMemo(() => {
    if (!!data) {
      const scopesList = JSON.parse(
        data?.scope_hierarchy_by_profiles?.scope_hierarchy
      );

      const newRoot = { ...(!!scopesList ? scopesList[0] : null) };
      if (newRoot?.children?.length) {
        newRoot.children = newRoot?.children?.filter(
          (node) => node?.editable && node?.created_by !== SYSTEM
        );
      }

      if (Object.keys([newRoot][0]).length) {
        return [newRoot];
      } else {
        return [];
      }
    }
  }, [data]);

  return (
    <div className={"allowed-scopes-wrapper"}>
      <Button
        wrapperClassName={"back-to-integrations"}
        data-testid={"back-to-btn"}
        type={"button"}
        leftIcon={"seem-icon-left-arrow"}
        label={"Back"}
        textLink
        onClick={onClosingAllowedScopes}
      />
      <div className={`input-wrap bomba-style has-label-top`}>
        <label className={`input-label`}>Allowed Groups & Scopes</label>
        <div className={"allowed-scopes-tree"}>
          <Tree
            data={treeData}
            nodeOperations={{}}
            data-testid={"profile-scopes-selection-tree"}
          />
        </div>
      </div>
    </div>
  );
};

export default AllowedScopes;
