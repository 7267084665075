import { useForm, Controller, useWatch } from "react-hook-form";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../Forms/Controls/Button/Button";
import React, { useContext, useState } from "react";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import "./CreateTenant.scss";
import { useMutation } from "@apollo/client";
import { CREATE_NEW_CUSTOMER } from "../CustomerProvisioningApi";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import CheckBox from "../../Forms/Controls/CheckBox/CheckBox";

const CreateTenant = () => {
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;
  const withoutEmail = useWatch({
    control,
    name: `withoutEmail`,
    defaultValue: false,
  });

  const [createNewCustomer] = useMutation(CREATE_NEW_CUSTOMER, {
    refetchQueries: ["get_customers"],
  });
  const { closeModal } = useContext(ModalContext);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (data) => {
    setIsSubmittingForm(true);

    let user = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data?.email,
    };

    if (data?.withoutEmail) {
      user = { ...user, password: data?.password };
    }
    createNewCustomer({
      variables: {
        customer: {
          domain: data?.email?.substring(data?.email?.lastIndexOf("@") + 1),
        },
        send_email: !data?.withoutEmail,
        user: user,
      },
    })
      .then((res) => {
        setIsSubmittingForm(false);
        if (!!res.data?.new_customer?.ok) {
          closeModal();
          showToast({
            message: res.data?.new_customer?.msg,
          });
        } else {
          showErrorDialog({ message: res.data?.new_customer?.msg });
        }
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <div>
      <form
        className={`create-tenant-wrapper`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name={"firstName"}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <TextInput
                type={"text"}
                value={value}
                data-testid={"first-name"}
                labelTop
                onBlur={onBlur}
                name={"firstName"}
                onChange={(e) => {
                  onChange(e);
                }}
                label={"First Name"}
                size="l"
                inputStyle={"bomba-style"}
                error={errors.firstName ? errors.firstName : null}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
          }}
        />
        <Controller
          name={"lastName"}
          defaultValue={""}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <TextInput
                type={"text"}
                value={value}
                data-testid={"last-name"}
                labelTop
                onBlur={onBlur}
                name={"lastName"}
                onChange={(e) => {
                  onChange(e);
                }}
                label={"Last Name"}
                size="l"
                inputStyle={"bomba-style"}
                error={errors.lastName ? errors.lastName : null}
              />
            );
          }}
          control={control}
        />
        <Controller
          name={"email"}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <TextInput
                type={"text"}
                value={value}
                data-testid={"email"}
                labelTop
                onBlur={onBlur}
                name={"username"}
                onChange={(e) => {
                  onChange(e);
                }}
                label={"Email"}
                size="l"
                inputStyle={"bomba-style"}
                error={errors.email ? errors.email : null}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
          }}
        />
        <Controller
          name={"withoutEmail"}
          render={({ field: { value, onChange } }) => {
            return (
              <CheckBox
                name={"withoutEmail"}
                data-testid={"withoutEmail"}
                checked={value}
                className={"send-email-cb"}
                label={"Do not send an invitation email to the user"}
                onChange={(val, newValue) => {
                  onChange(newValue);
                }}
                error={errors.withoutEmail ? errors.withoutEmail : null}
              />
            );
          }}
          control={control}
        />
        {withoutEmail && (
          <Controller
            shouldUnregister={true}
            name={"password"}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <TextInput
                  type={"text"}
                  value={value}
                  data-testid={"password"}
                  labelTop
                  onBlur={onBlur}
                  name={"password"}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  label={"Password"}
                  size="l"
                  inputStyle={"bomba-style"}
                  error={errors.password ? errors.password : null}
                />
              );
            }}
            rules={{
              required: "Required",
            }}
            control={control}
          />
        )}
        <ModalButtonsContainer>
          <Button
            type="submit"
            label={"Create Tenant"}
            data-testid={"create-tenant"}
            isSecondary
            isSubmitting={isSubmittingForm}
            rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
            disabled={!isValid || !isDirty || isSubmittingForm}
          />
        </ModalButtonsContainer>
      </form>
    </div>
  );
};
export default CreateTenant;
