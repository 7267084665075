import React, { useState, useMemo } from "react";
import "../Components/SelectionMenu/SelectionMenu.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Spring, animated } from "react-spring/renderprops";
import { DASHBOARD_TYPES } from "../Dashboard/DashboardUtils";
import { useFlags } from "launchdarkly-react-client-sdk";

const AppMenu = () => {
  const [selectedItemOpen, setSelectedItemOpen] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  let history = useHistory();
  let { id } = useParams();
  let match = useRouteMatch("/:item");
  const { resourcesPage } = useFlags();

  const menuItems = useMemo(() => {
    const items = [
      {
        label: "Dashboard",
        value: "dashboard",
        isDisabled: false,
        leftIcon: "seem-icon-dashboard",
        link: `dashboard/${DASHBOARD_TYPES.ORGANIZATIONAL}`,
      },
      {
        label: "Findings",
        value: "findings",
        isDisabled: false,
        leftIcon: "seem-icon-findings",
        link: "findings",
      },
      ...(resourcesPage
        ? [
            {
              label: "Resources",
              value: "resources",
              leftIcon: "seem-icon-icon-resources-filled",
              link: "resources",
            },
          ]
        : []),
    ];

    items.push({
      label: "Remediation",
      value: "remediation",
      isDisabled: false,
      leftIcon: "seem-icon-remed",
      subItems: ["remediationQueue"],
      link: "remediation",
    });

    return items;
  }, [resourcesPage]);

  return (
    <div className={`selection-menu-wrapper primary-menu`}>
      <ul
        className={`selection-menu-list selection-menu-list-selected-${selectedIndex}`}
      >
        <div className="selection-bg" />
        {menuItems.map((menuItem, index) => {
          const isActive =
            menuItem.value === match?.params?.item ||
            menuItem?.subItems?.includes(match?.params?.item);
          const isDisabled = menuItem.isDisabled;
          return (
            <li
              key={index}
              className={`selection-menu-option ${
                isActive ? "is-active" : ""
              } ${isDisabled ? "is-disabled" : ""}`}
              onClick={() => {
                setSelectedIndex(index);
                if (menuItem.hasOwnProperty("subMenu")) {
                  history.push(
                    `/${menuItem.link}/${menuItem.subMenu?.[0].pageLink}`
                  );
                } else {
                  history.push(`/${menuItem.link}`);
                }
              }}
              onMouseOver={() => setSelectedItemOpen(menuItem.value)}
              onMouseLeave={() => setSelectedItemOpen(null)}
            >
              {!!menuItem.leftIcon && (
                <i className={`seem-icon ${menuItem.leftIcon}`} />
              )}
              {!!menuItem.leftImg && (
                <div
                  style={{ backgroundImage: `url(${menuItem.leftImg})` }}
                  className={`selection-img`}
                />
              )}
              <span className="selection-menu-option-text">
                {menuItem.label}
              </span>
              <div className="menu-underline" />
              {menuItem.hasOwnProperty("subMenu") &&
                selectedItemOpen === menuItem.value && (
                  <Spring
                    native
                    unique
                    from={{ opacity: 0, transform: "translate3d(0,-20px,0)" }}
                    to={{ opacity: 1, transform: "translate3d(0,-20px,0)" }}
                  >
                    {(props) => (
                      <animated.div
                        style={props}
                        className="sub-header-menu-item-wrapper"
                      >
                        {menuItem.subMenu.map((subMenuItem, subMenuIndex) => {
                          return (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push(
                                  `/${menuItem.value}/${subMenuItem.pageLink}`
                                );
                              }}
                              className={`sub-header-menu-item-link${
                                menuItem.value === match?.param?.item &&
                                subMenuItem.pageLink === id
                                  ? "-active"
                                  : ""
                              }`}
                              title={subMenuItem.title}
                            >
                              {subMenuItem.title}
                            </div>
                          );
                        })}
                      </animated.div>
                    )}
                  </Spring>
                )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AppMenu;
