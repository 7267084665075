import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../Login/LoginPage.scss";
import { completeNewPassword } from "../AuthApi";
import { AuthContext } from "../AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import PasswordPolicy from "../PasswordPolicy/PasswordPolicy";
import { useTranslation } from "react-i18next";
import Button from "../../Forms/Controls/Button/Button";

const ChangePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { authState } = useContext(AuthContext);
  const {
    handleSubmit,
    // errors,
    control,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const passwordValue = useWatch({ control, name: "password" });
  const { t } = useTranslation();

  let history = useHistory();

  const onSubmit = (data) => {
    changePassword(data);
  };

  const changePassword = (data) => {
    setIsSubmitting(true);
    completeNewPassword(authState.user, data.password)
      .then(() => {
        setIsSubmitting(false);
        reset();
        history.push("/findings"); //todo: or login
      })
      .catch((err) => {
        console.log(err);
        setError("confirmPassword", {
          type: "error",
          message: t("An error occurred. Change password failed."),
        });
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <h2 className="login-page-title">{t("Create a new Password")}</h2>
      <form className="login-page-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          wrapperClassName={`login-username`}
          name={"password"}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextInput
                value={value}
                onChange={(val) => onChange(val)}
                onBlur={onBlur}
                error={errors && errors.password ? errors.password : null}
                placeholder={"PASSWORD"}
                type={"password"}
                size={"max"}
                isLarge
              />
            );
          }}
          control={control}
          rules={{ required: "Required" }}
        />

        <Controller
          wrapperClassName={`login-password`}
          name={"confirmPassword"}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextInput
                value={value}
                onChange={(val) => onChange(val)}
                onBlur={onBlur}
                error={
                  errors && errors.confirmPassword
                    ? errors.confirmPassword
                    : null
                }
                placeholder={"CONFIRM PASSWORD"}
                type={"password"}
                size={"max"}
                isLarge
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || "Passwords should match";
              },
            },
          }}
        />

        {!!passwordValue && <PasswordPolicy password={passwordValue} />}

        <Button
          type="submit"
          label={"Reset Password"}
          rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
          isSecondary
          disabled={isSubmitting}
          className="login-btn"
          isLarge
        />
      </form>
    </>
  );
};

export default ChangePassword;
