import React, { useMemo } from "react";
import "./Avatar.scss";
import ClassNames from "classnames";

const Avatar = ({ name, disabled = false, size = "m" }) => {
  const stringToHslColor = (str, a, s = 30, l = 60) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%, " + a + "%)";
  };

  const initials = useMemo(() => {
    const nameArr = name?.split(" ");

    if (nameArr?.length === 1 || !nameArr?.[1]) {
      return nameArr[0]
        .substring(0, nameArr[0]?.[1] === "@" ? 1 : 2)
        ?.toUpperCase();
    }

    return `${nameArr?.[0]?.substring(0, 1)?.toUpperCase()}${nameArr?.[1]
      ?.substring(0, 1)
      ?.toUpperCase()}`;
  }, [name]);

  const bgColor = useMemo(() => {
    return stringToHslColor(initials?.toLowerCase(), 15);
  }, [initials]);

  const borderColor = useMemo(() => {
    return stringToHslColor(initials?.toLowerCase(), 100);
  }, [initials]);

  const classNameWrapper = ClassNames("user-avatar", {
    "is-small": size === "s",
    "is-medium": size === "m",
  });

  return (
    <div
      className={classNameWrapper}
      title={name}
      style={{
        backgroundColor: !disabled ? bgColor : "white",
        borderColor: !disabled ? borderColor : "#BCBCBC",
        color: !disabled ? borderColor : "#BCBCBC",
      }}
    >
      <span>{initials}</span>
    </div>
  );
};

export default Avatar;
