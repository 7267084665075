import React, { useContext, useMemo, useState } from "react";
import ActionsMenu from "../Components/ActionsMenu/ActionsMenu";
import { LS_FILTER, LS_SCOPE } from "../Findings/FindingsFilters/FilterUtils";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ModalContext } from "../Forms/Dialogs/Modal/ModalContext";
import { useApolloClient } from "@apollo/client";
import { AuthContext } from "../Auth/AuthContext";
import "./AppSettings.scss";
import Button from "../Forms/Controls/Button/Button";
import DsLastSynced from "./DsLastSynced/DsLastSynced";
import Popover from "react-popover";
import { useFlags } from "launchdarkly-react-client-sdk";
import ScoringCustomizationList from "../ScoringCustomization/ScoringCustomizationList/ScoringCustomizationList";
import useCognitoAuthWithLogs from "../Auth/useCognitoAuthWithLogs";

const AppSettings = () => {
  let history = useHistory();
  const { openModal } = useContext(ModalContext);
  const client = useApolloClient();
  const [lastSyncedOpen, setLastSyncedOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { customerProvisioning, scoringCustomization } = useFlags();
  let match = useRouteMatch("/:item");
  const { signOut } = useCognitoAuthWithLogs();
  const {
    authState: { username, isDemoAccount },
    updateAuthState,
  } = useContext(AuthContext);

  const getSettingsMenuItems = useMemo(() => {
    const menuItems = [
      { text: "Settings", onClick: () => history.push(`/settings`) },
    ];

    if (scoringCustomization) {
      menuItems.push({
        text: "Scoring Customization",
        onClick: () => {
          openModal({
            title: "Scoring Customization",
            className: "scoring-customization-modal",
            size: "m",
            overlay: true,
            component: <ScoringCustomizationList />,
          });
        },
      });
    }

    return menuItems;
  }, [scoringCustomization]);

  const getUserMenuItems = useMemo(() => {
    const menuItems = [];

    if (customerProvisioning) {
      menuItems.push({
        text: "Customer Provisioning",
        onClick: () => {
          history.push("/customerProvisioning");
        },
      });
    }

    menuItems.push({
      text: "Logout",
      onClick: () => {
        localStorage.removeItem(LS_SCOPE);
        localStorage.removeItem(LS_FILTER);
        client.clearStore().finally(() => {
          signOut()
            .then(() => {
              history.push("/");
              updateAuthState({ user: null });
            })
            .catch((err) => console.log("logout error", err));
        });
      },
    });

    return menuItems;
  }, [username, customerProvisioning]);

  const handleUserMenuOpenChange = (isOpen) => {
    setUserMenuOpen(isOpen);
  };

  return (
    <div className={`selection-menu-wrapper settings-menu`}>
      <div className={"settings-menu-option-wrapper"}>
        <Popover
          isOpen={lastSyncedOpen}
          tipSize={0.01}
          place={"below"}
          body={[<DsLastSynced />]}
          enterExitTransitionDurationMs={0}
          onOuterAction={() => setTimeout(() => setLastSyncedOpen(false), 200)}
        >
          <Button
            isClean
            data-testid={"open-last-synced"}
            className={`settings-menu-option ${
              lastSyncedOpen ? "header-settings-menu-active" : ""
            }`}
            isIcon="seem-icon-last-synced"
            type={`button`}
            title={"last synced"}
            onClick={() => setLastSyncedOpen(!lastSyncedOpen)}
          />
        </Popover>
      </div>
      <div
        className={`settings-menu-option-wrapper system-settings-container ${
          match?.params?.item === "settings"
            ? "header-settings-menu-active"
            : ""
        }`}
      >
        {isDemoAccount && getSettingsMenuItems?.length ? (
          <ActionsMenu menuItems={getSettingsMenuItems}>
            <i
              data-testid={"settings-menu-button"}
              className={`seem-icon seem-icon-fix settings-menu-option`}
            />
            <div className="menu-underline is-demo" />
          </ActionsMenu>
        ) : !isDemoAccount ? (
          <>
            <i
              data-testid={"settings-menu-button"}
              className={`seem-icon seem-icon-fix`}
              onClick={() => history.push(`/settings`)}
            />
            <div className="menu-underline" />
          </>
        ) : null}
      </div>
      {getUserMenuItems?.length ? (
        <div className={"settings-menu-option-wrapper user-container-option"}>
          <ActionsMenu
            menuItems={getUserMenuItems}
            onMenuOpenChange={handleUserMenuOpenChange}
          >
            <div
              className={`user-details-container settings-menu-option ${
                userMenuOpen ? "header-settings-menu-active" : ""
              }`}
            >
              <i className="user-icon seem-icon seem-icon-user" />
              <div className={`username-display`}>{username || ""}</div>
            </div>
          </ActionsMenu>
        </div>
      ) : null}
    </div>
  );
};

export default AppSettings;
