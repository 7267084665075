import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../Forms/Controls/Button/Button";
import ProfilesItem from "./ProfilesItem/ProfilesItem";
import ProfileActions from "./ProfilesItem/ProfileActions/ProfileActions";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import Loader from "../../../Components/Loader/Loader";
import noProfilesImg from "../../../images/no-profiles.svg";
import "./Profiles.scss";
import { useQuery } from "@apollo/client";
import { GET_PROFILES } from "../PermissionsApi";
import Spinner from "../../../images/loader.svg";
import { resourceTypes } from "../../../Findings/FindingsUtils";

const Profiles = () => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const [profiles, setProfiles] = useState(null);

  const { data, error } = useQuery(GET_PROFILES);

  useEffect(() => {
    if (!!data) {
      const profilesData = data?.profiles?.edges?.map((profile) => {
        return {
          name: profile.node.name,
          id: profile.node.id,
          last_updated: profile.node.last_updated,
          scopes: profile.node.scopes.edges.map((scope) => {
            return { ...scope.node, type: resourceTypes.SCOPE };
          }),
          scopes_groups: profile.node.scope_groups.edges.map((scope_group) => {
            return { ...scope_group.node, type: scope_group.node.group_type };
          }),
          users: profile.node.users.edges.map((user) => {
            return { ...user.node };
          }),
        };
      });

      setProfiles(profilesData);
    }
  }, [data]);

  const noDataState = useMemo(
    () => (
      <div className={"profiles-wrapper"} data-testid={"profiles-content"}>
        <Loader
          flexcenter
          img={noProfilesImg}
          title={"No Profiles"}
          subtitle={
            "A profile contains a set of Scopes and Scopes Groups which determine which data the user can reach. Scopes contains the organization resources."
          }
          action={{
            label: "Add Your First Profile",
            onClick: () =>
              openModal({
                title: "Add Profile",
                size: "s",
                overlay: true,
                component: <ProfileActions mode={"create"} />,
              }),
          }}
        />
      </div>
    ),
    []
  );

  const loadingState = useMemo(
    () => (
      <div
        className={"profiles-wrapper is-loading"}
        data-testid={"profiles-content"}
      >
        <Loader center img={Spinner} />
      </div>
    ),
    []
  );

  const errorState = useMemo(
    () => (
      <div
        className={"profiles-wrapper is-error"}
        data-testid={"profiles-content"}
      >
        An Error Occurred.
      </div>
    ),
    []
  );

  return !!profiles && profiles.length ? (
    <div className={"profiles-wrapper"} data-testid={"profiles-content"}>
      <Button
        isSecondary
        wrapperClassName={"permissions-btn"}
        label={"+ Add Profile"}
        isl
        data-testid={"add-profile"}
        onClick={() =>
          openModal({
            title: "Add Profile",
            size: "s",
            overlay: true,
            component: <ProfileActions mode={"create"} />,
          })
        }
      />
      <div className={"page-description"}>
        {t(
          "A profile contains a set of Scopes and Scopes Groups which determine which data the user can reach. Scopes contains the organization resources."
        )}
      </div>
      <ul className={"permissions-list-wrapper"}>
        {profiles.map((profile) => (
          <li key={profile.id}>
            <ProfilesItem profile={profile} />
          </li>
        ))}
      </ul>
    </div>
  ) : !!profiles && !profiles.length ? (
    noDataState
  ) : error ? (
    errorState
  ) : (
    loadingState
  );
};

export default Profiles;
