export const attachmentsActions = {
  INIT: "INIT",
  UPDATE_ATTACHMENT_DATA: "UPDATE_ATTACHMENT_DATA",
  ADD_ATTACHMENTS: "ADD_ATTACHMENTS",
  REMOVE_ATTACHMENT: "REMOVE_ATTACHMENT",
};

export const AttachmentsReducer = (attachments, action) => {
  switch (action.type) {
    case attachmentsActions.INIT: {
      return action.payload;
    }

    case attachmentsActions.UPDATE_ATTACHMENT_DATA: {
      const attachmentsCopy = [...attachments];
      const index = attachmentsCopy.findIndex(
        (file) =>
          file[action.payload.key] === action.payload[action.payload.key]
      );
      attachmentsCopy[index] = {
        ...attachmentsCopy[index],
        ...action.payload.fileData,
      };

      return attachmentsCopy;
    }

    case attachmentsActions.ADD_ATTACHMENTS: {
      return [...action.payload, ...attachments];
    }

    case attachmentsActions.REMOVE_ATTACHMENT: {
      return attachments.filter(
        (attachment) => attachment.id !== action.payload.id
      );
    }

    default:
      return attachments;
  }
};
