import React, { useCallback, useRef, useState } from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import "./SelectInput.scss";
import { useTranslation } from "react-i18next";
import CheckBox from "../CheckBox/CheckBox";
import ClassNames from "classnames";
import { useClickAway } from "react-use";
import FormFieldError from "../FormFieldError/FormFieldError";
import { DropdownIndicator } from "../../../Findings/FindingsFilters/FilterHeaderStyleComponents/FilterHeaderStyleComponents";

const SelectInput = ({
  className,
  isLoading,
  options,
  label,
  labelMini,
  onChange,
  value,
  defaultValue,
  placeholder,
  isSearchable,
  isMulti,
  customStyle,
  replaceableComponents,
  openMenuOnClick,
  customProps,
  SelectInputStyle,
  wrapperClassName,
  menuWrapClassName,
  size,
  disabled,
  labelTop,
  isClearable = false,
  required,
  readOnly,
  labelWidth,
  isInline,
  isCreateable = false,
  setDefaultSingleOption = true,
  isLarge,
  isRound,
  menuIsOpen,
  error,
  onBlur,
  name,
  closeMenuOnSelect,
  onInputChange,
  inputValue,
  onFocus,
  "data-testid": dataTestId,
  transitionTimeout,
}) => {
  const { t } = useTranslation();
  const [isMenuOpen, toggleMenu] = useState(false);
  const selectWrapperRef = useRef(null);
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const onFocusChange = () => {
    if (isFocused) {
      if (onBlur) {
        onBlur();
      }
    } else {
      if (onFocus) {
        onFocus();
      }
    }
    setIsFocused(!isFocused);
  };

  useClickAway(
    selectWrapperRef,
    () => {
      if (closeMenuOnSelect !== false) {
        onInputChange("");
        setIsFocused(false);
        selectRef?.current?.blur();
        toggleMenu(false);
      } else {
        if (onBlur) {
          onBlur();
        }

        toggleMenu(true);
      }
    },
    ["click"]
  );

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-select",
    wrapperClassName && wrapperClassName,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-label-mini": labelMini,
      "has-required": required,
      "is-disabled": disabled,
      "has-read-only": readOnly,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth?.length,
      "has-size-custom": size === "custom",
      "is-inline": isInline,
      "is-large": isLarge,
      "is-round": isRound,
      "is-multi": isMulti,
      "has-menu-title": customProps?.menuTitle,
      "is-searchable": isSearchable,
      "has-error": !!error,
      "has-value": !!value || (Array.isArray(value) && value?.length),
      "has-no-value": !value || (Array.isArray(value) && !value?.length),
    },
    className
  );

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        color: "#24292B",
        backgroundColor: state.isSelected ? "rgba(228, 228, 229, 0.6)" : "#fff",
        ":active": {
          backgroundColor: state.isSelected
            ? "rgba(228, 228, 229, 0.6)"
            : "#fff",
        },
        ":hover": {
          backgroundColor: !state.isSelected
            ? "#EFF2F5"
            : "rgba(228, 228, 229, 0.6)",
        },
      };
    },
    control: (provided) => ({
      ...provided,
      outline: "none",
      borderColor: "var(--main-border-color)",
      boxShadow: "none",
      ":focus": {
        outline: "none",
        borderColor: "var(--main-border-color)",
        boxShadow: "none",
      },
      ":hover": {
        outline: "none",
        borderColor: "var(--main-border-color)",
        boxShadow: "none",
      },
      ":active": {
        outline: "none",
        borderColor: "var(--main-border-color)",
        boxShadow: "none",
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "5px",
    }),
  };

  const handleOnChange = (newValue) => {
    if (isMulti) {
      onChange(newValue || []);
    } else if (value?.value !== newValue?.value && !!onChange) {
      onChange(newValue, value);
    }
  };

  const handleInputChange = (newValue, { action }) => {
    if (action !== "set-value" && action !== "menu-close") {
      // in order to prevent options reset (load-on-demand)
      onInputChange(newValue);
    }
  };

  const ValueContainer = ({ children, getValue, ...props }) => {
    const selectedValues = getValue();
    const newChildren = [...children];

    if (SelectInputStyle === "filter-row-value-style") {
      const newInput = React.cloneElement(newChildren[1], {
        ...newChildren[1]?.props,
        placeholder: isCreateable
          ? "Type to search/add..."
          : selectedValues.length
          ? "Type to search..."
          : `Type to search...`,
        className: "filter-search-input",
      });
      newChildren[1] = newInput;
    }

    if (!isMulti) {
      const hasIcon = selectedValues?.[0]?.leftIcon;
      const hasImg = selectedValues?.[0]?.leftImg;
      return (
        <components.ValueContainer {...props}>
          <div
            className={`single-value-wrapper ${hasIcon ? "has-icon" : ""} ${
              hasImg ? "has-image" : ""
            }`}
          >
            {hasIcon && (
              <i
                className={`select-icon-value seem-icon ${selectedValues?.[0]?.leftIcon}`}
              />
            )}
            {hasImg && (
              <img
                className={`select-image-value`}
                src={selectedValues?.[0]?.leftImg}
              />
            )}
            {newChildren}
          </div>
        </components.ValueContainer>
      );
    }

    let maxToShow = 2;
    let length = selectedValues.length;

    newChildren[0] =
      length > maxToShow
        ? `${length} selected`
        : selectedValues.map((val) => val.label).join(", ");

    return length > maxToShow ? (
      <div>
        <components.ValueContainer {...props}>
          <div className={`multi-select-items-count`}>{newChildren}</div>{" "}
        </components.ValueContainer>
      </div>
    ) : !!length ? (
      <components.ValueContainer {...props}>
        <div className={`multi-select-item-selected`}>{newChildren}</div>
      </components.ValueContainer>
    ) : (
      <components.ValueContainer {...props}>
        {newChildren}
      </components.ValueContainer>
    );
  };

  const Option = (optionProps) => {
    const { innerRef } = optionProps;

    return isMulti ? (
      <>
        <div
          ref={innerRef}
          title={optionProps.data.label}
          onClick={(e) => e.stopPropagation()}
          className={"is-multi-wrap"}
        >
          <components.Option {...optionProps}>
            {optionProps.data.leftImg && <img src={optionProps.data.leftImg} />}
            {optionProps.data.leftIcon && (
              <i
                className={`select-option-icon seem-icon seem-icon-${optionProps.data.leftIcon}`}
              />
            )}
            <CheckBox
              data-testid={optionProps.data.label
                ?.replaceAll(" ", "_")
                ?.toLowerCase()}
              className={
                optionProps.data.disabled
                  ? "select-option is-disabled"
                  : "select-option"
              }
              name={optionProps.data.label}
              label={optionProps.data.label}
              checked={optionProps.isSelected}
            />
          </components.Option>
        </div>
      </>
    ) : optionProps.data.leftImg ? (
      <div title={optionProps.data.label}>
        <components.Option {...optionProps}>
          <div>
            <img src={optionProps.data.leftImg} />
            <div className={`select-option-with-img-label`}>
              {optionProps.data.label}
            </div>
          </div>
        </components.Option>
      </div>
    ) : optionProps.data.leftIcon ? (
      <div
        className="select-option-wrap select-option-with-icon-wrap"
        title={optionProps.data.label}
      >
        <components.Option {...optionProps}>
          <div
            title={optionProps.data.label}
            className={`select-option-with-icon`}
          >
            <i
              className={`select-option-icon seem-icon ${optionProps.data.leftIcon}`}
            />
            <div className={`select-option-with-icon-label`}>
              {optionProps.data.label}
            </div>
          </div>
        </components.Option>
      </div>
    ) : (
      <div className="select-option-wrap" title={optionProps.data.label}>
        <components.Option {...optionProps} />
      </div>
    );
  };

  const Menu = ({ children, ...props }) => {
    return (
      <div
        className={`select-input-menu ${menuWrapClassName} ${
          SelectInputStyle ? SelectInputStyle : ""
        }
        ${customProps?.menuTitle ? "has-menu-title" : ""}`}
      >
        <components.Menu {...props}>{children}</components.Menu>
      </div>
    );
  };

  const getComponents = useCallback(() => {
    if (SelectInputStyle === "bomba-style") {
      return {
        Option,
        Menu,
        ValueContainer,
        DropdownIndicator: DropdownIndicator,
      };
    } else {
      return { Option, Menu, ValueContainer, ...replaceableComponents };
    }
  }, [replaceableComponents]);

  const getValue = () => {
    if (!value && options?.length === 1 && setDefaultSingleOption) {
      onChange(options?.[0]);
      return options?.[0];
    } else {
      return value;
    }
  };

  const commonProps = {
    onChange: handleOnChange,
    onInputChange: handleInputChange,
    options,
    menuIsOpen: menuIsOpen || isMenuOpen,
    menuPosition: "absolute",
    menuPlacement: "auto",
    menuPortalTarget: document.body,
    className: `select-input-holder-wrap-inner ${
      isFocused ? "is-focused" : ""
    }`,
    classNamePrefix: "select",
    defaultValue,
    isLoading,
    name,
    styles: { ...customStyles, ...customStyle },
    isSearchable,
    value: getValue(),
    placeholder,
    components: getComponents(),
    isMulti,
    hideSelectedOptions: false,
    closeMenuOnSelect: !isMulti || closeMenuOnSelect,
    openMenuOnClick,
    customProps,
    isDisabled: disabled || readOnly,
    onBlur: () => {
      onFocusChange();
    },
    onFocus: () => {
      onFocusChange();
    },
    inputValue: inputValue || undefined,
  };

  return (
    <div
      ref={selectWrapperRef}
      className={`input-wrap input-wrap-select ${classNameWrapper} ${wrapperClassName} ${
        SelectInputStyle ? SelectInputStyle : ""
      }`}
    >
      {!!label && (
        <label
          style={{ width: labelWidth ? labelWidth : "" }}
          className={`input-label`}
        >
          {`${t(label)}`}
        </label>
      )}
      <div
        className={`select-input-holder-wrap ${className}`}
        onClick={(e) => {
          if (!disabled && !readOnly) {
            if (isMenuOpen) {
              onInputChange("");
              setIsFocused(false);
              selectRef?.current?.blur();
            }

            setTimeout(() => {
              toggleMenu(!isMenuOpen);
            }, transitionTimeout);
          }
        }}
        data-testid={`${dataTestId}-management`}
      >
        {!isCreateable ? (
          <Select
            ref={selectRef}
            isClearable={isClearable}
            {...commonProps}
            blurInputOnSelect={!isMulti}
          />
        ) : (
          <CreatableSelect
            isClearable={isClearable}
            {...commonProps}
            blurInputOnSelect={true}
          />
        )}
      </div>
      <div className="error-holder">
        {error && (
          <FormFieldError
            errorMessage={error.message}
            errorType={error.type}
            isOpen={!isFocused}
          />
        )}
      </div>
    </div>
  );
};

SelectInput.defaultProps = {
  label: null,
  options: [],
  isLoading: false,
  className: "",
  placeholder: "",
  isMulti: false,
  customStyle: {},
  replaceableComponents: {},
  openMenuOnClick: true,
  customProps: {},
  wrapperClassName: "",
  menuWrapClassName: "",
  size: "m",
  disabled: false,
  labelTop: false,
  required: false,
  readOnly: false,
  labelWidth: "",
  isInline: false,
  isLarge: false,
  isRound: false,
  menuIsOpen: false,
  onBlur: () => {},
  isSearchable: false,
  onInputChange: () => {},
  inputValue: "",
  onFocus: () => {},
  transitionTimeout: 0,
};

SelectInput.propTypes = {
  label: PropTypes.string,
  labelMini: PropTypes.bool,
  options: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  menuWrapClassName: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  labelTop: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  labelWidth: PropTypes.string,
  isInline: PropTypes.bool,
  isLarge: PropTypes.bool,
  isRound: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  "data-testid": PropTypes.string.isRequired,
  transitionTimeout: PropTypes.number,
};

export default SelectInput;
