import Loader from "../Loader/Loader";
import Spinner from "../../images/loader.svg";
import React from "react";
import "./Chart.scss";
// import noResultsImg from "../../../../src/icons/images/no-results.svg";

const Chart = ({
  chartLoading,
  chartName,
  chartError,
  chartData,
  legendTitle = null,
}) => {
  const errorExistsCondition = () => {
    return chartError && !chartLoading;
  };

  const noDataCondition = () => {
    return !chartData && !chartError && !chartLoading;
  };

  return (
    <div className={"chart-and-legend-wrap"}>
      {chartLoading ? (
        <>
          <div id={chartName} className="chart-wrap">
            <Loader center img={Spinner} />
          </div>
          <div className={"legend-wrap"} />
        </>
      ) : null}
      {errorExistsCondition() ? (
        <Loader center text={"Error loading data"} />
      ) : null}
      {noDataCondition() ? (
        <Loader center text={"No match for the selected filter"} />
      ) : null}
      {!chartLoading && !errorExistsCondition() && !noDataCondition() ? (
        <>
          <div id={chartName} className="chart-wrap" />
          <div className={"legend-data-container"}>
            {legendTitle && <div className={`legend-title`}>{legendTitle}</div>}
            <div
              id={`${chartName}-legend-container`}
              className={"legend-wrap"}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Chart;
