import React, { useEffect, useState } from "react";
import "./ProfileScopesList.scss";
import { getIconByType } from "../../../../../Components/Tree/TreeUtils";

const ProfileScopesList = ({ scopes, scopesGroups, max = null }) => {
  const [allScopes, setAllScopes] = useState(null);

  useEffect(() => {
    if (!!scopes && !!scopesGroups) {
      if (!max) {
        setAllScopes([...scopesGroups, ...scopes]);
      } else {
        setAllScopes([...scopesGroups, ...scopes].slice(0, max));
      }
    }
  }, [scopes, scopesGroups]);

  const getScopeTag = (scope) => {
    return (
      <li
        className={"profiles-scopes-tab"}
        key={scope.id}
        title={scope.name}
        data-testid={`scopes-tab-${scope.id}`}
      >
        <i
          className={`seem-icon ${getIconByType(scope.type)}`}
          data-testid={`tab-icon-${scope.type}`}
        />
        <span>{scope.name}</span>
      </li>
    );
  };

  return (
    !!allScopes && (
      <ul className={"scopes-list-wrapper"}>
        {allScopes?.map((scope) => getScopeTag(scope))}
      </ul>
    )
  );
};

export default ProfileScopesList;
