import { gql } from "@apollo/client";

export const LOG = gql`
  mutation createLogs(
    $levelname: LogLevel!
    $msg: String!
    $username: String
    $stack_trace: String
  ) {
    create_logs(
      input_logs: [
        {
          levelname: $levelname
          msg: $msg
          username: $username
          stack_trace: $stack_trace
          additional_info: "{}"
        }
      ]
    ) {
      ok
      illegal_keys
    }
  }
`;
