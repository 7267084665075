import React, { useContext, useEffect, useState, useMemo } from "react";
import OverviewItem from "./OverviewItem/OverviewItem";
import { RemediationQueueContext } from "../../RemediationQueueProvider";
import { queuePriorities, queueStates } from "../../../RemediationQueuesUtils";
import FindingsByTicketStatus from "../../../../Dashboard/OrganizationalDashboard/FindingsByTicketStatus/FindingsByTicketStatus";
import "./QueueOverview.scss";
import { capitalize } from "lodash";
import Button from "../../../../Forms/Controls/Button/Button";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import SyncQueue from "./SyncQueue/SyncQueue";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { ticketEndpointTypeEnum } from "../../../../Ticketing/TicketingUtils";
import {
  SYNC_QUEUE,
  UPDATE_REMEDIATION_QUEUE,
} from "../QueueSettings/QueueSettingsApi";
import ReactTooltip from "react-tooltip";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { dataSourceIcons } from "../../../../Findings/FindingsUtils";
import { parseExpression } from "cron-parser";
import moment from "moment";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { FiltersContext } from "../../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { ScopeManagementContext } from "../../../../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/useScope";
import ScopeManagement from "../../../../AppView/ScopeManagement/ScopeManagement";
import FilterManagement from "../../../../AppView/FilterManagement/FilterManagement";
import { AppPreferencesContext } from "../../../../General/AppPreferencesProvider";
import useFindingsViewChange from "../../../../Findings/useFindingsViewChange";
import { fieldTypeOptions } from "../../../../Ticketing/GenericField/GenericField";
import useKeysSavedValues from "../../../../Ticketing/TicketEndpointKeys/useKeysSavedValues";
import AlertIndicator from "../../../../Components/AlertIndicator/AlertIndicator";

const QueueOverview = () => {
  const { openModal } = useContext(ModalContext);
  const {
    remediationQueueData: {
      scopeId,
      scopejson,
      filterjson,
      ticketEndpointId,
      state,
      priority,
      providerType,
      ticketEndpointType,
      providerId,
      queueSize,
      findings_count: findingSize,
      id,
      cron,
      finding_filter,
      scope_id,
      syncedFindingsCount,
      remainingFindingsCount,
    },
    loading,
    updateRemediationQueueData,
  } = useContext(RemediationQueueContext);
  const { t } = useTranslation();
  const [syncNowTemporaryDisabled, setSyncNowTemporaryDisabled] =
    useState(false);

  const [nextRun, setNextRun] = useState("");
  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);
  useFindingsViewChange({ queries: ["getDashboard", "GetFindings"] });
  const [updateRemediationQueue, { loading: isUpdating }] = useMutation(
    UPDATE_REMEDIATION_QUEUE
  );
  const { savedKeys, getKeyPropsByKeyName } = useKeysSavedValues(
    providerId,
    ticketEndpointId
  );

  const [syncQueue] = useMutation(SYNC_QUEUE, {
    refetchQueries: [
      "GetRemediationQueue",
      "GetFindings",
      "get_activity",
      "getDashboard",
    ],
  });

  const { filtersState, updateFiltersState, getFilterValueById } =
    useContext(FiltersContext);

  const { getScopeValueById, updateScopeState } = useContext(ScopesContext);

  const { filtersList } = filtersState;

  useEffect(() => {
    if (!!finding_filter) {
      let selectedFilter = getFilterValueById(finding_filter?.id);
      if (!selectedFilter) {
        selectedFilter = {
          label: finding_filter?.name,
          value: {
            id: finding_filter?.id,
            query: finding_filter?.filter_data,
            editable: false,
          },
        };
      }
      updateFiltersState({ selectedFilter });
    }
  }, [filtersList, finding_filter]);

  useEffect(() => {
    if (!!scope_id) {
      const selectedScope = getScopeValueById(scope_id);
      updateScopeState({ selectedScope });
    }
  }, [getScopeValueById, scope_id]);

  useEffect(() => {
    const options = {
      currentDate: moment.utc().format("yyyy-MM-DD HH:mm:ss"),
    };

    if (!!cron) {
      const interval = parseExpression(cron, options);
      const nextRunTimestamp = interval?.next()?.toString();

      const nextRunTime = moment(nextRunTimestamp)
        .add(moment().utcOffset(), "minutes")
        .format("dddd, DD MMMM, hh:mm A");
      setNextRun(`Next sync is on ${nextRunTime}.`);
    }
  }, [cron]);

  const syncNow = () => {
    setSyncNowTemporaryDisabled(true);
    setTimeout(() => {
      setSyncNowTemporaryDisabled(false);
    }, 5000);
    syncQueue({ variables: { remediation_queue_id: id } })
      .then(() => {
        showToast({ message: "Queue Synced!" });
      })
      .catch((err) => showErrorDialog({ message: err.message }));
  };

  const contentBySynced = (queueState) => {
    switch (queueState) {
      case queueStates.ENABLED:
        return (
          <div
            className={
              "remediation-queue-overview-sync remediation-queue-overview-sync-btn-wrap"
            }
          >
            <span className="datatip-wrap">
              <i
                data-tip
                data-for="syncTip"
                className={"seem-icon seem-icon-help"}
              />
              <ReactTooltip
                id="syncTip"
                place="top"
                className={"sync-tooltip"}
                multiline={true}
              >
                {ticketEndpointType !== ticketEndpointTypeEnum.REPORT
                  ? `Pausing the queue stops creating new tickets to ${capitalize(
                      providerType
                    )}.
                  You can turn it back on anytime.`
                  : `Pausing the queue stops sending messages to ${capitalize(
                      providerType
                    )}. You can turn it back on anytime.`}
              </ReactTooltip>
            </span>
            <Button
              data-testid={"pause-sync"}
              label={"Pause Sync"}
              leftIcon={"seem-icon-pause"}
              onClick={() =>
                openModal({
                  title: "Pause Sync?",
                  size: "xs",
                  overlay: true,
                  component: (
                    <SyncQueue
                      ticketEndpointType={ticketEndpointType}
                      updateRemediationQueue={updateRemediationQueue}
                      id={id}
                      providerType={providerType}
                      updateRemediationQueueData={updateRemediationQueueData}
                    />
                  ),
                })
              }
            />
          </div>
        );
      default:
        return (
          <div
            className={
              "remediation-queue-overview-sync remediation-queue-overview-sync-btn-wrap"
            }
          >
            <Button
              data-testid={"start-sync"}
              isSecondary
              rightIcon={isUpdating ? "seem-icon-spinner spinner" : ""}
              disabled={isUpdating}
              label={t("Start Sync")}
              onClick={() => {
                updateRemediationQueue({
                  variables: { state: queueStates.ENABLED, id: id },
                })
                  .then((res) => {
                    if (res?.data?.update_remediation_queue?.ok) {
                      updateRemediationQueueData({
                        state: queueStates.ENABLED,
                      });
                    } else {
                      showErrorDialog({
                        message: "Remediation queue update failed",
                      });
                    }
                  })
                  .catch((error) =>
                    showErrorDialog({ message: error.message })
                  );
              }}
            />
          </div>
        );
    }
  };

  const getTicketingKeysInfo = useMemo(() => {
    return Object.keys(savedKeys).map((key) => {
      const inputType = getKeyPropsByKeyName(key)?.input;
      if (inputType === fieldTypeOptions.SELECT) {
        const defaultValue = getKeyPropsByKeyName(key)?.defaultValue;
        return (
          <OverviewItem
            title={getKeyPropsByKeyName(key)?.label}
            value={
              getKeyPropsByKeyName(key)?.options?.find(
                (option) => option?.id === defaultValue
              )?.name
            }
          />
        );
      }
    });
  }, [savedKeys]);

  const getSelectedScope = useMemo(() => {
    return { value: { query: scopejson, id: scopeId } };
  }, [scopejson, scopeId]);

  const contentByProvider = (type) => {
    switch (type) {
      case ticketEndpointTypeEnum.INTERNAL:
        return (
          <div
            className={`remediation-queue-overview-grid`}
            data-testid={"rmq-overview"}
          >
            <OverviewItem
              className={"overview-synced-with"}
              title={"Synced with"}
              value={
                <img
                  alt={providerType}
                  src={dataSourceIcons[providerType]?.L}
                />
              }
            />
          </div>
        );
      default:
        return (
          <div
            className={`remediation-queue-overview-grid`}
            data-testid={"rmq-overview"}
          >
            <div className={"grid-row"}>
              <div className={`overview-scope-filter-wrap`}>
                <ScopeManagementContext.Provider
                  value={{
                    setDefaultsForScopeAndFilter: false,
                  }}
                >
                  <ScopeManagement
                    editable={false}
                    withTitle={false}
                    readOnly={true}
                  />
                </ScopeManagementContext.Provider>
                <FilterManagement
                  setDefaultsForScopeAndFilter={false}
                  hasEditMode={false}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={"grid-row"}>
              <OverviewItem
                className={"overview-synced-with"}
                title={"Synced with"}
                value={
                  <img
                    alt={providerType}
                    src={dataSourceIcons[providerType]?.L}
                  />
                }
              />
              {getTicketingKeysInfo}
              {type === ticketEndpointTypeEnum.TICKET && (
                <OverviewItem title={"Queue Size"} value={queueSize} />
              )}
            </div>
            <div className={"grid-row"}>
              {state === queueStates.ENABLED && (
                <div className={"overview-sync-now"}>
                  <div className={`schedule-time`}>{nextRun}</div>
                  <div
                    className={`queue-sync-now ${
                      syncNowTemporaryDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      if (!syncNowTemporaryDisabled) {
                        syncNow();
                      }
                    }}
                  >
                    Sync Now
                  </div>
                </div>
              )}
            </div>
            {type === ticketEndpointTypeEnum.TICKET && (
              <div className={"grid-row"}>
                <div className={"overview-status"}>
                  <div className={"overview-status-title"}>
                    {`${findingSize} Findings in Queue`}
                  </div>
                  {state === queueStates.ENABLED &&
                    Number.isInteger(syncedFindingsCount) &&
                    Number.isInteger(remainingFindingsCount) && (
                      <div className={"overview-status-description"}>
                        {`${syncedFindingsCount} Findings Synced, ${remainingFindingsCount} Findings Remaining`}
                      </div>
                    )}
                  <OverviewItem
                    className={"overview-status-chart"}
                    title={"Synced Findings Status"}
                    value={
                      <FindingsByTicketStatus
                        filterToExecute={filterjson}
                        selectedScope={getSelectedScope}
                        scopeToExecute={scopejson}
                        chartLocation={"remediation-queue"}
                        columnHeight={26}
                      />
                    }
                  />
                </div>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div
      className={`remediation-queue-container remediation-queue-overview ${
        loading ? "is-loading" : ""
      }`}
    >
      <div className={`remediation-queue-overview-header`}>
        <div className={"rmq-overview-title-wrapper"}>
          <h3 className={"remediation-queue-title"}>Overview</h3>
          {ticketEndpointType !== ticketEndpointTypeEnum.INTERNAL &&
            contentBySynced(state)}
        </div>
        {slaEnabled &&
          priority === queuePriorities.SLA &&
          ticketEndpointType !== ticketEndpointTypeEnum.INTERNAL && (
            <AlertIndicator
              wrapperClassName={"rmq-overview-prioritize-wrapper"}
              content={"This queue sending order is based on the finding SLA."}
            />
          )}
        {ticketEndpointType === ticketEndpointTypeEnum.INTERNAL && (
          <AlertIndicator
            wrapperClassName={"rmq-overview-prioritize-wrapper"}
            content={
              "Findings in this queue are waiting to be reviewed by the security team."
            }
          />
        )}
      </div>
      {contentByProvider(ticketEndpointType)}
    </div>
  );
};
export default QueueOverview;
