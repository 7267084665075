import { useMemo } from "react";

const useKeysObject = (selectedKeys) => {
  const keysObject = useMemo(() => {
    const keys = {};
    if (!!selectedKeys) {
      Object.entries(selectedKeys)
        ?.filter((entry) => {
          const value = entry?.[1];
          return !!value;
        })
        ?.forEach((entry) => {
          const [key, value] = entry;
          keys[key] = value;
        });
    }

    return keys || {};
  }, [selectedKeys]);

  return { keysObject };
};

export default useKeysObject;
