import React from "react";
import "./ScopeManagementMenuItem.scss";
import ClassNames from "classnames";

const ScopeManagementMenuItem = ({
  title,
  subTitle,
  icon,
  "data-testid": dataTestId,
  disabled = false,
}) => {
  const classNameWrapper = ClassNames("scope-menu-item-wrapper", {
    "is-disabled": disabled,
  });

  return (
    <div className={classNameWrapper} data-testid={dataTestId}>
      <i className={`seem-icon ${icon}`} />
      <div className={`scope-menu-item-data`}>
        <span className={`scope-menu-item-title`}>{title}</span>
        <span className={`scope-menu-item-sub-title`}>{subTitle}</span>
      </div>
    </div>
  );
};

export default ScopeManagementMenuItem;
