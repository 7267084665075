import "./FilterInOut.scss";
import React, { useContext, useMemo } from "react";
import { GeneralAppContext } from "../../../General/GeneralAppProvider";
import { FiltersContext } from "../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { getFilterJson, processFilter } from "../../../AppView/AppViewUtils";
import Button from "../../../Forms/Controls/Button/Button";
import { findingsColumns, findingsQueryParams } from "../../FindingsUtils";
import useFindingsQueryParam from "../useFindingsQueryParam";
import { FindingsContext } from "../../FindingsContext";

const FilterInOut = ({
  field,
  inCondition = "in",
  exCondition = "not_in",
  value,
}) => {
  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const {
    filtersState: { selectedFilter, filterToExecute },
    updateFiltersState,
  } = useContext(FiltersContext);

  const {
    findingsState: { hasSelectedItem },
    selectFindingInList,
  } = useContext(FindingsContext);

  const { clearQueryParam, search } = useFindingsQueryParam(null);

  const filterData = useMemo(() => {
    const filter = JSON.parse(filterToExecute) || null;
    let filterObj = !!filter ? processFilter(filter) : [{}];
    return filterObj.filters;
  }, [filterToExecute]);

  const applyFilter = (newFilterData) => {
    const filterToExecute = getFilterJson({ filterPanels: newFilterData });
    const newFilter = {
      label: selectedFilter.name,
      value: {
        query: filterToExecute,
        id: selectedFilter.id,
        editable: true,
      },
    };

    if (!!search) {
      clearQueryParam([
        findingsQueryParams.FILTER_ID,
        findingsQueryParams.GROUP_VALUE,
        findingsQueryParams.FILTER_TO_EXECUTE,
      ]);
    }

    hasSelectedItem && selectFindingInList(null, false);

    updateFiltersState({
      filterToExecute,
      isEditFilter: false,
      selectedFilter: newFilter,
      shouldBlinkField: field,
    });

    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  const onIncludeFilter = (e) => {
    e.stopPropagation();

    if (field === findingsColumns.TITLE) {
      handleTitleField(inCondition);
    } else {
      const fieldIndex = filterData.findIndex(
        (filter) => filter.field === field
      );
      // field exist in filter panel
      if (fieldIndex >= 0) {
        const filterItem = filterData?.[fieldIndex];

        // condition not equals
        if (filterItem.condition !== inCondition) {
          filterData[fieldIndex] = {
            field,
            condition: inCondition,
            value: [value],
          };
          applyFilter(filterData);
        }
      } else {
        // condition is equal
        filterData.push({
          field,
          condition: inCondition,
          value: [value],
        });

        applyFilter(filterData);
      }
    }
  };

  const handleTitleField = (condition) => {
    const fieldWithSameCondition = filterData.findIndex(
      (filter) => filter.field === field && filter.condition === condition
    );
    const fieldWithDiffCondition = filterData.findIndex(
      (filter) => filter.field === field && filter.condition !== condition
    );

    if (fieldWithDiffCondition >= 0) {
      filterData[fieldWithDiffCondition].value = filterData[
        fieldWithDiffCondition
      ]?.value.filter((itemValue) => itemValue !== value);
      if (!filterData[fieldWithDiffCondition]?.value?.length) {
        filterData.splice(fieldWithDiffCondition, 1);
      }
    }

    if (fieldWithSameCondition >= 0) {
      if (!filterData[fieldWithSameCondition]?.value?.includes(value)) {
        filterData[fieldWithSameCondition].value.push(value);
      }
    } else {
      filterData.push({
        field,
        condition: condition,
        value: [value],
      });
    }
    applyFilter(filterData);
  };

  const onExcludeFilter = (e) => {
    e.stopPropagation();

    if (field === findingsColumns.TITLE) {
      handleTitleField(exCondition);
    } else {
      const fieldIndex = filterData.findIndex(
        (filter) => filter.field === field
      );

      if (fieldIndex >= 0) {
        const filterItem = filterData?.[fieldIndex];

        if (filterItem.condition !== exCondition) {
          filterData[fieldIndex].value = filterData[fieldIndex]?.value.filter(
            (itemValue) => itemValue !== value
          );
          if (!filterData[fieldIndex]?.value?.length) {
            filterData.splice(fieldIndex, 1);
            filterData.push({
              field,
              condition: exCondition,
              value: [value],
            });
          }

          applyFilter(filterData);
        } else {
          filterData[fieldIndex].value.push(value);
          applyFilter(filterData);
        }
      } else {
        filterData.push({
          field,
          condition: exCondition,
          value: [value],
        });

        applyFilter(filterData);
      }
    }
  };

  return (
    <div className={`filter-in-out-container`}>
      <div className={`in-out-wrapper filter-include-container`}>
        <Button
          data-testid={"include-in-filter-btn"}
          isIcon={"seem-icon-circle-plus"}
          isClean
          isSecondary
          onClick={(e) => onIncludeFilter(e)}
          tooltipData={{
            dataTip: `Include Value`,
          }}
        />
      </div>
      <div className={`in-out-wrapper filter-exclude-container`}>
        <Button
          data-testid={"exclude-from-filter-btn"}
          isIcon={"seem-icon-circle-minus"}
          isClean
          isSecondary
          onClick={(e) => onExcludeFilter(e)}
          tooltipData={{
            dataTip: `Exclude Value`,
          }}
        />
      </div>
    </div>
  );
};

export default FilterInOut;
