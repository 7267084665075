const UnsavedDataToast = ({ message }) => {
  return (
    <div className={`unsaved-toast-wrapper`}>
      <div className={`unsaved-toast-offset`} />
      <div className={`unsaved-toast-data`}>
        <i className={`seem-icon seem-icon-info`} />
        <div className={`toast-message`}>{message}</div>
      </div>
    </div>
  );
};

export default UnsavedDataToast;
