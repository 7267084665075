import React, { useMemo } from "react";
import {
  useTable,
  useFlexLayout,
  useFilters,
  useSortBy,
  useExpanded,
} from "react-table";
import Thead from "./Thead/Thead";
import Tbody from "./Tbody/Tbody";
import "./Table.scss";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import TableLoading from "./TableLoading/TableLoading";
import TableNoData from "./TableNoData/TableNoData";

const defaultColumnFilter = ({ column: { filterValue, setFilter, id } }) => {
  return (
    <TextInput
      data-testid={id}
      size="max"
      placeholder={"Type to search..."}
      wrapperClassName={"table-filter-text-input"}
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      leftIcon={`seem-icon-table-filter`}
    />
  );
};

const Table = ({
  columns,
  type = null,
  data,
  tableWrapperClass,
  onRowClick,
  onRowExpand,
  initialSortBy = [],
  initialFilters = [],
  loading,
  "data-testid-body": dataTestIdBody,
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: defaultColumnFilter,
    }),
    []
  );

  const initialState = useMemo(
    () => ({
      sortBy: initialSortBy,
      filters: initialFilters,
    }),
    [initialSortBy, initialFilters]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns, data, defaultColumn, initialState, autoResetExpanded: false },
      useFlexLayout,
      useFilters,
      useSortBy,
      useExpanded
    );

  const getTableContent = () => {
    if (loading) {
      return (
        <div className="table-wrap-loading">
          <table
            className={`table-wrapper ${
              tableWrapperClass ? tableWrapperClass : ""
            } ${!!type ? type : ""}`}
            {...getTableProps()}
          >
            <Thead headerGroups={headerGroups} />

            <Tbody
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              onRowClick={onRowClick}
              onRowExpand={onRowExpand}
            />
          </table>
          <TableLoading />
        </div>
      );
    } else {
      return (
        <>
          <table
            className={`table-wrapper ${
              tableWrapperClass ? tableWrapperClass : ""
            }  ${!!type ? type : ""}`}
            {...getTableProps()}
          >
            <Thead headerGroups={headerGroups} />
            <Tbody
              data-testid={dataTestIdBody}
              rows={rows}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              onRowClick={onRowClick}
              onRowExpand={onRowExpand}
            />
          </table>
          {!data?.length && <TableNoData />}
        </>
      );
    }
  };

  return getTableContent();
};

export default Table;
