import Button from "../../../Forms/Controls/Button/Button";
import React from "react";
import Loader from "../../../Components/Loader/Loader";

const AggregateWithTicketsPopup = ({ onBackClick, onAggregateClick }) => {
  return (
    <div className={`aggregate-popup-wrapper`}>
      <Button
        wrapperClassName={"back-to-aggregation"}
        data-testid={"back-to-btn"}
        type={"button"}
        leftIcon={"seem-icon-left-arrow"}
        label={`Back`}
        textLink
        onClick={() => {
          onBackClick();
        }}
      />

      <Loader
        className={"aggregation-popup"}
        center
        flexcenter
        icon={"seem-icon-aggregated"}
        title={"Just before aggregating..."}
        subtitle={
          <>
            <span className={"bold"} style={{ marginBottom: "20px" }}>
              Some of the selected findings already have open tickets.
            </span>
            <br />
            <span>
              We recommend marking these tickets as “Done” and opening
              <br /> one single ticket for the aggregated finding.
            </span>
          </>
        }
        secondaryAction={{
          label: `Aggregate & Keep Tickets Open`,
          onClick: () => onAggregateClick(false),
        }}
        action={{
          label: `Aggregate & Mark Tickets as Done`,
          onClick: () => onAggregateClick(true),
        }}
      />
    </div>
  );
};

export default AggregateWithTicketsPopup;
