import { gql } from "@apollo/client";

export const GET_FIELDS = gql`
  query ($filterType: FindingFilterTypeEnum!) {
    finding_filters_data(filter_type: $filterType) {
      field
      conditions
      type
    }
  }
`;

export const generateFindingFiltersData = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let findingFiltersDataMock = {
    request: {
      query: GET_FIELDS,
      variables,
    },
  };

  if (error) {
    return {
      ...findingFiltersDataMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...findingFiltersDataMock,
      result: { data: result },
    };
  }

  return {
    ...findingFiltersDataMock,
    result: {},
  };
};

export const GET_FIELD_VALUES = gql`
  query ($filterName: String!, $contains: String, $limit: Int) {
    finding_filter_data(
      filter_name: $filterName
      contains: $contains
      limit: $limit
    ) {
      values
      field
    }
  }
`;

export const CREATE_FINDINGS = gql`
  mutation addManualFindings($manual_findings: [ManualFindingInput]) {
    add_manual_findings(manual_findings: $manual_findings) {
      findings {
        id
        id_int
      }
    }
  }
`;

export const generateAddManualFindings = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let addManualFindingsMock = {
    request: {
      query: CREATE_FINDINGS,
      variables,
    },
  };

  if (error) {
    return {
      ...addManualFindingsMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...addManualFindingsMock,
      result: { data: result },
    };
  }

  return {
    ...addManualFindingsMock,
    result: {},
  };
};
