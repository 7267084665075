import { gql } from "@apollo/client";

export const GET_AGGREGATED_GROUP = gql`
  query get_aggregated_group(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $sort: [FindingSortEnum]
  ) {
    finding(id: $id) {
      id
      aggregation_group_id
      aggregation_group {
        main_finding_id
        findings_count
        open_findings_count
        findings(first: $first, last: $last, after: $after, sort: $sort) {
          edges {
            node {
              id
              id_int
              datasource {
                has_link
                friendly_name
                datasource_id
                datasource_definition {
                  file_prefix
                  id
                  name
                }
              }
              title
              original_finding_id
              original_score
              actual_status {
                key
                category
                type
              }
              system_status {
                key
                category
                type
              }
              user_status {
                key
                category
                type
              }
              discovered_time
              last_reported_time
              resource_name
              resource_id
              resource_type
              owner_email
              owner_name
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;
