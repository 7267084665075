import React, {useCallback, useMemo} from 'react';
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";

const InfiniteLoaderFullScreen = ({hasNextPage, data, isLoadingData, loadMore, getRow, itemSize = 48}) => {

    const rowCount = () => {
        return hasNextPage && data?.length
            ? data?.length + 1
            : data?.length;
    };

    const isRowLoaded = useMemo(() => {
        return (index) =>
            !hasNextPage || index < Math.round(data.length * 0.8);
    }, [data, hasNextPage]);

    const loadMoreItems = isLoadingData ? () => {
    } : loadMore;

    const rowRenderer = useCallback(
        ({index, style}) => {
            const finding = data?.[index];

            if (!isRowLoaded(index) || !finding || index > data.length) {
                return <tr style={{...style, height: `${itemSize}px`}}>LOAD</tr>;
            } else {
                return getRow(finding, style);
            }
        },
        [data]
    );

    const getInfiniteRowsData = () => {
        const isItemLoaded = isRowLoaded;
        const itemCount = rowCount();

        return (
            <InfiniteLoader
                isItemLoaded={isItemLoaded}
                loadMoreItems={() => loadMoreItems()}
                itemCount={itemCount}
                threshold={3}
            >
                {({onItemsRendered, ref}) => {
                    return (
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    onItemsRendered={onItemsRendered}
                                    ref={(list) => {
                                        if (!!list) {
                                            ref(list);
                                        }
                                    }}
                                    className={"findings-infinite-list"}
                                    height={height}
                                    itemCount={itemCount}
                                    itemSize={itemSize}
                                    width={width}
                                >
                                    {rowRenderer}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    );
                }}
            </InfiniteLoader>
        );
    };


    return getInfiniteRowsData();
}

export default InfiniteLoaderFullScreen;