import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {
  getFindingSeverity,
  groupByTypes,
  getSeverityValueByDescription,
} from "../../../Findings/FindingsUtils";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import { getSeverityColor } from "../../DashboardUtils";
import { startCase } from "lodash";
import useDashboardNavigator from "../../useDashboardNavigator";
import useSummary from "../../useSummary";

const AverageAgeBySeverity = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
}) => {
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.age"],
      group_by: [`finding.${groupByTypes.SEVERITY}`],
      aggregate_functions: ["AVG"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });
  const [dashboardData, setDashboardData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  const createCategoryAxis = (categoryAxis) => {
    categoryAxis.dataFields.category = "severity";
    categoryAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.paddingLeft = 10;
    categoryAxis.paddingRight = 20;
    categoryAxis.layout = "absolute";
    categoryAxis.title.text = "Severity";
    categoryAxis.title.fontSize = 14;
    categoryAxis.title.fontWeight = 500;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.title.rotation = 0;
    categoryAxis.title.dy = -30;
    categoryAxis.title.align = "center";
    categoryAxis.title.valign = "top";
    categoryAxis.renderer.grid.template.location = 0;
  };

  const createValueAxis = (valueAxis) => {
    valueAxis.title.text = "Average Age";
    valueAxis.title.fontWeight = 500;
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    valueAxis.renderer.labels.template.fontSize = 12;
  };

  useEffect(() => {
    if (!!data) {
      const dataArray = data.map((el) => {
        return {
          severity: startCase(getFindingSeverity(el[0])),
          age: el[1],
          fill: getSeverityColor(getFindingSeverity(el[0])),
        };
      });

      if (dataArray.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (dataArray.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(dataArray);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      const scopeId = selectedScope?.value?.id;

      let [chart, legendContainer] = createChart(
        "AvgAgeBySeverityDashboard",
        am4charts.XYChart
      );
      chart.data = dashboardData;

      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      createCategoryAxis(categoryAxis);
      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis);

      function createSeries(field, name) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "age";
        series.dataFields.categoryY = "severity";
        series.columns.template.tooltipText = "[bold]{valueX}[/]";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = "white";
        series.columns.template.cursorOverStyle =
          am4core.MouseCursorStyle.pointer;
        series.columns.template.propertyFields.fill = "fill";
        series.columns.template.fillOpacity = 0.6;
        series.columns.template.strokeOpacity = 1;
        series.columns.template.propertyFields.stroke = "fill";
        series.strokeWidth = 1.2;
        series.columns.template.height = am4core.percent(40);
        series.columns.template.maxHeight = 40;

        var hs = series.columns.template.states.create("hover");
        hs.properties.fillOpacity = 1;

        var columnTemplate = series.columns.template;
        columnTemplate.maxX = 0;

        series.columns.template.events.on("hit", (e) => {
          dashboardNavigator(
            null,
            scopeId,
            {
              groupIndex: 0,
              data: {
                severity: getSeverityValueByDescription(
                  e.target.dataItem.dataContext.severity
                ),
              },
            },
            filterToExecute
          );
        });
      }

      createSeries("age", "Age");

      chart.legend.disabled = true;

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"AvgAgeBySeverityDashboard"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default AverageAgeBySeverity;
