import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import "./ScoringCustomizationItem.scss";

const ScoringCustomizationItem = ({
  originalScore,
  defaultScore,
  datasourceName,
}) => {
  const methods = useFormContext();
  const {
    formState: { errors },
    control,
  } = methods;

  const getScores = () => (
    <>
      <div className={"original-score-wrap"}>{originalScore.toLowerCase()}</div>
      <i className="seem-icon-next-arrow" />
      <Controller
        name={`${datasourceName}-${originalScore}`}
        defaultValue={+defaultScore}
        label
        render={({ field: { value, onChange, onBlur, ref } }) => {
          return (
            <TextInput
              onChange={(e) => {
                onChange(e);
              }}
              inputRef={ref}
              onBlur={onBlur}
              value={value}
              error={
                errors && errors[`${datasourceName}-${originalScore}`]
                  ? errors[`${datasourceName}-${originalScore}`]
                  : null
              }
              label={""}
              type={"number"}
              size="xxs"
            />
          );
        }}
        control={control}
        rules={{
          pattern: {
            value: /^(?:[1-9]|10)?(?:\.\d+)?$/,
            message: "Invalid Score",
          },
        }}
      />
    </>
  );

  return <div className={"customization-item-wrapper"}>{getScores()}</div>;
};

export default ScoringCustomizationItem;
