import React from "react";
import SelectInput from "../../Forms/Controls/SelectInput/SelectInput";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import TextEditor from "../../Components/TextEditor/TextEditor";
import Textarea from "../../Forms/Controls/Textarea/Textarea";
import TagsSelect from "../../Forms/Controls/TagsSelect/TagsSelect";
import MappingField from "./MappingField/MappingField";
import SearchQueryField from "./SearchQueryField/SearchQueryField";
import DynamicMappingField from "./DynamicMappingField/DynamicMappingField";
import DateInput from "../../Forms/Controls/DateInput/DateInput";
import "./GenericField.scss";
import moment from "moment";

export const fieldTypeOptions = {
  TEXT: "STRING",
  LABEL: "LABEL",
  TAGS_LIST: "LIST",
  BOOL: "BOOL",
  SELECT: "SELECT",
  SELECT_QUERY: "SELECT_QUERY",
  DATE: "DATE",
  NUMBER: "NUMBER",
  TEXT_AREA: "TEXTAREA",
  HTML_TEXT_AREA: "HTML_TEXTAREA",
  MAPPING: "MAPPING",
  DYNAMIC_MAPPING: "SELECT_MAPPING",
};

const GenericField = (props) => {
  const {
    input,
    label = "",
    name,
    value,
    loading,
    error = null,
    clearable = true,
    options,
    onBlur,
    onChange,
    onInputChange = () => {},
    onFocus,
    creatable,
    query,
    setSingleOption = true,
    mapping,
    placeholder = "",
    searchable = true,
    classname = "",
    disabled = false,
    setValue,
  } = props;

  switch (input) {
    case fieldTypeOptions.SELECT:
      const selectOptions = options?.map((option) => {
        return { value: option?.id, label: option?.name };
      });

      return (
        <SelectInput
          wrapperClassName={classname}
          data-testid={name}
          name={name}
          value={
            selectOptions?.find((option) => option.value === value) || null
          }
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          SelectInputStyle={"bomba-style"}
          size="max"
          labelTop={!!label}
          setDefaultSingleOption={setSingleOption}
          onChange={(val) => onChange(val?.value)}
          onInputChange={(val) => onInputChange(val)}
          error={error}
          isClearable={clearable}
          placeholder={placeholder}
          isSearchable={searchable}
          label={label}
          isLoading={loading}
          options={selectOptions}
        />
      );
    case fieldTypeOptions.TEXT:
      return (
        <TextInput
          wrapperClassName={classname}
          data-testid={name}
          name={name}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          inputStyle={"bomba-style"}
          size="max"
          labelTop
          onChange={(val) => onChange(val)}
          error={error}
          placeholder={placeholder}
          label={label}
        />
      );
    case fieldTypeOptions.TEXT_AREA:
      return (
        <Textarea
          wrapperClassName={classname}
          data-testid={name}
          name={name}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          inputStyle={"bomba-style"}
          size="max"
          labelTop
          onChange={(data) => onChange(data)}
          error={error}
          label={label}
        />
      );
    case fieldTypeOptions.HTML_TEXT_AREA:
      return (
        <TextEditor
          wrapperClassName={`generic-field-text-area ${classname}`}
          data-testid={name}
          name={name}
          value={value}
          disabled={disabled}
          onBlur={onBlur}
          inputStyle={"bomba-style"}
          size="max"
          labelTop
          onChange={(data) => {
            onChange(data);
            setValue(`fields.${name}`, data);
          }}
          error={error}
          label={label}
        />
      );
    case fieldTypeOptions.TAGS_LIST:
      return (
        <TagsSelect
          wrapperClassName={classname}
          data-testid={name}
          name={name}
          value={
            value?.map((id) => {
              const item = options.find((option) => option?.id === id);
              if (item) {
                return item;
              } else {
                return { id: id, name: id };
              }
            }) || []
          }
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          inputStyle={"bomba-style"}
          size="max"
          labelTop
          onChange={(tags) => onChange(tags?.map((tag) => tag.id))}
          error={error}
          placeholder={placeholder}
          label={label}
          allowNew={creatable}
          suggestions={options}
          minQueryLength={0}
          newTagTextPrefix={`+`}
        />
      );
    case fieldTypeOptions.MAPPING:
      return (
        <MappingField
          {...props}
          value={value}
          onChange={(val) => {
            const undefinedRows = Object.keys(val).filter(
              (key) => val[key] === undefined
            );
            undefinedRows.forEach((key) => delete val[key]);

            if (Object.keys(val)?.length) {
              onChange(val);
            } else {
              onChange(null);
            }
          }}
          columns={mapping?.columns}
          data={mapping?.rows}
        />
      );
    case fieldTypeOptions.SELECT_QUERY:
      return (
        <SearchQueryField
          {...props}
          type={fieldTypeOptions.SELECT}
          params={query?.params}
        />
      );
    case fieldTypeOptions.DYNAMIC_MAPPING:
      return (
        <DynamicMappingField
          {...props}
          options={mapping?.rows?.map((row) => row?.field_from)}
        />
      );
    case fieldTypeOptions.NUMBER:
      return (
        <TextInput
          wrapperClassName={classname}
          data-testid={name}
          name={name}
          value={value}
          disabled={disabled}
          type={"number"}
          onBlur={onBlur}
          onFocus={onFocus}
          inputStyle={"bomba-style"}
          size="max"
          labelTop
          onChange={(val) => onChange(val)}
          error={error}
          placeholder={placeholder}
          label={label}
        />
      );
    case fieldTypeOptions.DATE:
      return (
        <DateInput
          onBlur={onBlur}
          name={name}
          labelTop
          onChange={(date) => {
            onChange(
              date ? moment(date.target.value).format("YYYY-MM-DD") : null
            );
          }}
          size="max"
          onFocus={onFocus}
          label={label}
          value={value}
          inputStyle={"bomba-style"}
        />
      );
    default:
      return <></>;
  }
};

export default GenericField;
