import useLogger from "../Logger/useLogger";
import { Auth } from "aws-amplify";

const useCognitoAuthWithLogs = () => {
  const { logError } = useLogger();

  const logErrorMiddleware = (promise) => {
    promise.catch((error) => logError({ message: error.message }));
    return promise;
  };

  const getAuthenticatedUser = () =>
    logErrorMiddleware(Auth.currentAuthenticatedUser());

  const changePassword = (user, oldPassword, newPassword) =>
    logErrorMiddleware(Auth.changePassword(user, oldPassword, newPassword));

  const setPreferredMFA = (user, mfaMethod) =>
    logErrorMiddleware(Auth.setPreferredMFA(user, mfaMethod));

  const verifyTotpToken = (user, challengeAnswer) =>
    logErrorMiddleware(Auth.verifyTotpToken(user, challengeAnswer));

  const setupTOTP = (user) => logErrorMiddleware(Auth.setupTOTP(user));

  const signOut = () => logErrorMiddleware(Auth.signOut());

  return {
    getAuthenticatedUser,
    changePassword,
    setPreferredMFA,
    verifyTotpToken,
    setupTOTP,
    signOut,
  };
};

export default useCognitoAuthWithLogs;
