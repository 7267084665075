import { gql } from "@apollo/client";

export const GET_DASHBOARD = gql`
  query getDashboard(
    $name: SummaryAPIS!
    $selection: [String]
    $group_by: [String]
    $aggregate_functions: [AggregateFunctions]
    $round_functions: [RoundFunctions]
    $filters_config: FiltersConfigInput
  ) {
    summary(
      name: $name
      selection: $selection
      group_by: $group_by
      aggregate_functions: $aggregate_functions
      round_functions: $round_functions
      filters_config: $filters_config
    )
  }
`;
