import React from "react";
import { toast } from "react-toastify";
import "./Toast.scss";
import SeemaOK from "./SeemaOK";

const Toast = ({ message, style }) => {
  return (
    <div className={`toast-box-wrapper`}>
      <SeemaOK />
      <div className={`toast-box-message`}>{message}</div>
    </div>
  );
};

export const showToast = ({
  message,
  container,
  style = "popup",
  position,
  hideProgressBar,
  autoClose = "5000",
  component,
  className,
  closeOnClick = true,
}) => {
  return toast(component || <Toast message={message} style={style} />, {
    containerId: container || "toast-root",
    position: position || "top-center",
    hideProgressBar: hideProgressBar || false,
    autoClose: autoClose,
    className: className,
    closeOnClick: closeOnClick,
  });
};

export const dismissToast = (id) => {
  toast.dismiss(id);
};
