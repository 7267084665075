import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./MappingField.scss";
import GenericField from "../GenericField";
import { ticketingIcons } from "../../TicketingUtils";

const MappingField = (props) => {
  const { columns, data, onChange, value: initialValue } = props;

  const [value, setValue] = useState(null);

  const handleChange = (newVal) => {
    setValue(newVal);
    onChange(newVal);
  };

  useEffect(() => {
    if (!!initialValue && !value) {
      handleChange(initialValue);
    }
  }, [initialValue, value]);

  const getMappingRow = useCallback(
    (row) => {
      return (
        <GenericField
          {...props}
          {...row.field_to}
          onChange={(val) =>
            handleChange({ ...value, [row?.field_from?.name]: val })
          }
          value={value?.[row?.field_from?.name]}
          placeholder={`Choose Mapping for ${row.field_from.label}`}
        />
      );
    },
    [value]
  );

  const mappingHeaders = useMemo(() => {
    return (
      <div className={"mapping-field-row mapping-title"}>
        <div className={"mapping-field-cell-from mapping-cell mapping-title"}>
          <img
            className={"from-icon"}
            alt={ticketingIcons[columns?.field_from?.icon]?.name}
            src={ticketingIcons[columns?.field_from?.icon]?.S}
          />
          {columns?.field_from?.label}
        </div>
        <div className={"mapping-field-cell-to mapping-cell mapping-title"}>
          <img
            className={"to-icon"}
            alt={ticketingIcons[columns?.field_to?.icon]?.name}
            src={ticketingIcons[columns?.field_to?.icon]?.S}
          />
          {columns?.field_to?.label}
        </div>
      </div>
    );
  }, [columns]);

  const mappingData = useMemo(() => {
    if (!!data) {
      return data?.map((row) => {
        return (
          <div
            className={"mapping-field-row mapping-data"}
            key={row?.field_from?.name}
          >
            <div className={"mapping-field-cell-from mapping-cell"}>
              <img
                className={"cell-icon"}
                alt={ticketingIcons[row?.field_from?.icon]?.name}
                src={ticketingIcons[row?.field_from?.icon]?.S}
              />
              {row.field_from.label}
            </div>
            <i className="seem-icon seem-icon-next-arrow" />
            <div className={"mapping-field-cell-to mapping-cell"}>
              {getMappingRow(row)}
            </div>
          </div>
        );
      });
    }
  }, [data, getMappingRow]);

  return (
    <div className={"mapping-field"}>
      {mappingHeaders}
      {mappingData}
    </div>
  );
};

export default MappingField;
