import React, { useContext, useState, useEffect } from "react";
import Tag from "../../../../Forms/Controls/TagsSelect/Tag/Tag";
import "./Tags.scss";
import { findingsQueryParams } from "../../../FindingsUtils";
import useFindingsQueryParam from "../../../FindingsData/useFindingsQueryParam";
import { FindingsContext } from "../../../FindingsContext";
import ReactTooltip from "react-tooltip";
import { useRouteMatch } from "react-router-dom";
import {
  scopePanelMode,
  ScopesContext,
} from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";

const TAG_TYPES = {
  MANUAL: "MANUAL",
  DATA_SOURCE: "DATA_SOURCE",
};
const Tags = ({
  tagsID = Math.random().toString(36),
  seem_tags,
  datasource_tags,
  showCollapsed,
  maxTags,
  showHeader,
  displayKey,
  isFullScreen = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [topTags, setTopTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const { updateScopeState, reset } = useContext(ScopesContext);

  const { clearQueryParam, search } = useFindingsQueryParam(
    findingsQueryParams.SCOPE_ID
  );

  const { selectFindingInList } = useContext(FindingsContext);
  let match = useRouteMatch("/:item");
  const scopeFromTagEnabled =
    match?.params?.item === "findings" && !isFullScreen;

  const createScopeFromTag = (tag) => {
    const selectedScope = {
      label: `Tag: ${tag}`,
      value: {
        query: `{"operator": "and", "operands": [{"value":["${tag}"],"field":"tags_values","condition":"overlap"}]}`,
        editable: true,
      },
      parentId: null,
    };

    if (!!search) {
      clearQueryParam([
        findingsQueryParams.SCOPE_ID,
        findingsQueryParams.GROUP_VALUE,
      ]);
    }

    scopeFromTagEnabled && selectFindingInList(null, false);

    updateScopeState({
      selectedScope: selectedScope,
      scopeToExecute: selectedScope.value.query,
      mode: scopePanelMode.SETTINGS,
    });

    if (reset) {
      reset();
    }
  };

  const getTag = (tag, tagType, index) => {
    const tagId = `${tagsID}_${tagType}_${index}`;
    return (
      <>
        {scopeFromTagEnabled && (
          <ReactTooltip id={`createScopeTip_${tagId}`} place="top">
            {`Create scope with ${tag?.[1]} tag`}
          </ReactTooltip>
        )}
        <Tag
          key={tagId}
          tag={{
            id: tagId,
            name: getTagLabel(tag),
            disabled: true,
            type: tagType === TAG_TYPES.MANUAL ? "" : "is-regular",
          }}
          onClick={(e) => {
            e.stopPropagation();
            scopeFromTagEnabled && createScopeFromTag(tag?.[1]);
          }}
          tooltipData={{ dataTip: true, dataFor: `createScopeTip_${tagId}` }}
        />
      </>
    );
  };

  const getTagLabel = (tag) => {
    return displayKey ? `${tag?.[0]} : ${tag?.[1]}` : `${tag?.[1]}`;
  };

  useEffect(() => {
    const seemTags =
      seem_tags?.map((tag, index) => {
        return getTag(tag, TAG_TYPES.MANUAL, index);
      }) || [];

    const dsTags =
      datasource_tags?.map((tag, index) => {
        return getTag(tag, TAG_TYPES.DATA_SOURCE, index);
      }) || [];

    const topTagsSliced = [...seemTags, ...dsTags]?.slice(0, maxTags);

    setTopTags(topTagsSliced);
    setAllTags([...seemTags, ...dsTags]);
  }, [seem_tags, datasource_tags]);

  return !!seem_tags?.length || !!datasource_tags?.length ? (
    <>
      <div
        className={`tags-container has-${topTags?.length}-tags ${
          allTags?.length > 3 ? "more-than-3-tags" : ""
        }`}
        data-testid={`finding-tags`}
      >
        {showHeader && <div className={`tags-header`}>Tags</div>}
        <div className={`tags-list`}>
          {isOpen ? allTags : topTags}
          {showCollapsed && allTags?.length > maxTags && (
            <div
              data-testid={`more-less-indicator`}
              className={`more-less-indicator`}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? "Less..." : "More..."}
            </div>
          )}
        </div>
      </div>
    </>
  ) : null;
};

export default Tags;
