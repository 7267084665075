import { gql } from "@apollo/client";

export const PROFILE_FRAGMENT = gql`
  fragment CoreProfileFields on Profile {
    id
    name
    last_updated
    users {
      edges {
        node {
          id
          first_name
          last_name
          email
        }
      }
    }
    scopes {
      edges {
        node {
          id
          name
        }
      }
    }
    scope_groups {
      edges {
        node {
          id
          name
          group_type
        }
      }
    }
  }
`;

export const GET_PROFILES = gql`
  ${PROFILE_FRAGMENT}
  query getProfiles {
    profiles {
      edges {
        node {
          ...CoreProfileFields
        }
      }
    }
  }
`;

export const CNC_GET_PROFILES = gql`
  ${PROFILE_FRAGMENT}
  query cncGetProfiles($customer_id: String!) {
    cnc_profiles(customer_id: $customer_id) {
      edges {
        node {
          ...CoreProfileFields
        }
      }
    }
  }
`;

export const generateProfilesData = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let profilesDataMock = {
    request: {
      query: GET_PROFILES,
      variables,
    },
  };

  if (error) {
    return {
      ...profilesDataMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...profilesDataMock,
      result: { data: result },
    };
  }

  return {
    ...profilesDataMock,
    result: {},
  };
};

export const ADD_PROFILE = gql`
  mutation addProfile($profile: ProfileInput) {
    add_profile(profile: $profile) {
      profile_id
    }
  }
`;

export const generateAddProfile = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let addProfileMock = {
    request: {
      query: ADD_PROFILE,
      variables,
    },
  };

  if (error) {
    return {
      ...addProfileMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...addProfileMock,
      result: { data: result },
    };
  }

  return {
    ...addProfileMock,
    result: {},
  };
};

export const EDIT_PROFILE = gql`
  mutation editProfile($profile: ProfileInput, $profile_id: String) {
    edit_profile(profile: $profile, profile_id: $profile_id) {
      profile_id
    }
  }
`;

export const generateEditProfile = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let editProfileMock = {
    request: {
      query: EDIT_PROFILE,
      variables,
    },
  };

  if (error) {
    return {
      ...editProfileMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...editProfileMock,
      result: { data: result },
    };
  }

  return {
    ...editProfileMock,
    result: {},
  };
};

export const DELETE_PROFILE = gql`
  mutation deleteProfile($profile_id: String) {
    delete_profile(profile_id: $profile_id) {
      ok
    }
  }
`;

export const generateDeleteProfile = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let deleteProfileMock = {
    request: {
      query: DELETE_PROFILE,
      variables,
    },
  };

  if (error) {
    return {
      ...deleteProfileMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...deleteProfileMock,
      result: { data: result },
    };
  }

  return {
    ...deleteProfileMock,
    result: {},
  };
};

export const GET_SCOPE_HIERARCHY_BY_PROFILES = gql`
  query ($profile_ids: [String]) {
    scope_hierarchy_by_profiles(profile_ids: $profile_ids) {
      scope_hierarchy
    }
  }
`;
