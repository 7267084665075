import React from "react";

export const FindingsContextInitialValue = {
  scrollToIndex: { index: null, cursor: null },
  dataStructure: new Map(),
  groupsList: null,
  resetList: null,
  hasSelectedItem: false,
  onSelectedFinding: null,
  selectedFilterRow: null,
};

export const FindingsContext = React.createContext(FindingsContextInitialValue);
