import { gql } from "@apollo/client";

export const UPDATE_SAML = gql`
  mutation update_saml($enabled: Boolean!, $file: String, $url: String) {
    update_saml(enabled: $enabled, file: $file, url: $url) {
      ok
      msg
      saml_url
    }
  }
`;

export const GET_LOGIN_URL = gql`
  query settings {
    settings(filters: { key: "saml_url" }) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`;
