import React, { useState } from "react";
import FormFieldError from "../FormFieldError/FormFieldError";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import Button from "../Button/Button";
import moment from "moment";
import "./DateInput.scss";

const DateInput = (props) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const {
    label,
    wrapperClassName,
    inputStyle,
    onBlur,
    labelTop,
    onFocus,
    inputRef,
    tooltipData = {},
    name,
    error,
    disabled = false,
    value,
    dateFormat = "YYYY-MM-DD",
    onChange,
    size,
    maxDate = null,
    minDate = null,
    clearable = true,
    showDisabledValue = false,
  } = props;

  const onFocusChange = () => {
    if (isFocused) {
      if (onBlur) {
        onBlur();
      }
    } else {
      if (onFocus) {
        onFocus();
      }
    }
    setIsFocused(!isFocused);
  };

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-text",
    "input-wrap-datepicker",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-error": !!error,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-clearable": clearable,
      "is-disabled": disabled,
    },
    props.className
  );

  return (
    <div className={classNameWrapper}>
      {label && <label className={`input-label`}>{t(label)}</label>}
      <div
        className={`input-holder ${isFocused ? "is-focused" : ""} ${
          disabled ? "is-disabled" : ""
        } `}
        data-for={tooltipData?.dataFor}
        data-tip={tooltipData?.dataTip}
      >
        <input
          data-testid={props["data-testid"]}
          className={"seem-icon-schedule"}
          ref={inputRef}
          name={name}
          disabled={disabled}
          value={
            (!disabled || showDisabledValue) && !!value
              ? moment(value).format(dateFormat)
              : ""
          } //TODO: ask Bar
          type={"date"}
          max={!!maxDate ? moment(maxDate).format(dateFormat) : null}
          min={!!minDate ? moment(minDate).format(dateFormat) : null}
          onChange={(e) => onChange(e)}
          onBlur={onFocusChange}
          onFocus={onFocusChange}
        />
        {clearable && !disabled && (
          <Button
            data-testid={"date-input-submit"}
            isClean
            isSmall
            className={`reset-date-btn`}
            isIcon="seem-icon-close"
            type={`button`}
            title={t("Close")}
            onClick={() => onChange("")}
          />
        )}
      </div>
      {error && (
        <FormFieldError
          errorMessage={error.message}
          errorType={error.type}
          isOpen={!isFocused}
        />
      )}
    </div>
  );
};

export default DateInput;
