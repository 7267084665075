import React, { useContext } from "react";
import Tree from "../../../../Components/Tree/Tree";
import { findingsQueryParams, resourceTypes } from "../../../FindingsUtils";
import { ScopeManagementContext } from "../ScopeManagement/useScope";
import CreateGroup from "../CreateGroup/CreateGroup";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import { LS_SCOPE } from "../../FilterUtils";
import useFindingsQueryParam from "../../../FindingsData/useFindingsQueryParam";
import {
  scopePanelMode,
  ScopesContext,
} from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { GeneralAppContext } from "../../../../General/GeneralAppProvider";

const ScopesList = ({ editable: editableScopes }) => {
  const {
    scopesState: { scopesList, selectedScope },
    updateScopeState,
    searchTree,
  } = useContext(ScopesContext);

  const { toggleIsOpen } = useContext(ScopeManagementContext);

  const { clearQueryParam, search } = useFindingsQueryParam(
    findingsQueryParams.SCOPE_ID
  );

  const { openModal } = useContext(ModalContext);

  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const onEditScope = (node) => {
    // toggleIsOpen(false);
    if (node.type === resourceTypes.SCOPE) {
      if (node.id === selectedScope?.value?.id) {
        updateScopeState({
          mode: scopePanelMode.SETTINGS,
        });

        return;
      }
      const newSelectedScope = {
        label: node.name,
        value: {
          id: node.id,
          query: JSON.stringify(node.filter_data),
          editable: node.editable,
          type: node?.type,
        },
        parentId: node.parentId,
      };

      if (!!search) {
        clearQueryParam([
          findingsQueryParams.SCOPE_ID,
          findingsQueryParams.GROUP_VALUE,
        ]);
      }

      updateScopeState({
        selectedScope: newSelectedScope,
        scopeToExecute: newSelectedScope.value.query,
        mode: scopePanelMode.SETTINGS,
        isNewScope: false,
      });
    } else if (node.type === resourceTypes.GROUP) {
      toggleIsOpen(false);
      openModal({
        title: "Edit Group",
        icon: "seem-icon-scope-group",
        className: "edit-group-modal",
        size: "s",
        overlay: true,
        component: (
          <CreateGroup
            groupId={node.id}
            root={!!scopesList ? scopesList : null}
            resetFindings={resetFindingsList}
            updateScopeState={updateScopeState}
            searchTree={searchTree}
          />
        ),
      });
    }

    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  const onSelectScope = (node) => {
    toggleIsOpen(false);

    if (selectedScope?.value?.id === node.id) {
      return;
    }

    const newSelectedScope = {
      label: node.name,
      value: {
        id: node.id,
        editable: node.editable,
        type: node.type,
      },
      parentId: node.parentId,
    };

    if (!!search) {
      clearQueryParam([
        findingsQueryParams.SCOPE_ID,
        findingsQueryParams.GROUP_VALUE,
      ]);
    }

    let scopeToExecute = "";
    if (node.type === resourceTypes.SCOPE) {
      newSelectedScope.value.query = JSON.stringify(node.filter_data);
      scopeToExecute = newSelectedScope.value.query;
    } else if (node.type === resourceTypes.GROUP) {
      newSelectedScope.value.query = "";
    }

    // if (setDefaultsForScopeAndFilter) { //TODO: reactivate
    localStorage.setItem(LS_SCOPE, JSON.stringify(newSelectedScope));
    // }
    updateScopeState({
      selectedScope: newSelectedScope,
      scopeToExecute: scopeToExecute,
      isNewScope: false,
    });

    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  return (
    <Tree
      data={scopesList}
      nodeOperations={{ onEdit: onEditScope, onSelect: onSelectScope }}
      isSearchable
      isSelectableRoot
      data-testid={"resources-tree"}
      editableItems={editableScopes}
      selectedNode={selectedScope?.value?.id}
      placeholder={"Search Scopes..."}
    />
  );
};

export default ScopesList;
