import React from "react";
import { components } from "react-select";
import Button from "../../../Forms/Controls/Button/Button";
import "./FilterHeaderComponents.scss";

export const ValueContainer = ({ children, ...props }) => {
  const { innerRef = null, selectProps } = props || {};
  return (
    <div style={{ display: "flex", flex: 1 }} ref={innerRef}>
      <components.ValueContainer {...props}>
        <i className={`seem-icon ${selectProps?.customProps?.icon}`} />
        {children}
      </components.ValueContainer>
    </div>
  );
};

export const Control = ({ children, ...props }) => {
  const { innerRef = null, selectProps } = props || {};
  return (
    <div style={{ display: "flex", flex: 1 }} ref={innerRef}>
      <components.Control {...props}>
        <i className={`seem-icon ${selectProps?.customProps?.icon}`} />
        {children}
      </components.Control>
    </div>
  );
};

export const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator
      {...props}
      className={`seem-icon-dropdown-style`}
    >
      <Button
        data-testid={"dropdown-indicator"}
        type="button"
        isClean
        isIcon="seem-icon-dropdown-arrow"
      />
    </components.DropdownIndicator>
  );
};

export const Option = ({ ...props }) => {
  const { selectProps } = props || {};
  return (
    <div title={props.data.label}>
      <components.Option {...props}>
        <div className={`filter-panel-selection-option`}>
          <div className={`filter-panel-option-name`}>{props.data.label}</div>
          {props.data.value?.editable &&
            selectProps?.customProps?.editableOptions && (
              <div
                className={`filter-option-edit`}
                onClick={(e) => {
                  selectProps?.customProps?.editFilter(props.data);
                  e.stopPropagation();
                }}
              >
                <i
                  className={`seem-icon seem-icon-edit filter-panel-option-edit`}
                />
              </div>
            )}
        </div>
      </components.Option>
    </div>
  );
};
