import React from "react";
import "./ScoreTag.scss";
import PropTypes from "prop-types";
import { upperFirst } from "lodash";

const ScoreTag = ({
  className,
  score,
  severity,
  hideSeverity,
  isLarge,
  isLargeWidth,
}) => {
  return (
    <>
      <div
        className={`score-tag-wrap ${isLarge ? "is-large" : ""} ${
          isLargeWidth ? "is-large-width" : ""
        } ${className ? className : ""}`}
      >
        <div className={`score-tag ${severity} tag-long`}>{score}</div>
      </div>
      {hideSeverity ? (
        ""
      ) : (
        <div className={"score-word"}>{upperFirst(severity)}</div>
      )}
    </>
  );
};

ScoreTag.defaultProps = {
  className: "",
  severity: "",
  score: "",
  isLarge: false,
  hideSeverity: false,
};

ScoreTag.propTypes = {
  className: PropTypes.string,
  severity: PropTypes.string,
  score: PropTypes.number,
  isLarge: PropTypes.bool,
  hideSeverity: PropTypes.bool,
};

export default ScoreTag;
