import Filter from "../../../FilterManagement/FilterPanel/Filter/Filter";
import { filterTypes } from "../../../../Findings/FindingsUtils";
import React, { useContext, useEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { ScopesContext } from "../../ScopeProvider/ScopeProvider";
import useFiltersData from "../../../useFiltersData";
import Button from "../../../../Forms/Controls/Button/Button";

const ScopePanel = ({ namePrefix, previewFilter, rowIndex, removeRow }) => {
  const {
    control,
    formState: { isValid },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${namePrefix}.conditions`, // unique name for your Field Array,
  });
  const {
    scopesState: { selectedScope, fieldsData, isNewScope },
    updateScopeState,
  } = useContext(ScopesContext);

  const watchFieldArray = useWatch({
    control,
    name: `${namePrefix}.conditions`,
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const { fieldsData: filterFieldsData } = useFiltersData({
    filterType: filterTypes.SCOPE,
  });

  useEffect(() => {
    if (!!filterFieldsData) {
      updateScopeState({ fieldsData: filterFieldsData });
    }
  }, [filterFieldsData]);

  return (
    <div className={`filter-rows`}>
      {!!fieldsData?.length &&
        controlledFields?.map((scopeItem, index) => (
          <Filter
            filterItem={scopeItem}
            key={scopeItem?.id}
            namePrefix={`${namePrefix}.conditions[${index}]`}
            append={append}
            previewFilter={previewFilter}
            itemIndex={index}
            removeFilter={remove}
            fieldsData={fieldsData}
            rowIndex={rowIndex}
            removeRow={removeRow}
            isEditable={selectedScope?.value?.editable || isNewScope}
            selectedFields={controlledFields?.map((el) => el?.field)}
            context={filterTypes.SCOPE}
          />
        ))}
      {(selectedScope?.value?.editable || isNewScope) && isValid && (
        <Button
          wrapperClassName={"filter-rows-add-value-btn"}
          data-testid={"add-value"}
          label={"Add Value"}
          leftIcon={"seem-icon-scope"}
          textLink
          onClick={() => {
            append({});
          }}
        />
      )}
    </div>
  );
};

export default ScopePanel;
