import React, { useMemo, useEffect, useState } from "react";
import logo from "../icons/logos/seemplicity-white.svg";
import screenshotImg from "../images/login-screenshot.png";
import { useHistory, useLocation } from "react-router-dom";
import ChangePassword from "./ChangePassword/ChangePassword";
import LoginPage from "./Login/LoginPage";
import "./Login/LoginPage.scss";
import SignInMFA from "./MFA/SignInMFA";
import { getAuthenticatedUser } from "./AuthApi";

const AuthPage = () => {
  const location = useLocation();
  const [isUserConnected, setIsUserConnected] = useState(true);
  let history = useHistory();

  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        setIsUserConnected(true);
        history.push("/dashboard/organizational");
      })
      .catch(() => {
        setIsUserConnected(false);
      });
  }, []);

  const getForm = useMemo(() => {
    if (!isUserConnected) {
      if (location?.pathname?.includes("changePassword")) {
        return <ChangePassword />;
      } else if (location?.pathname?.includes("mfa")) {
        return <SignInMFA />;
      } else {
        return <LoginPage />;
      }
    }
  }, [location, isUserConnected]);
  return (
    <>
      {!isUserConnected && (
        <div className="app-wrap">
          <div className="login-page-background-image" />
          <div className="login-page-header">
            <img className={"login-logo-container"} src={logo} />
          </div>
          <div className="login-view-body">
            <div className="login-page-content">
              <div className="login-page-content-image">
                <div
                  className="login-screenshot-img"
                  style={{ backgroundImage: `url(${screenshotImg})` }}
                />
              </div>
              <div className="login-page-content-form">
                <div className="login-page-form-container">{getForm}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthPage;
