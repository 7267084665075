import React from "react";
import "./AlertIndicator.scss";

export const alertTypes = {
  WARNING: "warning",
  INFO: "info",
};
const AlertIndicator = ({
  wrapperClassName = "",
  content = "",
  type = alertTypes.INFO,
  additionalContent,
}) => {
  return (
    <div className={`alert-indicator-container ${wrapperClassName} ${type}`}>
      <div className={`alert-indicator-offset ${type}`} />
      <div className={"alert-indicator-wrap"}>
        <div className={`alert-info ${type}`}>
          <i className={`seem-icon seem-icon-${type}`} />
          <div className="alert-indicator-content">{content}</div>
        </div>
        <div className="alert-indicator-additional-content">
          {additionalContent}
        </div>
      </div>
    </div>
  );
};

export default AlertIndicator;
