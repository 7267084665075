import React, { useMemo } from "react";
import FilterField from "./FilterField/FilterField";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FilterCondition from "./FilterCondition/FilterCondition";
import FilterValue from "./FilterValue/FilterValue";
import "./Filter.scss";

const Filter = ({
  filterItem,
  namePrefix,
  previewFilter,
  itemIndex,
  removeFilter,
  fieldsData,
  key,
  selectedFields,
  rowIndex,
  removeRow,
  isEditable,
  setMenuOpen,
  context,
  shouldBlinkField = null,
  updateFilter = () => {},
}) => {
  const { control, setValue, getValues } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: `${namePrefix}.field`,
  });

  const conditionValue = useWatch({
    control,
    name: `${namePrefix}.condition`,
  });

  const shouldBlink = useMemo(() => {
    const blink = shouldBlinkField;
    const isBlinking =
      !!blink && !!filterItem?.field && blink === filterItem.field;
    if (isBlinking) {
      setTimeout(() => {
        updateFilter({ shouldBlinkField: null });
      }, 2000);
    }

    return isBlinking;
  }, [shouldBlinkField, filterItem]);

  return (
    <div
      className={`filter-row-item ${shouldBlink ? "is-blink" : ""}`}
      key={key}
    >
      <Controller
        wrapperClassName={``}
        name={`${namePrefix}.field`}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            <FilterField
              onChange={(selectedValue) => {
                onChange(selectedValue.value);
                const conditions = fieldsData?.filter(
                  (selectedField) => selectedField.field === selectedValue.value
                )?.[0]?.conditions;

                let selectedCondition = conditions?.[0];
                if (conditions?.includes("in")) {
                  selectedCondition = "in";
                }

                setValue(`${namePrefix}.condition`, selectedCondition);
                setValue(`${namePrefix}.value`, null);
              }}
              value={value}
              fieldsData={fieldsData}
              isEditable={isEditable}
              itemIndex={itemIndex}
              removeFilter={removeFilter}
              selectedFields={selectedFields}
              setMenuOpen={setMenuOpen}
              context={context}
            />
          );
        }}
        defaultValue={filterItem?.field}
        rules={{ required: "Required" }}
        control={control}
      />

      <Controller
        wrapperClassName={``}
        name={`${namePrefix}.condition`}
        render={({ field: { onChange, value, onBlur } }) => {
          return !!fieldValue ? (
            <FilterCondition
              onChange={(selectedValue) => {
                setValue(`${namePrefix}.value`, null);
                onChange(selectedValue.value);
              }}
              value={value}
              namePrefix={namePrefix}
              fieldsData={fieldsData}
              isEditable={isEditable}
              setMenuOpen={setMenuOpen}
            />
          ) : null;
        }}
        defaultValue={filterItem?.condition}
        rules={{ required: "Required" }}
        control={control}
      />

      <Controller
        wrapperClassName={``}
        name={`${namePrefix}.value`}
        render={({ field: { onChange, value, onBlur } }) => {
          return (
            !!conditionValue && (
              <FilterValue
                onBlur={onBlur}
                onChange={(selectedValue) => {
                  onChange(selectedValue);
                }}
                value={value}
                namePrefix={namePrefix}
                previewFilter={previewFilter}
                itemIndex={itemIndex}
                removeFilter={removeFilter}
                fieldsData={fieldsData}
                isEditable={isEditable}
                rowIndex={rowIndex}
                removeRow={removeRow}
                setMenuOpen={setMenuOpen}
              />
            )
          );
        }}
        defaultValue={filterItem?.value}
        rules={{
          required: "Required",
          validate: () => {
            return getValues(`${namePrefix}.value`)?.length > 0;
          },
        }}
        control={control}
      />
    </div>
  );
};

export default Filter;
