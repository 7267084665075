import { gql } from "@apollo/client";

export const PREPARE_AGGREGATE_FINDINGS = gql`
  query prepare_aggregate_findings(
    $filters_config: FiltersConfigInput!
    $number_of_resources: Int
  ) {
    prepare_aggregate_findings(
      filters_config: $filters_config
      number_of_resources: $number_of_resources
    ) {
      suggested_title
      suggested_description
      suggested_remediation
      cloud_provider
      cloud_account
      severity
      score
      has_tickets
      resources {
        resource_name
        resource_id
        resource_type
      }
      dominant_finding_id
      number_of_previously_aggregated
      discovered_time
    }
  }
`;

export const GET_ALL_RESOURCES = gql`
  query prepare_aggregate_findings(
    $filters_config: FiltersConfigInput!
    $number_of_resources: Int
  ) {
    prepare_aggregate_findings(
      filters_config: $filters_config
      number_of_resources: $number_of_resources
    ) {
      resources {
        resource_name
        resource_id
        resource_type
      }
    }
  }
`;

export const AGGREGATE_FINDINGS = gql`
  mutation aggregate_findings(
    $filters_config: FiltersConfigInput!
    $incoming_fields: ManualAggregatedFindingInput!
    $close_tickets: Boolean
  ) {
    aggregate_findings(
      filters_config: $filters_config
      incoming_fields: $incoming_fields
      close_tickets: $close_tickets
    ) {
      ok
      msg
      new_finding_id
    }
  }
`;
