import React from "react";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";
import { dataSourceIcons } from "../../../../../FindingsUtils";
import { snakeCase } from "lodash";
import ReactTooltip from "react-tooltip";
import FilterInOut from "../../../../FilterInOut/FilterInOut";

const DatasourceNavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  datasourceIcon,
  dataSourceName,
  groupRow,
}) => {
  return (
    <div
      className={`findings-carousel-item is-row group-by-${groupBy}`}
      onClick={() => onGroupClick(groupRow)}
    >
      <div className={`findings-carousel-item-left`}>
        {!!datasourceIcon && (
          <div className={`findings-carousel-item-bottom-icon`}>
            {" "}
            <img
              data-tip
              data-for={`groupBy${dataSourceName}Tip`}
              alt={`${groupName}`}
              src={dataSourceIcons?.[datasourceIcon]?.S}
            />
            <ReactTooltip id={`groupBy${dataSourceName}Tip`} place="top">
              {dataSourceName}
            </ReactTooltip>
          </div>
        )}
      </div>
      <div className={`navigation-item-separator`} />
      <div className={`findings-carousel-item-right`}>
        <div
          className={`findings-carousel-item-count`}
          data-testid={"findings-count"}
        >
          {groupName && (
            <div
              className={`sevirity-level sevirity-level-${snakeCase(
                groupName
              )}`}
            />
          )}
          {normalizeNumbers(group.total_count)}
        </div>
        <div className={`findings-carousel-item-bottom-name`}>{`Findings`}</div>
      </div>

      <FilterInOut field={"source"} value={dataSourceName} />
    </div>
  );
};

export default DatasourceNavigationItem;
