import React, { useRef } from "react";
import FindingsNavigationGroup from "./FindingsNavigationGroup/FindingsNavigationGroup";
import FindingsList from "./FindingsList/FindingsList";
import { useMenuState } from "../../Components/SelectionMenu/SelectionMenu";
import { findingsQueryParams, groupByOptions } from "../FindingsUtils";
import useFindingsQueryParam from "./useFindingsQueryParam";
import useIntegratedDataSources from "../../AppSettings/Integrations/DataSources/useIntegratedDataSources";

const FindingsData = () => {
  const infiniteScrollRef = useRef(null);
  const { param: groupByIndexFromURL } = useFindingsQueryParam(
    findingsQueryParams.GROUP_INDEX
  );
  const { menuState: groupBy, updateMenuState } = useMenuState({
    selectedOptionValue:
      groupByOptions[groupByIndexFromURL]?.value || groupByOptions[0].value,
    selectedOptionId: groupByIndexFromURL || 0,
  });
  const { integratedDataSources } = useIntegratedDataSources();

  return (
    groupBy && (
      <>
        <FindingsNavigationGroup
          groupBy={groupBy}
          updateMenuState={updateMenuState}
          datasources={integratedDataSources?.datasources?.edges}
        />
        <FindingsList
          forwardedRef={infiniteScrollRef}
          groupBy={groupBy}
          datasources={integratedDataSources?.datasources?.edges}
        />
      </>
    )
  );
};

export default FindingsData;
