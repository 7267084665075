import ResourceDataItem from "../ResourceDataItem/ResourceDataItem";
import React from "react";
import { useTranslation } from "react-i18next";

const ResourcesList = ({ resources }) => {
  const { t } = useTranslation();
  return (
    <div className={`data-table finding-resource-table`}>
      <div className="data-table-row">
        <div className={`data-table-cell data-table-cell-header`}>
          {t("Name")}
        </div>
        <div className={`data-table-cell data-table-cell-header`}>
          {t("ID")}
        </div>
        <div className={`data-table-cell data-table-cell-header`}>
          {t("Type")}
        </div>
      </div>
      {resources?.map((resource) => (
        <ResourceDataItem resource={resource} />
      ))}
    </div>
  );
};

export default ResourcesList;
