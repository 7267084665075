import React from "react";
import Loader from "../../Components/Loader/Loader";
import { useFormContext, useWatch } from "react-hook-form";

const TicketEndpointPlaceholder = ({
  onFinalPhase,
  keysReady,
  getFieldPropsByName = () => {},
}) => {
  const { getValues, control } = useFormContext();

  const selectedTicketProvider = useWatch({ control, name: "ticketProvider" });

  const placeholder = (formValues) => {
    const fieldsToSelect = [];
    if (!!formValues && Object.keys(formValues).length) {
      Object.keys(formValues)
        ?.filter((key) => !formValues[key])
        ?.forEach((key) =>
          fieldsToSelect.push(getFieldPropsByName(key)?.label)
        );

      if (!fieldsToSelect?.length) {
        onFinalPhase(true);
      }
    }

    return fieldsToSelect?.length && !keysReady
      ? `Select ${fieldsToSelect?.join(", ")} to continue`
      : !selectedTicketProvider
      ? "Select Provider to continue"
      : "";
  };

  return (
    <div className={"endpoint-placeholder"}>
      {placeholder(getValues().keys) ? (
        <Loader
          placeholder
          center
          icon={"seem-icon-notebook"}
          text={placeholder(getValues().keys)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TicketEndpointPlaceholder;
