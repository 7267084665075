import React, { useContext, useEffect, useState, useMemo } from "react";
import { groupByTypes } from "../../FindingsUtils";
import { useQuery } from "@apollo/client";
import { GET_FINDINGS_BY_GROUP } from "./FindingsNavigationGroupAPI";
import { FindingsContext } from "../../FindingsContext";
import FindingsGroupsSelection from "./FindingsGroupsSelection/FindingsGroupsSelection";
import FindingsGroupsResults from "./FindingsGroupsResults/FindingsGroupsResults";
import "./FindingsNavigationGroup.scss";
import { FiltersContext } from "../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import ReactTooltip from "react-tooltip";
import { GET_TOTAL_RAW_COUNT } from "../FindingsList/FindingsListApi";

const FindingsNavigationGroup = ({ groupBy, updateMenuState, datasources }) => {
  const { findingsState, updateFindingsState } = useContext(FindingsContext);
  const { filtersState } = useContext(FiltersContext);
  const { selectedFilter, filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  const { groupsList } = findingsState;

  const getGroupBySort = (groupBy) => {
    switch (groupBy?.selectedOptionValue?.groupBy) {
      case groupByTypes.CLOUD_ACCOUNT:
        return ["CLOUD_ACCOUNT_FRIENDLY_NAME_ASC", "CLOUD_PROVIDER_ASC"];

      case groupByTypes.SEVERITY:
        return ["SEVERITY_DESC"];

      case groupByTypes.SLA_STATUS:
        return ["SLA_STATUS_DESC"];

      default:
        return [`${groupBy.selectedOptionValue.groupBy.toUpperCase()}_ASC`];
    }
  };

  const [findingsTotalCount, setFindingsTotalCount] = useState(null);

  const {
    data: groupsListData,
    loading: getFindingsByGroupLoading,
    error: groupsListError,
  } = useQuery(GET_FINDINGS_BY_GROUP, {
    variables: {
      groupBy: (Array.isArray(groupBy?.selectedOptionValue?.dbGroupBy)
        ? groupBy?.selectedOptionValue.dbGroupBy.map((el) => `finding.${el}`)
        : [`finding.${groupBy?.selectedOptionValue?.dbGroupBy}`]) || [
        `finding.${groupByTypes.SEVERITY}`,
      ],
      sort: getGroupBySort(groupBy),
      filters_config: {
        scopesjson: scopeToExecute || null,
        filtersjson: filterToExecute || "",
        scopesid: selectedScope?.value?.id,
      },
    },
    skip:
      (!selectedScope?.value?.id && !scopeToExecute) ||
      (!selectedFilter?.value?.id && !filterToExecute),
  });

  const {
    data: totalRawCount,
    loading: getTotalRawCountLoading,
    error: totalRawCountError,
  } = useQuery(GET_TOTAL_RAW_COUNT, {
    variables: {
      filters_config: {
        scopesjson: scopeToExecute || null,
        filtersjson: filterToExecute || "",
        scopesid: selectedScope?.value?.id,
      },
    },
    skip:
      (!selectedScope?.value?.id && !scopeToExecute) ||
      (!selectedFilter?.value?.id && !filterToExecute),
  });

  useEffect(() => {
    if (!!groupsListData && groupsList === null) {
      setFindingsTotalCount(null);
      if (groupsListData.findings_groups?.length) {
        const count = groupsListData.findings_groups
          .map((group) => group.total_count)
          .reduce((x, y) => x + y);
        setFindingsTotalCount(count);

        let newGroupsListData = {
          findings_groups: groupsListData.findings_groups.map((groupData) => {
            const newGroupData = {
              total_count: groupData.total_count,
              group_by: groupData.group_by.map((el) => {
                return {
                  value: el.value,
                  field: el.field.substring(el.field.indexOf(".") + 1),
                };
              }),
            };
            return newGroupData;
          }),
        };
        updateFindingsState({
          groupsList: newGroupsListData,
          totalCount: count,
        });
      } else {
        setFindingsTotalCount(0);
        updateFindingsState({ groupsList: groupsListData, totalCount: 0 });
      }
    } else if (!!groupsListData && groupsList !== null) {
      const count =
        groupsListData?.findings_groups
          ?.map((group) => group.total_count)
          ?.reduce((x, y) => x + y, 0) || 0;
      setFindingsTotalCount(count);
    }
  }, [groupsListData, groupsList]);

  useEffect(() => {
    if (groupsListError) {
      setFindingsTotalCount(null);
    }
  }, [groupsListError]);

  const isLoadingTitle = useMemo(() => {
    return (
      (findingsTotalCount === null && !groupsListError) ||
      getFindingsByGroupLoading ||
      (getTotalRawCountLoading && !totalRawCountError)
    );
  }, [
    findingsTotalCount,
    groupsListError,
    getFindingsByGroupLoading,
    getTotalRawCountLoading,
    totalRawCountError,
  ]);

  const isLoadingGroups = useMemo(
    () =>
      (groupsList === null && !groupsListError) ||
      selectedScope === null ||
      selectedFilter === null ||
      getFindingsByGroupLoading,
    [
      selectedFilter,
      selectedScope,
      getFindingsByGroupLoading,
      groupsList,
      groupsListError,
    ]
  );

  const totalRawCountTitle = useMemo(() => {
    if (!isLoadingTitle) {
      const titleText = !!totalRawCount?.findings?.total_raw_count
        ? `Derived from ${totalRawCount?.findings?.total_raw_count?.toLocaleString()} Raw Findings`
        : `No results for the selected Scope / Filter`;

      return (
        <>
          <div data-testid={`total-raw-count`}>{titleText}</div>
          {!!totalRawCount?.findings?.total_raw_count && (
            <>
              <i
                className={"seem-icon seem-icon-help aggregated-tip-icon"}
                data-tip
                data-for="aggregatedTip"
              />
              <ReactTooltip
                id="aggregatedTip"
                place="top"
                multiline={true}
                className={"aggregated-tip-icon"}
              >
                The number of findings before deduplication, aggregation and
                correlation
              </ReactTooltip>
            </>
          )}
        </>
      );
    } else {
      return <></>;
    }
  }, [isLoadingTitle, totalRawCount?.findings?.total_raw_count]);

  return (
    <div className={`totals-and-carousel-wrap`}>
      {!isLoadingTitle ? (
        <div className={`side-container total-findings-title-wrap`}>
          <div className={`total-findings-title`}>
            <span className={"total-findings-title-num"}>
              {!!findingsTotalCount
                ? `${findingsTotalCount.toLocaleString()}`
                : "No"}
            </span>
            <span className={"total-findings-title-findings"}> Findings</span>
          </div>
          <div className={"total-findings-subtitle"}>{totalRawCountTitle}</div>
        </div>
      ) : (
        <div
          className={`side-container total-findings-count-title total-findings-count-title-loading`}
        >
          <div className="loading-blocks-wrap">
            <div
              className="loading-block-square"
              style={{ width: "70px", height: "40px", top: "0px", left: "0px" }}
            />
            <div
              className="loading-block-row"
              style={{
                width: "100px",
                height: "24px",
                top: "50px",
                left: "0px",
              }}
            />
          </div>
        </div>
      )}
      <div className={"group-by-separator"} />
      <div className={"carousel-container"}>
        <FindingsGroupsSelection
          groupBy={groupBy}
          updateMenuState={updateMenuState}
        />
        {!isLoadingGroups ? (
          <FindingsGroupsResults groupBy={groupBy} datasources={datasources} />
        ) : (
          <div
            className={`findings-navigation-bar findings-navigation-bar-loading`}
            data-testid={"findings-navigation-bar-loading"}
          >
            <div className="loading-blocks-wrap loading-blocks-wrap-carousel">
              <div className="loading-block-carousel">
                <div className="loading-block-square" />
                <div className="loading-block-row" />
              </div>

              <div className="loading-block-carousel">
                <div className="loading-block-square" />
                <div className="loading-block-row" />
              </div>

              <div className="loading-block-carousel">
                <div className="loading-block-square" />
                <div className="loading-block-row" />
              </div>

              <div className="loading-block-carousel">
                <div className="loading-block-square" />
                <div className="loading-block-row" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={"side-container side-container-placeholder"} />
    </div>
  );
};
export default FindingsNavigationGroup;
