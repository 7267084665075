import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import "./AvgFixTimeBySeverity.scss";
import { useQuery } from "@apollo/client";
import {
  aggregatedStatus,
  getFindingSeverity,
} from "../../../Findings/FindingsUtils";
import ReactTooltip from "react-tooltip";
import Loader from "../../../Components/Loader/Loader";
import { GET_SLA_PREFERENCES } from "../../../AppSettings/SLACustomization/SLACustomizationAPI";
import useSummary from "../../useSummary";

const AvgFixTimeBySeverity = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
}) => {
  const [slaFixTimeData, setSlaFixTimeData] = useState([]);
  const [slaPreferencesData, setSlaPreferencesData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [error, setError] = useState(false);

  const { summaryData: data, summaryDataError } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.age"],
      group_by: ["finding.severity"],
      aggregate_functions: ["AVG"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute || null,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
        filters_fields_to_override: [
          JSON?.stringify({
            field: "actual_status",
            condition: "",
            value: {
              field: "key",
              condition: "in",
              value: aggregatedStatus.CLOSED,
            },
          }),
        ],
      },
    },
    skip: !slaPreferencesData?.length,
  });

  const {
    data: slaPreferences,
    loading,
    error: slaDataError,
  } = useQuery(GET_SLA_PREFERENCES, { notifyOnNetworkStatusChange: true });
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    if (loading) {
      setIsLoadingData(true);
      setError(false);
    }
  }, [loading]);

  useEffect(() => {
    if (!!slaDataError) {
      setError(true);
      setIsLoadingData(false);
    }
  }, [slaDataError]);

  useEffect(() => {
    if (!!slaPreferences) {
      const preferences =
        slaPreferences?.preferences?.customer_preferences?.sla_preferences?.edges?.map(
          (preference) => preference.node
        );

      setSlaPreferencesData(preferences);
    }
  }, [slaPreferences]);

  useEffect(() => {
    if (!!summaryDataError) {
      setError(true);
      setIsLoadingData(false);
    }
  }, [summaryDataError]);

  useEffect(() => {
    if (slaPreferencesData?.length && !!data) {
      if (!!data) {
        const dataArray = data.map((el) => {
          const newEl = [...slaPreferencesData]?.find(
            (dataEl) =>
              dataEl?.preference_name?.toLowerCase() ===
              getFindingSeverity(el[0])
          );
          return { ...newEl, avgFixTime: el[1], severityValue: el[0] };
        });

        dataArray?.sort((a, b) => b.severityValue - a.severityValue);
        setNoData(!data?.length);
        setSlaFixTimeData(dataArray);
        setIsLoadingData(false);
      }
    }
  }, [slaPreferencesData, data]);

  const slaItemRow = (sla) => {
    const severityTitle = sla.preference_name;
    return (
      <div className={`sla-item-row`}>
        <div className={`sla-field-wrap sla-item-wrapper sla-item-left`}>
          <i
            className={`sla-field-indicator sla-field-indicator-${severityTitle?.toLowerCase()}`}
          />
          <span className={`sla-field-text`}>
            {`${capitalize(severityTitle)} (${sla.time_to_respond} days)`}
          </span>
        </div>
        <div
          className={`sla-field-wrap sla-avg-fix-time-wrapper sla-item-right`}
        >
          {!isLoadingData && !isNaN(sla?.avgFixTime) ? (
            <>
              <span
                className={`sla-avg-time-text`}
              >{`${sla.avgFixTime} Days`}</span>
              <ReactTooltip
                id={`slaStatus-${severityTitle}`}
                place="top"
                className={"map-Fields-tooltip"}
              >
                {sla.avgFixTime > sla.time_to_respond ? "Overdue" : "In Time"}
              </ReactTooltip>
              <div className="sla-avg-time-icon">
                <i
                  data-tip
                  data-for={`slaStatus-${severityTitle}`}
                  className={`seem-icon seem-icon-${
                    sla.avgFixTime > sla.time_to_respond ? "overdue" : "in-time"
                  }`}
                />
              </div>
            </>
          ) : (
            <div className="loading-row" style={{ width: "60px" }} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`fix-time-by-severity-wrapper`}>
      {!isLoadingData && noData ? (
        <Loader center text={"No match for the selected filter"} />
      ) : !isLoadingData && error ? (
        <Loader center text={"Error loading data"} />
      ) : (
        <div className={`severities-wrapper`}>
          <div className={`sla-item-row`}>
            <div className={`sla-field-wrap sla-field-title sla-item-left`}>
              Organizational SLA
            </div>
            <div className={`sla-field-wrap sla-field-title sla-item-right`}>
              Avg. Time to Remediation
            </div>
          </div>
          {slaFixTimeData.map((sla) => slaItemRow(sla))}
        </div>
      )}
    </div>
  );
};

export default AvgFixTimeBySeverity;
