import "./FilterManagement.scss";
import FiltersMenu from "./FiltersMenu/FiltersMenu";
import FilterPanel from "./FilterPanel/FilterPanel";
import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ClassNames from "classnames";
import { FiltersContext } from "./FilterProvider/FilterProvider";
const FilterManagement = ({
  setDefaultsForScopeAndFilter,
  hasEditMode = false,
  readOnly = false,
  effectedSingleQueueName = null,
  onFilterSave = () => {},
}) => {
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      saveAsFilterName: "",
      filterPanels: [],
    },
  });

  const {
    filtersState: { selectedFilter },
  } = useContext(FiltersContext);

  const classNameWrapper = ClassNames("filters-management-wrapper", {
    "has-edit-mode": hasEditMode,
    "read-only": readOnly,
  });

  return (
    <FormProvider {...methods}>
      <div className={`${classNameWrapper}`}>
        <FiltersMenu
          setDefaultsForScopeAndFilter={setDefaultsForScopeAndFilter}
          readOnly={readOnly}
        />
        <div className={`filter-separator-wrap`}>
          <div className={`filter-separator`} />
        </div>
        {(setDefaultsForScopeAndFilter ||
          (!setDefaultsForScopeAndFilter &&
            !!selectedFilter?.value?.query)) && (
          <FilterPanel
            hasEditMode={hasEditMode}
            readOnly={readOnly}
            onFilterSave={onFilterSave}
            effectedSingleQueueName={effectedSingleQueueName}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default FilterManagement;
