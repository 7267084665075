export const categoriesOrder = [
  "Cloud Security",
  "Application Security",
  "Vulnerability Management",
  "Security Control Validation",
  "SaaS Security",
  "Other",
];

export const instanceActions = {
  CREATE: "create",
  EDIT: "edit",
};
