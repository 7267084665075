import moment from "moment";

export const trendsFormat = "yyyy-MM-DD";
export const resultsSinceFormat = "MMM DD, yyyy";

export const getSeverityColor = (type) => {
  switch (type) {
    case "low":
      return "#23bb8a";
    case "medium":
      return "#f7bb00";
    case "high":
      return "#ff7507";
    default:
      return "#ec2a3f";
  }
};

export const createValueAxis = (valueAxis, name) => {
  valueAxis.renderer.labels.template.fill = "#9494a2";
  valueAxis.renderer.labels.template.fontSize = 12;
  valueAxis.renderer.labels.template.dy = 15;
  valueAxis.paddingLeft = 10;
  valueAxis.paddingRight = 20;
  valueAxis.layout = "absolute";
  valueAxis.title.text = name;
  valueAxis.title.fontSize = 14;
  valueAxis.title.fontWeight = 500;
  valueAxis.title.rotation = 0;
  valueAxis.title.dy = -30;
  valueAxis.title.align = "center";
  valueAxis.title.valign = "top";
};

export const createDateAxis = (dateAxis, timeScale) => {
  dateAxis.dataFields.category = "date";
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.title.text = "Date";
  dateAxis.title.fontWeight = 500;
  dateAxis.renderer.labels.template.fontSize = 12;
  dateAxis.baseInterval = {
    timeUnit: dateFormatKey[timeScale],
    count: timeScale === "QUARTER" ? 3 : 1,
  };

  dateAxis.dateFormats.setKey(
    dateFormatKey[timeScale],
    timeScaleFormatter[timeScale]
  );
  dateAxis.periodChangeDateFormats.setKey(
    dateFormatKey[timeScale],
    timeScalePeriodChangeFormatter[timeScale]
  );

  dateAxis.tooltipDateFormat = timeScaleFormatter[timeScale];
};

const dateFormatKey = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  QUARTER: "month",
  // YEAR: "year",
};

export const timeScaleFormatter = {
  DAY: "MMM dd, yyyy",
  WEEK: "MMM dd, yyyy",
  MONTH: "MMM",
  QUARTER: "q.yyyy",
  // YEAR: "yyyy",
};

export const timeScalePeriodChangeFormatter = {
  DAY: "MMM dd, yyyy",
  WEEK: "MMM dd, yyyy",
  MONTH: "MMM yyyy",
  QUARTER: "q.yyyy",
  YEAR: "yyyy",
};

export const oneSeriesTrendProperties = {
  newFindingsByDiscoveryDate: {
    title: "New Findings By Discovery Date",
    queryNameVariable: ["new"],
    legend: "New Findings",
  },
  openFindingsByDiscoveryDate: {
    title: "Open Findings By Discovery Date",
    queryNameVariable: ["open"],
    legend: "Open Findings",
  },
};

export const getSinceText = (timeScale) => {
  switch (timeScale) {
    case "DAY": {
      return moment().subtract(12, "days").format(resultsSinceFormat);
    }
    case "WEEK": {
      return moment().subtract(12, "weeks").format(resultsSinceFormat);
    }
    case "MONTH": {
      return moment().subtract(12, "months").format(resultsSinceFormat);
    }
    default: {
      return "";
    }
  }
};

export const getStartDate = (timeScale) => {
  switch (timeScale) {
    case "DAY": {
      return moment().subtract(11, "days").format(trendsFormat);
    }
    case "WEEK": {
      return moment().subtract(12, "weeks").format(trendsFormat);
    }
    case "MONTH": {
      return moment().subtract(12, "months").format(trendsFormat);
    }
    case "QUARTER": {
      return "2000-01-01";
    }
    case "YEAR": {
      return "2000-01-01";
    }
    default: {
      return "2000-01-01";
    }
  }
};
