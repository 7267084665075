import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_COMMENTS,
  DELETE_COMMENT,
  GET_COMMENTS_FROM_TICKET_PROVIDER,
} from "../CommentsApi";
import "./CommentsList.scss";
import moment from "moment";
import TextEditor from "../../../../Components/TextEditor/TextEditor";
import { AuthContext } from "../../../../Auth/AuthContext";
import { DetailsPaneContext } from "../../DetailsPaneProvider";
import Button from "../../../../Forms/Controls/Button/Button";
import Avatar from "../../../../Components/Avatar/Avatar";
import InfiniteLoaderFullScreen from "../../InfiniteLoaderFullScreen/InfiniteLoaderFullScreen";
import ViewMoreItemsButton from "../../ViewMoreItemsButton/ViewMoreItemsButton";
import { dataSourceIcons } from "../../../FindingsUtils";
import {
  ticketManagersEnum,
  ticketStatus,
} from "../../../../Ticketing/TicketingUtils";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { GET_FINDING_COMMENTS_COUNT_FRAGMENT } from "../../DetailsPaneApi";

const COMMENTS_COUNT = 4;
const COMMENTS_COUNT_FS = 8;

const CommentsList = () => {
  const { t } = useTranslation();
  const {
    authState: { username },
  } = useContext(AuthContext);
  const {
    detailsPaneData: { id, isFullScreen, findingTicket },
    updateDetailsPaneData,
  } = useContext(DetailsPaneContext);

  const { data: commentsData, fetchMore: fetchMoreComments } = useQuery(
    GET_COMMENTS,
    {
      skip: !id,
      variables: {
        id: id,
        // first: !isFullScreen ? COMMENTS_COUNT : COMMENTS_COUNT_FS,
        after: "",
      },
      fetchPolicy: "network-only",
    }
  );

  const { data: ticketCommentsData } = useQuery(
    GET_COMMENTS_FROM_TICKET_PROVIDER,
    {
      skip:
        !id ||
        !findingTicket ||
        findingTicket.status.toLowerCase() ===
          ticketStatus.DELETED.text.toLowerCase() ||
        (findingTicket?.ticket_provider?.type !== ticketManagersEnum.JIRA &&
          findingTicket?.ticket_provider?.type !==
            ticketManagersEnum.SERVICENOW),
      variables: {
        ticket_id: findingTicket?.id,
        // first: !isFullScreen ? COMMENTS_COUNT : COMMENTS_COUNT_FS,
        after: "",
      },
    }
  );

  const [comments, setComments] = useState([]);
  const [deleteComment] = useMutation(DELETE_COMMENT, {
    update(cache) {
      cache.writeFragment({
        id: `Finding:${id}`,
        fragment: GET_FINDING_COMMENTS_COUNT_FRAGMENT,
        data: {
          comments: {
            total_count: comments.length,
          },
        },
      });
    },
    refetchQueries: ["get_comments", "get_comments_from_ticket_provider"],
  });

  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [endCursor, setEndCursor] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const loadMoreComments = () => {
    if (comments && comments?.length && hasNextPage) {
      setIsLoadingComments(true);
      return fetchMoreComments({
        variables: {
          first: !isFullScreen ? COMMENTS_COUNT : COMMENTS_COUNT_FS,
          after: endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          const newAgg = {
            edges: [
              ...prev.finding.comments.edges,
              ...fetchMoreResult.finding.comments.edges,
            ],
            pageInfo: fetchMoreResult.finding.comments.pageInfo,
          };
          const comments = Object.assign({}, prev.finding.comments, newAgg);
          const newFinding = Object.assign({}, prev.finding, {
            comments: comments,
          });

          return Object.assign({}, prev, { finding: newFinding });
        },
      })
        .catch((error) => showErrorDialog({ message: error.message }))
        .finally(() => {
          setIsLoadingComments(false);
        });
    }
  };

  useEffect(() => {
    if (!!commentsData) {
      let allComments = [];

      commentsData?.finding?.comments?.edges?.forEach((comment) => {
        allComments.push({
          id: comment?.node?.id,
          body: comment?.node?.body,
          creation_time: comment?.node?.creation_time,
          created_by: comment?.node?.created_by,
          last_modified: comment?.node?.last_modified,
          ticket: false,
        });
      });

      ticketCommentsData?.get_comments_from_ticket_provider?.forEach(
        (comment, index) => {
          let commentDate = new Date(comment.creation_time);
          allComments.push({
            id: "ticket" + index,
            body: comment?.body,
            creation_time: commentDate,
            created_by: comment?.created_by,
            last_modified: commentDate,
            ticket: true,
          });
        }
      );

      allComments.sort(function (a, b) {
        return new Date(b.creation_time) < new Date(a.creation_time) ? -1 : 1;
      });

      updateDetailsPaneData({ allCommentsCount: allComments.length });
      setComments(allComments);
      setEndCursor(commentsData?.finding?.comments?.pageInfo.endCursor);
      setHasNextPage(commentsData?.finding?.comments?.pageInfo.hasNextPage);
      setTotalCount(commentsData?.finding?.comments?.total_count);
    }
  }, [commentsData, ticketCommentsData]);

  const getRow = (comment, style) => {
    const isOwner = username === comment.created_by?.toLowerCase();
    return (
      <div key={comment.id} className={`finding-comment-wrapper`} style={style}>
        <div className={`finding-comment-content-wrapper`}>
          <Avatar name={comment.created_by} />

          <div className="comment-wrap" data-testid={"comment"}>
            <div className={`finding-comment-data`}>
              <div className={`comment-creation-data`}>
                <span className={`comment-username`}>{comment.created_by}</span>
                <span className={`comment-post-time`}>
                  &nbsp;{"Posted "}
                  {moment(comment.creation_time).fromNow()}
                  {comment.creation_time !== comment.last_modified
                    ? `| Edited ${moment(comment.last_modified).fromNow()}`
                    : ""}
                </span>
                {comment?.ticket && (
                  <div className="tooltip">
                    <span className="tooltiptext">
                      {`This comment is synced with ${findingTicket?.ticket_provider?.type}`}
                    </span>
                    <img
                      className={"ticket-logo"}
                      alt={`${findingTicket?.ticket_provider?.type}-logo`}
                      src={
                        dataSourceIcons[
                          findingTicket?.ticket_provider?.type?.toUpperCase()
                        ]?.S
                      }
                    />
                  </div>
                )}
              </div>
              <div className={`comment-time-operations`}>
                {isOwner && !comment.ticket && (
                  <Button
                    type="button"
                    isIcon="seem-icon-bin"
                    isClean
                    title={"Remove Comment"}
                    onClick={() =>
                      deleteComment({
                        variables: { comment_id: comment.id },
                      }).catch((error) =>
                        showErrorDialog({ message: error.message })
                      )
                    }
                  />
                )}
              </div>
            </div>
            <div className={`finding-comment-content`}>
              <TextEditor
                data-testid={"comment-contents"}
                disabled={true}
                size="max"
                value={comment.body}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getRowsData = () => {
    return comments
      .slice(0, COMMENTS_COUNT)
      ?.map((comment) => getRow(comment, {}));
  };

  return (
    <div
      className={`finding-comments-list ${isFullScreen ? "full-screen" : ""}`}
    >
      {isFullScreen ? (
        <div
          style={{
            height: `${
              totalCount >= COMMENTS_COUNT_FS
                ? COMMENTS_COUNT_FS * 120 + 30
                : totalCount * 120 + 30
            }px`,
            minHeight: "200px",
          }}
        >
          <InfiniteLoaderFullScreen
            hasNextPage={hasNextPage}
            data={comments}
            isLoadingData={isLoadingComments}
            loadMore={loadMoreComments}
            getRow={getRow}
            itemSize={120}
          />
        </div>
      ) : (
        <>{getRowsData()}</>
      )}
      <ViewMoreItemsButton
        total={totalCount}
        count={COMMENTS_COUNT}
        isFullScreen={isFullScreen}
        btnText={t("View more comments")}
      />
    </div>
  );
};

export default CommentsList;
