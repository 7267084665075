import React from "react";
import { snakeCase } from "lodash";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";
import FilterInOut from "../../../../FilterInOut/FilterInOut";

const CategoryNavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  groupRow,
}) => {
  return (
    <div
      className={`findings-carousel-item group-by-${groupBy}`}
      onClick={() => onGroupClick(groupRow)}
    >
      <div className={`findings-carousel-item-top`}>
        {groupName && (
          <div
            className={`sevirity-level sevirity-level-${snakeCase(groupName)}`}
          />
        )}
        {normalizeNumbers(group.total_count)}
      </div>

      <div className={`findings-carousel-item-bottom`}>
        <div className={`findings-carousel-item-bottom-name`}>
          {`${groupName}`}
        </div>
      </div>

      <FilterInOut field={"category"} value={groupName} />
    </div>
  );
};

export default CategoryNavigationItem;
