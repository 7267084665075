import { gql } from "@apollo/client";

export const GET_PROVIDERS = gql`
  query {
    ticket_providers {
      edges {
        node {
          id
          type
          known_identifier
          is_installed
        }
      }
    }
  }
`;

export const generateGetProvidersMock = ({ result = null, error = null }) => {
  let getFindingsByGroupMock = {
    request: {
      query: GET_PROVIDERS,
    },
  };

  if (error) {
    return {
      ...getFindingsByGroupMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getFindingsByGroupMock,
      result: { data: result },
    };
  }

  return {
    ...getFindingsByGroupMock,
    result: {},
  };
};
