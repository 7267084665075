import React from "react";
import SelectInput from "../SelectInput/SelectInput";
import "./TimePicker.scss";
import { formatTime } from "../../../Components/Schedule/Schedule";

export const initialTime = {
  hour: { label: "00", value: 0 },
  minutes: { label: "00", value: 0 },
};

const TimePicker = ({ onChange, value }) => {
  const getOptions = (interval, maxValue) => {
    const options = [];
    for (let i = 0; i <= maxValue; i += interval) {
      options.push({ label: formatTime(i), value: i });
    }

    return options;
  };

  const handleChangeHour = (hour) => {
    onChange({ hour: hour, minutes: value?.minutes || initialTime.minutes });
  };

  const handleChangeMinutes = (minutes) => {
    onChange({ hour: value?.hour || initialTime.hour, minutes: minutes });
  };

  return (
    <div className={`time-picker-wrap`}>
      <SelectInput
        data-testid={"hour"}
        name={`time-picker-hours`}
        value={value?.hour}
        options={getOptions(1, 23)}
        onChange={handleChangeHour}
        SelectInputStyle={"bomba-style"}
        size={"custom"}
        className={"time-picker-hour"}
        placeholder={"00"}
      />
      <div className="time-picker-separator">:</div>
      <SelectInput
        data-testid={"minute"}
        name={`time-picker-minutes`}
        value={value?.minutes}
        options={getOptions(5, 55)}
        onChange={handleChangeMinutes}
        SelectInputStyle={"bomba-style"}
        size={"custom"}
        className={"time-picker-minutes"}
        placeholder={"00"}
      />
    </div>
  );
};

TimePicker.defaultProps = {
  onChange: () => {},
};

export default TimePicker;
