import React, { useContext } from "react";
import { AuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import Button from "../../Forms/Controls/Button/Button";
import { confirmSignIn } from "../AuthApi";

const SignInMFA = () => {
  const {
    authState: { user, challengeName },
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    reset,
    isSubmitting,
  } = useForm();
  const history = useHistory();

  const onSubmit = (data) => {
    confirmSignIn(user, data.totpToken, challengeName)
      .then(() => {
        reset();
        history.push("/findings");
      })
      .catch((err) => {
        console.log(err);
        setError("totpToken", {
          type: "error",
          message: t("An error occurred. Sign in confirmation failed."),
        });
      });
  };

  return (
    <>
      <h2 className="login-page-title">{t("Confirm Sign in")}</h2>
      <form className={`login-page-form`} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          wrapperClassName={`login-username`}
          name={"totpToken"}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <TextInput
                placeholder={"Enter 6 digit code"}
                type={"text"}
                size={"max"}
                isLarge
                error={errors && errors.totpToken ? errors.totpToken : null}
                value={value}
                onChange={(val) => onChange(val)}
                onBlur={onBlur}
                disabled={isSubmitting}
              />
            );
          }}
          control={control}
          rules={{ required: "Required" }}
        />

        <Button
          type="submit"
          label={"Confirm"}
          rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
          isSecondary
          disabled={isSubmitting}
          className="login-btn"
          isLarge
        />
      </form>
    </>
  );
};

export default SignInMFA;
