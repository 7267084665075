import React, { useState, useMemo } from "react";
import Popover from "react-popover";
import "./SLAPopover.scss";
import moment from "moment";

const SLAPopover = ({ age, remaining, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getRemainingDays = useMemo(() => {
    const isPositive = remaining > 0;
    const isToday = remaining === 0;
    const dueDate = moment()?.add(remaining, "days");
    return (
      <div
        className={`sla-popover-row-value ${!isPositive ? "sla-overdue" : ""}`}
        data-testid={"sla-popover-dueDate-value"}
      >
        {!isToday ? moment(dueDate)?.fromNow() : "Today"}
        {` (${moment(dueDate)?.format("DD/MM/YY")})`}
      </div>
    );
  }, [remaining]);

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      onOuterAction={() => setIsOpen(false)}
      body={[
        <div
          key={id}
          className={`sla-popover-container`}
          data-testid={`sla-popover-container`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={"sla-popover-row"}
            data-testid={"sla-popover-dueDate-row"}
          >
            <div className={"sla-popover-row-param"}>Due Date</div>
            {remaining !== null && !isNaN(remaining) ? getRemainingDays : "N/A"}
          </div>

          <div className={"sla-popover-row"}>
            <div className={"sla-popover-row-param"}>Finding's Age</div>
            <div className={"sla-popover-row-value"}>
              {age !== null && !isNaN(age) ? `${age} Days` : "N/A"}
            </div>
          </div>
          <div className={"sla-popover-row"}>
            <div className={"sla-popover-row-param"}>Expected SLA</div>
            <div
              className={"sla-popover-row-value"}
              data-testid={"sla-popover-expected-sla"}
            >
              {age !== null &&
              !isNaN(age) &&
              remaining !== null &&
              !isNaN(remaining)
                ? `${age + remaining} Days`
                : "N/A"}
            </div>
          </div>
        </div>,
      ]}
    >
      <i
        className={"seem-icon seem-icon-help sla-popover-icon"}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        data-testid={"sla-popover-icon"}
      />
    </Popover>
  );
};

export default SLAPopover;
