import { gql } from "@apollo/client";

export const GET_DATA_SOURCE_DEFINITIONS = gql`
  query GetDataSourceDefinitions {
    datasource_definitions {
      edges {
        node {
          id
          file_prefix
          name
          instructions
          status
          category
          required_fields
          secret_configuration
          sections {
            name
            rows {
              fields {
                display_name
                field_name
                default_string_value
                field_input_type
                field_input_size
                required
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_DATASOURCE_DEFINITION = gql`
  mutation UpdateDatasourceDefinition(
    $id: String!
    $instructions: String
    $status: DatasourceDefinitionStatusEnum
  ) {
    update_datasource_definition(
      id: $id
      instructions: $instructions
      status: $status
    ) {
      ok
    }
  }
`;

export const GET_DATA_SOURCES = gql`
  query GetDataSources($with_last_received: Boolean!) {
    datasources {
      edges {
        node {
          id
          friendly_name
          last_received_finding @include(if: $with_last_received)
          required_fields_value
          secret_configuration_value
          enabled
          name
          datasource_definition {
            id
            file_prefix
            name
          }
        }
      }
    }
  }
`;

export const generateGetDataSources = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let getDataSourcesMock = {
    request: {
      query: GET_DATA_SOURCES,
      variables,
    },
  };

  if (error) {
    return {
      ...getDataSourcesMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getDataSourcesMock,
      result: { data: result },
    };
  }

  return {
    ...getDataSourcesMock,
    result: {},
  };
};

export const ADD_DATA_SOURCE = gql`
  mutation AddDatasource(
    $friendly_name: String!
    $datasource_definitions_id: String!
    $incoming_configuration: JSONString!
  ) {
    add_datasource(
      friendly_name: $friendly_name
      datasource_definitions_id: $datasource_definitions_id
      incoming_configuration: $incoming_configuration
    ) {
      ok
      msg
    }
  }
`;

export const UPDATE_DATA_SOURCE = gql`
  mutation UpdateDatasource(
    $id: String!
    $enabled: Boolean
    $friendly_name: String
    $incoming_configuration: JSONString
  ) {
    update_datasource(
      id: $id
      enabled: $enabled
      friendly_name: $friendly_name
      incoming_configuration: $incoming_configuration
    ) {
      ok
      msg
    }
  }
`;
