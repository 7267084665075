import ActionsMenu from "../../../../Components/ActionsMenu/ActionsMenu";
import Button from "../../../../Forms/Controls/Button/Button";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ScopeManagementMenuItem from "../../../FindingsFilters/ScopeHierarchy/ScopeManagement/ScopeManagementMenuItem/ScopeManagementMenuItem";
import ChangeStatus from "../../../FindingDetailsPane/Statuses/ChangeStatus/ChangeStatus";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import { FiltersContext } from "../../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { getMultiSelectFilter } from "../FindingsMultiSelect/FindingsMultiSelectFilters";
import Popover from "react-popover";
import "./FindingsActions.scss";
import { useApolloClient } from "@apollo/client";
import { GeneralAppContext } from "../../../../General/GeneralAppProvider";
import ReactTooltip from "react-tooltip";
import ManualAggregateFindings from "../../../ManualAggregateFindings/ManualAggregateFindings";
import { FindingsContext } from "../../../FindingsContext";

const FindingsActions = ({ itemsCount, checkedNodes, totalCount }) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { scopeToExecute, selectedScope } = scopesState;

  const { updateFindingsState } = useContext(FindingsContext);

  const client = useApolloClient();
  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const { closeModal } = useContext(ModalContext);

  const [isOpen, setIsOpen] = useState(true);
  const [initialIsOpen, setInitialIsOpen] = useState(false);

  const getMenuItems = useMemo(() => {
    const menuItems = [];
    const isAggregationDisabled =
      itemsCount - checkedNodes?.numberOfPreviouslyAggregated < 2;
    menuItems.push(
      {
        text: `Change Status (${itemsCount})`,
        onClick: () => {
          openModal({
            title: "Change Status",
            size: "s",
            overlay: true,
            className: "modal-change-status",
            component: (
              <ChangeStatus
                filters_config={getMultiSelectFilter(
                  checkedNodes,
                  scopeToExecute,
                  filterToExecute,
                  selectedScope?.value?.id
                )}
                newStatus={"OPEN"}
                findingsCount={totalCount}
                onStatusChanged={() => {
                  client
                    .refetchQueries({
                      include: ["GetFindings", "GetFindingsByGroup"],
                    })
                    .then(() => {
                      resetFindingsList && resetFindingsList();
                      closeModal();
                    });
                }}
                state={checkedNodes?.type}
              />
            ),
          });
        },
        component: (
          <ScopeManagementMenuItem
            data-testid={"change-status-menu-item"}
            title={`Change Status (${itemsCount})`}
            subTitle={"Change findings status to Ignored, Fixed or Open"}
            icon={`seem-icon-change-status`}
          />
        ),
      },
      {
        text: `Aggregate Findings (${itemsCount})`,
        disabled: isAggregationDisabled,
        onClick: () => {
          if (!isAggregationDisabled) {
            openModal({
              title: "Aggregate Findings",
              size: "m",
              overlay: true,
              className: "modal-aggregate-findings",
              component: (
                <ManualAggregateFindings
                  updateFindingsState={updateFindingsState}
                  filters_config={getMultiSelectFilter(
                    checkedNodes,
                    scopeToExecute,
                    filterToExecute,
                    selectedScope?.value?.id
                  )}
                  totalCount={totalCount}
                />
              ),
            });
          }
        },
        tooltipData: isAggregationDisabled
          ? {
              dataFor: `aggregate-findings-menu-item`,
              dataTip: true,
            }
          : {},
        component: (
          <>
            <ReactTooltip
              id={`aggregate-findings-menu-item`}
              place="top"
              className={"aggregate-findings-tooltip"}
            >
              You must select at least two findings, any aggregated findings
              cannot be aggregated again.
            </ReactTooltip>
            <ScopeManagementMenuItem
              data-testid={"aggregate-findings-menu-item"}
              title={`Aggregate Findings (${itemsCount})`}
              subTitle={
                "Group several findings into a single aggregated finding"
              }
              icon={`seem-icon-aggregated`}
              disabled={isAggregationDisabled}
            />
          </>
        ),
      }
    );

    return menuItems;
  }, [checkedNodes, filterToExecute, itemsCount, scopeToExecute]);

  const firstTimeAction = useMemo(() => {
    const firstTimeActionLs =
      localStorage.getItem("findings_actions") === null ||
      localStorage.getItem("findings_actions") === "true";

    setIsOpen(!!itemsCount && firstTimeActionLs);
    return firstTimeActionLs;
  }, [itemsCount]);

  return firstTimeAction && isOpen ? (
    <Popover
      isOpen={isOpen}
      tipSize={8}
      place={"above"}
      className={"findings-actions"}
      body={[
        <div className={`findings-actions-notification`}>
          <div className={`new-actions-title-wrap`}>
            <i className={`seem-icon seem-icon-new-actions`} />
            <span className="new-actions-title">New Actions</span>
          </div>
          <div className={`new-actions-description`}>
            You can create tickets or change status for the findings you
            selected.
          </div>
        </div>,
      ]}
    >
      <Button
        disabled={!itemsCount}
        label={t(`Actions${!!itemsCount ? ` (${itemsCount})` : ""}`)}
        wrapperClassName={`findings-actions-button ${
          isOpen ? "has-outline" : ""
        }`}
        data-testid={"findings-actions"}
        onClick={() => {
          localStorage.setItem("findings_actions", false.toString());
          setIsOpen(false);
          setInitialIsOpen(true);
        }}
      />
    </Popover>
  ) : (
    <ActionsMenu
      tipSize={0.01}
      menuItems={getMenuItems}
      className={`scope-management-action-menu`}
      disabled={!itemsCount}
      initialIsOpen={initialIsOpen}
    >
      {!itemsCount && (
        <ReactTooltip
          id="actions-btn-tooltip"
          place="top"
          className={"copy-tooltip"}
        >
          Select findings to apply actions
        </ReactTooltip>
      )}
      <Button
        tooltipData={
          !itemsCount
            ? {
                dataFor: `actions-btn-tooltip`,
                dataTip: true,
              }
            : {}
        }
        disabled={!itemsCount}
        label={t(`Actions${!!itemsCount ? ` (${itemsCount})` : ""}`)}
        wrapperClassName={`findings-actions-button`}
        data-testid={"findings-actions"}
      />
    </ActionsMenu>
  );
};

export default FindingsActions;
