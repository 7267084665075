import React from "react";

const Thead = ({ headerGroups }) => {
  return (
    <thead className={`thead`}>
      {headerGroups.map((headerGroup) => {
        return (
          <tr
            className={`tr ${
              headerGroup.headers.every((column) => column.disableFilters)
                ? "--no-filters"
                : ""
            }`}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={`th ${column.canSort ? "is-sortable" : ""} ${
                  column.isSorted ? "is-sorted" : ""
                }`}
                data-testid={`${column.id
                  .replaceAll(" ", "_")
                  .toLowerCase()}-header`}
              >
                <div
                  className={`column-header-title`}
                  {...column.getSortByToggleProps()}
                >
                  <div className={"column-header-title-text"}>
                    {column.render("Header")}
                  </div>
                  {column.canSort ? (
                    <div
                      className={`sort-direction-indicator`}
                      data-testid={`sort-by-${column.id
                        .replaceAll(" ", "_")
                        .toLowerCase()}`}
                    >
                      <i
                        className={`seem-icon seem-icon-${
                          column.isSortedDesc ? "down-arrow" : "up-arrow"
                        }`}
                      />
                    </div>
                  ) : null}
                </div>
                {column.disableFilters ? null : (
                  <div className={`th-filter-wrapper`}>
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                )}
              </th>
            ))}
          </tr>
        );
      })}
    </thead>
  );
};

export default Thead;
