import Popover from "react-popover";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiltersContext } from "../FilterProvider/FilterProvider";
import "./FiltersMenu.scss";
import FiltersMenuPopup from "./FiltersMenuPopup/FiltersMenuPopup";
import { useQuery } from "@apollo/client";
import { GET_FINDING_FILTERS } from "../../../Findings/FindingsFilters/FindingsFilterApi";
import {
  filterTypes,
  findingsQueryParams,
} from "../../../Findings/FindingsUtils";
import {
  DEFAULT_FILTER,
  DEFAULT_FILTER_NAME,
  LS_FILTER,
  SYSTEM,
} from "../../../Findings/FindingsFilters/FilterUtils";
import useFindingsQueryParam from "../../../Findings/FindingsData/useFindingsQueryParam";
import { useFormContext } from "react-hook-form";
import { useRouteMatch } from "react-router";
import { GeneralAppContext } from "../../../General/GeneralAppProvider";
import { filterTabs } from "../../AppViewUtils";

export const EMPTY_FILTER = "EMPTY";
const FiltersMenu = ({ setDefaultsForScopeAndFilter, readOnly }) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const {
    filtersState: { selectedFilter, filtersList, defaultFilter },
    updateFiltersState,
    getFilterValueById,
  } = useContext(FiltersContext);
  let match = useRouteMatch("/:item");

  const {
    formState: { isDirty },
  } = useFormContext();

  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const { search } = useFindingsQueryParam(null);
  const { param: selectedFilterIdFromURL } = useFindingsQueryParam(
    findingsQueryParams.FILTER_ID
  );
  const { param: filterToExecuteFromURL } = useFindingsQueryParam(
    findingsQueryParams.FILTER_TO_EXECUTE
  );

  const { data: filtersData, loading: isLoadingFilters } = useQuery(
    GET_FINDING_FILTERS,
    {
      fetchPolicy: "network-only",
    }
  );

  const setFiltersFromURL = () => {
    const filterExists = getFilterValueById(selectedFilterIdFromURL);
    const selectedFilter = filterExists ? filterExists : defaultFilter;

    updateFiltersState({
      selectedFilter: selectedFilter,
      filterToExecute: selectedFilter.value.query,
    });
  };

  const setFiltersFromLocalStorage = () => {
    const lsFilter = JSON.parse(localStorage.getItem(LS_FILTER));
    const filterExists = getFilterValueById(lsFilter?.value?.id);
    const selectedFilter = filterExists ? lsFilter : defaultFilter;

    updateFiltersState({
      selectedFilter: selectedFilter,
      filterToExecute: selectedFilter.value.query,
    });
  };

  useEffect(() => {
    if (filtersList.length) {
      if (!!selectedFilterIdFromURL) {
        setFiltersFromURL();
        if (!(selectedFilterIdFromURL === defaultFilter?.value?.id)) {
          if (resetFindingsList) {
            resetFindingsList();
          }
        }
      } else if (
        !search &&
        setDefaultsForScopeAndFilter &&
        localStorage.getItem(LS_FILTER)
      ) {
        setFiltersFromLocalStorage();
        if (resetFindingsList) {
          resetFindingsList();
        }
      } else {
        if (setDefaultsForScopeAndFilter) {
          let filter = {};

          if (!selectedFilter && !!defaultFilter && !filterToExecuteFromURL) {
            filter = {
              selectedFilter: defaultFilter,
              filterToExecute: defaultFilter?.value?.query,
            };
          }

          updateFiltersState(filter);
        } else if (!setDefaultsForScopeAndFilter && !selectedFilter) {
          updateFiltersState({
            selectedFilter: {
              label: EMPTY_FILTER,
            },
          });
        }
      }
    }
  }, [
    selectedFilterIdFromURL,
    filtersList,
    defaultFilter,
    setDefaultsForScopeAndFilter,
    resetFindingsList,
  ]);

  useEffect(() => {
    if (!!filterToExecuteFromURL && filtersList.length) {
      const filterObj = {};
      filterObj.filterToExecute = filterToExecuteFromURL;
      filterObj.selectedFilter = {
        label: "Filter By",
        value: {
          query: filterToExecuteFromURL,
          editable: true,
        },
      };
      updateFiltersState(filterObj);
      if (resetFindingsList) {
        resetFindingsList();
      }
    }
  }, [filterToExecuteFromURL, filtersList]);

  const getFilters = useCallback(() => {
    let findingsFilterData = [];
    if (
      !!filtersData &&
      !!filtersData.finding_filters &&
      !!filtersData.finding_filters.edges
    ) {
      let FILTER_ALL_OPEN = null;
      filtersData.finding_filters.edges.forEach((item) => {
        let optionValue = {
          label: item.node.name,
          value: {
            query: item.node.filter_data,
            id: item.node.id,
            editable: item.node.editable,
          },
          meta: {
            system: item.node.system,
            popular: item.node.popular,
            default: item.node.default,
          },
        };
        if (item.node.filter_type === filterTypes.FILTER) {
          if (
            item.node.name === DEFAULT_FILTER &&
            item.node.created_by === SYSTEM
          ) {
            optionValue.label = DEFAULT_FILTER_NAME;
            FILTER_ALL_OPEN = optionValue;
            return;
          }
          findingsFilterData.push(optionValue);
        }
      });
      findingsFilterData = [FILTER_ALL_OPEN].concat(findingsFilterData);

      const hasSavedFilters = findingsFilterData?.find(
        (filter) => !filter?.meta?.system
      );

      return {
        filtersList: findingsFilterData,
        defaultFilter: FILTER_ALL_OPEN,
        filtersMenuTab: !!hasSavedFilters
          ? filterTabs.SAVED
          : filterTabs.RECOMMENDED,
      };
    }
  }, [filtersData]);

  useEffect(() => {
    const filters = getFilters();

    updateFiltersState(filters);
  }, [filtersData, getFilters]);

  const popoverTitle = useMemo(() => {
    return selectedFilter?.label === EMPTY_FILTER
      ? "Select Filter"
      : !!selectedFilter?.label
      ? `${isDirty ? "* " : ""}${selectedFilter?.label}`
      : "Filter By";
  }, [selectedFilter, isDirty]);

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[
        <FiltersMenuPopup
          toggleIsOpen={toggleIsOpen}
          setDefaultsForScopeAndFilter={setDefaultsForScopeAndFilter}
        />,
      ]}
      enterExitTransitionDurationMs={0}
      onOuterAction={() => setTimeout(() => toggleIsOpen(false), 200)}
      className={`filter-popover-class-${match?.params?.item}`}
    >
      <div
        data-testid={`filters-menu`}
        className={`filters-menu-header ${
          isLoadingFilters ? "is-loading" : ""
        }`}
        onClick={(e) => {
          if (!readOnly) {
            toggleIsOpen(!isOpen);
          }
        }}
      >
        <label
          data-testid={`selected-filter-title`}
          className={`selected-filter-title ${
            selectedFilter?.label === EMPTY_FILTER ? "is-empty" : ""
          }`}
          title={popoverTitle}
        >
          {isLoadingFilters ? (
            <div className="dropdown-loading-indicator">
              <div className="loading-dot" />
              <div className="loading-dot" />
              <div className="loading-dot" />
            </div>
          ) : (
            popoverTitle
          )}
        </label>
        {!readOnly && (
          <div className={`dropdown-indicator`}>
            <i className={`seem-icon seem-icon-chevron-down`} />
          </div>
        )}
      </div>
    </Popover>
  );
};

export default FiltersMenu;
