import React, { useContext, useMemo } from "react";
import Button from "../../Forms/Controls/Button/Button";
import ManualFinding from "./ManualFinding/ManualFinding";
import { useTranslation } from "react-i18next";
import { manualFindingModes } from "../FindingsUtils";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import ActionsMenu from "../../Components/ActionsMenu/ActionsMenu";
import UploadBulkFindings from "./UploadBulkFindings/UploadBulkFindings";
import ScopeManagementMenuItem from "../FindingsFilters/ScopeHierarchy/ScopeManagement/ScopeManagementMenuItem/ScopeManagementMenuItem";

const ManualFindings = () => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);

  const getMenuItems = useMemo(() => {
    const menuItems = [];
    menuItems.push({
      text: "Manual Finding",
      onClick: () => {
        openModal({
          title: "Create Finding",
          icon: "seem-icon-new-ticket",
          size: "m",
          overlay: true,
          component: <ManualFinding mode={manualFindingModes.CREATE} />,
        });
      },
      component: (
        <ScopeManagementMenuItem
          data-testid={"manual-finding"}
          title={`Manual Finding`}
          subTitle={
            "Add pentesting findings or findings that came from other data sources"
          }
          icon={`seem-icon-manualfinding`}
        />
      ),
    });

    menuItems.push({
      text: "Bulk Upload",
      onClick: () => {
        openModal({
          title: "Import findings from CSV or XLSX",
          icon: "seem-icon-new-ticket",
          className: "upload-findings-fro-csv-modal",
          size: "s",
          overlay: true,
          component: <UploadBulkFindings />,
        });
      },
      component: (
        <ScopeManagementMenuItem
          data-testid={"findings-bulk-upload"}
          title={`Bulk Upload`}
          subTitle={"Upload CSV or XLSX files with your findings inside"}
          icon={`seem-icon-bulk-upload`}
        />
      ),
    });

    return menuItems;
  }, []);

  return (
    <div className={`manual-findings-action-menu`}>
      <ActionsMenu
        menuItems={getMenuItems}
        className={`scope-management-action-menu`}
      >
        <Button
          isSecondary
          label={t("+ New Finding")}
          data-testid={"new-finding"}
        />
      </ActionsMenu>
    </div>
  );
};

export default ManualFindings;
