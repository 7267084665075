import React from "react";
import "./DashboardWidget.scss";

const DashboardWidget = ({ children, title, size, className }) => {
  return (
    <div
      className={`dashboard-widget-wrapper widget-size-${size} ${
        className ? className : ""
      }`}
    >
      <div className={`widget-header`}>
        <div className={`widget-title`} data-testid={"widget-title"}>
          {title}
        </div>
      </div>
      <div className={`widget-body`}>{children}</div>
    </div>
  );
};

DashboardWidget.defaultProps = {
  title: "",
  size: "s",
};

export default DashboardWidget;
