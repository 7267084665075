import React, { useCallback, useMemo } from "react";
import { getMarkerEnd, getSmoothStepPath } from "react-flow-renderer";

import TicketStatus from "../../../Findings/FindingsData/FindingsList/FindingsListItem/TicketStatus/TicketStatus";
import "./RMQEdge.scss";
import Badge from "../../../Components/Badge/Badge";
import ActionsMenu from "../../../Components/ActionsMenu/ActionsMenu";
import queryString from "query-string";
import { aggregatedStatus } from "../../../Findings/FindingsUtils";
import { useHistory } from "react-router-dom";
import { edgeSourceTypes, sourceTypes } from "../Workflows";

const foreignObjectSize = 40;
const addQueueObjectSize = 50;

const RMQEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 20,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  let history = useHistory();

  const sourceDirection = sourceX > targetX ? -(282 + 50) : 25;

  const getX = useMemo(() => {
    if (data?.type === sourceTypes.ADD) {
      return sourceX;
    } else {
      return sourceX + sourceDirection;
    }
  }, [data?.type, sourceDirection, sourceX]);

  const getY = useMemo(() => {
    if (data?.type === sourceTypes.ADD) {
      return sourceY;
    } else {
      return sourceY - foreignObjectSize / 2;
    }
  }, [data?.type, sourceY]);

  const getStatusFilter = (filter, status) => {
    const statusFilter = {
      field: "actual_status",
      condition: "",
      value: {
        field: "key",
        condition: "in",
        value: aggregatedStatus[status],
      },
    };

    if (!!filter) {
      return {
        operator: "and",
        operands: [statusFilter, filter],
      };
    } else {
      return {
        operator: "and",
        operands: [statusFilter],
      };
    }
  };

  const processFilter = (filter) => {
    if (
      filter?.operator === "and" &&
      filter?.operands?.[0]?.field === "actual_status"
    ) {
      return filter?.operands?.[1] ? filter?.operands?.[1] : null;
    }

    return filter;
  };

  const createQueue = useCallback(
    (status) => {
      const filterNew = JSON.parse(data?.finding_filter?.filter_data);
      let filterObj = !!data?.finding_filter?.filter_data
        ? processFilter(filterNew)
        : [{}];

      const filterWithStatus = JSON.stringify(
        getStatusFilter(filterObj, status)
      );

      const queryStringObj = {
        name: `${data?.filter} and ${edgeSourceTypes[status]?.text}`,
        filter_data: filterWithStatus,
        scope_id: data?.scope_id,
      };

      const queryStringURL = queryString.stringifyUrl({
        url: "/remediationQueue",
        query: queryStringObj,
      });

      history.push(queryStringURL);
    },
    [data?.filter, data?.finding_filter?.filter_data, data?.scope_id, history]
  );

  const menuItems = useMemo(() => {
    const items = [];

    if (!data?.transitionTypes?.includes(edgeSourceTypes.EXCEPTION?.value)) {
      items.push({
        component: (
          <Badge
            text={`${edgeSourceTypes.EXCEPTION?.text} Findings`}
            icon={edgeSourceTypes.EXCEPTION?.icon}
            withLabel={true}
            className={`${sourceTypes.EXCEPTION.toLowerCase()}`}
          />
        ),
        onClick: () => {
          createQueue(edgeSourceTypes.EXCEPTION?.value);
        },
      });
    }

    if (
      !data?.transitionTypes?.includes(edgeSourceTypes.FALSE_POSITIVE?.value)
    ) {
      items.push({
        component: (
          <Badge
            text={`${edgeSourceTypes.FALSE_POSITIVE?.text} Findings`}
            icon={edgeSourceTypes.FALSE_POSITIVE?.icon}
            withLabel={true}
            className={`${sourceTypes.FALSE_POSITIVE.toLowerCase()}`}
          />
        ),
        onClick: () => {
          createQueue(edgeSourceTypes.FALSE_POSITIVE?.value);
        },
      });
    }

    if (
      !data?.transitionTypes?.includes(edgeSourceTypes.OPEN_CONFIRMED?.value)
    ) {
      items.push({
        component: (
          <Badge
            text={`${edgeSourceTypes.OPEN_CONFIRMED?.text} Findings`}
            icon={edgeSourceTypes.OPEN_CONFIRMED?.icon}
            withLabel={true}
            className={`${sourceTypes.OPEN_CONFIRMED.toLowerCase()}`}
          />
        ),
        onClick: () => {
          createQueue(edgeSourceTypes.OPEN_CONFIRMED?.value);
        },
      });
    }

    return items;
  }, [createQueue, data?.transitionTypes]);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={
          data?.type !== sourceTypes.ADD
            ? foreignObjectSize
            : addQueueObjectSize
        }
        height={
          data?.type !== sourceTypes.ADD
            ? foreignObjectSize
            : addQueueObjectSize
        }
        x={getX}
        y={getY}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <body>
          <div className={`done-handle handle-container`}>
            <div className={`handle-point ${data?.type?.toLowerCase()}`}>
              <div className={`inside-dot`} />
            </div>
            {data?.endpoint_type === "TICKET" ? (
              <TicketStatus
                status={data?.type}
                withLabel={false}
                className={`with-shadow`}
              />
            ) : data?.type === sourceTypes.ADD ? (
              <div className={`add-queue-to-flow`}>
                <div className={`add-queue-connector`} />
                <ActionsMenu
                  title={"Create Queue for..."}
                  className={"new-queue-when-menu"}
                  cleanListBg
                  menuItems={menuItems}
                >
                  <div className={`add-queue-icon-container`}>
                    <i className={`seem-icon seem-icon-plus`} />
                  </div>
                </ActionsMenu>
              </div>
            ) : (
              <Badge
                text={edgeSourceTypes[data?.type]?.text}
                icon={edgeSourceTypes[data?.type]?.icon}
                withLabel={false}
                className={`${data?.type?.toLowerCase()} with-shadow`}
              />
            )}
          </div>
        </body>
      </foreignObject>
    </>
  );
};

export default RMQEdge;
