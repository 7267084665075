import { gql } from "@apollo/client";

export const GET_COMMENTS = gql`
  query get_comments($id: ID!, $first: Int, $after: String) {
    finding(id: $id) {
      id
      comments(first: $first, after: $after) {
        total_count
        edges {
          node {
            id
            body
            created_by
            creation_time
            last_modified
          }
        }
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`;

export const GET_COMMENTS_FROM_TICKET_PROVIDER = gql`
  query get_comments_from_ticket_provider($ticket_id: String!) {
    get_comments_from_ticket_provider(ticket_id: $ticket_id) {
      body
      created_by
      creation_time
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment(
    $finding_id: String!
    $body: String!
    $body_content: String
    $ticket_id: String
    $account_id: String
  ) {
    new_comment(
      comment: {
        finding_id: $finding_id
        body: $body
        body_content: $body_content
        ticket_id: $ticket_id
        account_id: $account_id
      }
    ) {
      comment {
        id
        body
        created_by
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($comment_id: String!) {
    delete_comment(existing_comment: { id: $comment_id }) {
      ok
    }
  }
`;
