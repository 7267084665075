import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {
  cloudProviderColors,
  cloudProviderIcons,
} from "../../../../FindingsUtils";
import { snakeCase } from "lodash";
import "./CloudAccountNavigationTreemap.scss";

am4core.addLicense("CH269599249");

const CloudAccountNavigationTreemap = ({ data }) => {
  const chartRef = useRef();

  const getChart = (data) => {
    let chart = am4core.create("cloudAccountHeatmap", am4charts.TreeMap);
    chart.data = getData(data);

    // define data fields
    chart.dataFields.value = "value";
    chart.dataFields.title = "title";
    chart.dataFields.name = "name";
    chart.dataFields.children = "children";
    chart.dataFields.nodeData = "nodeData";
    chart.dataFields.icon = "icon";
    chart.dataFields.color = "color";

    // level 0 series template
    let level0SeriesTemplate = chart.seriesTemplates.create("0");
    let level0ColumnTemplate = level0SeriesTemplate.columns.template;

    level0ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level0ColumnTemplate.fillOpacity = 0;
    level0ColumnTemplate.strokeWidth = 4;
    level0ColumnTemplate.strokeOpacity = 0;
    //
    // // level 1 series template
    let level1SeriesTemplate = chart.seriesTemplates.create("1");
    let level1ColumnTemplate = level1SeriesTemplate.columns.template;

    level1ColumnTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    level1SeriesTemplate.tooltip.animationDuration = 0;
    level1SeriesTemplate.strokeOpacity = 1;

    level1ColumnTemplate.tooltipHTML =
      "<div style='width: 100%; display: flex;'>" +
      "<div style='background-color:white; border-radius:4px; padding: 5px; text-align:center; width: 26px; height: 26px;'>" +
      "<img style='width: 16px; height: 16px; margin-right: 20px' src={icon}></img>" +
      "</div>" +
      "<div style='font-size: 20px;font-weight: bold;margin-left: 10px;'>{value}</div></div>" +
      "<div>{title}</div>";

    level1ColumnTemplate.column.cornerRadius(10, 10, 10, 10);
    level1ColumnTemplate.fillOpacity = 1;
    level1ColumnTemplate.strokeWidth = 2;
    level1ColumnTemplate.stroke = am4core.color("#f9f9fb");

    level1ColumnTemplate.events.on("hit", (ev) => {
      const { groupRowObj, onGroupClick } =
        ev.target.dataItem.dataContext.nodeData;
      onGroupClick(groupRowObj);
    });

    chart.zoomable = false;
    chart.maxLevels = 2;

    return chart;
  };

  const getData = (data) => {
    const treeData = {};

    data.forEach((group) => {
      if (treeData.hasOwnProperty(group.cloudProviderIcon)) {
        treeData[group.cloudProviderIcon].children.push({
          name: group.groupName,
          title: group.groupTitle,
          value: group.groupItem.total_count,
          nodeData: group,
          icon: cloudProviderIcons[snakeCase(group.cloudProviderIcon)],
          groupRowObj: group.groupRowObj,
        });
      } else {
        treeData[group.cloudProviderIcon] = {
          name: group.cloudProviderIcon,
          color:
            cloudProviderColors[
              snakeCase(group?.cloudProviderIcon)?.toLowerCase()
            ] || "green",
          children: [
            {
              title: group.groupTitle,
              name: group.groupName,
              value: group.groupItem.total_count,
              nodeData: group,
              icon: cloudProviderIcons[snakeCase(group.cloudProviderIcon)],
              groupRowObj: group.groupRowObj,
            },
          ],
        };
      }
    });

    return Object.keys(treeData).map((key) => treeData[key]);
  };

  useLayoutEffect(() => {
    if (!chartRef.current && !Number.isNaN(data?.[0]?.groupRowObj.index)) {
      chartRef.current = getChart(data);
    }
  }, [data, getChart]);

  useEffect(() => {
    return () => {
      chartRef?.current?.dispose();
    };
  }, []);

  return (
    <div
      id="cloudAccountHeatmap"
      style={{ width: "800px", height: "142px" }}
    />
  );
};

export default CloudAccountNavigationTreemap;
