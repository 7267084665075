import React, { useMemo, useRef, useState } from "react";
import ClassNames from "classnames";
import { useTranslation } from "react-i18next";
import Input from "./Input/Input";
import Suggestions from "./Suggestions/Suggestions";
import Popover from "react-popover";
import Tag from "./Tag/Tag";
import PropTypes from "prop-types";
import "./TagsSelect.scss";
import { escapeForRegExp } from "./TagUtils";
import FormFieldError from "../FormFieldError/FormFieldError";

const KEYS = {
  ENTER: 13,
  TAB: 9,
  BACKSPACE: 8,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  COMMA: 188,
};

const TagsSelect = ({
  wrapperClassName,
  "data-testid": dataTestId,
  inputStyle,
  size,
  label,
  labelWidth,
  error,
  className,
  disabled,
  readOnly,
  labelTop,
  handleInputChange,
  delimiters,
  minQueryLength,
  suggestions,
  allowNew,
  allowBackspace,
  value,
  onChange,
  maxSuggestionsLength,
  autoFocus,
  autoResize,
  placeholder,
  name,
  maxHeight,
  isLoading,
  newTagTextPrefix,
  newTagTextSuffix,
  onBlur,
  onFocus,
  noSuggestionsText,
}) => {
  const { t } = useTranslation();

  const [inputFocused, setInputFocused] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [query, setQuery] = useState("");
  const input = useRef(null);

  const handleChange = (event) => {
    const value = event.target.value;

    if (handleInputChange) {
      handleInputChange(value);
    }

    setQuery(value);
  };

  const handleKeyDown = (e) => {
    // when one of the terminating keys is pressed, add current query to the tags.
    if (delimiters.indexOf(e.keyCode) !== -1) {
      (query || selectedIndex > -1) && e.preventDefault();

      if (query.length >= minQueryLength) {
        // Check if the user typed in an existing suggestion.
        const match = filteredSuggestions.findIndex((suggestion) => {
          return suggestion.name.search(new RegExp(`^${query}$`, "i")) === 0;
        });

        const index = selectedIndex === -1 ? match : selectedIndex;

        if (index > -1) {
          addTag(filteredSuggestions[index]);
        } else if (allowNew) {
          addTag({ id: query, name: query });
        }
      }
    }

    // when backspace key is pressed and query is blank, delete the last tag
    if (e.keyCode === KEYS.BACKSPACE && query.length === 0 && allowBackspace) {
      deleteTag(value.length - 1);
    }

    if (e.keyCode === KEYS.UP_ARROW) {
      e.preventDefault();

      // if last item, cycle to the bottom
      if (selectedIndex <= 0) {
        setSelectedIndex(filteredSuggestions.length - 1);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
    }

    if (e.keyCode === KEYS.DOWN_ARROW) {
      e.preventDefault();
      setSelectedIndex((selectedIndex + 1) % filteredSuggestions.length);
    }
  };

  const handleClick = (event) => {
    if (document.activeElement !== event.target) {
      input.current.focus();
    }
  };

  const handleBlur = () => {
    setQuery("");
    handleFocus();
    setSelectedIndex(-1);
  };

  const handleFocus = () => {
    if (inputFocused) {
      if (onBlur) {
        onBlur();
      }
    } else {
      if (onFocus) {
        onFocus();
      }
    }
    setInputFocused(!inputFocused);
  };

  const isTagAdded = (newTag, tagsList) => {
    if (!!tagsList) {
      return tagsList?.find((tag) => tag.name === newTag.name);
    } else {
      return false;
    }
  };

  const addTag = (tag) => {
    if (tag.disabled) {
      return;
    }

    if (!isTagAdded(tag, value)) {
      const tags = [...value, tag];
      onChange(tags);
    }

    setQuery("");
    setSelectedIndex(-1);
  };

  const deleteTag = (i) => {
    if (!readOnly) {
      const tags = [...value].slice(0);
      tags.splice(i, 1);
      onChange(tags);
      setQuery("");
    }
  };

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-tags",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "is-disabled": disabled,
      "has-read-only": readOnly,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "has-error": !!error,
      "has-max-height": !!maxHeight,
    },
    className
  );

  const expandable = useMemo(() => {
    return inputFocused && query.length >= minQueryLength;
  }, [inputFocused, query, minQueryLength]);

  const filteredSuggestions = useMemo(() => {
    const regex = new RegExp(`(?:^|\\s)${escapeForRegExp(query)}`, "i");
    const options = suggestions
      .filter((item) => regex.test(item.name) && !isTagAdded(item, value))
      .slice(0, maxSuggestionsLength);

    if (options.length === 0 && query) {
      if (allowNew) {
        if (newTagTextPrefix || newTagTextSuffix) {
          return [
            {
              id: query,
              name: query,
              prefix: newTagTextPrefix,
              suffix: newTagTextSuffix,
              disableMarkIt: true,
            },
          ];
        }
      } else {
        if (noSuggestionsText) {
          return [
            {
              id: 0,
              name: noSuggestionsText,
              disabled: true,
              disableMarkIt: true,
            },
          ];
        }
      }
    }

    return options;
  }, [maxSuggestionsLength, query, suggestions, value]);

  return (
    <div className={classNameWrapper} data-testid={dataTestId}>
      {!!label && (
        <label
          style={{ width: labelWidth ? labelWidth : "" }}
          className={`input-label`}
        >
          {`${t(label)}`}
        </label>
      )}

      <div
        style={maxHeight && { maxHeight: maxHeight }}
        className={`input-holder${
          inputFocused ? " focused" : ""
        } input-holder-tags-select`}
      >
        {isLoading ? (
          <div className={`tags-select is-loading`}>
            <div className={`loading-placeholder`}>{placeholder}</div>
            <div className="dropdown-loading-indicator">
              <div className="loading-dot" />
              <div className="loading-dot" />
              <div className="loading-dot" />
            </div>
          </div>
        ) : (
          <div className="tags-select" onClick={handleClick}>
            <div
              className="tags-select__selected"
              aria-live="polite"
              aria-relevant="additions removals"
            >
              {!!value &&
                value?.map((tag, i) => {
                  return (
                    <Tag key={i} tag={tag} onDelete={deleteTag.bind(this, i)} />
                  );
                })}
            </div>
            <div
              className="tags-select__search"
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            >
              <Popover
                isOpen={expandable}
                preferPlace="below"
                place="below"
                tipSize={0.01}
                body={[
                  <Suggestions
                    key="suggestions"
                    query={query}
                    selectedIndex={selectedIndex}
                    listboxId={`${name}-list-box`}
                    expandable={expandable}
                    suggestions={filteredSuggestions}
                    addTag={addTag}
                    // maxSuggestionsLength={maxSuggestionsLength}
                    data-test={`${dataTestId}-suggestions`}
                  />,
                ]}
              >
                <Input
                  data-test={`${dataTestId}-input`}
                  ref={input}
                  query={query}
                  selectedIndex={selectedIndex}
                  listboxId={`${name}-list-box`}
                  autoFocus={autoFocus}
                  autoResize={autoResize}
                  expandable={expandable}
                  placeholder={placeholder}
                />
              </Popover>
            </div>
          </div>
        )}
      </div>
      <div className="error-holder">
        {error && (
          <FormFieldError
            errorMessage={error.message}
            errorType={error.type}
            isOpen={!inputFocused}
          />
        )}
      </div>
    </div>
  );
};

TagsSelect.defaultProps = {
  placeholder: "",
  maxHeight: "66px",
  suggestions: [],
  autofocus: false,
  autoResize: true,
  delimiters: [KEYS.TAB, KEYS.ENTER, KEYS.COMMA],
  minQueryLength: 2,
  maxSuggestionsLength: 6,
  allowNew: false,
  onBlur: () => {},
  onFocus: () => {},
  allowBackspace: true,
  buttonRemoved: false,
  readOnly: false,
  isLoading: false,
};

TagsSelect.propTypes = {
  allowBackspace: PropTypes.bool,
  allowNew: PropTypes.bool,
  autofocus: PropTypes.bool,
  autoResize: PropTypes.bool,
  className: PropTypes.string,
  delimiters: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  buttonRemoved: PropTypes.bool,
  handleInputChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  maxHeight: PropTypes.string,
  label: PropTypes.string,
  labelTop: PropTypes.bool,
  maxSuggestionsLength: PropTypes.number,
  minQueryLength: PropTypes.number,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["s", "m", "l", "max", "custom"]),
  suggestions: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default TagsSelect;
