import React from "react";
import "./Tabs.scss";

const Tabs = ({
  menuItems,
  onSelectTab,
  activeId,
  displayUnderline = true,
}) => (
  <div className={`tabs-menu-wrapper`}>
    <div className={"menu-tabs"}>
      {menuItems?.map((menuItem) => (
        <div
          key={`${activeId}_${menuItem?.title}`}
          data-testid={`menu-item-${menuItem?.pageLink}`}
          onClick={(e) => {
            e.stopPropagation();
            !!onSelectTab && onSelectTab(menuItem);
          }}
          className={`sub-header-menu-item-link${
            menuItem?.pageLink === activeId ? " active" : ""
          }`}
          title={menuItem?.title}
        >
          <div
            className={`sub-menu-item-label  ${
              menuItem?.leftImg ? " has-left-img" : ""
            }`}
          >
            {!!menuItem?.leftImg ? (
              <img src={menuItem?.leftImg} alt={menuItem?.title} />
            ) : null}
            <span className={`sub-menu-item-title`}>{menuItem?.title}</span>
          </div>
          <div className={`sub-item-underline`} />
        </div>
      ))}
    </div>
    {displayUnderline && <div className={"menu-underline"} />}
  </div>
);

export default Tabs;
