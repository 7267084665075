import React from "react";
import "./OverviewItem.scss";

const OverviewItem = ({ title, value, className }) => (
  <div className={`overview-item ${className ? className : ""}`}>
    <div className={"overview-item-title"} title={title}>
      {title}
    </div>
    <div className={"overview-item-value"} title={value}>
      {value}
    </div>
  </div>
);

export default OverviewItem;
