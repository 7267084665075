import { useState, useMemo, useCallback, useEffect } from "react";
import { GET_FINDINGS } from "./FindingsListApi";
import {
  FINDING_ROW_HEIGHT,
  FINDINGS_PAGE_SIZE,
  findingsCheckType,
  findingsQueryParams,
  findingsRowType,
  listScrollDirection,
} from "../../FindingsUtils";
import { useQuery } from "@apollo/client";
import { useHistory, useRouteMatch } from "react-router-dom";
import useFindingsQueryParam from "../useFindingsQueryParam";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { difference, union } from "lodash";

const useFindingsListState = (
  sort = [],
  filters = null,
  listRef = null,
  scrollToIndexProp = null,
  withTotalCount = false,
  totalCount = null
) => {
  const history = useHistory();
  let match = useRouteMatch();
  const { clearQueryParam, search } = useFindingsQueryParam(null);

  const [startCursor, setStartCursor] = useState("");
  const [endCursor, setEndCursor] = useState("");
  const [scrollTo, setScrollTo] = useState(null);
  const [findingsTotalCount, setFindingsTotalCount] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [findings, setFindings] = useState([]);
  const [scrollData, setScrollData] = useState(null);
  const [loadMore, setLoadMore] = useState(false);

  const [selectedRowState, setSelectedRowState] = useState({
    selectedFilterRow: null,
    hasSelectedItem: false,
  });

  const [checkedFindings, setCheckedFindings] = useState({
    type: findingsCheckType.MULTI,
    findingsList: [],
    numberOfPreviouslyAggregated: 0,
  });

  const onCheckFinding = (findingData, isChecked) => {
    if (findingData?.id === findingsCheckType.ALL) {
      setCheckedFindings({
        type: isChecked ? findingsCheckType.ALL : findingsCheckType.MULTI,
        findingsList: [],
        numberOfPreviouslyAggregated:
          checkedFindings?.numberOfPreviouslyAggregated,
      });
    } else {
      let checked = checkedFindings?.findingsList?.slice();
      let numOfAggregated = checkedFindings?.numberOfPreviouslyAggregated;
      if (isChecked) {
        checked = union(checked, [findingData?.id]);
        if (findingData?.isMainFinding) {
          numOfAggregated += 1;
        }
      } else {
        checked = difference(checked, [findingData?.id]);
        if (findingData?.isMainFinding) {
          numOfAggregated -= 1;
        }
      }

      setCheckedFindings({
        type: findingsCheckType.MULTI,
        findingsList: checked,
        numberOfPreviouslyAggregated: numOfAggregated,
      });
    }
  };

  const resetCheckedFindings = () => {
    setCheckedFindings({
      type: findingsCheckType.MULTI,
      findingsList: [],
      numberOfPreviouslyAggregated: 0,
    });
  };

  const onSelectedFinding = (finding, toggle) => {
    const inRmq = match.path.startsWith("/remediationQueue/");
    const inFindings = match.url.startsWith("/findings");
    if (inRmq && !match.params.queueid) {
      return;
    }

    setSelectedRowState({
      ...selectedRowState,
      selectedFilterRow: finding,
      hasSelectedItem: toggle,
    });

    if (!!finding) {
      const newId = btoa(`Finding:${finding.id_int}`);
      if (inFindings) {
        history.replace(`/findings/${newId}`);
      }
      if (inRmq) {
        history.replace(`/remediationQueue/${match.params.queueid}/${newId}`);
      }
    } else {
      if (inFindings) {
        history.replace(`/findings`);
      }
      if (inRmq) {
        const currentId = btoa(
          `Finding:${selectedRowState?.selectedFilterRow?.id_int}`
        );
        if (currentId === match?.params?.id) {
          history.replace(`/remediationQueue/${match.params.queueid}`);
        }
      }
    }
  };

  const { filterToExecute, scopeToExecute, selectedScope, selectedFilter } =
    filters || {};

  const resetList = () => {
    setEndCursor("");
    setStartCursor("");
    resetCheckedFindings();
  };

  const [isLoadingFindings, setIsLoadingFindings] = useState(false);

  const handleScroll = (updatedScrollData) => {
    setScrollData({ ...scrollData, ...updatedScrollData });
  };

  useEffect(() => {
    setIsLoadingFindings(false);
  }, [findings]);

  const commonQueryVars = useMemo(() => {
    return {
      sort: sort,
      filters_config: {
        scopesjson: scopeToExecute || null,
        filtersjson: filterToExecute || "",
        scopesid: selectedScope?.value?.id,
      },
      last: FINDINGS_PAGE_SIZE,
      with_total_count: withTotalCount,
      totalCount: totalCount,
    };
  }, [
    scopeToExecute,
    filterToExecute,
    selectedScope,
    sort,
    withTotalCount,
    totalCount,
  ]);

  useEffect(() => {
    if (scrollData?.scrollOffset && !scrollData?.scrollUpdateWasRequested) {
      const targetRow = Math.floor(
        scrollData?.scrollOffset / FINDING_ROW_HEIGHT
      );

      const relevantData = findings.slice(0, targetRow);
      const numOfGroups = relevantData.filter(
        (finding) => finding.rowType === findingsRowType.GROUP
      )?.length;

      const index = targetRow - numOfGroups;
      const offsetIndex = index - 50 > 0 ? index - 50 : 0;
      const offsetCursor = btoa(`arrayconnection:${offsetIndex}`);

      setStartCursor(offsetCursor);
      setEndCursor(offsetCursor);
      setLoadMore(true);
    }
  }, [scrollData]);

  const scrollQueryVars = useMemo(() => {
    return {
      ...commonQueryVars,
      before: "",
      after: endCursor,
      first: FINDINGS_PAGE_SIZE,
    };
  }, [endCursor, commonQueryVars]);

  const {
    loading: loadingData,
    error: errorData,
    data: findingsListData,
    fetchMore: fetchMoreFindings,
  } = useQuery(GET_FINDINGS, {
    variables: {
      ...commonQueryVars,
      before: "",
      after: "",
      first: FINDINGS_PAGE_SIZE,
    },
    skip:
      (!selectedScope?.value?.id && !scopeToExecute) ||
      (!selectedFilter?.value?.id && !filterToExecute),
  });

  useEffect(() => {
    if (scrollToIndexProp !== null) {
      setScrollTo(scrollToIndexProp);
    }
  }, [scrollToIndexProp?.index, scrollToIndexProp?.cursor, scrollToIndexProp]);

  useEffect(() => {
    if (scrollTo !== null) {
      let offsetCursor;
      let offsetIndex;
      if (scrollTo?.cursor) {
        offsetCursor = scrollTo?.cursor;
      } else {
        offsetIndex = scrollTo?.index - 50 > 0 ? scrollTo?.index - 50 : 0;
        offsetCursor = btoa(`arrayconnection:${offsetIndex}`);
      }

      setStartCursor(offsetCursor);
      setEndCursor(offsetCursor);
      setScrollData(null);
    }
  }, [scrollTo?.index, scrollTo?.cursor, scrollTo]);

  useEffect(() => {
    if (!!findingsListData) {
      setHasPreviousPage(
        findingsListData.findings.pageInfo.hasPreviousPage || false
      );
      setHasNextPage(findingsListData.findings.pageInfo.hasNextPage || false);
      setStartCursor(findingsListData.findings.pageInfo.startCursor);
      setEndCursor(findingsListData.findings.pageInfo.endCursor);
      if (withTotalCount) {
        setFindingsTotalCount(findingsListData.findings.total_count);
      } else {
        setFindingsTotalCount(totalCount);
      }
    }
  }, [findingsListData]);

  const loadMoreFindings = useCallback(() => {
    if (
      !!findingsTotalCount &&
      findings?.length &&
      ((scrollData?.scrollDirection === listScrollDirection.FORWARD &&
        hasNextPage) || // Scrolling forward and next page exists
        (scrollData?.scrollDirection === listScrollDirection.BACK &&
          hasPreviousPage) || // Scrolling backward and previous page exists
        (endCursor === startCursor && !!scrollTo && !scrollData)) // Scrolling to specified index
    ) {
      setIsLoadingFindings(true);
      return fetchMoreFindings({
        variables: scrollQueryVars,
      })
        .then(() => {})
        .catch((error) => {
          showDialog({
            title: "Error",
            type: "error",
            message: error.message,
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        });
    } else {
      return Promise.resolve();
    }
  }, [
    scrollData,
    findings,
    findingsTotalCount,
    hasNextPage,
    hasPreviousPage,
    endCursor,
    startCursor,
    fetchMoreFindings,
  ]);

  useEffect(() => {
    if (!!scrollData && scrollData?.scrollOffset && loadMore) {
      loadMoreFindings().then(() => {
        setScrollData(null);
        setLoadMore(false);
        if (!!search) {
          clearQueryParam([findingsQueryParams.GROUP_VALUE]);
        }
      });
    }
  }, [loadMore]);

  useEffect(() => {
    // The next check verifies that all of the index scrolling conditions from the previous effect were accomplished.
    if (
      findings?.length &&
      endCursor === startCursor &&
      !!scrollTo?.index?.toString() &&
      scrollData === null
    ) {
      if (findings[scrollTo.index + 1]?.isLoader) {
        loadMoreFindings();
      }

      listRef.current.scrollToItem(scrollTo?.index, "start");
      setScrollTo(null);
    }
  }, [endCursor, startCursor, scrollData, findings, scrollTo]);

  return {
    errorData,
    loadMoreFindings,
    findings,
    setFindings,
    loadingData,
    findingsListData,
    handleScroll,
    onSelectedFinding,
    selectedRowState,
    findingsTotalCount,
    isLoadingFindings,
    resetList,
    checkedFindings,
    onCheckFinding,
  };
};

export default useFindingsListState;
