import React, { useContext, useEffect, useState } from "react";
import { taskNames } from "../WebSocket/WebSocketUtils";
import { showToast, dismissToast } from "../Forms/Dialogs/Toast/Toast";
import Button from "../Forms/Controls/Button/Button";
import UnsavedDataToast from "../Forms/Dialogs/Toast/UnsavedDataToast";
import { useApolloClient } from "@apollo/client";
import { GeneralAppContext } from "../General/GeneralAppProvider";
import useWebSocketMessageHandler from "../WebSocket/useWebSocketMessageHandler";

const useFindingsViewChange = ({ queries }) => {
  const { wsMessage } = useWebSocketMessageHandler({
    messageTypes: [taskNames.UPDATE_VIEW],
  });
  const client = useApolloClient();
  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);
  const [toastId, setToastId] = useState(null);

  useEffect(() => {
    if (!!wsMessage) {
      if (!toastId) {
        const id = showToast({
          position: "bottom-right",
          hideProgressBar: true,
          autoClose: false,
          closeOnClick: false,
          className: "unsaved-filter",
          component: (
            <UnsavedDataToast
              message={
                <>
                  <div>{`New Data is Available`}</div>
                  <div className={`unsaved-toast-sub-title`}>
                    <div>
                      Your findings data has been updated! Isn’t that exciting?
                    </div>
                  </div>
                  <Button
                    data-testid={`scope-save-toast`}
                    label={"Refresh to Update"}
                    onClick={() => {
                      client
                        .refetchQueries({
                          include: queries,
                        })
                        .then(() => {
                          dismissToast(toastId);
                          setToastId(null);
                          resetFindingsList && resetFindingsList();
                        });
                    }}
                  />
                </>
              }
            />
          ),
        });

        setToastId(id);
      }
    }
  }, [wsMessage]);
};

export default useFindingsViewChange;
