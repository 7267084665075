import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import {
  getFindingSeverity,
  dataSourceIcons,
  getSeverityValueByDescription,
} from "../../../Findings/FindingsUtils";
import { getFilterToExecute } from "../../DashboardUtils";
import useDashboardNavigator from "../../useDashboardNavigator";
import useSummary from "../../useSummary";

const FindingsByDataSourceAndSeverity = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
}) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [maxFindings, setMaxFindings] = useState(0);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id"],
      group_by: [
        "datasource_definition.name",
        "datasource_definition.file_prefix",
        "finding.severity",
      ],
      aggregate_functions: ["COUNT"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });

  const createCategoryAxis = (categoryAxis) => {
    categoryAxis.dataFields.category = "source";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 40;
  };

  const createValueAxis = (valueAxis) => {
    valueAxis.min = 0;
    valueAxis.max = maxFindings;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.paddingLeft = 10;
    valueAxis.paddingRight = 20;
    valueAxis.layout = "absolute";
    valueAxis.title.text = "Findings";
    valueAxis.title.fontSize = 14;
    valueAxis.title.fontWeight = 500;
    valueAxis.title.rotation = 0;
    valueAxis.title.dy = -30;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "top";
  };

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const sources = [];
      const dataObj = {};

      for (let i = 0; i < data.length; i += 1) {
        const dataSrcName = data[i][0];
        if (!sources.includes(dataSrcName)) {
          sources.push(dataSrcName);
          dataObj[dataSrcName] = {};
        }

        const logo = data[i][1];
        dataObj[dataSrcName].logo = logo;

        const severity = getFindingSeverity(data[i][2]);

        const findingsAmount = data[i][3];
        dataObj[dataSrcName][severity] = findingsAmount;
      }

      const max = Math.max(
        ...Object.keys(dataObj).map((el) => {
          let findingsNum = 0;
          for (const [key, value] of Object.entries(dataObj[el])) {
            if (key !== "logo") {
              findingsNum += value;
            }
          }
          return findingsNum;
        })
      );

      setMaxFindings(max);

      const dataArray = Object.keys(dataObj).map((el) => {
        return {
          source: el,
          low: dataObj[el].low ?? undefined,
          medium: dataObj[el].medium ?? undefined,
          high: dataObj[el].high ?? undefined,
          critical: dataObj[el].critical ?? undefined,
          logo: dataObj[el].logo,
        };
      });

      if (dataArray?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (dataArray?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(dataArray);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      let [chart, legendContainer] = createChart(
        "stackBarDiv",
        am4charts.XYChart
      );
      chart.data = dashboardData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      createCategoryAxis(categoryAxis);
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis);
      let range = valueAxis.axisRanges.create();
      range.value = maxFindings;

      let image = new am4core.Image();
      image.horizontalCenter = "middle";
      image.verticalCenter = "top";
      image.dy = 12;
      image.height = 24;
      image.width = 90;
      image.adapter.add("href", (href, target) => {
        let logo = target.dataItem.dataContext?.logo;
        if (logo) {
          return dataSourceIcons[logo]?.L;
        }
        return href;
      });
      categoryAxis.dataItems.template.bullet = image;
      categoryAxis.dataItems.template.text = "";

      chart.legend.useDefaultMarker = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;

      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "source";
        series.sequencedInterpolation = true;

        series.stacked = true;
        series.columns.template.width = am4core.percent(60);
        series.columns.template.maxWidth = 80;
        series.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = "white";
        series.columns.template.fillOpacity = 0.6;
        series.columns.template.strokeOpacity = 1;
        series.columns.template.propertyFields.stroke = "fill";
        series.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        series.columns.template.events.on("hit", (e) => {
          const clickedSeverity = getSeverityValueByDescription(field);
          const clickedDataSource = e.target.dataItem.dataContext.source;

          dashboardNavigator(
            null,
            null,
            {
              groupIndex: 0,
              data: {
                severity: clickedSeverity,
              },
            },
            getFilterToExecute({
              value: clickedDataSource,
              type: "source",
            })
          );
        });

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}";
        labelBullet.locationY = 0.5;
        labelBullet.label.hideOversized = true;
        labelBullet.label.fill = "#26026c";
        labelBullet.label.fontWeight = 500;
        labelBullet.label.fontSize = 10;

        return series;
      }

      chart.colors.list = [
        am4core.color("#21bc89"),
        am4core.color("#f7bb00"),
        am4core.color("#ff7506"),
        am4core.color("#ec2a3f"),
      ];

      createSeries("low", "Low");
      createSeries("medium", "Medium");
      createSeries("high", "High");
      createSeries("critical", "Critical");

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"stackBarDiv"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default FindingsByDataSourceAndSeverity;
