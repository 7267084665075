import React, { useContext, useState } from "react";
import Button from "../../../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import "./SyncQueue.scss";
import { ModalContext } from "../../../../../Forms/Dialogs/Modal/ModalContext";
import { queueStates } from "../../../../RemediationQueuesUtils";
import { capitalize } from "lodash";
import { showErrorDialog } from "../../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ticketEndpointTypeEnum } from "../../../../../Ticketing/TicketingUtils";

const SyncQueue = ({
  id,
  ticketEndpointType,
  providerType,
  updateRemediationQueue,
  updateRemediationQueueData,
}) => {
  const { closeModal } = useContext(ModalContext);
  const [update, setUpdate] = useState(false);
  const { t } = useTranslation();

  const messageByProvider = (type) => {
    switch (type) {
      case ticketEndpointTypeEnum.REPORT:
        return (
          <div className={"sync-queue-message"}>
            <p>Pausing this queue will stop sending alerts for new findings.</p>
            <p>
              You can restart the alerts at any given time by clicking on
              "Sync".
            </p>
          </div>
        );
      case ticketEndpointTypeEnum.TICKET:
        return (
          <div className={"sync-queue-message"}>
            <p>
              {`Pausing this queue will keep the findings in ${capitalize(
                providerType
              )}’s backlog, but will not create new ones.`}
            </p>
            <p>
              {`If you wish to remove items from ${capitalize(
                providerType
              )} you’ll need to delete this queue.`}
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={"sync-queue-container"}>
      {messageByProvider(ticketEndpointType)}
      <div className={"sync-queue-buttons"}>
        <Button
          data-testid={"cancel-sync-queue"}
          label={t("Cancel")}
          onClick={() => closeModal()}
        />
        <Button
          data-testid={"confirm-sync-pause"}
          isSecondary
          disabled={update}
          rightIcon={update ? "seem-icon-spinner spinner" : ""}
          label={t("Yes, pause sync")}
          onClick={() => {
            setUpdate(true);
            updateRemediationQueue({
              variables: { state: queueStates.DISABLED, id: id },
            })
              .then((res) => {
                if (res?.data?.update_remediation_queue?.ok) {
                  updateRemediationQueueData({ state: queueStates.DISABLED });
                  closeModal();
                } else {
                  showErrorDialog({
                    message: "Remediation queue update failed",
                  });
                }
              })
              .catch((error) => showErrorDialog({ message: error.message }));
          }}
        />
      </div>
    </div>
  );
};

export default SyncQueue;
