import React, { useState, useEffect, useCallback } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_REMEDIATION_QUEUES,
  GET_REMEDIATION_QUEUES_FINDINGS_COUNT,
} from "../RemediationQueueApi";
import moment from "moment";
import Table from "../../Components/Table/Table";
import { dataSourceIcons } from "../../Findings/FindingsUtils";
import { queueStates } from "../RemediationQueuesUtils";
import {
  ticketManagersEnum,
  ticketManagers,
  ticketStatus,
} from "../../Ticketing/TicketingUtils";
import "./RemediationQueuesList.scss";
import TicketStatus from "../../Findings/FindingsData/FindingsList/FindingsListItem/TicketStatus/TicketStatus";
import { useHistory } from "react-router-dom";
import SelectInput from "../../Forms/Controls/SelectInput/SelectInput";
import { Control } from "../../Findings/FindingsFilters/FilterHeaderStyleComponents/FilterHeaderStyleComponents";
import LabelIndicator from "../../Components/LabelIndicator/LabelIndicator";

const activityValues = [
  {
    label: "High",
    value: "High",
  },
  {
    label: "Medium",
    value: "Medium",
  },
  {
    label: "Low",
    value: "Low",
  },
];

const multiSelectColumnFilter = (options) => {
  options = [{ label: "All", value: "" }, ...options];
  return ({ column: { filterValue, setFilter, id } }) => {
    const includes = (val) => {
      return filterValue?.includes(val);
    };
    let value = options.filter((option) => includes(option.value));

    return (
      <SelectInput
        data-testid={id}
        SelectInputStyle={"bomba-style"}
        isMulti
        size={"max"}
        options={options}
        value={value || ""}
        onChange={(newValue) => {
          if (filterValue?.includes("")) {
            newValue = newValue?.filter((option) => option.value !== "");
          } else if (
            newValue?.filter((option) => option.value === "")?.length
          ) {
            newValue = newValue?.filter((option) => option.value === "");
          }
          setFilter(newValue.map((val) => val.value)); // Set undefined to remove the filter entirely
        }}
        replaceableComponents={{
          Control,
        }}
        customProps={{
          icon: "seem-icon-filter-saved",
        }}
      />
    );
  };
};

const ticketStatusFilterFn = (rows, id, filterValues) => {
  if (!filterValues?.length || !filterValues?.[0]) {
    return rows;
  }
  return rows.filter((row) => {
    let isFilterRes = null;

    let ticketStatusMap = Object.fromEntries(row.values.ticket_status);

    filterValues?.forEach((value) => {
      let match =
        ticketStatusMap?.hasOwnProperty(value) && ticketStatusMap?.[value] > 0;
      isFilterRes = isFilterRes ? isFilterRes || match : match;
    });

    return (
      isFilterRes &&
      row?.original?.ticket_endpoint?.ticket_provider?.type ===
        ticketManagersEnum.JIRA
    );
  });
};

const RemediationQueuesList = () => {
  let history = useHistory();
  const [queuesList, setQueuesList] = useState([]);

  const { loading, data: remediationQueues } = useQuery(
    GET_REMEDIATION_QUEUES,
    {
      fetchPolicy: "network-only",
    }
  );
  const { data: remediationQueuesCount } = useQuery(
    GET_REMEDIATION_QUEUES_FINDINGS_COUNT,
    {
      skip: !queuesList.length,
      fetchPolicy: "network-only",
    }
  );

  const sortedActivity = useCallback((rowA, rowB, columnId) => {
    if (rowA.values[columnId] === rowB.values[columnId]) {
      return 0;
    } else if (rowA.values[columnId] === "High") {
      return 1;
    } else if (rowB.values[columnId] === "High") {
      return -1;
    } else if (rowA.values[columnId] === "Medium") {
      return 1;
    } else if (rowB.values[columnId] === "Medium") {
      return -1;
    } else if (rowA.values[columnId] === "Low") {
      return 1;
    } else if (rowB.values[columnId] === "Low") {
      return -1;
    }
  }, []);

  useEffect(() => {
    let listItems =
      remediationQueues?.remediation_queues?.edges?.map((item) => {
        return {
          ...item.node,
          activity: "High",
          avgTime: "50",
        };
      }) || [];

    setQueuesList(listItems);
  }, [remediationQueues]);

  useEffect(() => {
    if (!!remediationQueuesCount) {
      let queuesListCopy =
        remediationQueuesCount?.remediation_queues?.edges?.map((counts) => {
          const newEl = queuesList.find(
            (dataEl) => dataEl.id === counts?.node?.id
          );
          if (!!newEl) {
            newEl.findings_count = counts?.node?.findings_count;
          }
          return newEl;
        });

      setQueuesList(queuesListCopy);
    }
  }, [remediationQueuesCount]);

  const columns = React.useMemo(
    () => [
      {
        id: "name",
        Header: "Name",
        accessor: "title",
        filter: "fuzzyText",
        width: "180",
      },
      {
        id: "output",
        Header: "Output",
        className: "rmq-input",
        Filter: multiSelectColumnFilter(
          [...ticketManagers].map((el) => {
            return { label: el.friendlyName, value: el.value };
          })
        ),
        filter: "includesSome",
        accessor: (d) => {
          return d?.ticket_endpoint?.ticket_provider?.type;
        },
        Cell: (row) => {
          return row.value ? (
            <div
              className={"td-rmq-data"}
              data-testid={"output"}
              data-output={row?.value?.toLowerCase()}
            >
              <img alt={`${row.value}`} src={dataSourceIcons[row.value]?.L} />
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: "# of Findings",
        accessor: "findings_count",
        disableFilters: true,
        sortType: "number",
        className: "td-finding-num",

        Cell: (row) => {
          return row.value || row.value === 0 ? (
            row.value
          ) : (
            <div className={"loading-row"} data-testid={"loading-row"} />
          );
        },
      },
      {
        Header: "Ticket Status",
        id: "ticket_status",
        width: "180",
        disableSortBy: true,
        className: "jira-status-td",
        Filter: multiSelectColumnFilter(
          Object.keys(ticketStatus)
            ?.filter((ticketStatus) => ticketStatus !== "DELETED")
            .map((status) => {
              return { label: ticketStatus[status].text, value: status };
            })
        ),
        filter: ticketStatusFilterFn,
        accessor: (d) => {
          return d?.findings_count_by_ticket_status
            ? JSON.parse(d?.findings_count_by_ticket_status)
            : [];
        },
        Cell: (row) => {
          const providerName =
            row.row.original.ticket_endpoint.ticket_provider.type;
          if (row.value && providerName !== ticketManagersEnum.SLACK) {
            const ticketStatusItems = row.value;

            return ticketStatusItems
              ?.filter((ticketStatus) => ticketStatus[0] !== "DELETED")
              ?.map((ticketStatus) => {
                return (
                  <div className={`jira-status-item`}>
                    <TicketStatus status={ticketStatus[0]} />
                    <span
                      className={`jira-status-item-count`}
                      data-testid={`${ticketStatus[0]
                        ?.toLowerCase()
                        .replaceAll(" ", "_")}-item-count`}
                    >{`${ticketStatus[1]}`}</span>
                  </div>
                );
              });
          } else {
            return null;
          }
        },
      },
      {
        id: "activity",
        Header: "Activity Level",
        accessor: (d) => {
          return d?.activity;
        },
        Filter: multiSelectColumnFilter(activityValues),
        filter: "includesSome",
        sortType: sortedActivity,
      },
      {
        id: "last_updated",
        Header: "Last Updated",
        className: "rmq-last-update",
        disableFilters: true,
        accessor: (d) => {
          return d?.updated_time || null;
        },
        Cell: (row) => {
          const isSynced = row.row.original.state === queueStates.ENABLED;
          return (
            <div
              className={`last-update-row-data`}
              data-testid={"last-updated"}
              data-lastupdated={row?.value}
            >
              <div>{row.value ? moment(row.value).fromNow() : "N/A"}</div>
              <LabelIndicator
                className={isSynced ? "synced" : "not-synced"}
                text={isSynced ? "Synced" : "Not Synced"}
                icon={`seem-icon-${isSynced ? "synced" : "not-synced"}`}
              />
            </div>
          );
        },
      },
    ],
    [queuesList]
  );

  const rowClick = (row) => {
    history.push(`/remediationQueue/${row.original.id}`);
  };

  return (
    <div className={`remediation-queue-list remediation-queues-wrapper`}>
      <div className="rmq-table-container">
        <Table
          tableWrapperClass={"rmq-table"}
          loading={loading}
          initialSortBy={[{ id: "name" }]}
          initialFilters={[
            { id: "activity", value: [""] },
            { id: "output", value: [""] },
            { id: "ticket_status", value: [""] },
          ]}
          columns={columns}
          data={queuesList}
          onRowClick={(row) => {
            rowClick(row);
          }}
          data-testid-body={"rmq"}
        />
      </div>
    </div>
  );
};

export default RemediationQueuesList;
