import Button from "../../../Forms/Controls/Button/Button";
import React from "react";
import { useTranslation } from "react-i18next";

const ViewMoreItemsButton = ({ total, count, isFullScreen, btnText }) => {
  const { t } = useTranslation();
  const moreItemsCount = total - count;

  return total > count && !isFullScreen ? (
    <div className="details-pane-related-view-btn-wrap">
      <a rel="noreferrer" target="_blank" href={window.location.href}>
        <Button
          type={`button`}
          label={btnText ? btnText : t(` View ${moreItemsCount} more`)}
        />
      </a>
    </div>
  ) : null;
};

export default ViewMoreItemsButton;
