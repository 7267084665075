import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  GET_REMEDIATION_QUEUE,
  GET_RMQ_PRIORITY_SETTINGS,
} from "../RemediationQueueApi";
import { useParams } from "react-router-dom";
import { modeOptions } from "../RemediationQueuesUtils";
import {
  DEFAULT_FILTER,
  DEFAULT_FILTER_NAME,
  DEFAULT_SCOPE,
  DEFAULT_SCOPE_NAME,
  SYSTEM,
} from "../../Findings/FindingsFilters/FilterUtils";
import ScopeProvider from "../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import FilterProvider from "../../AppView/FilterManagement/FilterProvider/FilterProvider";

export const RemediationQueueContext = React.createContext({});

const RemediationQueueProvider = ({ children }) => {
  const [remediationQueueData, setRemediationQueueData] = useState({});
  const [hasGeneralPrioritizeSetting, setHasPrioritizeSetting] = useState(null);
  const [mode, setMode] = useState(modeOptions.READ);
  let { queueid } = useParams();

  const { loading, data: remediation_queue } = useQuery(GET_REMEDIATION_QUEUE, {
    variables: { id: queueid },
    skip: !queueid,
  });

  const { data: settingsData } = useQuery(GET_RMQ_PRIORITY_SETTINGS, {
    skip: mode !== modeOptions.CREATE,
  });

  useEffect(() => {
    if (!queueid) {
      setMode(modeOptions.CREATE);
    } else {
      setMode(modeOptions.READ);
    }
  }, [queueid]);

  const updateMode = (rmqMode) => setMode(rmqMode);

  useEffect(() => {
    if (!!settingsData) {
      const rmqPriority = settingsData?.settings?.edges?.[0]?.node;
      if (!!rmqPriority) {
        setHasPrioritizeSetting({ value: rmqPriority?.value });
      }
    } else {
      setHasPrioritizeSetting(null);
    }
  }, [settingsData]);

  useEffect(() => {
    if (!!remediation_queue) {
      const remediationQueue = remediation_queue.remediation_queue;
      const fieldMapping = JSON.parse(
        remediationQueue?.ticket_endpoint?.value_mapping
      );

      const findingScope = remediationQueue?.finding_scope;
      let findingScopeName = remediationQueue?.finding_scope?.name;
      if (
        findingScope?.name === DEFAULT_SCOPE &&
        findingScope?.created_by === SYSTEM
      ) {
        findingScopeName = DEFAULT_SCOPE_NAME;
      }

      const findingFilter = remediationQueue?.finding_filter;
      let findingFilterName = remediationQueue?.finding_filter?.name;
      if (
        findingFilter?.name === DEFAULT_FILTER &&
        findingFilter?.created_by === SYSTEM
      ) {
        findingFilterName = DEFAULT_FILTER_NAME;
      }

      setRemediationQueueData({
        ...remediationQueue,
        priority: remediationQueue?.priority,
        ticketEndpointType: remediationQueue?.ticket_endpoint?.type,
        providerId: remediationQueue?.ticket_endpoint?.ticket_provider?.id,
        providerType: remediationQueue?.ticket_endpoint?.ticket_provider?.type,
        ticketEndpointId: remediationQueue?.ticket_endpoint?.id,
        findingScopeName: findingScopeName,
        findingScopeGroupName: remediationQueue?.finding_scope_group?.name,
        findingFilterName: findingFilterName,
        scopeId: remediationQueue?.scope_id,
        filter_id: btoa(`FindingFilter:${remediationQueue?.finding_filter_id}`),
        scopejson: remediationQueue?.finding_scope?.filter_data,
        filterjson: remediationQueue?.finding_filter?.filter_data,
        queueSize: remediationQueue?.max_concurrent_opened_tickets,
        channel: fieldMapping?.channel?.key,
      });
    }
  }, [remediation_queue]);

  const updateRemediationQueueData = (state) => {
    setRemediationQueueData({ ...remediationQueueData, ...state });
  };

  const remediationQueueContextValue = useMemo(
    () => ({
      remediationQueueData,
      mode,
      updateMode,
      updateRemediationQueueData,
      loading,
      hasGeneralPrioritizeSetting,
    }),
    [remediationQueueData, hasGeneralPrioritizeSetting, mode]
  );

  return (
    <RemediationQueueContext.Provider value={remediationQueueContextValue}>
      <ScopeProvider>
        <FilterProvider>{children}</FilterProvider>
      </ScopeProvider>
    </RemediationQueueContext.Provider>
  );
};

export default RemediationQueueProvider;
