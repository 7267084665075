import React, { useContext, useEffect, useState } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import { uniqBy } from "lodash";
import { AggregatedFindingsContext } from "../AggregatedFindings/AggregatedFindingsProvider";
import Tags from "./Tags/Tags";
import "./Resources.scss";
import ResourcesList from "./ResourcesList/ResourcesList";

const STATUS_CATEGORY_ENUM = {
  OPEN: "OPEN",
  FIXED: "FIXED",
  IGNORED: "IGNORED",
};

const Resources = () => {
  const {
    detailsPaneData: {
      resource_name,
      resource_id,
      resource_type,
      aggregation_group_id,
      seem_tags,
      datasource_tags,
      isFullScreen,
    },
  } = useContext(DetailsPaneContext);

  const { relatedFindings: data } = useContext(AggregatedFindingsContext);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    if (!aggregation_group_id) {
      setResources([
        {
          resource_name,
          resource_id,
          resource_type,
        },
      ]);
    }
  }, [aggregation_group_id, resource_id, resource_name, resource_type]);

  useEffect(() => {
    if (!!data && !!aggregation_group_id) {
      let resourcesList = [];
      resourcesList = uniqBy(
        data
          ?.map((finding) => {
            return finding.node;
          })
          .filter((finding) => {
            return (
              finding.system_status?.category === STATUS_CATEGORY_ENUM.OPEN ||
              finding.user_status?.category === STATUS_CATEGORY_ENUM.OPEN
            );
          }),
        "resource_id"
      );

      if (resourcesList.length > 10) {
        setResources(resourcesList.slice(0, 10));
      } else {
        setResources(resourcesList);
      }
    }
  }, [aggregation_group_id, data]);

  return (
    <div className={`details-pane-section-data`}>
      <ResourcesList resources={resources} />
      <Tags
        tagsID={resource_id}
        seem_tags={seem_tags}
        datasource_tags={datasource_tags}
        maxTags={10}
        showHeader
        showCollapsed
        displayKey
        isFullScreen={isFullScreen}
      />
    </div>
  );
};

export default Resources;
