import { useEffect, useState } from "react";

const useChangeTooltipMsg = (initialMsg, finalMsg) => {
  const [copyMsg, setCopyMsg] = useState(initialMsg);

  useEffect(() => {
    if (copyMsg === finalMsg) {
      setTimeout(() => setCopyMsg(initialMsg), 1800);
    }
  }, [copyMsg]);

  return { copyMsg, setCopyMsg };
};

export default useChangeTooltipMsg;
