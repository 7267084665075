import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SidebarLayoutStateContext } from "../../Components/SidebarLayout/SidebarLayout";

export const integrationInitialValues = {
  active: "main",
  currentIntegration: null,
};

const useIntegrationManagement = () => {
  const [activeComponent, setActiveComponent] = useState("main");
  const [currentIntegration, setCurrentIntegration] = useState(null);
  const { changeDisplayAreaTitle } = useContext(SidebarLayoutStateContext);
  const [integrationContext, setIntegrationContext] = useState(null);

  useEffect(() => {
    const initialContext = createContext({ active: "main" });
    setIntegrationContext(initialContext);

    return () => {
      changeDisplayAreaTitle(null);
    };
  }, []);

  const handleChange = useCallback(
    (reference, data = null) => {
      setActiveComponent(reference);

      switch (reference) {
        case "view":
          changeDisplayAreaTitle(
            <span>
              <span className={"display-area-title-separator"}> / </span>
              {data.name}
            </span>
          );
          setCurrentIntegration(data);
          break;
        case "create":
          changeDisplayAreaTitle(
            <span>
              <span className={"display-area-title-separator"}> / </span>
              {data.name}
              <span className={"display-area-title-separator"}> / </span>
              {`New Instance`}
            </span>
          );
          setCurrentIntegration(data);
          break;
        case "edit":
          const integrationObj = { ...currentIntegration };
          const editedInstance = integrationObj?.instances.find(
            (instance) => instance.id === data
          );
          integrationObj.editedInstance = editedInstance;

          changeDisplayAreaTitle(
            <span>
              <span className={"display-area-title-separator"}> / </span>
              {currentIntegration.name}
              <span className={"display-area-title-separator"}> / </span>
              {editedInstance.name ? editedInstance.name : `New Instance`}
            </span>
          );
          setCurrentIntegration(integrationObj);
          break;
        default:
          changeDisplayAreaTitle(null);
          setCurrentIntegration(null);
      }
    },
    [currentIntegration]
  );

  const handleBackToMainPage = () => handleChange("main");
  const handleInstanceEditing = (id) => handleChange("edit", id);
  const handleInstanceAddition = (data) => handleChange("create", data);
  const handleViewIntegration = (data) => handleChange("view", data);

  return {
    integrationContext,
    activeComponent,
    setCurrentIntegration,
    setActiveComponent,
    currentIntegration,
    handleBackToMainPage,
    handleInstanceEditing,
    handleInstanceAddition,
    handleViewIntegration,
  };
};

export default useIntegrationManagement;
