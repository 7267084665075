import { gql } from "@apollo/client";
import { UPDATE_STATUS_CACHE } from "../../FindingDetailsPane/Statuses/ChangeStatus/UserStatusApi";

export const GET_FINDINGS = gql`
  ${UPDATE_STATUS_CACHE}
  query GetFindings(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $sort: [FindingsViewSortEnum]
    $filters_config: FiltersConfigInput
    $with_total_count: Boolean!
  ) {
    findings: findings_view(
      first: $first
      after: $after
      before: $before
      last: $last
      sort: $sort
      filters_config: $filters_config
    ) {
      total_count @include(if: $with_total_count)
      edges {
        node {
          id
          id_int
          cloud_provider
          cloud_account
          original_status
          resource_name
          title
          score
          age
          severity
          cloud_account_friendly_name
          ...ActualStatus
          file_prefix
          datasource_id
          datasource {
            datasource_definition {
              id
              file_prefix
              name
            }
          }
          tickets {
            edges {
              node {
                id
                status
                external_status
                external_id
                ticket_endpoint {
                  field_mapping
                }
                ticket_provider {
                  known_identifier
                  type
                }
              }
            }
          }
          owner_email
          owner_name
          seem_tags_values
          datasource_tags_values
          category
          sla_status
          aggregated_findings_count
          open_aggregated_findings_count
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_FINDINGS_COUNT = gql`
  query GetFindingsCount(
    $first: Int
    $after: String
    $before: String
    $last: Int
    $sort: [FindingsViewSortEnum]
    $filters_config: FiltersConfigInput
  ) {
    findings: findings_view(
      first: $first
      after: $after
      before: $before
      last: $last
      sort: $sort
      filters_config: $filters_config
    ) {
      total_count
    }
  }
`;

export const GET_TOTAL_RAW_COUNT = gql`
  query GetRawFindingsCount($filters_config: FiltersConfigInput) {
    findings: findings_view_count(filters_config: $filters_config) {
      total_raw_count
    }
  }
`;

export const DOWNLOAD_FINDINGS = gql`
  mutation DownloadFindings(
    $scopesjson: String
    $filtersjson: String
    $scope_id: String
  ) {
    download_findings(
      scopesjson: $scopesjson
      filtersjson: $filtersjson
      scope_id: $scope_id
    ) {
      url
    }
  }
`;

export const generateGetTotalRawMock = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let queryVars = {
    filters_config: variables?.filters_config,
  };

  let getFindingsRawCountMock = {
    request: {
      query: GET_TOTAL_RAW_COUNT,
      variables: {
        ...queryVars,
      },
    },
  };

  if (error) {
    return {
      ...getFindingsRawCountMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getFindingsRawCountMock,
      result: { data: result },
    };
  }

  return {
    ...getFindingsRawCountMock,
    result: { data: { findings: { total_raw_count: 20 } } },
  };
};
