import React from "react";
import "./LabelIndicator.scss";

const LabelIndicator = ({ className, icon, text }) => {
  return (
    <div className={`label-indicator-wrapper ${className}`}>
      {icon && <i className={`label-indicator-icon seem-icon ${icon}`} />}
      {text && <span className={`label-text`}>{text}</span>}
    </div>
  );
};

export default LabelIndicator;
