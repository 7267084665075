import React, { useContext, useState } from "react";
import TicketManagersAndProviders from "../TicketManagersAndProviders/TicketManagersAndProviders";
import TicketEndpointKeys from "../TicketEndpointKeys/TicketEndpointKeys";
import Button from "../../Forms/Controls/Button/Button";
import {
  ticketEndpointContextEnum,
  ticketEndpointTypeEnum,
} from "../TicketingUtils";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { useFormContext, useWatch } from "react-hook-form";
import "./RmqTicketsettings.scss";
import useKeysObject from "../useKeysObject/useKeysObject";
import RmqTicketSettingsFields from "./RmqTicketSettingsFields/RmqTicketSettingsFields";
import { modeOptions } from "../../RemediationQueues/RemediationQueuesUtils";

const RmqTicketSettings = ({
  endpointId = null,
  providerId = null,
  ticketManagerId = null,
  setGetPropsFromKeyField = () => {},
  setGetPropsFromField = () => {},
  setGetPropsFromTicketManager = () => {},
  getPropsFromTicketManager = () => {},
  disabled = false,
  mode = modeOptions.CREATE,
}) => {
  const methods = useFormContext();
  const { getValues, setValue } = methods;

  const { openModal } = useContext(ModalContext);
  const [keysFinalPhase, setKeysFinalPhase] = useState(false);

  const selectedTicketManager = useWatch({ name: "ticketManager" });
  const selectedTicketProvider = useWatch({
    name: `ticketProvider`,
  });

  const selectedKeys = useWatch({
    name: `keys`,
  });
  const selectedFields = useWatch({ name: "fields" });
  const { keysObject } = useKeysObject(selectedKeys);

  return (
    <div className={"rmq-ticket-settings-wrapper"}>
      <TicketManagersAndProviders
        disabled={mode === modeOptions.EDIT || disabled}
        ticketProviderId={providerId}
        ticketManagerId={ticketManagerId}
        onFinalPhase={setKeysFinalPhase}
        context={ticketEndpointContextEnum.RMQ}
        onGetProps={setGetPropsFromTicketManager}
      />
      <div className={"rmq-ticket-settings-keys"}>
        {!!selectedTicketProvider &&
          getPropsFromTicketManager(selectedTicketManager)?.type !==
            ticketEndpointTypeEnum.INTERNAL && (
            <>
              <TicketEndpointKeys
                disabled={mode === modeOptions.EDIT || disabled}
                endpointId={endpointId}
                keysObject={keysObject}
                onGetProps={setGetPropsFromKeyField}
                onFinalPhase={setKeysFinalPhase}
                keysReady={keysFinalPhase}
                showPlaceholder={false}
              />
              {getPropsFromTicketManager(selectedTicketManager)
                ?.showFieldsMapping && (
                <Button
                  type={"button"}
                  leftIcon={"seem-icon-fix"}
                  label={"Field Mapping"}
                  textLink
                  data-testid={"rmq-field-mapping"}
                  disabled={!keysFinalPhase}
                  onClick={() => {
                    openModal({
                      title: "Field Mapping",
                      size: "l",
                      className: "modal-fields",
                      overlay: true,
                      component: (
                        <RmqTicketSettingsFields
                          setValue={setValue}
                          getValues={getValues}
                          keysObject={keysObject}
                          selectedKeys={selectedKeys}
                          selectedFields={
                            !!selectedFields &&
                            Object.keys(selectedFields)?.length
                              ? selectedFields
                              : null
                          }
                          selectedTicketProvider={selectedTicketProvider}
                          onGetProps={setGetPropsFromField}
                          context={ticketEndpointContextEnum.RMQ}
                          endpointId={endpointId}
                        />
                      ),
                    });
                  }}
                />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default RmqTicketSettings;
