import Popover from "react-popover";
import React, { useState } from "react";
import ScoreTag from "../../../Components/ScoreTag/ScoreTag";
import ClassNames from "classnames";
import "./ScoreSelection.scss";

const scores = [
  {
    score: 1,
    severity: "low",
  },
  {
    score: 2,
    severity: "low",
  },
  {
    score: 3,
    severity: "low",
  },
  {
    score: 4,
    severity: "medium",
  },
  {
    score: 5,
    severity: "medium",
  },
  {
    score: 6,
    severity: "medium",
  },
  {
    score: 7,
    severity: "high",
  },
  {
    score: 8,
    severity: "high",
  },
  {
    score: 9,
    severity: "critical",
  },
  {
    score: 10,
    severity: "critical",
  },
];

const ScoreSelection = ({
  value,
  onChange,
  disabled,
  wrapperClassName,
  inputStyle,
  label,
  labelTop,
  size,
  labelWidth,
  error,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-score",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "is-disabled": disabled,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "has-error": !!error,
    }
  );

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[
        <div className={`score-dropdown-wrap`}>
          {scores?.map((field) => (
            <div
              className={"score-item-wrap"}
              onClick={(e) => {
                onChange(field);
                toggleIsOpen(false);
                e.stopPropagation();
              }}
              title={field.label}
            >
              <div className="score-dropdown-item">
                <ScoreTag score={field?.score} severity={field?.severity} />
              </div>
            </div>
          ))}
        </div>,
      ]}
      enterExitTransitionDurationMs={0}
      onOuterAction={(e) => {
        setTimeout(() => toggleIsOpen(false), 200);
      }}
    >
      <div
        className={classNameWrapper}
        onClick={() => {
          if (!disabled) {
            toggleIsOpen(!isOpen);
          }
        }}
      >
        {label && <label className={`input-label`}>{label}</label>}

        <div className={`input-holder`}>
          <ScoreTag
            score={value?.score}
            severity={value?.severity}
            hideSeverity
            isLarge
          />
          <div className={`dropdown-indicator`}>
            <i className={`seem-icon-dropdown-arrow`} />
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default ScoreSelection;
