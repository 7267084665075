import React, { useState } from "react";
import FormFieldError from "../FormFieldError/FormFieldError";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import "./TextareaAutoSize.scss";
import ReactTooltip from "react-tooltip";
import TextareaAutosize from "react-textarea-autosize";

const TextareaAutoSize = (props) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const {
    value,
    onChange,
    name,
    error,
    wrapperClassName,
    label,
    labelTop,
    size,
    required,
    disabled,
    readOnly,
    password,
    labelWidth,
    icon,
    isInline,
    inputStyle,
    isLarge,
    placeholder,
    onBlur,
    onFocus,
    leftIcon,
    rightIcon,
    tooltipData = {},
    inputRef,
    title,
    "data-testid": dataTestId,
    withHelp,
    rowNumber,
    resize,
  } = props;

  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-text-area",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-required": required,
      "is-disabled": disabled,
      "has-password": password,
      "has-read-only": readOnly,
      "has-left-icon": leftIcon,
      "has-right-icon": rightIcon,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "has-icon": !!icon,
      "is-inline": isInline,
      "is-large": isLarge,
      "has-error": !!error,
      "with-help": withHelp,
      "has-resize": resize,
    },
    props.className
  );

  const onFocusChange = () => {
    if (isFocused) {
      if (onBlur) {
        onBlur();
      }
    } else {
      if (onFocus) {
        onFocus();
      }
    }
    setIsFocused(!isFocused);
  };

  return (
    <div className={classNameWrapper}>
      {label && (
        <label className={`input-label`}>
          {t(label)}
          {withHelp && (
            <>
              <ReactTooltip
                id={`${name}-help`}
                place="top"
                className={"input-help-tooltip"}
              >
                {withHelp}
              </ReactTooltip>
              <i
                data-tip
                data-for={`${name}-help`}
                className={`seem-icon seem-icon-help`}
              />
            </>
          )}
        </label>
      )}

      <div
        className={`input-holder ${isFocused ? "is-focused" : ""}`}
        data-for={tooltipData?.dataFor}
        data-tip={tooltipData?.dataTip}
      >
        {leftIcon && <i className={`seem-icon ${leftIcon}`} />}
        <TextareaAutosize
          style={{ whiteSpace: "preWrap" }}
          title={title}
          data-testid={`${dataTestId}-input`}
          ref={inputRef}
          readOnly={readOnly}
          placeholder={placeholder}
          name={name}
          rows={rowNumber}
          value={value || ""}
          onChange={(e) => {
            onChange(e);
          }}
          onBlur={onFocusChange}
          onFocus={onFocusChange}
          resize={resize}
        />
        {rightIcon && <i className={`seem-icon ${rightIcon}`} />}
      </div>
      {error && (
        <FormFieldError
          errorMessage={error.message}
          errorType={error.type}
          isOpen={!isFocused}
          fieldName={dataTestId}
        />
      )}
    </div>
  );
};

TextareaAutoSize.defaultProps = {
  labelTop: false,
  size: "m",
  required: false,
  disabled: false,
  readOnly: false,
  password: false,
  labelWidth: false,
  icon: "",
  inputStyle: "",
  onChange: () => {},
  placeholder: "",
  onBlur: () => {},
  onFocus: () => {},
  leftIcon: "",
  rightIcon: "",
  min: null,
  withHelp: false,
  rowNumber: 2,
  resize: "none",
};

TextareaAutoSize.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  labelTop: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  password: PropTypes.bool,
  labelWidth: PropTypes.bool,
  icon: PropTypes.string,
  inputStyle: PropTypes.string,
  isInline: PropTypes.bool,
  isLarge: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  rowNumber: PropTypes.number,
  resize: PropTypes.string,
};

export default TextareaAutoSize;
