import { gql } from "@apollo/client";

export const GET_TICKET_MANAGERS = gql`
  query getTicketManagers($providers_query_context: EndpointFieldsContext) {
    ticket_managers(providers_query_context: $providers_query_context) {
      type
      field_mapping_allowed
      endpoint_type
      friendly_name
      providers {
        id
        display
        known_identifier
      }
    }
  }
`;

export const MANUAL_TICKET = gql`
  mutation openTicket(
    $finding_id: String!
    $ticket_provider_id: String!
    $value_mapping: JSONString
    $duedate: String
  ) {
    open_ticket(
      finding_id: $finding_id
      ticket_provider_id: $ticket_provider_id
      value_mapping: $value_mapping
      duedate: $duedate
    ) {
      ticket {
        link
        external_id
      }
    }
  }
`;

export const FIELD_INPUT_FRAGMENT = gql`
  fragment fieldInputFragment on FieldInput {
    display_name
    field_name
    field_input_type
    default_value
    required
    options {
      is_creatable
      values {
        id
        name
      }
    }
    query_data
    field_input_size
  }
`;

export const MAPPER_FIELD_INPUT_FRAGMENT = gql`
  fragment mapperFieldInputFragment on MapperRowField {
    display_name
    field_name
    field_input_type
    default_value
    required
    options {
      is_creatable
      values {
        id
        name
      }
    }
    query_data
    icon
  }
`;

export const GET_TICKET_ENDPOINT_KEYS = gql`
  ${FIELD_INPUT_FRAGMENT}
  query getTicketEndpointKeys(
    $ticket_endpoint_id: String
    $ticket_provider_id: String
    $selected_keys: JSONString
  ) {
    endpoint_keys(
      ticket_endpoint_id: $ticket_endpoint_id
      ticket_provider_id: $ticket_provider_id
      selected_keys: $selected_keys
    ) {
      is_final
      fields_sections {
        name
        rows {
          fields {
            fieldDescendants: descendants
            fieldPrecedents: precedents
            skeleton
            ...fieldInputFragment
          }
        }
      }
    }
  }
`;

export const GET_TICKET_ENDPOINT_FIELDS = gql`
  ${FIELD_INPUT_FRAGMENT}
  ${MAPPER_FIELD_INPUT_FRAGMENT}
  query getTicketEndpointFields(
    $finding_id: String
    $ticket_endpoint_id: String
    $ticket_provider_id: String
    $selected_keys: JSONString
    $endpoint_fields_context: String
  ) {
    endpoint_fields(
      finding_id: $finding_id
      ticket_endpoint_id: $ticket_endpoint_id
      ticket_provider_id: $ticket_provider_id
      selected_keys: $selected_keys
      endpoint_fields_context: $endpoint_fields_context
    ) {
      fields_sections {
        name
        rows {
          fields {
            mapping_data {
              columns {
                field_from {
                  display_name
                  icon
                }
                field_to {
                  display_name
                  icon
                }
              }
              rows {
                field_from {
                  ...mapperFieldInputFragment
                }
                field_to {
                  ...mapperFieldInputFragment
                }
              }
            }
            ...fieldInputFragment
          }
        }
      }
    }
  }
`;

export const GET_QUERY_FIELD = gql`
  query get_query_fields(
    $ticket_provider_id: String!
    $field_name: String!
    $params: JSONString!
  ) {
    get_query_fields(
      ticket_provider_id: $ticket_provider_id
      field_name: $field_name
      params: $params
    ) {
      values {
        id
        name
      }
    }
  }
`;
