import React from "react";
import ContentLoader from "react-content-loader";

const RowLoader = (props) => (
    <>
        <ContentLoader
            speed={2}
            width={'180'}
            height={60}
            viewBox="0 0 180 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="180" height={"24"} />
        </ContentLoader>
        <ContentLoader
            speed={2}
            width={'23%'}
            height={60}
            viewBox="0 0 400 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height={"24"} />
        </ContentLoader>
        <ContentLoader
            speed={2}
            width={'23%'}
            height={60}
            viewBox="0 0 400 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height={"24"} />
        </ContentLoader>
        <ContentLoader
            speed={2}
            width={'23%'}
            height={60}
            viewBox="0 0 400 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height={"24"} />
        </ContentLoader>
        <ContentLoader
            speed={2}
            width={'23%'}
            height={60}
            viewBox="0 0 400 60"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="20" rx="3" ry="3" width="100%" height={"24"} />
        </ContentLoader>
    </>
)
export default RowLoader;