import {
  groupByOptions,
  findingsQueryParams,
  groupByTypes,
} from "../../../FindingsUtils";
import React, { useContext, useEffect, useMemo } from "react";
import SelectionMenu from "../../../../Components/SelectionMenu/SelectionMenu";
import { FindingsContext } from "../../../FindingsContext";
import useFindingsQueryParam from "../../useFindingsQueryParam";
import { AppPreferencesContext } from "../../../../General/AppPreferencesProvider";

const FindingsGroupsSelection = ({ groupBy, updateMenuState }) => {
  const {
    resetFindingsList,
    selectFindingInList,
    findingsState: { hasSelectedItem },
  } = useContext(FindingsContext);
  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);
  const {
    clearQueryParam,
    param: groupByIndexFromURL,
    search,
  } = useFindingsQueryParam(findingsQueryParams.GROUP_INDEX);

  const menuItems = useMemo(() => {
    let menuItems = [...groupByOptions];
    if (slaEnabled) {
      let sla = menuItems.findIndex((option) => option.label === "SLA");
      menuItems[sla] = { ...menuItems[sla], isHidden: false };
    }

    return menuItems;
  }, [slaEnabled]);

  const onGroupSelectionChange = (index, value) => {
    updateMenuState({
      selectedOptionId: index,
      selectedOptionValue: { ...value },
    });
    resetFindingsList();
  };

  useEffect(() => {
    if (!!groupByIndexFromURL) {
      const groupByObj = {
        index: groupByIndexFromURL,
        value: {
          groupBy: groupByOptions[groupByIndexFromURL].value.groupBy,
          dbGroupBy: groupByOptions[groupByIndexFromURL].value.dbGroupBy,
          noData: groupByOptions[groupByIndexFromURL].value.noData,
        },
      };
      onGroupSelectionChange(groupByObj.index, { ...groupByObj.value });
    }
  }, [groupByIndexFromURL]);

  useEffect(() => {
    if (!slaEnabled) {
      if (groupBy?.selectedOptionValue?.groupBy === groupByTypes.SLA_STATUS) {
        const groupByObj = {
          index: 0,
          value: {
            groupBy: groupByOptions[0].value.groupBy,
            dbGroupBy: groupByOptions[0].value.dbGroupBy,
            noData: groupByOptions[0].value.noData,
          },
        };
        onGroupSelectionChange(groupByObj.index, { ...groupByObj.value });
      }
    }
  }, [slaEnabled]);

  return (
    <div className={`group-by-panel`}>
      <SelectionMenu
        type={"secondary"}
        label={"Grouped by:"}
        menuItems={menuItems}
        selectedIndex={groupBy.selectedOptionId}
        onChange={(index, value) => {
          if (!!search) {
            clearQueryParam([
              findingsQueryParams.GROUP_INDEX,
              findingsQueryParams.GROUP_VALUE,
            ]);
          }

          hasSelectedItem && selectFindingInList(null, false);
          onGroupSelectionChange(index, { ...value });
        }}
      />
    </div>
  );
};

export default FindingsGroupsSelection;
