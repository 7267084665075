import NavigationMenu from "./NavigationMenu/NavigationMenu";
import { Element } from "react-scroll";
import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./EntityDetailsPane.scss";
import Header from "./Header/Header";
import Button from "../../Forms/Controls/Button/Button";
import { capitalize } from "lodash";
import { useHistory } from "react-router-dom";
import { useClickAway, useKeyPress } from "react-use";
import { MODAL_CONTAINER_CLASS } from "../../Forms/Dialogs/Modal/Modal";

const EntityDetailsPane = ({
  navigationOptions,
  contentOptions,
  entityId,
  editable,
  headerBottomComponents,
  headerTopComponents,
  closeDetailsPane,
  isFullScreen,
  entityClass,
  entityTitle,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const detailsPaneWrapperRef = useRef();

  useClickAway(
    detailsPaneWrapperRef,
    () => {
      const modalIsVisible = !!document.getElementsByClassName(
        MODAL_CONTAINER_CLASS
      )?.length;
      if (!modalIsVisible && !isFullScreen) {
        closeDetailsPane();
      }
    },
    ["click"]
  );

  useKeyPress((event) => {
    const modalIsVisible = !!document.getElementsByClassName(
      MODAL_CONTAINER_CLASS
    )?.length;
    if (event?.key === "Escape" && !modalIsVisible && !isFullScreen) {
      closeDetailsPane();
    }
    return true;
  });

  return (
    <>
      {isFullScreen && (
        <div className={`details-pane-back-to`}>
          <Button
            data-testid={"back-to-btn"}
            type={"button"}
            leftIcon={"seem-icon-left-arrow"}
            label={`Back To ${capitalize(entityClass)}`}
            textLink
            onClick={() => history.push(`/${entityClass}`)}
          />
        </div>
      )}
      <div
        className={`entity-details-pane-wrapper ${
          isFullScreen ? "is-full-screen" : ""
        }`}
        ref={detailsPaneWrapperRef}
      >
        <Header
          id={entityId}
          title={entityTitle}
          editable={editable}
          type={entityClass}
          closePane={closeDetailsPane}
          isFullScreen={isFullScreen}
          headerBottomComponents={headerBottomComponents}
          headerTopComponents={headerTopComponents}
        />
        <NavigationMenu menuItems={navigationOptions} />
        <Element
          name="details-pane-data-container"
          className="details-pane-data-container"
          id="details-pane-data-container"
          style={{
            position: "relative",
            overflow: "scroll",
          }}
        >
          {contentOptions.map(
            (component, index) =>
              navigationOptions[index].hasContent && (
                <Fragment key={index}>
                  <Element
                    name={navigationOptions[index].to}
                    className={"details-pane-section"}
                  >
                    <h3 className={`details-pane-section-title`}>
                      {t(navigationOptions[index].title)}
                    </h3>
                    {component}
                  </Element>
                </Fragment>
              )
          )}
        </Element>
      </div>
    </>
  );
};

export default EntityDetailsPane;
