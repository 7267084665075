import React, { useState } from "react";
import TruncateMarkup from "react-truncate-markup";

const ReadMore = ({ children, lines, more, less }) => {
  const [shouldTruncate, setShouldTruncate] = useState(true);

  const toggleTruncate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShouldTruncate(!shouldTruncate);
  };

  const readMoreEllipsis = (
    <span>
      <a style={{ textDecoration: "none" }} href="#" onClick={toggleTruncate}>
        {" "}
        {more}
      </a>
    </span>
  );

  const shortContent = (
    <TruncateMarkup lines={lines} ellipsis={readMoreEllipsis}>
      <div>{children}</div>
    </TruncateMarkup>
  );

  const fullContent = (
    <div>
      {children}{" "}
      <span>
        <a style={{ textDecoration: "none" }} href="#" onClick={toggleTruncate}>
          {less}
        </a>
      </span>
    </div>
  );

  return shouldTruncate ? shortContent : fullContent;
};

export default ReadMore;
