import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectInput from "../../../../Forms/Controls/SelectInput/SelectInput";
import { startCase } from "lodash";
import { useQuery } from "@apollo/client";
import { GET_FIELD_VALUES } from "../../ManualFindingsApi";
import { getFieldValueOptions } from "../../../FindingsFilters/FilterRow/FilterRowUtils";

const FilterDataField = ({
  field,
  label,
  required = false,
  isCreateable = false,
}) => {
  const methods = useFormContext();
  const {
    formState: { errors },
    control,
  } = methods;

  const [valueSearchInput, setValueSearchInput] = useState("");

  const { data: fieldOptions } = useQuery(GET_FIELD_VALUES, {
    variables: { filterName: field, contains: valueSearchInput, limit: 10 },
    skip: !field,
  });

  const getLabelText = (field, required) => {
    let text = startCase(field);
    if (!required) {
      text += " (Optional)";
    }

    return text;
  };

  let fieldRules = {};
  if (required) {
    fieldRules.required = "Required";
  }

  return (
    <Controller
      wrapperClassName={``}
      name={field}
      defaultValue={null}
      render={({ field: { onChange, value, onBlur, onFocus } }) => {
        let valueOptions = [];

        valueOptions =
          getFieldValueOptions(
            field,
            fieldOptions?.finding_filter_data?.values
          ) || [];

        return !!field ? (
          <SelectInput
            data-testid={`${field}`}
            isCreateable={isCreateable}
            onInputChange={(value) => setValueSearchInput(value)}
            inputValue={valueSearchInput}
            isSearchable={true}
            name={field}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(selectedValue) => onChange(selectedValue)}
            labelTop
            label={label || getLabelText(field, required)}
            className={`create-finding-${field}-field`}
            size="max"
            placeholder={`Select ${startCase(field)}`}
            options={valueOptions}
            value={value}
            SelectInputStyle={"bomba-style"}
            defaultValue={null}
            error={errors && errors[field] ? errors[field] : null}
          />
        ) : null;
      }}
      rules={{ ...fieldRules }}
      control={control}
    />
  );
};

export default FilterDataField;
