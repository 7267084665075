import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";

const FilterSaveAs = ({ setValue }) => {
  const { closeModal } = useContext(ModalContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      saveAsFilterName: "",
    },
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    setValue("saveAsFilterName", data?.saveAsFilterName);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-content-top filter-save-as">
        <Controller
          name="saveAsFilterName"
          render={({ field: { onChange, onFocus, onBlur, value, ref } }) => (
            <TextInput
              onBlur={onBlur}
              onFocus={onFocus}
              label={"Name"}
              labelTop
              inputStyle={"bomba-style"}
              data-testid={"scope-name"}
              size="max"
              onChange={(e) => {
                onChange(e);
              }}
              inputRef={ref}
              placeholder={"Filter Name"}
              value={value}
              error={
                errors && errors.saveAsFilterName
                  ? errors.saveAsFilterName
                  : null
              }
            />
          )}
          control={control}
          rules={{ required: "Required" }}
          defaultValue={""}
        />

        <ModalButtonsContainer>
          <>
            <Button
              type="button"
              label={"Cancel"}
              onClick={() => {
                closeModal();
              }}
            />
            <Button
              data-testid={"save-new-scope"}
              type="submit"
              label={"Save"}
              isSecondary
            />
          </>
        </ModalButtonsContainer>
      </div>
    </form>
  );
};

export default FilterSaveAs;
