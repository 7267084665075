import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import RadioButtonsList from "../../Forms/Controls/RadioButtonsList/RadioButtonsList";
import { dataSourceIcons } from "../../Findings/FindingsUtils";
import { useQuery } from "@apollo/client";
import { GET_TICKET_MANAGERS } from "../TicketingAPI";
import GenericField, { fieldTypeOptions } from "../GenericField/GenericField";
import "./TicketManagersAndProviders.scss";
import {
  ticketEndpointContextEnum,
  ticketManagersEnum,
} from "../TicketingUtils";
import ReactTooltip from "react-tooltip";

const TicketManagersAndProviders = ({
  context,
  onFinalPhase,
  ticketManagerId,
  ticketProviderId,
  disabled = false,
  onGetProps = () => {},
}) => {
  const { control, reset: formReset, getValues } = useFormContext();
  const [ticketManagers, setTicketManagers] = useState(null);

  const { data: ticketProvidersData, error: ticketProvidersError } = useQuery(
    GET_TICKET_MANAGERS,
    {
      variables: {
        providers_query_context: context,
      },
    }
  );

  useEffect(() => {
    if (!!ticketProvidersData) {
      const ticketManagersArray = ticketProvidersData?.ticket_managers?.map(
        (ticketManager) => ({
          name: ticketManager?.type,
          label: "",
          value: ticketManager?.type,
          friendlyName: ticketManager?.friendly_name,
          showFieldsMapping: ticketManager?.field_mapping_allowed,
          type: ticketManager?.endpoint_type,
          leftImg: dataSourceIcons[ticketManager?.type]?.L,
          providers: ticketManager?.providers?.map((ticketProvider) => ({
            name: ticketProvider?.display,
            id: ticketProvider?.id,
          })),
        })
      );

      const hasSlack = ticketManagersArray?.findIndex(
        (ticketManager) => ticketManager?.value === ticketManagersEnum.SLACK
      );
      if (hasSlack === -1 && context === ticketEndpointContextEnum.RMQ) {
        ticketManagersArray?.push({
          name: ticketManagersEnum.SLACK,
          label: "",
          value: ticketManagersEnum.SLACK,
          friendlyName: "Slack",
          showFieldsMapping: false,
          leftImg: dataSourceIcons[ticketManagersEnum.SLACK]?.L,
          providers: [],
          disabled: true,
          tooltipData: {
            dataFor: `integrate-slack-tooltip`,
            dataTip: true,
          },
        });
      }

      setTicketManagers(ticketManagersArray);
    }
  }, [ticketProvidersData]);

  const selectedTicketManager = useWatch({
    control,
    name: "ticketManager",
  });

  useEffect(() => {
    if (!!ticketManagerId && !!ticketProviderId && ticketManagers) {
      formReset({
        ...getValues(),
        ticketManager: ticketManagerId,
        ticketProvider: ticketProviderId,
      });
    }
  }, [ticketManagerId, ticketProviderId, ticketManagers]);

  const getTicketManagerPropsByFieldName = useCallback(
    (name) => {
      let target;
      ticketManagers?.some((ticketManager) => {
        if (ticketManager.name === name) {
          target = ticketManager;
          return true;
        }

        return false;
      });

      return target;
    },
    [ticketManagers]
  );

  useEffect(() => {
    if (!!getTicketManagerPropsByFieldName) {
      onGetProps(() => getTicketManagerPropsByFieldName);
    }
  }, [getTicketManagerPropsByFieldName]);

  return !!ticketManagers && ticketManagers.length ? (
    <>
      <ReactTooltip
        id="integrate-slack-tooltip"
        place="top"
        className={"integrate-slack-tooltip"}
        delayHide={1000}
        effect="solid"
      >
        <div
          onClick={() =>
            window.open("/settings/integrations/ticketingSystems", "_blank")
          }
        >
          To enable Slack,{" "}
          <span className={`integrate-slack-link`}>click here</span>
        </div>
      </ReactTooltip>
      <Controller
        name={"ticketManager"}
        render={({ field: { value, onChange } }) => {
          return (
            <RadioButtonsList
              data-testid={"ticket-managers"}
              value={value}
              disabled={disabled}
              onChange={(val) => {
                onChange(val);
                formReset({
                  ...getValues(),
                  ticketManager: val,
                  ticketProvider: null,
                  keys: {},
                  fields: {},
                });
                onFinalPhase(false);
              }}
              label={""}
              options={ticketManagers}
            />
          );
        }}
        control={control}
      />
      {!!selectedTicketManager &&
        selectedTicketManager !== ticketManagersEnum.SEEMPLICITY && (
          <Controller
            name={`ticketProvider`}
            render={({ field: { onChange, onBlur, value, onFocus } }) => {
              return (
                <GenericField
                  onChange={(val) => {
                    onChange(val);
                    formReset({
                      ...getValues(),
                      ticketProvider: val,
                      keys: {},
                      fields: {},
                    });
                    onFinalPhase(false);
                  }}
                  name={"ticketProvider"}
                  onBlur={onBlur}
                  clearable={false}
                  value={value}
                  size={"LARGE"}
                  disabled={!selectedTicketManager || disabled}
                  onFocus={onFocus}
                  searchable={true}
                  input={fieldTypeOptions.SELECT}
                  label={"Provider"}
                  options={
                    ticketManagers?.find(
                      (ticketManager) =>
                        ticketManager?.name === selectedTicketManager
                    )?.providers || []
                  }
                  placeholder={"Choose Provider"}
                />
              );
            }}
            control={control}
          />
        )}
    </>
  ) : !!ticketManagers && !ticketManagers.length ? (
    <div>no data</div>
  ) : ticketProvidersError ? (
    <div>error in getting ticket managers</div>
  ) : (
    <></>
  );
};

export default TicketManagersAndProviders;
