import FormFieldError from "../FormFieldError/FormFieldError";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./RadioButton.scss";
import ClassNames from "classnames";
import TextInput from "../TextInput/TextInput";
import { CUSTOM_EMPTY } from "../RadioButtonsList/RadioButtonsList";
import DateInput from "../DateInput/DateInput";

const RadioButton = ({
  checked,
  label,
  error,
  className,
  name,
  onChange,
  value,
  disabled,
  leftImg,
  labelTop,
  required,
  readOnly,
  size,
  labelWidth,
  customOption,
  selectedValue,
  "data-testid": dataTestId,
  tooltipData = {},
}) => {
  const classNameWrapper = ClassNames(
    "input-wrap",
    "radio-button-wrap",
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-required": required,
      "is-disabled": disabled,
      "is-custom": !!customOption,
      "has-read-only": readOnly,
      "has-left-img": leftImg,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "has-error": !!error,
    },
    className
  );

  const [customValue, setCustomValue] = useState(
    checked ? selectedValue : null
  );

  useEffect(() => {
    if (selectedValue === CUSTOM_EMPTY) {
      customOption?.onError();
    }
  }, [selectedValue]);

  const handleChange = (radioValue) => {
    if (radioValue === "custom") {
      onChange(customValue || CUSTOM_EMPTY);
    } else {
      onChange(radioValue);
    }
  };

  const getCustomOption = () => {
    const customValueProps = {};
    customValueProps.onChange = (e) => {
      setCustomValue(e?.target?.value);
      handleChange(e?.target?.value);
    };
    customValueProps["data-testid"] = `radio-btn-custom-input`;
    customValueProps.value = customValue;

    switch (customOption?.component) {
      case "TEXT": {
        return (
          <TextInput
            {...customValueProps}
            type={customOption?.type}
            placeholder={customOption?.placeholder}
          />
        );
      }
      case "DATE": {
        return (
          <DateInput {...customValueProps} minDate={customOption?.minDate} />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div
      className={`radio-button-input-wrap ${classNameWrapper}`}
      data-for={tooltipData?.dataFor}
      data-tip={tooltipData?.dataTip}
    >
      <input
        data-testid={`${dataTestId}-checkbox`}
        className="radio-input"
        id={name}
        name={name}
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={() => handleChange(value)}
      />
      {leftImg && (
        <label
          htmlFor={name}
          className={`selection-img ${disabled ? "is-disabled" : ""}`}
          onClick={() => {
            if (!disabled) {
              handleChange(value);
            }
          }}
        >
          <img src={leftImg} height="20" alt={name} />
        </label>
      )}
      {customOption && (
        <label
          htmlFor={name}
          className={`option-item ${customOption?.className} ${
            disabled ? "is-disabled" : ""
          }`}
        >
          {getCustomOption()}
        </label>
      )}
      {label && (
        <label
          htmlFor={name}
          className="radio-button-label"
          onClick={() => {
            if (!disabled) {
              handleChange(value);
            }
          }}
        >
          {label}
        </label>
      )}
      {error && (
        <FormFieldError errorMessage={error.message} errorType={error.type} />
      )}
    </div>
  );
};

RadioButton.defaultProps = {
  "data-testid": "radio-button",
  checked: false,
  label: "",
  onChange: () => {},
  wrapperClassName: "",
  customOption: null,
};

RadioButton.propTypes = {
  "data-testid": PropTypes.string,
  wrapperClassName: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  labelWidth: PropTypes.string,
  customOption: PropTypes.object,
};

export default RadioButton;
