import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TREND } from "./TrendsApi";

const useTrend = ({ variables, skip = false }) => {
  const [trendData, setTrendData] = useState(null);

  const {
    data,
    error: trendDataError,
    loading: trendLoading,
  } = useQuery(GET_TREND, { variables: { ...variables }, skip: skip });

  useEffect(() => {
    if (!!data && !trendLoading) {
      let parsedData = JSON.parse(data?.trends);
      setTrendData(parsedData);
    }
  }, [data, trendLoading]);

  return { trendData, trendDataError, trendLoading };
};

export default useTrend;
