import {
  AWS,
  AWSLong,
  SAP,
  SAPLong,
  GIT,
  GITLong,
  GCP,
  GCPLong,
  AZURE,
  AZURELong,
  ORCA,
  ORCALong,
  WIZ,
  WIZLong,
  BCW,
  BCWLong,
  SNYK,
  SNYKLong,
  AQUA,
  AQUALong,
  AXONIUS,
  AXONIUSLong,
  DOME9,
  DOME9Long,
  CHECKMARX,
  CHECKMARXLong,
  RAPID7,
  RAPID7Long,
  JIRA,
  JIRALong,
  SLACK,
  SLACKLong,
  FIBERY,
  FIBERYLong,
  MONDAY,
  MONDAYLong,
  NTTGN,
  NTTGNLong,
  ACR,
  ACRLong,
  GITLAB,
  GITLABLong,
  ECR,
  ECRLong,
  DOCKER,
  DOCKERLong,
  BITBUCKET,
  BITBUCKETLong,
  SFB,
  SFBLong,
  Datacenter,
  DATACENTERLong,
  ERMETIC,
  ERMETICLong,
  MAN,
  MANLong,
  MONITOR,
  ARMS,
  ARMSLong,
  TEN,
  TENLong,
  SERVICENOWLong,
  SERVICENOW,
  WSO,
  WSOLong,
  Cloud,
  CodeRepository,
  HACKER1,
  TRUFFELHOG,
  TRUFFELHOGLong,
  SEMGREP,
  SEMGREPLong,
  HACKER1Long,
  FOSSA,
  BOX,
  SALESFORCE,
  TEAMS,
  ZSCALER,
  OFFICE365,
  SNOWFLAKE,
  DEFENDER,
  DEFENDERLong,
  CONFLUENCE,
  SHAREPOINT,
  INTUNE,
  DOCUSIGN,
  SMARTSHEET,
  ZOOM,
  ADAPTIVESHIELD,
  ADAPTIVESHIELDLong,
  FOSSALong,
  CROWDSTRIKE,
  CROWDSTRIKELong,
  SEEMPLICITYLong,
  SEEMPLICITY,
  INTRUDER,
  INTRUDERLong,
  CIDER,
  CIDERLong,
  GOOGLE,
  AUTH0,
  OKTA,
  ZENDESK,
  ZENDESKLong,
  CONNECTWISE,
  CONNECTWISELong,
  QUALYS,
  QUALYSLong,
  WHITEHAT,
  WHITEHATLong,
  BUGCROWD,
  BUGCROWDLong,
  KUBERNETES,
} from "../icons/DataSourceIcons/DataSourceIcons";
import { SLA } from "../Dashboard/SLADashboard/SLAUtils";

export const cloudProviderIcons = {
  aws: AWS,
  sap: SAP,
  code_repository: CodeRepository,
  gcp: GCP,
  azure: AZURE,
  ecr: ECR,
  acr: ACR,
  github: GIT,
  gitlab: GITLAB,
  git_lab: GITLAB,
  datacenter: Datacenter,
  docker_hub: DOCKER,
  bitbucket_cloud: BITBUCKET,
  desktop_management: MONITOR,
  manual: Cloud,
  external: Cloud,
  other: CodeRepository,
  bitbucket: BITBUCKET,
  jira: JIRA,
  box: BOX,
  salesforce: SALESFORCE,
  slack: SLACK,
  teams: TEAMS,
  zscaler: ZSCALER,
  office_365: OFFICE365,
  snowflake: SNOWFLAKE,
  confluence: CONFLUENCE,
  service_now: SERVICENOW,
  share_point_one_drive: SHAREPOINT,
  intune: INTUNE,
  kubernetes: KUBERNETES,
  zoom: ZOOM,
  docu_sign: DOCUSIGN,
  smartsheet: SMARTSHEET,
};

export const cloudProviderColors = {
  aws: "#fc9c04",
  sap: "#354a5f",
  gcp: "#eb4334",
  azure: "#003399",
  ecr: "#d45a06",
  acr: "#cacbcc",
  docker_hub: "#7cb4fc",
  github: "#181414",
  gitlab: "#ff7828",
  datacenter: "#208cf4",
  bitbucket: "#0658d2",
  bitbucket_cloud: "#0658d2",
};

export const dataSourceIcons = {
  GHB: { S: GIT, L: GITLong, name: "GitHub" },
  TRFL: { S: TRUFFELHOG, L: TRUFFELHOGLong, name: "TruffleHog" },
  SEM: { S: SEMGREP, L: SEMGREPLong, name: "" },
  SAP: { S: SAP, L: SAPLong, name: "" },
  ORC: { S: ORCA, L: ORCALong, name: "" },
  SNK: { S: SNYK, L: SNYKLong, name: "" },
  GCP: { S: GCP, L: GCPLong, name: "GCP Security Command Center" },
  BCW: { S: BCW, L: BCWLong, name: "" },
  AWS: { S: AWS, L: AWSLong, name: "AWS Security Hub" },
  AXN: { S: AXONIUS, L: AXONIUSLong, name: "" },
  AZR: { S: AZURE, L: AZURELong, name: "" },
  WIZ: { S: WIZ, L: WIZLong, name: "" },
  WIZ_VULNERABILITIES: { S: WIZ, L: WIZLong, name: "" },
  DO9: { S: DOME9, L: DOME9Long, name: "" },
  CMX: { S: CHECKMARX, L: CHECKMARXLong, name: "Checkmarx AST" },
  CXG: { S: CHECKMARX, L: CHECKMARXLong, name: "Checkmarx GO" },
  CXAST: { S: CHECKMARX, L: CHECKMARXLong, name: "" },
  CX_AST: { S: CHECKMARX, L: CHECKMARXLong, name: "" },
  CSPL: { S: AQUA, L: AQUALong, name: "" },
  RPD7: { S: RAPID7, L: RAPID7Long, name: "" },
  RP7RF: { S: RAPID7, L: RAPID7Long, name: "" },
  JIRA: { S: JIRA, L: JIRALong, name: "" },
  SLACK: { S: SLACK, L: SLACKLong, name: "" },
  MONDAY: { S: MONDAY, L: MONDAYLong, name: "" },
  SERVICENOW: { S: SERVICENOW, L: SERVICENOWLong, name: "" },
  FIBERY: { S: FIBERY, L: FIBERYLong, name: "" },
  NTTGN: { S: NTTGN, L: NTTGNLong, name: "" },
  ZENDESK: { S: ZENDESK, L: ZENDESKLong, name: "" },
  CONNECTWISE: { S: CONNECTWISE, L: CONNECTWISELong, name: "" },
  ACR: { S: ACR, L: ACRLong, name: "" },
  QLS: { S: QUALYS, L: QUALYSLong, name: "Qualys" },
  GITLAB: { S: GITLAB, L: GITLABLong, name: "GitLab" },
  ECR: { S: ECR, L: ECRLong, name: "" },
  DOCKER: { S: DOCKER, L: DOCKERLong, name: "" },
  BITBUCKET: { S: BITBUCKET, L: BITBUCKETLong, name: "" },
  SFB: { S: SFB, L: SFBLong, name: "SafeBreach" },
  MSDE: { S: DEFENDER, L: DEFENDERLong, name: "Microsoft Defender" },
  Datacenter: { S: Datacenter, L: DATACENTERLong, name: "" },
  ERM: { S: ERMETIC, L: ERMETICLong, name: "" },
  MAN: { S: MAN, L: MANLong, name: "" },
  ARMS: { S: ARMS, L: ARMSLong, name: "" },
  TEN: { S: TEN, L: TENLong, name: "" },
  TENSC: { S: TEN, L: TENLong, name: "" },
  WSO: { S: WSO, L: WSOLong, name: "" },
  SPC: { S: AWS, L: AWSLong, name: "" },
  HACKER1: { S: HACKER1, L: HACKER1Long, name: "" },
  GTLB: { S: GITLAB, L: GITLABLong, name: "GitLab" },
  FOS: { S: FOSSA, L: FOSSALong, name: "" },
  ADPSH: { S: ADAPTIVESHIELD, L: ADAPTIVESHIELDLong, name: "" },
  CRWD: { S: CROWDSTRIKE, L: CROWDSTRIKELong, name: "" },
  SEEMPLICITY: { S: SEEMPLICITY, L: SEEMPLICITYLong, name: "" },
  INTRDR: { S: INTRUDER, L: INTRUDERLong, name: "" },
  CIDER: { S: CIDER, L: CIDERLong, name: "" },
  GOOGLE: { S: GOOGLE, L: GOOGLE, name: "" },
  AUTH0: { S: AUTH0, L: AUTH0, name: "" },
  OKTA: { S: OKTA, L: OKTA, name: "" },
  WHATS: { S: WHITEHAT, L: WHITEHATLong, name: "WhiteHat" },
  BUGC: { S: BUGCROWD, L: BUGCROWDLong, name: "Bugcrowd" },
};

export const FINDING_ROW_HEIGHT = 52;
export const FINDING_TITLE_ROW_HEIGHT = 52;
export const NAVIGATION_ITEMS_PAGE_SIZE = 4;
export const NAVIGATION_ITEMS_PADDING = 8;
export const NAVIGATION_ITEM_WIDTH = 158;
export const FINDINGS_PAGE_SIZE = 100;
export const sortByOptions = [
  {
    label: "Score and Age",
    value: ["SCORE_DESC", "AGE_DESC", "ID_DESC"],
  },
  {
    label: "Age and Score",
    value: ["AGE_DESC", "SCORE_DESC", "ID_DESC"],
  },
  {
    label: "SLA",
    value: ["SLA_STATUS_DESC", "SCORE_DESC", "AGE_DESC", "ID_DESC"],
  },
];

export const groupByTypes = {
  SEVERITY: "severity",
  CLOUD_ACCOUNT: "cloud_account_friendly_name",
  CLOUD_PROVIDER: "cloud_provider",
  DATASOURCE: "file_prefix",
  CATEGORY: "category",
  SLA_STATUS: "sla_status",
};

export const groupByOptions = [
  {
    label: "Severity",
    value: {
      groupBy: groupByTypes.SEVERITY,
      dbGroupBy: groupByTypes.SEVERITY,
      noData: "Missing Severity",
    },
  },
  {
    label: "Account",
    value: {
      groupBy: groupByTypes.CLOUD_ACCOUNT,
      dbGroupBy: [groupByTypes.CLOUD_ACCOUNT, groupByTypes.CLOUD_PROVIDER],
      noData: "Missing Cloud Account",
    },
  },
  {
    label: "Cloud",
    value: {
      groupBy: groupByTypes.CLOUD_PROVIDER,
      dbGroupBy: groupByTypes.CLOUD_PROVIDER,
      noData: "Missing Cloud Provider",
    },
  },
  {
    label: "Datasource",
    value: {
      groupBy: groupByTypes.DATASOURCE,
      dbGroupBy: groupByTypes.DATASOURCE,
      noData: "Missing Datasource",
    },
  },
  {
    label: "Category",
    value: {
      groupBy: groupByTypes.CATEGORY,
      dbGroupBy: groupByTypes.CATEGORY,
      noData: "Missing Category",
    },
  },
  {
    label: "SLA",
    value: {
      groupBy: groupByTypes.SLA_STATUS,
      dbGroupBy: groupByTypes.SLA_STATUS,
      noData: "Missing SLA",
    },
    isHidden: true,
  },
];

export const findingsQueryParams = {
  FILTER_ID: "filterId",
  SCOPE_ID: "scopeId",
  GROUP_INDEX: "groupByIndex",
  GROUP_VALUE: "groupByValue",
  FILTER_TO_EXECUTE: "filterToExecute",
};

export const findingStatuses = {
  11: "Open",
  12: "Open",
  13: "Open",
  14: "Open",
  15: "Reviewed",
  21: "Fixed",
  22: "Fixed",
  23: "Fixed",
  24: "Fixed",
  25: "Fixed",
  26: "Fixed",
  31: "Ignored",
  32: "Ignored",
  33: "Ignored",
};

export const slaStatuses = {
  WITHIN_SLA: {
    title: "In Time",
    icon: "seem-icon-in-time",
  },
  BROKEN_SLA: {
    title: "Overdue",
    icon: "seem-icon-overdue",
  },
  ALMOST_BROKEN_SLA: {
    title: "Due Soon",
    icon: "seem-icon-in-time",
  },
  NA: {
    title: "No Due Date",
    icon: "",
  },
};

export const aggregatedStatus = {
  OPEN: ["11", "12", "13", "14"],
  OPEN_CONFIRMED: ["15"],
  FIXED: ["21", "22", "23", "24", "25", "26"],
  IGNORED: ["32"],
  CLOSED: ["21", "22", "23", "24", "25", "26", "31", "32", "33"],
  FALSE_POSITIVE: ["31"],
  EXCEPTION: ["33"],
};

export const findingLastStatusValues = [
  { label: "Open", value: aggregatedStatus.OPEN },
  { label: "Reviewed", value: aggregatedStatus.OPEN_CONFIRMED },
  { label: "Fixed", value: aggregatedStatus.FIXED },
  { label: "Ignored", value: aggregatedStatus.IGNORED },
  { label: "Ignored - False Positive", value: aggregatedStatus.FALSE_POSITIVE },
  { label: "Ignored - Exception", value: aggregatedStatus.EXCEPTION },
];

export const findingSeverities = {
  0: "Low",
  1: "Medium",
  2: "High",
  3: "Critical",
};

export const filterTypes = {
  FILTER: "FILTER",
  SCOPE: "SCOPE",
  NONE: "NONE",
};

export const findingsRowType = {
  GROUP: "GROUP",
};

export const findingActions = {
  SNOOZE: "snooze",
  RESOLVED: "resolved",
  IGNORE: "ignore",
  COMMENT: "comment",
  JIRA: "jira",
};

export const getFindingSeverity = (severity) => {
  switch (severity) {
    case 0:
    case "0":
      return "low";

    case 1:
    case "1":
      return "medium";

    case 2:
    case "2":
      return "high";

    case 3:
    case "3":
      return "critical";

    default:
      return "low";
  }
};

export const getSeverityValueByDescription = (severityDescription) => {
  switch (severityDescription.toLowerCase()) {
    case "low":
      return "0";
    case "medium":
      return "1";
    case "high":
      return "2";
    case "critical":
      return "3";
  }
};

export const getNameByFilterToExecute = (filterToExecute) => {
  const jsonFilterToExecute = JSON.parse(filterToExecute);
  for (let i = 0; i < jsonFilterToExecute.operands.length; i += 1) {
    if (
      jsonFilterToExecute.operands[i].field === "source" ||
      jsonFilterToExecute.operands[i].field === "ticket_status" ||
      jsonFilterToExecute.operands[i].field === "category" ||
      jsonFilterToExecute.operands[i].field === "tags_values"
    ) {
      return jsonFilterToExecute.operands[i].value[0];
    } else if (jsonFilterToExecute.operands[i].field === "sla_status") {
      return SLA?.[jsonFilterToExecute.operands[i].value[0]]?.title;
    }
  }
};

export const getDisplayName = (username) => {
  return username.substr(0, username.lastIndexOf("@")).replace(/\./g, " ");
};

export const getGroupName = (groupByObject) => {
  let groupByKey;
  if (groupByObject.group_by.length > 1) {
    groupByKey = [];
  }
  groupByObject.group_by.map((el) => {
    if (groupByObject.group_by.length > 1) {
      groupByKey.push(el.value);
    } else {
      groupByKey = el.value?.toString();
    }
  });

  if (Array.isArray(groupByKey)) {
    return JSON.stringify(groupByKey);
  }

  return groupByKey;
};

export const getGroupTitle = (groupByObject) => {
  if (groupByObject.group_by.length > 1) {
    return JSON.parse(getGroupName(groupByObject))[0];
  }

  return getGroupName(groupByObject);
};

export const listScrollDirection = {
  BACK: "backward",
  FORWARD: "forward",
};

export const findingsColumns = {
  ACCOUNT: "account",
  TITLE: "title",
  SEVERITY: "severity",
  TICKET_STATUS: "ticket_status",
  STATUS: "status",
  RESOURCES: "resources",
  TAGS: "tags",
  TICKET_ID: "ticket_id",
  SLA_STATUS: "sla_status",
  SELECT: "select",
};

export const findingsVisibleColumns = [
  findingsColumns.ACCOUNT,
  findingsColumns.TITLE,
  findingsColumns.SEVERITY,
  findingsColumns.TICKET_STATUS,
  findingsColumns.STATUS,
  findingsColumns.RESOURCES,
  findingsColumns.TAGS,
  findingsColumns.SLA_STATUS,
];

export const resourceTypes = {
  SCOPE: "scope",
  GROUP: "group",
  ROOT: "root",
};

export const manualFindingModes = {
  CREATE: "create",
  EDIT: "edit",
};

export const entityTypes = {
  FINDING: "finding",
  RESOURCE: "resource",
};

export const manualFindingCsvFieldsEnum = {
  TITLE: {
    fieldName: "title",
    text: "title (required - Short description of the finding)",
  },
  DESCRIPTION: {
    fieldName: "description",
    text: "description (Describe the finding/vulnerability)",
  },
  ORIGINAL_SCORE: {
    fieldName: "original_score",
    text: "original_score (required - number from 1 to 10)",
  },
  DISCOVERED_TIME: {
    fieldName: "discovered_time",
    text: "discovered_time (YYYY-MM-DD)",
  },
  REMEDIATION_TEXT: {
    fieldName: "remediation text",
    text: "remediation text (Explanation how to fix it issue- if any)",
  },
  REMEDIATION_URL: { fieldName: "remediation url", text: "remediation url" },
  RESOURCE_NAME: {
    fieldName: "resource name",
    text: "resource_name (required - User \\ Machine \\ Repo etc.. the issue was found in..)",
  },
  RESOURCE_TYPE: {
    fieldName: "resource type",
    text: "resource_type (EC2, API Gateway, Bucket,...)",
  },
  CLOUD_ACCOUNT: {
    fieldName: "cloud account",
    text: "cloud_account (Cloud account name)",
  },
};

export const csvTemplateForManualFindings = [
  Object.values(manualFindingCsvFieldsEnum).map((field) => field.text),
  ["Start entering values in this line"],
];

export const findingsCheckType = {
  ALL: "ALL",
  MULTI: "MULTI",
  SINGLE: "SINGLE",
};
