import React from "react";
import "./Comments.scss";
import CommentsList from "./CommentsList/CommentsList";
import NewComment from "./NewComment/NewComment";

const Comments = () => {
  return (
    <div className={`add-comment-container`}>
      <CommentsList />
      <NewComment />
    </div>
  );
};

export default Comments;
