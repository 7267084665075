import React from "react";

const ModalButtonsContainer = ({ children }) => {
  return (
    <div className={`form-button-group modal-buttons-container`}>
      {children}
    </div>
  );
};

export default ModalButtonsContainer;
