import Popover from "react-popover";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  ageFields,
  getFieldValueOptions,
} from "../../../../../Findings/FindingsFilters/FilterRow/FilterRowUtils";
import "./FilterValue.scss";
import { findingLastStatusValues } from "../../../../../Findings/FindingsUtils";
import FilterValueSelection from "./FilterValueSelection/FilterValueSelection";
import ClassNames from "classnames";
import Button from "../../../../../Forms/Controls/Button/Button";

const MAX_VALUES_LENGTH = 2;
const FilterValue = ({
  namePrefix,
  onChange,
  value,
  previewFilter,
  itemIndex,
  removeFilter,
  isEditable,
  fieldsData,
  onBlur,
  rowIndex,
  removeRow,
  setMenuOpen,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const { control } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: `${namePrefix}.field`,
  });

  const conditionValue = useWatch({
    control,
    name: `${namePrefix}.condition`,
  });

  const filterPanel = useWatch({
    control,
    name: `filterPanels`,
  });

  useEffect(() => {
    if (!!conditionValue && !value) {
      toggleIsOpen(true);
    }
  }, [conditionValue, value]);

  useEffect(() => {
    if (!!setMenuOpen) {
      setMenuOpen({ value: isOpen });
    }
  }, [isOpen]);

  const conditionWithValuesList = useMemo(() => {
    return (
      conditionValue === "in" ||
      conditionValue === "not_in" ||
      conditionValue === "overlap"
    );
  }, [conditionValue]);

  const selectedOptionVal = useMemo(() => {
    let selectedOption = [];

    if (fieldValue === "actual_status") {
      const res = [];
      findingLastStatusValues?.forEach((status) => {
        if (value?.includes(status?.value?.[0])) {
          res.push(status);
        }
      });
      return res;
    }

    if (!!conditionValue && conditionWithValuesList) {
      selectedOption = Array.isArray(value)
        ? getFieldValueOptions(fieldValue, value)
        : [];
    }

    return selectedOption;
  }, [conditionValue, conditionWithValuesList, fieldValue, value]);

  const onPrev = () => {
    previewFilter(true);
    toggleIsOpen(false);
  };

  const selectedValueLabel = useMemo(() => {
    let label = "";
    if (!value) {
      label = "Choose Value";
    } else if (fieldValue === "title") {
      label =
        value?.length > MAX_VALUES_LENGTH
          ? `${value?.length} selected`
          : value?.join(", ");
    } else if (conditionValue === "recent") {
      label = value === "1" ? `Today` : `Last ${value} Days`;
    } else if (conditionValue === "within") {
      label = value === "1" ? `1 Day` : `${value} Days`;
    } else if (!!conditionValue && conditionWithValuesList) {
      label =
        selectedOptionVal?.length > MAX_VALUES_LENGTH
          ? `${selectedOptionVal?.length} selected`
          : selectedOptionVal?.map((option) => option.label)?.join(", ");
    } else {
      label = value;
    }

    if (ageFields?.includes(fieldValue) && !!value) {
      label = `${label} Days`;
    }

    return label;
  }, [
    conditionValue,
    conditionWithValuesList,
    fieldValue,
    selectedOptionVal,
    value,
  ]);

  const popoverTitle = useMemo(() => {
    return (
      <div className={`selected-filter-value`} title={selectedValueLabel}>
        <div className={`selected-value-title`}>{selectedValueLabel}</div>
        {fieldValue !== "actual_status" && isEditable && (
          <Button
            data-testid={"delete-condition-btn"}
            wrapperClassName={`remove-filter-icon`}
            type="button"
            isIcon="seem-icon-close"
            isClean
            isXs
            title={"Remove condition"}
            onClick={() => {
              if (itemIndex === 0 && filterPanel?.length > 1) {
                removeRow(rowIndex);
              }
              removeFilter(itemIndex);
              setTimeout(() => {
                onPrev();
              }, 0);
            }}
          />
        )}
      </div>
    );
  }, [
    conditionValue,
    conditionWithValuesList,
    selectedOptionVal,
    value,
    itemIndex,
    isEditable,
  ]);

  const classNameWrapper = ClassNames("filter-row-selected-value", {
    "is-active": isOpen,
    "is-disabled": !isEditable,
  });

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[
        <FilterValueSelection
          namePrefix={namePrefix}
          value={value}
          onChange={onChange}
          conditionWithValuesList={conditionWithValuesList}
          onPrev={onPrev}
          fieldsData={fieldsData}
          onBlur={onBlur}
        />,
      ]}
      enterExitTransitionDurationMs={0}
      onOuterAction={(e) => {
        const isTagDropdown = e?.path?.filter((path) => {
          return path?.className?.includes("suggestion-name");
        });
        if (!isTagDropdown?.length) {
          setTimeout(() => toggleIsOpen(false), 200);
        }
      }}
    >
      <div
        //INBAL I NEED A CONDITION FOR IF DISABLED
        className={`${classNameWrapper} ${false ? "is-disabled" : ""} ${
          isOpen ? "is-active" : ""
        }`}
        onClick={() => {
          if (isEditable) {
            toggleIsOpen(!isOpen);
          }
        }}
      >
        {popoverTitle}
      </div>
    </Popover>
  );
};

export default FilterValue;
