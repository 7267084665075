import React, { useContext, useEffect, useMemo } from "react";
import { Auth } from "aws-amplify";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import { FeatureToggles } from "@paralleldrive/react-feature-toggles";
import { features } from "../FeatureFlag/FeaturesList";
import ModalProvider from "../Forms/Dialogs/Modal/ModalProvider";
import { sha256 } from "js-sha256";
import { LDProvider } from "launchdarkly-react-client-sdk";

const SEEMPLICITY = "seemplicity.io";
const ProtectedRoute = ({ children, ...rest }) => {
  let history = useHistory();
  const { updateAuthState, authState } = useContext(AuthContext);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        let email = user?.attributes?.email;
        if (user?.signInUserSession?.idToken?.payload?.identities?.length) {
          email =
            user?.signInUserSession?.idToken?.payload?.identities[0].userId;
        }

        const domain = email?.substring(email?.lastIndexOf("@") + 1);
        const isDemoAccount = domain?.toLowerCase() === SEEMPLICITY;
        updateAuthState({
          ...authState,
          user: user,
          username: email,
          domain: domain,
          isDemoAccount: isDemoAccount,
          mfaType: user?.preferredMFA,
        });
      })
      .catch(() => {
        history.push("/login");
      });
  }, []);

  const initialFeatures = useMemo(() => {
    return features;
  }, []);
  return (
    <Route
      {...rest}
      render={() => (
        <FeatureToggles features={initialFeatures}>
          {authState.domain && (
            <LDProvider
              clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID}
              user={{ key: sha256(authState.domain) }}
            >
              <ModalProvider>{children}</ModalProvider>
            </LDProvider>
          )}
        </FeatureToggles>
      )}
    />
  );
};

export default ProtectedRoute;
