import TextInput from "../../../../../../Forms/Controls/TextInput/TextInput";
import Button from "../../../../../../Forms/Controls/Button/Button";
import React, { useMemo, useState } from "react";
import CheckBoxList from "../../../../../../Forms/Controls/CheckBoxList/CheckBoxList";
import { findingLastStatusValues } from "../../../../../../Findings/FindingsUtils";
import {
  ageFields,
  dateFields,
  dateOptions,
  getFieldValueOptions,
  ageOptions,
} from "../../../../../../Findings/FindingsFilters/FilterRow/FilterRowUtils";
import { useQuery } from "@apollo/client";
import { GET_FILTER_ROW_VALUES } from "../../../../../../Findings/FindingsFilters/FindingsFilterApi";
import { useFormContext, useWatch } from "react-hook-form";
import RadioButtonsList from "../../../../../../Forms/Controls/RadioButtonsList/RadioButtonsList";
import DateInput from "../../../../../../Forms/Controls/DateInput/DateInput";
import TagsSelect from "../../../../../../Forms/Controls/TagsSelect/TagsSelect";
import Tag from "../../../../../../Forms/Controls/Tags/Tag/Tag";

const FilterValueSelection = ({
  namePrefix,
  value,
  onChange,
  conditionWithValuesList,
  onPrev,
  fieldsData,
  onBlur,
}) => {
  const { control } = useFormContext();

  const [valueSearchInput, setValueSearchInput] = useState("");
  const [valueChanges, setValueChanges] = useState(value);
  const [customValueError, setCustomValueError] = useState(false);

  const fieldValue = useWatch({
    control,
    name: `${namePrefix}.field`,
  });

  const conditionValue = useWatch({
    control,
    name: `${namePrefix}.condition`,
  });

  const dateInputConditions = useMemo(() => {
    return conditionValue === "lt" || conditionValue === "gt";
  }, [conditionValue]);

  const searchableFilter = useMemo(() => {
    return (
      fieldsData?.find((fieldData) => fieldData.field === fieldValue)
        ?.searchable || false
    );
  }, [fieldsData, fieldValue]);

  const { data: fieldValues, loading: loadValues } = useQuery(
    GET_FILTER_ROW_VALUES,
    {
      variables: {
        filterName: fieldValue,
        contains: valueSearchInput,
        limit: 100,
      },
      skip: !fieldValue || !conditionValue || !conditionWithValuesList,
    }
  );

  const valueOptions = useMemo(() => {
    if (fieldValue === "actual_status") {
      return findingLastStatusValues;
    }
    return (
      getFieldValueOptions(
        fieldValue,
        fieldValues?.finding_filter_data?.values
      ) || []
    );
  }, [fieldValue, fieldValues?.finding_filter_data?.values]);
  const getTextInputOptions = () => (
    <TextInput
      data-testid={"condition-options"}
      onBlur={onBlur}
      wrapperClassName={`filter-values-text-box`}
      className="filter-row-last "
      onChange={(e) => {
        // onChange(e);
        setValueChanges(e.target.value);
      }}
      placeholder={"+ Add Value"}
      size="max"
      value={valueChanges}
      defaultValue={""}
      // error={
      //   rowError && !!rowError.value
      //     ? { ...rowError.value, type: "filterError" }
      //     : null
      // }
    />
  );

  const getTagsList = () => {
    return (
      <TagsSelect
        onChange={(tags) => {
          const tagsList = tags?.map((tag) => tag?.name);
          setValueChanges(tagsList);
        }}
        value={
          valueChanges?.map((val) => {
            return { id: val, name: val };
          }) || []
        }
        placeholder={`Type Titles`}
        allowNew={true}
        suggestions={[]}
        label={""}
        // error={isTouched ? error : null}
        inputStyle={"bomba-style"}
        // labelTop
        // onBlur={onBlur}
        // onFocus={onFocus}
        // name={name}
        size="max"
        minQueryLength={0}
        newTagTextPrefix={`+`}
      />
    );
  };

  const getRadioButtonsList = (menuOptions) => {
    if (!valueChanges) {
      setValueChanges(menuOptions?.[0]?.value);
    }
    return (
      <RadioButtonsList
        value={valueChanges}
        onChange={(val) => {
          setValueChanges(val);
          if (customValueError) {
            setCustomValueError(false);
          }
        }}
        label={""}
        options={menuOptions}
        direction={"column"}
      />
    );
  };

  const getCheckboxList = () => {
    return (
      <CheckBoxList
        data-testid={"filter-dropdown-item"}
        className={"filter-dropdown-item"}
        label={``}
        value={valueChanges}
        onChange={(val) => setValueChanges(val)}
        options={valueOptions}
        direction={"column"}
        loading={loadValues}
      />
    );
  };

  const getDateOptions = () => (
    <DateInput
      onBlur={onBlur}
      name={"enter-date"}
      labelTop
      wrapperClassName={``}
      onChange={(e) => setValueChanges(e.target.value)}
      value={valueChanges}
      // inputStyle={"bomba-style"}
      size={"max"}
      // error={
      //   rowError && !!rowError.value
      //       ? { ...rowError.value, type: "filterError" }
      //       : null
      // }
    />
  );

  const valueComponent = useMemo(() => {
    if (fieldValue === "title") {
      return getTagsList();
    } else if (ageFields?.includes(fieldValue)) {
      return getRadioButtonsList(
        ageOptions(fieldValue, () => {
          setCustomValueError(true);
        })
      );
    } else if (dateFields?.includes(fieldValue) && dateInputConditions) {
      return getDateOptions();
    } else if (
      dateFields?.includes(fieldValue) &&
      conditionValue !== "within"
    ) {
      return getRadioButtonsList(dateOptions());
    } else if (!!conditionValue && conditionWithValuesList) {
      return getCheckboxList();
    } else {
      return getTextInputOptions();
    }
  }, [
    conditionValue,
    conditionWithValuesList,
    fieldValue,
    getCheckboxList,
    getRadioButtonsList,
    getTextInputOptions,
  ]);

  const wrapperClassname = useMemo(() => {
    if (fieldValue === "title") {
      return "has-title-input";
    } else if (ageFields?.includes(fieldValue)) {
      return "has-age-input";
    } else if (dateFields?.includes(fieldValue) && dateInputConditions) {
      return "has-date-input";
    } else if (
      dateFields?.includes(fieldValue) &&
      conditionValue !== "within"
    ) {
      return "has-date-input";
    } else if (!!conditionValue && conditionWithValuesList) {
      return "has-input";
    } else {
      return "has-other-input";
    }
  }, [
    conditionValue,
    conditionWithValuesList,
    fieldValue,
    getCheckboxList,
    getRadioButtonsList,
    getTextInputOptions,
  ]);

  const isValidValues = useMemo(() => {
    return valueChanges?.length > 0 && !customValueError;
  }, [customValueError, valueChanges?.length]);

  return (
    <div className={`filter-dropdown-wrap ${wrapperClassname}`}>
      {searchableFilter && !!conditionValue && conditionWithValuesList && (
        <div className={"dropdown-tag-container"}>
          <div className={"dropdown-tag-container-tags"}>
            {valueChanges?.map((tag) => {
              return (
                <Tag
                  tag={{
                    name: tag,
                  }}
                  onDelete={() => {
                    const data = [...valueChanges].filter(
                      (item) => item !== tag
                    );
                    setValueChanges(data);
                  }}
                />
              );
            })}
          </div>
          <TextInput
            data-testid={`${namePrefix}.search-value`}
            // onBlur={onBlur}
            wrapperClassName={`filter-values-text-box`}
            className="menu-search filter-row-last "
            onChange={(e) => {
              setValueSearchInput(e.target.value);
            }}
            placeholder={"Search"}
            size="max"
            value={valueSearchInput}
            defaultValue={""}
            // error={
            //   rowError && !!rowError.value
            //     ? { ...rowError.value, type: "filterError" }
            //     : null
            // }
          />
        </div>
      )}
      <div className={`filter-value-options-list`}>{valueComponent}</div>
      <Button
        data-testid={`filter-preview`}
        label={"Submit"}
        isSecondary
        isInMenu
        onClick={() => {
          onChange(valueChanges);
          onPrev();
        }}
        disabled={!isValidValues}
      />
    </div>
  );
};

export default FilterValueSelection;
