import React, { useContext, useMemo } from "react";
import "./FindingsView.scss";
import RMQFindingsTable from "../RMQFindingsTable/RMQFindingsTable";
import {
  findingsTicketsColumns,
  modeOptions,
} from "../../../RemediationQueuesUtils";
import { RemediationQueueContext } from "../../RemediationQueueProvider";
import { ScopesContext } from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { FiltersContext } from "../../../../AppView/FilterManagement/FilterProvider/FilterProvider";

const FindingsView = () => {
  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope } = scopesState;

  const {
    remediationQueueData: { scope_id, filterjson, priority },
    mode,
    hasGeneralPrioritizeSetting,
  } = useContext(RemediationQueueContext);

  const scope = useMemo(() => {
    if (mode !== modeOptions.READ) {
      return selectedScope?.value?.id;
    } else {
      return scope_id;
    }
  }, [mode, scope_id, selectedScope]);

  const filter = useMemo(() => {
    if (mode !== modeOptions.READ) {
      return filterToExecute;
    } else {
      return filterjson;
    }
  }, [mode, filterjson, filterToExecute]);

  const getPriorityByMode = useMemo(() => {
    if (mode !== modeOptions.CREATE) {
      return priority;
    } else if (!!hasGeneralPrioritizeSetting) {
      return hasGeneralPrioritizeSetting.value;
    }
  }, [hasGeneralPrioritizeSetting, mode, priority]);

  return scope && filter ? (
    <RMQFindingsTable
      priority={getPriorityByMode}
      title={"{{count}} Findings"}
      scope={scope}
      filter={filter}
      columns={findingsTicketsColumns}
      enableRowSelection={mode !== modeOptions.CREATE}
      className={"with-tickets"}
    />
  ) : null;
};

export default FindingsView;
