import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PREFERENCES } from "./AppPreferencesApi";
import { UPDATE_LAST_LOGIN } from "../Auth/AuthApi";
import { AuthContext } from "../Auth/AuthContext";
import WebSocketProvider from "../WebSocket/WebSocketProvider";

export const userActionsEnum = {
  EDIT_SCOPE: "scopes_edit",
  CREATE_SCOPE: "scopes_create",
  MANAGE_USERS: "user_management",
  MANAGE_PERMISSIONS: "permissions_management",
  VIEW_WORKFLOWS: "workflow_view",
  MANAGE_SAML: "saml_settings",
};

export const AppPreferencesContext = React.createContext({});

const AppPreferencesProvider = ({ children }) => {
  const [appPreferences, setAppPreferences] = useState({});
  const [finishedLoginProcess, setFinishedLoginProcess] = useState(false);

  const { authState } = useContext(AuthContext);

  const [getPreferences] = useLazyQuery(GET_PREFERENCES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const slaEnabled =
        data?.preferences?.customer_preferences?.sla_preferences?.edges?.[0]
          .node?.group?.enabled;
      const userActionsArray =
        data?.preferences?.user_preferences?.user_actions?.edges;
      const userActions = userActionsArray.map((action) => {
        return { id: atob(action?.node?.id).split(":")?.[1] };
      });

      const wsTicket = data?.preferences?.ws_ticket;

      updateAppPreferences({ slaEnabled, userActions, wsTicket });
    },
  });
  const [updateLastLogin] = useMutation(UPDATE_LAST_LOGIN);

  useEffect(() => {
    updateLastLogin({ variables: { email: authState.username } })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        getPreferences();
        setFinishedLoginProcess(true);
      });
  }, [authState.username]);

  const updateAppPreferences = (state) => {
    setAppPreferences({ ...appPreferences, ...state });
  };

  const isUserActionAllowed = (actionId) =>
    appPreferences?.userActions?.some((action) => action.id === actionId);

  return (
    <AppPreferencesContext.Provider
      value={{
        appPreferences,
        isAllowed: isUserActionAllowed,
        appPreferencesAreSet: Boolean(
          Object.keys(appPreferences).length && finishedLoginProcess
        ),
      }}
    >
      <WebSocketProvider>{children}</WebSocketProvider>
    </AppPreferencesContext.Provider>
  );
};

export default AppPreferencesProvider;
