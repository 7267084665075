export const sendMessageTypes = {
  KEEPALIVE: "KEEPALIVE",
  OPEN_CONNECTION: "OPEN_CONNECTION",
};

export const taskNames = {
  UPDATE_VIEW: "FindingsViewUpdate",
  UPDATE_FINDING_STATUS: "UpdateFindingsStatus",
  AGGREGATE_FINDINGS_STATUS: "AggregateFindingsStatus",
};
