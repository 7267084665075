import React, { useContext } from "react";
import "./InstancesList.scss";
import Button from "../../../../Forms/Controls/Button/Button";

const InstancesList = ({ children, IntegrationContext }) => {
  const { currentIntegration: data, handleInstanceAddition } =
    useContext(IntegrationContext);

  return (
    <div className={"integration-details-instances-wrapper"}>
      <div className={"instances-list-header"}>
        <div className={"instances-title"}>Instances</div>
        {!!handleInstanceAddition ? (
          <Button
            isSecondary
            label={"+ Add Instance"}
            data-testid={"add-user"}
            onClick={() => handleInstanceAddition(data)}
            title={"Add"}
          />
        ) : null}
      </div>
      {children}
    </div>
  );
};

export default InstancesList;
