import React, { useCallback, useEffect, useMemo, useState } from "react";
import IntegrationTicket from "../IntegrationTicket/IntegrationTicket";
import DataSourcesHeader from "./DataSourcesHeader/DataSourcesHeader";
import { useTranslation } from "react-i18next";
import "./DataSources.scss";
import Loader from "../../../Components/Loader/Loader";
import Spinner from "../../../images/loader.svg";
import moment from "moment";
import useIntegratedDataSources from "./useIntegratedDataSources";
import { useQuery } from "@apollo/client";
import { GET_DATA_SOURCE_DEFINITIONS } from "./DataSourcesApi";
import useIntegrationManagement from "../useIntegrationManagement";
import IntegrationDetails from "../IntegrationDetails/IntegrationDetails";
import InstancesItem from "./InstancesItem/InstancesItem";
import InstanceActions from "./InstanceActions/InstanceActions";
import { dataSourceIcons } from "../../../Findings/FindingsUtils";
import { categoriesOrder } from "./DataSourcesUtils";

const DataSources = () => {
  const {
    integrationContext: IntegrationContext,
    currentIntegration,
    setCurrentIntegration: updateCurrentIntegration,
    activeComponent,
    handleBackToMainPage,
    handleInstanceEditing,
    handleInstanceAddition,
    handleViewIntegration,
  } = useIntegrationManagement();

  const { integratedDataSources } = useIntegratedDataSources(true);

  const { data: dataSourceDefinitionsData } = useQuery(
    GET_DATA_SOURCE_DEFINITIONS
  );

  const layoutComponents = (integration) => {
    if (!!IntegrationContext) {
      return [
        {
          reference: "view",
          component: (
            <IntegrationDetails
              instances={
                <div className={"instances-wrapper"}>
                  {integration.instances?.map((instance) => (
                    <InstancesItem
                      key={instance.id}
                      name={instance.name}
                      id={instance.id}
                      enabled={instance.isEnabled}
                      lastSynced={instance.lastSynced}
                      IntegrationContext={IntegrationContext}
                    />
                  ))}
                </div>
              }
              buttonData={{
                text: "Back to All Data Sources",
              }}
              IntegrationContext={IntegrationContext}
            />
          ),
        },
        {
          reference: "edit",
          component: (
            <InstanceActions
              mode={"edit"}
              IntegrationContext={IntegrationContext}
            />
          ),
        },
        {
          reference: "create",
          component: (
            <InstanceActions
              mode={"create"}
              IntegrationContext={IntegrationContext}
            />
          ),
        },
      ];
    }
  };

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [datasourceDefinitions, setDatasourceDefinitions] = useState(null);
  const [datasourceDefinitionsToShow, setDatasourceDefinitionsToShow] =
    useState(null);

  useEffect(() => {
    if (!!dataSourceDefinitionsData && !!integratedDataSources) {
      let dataSourcesDefinitionsWithInstances = {};

      dataSourceDefinitionsData?.datasource_definitions?.edges.forEach(
        (dataSourceDefinition) => {
          if (
            dataSourceDefinition.node.name !== "Manual" &&
            dataSourceDefinition.node.name !== "Rapid7 On Prem"
          ) {
            dataSourcesDefinitionsWithInstances[dataSourceDefinition.node.id] =
              {
                name: dataSourceIcons[dataSourceDefinition.node?.file_prefix]
                  ?.name
                  ? dataSourceIcons[dataSourceDefinition.node?.file_prefix]
                      ?.name
                  : dataSourceDefinition.node.name,
                imageLong:
                  dataSourceIcons[dataSourceDefinition.node?.file_prefix]?.L,
                imageShort:
                  dataSourceIcons[dataSourceDefinition.node?.file_prefix]?.S,
                category: dataSourceDefinition.node.category,
                id: dataSourceDefinition.node.id,
                instructions: dataSourceDefinition.node.instructions,
                sections: dataSourceDefinition.node.sections,
                instances: [],
              };
          }
        }
      );

      const dataSourcesDefinitionsKeys = Object.keys(
        dataSourcesDefinitionsWithInstances
      );

      integratedDataSources.datasources?.edges?.forEach(
        (integratedDataSource) => {
          const dataSourceDefinitionKey = dataSourcesDefinitionsKeys.find(
            (key) => key === integratedDataSource.node.datasource_definition.id
          );
          let dataSourceDefinitionObj;
          if (dataSourceDefinitionKey) {
            dataSourceDefinitionObj =
              dataSourcesDefinitionsWithInstances[dataSourceDefinitionKey];
          }

          if (dataSourceDefinitionObj) {
            dataSourceDefinitionObj.instances.push({
              isEnabled: integratedDataSource?.node?.enabled,
              name: integratedDataSource?.node?.friendly_name,
              id: integratedDataSource?.node?.id,
              lastSynced: integratedDataSource?.node?.last_received_finding,
              fieldsValues: {
                friendly_name: integratedDataSource?.node?.friendly_name,
                ...JSON.parse(
                  integratedDataSource?.node?.required_fields_value
                ),
                ...JSON.parse(
                  integratedDataSource?.node?.secret_configuration_value
                ),
              },
            });
          }

          dataSourcesDefinitionsWithInstances[dataSourceDefinitionKey] =
            dataSourceDefinitionObj;
        }
      );

      dataSourcesDefinitionsKeys.forEach((dataSourceDefinitionId) => {
        const dataSourceDefinition =
          dataSourcesDefinitionsWithInstances[dataSourceDefinitionId];
        let latestSynced;
        let isEnabled;
        dataSourceDefinition.instances.forEach((instance) => {
          isEnabled = instance.isEnabled;

          if (!latestSynced && !!instance.lastSynced) {
            latestSynced = instance.lastSynced;
          } else if (
            latestSynced &&
            moment(instance.lastSynced).isAfter(moment(latestSynced))
          ) {
            latestSynced = instance.lastSynced;
          }
        });

        if (dataSourceDefinition.instances.length) {
          dataSourceDefinition.hasAtLeastOneInstance = true;
        }
        dataSourceDefinition.lastSynced = latestSynced;
        dataSourceDefinition.isEnabled = Boolean(isEnabled);

        if (dataSourceDefinitionId === currentIntegration?.id) {
          updateCurrentIntegration(dataSourceDefinition);
        }
      });

      const dataSourcesDefinitionsWithInstancesArray = Object.keys(
        dataSourcesDefinitionsWithInstances
      ).map(
        (dataSourceDefinitionId) =>
          dataSourcesDefinitionsWithInstances[dataSourceDefinitionId]
      );

      setDatasourceDefinitions(dataSourcesDefinitionsWithInstancesArray);
    }
  }, [dataSourceDefinitionsData, integratedDataSources]);

  const dataSourceDefinitionsByActiveTab = useMemo(() => {
    if (!!activeTab && !!datasourceDefinitions) {
      let filteredArray = datasourceDefinitions.slice();
      switch (activeTab) {
        case "enabled":
          return filteredArray.filter((el) => el?.isEnabled);
        default:
          return filteredArray;
      }
    }
  }, [activeTab, datasourceDefinitions]);

  useEffect(() => {
    if (!!dataSourceDefinitionsByActiveTab) {
      const sortedArray = dataSourceDefinitionsByActiveTab
        .slice()
        .filter((value) =>
          value?.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort(function (a, b) {
          return b?.name.toLowerCase() > a?.name.toLowerCase() ? -1 : 1;
        })
        .sort(function (a, b) {
          let res;
          if (a.hasAtLeastOneInstance && !b.hasAtLeastOneInstance) {
            res = -1;
          }
          if (!a.hasAtLeastOneInstance && b.hasAtLeastOneInstance) {
            res = 1;
          }
          if (a.hasAtLeastOneInstance && b.hasAtLeastOneInstance) {
            res = 0;
          }
          return res;
        });

      setDatasourceDefinitionsToShow(sortedArray);
    }
  }, [searchTerm, dataSourceDefinitionsByActiveTab]);

  const groupDataSourcesByCategory = (datasources) => {
    const categories = {};
    datasources.forEach((datasource) => {
      if (!Object.keys(categories).includes(datasource.category)) {
        categories[datasource.category] = [datasource];
      } else {
        categories[datasource.category].push(datasource);
      }
    });

    return Object.keys(categories)
      .map((category) => {
        return {
          [category]: categories[category],
        };
      })
      .sort((firstCategory, SecondCategory) => {
        const firstCategoryName = Object.keys(firstCategory)[0];
        const secondCategoryName = Object.keys(SecondCategory)[0];
        return (
          categoriesOrder.indexOf(firstCategoryName) -
          categoriesOrder.indexOf(secondCategoryName)
        );
      });
  };

  const getContentByActiveTab = useCallback(
    (tab, datasources) => {
      switch (tab) {
        case "enabled":
          return (
            <ul className={`integrations-section-wrap`}>
              {datasources.map((datasource) => (
                <li key={datasource.id}>
                  <IntegrationTicket
                    IntegrationContext={IntegrationContext}
                    data={datasource}
                    data-testid={`ticket-${datasource.name}`}
                  />
                </li>
              ))}
            </ul>
          );
        default:
          return groupDataSourcesByCategory(datasources).map((categoryObj) => (
            <div key={Object.keys(categoryObj)[0]}>
              <div className={`integrations-section-title`}>
                {t(Object.keys(categoryObj)[0])}
              </div>
              <ul className={`integrations-section-wrap`}>
                {categoryObj[Object.keys(categoryObj)[0]].map((datasource) => (
                  <li key={datasource.id}>
                    <IntegrationTicket
                      IntegrationContext={IntegrationContext}
                      data={datasource}
                      data-testid={`ticket-${datasource.name}`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ));
      }
    },
    [IntegrationContext]
  );

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab);
    setDatasourceDefinitionsToShow(null);
  };

  const getMainComponent = useMemo(() => {
    return (
      <div className="integrations-wrap" data-testid={"integrations-wrap"}>
        <DataSourcesHeader
          data={datasourceDefinitions}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          onModeChange={handleActiveTabChange}
        />

        {!!datasourceDefinitionsToShow ? (
          <div className={`integrations-section`}>
            {getContentByActiveTab(activeTab, datasourceDefinitionsToShow)}
          </div>
        ) : (
          <div className={`integrations-section is-loading`}>
            <Loader center img={Spinner} />
          </div>
        )}
      </div>
    );
  }, [
    datasourceDefinitions,
    searchTerm,
    getContentByActiveTab,
    activeTab,
    datasourceDefinitionsToShow,
  ]);

  const handleLeavingMainComponent = (data) => {
    setActiveTab(null);
    handleViewIntegration(data);
  };

  return (
    !!IntegrationContext && (
      <IntegrationContext.Provider
        value={{
          currentIntegration,
          handleBackToMainPage,
          handleInstanceEditing,
          handleInstanceAddition,
          handleViewIntegration: handleLeavingMainComponent,
        }}
      >
        {(!!currentIntegration &&
          layoutComponents(currentIntegration).find(
            (el) => el.reference === activeComponent
          )?.component) ||
          getMainComponent}
      </IntegrationContext.Provider>
    )
  );
};

export default DataSources;
