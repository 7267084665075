import React, { useContext } from "react";
import DashboardWidget from "../DashboardWidget/DashboardWidget";
import FindingsByStatusAndCategory from "./FindingsByStatusAndCategory/FindingsByStatusAndCategory";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import FindingsByCategoryAndSeverity from "./FindingsByCategoryAndSeverity/FindingsByCategoryAndSeverity";
import NewFindingsByCategory from "./NewFindingsByCategory/NewFindingsByCategory";
import AvgAgeByCategory from "./AvgAgeByCategory/AvgAgeByCategory";
import { ScopesContext } from "../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import { FiltersContext } from "../../AppView/FilterManagement/FilterProvider/FilterProvider";
import useFindingsViewChange from "../../Findings/useFindingsViewChange";

const ProgramDashboard = ({ timeScale }) => {
  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  useFindingsViewChange({ queries: ["getDashboard"] });

  return (
    <>
      {!!selectedScope && !!filterToExecute && !!timeScale ? (
        <div className="widgets-grid widgets-grid-program">
          <DashboardWidget title={"Findings by Category and Severity"}>
            <FindingsByCategoryAndSeverity
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>

          <DashboardWidget title={"Findings by Ticket Status and Category"}>
            <FindingsByStatusAndCategory
              selectedScope={selectedScope}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>

          <DashboardWidget title={"New Findings by Category"}>
            <NewFindingsByCategory
              selectedScope={selectedScope}
              timeScale={timeScale}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>

          <DashboardWidget title={"Average Age by Category"}>
            <AvgAgeByCategory
              selectedScope={selectedScope}
              timeScale={timeScale}
              filterToExecute={filterToExecute}
              scopeToExecute={scopeToExecute}
            />
          </DashboardWidget>
        </div>
      ) : (
        <Loader center img={Spinner} />
      )}
    </>
  );
};

export default ProgramDashboard;
