import { useContext, useEffect, useState } from "react";
import { WebSocketContext } from "./WebSocketProvider";

const useWebSocketMessageHandler = ({ messageTypes }) => {
  const { value } = useContext(WebSocketContext);
  const [wsMessage, setWsMessage] = useState(null);

  useEffect(() => {
    if (!!value) {
      const dataToJson = value?.replaceAll("'", '"');
      const dataFromServer = JSON?.parse(dataToJson);

      if (messageTypes.includes(dataFromServer?.response?.data?.task_name)) {
        setWsMessage(dataFromServer?.response?.data);
      }
    }
  }, [value]);

  return {
    wsMessage,
  };
};

export default useWebSocketMessageHandler;
