import React, { useContext } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import TextInput from "../../../../../Forms/Controls/TextInput/TextInput";
import Schedule from "../../../../../Components/Schedule/Schedule";
import { RemediationQueueContext } from "../../../RemediationQueueProvider";
import RmqTicketSettings from "../../../../../Ticketing/RmqTicketSettings/RmqTicketSettings";
import { ticketEndpointTypeEnum } from "../../../../../Ticketing/TicketingUtils";

const OutputPreferences = ({
  setGetPropsFromKeyField = () => {},
  setGetPropsFromField = () => {},
  setGetPropsFromTicketManager = () => {},
  getPropsFromTicketManager = () => {},
  disabled,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const {
    remediationQueueData: {
      providerType: ticketManagerId,
      providerId,
      ticketEndpointId,
    },
    mode,
  } = useContext(RemediationQueueContext);

  const selectedTicketManager = useWatch({ control, name: "ticketManager" });

  return (
    <>
      <RmqTicketSettings
        disabled={disabled}
        endpointId={ticketEndpointId}
        providerId={providerId}
        ticketManagerId={ticketManagerId}
        mode={mode}
        getPropsFromTicketManager={getPropsFromTicketManager}
        setGetPropsFromKeyField={setGetPropsFromKeyField}
        setGetPropsFromField={setGetPropsFromField}
        setGetPropsFromTicketManager={setGetPropsFromTicketManager}
      />
      {!!selectedTicketManager &&
        getPropsFromTicketManager(selectedTicketManager)?.type !==
          ticketEndpointTypeEnum.INTERNAL && (
          <>
            <div className={`form-row`}>
              <Controller
                name={`schedule`}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <Schedule
                      onChange={(e) => onChange(e)}
                      value={value}
                      onBlur={onBlur}
                      disabled={disabled}
                      error={errors && errors.schedule ? errors.schedule : null}
                      defaultValue={null}
                      label={"Schedule"}
                      labelTop
                      inputStyle={"bomba-style"}
                      size="custom"
                      className="rmq-input-schedule"
                    />
                  );
                }}
                control={control}
                rules={{ required: "Required" }}
              />
              {!!selectedTicketManager &&
                getPropsFromTicketManager(selectedTicketManager)?.type !==
                  ticketEndpointTypeEnum.REPORT && (
                  <Controller
                    name={`queueSize`}
                    render={({ field: { value, onChange, onBlur, ref } }) => {
                      return (
                        <TextInput
                          data-testid={"queue-size"}
                          labelTop
                          onChange={(e) => onChange(e)}
                          inputRef={ref}
                          onBlur={onBlur}
                          disabled={disabled}
                          value={value}
                          error={
                            errors && errors.queueSize ? errors.queueSize : null
                          }
                          min={1}
                          defaultValue={1}
                          label={"Queue Size"}
                          type={"number"}
                          size="custom"
                          className="rmq-input-que-size"
                          inputStyle={"bomba-style"}
                          placeholder={"Number of items"}
                        />
                      );
                    }}
                    control={control}
                    rules={{
                      required: "Required",
                      pattern: {
                        value: /^[1-9][0-9]*$/,
                        message: "Invalid Queue Size",
                      },
                    }}
                  />
                )}
            </div>
          </>
        )}
    </>
  );
};

export default OutputPreferences;
