import { gql } from "@apollo/client";
import { oneSeriesTrendProperties } from "./TrendsUtils";

export const GET_TREND = gql`
  query getTrend(
    $timescale: TimeScales
    $selection: [String]
    $group_by: [String]
    $aggregate_functions: [AggregateFunctions]
    $round_functions: [RoundFunctions]
    $filters_config: FiltersConfigInput
    $snapshots_filters_config: FiltersConfigInput
  ) {
    trends(
      timescale: $timescale
      selection: $selection
      group_by: $group_by
      aggregate_functions: $aggregate_functions
      round_functions: $round_functions
      filters_config: $filters_config
      snapshots_filters_config: $snapshots_filters_config
    )
  }
`;

export const generateGetTrendMock = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let parameters = {
    selection:
      variables?.parameters?.selection ||
      oneSeriesTrendProperties.newFindingsByDiscoveryDate.queryNameVariable,
    timescale: variables?.parameters?.timescale || "WEEK",
    filters_config: variables?.parameters?.filters_config,
    aggregate_functions: variables?.parameters?.aggregate_functions || ["SUM"],
    round_functions: variables?.parameters?.round_functions || ["ROUND"],
  };

  let getTrendMock = {
    request: {
      query: GET_TREND,
      variables: {
        ...variables?.parameters,
        ...parameters,
      },
    },
  };

  if (error) {
    return {
      ...getTrendMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getTrendMock,
      result: { data: result },
    };
  }

  return {
    ...getTrendMock,
    result: { data: { trends: JSON.stringify([]) } },
  };
};
