import { useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const useChart = () => {
  const [chartLoading, setChartLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [dataExists, setDataExists] = useState(null);

  const startLoadingChart = () => {
    setChartLoading(true);
  };

  const stopLoadingChart = () => {
    setChartLoading(false);
  };

  const setChartError = (isError) => {
    setDataError(isError);
  };

  const setChartDataExists = (isData) => {
    setDataExists(isData);
  };

  const createChart = (chartName, chartType, defs) => {
    let chart = am4core.create(chartName, chartType);

    chart.paddingTop = 38;
    chart.preloader.disabled = true;

    if (defs?.isScrollable) {
      chart.cursor = new am4charts.XYCursor();

      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();

      chart.cursor.behavior = "zoomXY";
    }

    let legendContainer = am4core.create(
      `${chartName}-legend-container`,
      am4core.Container
    );
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend = new am4charts.Legend();
    chart.legend.parent = legendContainer;
    chart.legend.valign = "bottom";

    return [chart, legendContainer];
  };

  return {
    chartLoading,
    dataError,
    dataExists,
    startLoadingChart,
    stopLoadingChart,
    setChartError,
    createChart,
    setChartDataExists,
  };
};

export default useChart;
