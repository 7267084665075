import { useForm, FormProvider, Controller } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import DateInput from "../../Forms/Controls/DateInput/DateInput";
import SelectInput from "../../Forms/Controls/SelectInput/SelectInput";
import { getFindingSeverity } from "../FindingsUtils";
import ScoreSelection from "./ScoreSelection/ScoreSelection";
import { Collapse } from "react-collapse";
import Button from "../../Forms/Controls/Button/Button";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import "./ManualFinding.scss";
import TextareaAutoSize from "../../Forms/Controls/TextareaAutoSize/TextareaAutoSize";
import { getFieldValueOptions } from "../FindingsFilters/FilterRow/FilterRowUtils";
import { useQuery } from "@apollo/client";
import { GET_FILTER_ROW_VALUES } from "../FindingsFilters/FindingsFilterApi";

export const manualFindingStates = {
  CREATE: "CREATE",
  AGGREGATE: "AGGREGATE",
};
const ManualFinding = ({ state, data, onFormSubmit, additionalData }) => {
  const methods = useForm({
    mode: "all",
  });
  const {
    control,
    formState: { isValid, isSubmittingForm, errors },
    reset,
    getValues,
    handleSubmit,
  } = methods;

  const [additionalDataIsOpen, toggleAdditionalData] = useState(false);
  const additionalDataRef = useRef(null);

  const onSubmit = (data) => {
    onFormSubmit(data);
  };

  useEffect(() => {
    reset({
      ...getValues(),
      title: data?.title,
      remediation: data?.remediation,
      description: data?.description,
      score_data: {
        score: data?.score,
        severity: getFindingSeverity(data?.severity),
      },
      date: data?.date,
      cloud_account: { value: data?.cloud_account, label: data?.cloud_account },
    });
  }, [data]);

  const [valueSearchInput, setValueSearchInput] = useState("");
  const { data: cloudAccountOptions } = useQuery(GET_FILTER_ROW_VALUES, {
    variables: {
      filterName: "cloud_account",
      contains: valueSearchInput,
      limit: 10,
    },
  });

  return (
    <FormProvider {...methods}>
      <form className={`manual-finding-form`} onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-scroll-area">
          <Controller
            name={`title`}
            render={({ field: { onChange, value, onBlur, ref } }) => (
              <TextInput
                data-testid={`title`}
                onChange={(e) => {
                  onChange(e);
                }}
                inputRef={ref}
                onBlur={onBlur}
                value={value}
                error={errors?.title || null}
                size="max"
                inputStyle={"bomba-style"}
                isLarge
              />
            )}
            defaultValue={null}
            control={control}
            rules={{
              required: "Required",
            }}
          />

          <div className={`score-date-container`}>
            <div className="manual-finding-form-score-wrap">
              <Controller
                name={`score_data`}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <ScoreSelection
                    inputStyle={"bomba-style"}
                    onChange={onChange}
                    value={value}
                    disabled={state === manualFindingStates.AGGREGATE}
                    size={"max"}
                  />
                )}
                control={control}
                rules={{ required: "Required" }}
              />
            </div>
            <div className="form-separator-horizontal" />
            <div className="manual-finding-form-date-wrap">
              <Controller
                name={`date`}
                defaultValue={moment().format("YYYY-MM-DD")}
                render={({ field: { value, onChange, onBlur, ref } }) => (
                  <DateInput
                    onBlur={onBlur}
                    inputRef={ref}
                    name={"enter-date"}
                    labelTop
                    wrapperClassName={``}
                    className={"create-finding-date-field"}
                    data-testid={`date-input`}
                    onChange={(e) => onChange(e)}
                    maxDate={moment().format("YYYY-MM-DD")}
                    error={errors && errors.date ? errors.date : null}
                    value={value}
                    inputStyle={"bomba-style"}
                    size={"max"}
                    disabled={state === manualFindingStates.AGGREGATE}
                    showDisabledValue
                  />
                )}
                control={control}
              />
            </div>
          </div>
          <div className={"form-separator"} />
          <Controller
            name={`description`}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextareaAutoSize
                data-testid={`description`}
                name={`description`}
                value={value}
                onBlur={onBlur}
                inputStyle={"bomba-style"}
                size="max"
                labelTop
                onChange={(data) => onChange(data)}
                error={errors && errors.description ? errors.description : null}
                label={`About this finding`}
              />
            )}
            rules={{ required: "Required" }}
            control={control}
          />

          <Controller
            name={`remediation`}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextareaAutoSize
                data-testid={`remediation`}
                name={`remediation`}
                value={value}
                onBlur={onBlur}
                inputStyle={"bomba-style"}
                size="max"
                labelTop
                onChange={(data) => onChange(data)}
                error={errors && errors.remediation ? errors.remediation : null}
                label={`Remediation (Optional)`}
              />
            )}
          />

          <Controller
            wrapperClassName={``}
            name={`cloud_account`}
            render={({ field: { onChange, value, onFocus, onBlur } }) => {
              let valueOptions = [];

              valueOptions =
                getFieldValueOptions(
                  "cloud_account",
                  cloudAccountOptions?.finding_filter_data?.values
                ) || [];

              return (
                <SelectInput
                  data-testid={"cloud_account"}
                  isCreateable={true}
                  onInputChange={(value) => setValueSearchInput(value)}
                  inputValue={valueSearchInput}
                  isSearchable={true}
                  name={`cloud_account`}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  onChange={(selectedValue) => onChange(selectedValue)}
                  labelTop
                  label={"Cloud Account"}
                  size="l"
                  SelectInputStyle={"bomba-style"}
                  error={
                    errors && !!errors.cloud_account
                      ? errors.cloud_account
                      : null
                  }
                  placeholder={"Select..."}
                  options={valueOptions}
                  value={value}
                  defaultValue={null}
                  disabled={state === manualFindingStates.AGGREGATE}
                />
              );
            }}
            rules={{ required: "Required" }}
            control={control}
          />

          <div className={`additional-data-container`}>
            <div
              className={`additional-data-item-toggle`}
              onClick={() => toggleAdditionalData(!additionalDataIsOpen)}
              data-testid={`create-finding-additional-data`}
            >
              <div
                className={`additional-data-item-toggle-icon-wrap ${
                  additionalDataIsOpen ? "is-open" : "is-closed"
                }`}
              >
                <i className="seem-icon-right-arrow" />
              </div>
              <span className="additional-data-title">
                {additionalData.title}
              </span>
            </div>

            <Collapse isOpened={additionalDataIsOpen}>
              <div ref={additionalDataRef}>{additionalData.component}</div>
            </Collapse>
          </div>
        </div>

        <ModalButtonsContainer>
          <Button
            data-testid={"submit-aggregate-findings"}
            type="submit"
            label={`${
              state === manualFindingStates.AGGREGATE ? "Aggregate" : "Create"
            }`}
            isSecondary
            disabled={!isValid || isSubmittingForm}
          />
        </ModalButtonsContainer>
      </form>
    </FormProvider>
  );
};

export default ManualFinding;
