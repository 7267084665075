import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TICKET_ENDPOINT_FIELDS } from "../TicketingAPI";
import { Controller } from "react-hook-form";
import GenericField from "../GenericField/GenericField";
import useSections from "../useSections/useSections";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import "./TicketEndpointFields.scss";
import { ticketEndpointContextEnum } from "../TicketingUtils";

const TicketEndpointFields = (props) => {
  const {
    context,
    onGetProps,
    shouldUnregisterOnUnmount = true,
    findingsSeverity = null,
    keysObject,
    endpointId,
    findingsId = undefined,
    selectedTicketProvider,
    selectedFields = null,
    control,
    reset: formReset,
    getValues,
    formState: { errors },
  } = props;
  const [fieldsSections, setFieldsSections] = useState(null);

  const { data: ticketEndpointFieldsData, error: ticketEndpointFieldsError } =
    useQuery(GET_TICKET_ENDPOINT_FIELDS, {
      variables: {
        ticket_provider_id: selectedTicketProvider,
        selected_keys: JSON.stringify(keysObject ? keysObject : {}),
        endpoint_fields_context: context,
        ticket_endpoint_id: endpointId,
        finding_id: findingsId,
      },
      skip: !selectedTicketProvider,
    });

  useEffect(() => {
    if (!!ticketEndpointFieldsError) {
      setFieldsSections(null);
    }
  }, [ticketEndpointFieldsError]);

  const getField = (field) => {
    return (
      <Controller
        shouldUnregister={shouldUnregisterOnUnmount}
        name={`fields.${field?.name}`}
        render={({
          field: { onChange, onBlur, value, onFocus },
          fieldState: { isTouched },
        }) => {
          return (
            <GenericField
              {...props}
              onChange={onChange}
              name={field?.name}
              registerPrefix={`fields.${field?.name}`}
              onBlur={onBlur}
              value={value}
              size={field?.size}
              setSingleOption={false}
              error={isTouched ? errors?.fields?.[field?.name] : null}
              mapping={field?.mapping}
              query={field?.query}
              creatable={field?.creatable}
              onFocus={onFocus}
              key={field?.name}
              input={field?.input}
              label={field?.label}
              options={field?.options}
              placeholder={`Choose${field?.creatable ? " or Create" : ""} ${
                field?.label
              }`}
            />
          );
        }}
        rules={{ ...(field?.required && { required: "Required" }) }}
        defaultValue={field?.defaultValue}
        control={control}
      />
    );
  };

  const { getSections, defaultValues, setDefaultValues, sections } =
    useSections(
      fieldsSections,
      ticketEndpointFieldsError,
      onGetProps,
      getField,
      findingsSeverity,
      <Loader center img={Spinner} />,
      <Loader center text="No data" />,
      context !== ticketEndpointContextEnum.MANUAL_TICKET,
      selectedFields
    );

  useEffect(() => {
    if (!!ticketEndpointFieldsData) {
      setFieldsSections(
        ticketEndpointFieldsData?.endpoint_fields?.fields_sections
      );
      formReset({ ...getValues(), fields: defaultValues });
    } else {
      setFieldsSections(null);
    }
  }, [ticketEndpointFieldsData, defaultValues]);

  useEffect(() => {
    if (!!selectedFields && !!sections) {
      setDefaultValues(selectedFields);
    }
  }, [selectedFields, sections]);

  return <div className={"endpoint-list"}>{getSections()}</div>;
};

export default TicketEndpointFields;
