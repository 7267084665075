import React from "react";
import { snakeCase } from "lodash";
import { cloudProviderIcons } from "../../../../../FindingsUtils";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";

const CloudAccountNavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  cloudProviderIcon,
  groupRow,
  groupTitle,
}) => (
  <div
    className={`findings-carousel-item group-by-${groupBy}`}
    onClick={() => onGroupClick(groupRow)}
  >
    <div
      className={`findings-carousel-item-top`}
      data-testid={"findings-count"}
    >
      {groupName && (
        <div
          className={`sevirity-level sevirity-level-${snakeCase(groupName)}`}
        />
      )}
      {normalizeNumbers(group.total_count)}
    </div>

    <div className={`findings-carousel-item-bottom`}>
      {!!cloudProviderIcon && (
        <div className={`findings-carousel-item-bottom-icon`}>
          {" "}
          <img
            alt={`${groupTitle}`}
            src={cloudProviderIcons[snakeCase(cloudProviderIcon)]}
          />
        </div>
      )}
      <div className={`findings-carousel-item-bottom-name`}>
        {`${groupTitle}`}
      </div>
      <div className={`findings-carousel-item-bottom-name-full`}>
        {`${groupTitle}`}
      </div>
    </div>
  </div>
);

export default CloudAccountNavigationItem;
