import React from "react";
import { Controller, useWatch } from "react-hook-form";
import GenericField, { fieldTypeOptions } from "../../GenericField";
import TextInput from "../../../../Forms/Controls/TextInput/TextInput";
import Button from "../../../../Forms/Controls/Button/Button";

const DynamicMappingFieldRow = (props) => {
  const {
    control,
    errors,
    registerPrefix,
    label,
    mappingOptions,
    removeRow,
    mappedFields,
    updateRow,
    index,
    value,
    rowsNumber,
    field,
    appendRow,
  } = props;

  const selectedField = useWatch({
    control,
    name: `${registerPrefix}[${index}].field`,
  });

  const getFieldChoice = () => {
    return (
      <Controller
        name={`${registerPrefix}[${index}].field`}
        render={({ field: { onChange, onBlur, value, onFocus } }) => {
          let currentOptions = mappingOptions.filter(
            (el) => !mappedFields.includes(el.name)
          );
          if (value) {
            currentOptions.push(mappingOptions.find((el) => el.name === value));
          }
          currentOptions = currentOptions.map((el) => {
            return {
              id: el.name,
              name: el.label,
            };
          });
          return (
            <GenericField
              onChange={(val) => {
                onChange(val);
                updateRow(index, { field: val, value: null });
              }}
              setSingleOption={false}
              onBlur={onBlur}
              value={value}
              clearable={false}
              onFocus={onFocus}
              label={""}
              input={fieldTypeOptions.SELECT}
              placeholder={`Choose ${label}`}
              options={currentOptions}
            />
          );
        }}
        defaultValue={field}
        control={control}
      />
    );
  };

  const getFieldValue = () => {
    const currFieldFrom = mappingOptions?.find((option) => {
      return option?.name === selectedField;
    });

    return selectedField ? (
      <Controller
        name={`${registerPrefix}[${index}].value`}
        render={({ field: { onChange, onBlur, onFocus, value } }) => {
          return (
            <GenericField
              {...props}
              {...currFieldFrom}
              classname={"dynamic-field-mapping-value"}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              value={value}
              control={control}
              label={""}
              size={"max"}
              placeholder={`Choose ${currFieldFrom?.label}`}
              error={
                errors[`${registerPrefix.split(".")?.[0]}`]?.[
                  registerPrefix.split(".")?.[1]
                ][index]?.value
              }
              required={true}
            />
          );
        }}
        defaultValue={value}
        control={control}
        rules={{ required: "Required" }}
      />
    ) : (
      <TextInput
        wrapperClassName={"dynamic-field-mapping-value"}
        data-testid={"dynamic-mapping-field-placeholder"}
        disabled
        size="max"
        value={null}
        inputStyle={"bomba-style"}
        placeholder={`Description`}
      />
    );
  };

  return (
    <div className={"row"}>
      <div className={"dynamic-mapping-field-inputs"}>
        {getFieldChoice()}
        <div className={"dynamic-mapping-field-to"}>
          {getFieldValue()}
          <Button
            data-testid={`remove-dynamic-mapping-field`}
            wrapperClassName={`remove-dynamic-mapping-field`}
            type="button"
            isIcon="seem-icon-bin"
            isClean
            isSecondary
            title={"Delete field"}
            onClick={() => {
              if (index === 0 && rowsNumber === 1) {
                updateRow(index, { field: null, value: null });
              } else {
                removeRow(index);
              }
            }}
          />
        </div>
      </div>
      <Button
        wrapperClassName={`add-dynamic-mapping-field ${
          index + 1 === mappingOptions.length || index + 1 !== rowsNumber
            ? "is-hidden"
            : ""
        }`}
        type={"button"}
        label={"+ Add Field"}
        textLink
        data-testid={"add-dynamic-mapping-field"}
        onClick={() => appendRow({ field: null, value: null })}
      />
    </div>
  );
};

export default DynamicMappingFieldRow;
