import { useQuery } from "@apollo/client";
import { GET_DATA_SOURCES } from "./DataSourcesApi";

const useIntegratedDataSources = (withLastReceived = false) => {
  const { data: integratedDataSources, error: integratedDataSourcesError } =
    useQuery(GET_DATA_SOURCES, {
      variables: { with_last_received: withLastReceived },
    });

  return { integratedDataSources, integratedDataSourcesError };
};

export default useIntegratedDataSources;
