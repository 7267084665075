import React from "react";

const ErrorAnimation = () => {
  return (
    <div className="seema-error-wrap">
      <div className="seema-bg" />
      <div className="seema-question-mark" />
      <div className="seema-question-mark" />
      <div className="seema-question-mark" />
      <div className="seema-face" />
      <div className="seema-hand" />
    </div>
  );
};

export default ErrorAnimation;
