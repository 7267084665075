import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "../../Controls/Button/Button";
import { createPortal } from "react-dom";
import "./Modal.scss";
import { ModalContext } from "./ModalContext";
import ClassNames from "classnames";
export const MODAL_CONTAINER_CLASS = `modal-dialog-container`;
export const Modal = () => {
  const { isOpen, modalContent, closeModal, showCloseButton } =
    useContext(ModalContext);
  const {
    title,
    icon,
    component,
    size,
    className,
    modalRight = false,
    modalLeft = false,
    overlay = true,
    image,
    overlayClass = "",
    displayHeader = true,
  } = modalContent;

  const classNameWrapper = ClassNames(
    "modal-dialog-content",
    {
      "modal-right": modalRight,
      "modal-left": modalLeft,
      "modal-size-xxs": size === "xxs",
      "modal-size-xs": size === "xs",
      "modal-size-s": size === "s",
      "modal-size-m": size === "m",
      "modal-size-l": size === "l",
      "modal-size-xl": size === "xl",
      "modal-size-xxl": size === "xxl",
      "modal-size-xxxl": size === "xxxl",
    },
    className
  );
  if (isOpen) {
    return createPortal(
      <div className={`${MODAL_CONTAINER_CLASS}`}>
        {/*TODO*/}
        {/*FIX TO HIDE OVERLAY IF SET TO FALSE*/}
        {overlay === false ? null : (
          <div
            className={`modal-dialog-overlay ${
              overlayClass ? overlayClass : ""
            }`}
          />
        )}

        <div className={classNameWrapper}>
          {displayHeader && (
            <div className={`modal-header`}>
              <div className={`modal-header-title-wrap`}>
                {image ? (
                  <div
                    className={`modal-title-image`}
                    style={{ backgroundImage: `url(${image})` }}
                  />
                ) : (
                  <></>
                )}

                {icon ? <i className={`seem-icon ${icon}`} /> : null}

                <div className={`modal-title`}>{title}</div>
              </div>

              {showCloseButton && (
                <div className={`modal-dialog-actions`}>
                  <Button
                    isClean
                    isIcon="seem-icon-close"
                    type={`button`}
                    title={"Close"}
                    onClick={(e) => {
                      e.stopPropagation();
                      closeModal();
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className={`modal-content`}>{component}</div>
        </div>
      </div>,
      document.querySelector("#modal-root")
    );
  } else {
    return null;
  }
};

Modal.defaultProps = {
  size: "xs",
  overlay: true,
  modalRight: false,
  modalLeft: false,
  className: "",
  icon: "",
};

Modal.propTypes = {
  size: PropTypes.string,
  modalRight: PropTypes.bool,
  modalLeft: PropTypes.bool,
  overlay: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
};
