import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import TextInput from "../TextInput/TextInput";
import { useTranslation } from "react-i18next";
import ClassNames from "classnames";
import "./Slider.scss";

const SliderInput = ({
  value,
  defaultValue,
  min,
  max,
  step,
  onChange,
  label,
  inputStyle,
  labelTop,
  error,
  marks,
  "data-testid": dataTestId,
}) => {
  const { t } = useTranslation();
  const handleChange = (value) => {
    onChange(value);
  };

  const classNameWrapper = ClassNames(
    "input-wrap",
    // "input-wrap-text",
    // wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "is-idle": !value,
      "has-error": !!error,
      "is-active": !!value && !error,
    }
  );
  return (
    <div className={classNameWrapper}>
      {label && <label className={`input-label`}>{t(label)}</label>}
      <div
        className={`slider-container active-mark-${value}`}
        style={{ height: "20px", width: "322px", margin: "0 8px" }}
      >
        <Slider
          min={min}
          max={max}
          value={!!error ? null : value || null}
          defaultValue={defaultValue}
          dots={true}
          step={step}
          onChange={handleChange}
          marks={marks}
          data-testid={`${dataTestId}-input`}
        />
      </div>
    </div>
  );
};

TextInput.defaultProps = {
  min: 1,
  max: 10,
  value: null,
  defaultValue: null,
  step: 1,
  onChange: () => {},
  marks: null,
};

export default SliderInput;
