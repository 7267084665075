import React, { useContext } from "react";
import IntegrationDetailsSummary from "./IntegrationDetailsSummary/IntegrationDetailsSummary";
import Button from "../../../Forms/Controls/Button/Button";
import "./IntegrationDetails.scss";
import InstancesList from "./InstancesList/InstancesList";

const IntegrationDetails = ({ instances, buttonData, IntegrationContext }) => {
  const { handleBackToMainPage } = useContext(IntegrationContext);

  return (
    <div className={"integration-details-wrapper"}>
      <Button
        wrapperClassName={"back-to-integrations"}
        data-testid={"back-to-btn"}
        type={"button"}
        leftIcon={"seem-icon-left-arrow"}
        label={buttonData.text}
        textLink
        onClick={() => handleBackToMainPage()}
      />
      <IntegrationDetailsSummary IntegrationContext={IntegrationContext} />
      <InstancesList IntegrationContext={IntegrationContext}>
        {instances}
      </InstancesList>
    </div>
  );
};

export default IntegrationDetails;
