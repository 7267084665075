import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import { Controller, useForm } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import Button from "../../Forms/Controls/Button/Button";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { MFAMethod, MFAType } from "./MFAUtils";
import "./SetupMFA.scss";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import useCognitoAuthWithLogs from "../useCognitoAuthWithLogs";

const SetupMFA = () => {
  const { updateAuthState, authState } = useContext(AuthContext);
  const { user, username } = authState;
  const [totpUri, setTOTPUri] = useState(null);
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    reset,
    isSubmitting,
  } = useForm();
  const { closeModal } = useContext(ModalContext);
  const { setPreferredMFA, setupTOTP, verifyTotpToken } =
    useCognitoAuthWithLogs();

  useEffect(() => {
    setupTOTP(user)
      .then((code) => {
        // setMFACode(code);
        setTOTPUri(
          `otpauth://totp/Seemplicity:${username}?secret=${code}&issuer=SEEMPLICITY`
        );
      })
      .catch((ex) => {
        console.log(ex);
        //TODO: alert!
      });
  }, []);

  const onSubmit = (data) => {
    verifyTotpToken(user, data.totpToken)
      .then(() => {
        setPreferredMFA(user, MFAMethod.TOTP);
        updateAuthState({ ...authState, mfaType: MFAType.SOFTWARE_TOKEN_MFA });
        reset();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        setError("totpToken", {
          type: "error",
          message: t("An error occurred. Setup TOTP failed."),
        });
      });
  };

  return (
    <>
      <div className={"qr-code-title"}>
        <div className="title">{t("1. Scan the barcode with your app")}</div>
        <div className="subtitle">
          {t("Use your two-factor authentication app to scan the image below")}
        </div>
      </div>

      <>
        {!totpUri ? (
          <div className="qr-loading">
            <Loader center img={Spinner} />
          </div>
        ) : (
          <div
            className={`qrCodeContainer`}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <QRCode value={totpUri} />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"totp-token-title"}>
            <div className={`title`}>
              {t("2. Enter the 6-digit code from your app")}
            </div>
            <div className={`subtitle`}>
              {t("to activate two-factor authentication")}
            </div>
          </div>
          <Controller
            wrapperClassName={`login-username`}
            name={"totpToken"}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <div className={"center"}>
                  <TextInput
                    placeholder={"6 digit code"}
                    type={"password"}
                    size={"xxs"}
                    error={errors?.totpToken || null}
                    value={value}
                    onChange={(val) => onChange(val)}
                    onBlur={onBlur}
                    disabled={isSubmitting}
                    wrapperClassName={"center"}
                  />
                </div>
              );
            }}
            control={control}
            rules={{ required: "Required" }}
          />

          <ModalButtonsContainer>
            <Button
              type="submit"
              label={"Enable"}
              rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
              isSecondary
              disabled={isSubmitting}
            />
          </ModalButtonsContainer>
        </form>
      </>
    </>
  );
};

export default SetupMFA;
