import queryString from "query-string";
import { useHistory } from "react-router-dom";

const useDashboardNavigator = () => {
  const history = useHistory();

  const dashboardNavigator = (
    filterId,
    scopeId,
    group,
    filterToExecute = null
  ) => {
    const queryStringObj = {};
    if (!!filterId) {
      queryStringObj.filterId = filterId;
    }
    if (!!scopeId) {
      queryStringObj.scopeId = scopeId;
    }
    if (group?.groupIndex !== null) {
      queryStringObj.groupByIndex = group?.groupIndex;
    }
    if (group?.data) {
      for (const key in group?.data) {
        queryStringObj[`groupByValue-${key}`] = group?.data[key];
      }
    }
    if (!!filterToExecute) {
      queryStringObj.filterToExecute = filterToExecute;
    }

    const queryStringURL = queryString.stringifyUrl({
      url: "/findings",
      query: queryStringObj,
    });

    history.push(queryStringURL);
  };

  return {
    dashboardNavigator,
  };
};

export default useDashboardNavigator;
