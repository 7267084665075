import React, {useState} from "react";

const useScope = () => {
    const [isOpen, toggleIsOpen] = useState(false);

    return {isOpen, toggleIsOpen}
}

export default useScope;

export const ScopeManagementContext = React.createContext({});