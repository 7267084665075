import React from "react";
import "./SidebarDisplayArea.scss";

const SidebarDisplayArea = ({ title, children }) => {
  return (
    <div className={"sidebar-display-area-wrapper"}>
      <div className={"sidebar-display-area-title-wrap"}>
        <div className={"sidebar-display-area-title"}>{title}</div>
      </div>
      <div className={"sidebar-display-area-content"}>{children}</div>
    </div>
  );
};

export default SidebarDisplayArea;
