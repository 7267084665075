import React from "react";

// NOTE: Use the editor from source (not a build)!
import { Editor } from "@tinymce/tinymce-react";
import "./TextEditor.scss";
import PropTypes from "prop-types";
import FormFieldError from "../../Forms/Controls/FormFieldError/FormFieldError";
import ClassNames from "classnames";

export const convertHtmlToPlainText = (htmlString) => {
  const dom = document.createElement("DIV");
  dom.innerHTML = htmlString;
  return dom.innerText || dom.textContent;
};

const TextEditor = ({
  placeholder,
  wrapperClassName,
  customHeight,
  label,
  name,
  value,
  disabled = false,
  onChange,
  error,
  onBlur,
  maxCharacters,
  inputStyle,
  labelTop,
  required,
  readOnly,
  isInline,
  isLarge,
  size,
  labelWidth,
  "data-testid": dataTestId,
}) => {
  const classNameWrapper = ClassNames(
    "input-wrap",
    "input-wrap-text-editor",
    wrapperClassName && wrapperClassName,
    inputStyle && inputStyle,
    {
      "has-label": label !== null,
      "has-label-top": labelTop,
      "has-required": required,
      "is-disabled": disabled,
      "has-read-only": readOnly,
      "has-size-xxs": size === "xxs",
      "has-size-xs": size === "xs",
      "has-size-s": size === "s",
      "has-size-m": size === "m",
      "has-size-l": size === "l",
      "has-size-xl": size === "xl",
      "has-size-xxl": size === "xxl",
      "has-size-max": size === "max",
      "has-label-width": labelWidth,
      "has-size-custom": size === "custom",
      "is-inline": isInline,
      "is-large": isLarge,
      "has-error": !!error,
    }
  );

  return (
    <div className={classNameWrapper}>
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}
      <>
        <div
          className={`input-holder ${disabled ? "is-disabled" : ""}`}
          style={{ height: customHeight }}
          data-testid={dataTestId}
        >
          <div
            id={`toolbar-container-${dataTestId}`}
            className={"toolbar-container"}
          />
          <Editor
            apiKey="9uqwcmm4ae0s779bx8jzelj0ipqkyv547kpxdaeqzswg2lpb"
            value={value}
            inline={true}
            onEditorChange={(newValue) => onChange(newValue)}
            onBlur={onBlur}
            init={{
              toolbar: "bold italic",
              menubar: false,
              placeholder,
              fixed_toolbar_container: `#toolbar-container-${dataTestId}`,
            }}
            disabled={disabled}
          />
          {!!maxCharacters && (
            <div className="word-count-wrap">
              <div
                className="word-count-wrap-allowed"
                id="max-chars-count"
              >{`Max ${maxCharacters} letters`}</div>
            </div>
          )}
          <div className="error-holder">
            {error && (
              <FormFieldError
                errorMessage={error.message}
                errorType={error.type}
              />
            )}
          </div>
        </div>
      </>
    </div>
  );
};

TextEditor.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  /** controls the size of the input: possible values: 's', 'm', 'l', 'max','custom' */
  size: PropTypes.string,
  label: PropTypes.string,
  /** if exists - label will be shown above the input */
  labelTop: PropTypes.bool,
  /** if exists - label will be shown to the right of the input */
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  customHeight: PropTypes.string,
  /** Autocomplete items (list of constants) */
  feed: PropTypes.arrayOf(PropTypes.string),
  /** The character which triggers autocompletion for mention */
  mentionMarker: PropTypes.string,
  /** if exists - gives the input a styling of an error state */
  error: PropTypes.object,
  maxCharacters: PropTypes.number,
  plainText: PropTypes.bool,
  inputStyle: PropTypes.string,
  isInline: PropTypes.bool,
  isLarge: PropTypes.bool,
};

TextEditor.defaultProps = {
  label: null,
  readOnly: false,
  customHeight: "",
  error: null,
  labelTop: false,
  placeholder: "",
  onChange: () => {},
  maxCharacters: null,
  plainText: false,
  size: "m",
  required: false,
  disabled: false,
  password: false,
  labelWidth: false,
  icon: "",
  inputStyle: "",
};

export default TextEditor;
