import React, { useContext, useMemo } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import Linkify from "react-linkify";
import ReadMore from "../../../Components/ReadMore/ReadMore";

const Remediation = () => {
  const {
    detailsPaneData: { remediation },
  } = useContext(DetailsPaneContext);

  const remediationData = useMemo(() => {
    if (!!remediation) {
      return remediation;
    }
  }, [remediation]);

  return (
    <div className={`details-pane-section-data`}>
      {remediationData?.text ? (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a rel="noreferrer" target="_blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <ReadMore lines={4} more="More.." less="Less..">
            <div>
              {(remediationData?.text || remediationData?.Text)
                ?.split("\n")
                ?.map((item) => {
                  return (
                    <span>
                      {item}
                      <br />
                    </span>
                  );
                })}
            </div>

            {(remediationData?.url || remediationData?.Url) && (
              <a
                rel="noreferrer"
                target="_blank"
                className={`see-link`}
                href={remediationData?.url || remediationData?.Url}
              >{` - see here`}</a>
            )}
          </ReadMore>
        </Linkify>
      ) : (
        "N/A"
      )}
    </div>
  );
};

export default Remediation;
