import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";
import Table from "../Components/Table/Table";
import "./ResourcesList.scss";
import { useTranslation } from "react-i18next";
import { GET_FILTER_ROW_VALUES } from "../Findings/FindingsFilters/FindingsFilterApi";
import SelectInput from "../Forms/Controls/SelectInput/SelectInput";
import { Control } from "../Findings/FindingsFilters/FilterHeaderStyleComponents/FilterHeaderStyleComponents";
import { GET_RESOURCES } from "./ResourcesApi";
import Tags from "../Findings/FindingDetailsPane/Resources/Tags/Tags";

const ResourcesList = () => {
  const { t } = useTranslation();
  const [resourcesList, setResourcesList] = useState([]);
  const [scopesjson, setScopesJson] = useState({
    field: "resource_type",
    value: ["cloud_provider"],
    condition: "in",
  });
  const [expandedRowIndex, setExpandedRowIndex] = useState([]);

  const { data: typeValues } = useQuery(GET_FILTER_ROW_VALUES, {
    variables: {
      filterName: "resource_type",
      contains: "",
      limit: 100,
    },
  });

  const { loading, data: resources } = useQuery(GET_RESOURCES, {
    variables: {
      filters_config: {
        scopesjson: JSON.stringify(scopesjson),
      },
    },
  });

  const multiSelectColumnFilter = (options) => {
    if (options) {
      options = [{ label: "All", value: "" }, ...options];
      return ({ column: { filterValue, setFilter, id } }) => {
        const includes = (val) => {
          return filterValue?.includes(val);
        };
        let value = options.filter((option) => includes(option.value));

        return (
          <SelectInput
            data-testid={id}
            SelectInputStyle={"bomba-style"}
            isMulti
            size={"max"}
            options={options}
            value={value || ""}
            onChange={(newValue) => {
              if (filterValue?.includes("")) {
                newValue = newValue?.filter((option) => option.value !== "");
              } else if (
                newValue?.filter((option) => option.value === "")?.length
              ) {
                newValue = newValue?.filter((option) => option.value === "");
              }
              setFilter(newValue.map((val) => val.value)); // Set undefined to remove the filter entirely
            }}
            replaceableComponents={{
              Control,
            }}
            customProps={{
              icon: "seem-icon-table-filter",
            }}
          />
        );
      };
    }
    return <div />;
  };

  const getRow = (data) => {
    let row = data[expandedRowIndex[0]];

    for (let i = 1; i < expandedRowIndex.length; i++) {
      row = row.subRows[expandedRowIndex[i]];
    }

    return row;
  };

  const addExpandedRowSubRows = (subRowsData) => {
    let data = [...resourcesList];

    let row = getRow(data);
    row.subRows = subRowsData.map((item) => {
      return {
        ...item.node,
        subRows: item.node?.child_resources?.edges,
      };
    });

    setResourcesList(data);
  };

  const initResourcesData = (resources) => {
    const data =
      resources?.resources?.edges?.map((item) => {
        return {
          ...item.node,
          subRows: item.node?.child_resources?.edges,
        };
      }) || [];

    setResourcesList(data);
  };

  const onRowExpand = (row) => {
    setExpandedRowIndex(row.id.split("."));
    setScopesJson({
      field: "resource_id",
      value: [row.original.resource_id],
      condition: "in",
    });
  };

  useEffect(() => {
    if (resources) {
      if (
        Array.isArray(scopesjson.value) &&
        scopesjson.value.length === 1 &&
        scopesjson.value[0] === "cloud_provider"
      ) {
        initResourcesData(resources);
      } else if (expandedRowIndex.length > 0) {
        addExpandedRowSubRows(
          resources?.resources?.edges[0].node?.child_resources?.edges
        );
      }
    }
  }, [resources, expandedRowIndex, scopesjson]);

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "Name",
        accessor: "resource_name",
        width: 356,
        Cell: (cellProps) => {
          return (
            <span
              className={"expander"}
              {...cellProps.row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${cellProps.row.depth * 2}rem`,
                },
              })}
            >
              {cellProps.row.canExpand ? (
                cellProps.row.isExpanded ? (
                  <i
                    className={`expander-icon seem-icon-xs seem-icon-right-arrow expanded`}
                  />
                ) : (
                  <i
                    className={`expander-icon seem-icon-xs seem-icon-right-arrow`}
                  />
                )
              ) : (
                <div className={`tree-leaf-icon`} />
              )}
              {cellProps.value}
            </span>
          );
        },
      },
      {
        id: "findings",
        Header: "Findings",
        accessor: "findings_count",
        disableFilters: true,
        width: 180,
        maxWidth: 180,
      },
      {
        id: "critical_findings",
        Header: "Critical Findings",
        accessor: "critical_findings_count",
        disableFilters: true,
        width: 180,
        maxWidth: 180,
        Cell: (cellProps) => {
          return (
            <span className={"critical-finding-cell"}>
              <div className={"critical-finding-square"} />
              {cellProps.value}
            </span>
          );
        },
      },
      {
        Header: "Type",
        id: "resource_type",
        width: 232,
        maxWidth: 232,
        Filter: multiSelectColumnFilter(
          typeValues?.finding_filter_data?.values.map((type) => {
            return { label: type, value: type };
          })
        ),
        filter: "includesSome",
        accessor: "resource_type",
      },
      {
        id: "last_seen",
        Header: "Last Seen",
        width: 232,
        maxWidth: 232,
        disableFilters: true,
        accessor: (d) => {
          return moment(d.last_modified).format("MMMM DD, YYYY, hh:mm A");
        },
      },
      {
        id: "tags",
        Header: "Tags",
        disableFilters: true,
        accessor: (d) => {
          return {
            resource_id: d.resource_id,
            datasource_tags_values: d.datasource_tags_values,
            seem_tags_values: d.seem_tags_values,
          };
        },
        width: 456,
        Cell: (cellProps) => {
          //todo: unfinished yet
          return (
            <Tags
              tagsID={cellProps.value?.resource_id}
              showCollapsed={false}
              showHeader={false}
              maxTags={4}
              seem_tags={cellProps.value?.seem_tags_values?.map((tag) => [
                tag,
                tag,
              ])}
              datasource_tags={cellProps.value?.datasource_tags_values?.map(
                (tag) => [tag, tag]
              )}
              displayKey={false}
            />
          );
        },
      },
    ],
    [typeValues]
  );

  return (
    <div className={`resource-list`}>
      <div className={`resource-list-title`}>{t("Resources")}</div>
      <div className={`resource-list-table-container`}>
        <Table
          tableWrapperClass={`resource-list-table`}
          loading={loading}
          columns={columns}
          data={resourcesList}
          data-testid-body={"resources"}
          onRowExpand={(row) => {
            onRowExpand(row);
          }}
        />
      </div>
    </div>
  );
};

export default ResourcesList;
