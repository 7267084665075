import React, { useCallback, useMemo } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import {
  FINDING_ROW_HEIGHT,
  FINDING_TITLE_ROW_HEIGHT,
  findingsCheckType,
  findingsRowType,
  getFindingSeverity,
} from "../../../FindingsUtils";
import FindingsListItem from "../FindingsListItem/FindingsListItem";
import ReactTooltip from "react-tooltip";
import RowLoader from "../../../../Components/RowLoader/RowLoader";
import { debounce } from "lodash";

const FindingsRender = ({
  findings,
  isLoadingFindings,
  forwardedRef,
  groupBy,
  columns,
  handleScroll,
  onSelectedFinding,
  hasSelectedItem,
  selectedFilterRow,
  enableRowSelection,
  checkedFindings,
  onCheckFinding,
  multiSelect,
  filteringEnabled,
}) => {
  const rowCount = findings?.length || 0;

  const isRowLoaded = useMemo(() => {
    return (index) => !findings?.[index].isLoader;
  }, [findings]);

  const itemSize = useMemo(() => {
    return (index) => {
      const finding = findings?.[index];
      if (finding.rowType === findingsRowType.GROUP) {
        return FINDING_TITLE_ROW_HEIGHT;
      } else {
        return FINDING_ROW_HEIGHT;
      }
    };
  }, [findings]);

  const rowRenderer = useCallback(
    ({ index, style }) => {
      let finding = findings[index];

      if (!isRowLoaded(index) || !finding) {
        return (
          <div
            data-testid={"finding-loading-row"}
            className={"finding-loading-row"}
            style={{ ...style, height: `${FINDING_ROW_HEIGHT}px` }}
          >
            <div className="group-indicator-container">
              <div className="group-indicator" />
            </div>
            <RowLoader />
          </div>
        );
      } else if (
        !!finding.rowType &&
        finding.rowType === findingsRowType.GROUP
      ) {
        return (
          <div
            className={"finding-group-title-wrapper"}
            style={{ ...style, height: `${FINDING_TITLE_ROW_HEIGHT}px` }}
          >
            <div className="group-indicator-container">
              <div className="group-indicator" />
            </div>
            <div
              id={finding?.groupTitle?.toLowerCase()}
              className={`findings-group-title`}
              datatest-id="findings-group-by-title"
            >
              {`${finding?.groupCount?.toLocaleString()} ${
                finding?.groupTitle || ""
              } Findings`}
            </div>
          </div>
        );
      } else {
        finding = findings[index];
        const isFirstGroupItem =
          findings?.[index - 1]?.rowType === findingsRowType.GROUP;
        const isLastGroupItem =
          findings?.[index + 1]?.rowType === findingsRowType.GROUP;
        const isSelected = selectedFilterRow?.id_int === finding.id_int;
        const isChecked =
          checkedFindings?.type === findingsCheckType.ALL ||
          checkedFindings?.findingsList?.includes(finding.id_int);
        return (
          <div
            className={`finding-list-item-table-row ${
              isSelected
                ? "finding-list-item-table-row-open-in-details-pane"
                : ""
            } ${
              isChecked ? "finding-list-item-table-row-checkbox-selected" : ""
            }`}
            style={style}
          >
            <div className={`finding-list-item-table-row-wrapper`}>
              <div className={`group-indicator-container`}>
                <div
                  className={`group-indicator ${
                    isFirstGroupItem ? "is-top-edge" : ""
                  } ${isLastGroupItem ? "is-bottom-edge" : ""}`}
                />
              </div>

              <FindingsListItem
                groupBy={groupBy.selectedOptionValue.groupBy}
                rowData={finding}
                groupId={finding.groupId}
                hasSelected={hasSelectedItem}
                isSelected={isSelected}
                isChecked={isChecked}
                severity={getFindingSeverity(finding.severity)}
                onSelectFinding={(e) => {
                  e.stopPropagation();
                  onSelectedFinding(finding, true);
                }}
                columns={columns}
                enableRowSelection={enableRowSelection}
                checkedFindings={checkedFindings}
                onCheckFinding={onCheckFinding}
                multiSelect={multiSelect}
                filteringEnabled={filteringEnabled}
              />
            </div>
          </div>
        );
      }
    },
    [
      findings,
      onSelectedFinding,
      selectedFilterRow?.id_int,
      isLoadingFindings,
      checkedFindings,
      onCheckFinding,
    ]
  );

  const getFindings = () => {
    return (
      <>
        <ReactTooltip place={"top"} effect={"solid"} />
        <AutoSizer>
          {({ width, height }) => (
            <VariableSizeList
              infiniteScrollRef={forwardedRef}
              ref={(list) => {
                if (!!list) {
                  forwardedRef.current = list;
                  ReactTooltip.rebuild();
                }
              }}
              className={"findings-infinite-list"}
              height={height}
              overscanCount={10}
              itemCount={rowCount}
              itemSize={itemSize}
              width={width < 1250 ? 1250 : width}
              useIsScrolling={true}
              onScroll={debounce(
                ({
                  scrollDirection,
                  scrollOffset,
                  scrollUpdateWasRequested,
                }) => {
                  if (typeof handleScroll === "function") {
                    handleScroll({
                      scrollDirection,
                      scrollOffset,
                      scrollUpdateWasRequested,
                    });
                  }
                },
                50
              )}
            >
              {rowRenderer}
            </VariableSizeList>
          )}
        </AutoSizer>
      </>
    );
  };

  return <>{getFindings()}</>;
};

export default FindingsRender;
