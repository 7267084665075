import React, { useContext } from "react";
import { dataSourceIcons } from "../../FindingsUtils";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import "./DataSource.scss";
import { startCase } from "lodash";
import ReactTooltip from "react-tooltip";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import useChangeTooltipMsg from "../../../Components/Tooltip/useChangeTooltipMsg";

const DataSource = () => {
  const {
    detailsPaneData: {
      datasource,
      original_score,
      original_finding_id,
      original_finding_link,
    },
  } = useContext(DetailsPaneContext);
  const { copyMsg, setCopyMsg } = useChangeTooltipMsg("Copy ID", "Copied!");

  return (
    <div
      className={`data-source-wrap ${
        datasource?.friendly_name ? "" : "is-hidden"
      }`}
    >
      <img
        data-tip
        data-for={"dataSourceTip"}
        className={`data-source-icon`}
        src={dataSourceIcons[datasource?.datasource_definition?.file_prefix]?.S}
        alt={datasource?.datasource_definition?.name}
      />
      <ReactTooltip id="dataSourceTip" place="top">
        {startCase(datasource?.datasource_definition?.name)}
      </ReactTooltip>
      {!!original_finding_id ? (
        <div className={"finding-in-data-src-wrapper"}>
          <a
            className={`id-in-datasource ${
              !original_finding_link ? "is-disabled" : ""
            }`}
            rel="noreferrer"
            target={"_blank"}
            title={original_finding_id}
            href={original_finding_link || "#"}
          >{`${original_finding_id}`}</a>
          <i
            data-tip
            onClick={() => {
              try {
                setCopyMsg("Copied!");
                navigator.clipboard.writeText(original_finding_id);
              } catch (err) {
                showDialog({
                  title: "Error",
                  type: "error",
                  message: err.message,
                  buttons: [
                    {
                      isSecondary: true,
                      label: "Close",
                    },
                  ],
                });
              }
            }}
            data-for="copyDatasourceFindingId"
            className="seem-icon seem-icon-link id-in-datasrc-copy"
          />
          <ReactTooltip
            id="copyDatasourceFindingId"
            place="top"
            className={"copy-tooltip"}
          >
            {copyMsg}
          </ReactTooltip>
        </div>
      ) : null}
      {!!original_finding_id && !!original_score ? <span>,</span> : null}
      {!!original_score ? (
        <div className={"datasource-original-score"}>
          {` Original Severity: ${original_score}`}
        </div>
      ) : null}
    </div>
  );
};

export default DataSource;
