import React from "react";
import "./ProgressBar.scss";

const ProgressBar = ({
  percent,
  withLabels,
  labelsData = {},
  withLegend,
  inputStyle = "",
}) => {
  return (
    <div className={`progress-bar-container ${inputStyle}`}>
      <div className={`progress-bar-wrap`}>
        <div
          data-testid={`progress-bar-completed`}
          className={`progress-bar-completed`}
          style={{ width: `${percent}%` }}
        />
      </div>
      {withLabels && (
        <div className={`progress-bar-labels-container`}>
          {labelsData?.completed?.count > 0 && (
            <div
              data-testid={`progress-bar-completed-label`}
              className={`progress-bar-completed-label progress-bar-label`}
              style={{ width: `${percent || 1}%` }}
            >
              {labelsData?.completed?.count}
            </div>
          )}
          <div
            data-testid={`progress-bar-total-label`}
            className={`progress-bar-total-label progress-bar-label`}
            style={{ width: `${100 - percent}%` }}
          >
            {labelsData?.total?.count}
          </div>
        </div>
      )}
      {withLegend && (
        <div className={`progress-bar-legend`}>
          <div className={`progress-bar-legend-item`}>
            <div className={"legend-item-indicator completed"} />
            {labelsData?.completed?.label}
          </div>
          <div className={`progress-bar-legend-item`}>
            <div className={"legend-item-indicator total"} />
            {labelsData?.total?.label}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
