import ScopeProvider from "./ScopeManagement/ScopeProvider/ScopeProvider";
import FilterProvider from "./FilterManagement/FilterProvider/FilterProvider";
import ScopeManagement from "./ScopeManagement/ScopeManagement";
import useScope, {
  ScopeManagementContext,
} from "../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/useScope";
import FilterManagement from "./FilterManagement/FilterManagement";
import "./AppView.scss";
import React, { useContext } from "react";
import ShareAppView from "./ShareAppView/ShareAppView";
import DownloadFindings from "./DownloadFindings/DownloadFindings";
import Button from "../Forms/Controls/Button/Button";
import { GeneralAppContext } from "../General/GeneralAppProvider";

const AppView = ({
  children,
  setDefaultsForScopeAndFilter = true,
  editable = false,
  enableSharing = false,
  enableDownload = false,
}) => {
  const { isOpen, toggleIsOpen } = useScope();
  const {
    appContext: { isFiltersToggleOpen, disableAppView },
    updateAppState,
  } = useContext(GeneralAppContext);
  return (
    <ScopeProvider>
      <FilterProvider>
        <div className="app-view-wrapper-placeholder" />
        <div className={`app-view-wrapper`}>
          <div className="scope-and-filters-header">
            <ScopeManagementContext.Provider
              value={{ isOpen, toggleIsOpen, setDefaultsForScopeAndFilter }}
            >
              <ScopeManagement editable={editable} disabled={disableAppView} />
            </ScopeManagementContext.Provider>
            <div className={`app-view-separator-wrap`}>
              <div className={`app-view-separator`} />
            </div>
            <FilterManagement
              setDefaultsForScopeAndFilter={setDefaultsForScopeAndFilter}
              readOnly={disableAppView}
            />
            <ShareAppView enableSharing={enableSharing} />
            <DownloadFindings enableDownload={enableDownload} />
          </div>
          <div className="toggle-filters-panel-wrap">
            <Button
              wrapperClassName={`toggle-filters-panel`}
              type="button"
              // isIcon="seem-icon-chevron-up"
              isIcon="seem-icon-chevron-down"
              isClean
              isXs
              title={"Toggle"}
              onClick={() => {
                updateAppState({ isFiltersToggleOpen: !isFiltersToggleOpen });
              }}
            />
          </div>
        </div>
        {children}
      </FilterProvider>
    </ScopeProvider>
  );
};

export default AppView;
