import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./UploadBulkFindings.scss";
import Papa from "papaparse/papaparse.min";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "@apollo/client";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import { CREATE_FINDINGS } from "../ManualFindingsApi";
import Button from "../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import {
  csvTemplateForManualFindings,
  manualFindingCsvFieldsEnum,
} from "../../FindingsUtils";

const UploadBulkFindings = () => {
  const [manualFindings, setManualFindings] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const { closeModal } = useContext(ModalContext);
  const [createManualFindings] = useMutation(CREATE_FINDINGS, {
    refetchQueries: ["GetFindingsByGroup", "GetFindings"],
  });

  const onDrop = useCallback((acceptedFiles) => {
    switch (acceptedFiles[0]?.name?.split(".")?.pop()?.toLowerCase()) {
      case "csv":
        setErrorMessage(null);
        setFileName(acceptedFiles[0].name);
        Papa.parse(acceptedFiles[0], {
          complete: readFileData,
          header: false,
          download: true,
        });
        break;

      case "xlsx":
        setErrorMessage(null);
        setFileName(acceptedFiles[0].name);
        readXlsxFile(acceptedFiles[0])
          .then((data) => {
            readFileData({ data: data });
          })
          .catch(() => {
            reset("Cannot parse this file.");
          });
        break;

      default:
        reset("Wrong file type selected. Only CSV, Xlsx files supported.");
        break;
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const reset = (errorMessageToSet = null) => {
    setManualFindings([]);
    setFileName(null);
    setErrorMessage(errorMessageToSet);
  };

  const readFileData = (result) => {
    let data = result?.data;
    let manualFindingsMutationData = [];

    if (!data || !data?.length) {
      setErrorMessage("Cannot read file");
      return;
    }

    let headers = data[0];

    if (
      data?.length < 2 ||
      (data?.length === 2 &&
        !!data[1] &&
        data[1][0] === "Start entering values in this line")
    ) {
      setErrorMessage("Empty file");
      return;
    }

    for (let i = 1; i < data?.length; i++) {
      let obj = {};
      obj.resources = [];
      let resourceName = "";
      let resourceType = "";
      let text = "";
      let url = "";
      let currentLine = data[i];

      for (let j = 0; j < headers.length; j++) {
        switch (headers[j]) {
          case manualFindingCsvFieldsEnum.CLOUD_ACCOUNT.text: {
            if (!!currentLine[j]) {
              obj.resources.push({
                resource_name: currentLine[j],
                resource_type: "cloud_account",
              });
            }
            break;
          }
          case manualFindingCsvFieldsEnum.TITLE.text: {
            if (!!currentLine[j]) {
              obj[manualFindingCsvFieldsEnum.TITLE.fieldName] = currentLine[j];
            } else {
              setErrorMessage("Title is missing");
              return;
            }
            break;
          }
          case manualFindingCsvFieldsEnum.RESOURCE_NAME.text: {
            if (!!currentLine[j]) {
              resourceName = currentLine[j];
            } else {
              setErrorMessage("Resource Name is missing");
              return;
            }
            break;
          }
          case manualFindingCsvFieldsEnum.RESOURCE_TYPE.text: {
            resourceType = currentLine[j];
            break;
          }
          case manualFindingCsvFieldsEnum.REMEDIATION_TEXT.text: {
            text = currentLine[j];
            break;
          }
          case manualFindingCsvFieldsEnum.REMEDIATION_URL.text: {
            url = currentLine[j];
            break;
          }
          case manualFindingCsvFieldsEnum.ORIGINAL_SCORE.text: {
            if (currentLine[j] !== "") {
              let parsed = parseInt(currentLine[j]);
              if (isNaN(parsed) || parsed > 10 || parsed < 1) {
                setErrorMessage("Score should be number between 1-10");
                return;
              } else {
                obj[manualFindingCsvFieldsEnum.ORIGINAL_SCORE.fieldName] =
                  parsed;
              }
            } else {
              setErrorMessage("Score is missing");
              return;
            }
            break;
          }
          case manualFindingCsvFieldsEnum.DISCOVERED_TIME.text: {
            if (!!currentLine[j]) {
              if (new Date(currentLine[j]).toString() === "Invalid Date") {
                setErrorMessage("Invalid Date in Discovered time");
                return;
              } else if (new Date() < new Date(currentLine[j])) {
                setErrorMessage("Date in the future in Discovered time");
                return;
              } else {
                if (moment(currentLine[j], "YYYY-MM-DD", true).isValid()) {
                  obj[manualFindingCsvFieldsEnum.DISCOVERED_TIME.fieldName] =
                    currentLine[j];
                } else {
                  setErrorMessage("Discovered Time is not in the right format");
                  return;
                }
              }
            } else {
              obj[manualFindingCsvFieldsEnum.DISCOVERED_TIME.fieldName] =
                moment().format("YYYY-MM-DD");
            }
            break;
          }
          default: {
            const field = Object.values(manualFindingCsvFieldsEnum).find(
              (field) => field.text === headers[j]
            );

            if (!!field) {
              obj[field.fieldName] = currentLine[j];
            }
            break;
          }
        }
      }

      obj.remediation = {
        text: text,
        url: url,
      };

      obj.resources.push({
        resource_name: resourceName,
        resource_type: resourceType || "other",
      });

      manualFindingsMutationData.push(obj);
    }

    setManualFindings(manualFindingsMutationData);
  };

  const uploadCSV = () => {
    if (!!manualFindings.length) {
      setIsSaving(true);
      createManualFindings({ variables: { manual_findings: manualFindings } })
        .then(() => {
          closeModal();
          showToast({
            message: <div>{"Findings have been successfully created."}</div>,
          });
        })
        .catch((err) => {
          showErrorDialog({
            message:
              err?.networkError?.statusCode === 400
                ? "Failed to parse the CSV file. Please contact support."
                : err.message,
          });
          setIsSaving(false);
        });
    }
  };

  return (
    <div className="modal-content-inner">
      {!manualFindings.length && !errorMessage ? (
        <div
          {...getRootProps()}
          className={`dropzone-container ${isDragActive ? "is-hover" : ""}`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <span>Drop files here</span>
          ) : (
            <span style={{ textAlign: "center" }}>
              Drag & Drop your file, or click to browse
            </span>
          )}
        </div>
      ) : (
        <div className={"dropzone-container dropzone-container-uploaded"}>
          <span
            className={`dropzone-container-file ${
              errorMessage ? "dropzone-container-file-error" : ""
            }`}
          >
            {errorMessage ? (
              <div className={`error-message-container`}>
                <div className={`error-message`}>
                  <p>{errorMessage}</p>
                </div>
                <div className={`error-button`}>
                  <Button
                    data-testid={"reupload"}
                    label={t("Upload Again")}
                    onClick={() => reset()}
                  />
                </div>
              </div>
            ) : (
              <div className={`valid-message-container`}>
                <p className={`filename-message`}>{fileName}</p>
                <Button
                  data-testid={"remove"}
                  type="button"
                  isIcon="seem-icon-bin"
                  isClean
                  title={"Remove file"}
                  onClick={() => reset()}
                />
              </div>
            )}
          </span>
        </div>
      )}

      <div className={`modal-buttons-container`}>
        <div className="align-left">
          <CSVLink
            data={csvTemplateForManualFindings}
            filename={"seemplicity_csv_findings"}
          >
            <Button
              data-testid={"download-csv"}
              isSecondaryInvert
              label={t("Download a CSV template")}
            />
          </CSVLink>
        </div>

        <div className={"form-button-group align-end"}>
          <Button
            data-testid={"upload-csv"}
            disabled={!manualFindings.length || isSaving}
            isSecondary
            label={t("Upload CSV file")}
            onClick={() => uploadCSV()}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadBulkFindings;
