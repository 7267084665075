import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  AGGREGATE_FINDINGS,
  GET_ALL_RESOURCES,
  PREPARE_AGGREGATE_FINDINGS,
} from "./ManualAggregateFindingsApi";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ManualFinding, {
  manualFindingStates,
} from "../ManualFinding/ManualFinding";
import AggregateWithTicketsPopup from "./AggregateWithTicketsPopup/AggregateWithTicketsPopup";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import "./ManualAggregateFindings.scss";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import AlertIndicator, {
  alertTypes,
} from "../../Components/AlertIndicator/AlertIndicator";
import AsyncProgressMessage from "../FindingsData/FindingsList/FindingsActions/AsyncProgressMessage/AsyncProgressMessage";
import { taskNames } from "../../WebSocket/WebSocketUtils";
import { GeneralAppContext } from "../../General/GeneralAppProvider";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { useHistory } from "react-router-dom";
import ResourcesList from "../FindingDetailsPane/Resources/ResourcesList/ResourcesList";
import Button from "../../Forms/Controls/Button/Button";
import { trimWhiteSpaces } from "../../Tools/StringUtils";

const LS_AGGREGATE_FINDINGS_TASK_ID = "aggregate_findings_task_id";

const ManualAggregateFindings = ({
  filters_config,
  totalCount,
  updateFindingsState,
}) => {
  const [aggregateFindingData, setAggregateFindingData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [aggregateWithTickets, setAggregateWithTickets] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayProgress, setDisplayProgress] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [aggregatedFindingId, setAggregatedFindingId] = useState(null);
  const { closeModal, hideCloseButton } = useContext(ModalContext);
  const [shouldNavigateToFinding, setShouldNavigateToFinding] = useState(false);
  const {
    data: aggregationData,
    loading,
    error: prepareError,
  } = useQuery(PREPARE_AGGREGATE_FINDINGS, {
    variables: { filters_config, number_of_resources: 11 },
    fetchPolicy: "network-only",
  });

  const [getAllResources, { loading: resourcesLoading }] = useLazyQuery(
    GET_ALL_RESOURCES,
    {
      variables: { filters_config, number_of_resources: 0 },
      fetchPolicy: "no-cache",
      onCompleted: (resourcesData) => {
        setAggregateFindingData({
          ...aggregateFindingData,
          resources: resourcesData?.prepare_aggregate_findings?.resources,
          showAllResources: true,
        });
      },
    }
  );

  const [aggregateFindings] = useMutation(AGGREGATE_FINDINGS);
  const client = useApolloClient();
  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);
  const history = useHistory();

  useEffect(() => {
    if (!!aggregationData) {
      setAggregateFindingData(aggregationData?.prepare_aggregate_findings);
    }
  }, [aggregationData]);

  const onSubmit = (data, close_tickets) => {
    setIsSubmitting(true);
    const aggregateFinding = {
      filters_config,
      close_tickets,
      incoming_fields: {
        title: data?.title,
        description: data?.description,
        remediation: JSON?.stringify({
          text: data?.remediation,
          url: "",
        }),
        dominant_finding_id: aggregateFindingData?.dominant_finding_id,
      },
    };

    aggregateFindings({ variables: aggregateFinding })
      .then((res) => {
        if (res?.data?.aggregate_findings?.ok) {
          hideCloseButton();
          localStorage.setItem(
            LS_AGGREGATE_FINDINGS_TASK_ID,
            aggregateFindingData?.dominant_finding_id
          );
          setAggregatedFindingId(res?.data?.aggregate_findings?.new_finding_id);
          setDisplayProgress(true);
        } else {
          setIsSubmitting(false);
          showErrorDialog({
            message: "An error occurred while trying to aggregate findings",
          });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        showErrorDialog({ message: error.message });
      });
  };

  useEffect(() => {
    if (isClosing) {
      client
        .refetchQueries({
          include: ["GetFindings", "GetFindingsByGroup"],
        })
        .then(() => {
          resetFindingsList && resetFindingsList();
          if (shouldNavigateToFinding) {
            history.push(`/findings/${aggregatedFindingId}`);
          }
          closeModal();
        });
    }
  }, [isClosing, shouldNavigateToFinding]);

  const progressMessage = useMemo(() => {
    return (
      <AsyncProgressMessage
        useDisabled
        description={`We are aggregating ${(
          totalCount - aggregateFindingData?.number_of_previously_aggregated
        )?.toLocaleString()} findings into 1 aggregated finding.`}
        taskName={taskNames.AGGREGATE_FINDINGS_STATUS}
        lsItem={LS_AGGREGATE_FINDINGS_TASK_ID}
        isClosing={isClosing}
        setIsClosing={setIsClosing}
        closeButtonProps={{
          rightIcon:
            isClosing && !shouldNavigateToFinding
              ? "seem-icon-spinner spinner"
              : "",
        }}
        buttons={[
          {
            rightIcon:
              isClosing && shouldNavigateToFinding
                ? "seem-icon-spinner spinner"
                : "",
            "data-testid": "view-aggregate-finding",
            isSecondary: true,
            label: "View Aggregated Finding",
            onClick: () => {
              setIsClosing(true);
              setShouldNavigateToFinding(true);
              updateFindingsState({
                scrollToIndex: { index: null, cursor: null },
              });
            },
          },
        ]}
      />
    );
  }, [
    totalCount,
    isClosing,
    aggregateFindingData?.number_of_previously_aggregated,
    aggregatedFindingId,
  ]);

  const resourcesData = useMemo(() => {
    return aggregationData?.showAllResources
      ? aggregateFindingData?.resources
      : aggregateFindingData?.resources?.slice(0, 10);
  }, [aggregateFindingData?.showAllResources, aggregateFindingData?.resources]);

  const remediationText = useMemo(() => {
    let remediationObject, remediationString;
    try {
      remediationObject = JSON.parse(
        aggregateFindingData?.suggested_remediation
      );
      remediationString = trimWhiteSpaces(
        `${remediationObject?.text} ${remediationObject.url}`
      );
    } catch (e) {
      remediationString = "";
    }

    return remediationString;
  }, [aggregateFindingData?.suggested_remediation]);

  return displayProgress ? (
    <>{progressMessage}</>
  ) : prepareError ? (
    <Loader
      center
      title={
        "An error occurred while trying to prepare findings for aggregation"
      }
      subtitle={"Please contact support."}
      action={{ label: "Close", onClick: () => closeModal() }}
      className={"prepare-aggregation-error"}
    />
  ) : loading || !aggregateFindingData ? (
    <Loader center img={Spinner} />
  ) : !aggregateWithTickets ? (
    <>
      <AlertIndicator
        type={
          aggregateFindingData?.number_of_previously_aggregated === 0
            ? alertTypes.INFO
            : alertTypes.WARNING
        }
        content={
          aggregateFindingData?.number_of_previously_aggregated === 0 ? (
            <div className={`alert-indicator-text`}>
              <p>
                {`This action will group the `}
                <span
                  className={`findings-count-to-change`}
                >{`${totalCount?.toLocaleString()} selected findings`}</span>
                {` into a single aggregated finding.`}
              </p>
            </div>
          ) : (
            <div className={`alert-indicator-text`}>
              <p>
                {`This action will group the `}
                <span className={`findings-count-to-change`}>{`${(
                  totalCount -
                  aggregateFindingData?.number_of_previously_aggregated
                )?.toLocaleString()} out of ${totalCount?.toLocaleString()} selected findings`}</span>
                {` into a single aggregated finding.`}
              </p>
              <p>
                <span>{`Other ${aggregateFindingData?.number_of_previously_aggregated?.toLocaleString()} findings are already aggregated and will remain as they are currently.`}</span>
              </p>
            </div>
          )
        }
      />
      <ManualFinding
        state={manualFindingStates.AGGREGATE}
        data={{
          title: aggregateFindingData?.suggested_title,
          description: aggregateFindingData?.suggested_description,
          date: aggregateFindingData?.discovered_time,
          score: aggregateFindingData?.score,
          severity: aggregateFindingData?.severity,
          cloud_account: aggregateFindingData?.cloud_account,
          remediation: remediationText,
        }}
        onFormSubmit={(data) => {
          if (!aggregateFindingData?.has_tickets) {
            onSubmit(data, false);
          } else {
            setFormData(data);
            setAggregateWithTickets(true);
          }
        }}
        additionalData={{
          component: (
            <div className={`resources-fields`}>
              <ResourcesList resources={resourcesData} />
              {resourcesLoading && (
                <div className={`row-status show-all-resources`}>
                  <i className={"seem-icon-spinner spinner"} />
                  <span>Loading...</span>
                </div>
              )}
              {!aggregateFindingData?.showAllResources &&
                aggregateFindingData?.resources?.length > 10 &&
                !resourcesLoading && (
                  <Button
                    data-testid={"show-all-resources"}
                    type={"button"}
                    label={`Show All Resources`}
                    textLink
                    onClick={() => {
                      getAllResources();
                    }}
                    className={"show-all-resources"}
                    wrapperClassName={"show-all-resources-btn-wrap"}
                  />
                )}
            </div>
          ),
          title: "View Related Resources",
        }}
      />
    </>
  ) : (
    <AggregateWithTicketsPopup
      onBackClick={() => setAggregateWithTickets(false)}
      onAggregateClick={(close_tickets) => onSubmit(formData, close_tickets)}
      isSubmitting={isSubmitting}
    />
  );
};

export default ManualAggregateFindings;
