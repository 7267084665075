import React, { useContext, useEffect, useState } from "react";
import "./SyncedQueueFindings.scss";
import RMQFindingsTable from "../RMQFindingsTable/RMQFindingsTable";
// import { aggregatedStatus } from "../../../../Findings/FindingsUtils";
import { RemediationQueueContext } from "../../RemediationQueueProvider";
import {
  findingsTicketsColumns,
  findingsWithoutTicketsColumns,
} from "../../../RemediationQueuesUtils";
import { ticketManagersEnum } from "../../../../Ticketing/TicketingUtils";

const SyncedQueueFindings = () => {
  const [countInTicketManager, setCountInTicketManager] = useState(null);
  const [countNotInTicketManager, setCountNotInTicketManager] = useState(null);
  const [paneInTicketManagerOpen, setPaneInTicketManagerOpen] = useState(false);
  const [paneNotInTicketManagerOpen, setPaneNotInTicketManagerOpen] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const {
    remediationQueueData: { scope_id, filterjson, priority, providerType },
    updateRemediationQueueData,
  } = useContext(RemediationQueueContext);

  const jiraStatusFilter = (filter, condition, statusList) => {
    // const actualStatusFilter = {
    //   field: "actual_status",
    //   condition: "",
    //   value: {
    //     field: "key",
    //     condition: "in",
    //     value: aggregatedStatus.OPEN,
    //   },
    // };

    const ticketStatusFilter = {
      field: "ticket_status",
      condition: condition,
      value: statusList,
    };

    if (!!filter) {
      return {
        operator: "and",
        operands: [ticketStatusFilter, JSON.parse(filter)],
      };
    } else {
      return {
        operator: "and",
        operands: [ticketStatusFilter],
      };
    }
  };

  const selectFindingInTicketManager = (finding, toggle, onSelectedFinding) => {
    if (paneNotInTicketManagerOpen) {
      setPaneNotInTicketManagerOpen(false);
    }

    onSelectedFinding(finding, toggle);
    setPaneInTicketManagerOpen(toggle);
  };

  const selectFindingNotInTicketManager = (
    finding,
    toggle,
    onSelectedFinding
  ) => {
    if (paneInTicketManagerOpen) {
      setPaneInTicketManagerOpen(false);
    }

    onSelectedFinding(finding, toggle);
    setPaneNotInTicketManagerOpen(toggle);
  };

  useEffect(() => {
    if (
      Number.isInteger(countInTicketManager) &&
      Number.isInteger(countNotInTicketManager)
    ) {
      setTotalCount(countInTicketManager + countNotInTicketManager);
      updateRemediationQueueData({
        syncedFindingsCount: countInTicketManager,
        remainingFindingsCount: countNotInTicketManager,
      });
    }
  }, [countInTicketManager, countNotInTicketManager]);

  const getSyncedTableFilter = (filter) => {
    const parsedFilter = JSON.parse(filter);
    let hasTicketStatusOperand = false;

    const getTicketStatusOperand = (operands) => {
      operands?.forEach((operand) => {
        if (operand?.field === "ticket_status") {
          hasTicketStatusOperand = true;
        } else if (operand?.operands) {
          getTicketStatusOperand(operand?.operands);
        }
      });
    };

    getTicketStatusOperand(parsedFilter?.operands);

    if (
      hasTicketStatusOperand &&
      providerType === ticketManagersEnum.SEEMPLICITY
    ) {
      return filter;
    } else {
      return JSON.stringify(
        jiraStatusFilter(filterjson, "in", [
          "BACKLOG",
          "SCHEDULED",
          "IN_PROGRESS",
        ])
      );
    }
  };

  return (
    <div className={"rmq-tables-wrap"}>
      <RMQFindingsTable
        priority={priority}
        scope={scope_id}
        filter={getSyncedTableFilter(filterjson)}
        title={`{{count}} Findings synced (${totalCount} total)`}
        onCountChange={setCountInTicketManager}
        columns={findingsTicketsColumns}
        onPaneChange={selectFindingInTicketManager}
        isPaneOpen={paneInTicketManagerOpen}
        className={"rmqfs-table with-tickets"}
      />
      <RMQFindingsTable
        priority={priority}
        scope={scope_id}
        filter={JSON.stringify(
          jiraStatusFilter(filterjson, "not_in", [
            "BACKLOG",
            "SCHEDULED",
            "IN_PROGRESS",
            "REJECTED",
            "DONE",
          ])
        )}
        title={`{{count}} Findings in waiting list (${totalCount} total)`}
        onCountChange={setCountNotInTicketManager}
        columns={findingsWithoutTicketsColumns}
        onPaneChange={selectFindingNotInTicketManager}
        isPaneOpen={paneNotInTicketManagerOpen}
        className={"rmqfwl-table"}
      />
    </div>
  );
};

export default SyncedQueueFindings;
