import React, { useState } from "react";
import "./SelectionMenu.scss";

export const useMenuState = (initialValue) => {
  const [menuState, setMenuState] = useState(initialValue);

  const updateMenuState = (state) => {
    setMenuState(state);
  };

  return {
    menuState,
    updateMenuState,
  };
};

const SelectionMenu = ({
  menuItems,
  onChange,
  selectedIndex,
  label,
  type = "secondary",
}) => {
  return (
    <div className={`selection-menu-wrapper ${type}-menu`}>
      <ul
        className={`selection-menu-list selection-menu-list-selected-${selectedIndex}`}
      >
        <div className="selection-bg" />
        {menuItems.map((item, index) => {
          const isActive = index === selectedIndex;
          const isDisabled = item.isDisabled;

          if (item.isHidden) {
            return <></>;
          }
          return (
            <li
              key={index}
              className={`selection-menu-option ${
                isActive ? "is-active" : ""
              } ${isDisabled ? "is-disabled" : ""}`}
              onClick={() => onChange(index, item.value)}
            >
              {!!item.leftIcon && (
                <i className={`seem-icon ${item.leftIcon}`} />
              )}
              {!!item.leftImg && (
                <div
                  style={{ backgroundImage: `url(${item.leftImg})` }}
                  className={`selection-img`}
                />
              )}
              <span className="selection-menu-option-text">{item.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SelectionMenu;
