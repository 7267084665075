import { useMutation, useQuery } from "@apollo/client";
import {
  CNC_GET_USERS,
  RESET_USER_PASSWORD,
  CNC_INVITE_USER,
  CNC_DELETE_USER,
  CNC_EDIT_USER,
} from "../CustomerProvisioningApi";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../Forms/Controls/Button/Button";
import {
  showDialog,
  showErrorDialog,
} from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import Table from "../../Components/Table/Table";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import "./ManageDomainUsers.scss";
import ChangeUserPassword from "../ChangeUserPassword/ChangeUserPassword";
import UserActions from "../../AppSettings/UsersList/UserActions/UserActions";
import { userActions } from "../../AppSettings/UsersList/UsersListUtils";
import { CNC_GET_PROFILES } from "../../AppSettings/Permissions/PermissionsApi";
import ReactTooltip from "react-tooltip";

const ManageDomainUsers = ({ customerID, domains }) => {
  const { loading, data: usersData } = useQuery(CNC_GET_USERS, {
    variables: {
      customer_id: customerID,
    },
  });
  const { data: profilesData } = useQuery(CNC_GET_PROFILES, {
    variables: {
      customer_id: customerID,
    },
  });
  const [resetUserPassword] = useMutation(RESET_USER_PASSWORD);
  const [inviteUser] = useMutation(CNC_INVITE_USER);
  const [deleteUser] = useMutation(CNC_DELETE_USER);
  const [editUser] = useMutation(CNC_EDIT_USER);
  const [profilesSuggestions, setProfilesSuggestions] = useState([]);
  const [users, setUsers] = useState([]);
  const { openModal, closeModal } = useContext(ModalContext);

  const queryArray = useMemo(
    () => [
      {
        query: CNC_GET_PROFILES,
        variables: {
          customer_id: customerID,
        },
      },
      {
        query: CNC_GET_USERS,
        variables: {
          customer_id: customerID,
        },
      },
    ],
    [customerID]
  );

  const columns = React.useMemo(
    () => [
      {
        id: "email",
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
      },
      {
        id: "id",
        Header: "",
        accessor: "id",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                wrapperClassName={"change-pass-btn"}
                data-testid={`${cellProps.value}-change-pass`}
                isSecondary
                label={"Change Password"}
                onClick={() => {
                  openModal({
                    title: "Change Password",
                    size: "s",
                    overlay: true,
                    component: (
                      <ChangeUserPassword
                        customerID={customerID}
                        userID={cellProps.value}
                      />
                    ),
                  });
                }}
              />

              <Button
                data-testid={`${cellProps.value}-reset-pass`}
                label={"Reset password"}
                isSecondary
                onClick={() => {
                  showDialog({
                    title: "Reset Password",
                    message: `Are you sure you want to reset ${cellProps.row.values.email}'s password?`,
                    buttons: [
                      {
                        label: "Cancel",
                      },
                      {
                        "data-testid": "reset-user-pass-confirm",
                        isSecondary: true,
                        label: "Reset password",
                        onClick: () => {
                          onResetPassword(cellProps.value);
                        },
                      },
                    ],
                  });
                }}
              />
            </>
          );
        },
      },
      {
        id: "actions",
        Header: "",
        width: 65,
        disableFilters: true,
        Cell: (value) => {
          const email = value.row?.values?.email;
          const firstName = value.row?.original?.first_name;
          const lastName = value.row?.original?.last_name;
          const userId = value.row?.original?.id;
          const role = value?.row?.original?.role;
          const profiles = value?.row?.original?.profiles;

          return (
            <div className={"users-list-actions users-list-cell"}>
              <Button
                data-testid={"edit-user"}
                isClean
                tooltipData={{
                  dataFor: `edit-user-btn-${value.row?.id}`,
                  dataTip: true,
                }}
                isIcon="seem-icon-edit"
                type={`button`}
                onClick={() =>
                  openModal({
                    title: "Edit User",
                    size: "xs",
                    overlay: true,
                    component: (
                      <UserActions
                        mode={userActions.EDIT}
                        data={{
                          email,
                          firstName,
                          lastName,
                          id: userId,
                          role,
                          profiles,
                        }}
                        profilesSuggestions={profilesSuggestions}
                        onSubmit={handleEditing}
                      />
                    ),
                  })
                }
                title={"Edit"}
              />
              <ReactTooltip id={`edit-user-btn-${value.row?.id}`} place="top">
                Edit User
              </ReactTooltip>

              <Button
                data-testid={"delete-user"}
                isClean
                tooltipData={{
                  dataFor: `delete-user-btn-${value.row?.id}`,
                  dataTip: true,
                }}
                isIcon="seem-icon-bin"
                type={`button`}
                onClick={() =>
                  showDialog({
                    title: "Delete User",
                    message: (
                      <div>
                        {`Are you sure you want to delete `}
                        <span
                          className={"user-name-bold"}
                        >{`${value.row?.values?.email}`}</span>
                        {`?`}
                      </div>
                    ),
                    buttons: [
                      {
                        "data-testid": "cancel-delete",
                        label: "Cancel",
                      },
                      {
                        "data-testid": "confirm-delete",
                        isSecondary: true,
                        label: "Delete",
                        type: "submit",
                        onClick: () => handleDeletion(userId),
                      },
                    ],
                  })
                }
                title={"Delete"}
              />
              <ReactTooltip id={`delete-user-btn-${value.row?.id}`} place="top">
                Delete User
              </ReactTooltip>
            </div>
          );
        },
      },
    ],
    []
  );

  const onResetPassword = (userID) => {
    resetUserPassword({
      variables: { customer_id: customerID, user_id: userID },
    })
      .then((res) => {
        if (!!res?.data?.cnc_reset_user_password?.ok) {
          closeModal();
          showToast({ message: res?.data?.cnc_reset_user_password?.msg });
        } else {
          showErrorDialog({ message: res.data?.cnc_reset_user_password?.msg });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  const createUser = ({
    email,
    firstName,
    lastName,
    role,
    profiles,
    withoutEmail,
    password,
  }) => {
    return inviteUser({
      variables: {
        customer_id: customerID,
        user: {
          first_name: firstName,
          last_name: lastName ? lastName : "",
          email: email,
          role_id: role?.value,
          profile_ids: Array.isArray(profiles)
            ? profiles.map((el) => el.id)
            : undefined,
          password: password,
        },
        send_email: !withoutEmail,
      },
      refetchQueries: queryArray,
    })
      .then((res) => {
        if (res.data?.cnc_invite_user?.ok) {
          closeModal();
          showDialog({
            title: "User Added",
            message: (
              <div className={"user-added-success-msg"}>
                <i className={"seem-icon seem-icon-add-user"} />
                <span className={"user-name-bold"}>{`${firstName} ${
                  lastName ? lastName : ""
                }`}</span>
                {withoutEmail
                  ? " created successfully with a temporary password."
                  : ` will receive an invitation email with a temporary password.`}
              </div>
            ),
            buttons: [
              {
                "data-testid": "close-addition-success",
                label: "Close",
              },
            ],
          });
        } else {
          showErrorDialog({ message: res.data?.cnc_invite_user?.msg });
        }
      })
      .catch((error) => showErrorDialog({ message: error.message }));
  };

  const handleEditing = ({ id, firstName, lastName, role, profiles }) => {
    return editUser({
      variables: {
        customer_id: customerID,
        user_id: id,
        first_name: firstName,
        last_name: lastName,
        role_id: role?.value,
        profile_ids: Array.isArray(profiles)
          ? profiles.map((el) => el.id)
          : undefined,
      },
      refetchQueries: queryArray,
    })
      .then((res) => {
        if (res.data?.cnc_change_user_fields?.ok) {
          closeModal();
          showToast({ message: `${res.data?.cnc_change_user_fields?.msg}` });
        } else {
          showErrorDialog({ message: res.data?.cnc_change_user_fields?.msg });
        }
      })
      .catch((error) => showErrorDialog({ message: error.message }));
  };

  const handleDeletion = (id) => {
    deleteUser({
      variables: { user_id: id, customer_id: customerID },
      refetchQueries: queryArray,
    })
      .then((res) => {
        if (res.data?.cnc_delete_user?.ok) {
          showToast({ message: res.data?.cnc_delete_user?.msg });
        } else {
          showErrorDialog({ message: res.data?.cnc_delete_user?.msg });
        }
      })
      .catch((error) => {
        showErrorDialog({ message: error.message });
      });
  };

  useEffect(() => {
    if (!!usersData) {
      const formattedUsers = usersData?.cnc_users?.edges?.map((user) => {
        const userProfiles = user.node?.profiles?.edges?.map(
          (profile) => profile.node
        );
        return { ...user.node, profiles: userProfiles };
      });

      setUsers(formattedUsers);
    }
  }, [usersData]);

  useEffect(() => {
    if (!!profilesData) {
      const formattedProfiles = profilesData?.cnc_profiles?.edges?.map(
        (profile) => {
          return {
            id: profile?.node?.id,
            name: profile?.node?.name,
            hasScopes:
              profile?.node?.scopes?.edges?.length ||
              profile?.node?.scope_groups?.edges?.length,
          };
        }
      );

      setProfilesSuggestions(formattedProfiles);
    }
  }, [profilesData]);

  return (
    <div className={"users-table-container"}>
      <Button
        isSecondary
        wrapperClassName={"sidebar-display-area-top-button"}
        label={"+ Add User"}
        isXl
        data-testid={"add-user"}
        onClick={() =>
          openModal({
            title: "Add User",
            size: "xs",
            overlay: true,
            component: (
              <UserActions
                mode={userActions.CNC_CREATE}
                allowedDomains={domains}
                onSubmit={createUser}
                profilesSuggestions={profilesSuggestions}
              />
            ),
          })
        }
        title={"Add"}
      />
      <Table
        tableWrapperClass={`users-table`}
        loading={loading}
        columns={columns}
        data={users}
        data-testid-body={"users"}
      />
    </div>
  );
};
export default ManageDomainUsers;
