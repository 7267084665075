import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  filterTypes,
  findingsQueryParams,
} from "../../../Findings/FindingsUtils";
import { FiltersContext } from "../FilterProvider/FilterProvider";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import {
  getFilterJson,
  getQueuePluralsByCount,
  processFilter,
} from "../../AppViewUtils";
import Filter from "./Filter/Filter";
import "./FilterPanel.scss";
import Button from "../../../Forms/Controls/Button/Button";
import { GeneralAppContext } from "../../../General/GeneralAppProvider";
import { LS_FILTER } from "../../../Findings/FindingsFilters/FilterUtils";
import { dismissToast, showToast } from "../../../Forms/Dialogs/Toast/Toast";
import {
  showDialog,
  showErrorDialog,
} from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  SAVE_FILTER,
  UPDATE_FILTER,
} from "../../../Findings/FindingsFilters/FindingsFilterApi";
import FilterSaveAs from "../../../Findings/FindingsFilters/FilterSaveAs/FilterSaveAs";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import useRecentFilters from "../useRecentFilters";
import useFiltersData from "../../useFiltersData";
import UnsavedDataToast from "../../../Forms/Dialogs/Toast/UnsavedDataToast";
import { useMeasure } from "react-use";
import ReactTooltip from "react-tooltip";
import useFindingsQueryParam from "../../../Findings/FindingsData/useFindingsQueryParam";

const FilterPanel = ({
  hasEditMode,
  readOnly,
  effectedSingleQueueName,
  onFilterSave,
}) => {
  const {
    filtersState: { selectedFilter, isEditFilter, shouldBlinkField },
    updateFiltersState,
  } = useContext(FiltersContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const client = useApolloClient();
  const {
    appContext: { resetFindingsList },
    updateAppState,
  } = useContext(GeneralAppContext);

  const [updateFilter] = useMutation(UPDATE_FILTER);

  const [saveFilter] = useMutation(SAVE_FILTER, {
    refetchQueries: ["filters", "scopes"],
  });

  const { addFilterToRecent } = useRecentFilters();
  const { clearQueryParam, search } = useFindingsQueryParam(null);

  const { fieldsData } = useFiltersData({
    filterType: filterTypes.FILTER,
  });

  const [ref, { width }] = useMeasure();
  const addFilterRef = useRef(null);
  const [menuOpen, setMenuOpen] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { field: false, condition: false, value: false }
  );

  const {
    formState: { isValid, isDirty },
    control,
    reset,
    getValues,
    handleSubmit,
    setValue,
  } = useFormContext();

  const [toastId, setToastId] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filterPanels",
  });

  const watchFieldArray = useWatch({
    control,
    name: `filterPanels`,
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const saveAsFilterNameValue = useWatch({
    control,
    name: `saveAsFilterName`,
  });

  const isLoading = useMemo(() => {
    return !selectedFilter || !fields?.length;
  }, [fields?.length, selectedFilter]);

  useEffect(() => {
    const filter =
      !!selectedFilter && selectedFilter?.value?.query
        ? JSON.parse(selectedFilter?.value?.query)
        : null;

    let filterObj = !!filter ? processFilter(filter) : [{}];

    if (!!filter) {
      filterObj = filterObj.filters;
    }

    reset({
      ...getValues(),
      filterPanels: filterObj,
    });
  }, [selectedFilter]);

  const updateDisabled = useMemo(() => {
    return (
      selectedFilter?.meta?.system ||
      !selectedFilter?.value?.editable ||
      !selectedFilter?.value?.id
    );
  }, [selectedFilter]);

  useEffect(() => {
    if (isDirty && isValid && !toastId) {
      const id = showToast({
        position: "bottom-right",
        hideProgressBar: true,
        autoClose: false,
        className: "unsaved-filter",
        closeOnClick: false,
        component: (
          <UnsavedDataToast
            message={
              <>
                <div>{`The active filter is unsaved.`}</div>
                <Button
                  data-testid={`scope-save-toast`}
                  label={"Save to easily use again"}
                  onClick={() => {
                    updateDisabled ? openSaveAsModal() : validateOnSave();
                    dismissToast(toastId);
                  }}
                />
              </>
            }
          />
        ),
      });

      setToastId(id);
    } else {
      dismissToast(toastId);
      setToastId(null);
    }
  }, [isDirty, isValid]);

  const onSubmit = (data, editMode) => {
    //preview:
    const filterToExecute = getFilterJson(data);

    if (isDirty) {
      addFilterToRecent(filterToExecute);
    }

    updateFiltersState({ filterToExecute, isEditFilter: editMode });
    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  const previewFilter = (editMode) => {
    handleSubmit((data) => onSubmit(data, editMode || false))();
  };

  const validateOnSave = () => {
    handleSubmit((data) => onSave(data, false))();
  };

  const onSave = (data, overwrite) => {
    let filterMutationData = {};
    const filter = getFilterJson(data);

    filterMutationData = {
      name: selectedFilter?.label,
      filterData: filter,
      filterType: filterTypes.FILTER,
    };

    filterMutationData.id = selectedFilter?.value?.id;
    filterMutationData.overwrite = overwrite;

    updateFilter({ variables: filterMutationData })
      .then((res) => {
        if (!overwrite && !res?.data?.update_finding_filter?.ok) {
          if (
            res?.data?.update_finding_filter?.effected_queues?.length === 1 &&
            !!effectedSingleQueueName &&
            res?.data?.update_finding_filter?.effected_queues?.includes(
              effectedSingleQueueName
            )
          ) {
            handleSubmit((data) => onSave(data, true))();
          } else if (
            res?.data?.update_finding_filter?.effected_queues?.length
          ) {
            showDialog({
              title: "Save Filter",
              type: "warning",
              description: `The filter "${
                selectedFilter?.label
              }" is part of an existing ${getQueuePluralsByCount(
                res?.data?.update_finding_filter?.effected_queues?.length
              )}.`,
              message: `Saving this filter might change the findings in the ${getQueuePluralsByCount(
                res?.data?.update_finding_filter?.effected_queues?.length
              )} "${res?.data?.update_finding_filter?.effected_queues?.join(
                ", "
              )}".`,
              buttons: [
                {
                  isSecondary: false,
                  label: "Cancel",
                },
                {
                  isSecondary: false,
                  label: "Save as a new filter",
                  onClick: () => {
                    openSaveAsModal();
                  },
                },
                {
                  isSecondary: true,
                  label: "Save and update queue",
                  onClick: () => {
                    handleSubmit((data) => onSave(data, true))();
                  },
                },
              ],
            });
          }
        } else if (!!res.data?.update_finding_filter?.ok) {
          const selectedFilter = {
            label: filterMutationData.name,
            value: {
              query: filter,
              id: filterMutationData.id,
              editable: true,
            },
          };

          updateFiltersState({
            filterToExecute: filter,
            selectedFilter,
            isEditFilter: false,
          });
          localStorage.setItem(LS_FILTER, JSON.stringify(selectedFilter));
          client.refetchQueries({ include: ["filters"] });
          if (resetFindingsList) {
            resetFindingsList();
          }
          onFilterSave(selectedFilter);
          showToast({ message: "Filter Updated!" });
        } else {
          showErrorDialog({ message: "Filter update failed" });
        }
      })
      .catch((err) => showErrorDialog({ message: err.message }));
  };

  const onSaveAs = (data) => {
    let filterMutationData = {};
    const filter = getFilterJson(data);

    filterMutationData = {
      name: saveAsFilterNameValue,
      filterData: filter,
      filterType: filterTypes.FILTER,
    };

    saveFilter({ variables: filterMutationData })
      .then((res) => {
        const newFilter = res.data?.new_finding_filter?.finding_filter;

        const selectedFilter = {
          label: newFilter.name,
          value: {
            query: newFilter.filter_data,
            id: newFilter.id,
            editable: true,
          },
        };

        if (!!search) {
          clearQueryParam([
            findingsQueryParams.FILTER_ID,
            findingsQueryParams.GROUP_VALUE,
            findingsQueryParams.FILTER_TO_EXECUTE,
          ]);
        }

        updateFiltersState({
          filterToExecute: filter,
          selectedFilter,
          isEditFilter: false,
        });
        localStorage.setItem(LS_FILTER, JSON.stringify(selectedFilter));

        if (resetFindingsList) {
          resetFindingsList();
        }
        onFilterSave(selectedFilter);
        closeModal();
        showToast({ message: "Filter Saved!" });
      })
      .catch((ex) => showErrorDialog({ message: ex.message }));
  };

  const openSaveAsModal = () => {
    openModal({
      title: "Save As",
      component: <FilterSaveAs setValue={setValue} />,
    });
  };

  useEffect(() => {
    if (!!saveAsFilterNameValue) {
      handleSubmit(onSaveAs)();
    }
  }, [saveAsFilterNameValue]);

  const filterElements = useRef([]);

  useEffect(() => {
    let sum = filterElements.current.reduce((prev, current) => {
      return prev + (current?.offsetWidth ? current?.offsetWidth : 0);
    }, 0);

    sum += addFilterRef?.current?.offsetWidth || 0;

    const isActiveMenu =
      menuOpen?.field || menuOpen?.condition || menuOpen?.value;

    if (sum > width) {
      updateAppState({
        layoutClass: `layout-filters-long ${
          isActiveMenu ? "filters-menu-active" : ""
        }`,
      });
    } else {
      updateAppState({
        layoutClass: `layout-filters-short ${
          isActiveMenu ? "filters-menu-active" : ""
        }`,
      });
    }
  }, [controlledFields?.length, width, addFilterRef?.current, menuOpen]);

  return (
    <form
      className={`filters-panel-form-wrapper filters-panel-form-wrapper-FormProvider`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={`filter-panel-data`}>
        {isLoading ? (
          <div className="dropdown-loading-indicator">
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
          </div>
        ) : !selectedFilter?.value?.query ? (
          <></>
        ) : (
          <div className={"filter-rows-wrap"}>
            <div className="filter-items-wrap" ref={ref}>
              {controlledFields?.map((filterItem, index) => {
                return (
                  <div
                    ref={(element) => (filterElements.current[index] = element)}
                  >
                    <Filter
                      filterItem={filterItem}
                      key={filterItem?.id}
                      namePrefix={`filterPanels[${index}]`}
                      append={append}
                      previewFilter={previewFilter}
                      itemIndex={index}
                      removeFilter={remove}
                      fieldsData={fieldsData}
                      isEditable={
                        !readOnly &&
                        ((hasEditMode && isEditFilter) || !hasEditMode) &&
                        selectedFilter?.value?.editable
                      }
                      selectedFields={controlledFields.map((el) => el?.field)}
                      setMenuOpen={setMenuOpen}
                      context={filterTypes.FILTER}
                      shouldBlinkField={shouldBlinkField}
                      updateFilter={updateFiltersState}
                    />
                  </div>
                );
              })}
              {((hasEditMode && isEditFilter) || !hasEditMode) &&
                selectedFilter?.value?.editable &&
                isValid &&
                !readOnly && (
                  <div ref={addFilterRef}>
                    <Button
                      data-testid={"add-filter-btn"}
                      label={"Add Filter"}
                      leftIcon={"seem-icon-filter-saved"}
                      textLink
                      onClick={() => {
                        append({ field: null, condition: null, value: "" });
                      }}
                    />
                  </div>
                )}
            </div>
            <div className="filter-buttons-wrap">
              {hasEditMode &&
                !isEditFilter &&
                selectedFilter?.value?.editable &&
                !readOnly && (
                  <Button
                    title={"Edit filter"}
                    data-testid={"edit-filter-btn"}
                    isIcon={"seem-icon-edit"}
                    isClean
                    isSmall
                    onClick={() => {
                      updateFiltersState({ isEditFilter: true });
                    }}
                  />
                )}
              {(!hasEditMode || isEditFilter) && !readOnly && (
                <>
                  <Button
                    data-testid={"reset-filter-btn"}
                    isIcon={"seem-icon-close"}
                    isClean
                    isSmall
                    onClick={() => {
                      reset();
                      setTimeout(() => {
                        previewFilter();
                      }, 0);
                    }}
                    tooltipData={{
                      dataFor: `reset-filter`,
                      dataTip: true,
                    }}
                  />
                  <ReactTooltip
                    id="reset-filter"
                    place="top"
                    className={"copy-tooltip"}
                  >
                    {`Reset Filter`}
                  </ReactTooltip>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {/*</div>*/}

      {(!hasEditMode || isEditFilter) && !readOnly && (
        <ModalButtonsContainer>
          {hasEditMode && isEditFilter && (
            <Button
              data-testid={`filter-cancel-edit`}
              label={"Cancel"}
              onClick={() => {
                reset();
                setTimeout(() => {
                  previewFilter(false);
                }, 0);
              }}
            />
          )}
          <Button
            data-testid={`filter-save-as`}
            label={"Save As"}
            disabled={!isValid}
            onClick={() => {
              openSaveAsModal();
            }}
          />
          <Button
            data-testid={`filter-save`}
            label={"Save Filter"}
            isSecondary
            onClick={validateOnSave}
            disabled={updateDisabled || !isValid || !isDirty}
          />
        </ModalButtonsContainer>
      )}
    </form>
  );
};

export default FilterPanel;
