import React, { useState, useEffect, useRef, useMemo } from "react";
import { sortByOptions } from "../../../../Findings/FindingsUtils";
import useFindingsListState from "../../../../Findings/FindingsData/FindingsList/useFindingsListState";
import FindingsTable from "../../../../Findings/FindingsData/FindingsList/FindingsTable/FindingsTable";
import { useTranslation } from "react-i18next";
import { queuePriorities } from "../../../RemediationQueuesUtils";

const RMQFindingsTable = ({
  title,
  scope,
  filter,
  columns,
  priority,
  enableRowSelection,
  onCountChange = null,
  onPaneChange = null,
  isPaneOpen = null,
  className = "",
}) => {
  const listRef = useRef(null);
  const { t } = useTranslation();

  const sortByPriority = useMemo(() => {
    if (!!priority) {
      if (priority === queuePriorities.SLA) {
        return sortByOptions[2].value;
      }
      return sortByOptions[0].value;
    }
  }, [priority]);

  const {
    errorData,
    findings,
    setFindings,
    loadingData,
    findingsListData,
    onSelectedFinding: onSelectedFromHook,
    selectedRowState,
    handleScroll,
    findingsTotalCount,
    loadingCount,
  } = useFindingsListState(
    sortByPriority,
    {
      filterToExecute: filter,
      selectedScope: { value: { id: scope } },
    },
    listRef,
    null,
    true
  );

  const getFindingsList = (findingsList) => {
    return findingsList?.findings?.edges?.map((edge) => {
      if (!edge) {
        return { isLoader: true };
      }
      const finding = edge.node;
      const tickets = finding.tickets?.edges;

      return {
        ...finding,
        remediation:
          !!finding.remediation && !finding.remediation.startsWith('"')
            ? JSON.parse(finding.remediation)
            : null,
        ticketStatus:
          !!tickets && !!tickets.length ? tickets[0].node.status : null,
        ticketId: tickets?.[0]?.node.external_id,
        isLoader: false,
      };
    });
  };

  useEffect(() => {
    if (Number.isInteger(findingsTotalCount)) {
      !!onCountChange && onCountChange(findingsTotalCount);
    }
  }, [findingsTotalCount]);

  const [isLoadingFindings, setIsLoadingFindings] = useState(false);

  useEffect(() => {
    if (!!findingsListData) {
      const data = getFindingsList(findingsListData);
      if (data.length < findingsTotalCount) {
        data?.push({ isLoader: true });
      }

      setFindings(data);
    }
  }, [findingsListData]);

  useEffect(() => {
    setIsLoadingFindings(false);
  }, [findings]);

  const onSelectedFinding = (finding, toggle) => {
    if (!!onPaneChange) {
      onPaneChange(finding, toggle, onSelectedFromHook);
    } else {
      onSelectedFromHook(finding, toggle);
    }
  };

  useEffect(() => {
    if (isPaneOpen !== null && !!onSelectedFromHook) {
      if (!isPaneOpen) {
        onSelectedFromHook(null, false);
      }
    }
  }, [isPaneOpen]);

  return (
    <div className={`remediation-queue-container rmq-findings-table-wrapper`}>
      <div className={`rmq-findings-table-wrapper-title`}>
        {loadingCount ? (
          <div
            className={`total-findings-count-title total-findings-count-title-loading`}
          />
        ) : (
          <div className={`table-total-findings-title-wrap`}>
            <div className={`table-total-findings-title`}>
              <div className={"remediation-queue-title"}>
                {!loadingData
                  ? t(title, { count: findingsTotalCount?.toLocaleString() })
                  : ""}
              </div>
            </div>
          </div>
        )}
      </div>
      <FindingsTable
        isLoading={loadingData}
        isError={errorData}
        findings={findings}
        handleScroll={handleScroll}
        isLoadingFindings={isLoadingFindings}
        groupBy={{ selectedOptionValue: { groupBy: "" } }}
        forwardedRef={listRef}
        findingsListData={findingsListData}
        columns={columns}
        hasSelectedItem={selectedRowState.hasSelectedItem}
        onSelectedFinding={onSelectedFinding}
        selectedFilterRow={selectedRowState.selectedFilterRow}
        enableRowSelection={enableRowSelection}
        className={`rmq-finding-table ${className}`}
        multiSelect={false}
        filteringEnabled={false}
      />
    </div>
  );
};

export default RMQFindingsTable;
