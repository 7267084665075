import React from "react";
import "./FindingsNavigationItem.scss";
import { groupByTypes } from "../../../../FindingsUtils";
import SeverityNavigationItem from "./NavigationItems/SeverityNavigationItem";
import CloudAccountNavigationItem from "./NavigationItems/CloudAccountNavigationItem";
import CloudProviderNavigationItem from "./NavigationItems/CloudProviderNavigationItem";
import DatasourceNavigationItem from "./NavigationItems/DatasourceNavigationItem";
import CategoryNavigationItem from "./NavigationItems/CategoryNavigationItem";
import SLANavigationItem from "./NavigationItems/SLANavigationItem";

const FindingsNavigationItem = ({
  group,
  groupName,
  groupBy,
  groupTitle,
  onGroupClick,
  cloudProviderIcon,
  datasourceIcon,
  groupKey,
  dataSourceName,
  groupRow,
}) => {
  const navigationProps = {
    group,
    groupName,
    groupBy,
    onGroupClick,
    dataSourceName,
    groupTitle,
    cloudProviderIcon,
    datasourceIcon,
    groupKey,
    groupRow,
  };

  const getNavigationItem = () => {
    switch (groupBy) {
      case groupByTypes.SEVERITY:
        return <SeverityNavigationItem {...navigationProps} />;

      case groupByTypes.CLOUD_ACCOUNT:
        return <CloudAccountNavigationItem {...navigationProps} />;

      case groupByTypes.CLOUD_PROVIDER:
        return <CloudProviderNavigationItem {...navigationProps} />;

      case groupByTypes.DATASOURCE:
        return <DatasourceNavigationItem {...navigationProps} />;

      case groupByTypes.CATEGORY:
        return <CategoryNavigationItem {...navigationProps} />;

      case groupByTypes.SLA_STATUS:
        return <SLANavigationItem {...navigationProps} />;

      default:
        return null;
    }
  };

  return getNavigationItem();
};

export default FindingsNavigationItem;
