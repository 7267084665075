import React, { useContext } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import "./ChangeOwnPassword.scss";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import useCognitoAuthWithLogs from "../../../Auth/useCognitoAuthWithLogs";
import { showErrorDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../../Forms/Dialogs/Toast/Toast";
import PasswordPolicy from "../../../Auth/PasswordPolicy/PasswordPolicy";

const ChangeOwnPassword = () => {
  const methods = useForm({
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;
  const { closeModal } = useContext(ModalContext);
  const passwordValue = useWatch({ control, name: "newPassword" });
  const { getAuthenticatedUser, changePassword } = useCognitoAuthWithLogs();

  const onSubmit = (data) => {
    getAuthenticatedUser()
      .then((user) => {
        return changePassword(user, data?.currentPassword, data?.newPassword);
      })
      .then((res) => {
        if (res === "SUCCESS") {
          closeModal();
          showToast({ message: "Password Changed Successfully" });
        }
      })
      .catch((err) => showErrorDialog({ message: err.message }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"reset-password-form"}>
      <Controller
        name={"currentPassword"}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            type={"password"}
            label={"Current Password"}
            inputStyle={"bomba-style"}
            labelTop
            error={
              errors && errors.currentPassword ? errors.currentPassword : null
            }
            value={value}
            placeholder={"Type current password"}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={"user-profile-current-password"}
          />
        )}
        rules={{ required: "Required" }}
        control={control}
      />
      <Controller
        name={"newPassword"}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            type={"password"}
            label={"New Password"}
            inputStyle={"bomba-style"}
            labelTop
            placeholder={"Type new password"}
            error={errors && errors.newPassword ? errors.newPassword : null}
            value={value}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={"user-profile-new-password"}
          />
        )}
        rules={{
          required: "Required",
          pattern: {
            value: /^[\S]+.*[\S]+$/,
            message: "Password too short",
          },
        }}
        control={control}
      />
      {!!passwordValue && <PasswordPolicy password={passwordValue} />}

      <Controller
        name={"confirmPassword"}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            type={"password"}
            label={"Confirm New Password"}
            inputStyle={"bomba-style"}
            labelTop
            error={
              errors && errors.confirmPassword ? errors.confirmPassword : null
            }
            value={value}
            placeholder={"Confirm new password"}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={"user-profile-confirm-password"}
          />
        )}
        rules={{
          required: "Required",
          validate: {
            matchesPreviousPassword: (value) => {
              const { newPassword } = getValues();
              return newPassword === value || "Passwords should match";
            },
          },
        }}
        control={control}
      />
      <ModalButtonsContainer>
        <Button
          type="button"
          data-testid={"user-actions-cancel"}
          label={"Cancel"}
          onClick={() => closeModal()}
        />
        <Button
          data-testid={"user-actions-submit"}
          type="submit"
          label={"Save"}
          isSecondary
        />
      </ModalButtonsContainer>
    </form>
  );
};

export default ChangeOwnPassword;
