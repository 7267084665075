import Button from "../Forms/Controls/Button/Button";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./CustomerProvisioning.scss";
import CreateTenant from "./CreateTenant/CreateTenant";
import { ModalContext } from "../Forms/Dialogs/Modal/ModalContext";
import Table from "../Components/Table/Table";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_CUSTOMER, GET_CUSTOMERS } from "./CustomerProvisioningApi";
import {
  showDialog,
  showErrorDialog,
} from "../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../Forms/Dialogs/Toast/Toast";
import ManageDomainUsers from "./ManageDomainUsers/ManageDomainUsers";
import AllowedDomainsModal from "./AllowedDomainsModal/AllowedDomainsModal";

const CustomerProvisioning = () => {
  const { openModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const { loading, data: customersData } = useQuery(GET_CUSTOMERS);
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    refetchQueries: ["get_customers"],
  });

  const columns = React.useMemo(
    () => [
      {
        id: "customer",
        Header: "Customer",
        accessor: "friendly_name",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
      },
      {
        id: "id",
        Header: "",
        accessor: "id",
        disableFilters: true,
        width: 232,
        maxWidth: 232,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                wrapperClassName={"allowed-domains-btn"}
                data-testid={`${cellProps.value}-allowed-domains`}
                isSecondary
                label={t("Allowed Domains")}
                onClick={() => {
                  openModal({
                    title: `Allowed Domains - ${cellProps.row.values.customer}`,
                    size: "m",
                    overlay: true,
                    component: (
                      <AllowedDomainsModal customerID={cellProps.value} />
                    ),
                  });
                }}
              />
              <Button
                wrapperClassName={"manage-users-btn"}
                data-testid={`${cellProps.value}-manage-users`}
                isSecondary
                label={t("Manage Users")}
                onClick={() => {
                  openModal({
                    title: `Manage ${cellProps.row.values.customer}'s users`,
                    size: "m",
                    overlay: true,
                    component: (
                      <ManageDomainUsers
                        customerID={cellProps.value}
                        domains={cellProps.row?.original?.domains?.edges?.map(
                          (domain) => domain.node?.domain
                        )}
                      />
                    ),
                  });
                }}
              />

              <Button
                data-testid={`${cellProps.value}-delete`}
                label={"Delete"}
                isSecondary
                onClick={() => {
                  showDialog({
                    title: "Delete Customer",
                    message: `Are you sure you want to delete ${cellProps.row.values.friendly_name}?`,
                    buttons: [
                      {
                        label: "Cancel",
                      },
                      {
                        "data-testid": "delete-customer-confirm",
                        isSecondary: true,
                        label: "Delete",
                        onClick: () => {
                          onDeleteCustomer(cellProps.value);
                        },
                      },
                    ],
                  });
                }}
              />
            </>
          );
        },
      },
    ],
    [customersData]
  );

  const onDeleteCustomer = (customerID) => {
    deleteCustomer({ variables: { customer_id: customerID } })
      .then((res) => {
        if (!!res?.data?.terminate_customer?.ok) {
          closeModal();
          showToast({ message: res?.data?.terminate_customer?.msg });
        } else {
          showErrorDialog({ message: res.data?.terminate_customer?.msg });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <div className={"customer-provisioning-wrapper"}>
      <div className={"header"}>
        <div className={`title`}>{t("Customer Provisioning")}</div>

        <Button
          wrapperClassName={"create-tenant-btn"}
          data-testid={"create-tenant"}
          isSecondary
          label={t("+ New Tenant")}
          isXl
          onClick={() => {
            openModal({
              title: "Create New Tenant",
              size: "m",
              overlay: true,
              component: <CreateTenant />,
            });
          }}
        />
      </div>
      <div className={"tenant-table-container"}>
        <Table
          tableWrapperClass={`tenant-table`}
          loading={loading}
          columns={columns}
          data={customersData?.get_customers?.customers || []}
          data-testid-body={"customers"}
          initialSortBy={[{ id: "customer" }]}
        />
      </div>
    </div>
  );
};
export default CustomerProvisioning;
