import EntityDetailsPane from "../EntityDetailsPane/EntityDetailsPane";
import SideDrawer from "../../Components/SideDrawer/SideDrawer";
import React, { useContext, useMemo } from "react";
import { DetailsPaneContext } from "./DetailsPaneProvider";
import { ticketStatus } from "../../Ticketing/TicketingUtils";
import DetailsPaneDescription from "./Description/Description";
import Remediation from "./Remediation/Remediation";
import AggregatedFindingsProvider from "./AggregatedFindings/AggregatedFindingsProvider";
import Resources from "./Resources/Resources";
import AggregatedFindings from "./AggregatedFindings/AggregatedFindings";
import Ticket from "./Ticket/Ticket";
import AdditionalData from "./AdditionalData/AdditionalData";
import Attachments from "./Attachments/Attachments";
import Comments from "./Comments/Comments";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";
import { cloudProviderIcons } from "../FindingsUtils";
import { snakeCase } from "lodash";
import Statuses from "./Statuses/Statuses";
import DataSource from "./DataSource/DataSource";
import "./FindingDetailsPane.scss";
import SLAStatus from "../FindingsData/SLAStatus/SLAStatus";
import SLAPopover from "./SLAPopover/SLAPopover";
import { SLA_STATUS_NA } from "../../Dashboard/SLADashboard/SLAUtils";
import { AppPreferencesContext } from "../../General/AppPreferencesProvider";
import ScoreTag from "../../Components/ScoreTag/ScoreTag";

const FindingDetailsPane = ({ closeDetailsPane, isFullScreen }) => {
  const {
    detailsPaneData: {
      aggregation_group,
      aggregation_group_id,
      allCommentsCount,
      tickets,
      id_int,
      cloud_provider,
      id,
      sla_remaining_time,
      cloud_account_friendly_name,
      cloud_account,
      region,
      repoLink,
      severity,
      age,
      sla_status,
      score,
      additional_data,
      editable,
      findingTicket,
      title,
    },
    loading,
  } = useContext(DetailsPaneContext);
  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);
  const menuItems = useMemo(
    () => [
      {
        title: "About This Finding",
        name: "Description",
        to: "description",
        isVisible: true,
        hasContent: true,
      },
      {
        name: "Remediation",
        title: "Remediation",
        to: "remediation",
        isVisible: true,
        hasContent: true,
      },
      {
        name: "Resources",
        title: "Resources",
        to: "resources",
        isVisible: true,
        hasContent: true,
      },
      {
        name: `Raw Findings`,
        title: `Raw Findings (${aggregation_group?.findings_count})`,
        to: "raw-findings",
        isVisible: true,
        totalCount: aggregation_group?.findings_count,
        isDisabled: !aggregation_group_id,
        hasContent: !!aggregation_group_id,
      },
      {
        name: "Ticket",
        title: "Ticket",
        to: "ticket",
        isVisible: true,
        isDisabled:
          !tickets?.length ||
          (!!findingTicket &&
            findingTicket.status.toLowerCase() ===
              ticketStatus.DELETED.text.toLowerCase()),
        hasContent:
          !!tickets?.length &&
          findingTicket.status.toLowerCase() !==
            ticketStatus.DELETED.text.toLowerCase(),
      },
      {
        name: "Additional Data",
        to: "additional-data",
        title: "Additional Data",
        isVisible: true,
        isDisabled:
          !additional_data ||
          (additional_data &&
            Object.keys(JSON.parse(additional_data)).length === 0),
        hasContent:
          !!additional_data &&
          Object.keys(JSON.parse(additional_data)).length > 0,
      },
      {
        name: "Attachments",
        title: "Attachments",
        to: "attachments",
        isVisible: true,
        hasContent: true,
      },
      {
        name: "Comments",
        title: "Comments",
        to: "comments",
        totalCount: allCommentsCount,
        isVisible: true,
        hasContent: true,
      },
    ],
    [
      additional_data,
      aggregation_group?.findings_count,
      aggregation_group_id,
      allCommentsCount,
      findingTicket,
      tickets?.length,
    ]
  );

  const contentItems = [
    <DetailsPaneDescription />,
    <Remediation />,
    <AggregatedFindingsProvider>
      <Resources />
    </AggregatedFindingsProvider>,
    <AggregatedFindingsProvider>
      <AggregatedFindings />
    </AggregatedFindingsProvider>,
    <Ticket />,
    <AdditionalData />,
    <Attachments />,
    <Comments />,
  ];

  const getFindingCloudProvider = useMemo(
    () => (
      <div className={`details-pane-header-cloud-provider`}>
        <a
          href={repoLink}
          target={"_blank"}
          aria-disabled={`${!repoLink?.length}`}
          className={`${!repoLink?.length && "repo-link-disabled"}`}
          rel="noreferrer"
        >
          <img
            alt={`${cloud_provider}`}
            src={cloudProviderIcons[snakeCase(cloud_provider)]}
          />
          <h3 className={`finding-account-col`}>{`${
            !!cloud_account_friendly_name
              ? cloud_account_friendly_name
              : cloud_account
          } ${!!region ? `| ${region}` : ""}`}</h3>
        </a>
      </div>
    ),
    [
      cloud_account,
      cloud_account_friendly_name,
      cloud_provider,
      region,
      repoLink,
    ]
  );

  const getFindingScore = useMemo(
    () => <ScoreTag severity={severity} score={score} isLarge hideSeverity />,
    [score, severity]
  );

  return (
    <SideDrawer isFullScreen={isFullScreen} width={"40vw"} minWidth={"820px"}>
      {loading ? (
        <Loader center img={Spinner} />
      ) : (
        <EntityDetailsPane
          isFullScreen={isFullScreen}
          closeDetailsPane={closeDetailsPane}
          entityId={id_int}
          entityTitle={title}
          editable={editable}
          headerBottomComponents={[
            <div className={"details-pane-score-wrap"}>{getFindingScore}</div>,
            ...(slaEnabled &&
            !!sla_status &&
            sla_status !== SLA_STATUS_NA &&
            sla_remaining_time !== null
              ? [
                  <div className={"details-pane-sla-status"}>
                    <SLAStatus isLarge sla_status={sla_status} />
                    <SLAPopover
                      age={age}
                      remaining={sla_remaining_time}
                      id={id}
                    />
                  </div>,
                ]
              : []),
            <Statuses />,
          ]}
          headerTopComponents={[getFindingCloudProvider, <DataSource />]}
          navigationOptions={menuItems}
          contentOptions={contentItems}
          entityClass={"findings"}
        />
      )}
    </SideDrawer>
  );
};

export default FindingDetailsPane;
