import { dataSourceIcons } from "../../../Findings/FindingsUtils";

const INSTRUCTION_OPTIONS_HTML = {
  AZURE: `<div style="
    color: #41425A;
    width: 822px;
    height: 300px;
    left: 1030px;
    top: 284px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
">
<p>1.In Active Directory menu choose “Enterprise applications”</p>
<p>2. In opened section choose “New Application”</p>
<p>3. Pick “Non-gallery application” type for your application</p>
<p>4. Type name of your application and press “Add”.</p>
<p>5. In your Azure AD enterprise application choose section “Single sign-on”, in dropdown list choose “SAML- based Sign-on”</p>
<p>6. In section “Domain and URLs” set next information:</p>
<div style="display: flex">

<p>
    &nbsp; &nbsp; Identifier: 
        <div><strong style="font-weight: 500; margin-left: 8px;">urn:amazon:cognito:sp:eu-central-1_eHJyIt4nH</strong>
</div>
</p>
</div>
<div style="display: flex">
<p>&nbsp; &nbsp; Reply URL: 
<div><strong style="
    font-weight: 500; margin-left: 8px;
">https://seemplicity-prod.auth.eu-central-1.amazoncognito.com/saml2/idpresponse</strong><i data-tip="true" data-for="copyScopeFilterTip" class="seem-icon seem-icon-link scope-filter-copy" currentitem="false" style="
    margin-left: 4px;
"></i>
</p>
</div><p>7. Save changes and download the Metadata XML and send that to me</p>
<p>8. Add a User to your app.</p>
<p>9. In your Azure AD select “Enterprise applications” and choose your application.</p>
<p>10. Select “Users and groups”-&gt;“Add user”</p>
</div>`,
  AUTH0: `<div style="
    color: #41425A;
    width: 822px;
    height: 300px;
    left: 1030px;
    top: 284px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
">
    <p>1.Go to Dashboard > Applications >
        &nbsp;<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABqElEQVRYCe2YPU/DMBCG+1OZWdlQWTowdKMTXTtRqQx0QKILMGYEpkpl6FKpU9Wxiq1Dr4sd17LjpP4QQhlOceLk7vHri31J73Ao6S9Zz4T5WjKazDndP5xnpj+cN/FVfDAhjALa7UsaTzldXIeZDaipTzAooBgwCBwC9LlkRyBMkz6KywGn/h2n23F7swGZfuBfjyfbCmjyVN2Amzfb43zanIdeeyuqWBJEHhUQkk5exGhCg7qer4NB/KxANhgMXgqRFcgGM1swMnM3i0IuGExrdqA6mOxAPhgAbbYlzV64MrzdYmGM/ZY1gXG9idGBQmAAGRUoFCYqUAyYaEDnwiCpXwumTO32IUkNp/pqizYWPVfS6teTrEMYoQ7UFAZgSYAgM0oVVAnP7+025iRA+hS0bXdAPsU6hTqFfAr4+rsc+p8K3YzaLf8+Fer6zRz6Xv+WsNiD9A3yccEJnahxUxlg+qPTuIAXFSM2SNf3tg6aso0SSAGhYaqUMrjp+2pY/U8QCsl5xk8jdJoPpDrHrECZ3b4q6E6AJNhqzcSHPz5tUxli6CAy9g+VBQX8F6YPcAAAAABJRU5ErkJggg==" alt="" width="14" height="14" />
        <a href="https://manage.auth0.com/?_gl=1*ngpjph*rollup_ga*MTY5ODg0MDgwNi4xNjM4MjY3ODMx*rollup_ga_F1G3E656YZ*MTY0MDE2MzM5NC4zLjEuMTY0MDE2Mzk2NC41NQ..&_ga=2.49317472.770058483.1640163394-1698840806.1638267831#/externalapps" style="
    font-weight: 500;
    text-decoration: none;
">SSO Integrations </a> and select <strong>Create SSO Integration.</strong></p>
    <p>2. Choose an SSO identity provider integration and click <strong>Continue.</strong></p>
    <p>3. Enter a Name and a Callback URL for your new integration and click <strong>Save.</strong></p>
</div>`,
  GOOGLE: `<div style="
    color: #41425A;
    width: 822px;
    height: 300px;
    left: 1030px;
    top: 284px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
">
    <p>1. Sign in to your
    &nbsp;<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABqElEQVRYCe2YPU/DMBCG+1OZWdlQWTowdKMTXTtRqQx0QKILMGYEpkpl6FKpU9Wxiq1Dr4sd17LjpP4QQhlOceLk7vHri31J73Ao6S9Zz4T5WjKazDndP5xnpj+cN/FVfDAhjALa7UsaTzldXIeZDaipTzAooBgwCBwC9LlkRyBMkz6KywGn/h2n23F7swGZfuBfjyfbCmjyVN2Amzfb43zanIdeeyuqWBJEHhUQkk5exGhCg7qer4NB/KxANhgMXgqRFcgGM1swMnM3i0IuGExrdqA6mOxAPhgAbbYlzV64MrzdYmGM/ZY1gXG9idGBQmAAGRUoFCYqUAyYaEDnwiCpXwumTO32IUkNp/pqizYWPVfS6teTrEMYoQ7UFAZgSYAgM0oVVAnP7+025iRA+hS0bXdAPsU6hTqFfAr4+rsc+p8K3YzaLf8+Fer6zRz6Xv+WsNiD9A3yccEJnahxUxlg+qPTuIAXFSM2SNf3tg6aso0SSAGhYaqUMrjp+2pY/U8QCsl5xk8jdJoPpDrHrECZ3b4q6E6AJNhqzcSHPz5tUxli6CAy9g+VBQX8F6YPcAAAAABJRU5ErkJggg==" alt="" width="14" height="14" />
        <a style="
    font-weight: 500;
    text-decoration: none;
" href="http://admin.google.com/">Admin Console </a></p>
    <p>2. From the Admin console Home page, go to <strong>Security</strong>.</p>
    <p>3. Click<strong> Set up single sign-on (SSO) with a third party IdP.</strong></p>
    <p>4. Click <strong>Add SSO profile.</strong></p>
    <p>5. Check the <strong>Set up SSO with third-party identity provider box.</strong></p>
    <p>6. Enter the following URL to Seemplicity:</p>
    <p>&nbsp; &nbsp; &nbsp;Sign-in page URL: The page where users sign in to your system and Google Workspace.</p>
</div>`,
  OKTA: `<div style="
    color: #41425A;
    width: 822px;
    height: 300px;
    left: 1030px;
    top: 284px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
">
<p>1.Add new application -&gt; Create new app</p>
<p>2. Choose <strong>SAML 2.0.</strong></p>
<p>3. App name: <strong>Seemplicity</strong></p>
<p>4. App visibility: <strong>Check &ldquo;Do not display application icon to users&rdquo;</strong></p>
<p>5. Single sign on URL: <strong>https://seemplicity-prod.auth.eu-central-1.amazoncognito.com/saml2/idpresponse</strong></p>
<p>6. Audience URI (SP Entity ID): <strong>urn:amazon:cognito:sp:eu-central-1_eHJyIt4nH</strong></p>
<p>7. Name ID format: <strong>EmailAddress</strong></p>
<p>8. Application username: <strong>Email</strong></p>
<p>9. Click <strong>Save</strong>.</p>
<p>10. In the assignments tab assign the user and groups allowed to access the Seemplicity platform.&nbsp;</p>
<p>11. Click on Sign On Tab -&gt; <strong>Click on Identity Provider metadata</strong> and save the xml to file, then upload it here in "Upload metadata document".&nbsp;</p>
</div>`,
  OTHER: `<div style="
    color: #41425A;
    width: 822px;
    height: 300px;
    left: 1030px;
    top: 284px;
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 180%;
">
<p>User Pool:<strong> urn:amazon:cognito:sp:eu-central-1_eHJyIt4nH</strong></p>
<p>Cognito Endpoint: <strong>https://seemplicity-prod.auth.eu-central-1.amazoncognito.com/saml2/idpresponse</strong></p>
</div>`,
};

export const instructionsOptions = [
  {
    title: "Azure AD",
    leftImg: dataSourceIcons.AZR.S,
    pageLink: "azure",
    content: INSTRUCTION_OPTIONS_HTML.AZURE,
  },
  {
    title: "Auth0",
    leftImg: dataSourceIcons.AUTH0.S,
    pageLink: "auto0",
    content: INSTRUCTION_OPTIONS_HTML.AUTH0,
  },
  {
    title: "Google",
    leftImg: dataSourceIcons.GOOGLE.S,
    pageLink: "google",
    content: INSTRUCTION_OPTIONS_HTML.GOOGLE,
  },
  {
    title: "Okta",
    leftImg: dataSourceIcons.OKTA.S,
    pageLink: "okta",
    content: INSTRUCTION_OPTIONS_HTML.OKTA,
  },
  {
    title: "Other",
    leftImg: dataSourceIcons.SEEMPLICITY.S,
    pageLink: "other",
    content: INSTRUCTION_OPTIONS_HTML.OTHER,
  },
];
