import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GET_REPORTS, GET_NAMES } from "./DataGridApi";
import SelectInput from "../Forms/Controls/SelectInput/SelectInput";
import Table from "../Components/Table/Table";
import "./DataGrid.scss";
import { GeneralAppContext } from "../General/GeneralAppProvider";

const DataGrid = () => {
  const [getNames] = useMutation(GET_NAMES);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [names, setNames] = useState([]);
  const [getData] = useMutation(GET_REPORTS);
  const [selectedNameToFetch, setSelectedNameToFetch] = useState();
  const { updateAppState } = useContext(GeneralAppContext);

  useEffect(() => {
    updateAppState({ appClass: "monitoring" });
  }, []);

  useEffect(() => {
    getNames().then((getDataData) => {
      let namesData = [];
      getDataData.data.get_all_system_report_names.data.map((name) =>
        namesData.push({
          label: name,
          value: name,
        })
      );
      setNames(namesData);
    });
  }, []);

  useEffect(() => {
    if (!!selectedNameToFetch) {
      getData({
        variables: {
          name: selectedNameToFetch.label,
        },
      }).then((getDataData) => {
        let x = JSON.parse(getDataData.data.run_system_report.data);
        let y = [];
        Object.keys(x[0]).map((name) => {
          y.push({
            id: name,
            Header: name,
            accessor: name,
          });
        });

        let xx = y.map((yy) => ({ ...yy, ...defaultColumnProperties }));
        setColumns(xx);
        setRows(x);
      });
    }
  }, [selectedNameToFetch]);

  const defaultColumnProperties = {
    width: 200,
    sortable: true,
  };

  return (
    <div className={"monitoring-wrapper"}>
      <SelectInput
        labelTop
        name={`check_names`}
        size="custom"
        wrapperClassName="project-menu"
        SelectInputStyle={"bomba-style"}
        onChange={(selectedValue) => {
          setSelectedNameToFetch(selectedValue);
        }}
        placeholder={"Choose query"}
        isSearchable={true}
        label={"Query"}
        options={names}
      />
      <SelectInput
        labelTop
        name={`check_schemas`}
        size="custom"
        wrapperClassName="project-menu"
        SelectInputStyle={"bomba-style"}
        placeholder={"Choose schema"}
        isSearchable={true}
        label={"Schema"}
      />

      <Table
        columns={columns}
        style={{ height: "1000px" }}
        data={rows}
        //rowGetter={(i) => rows[i]}
        // rowsCount={10000}
        // minHeight={-1}
        // onColumnResize={(idx, width) =>
        //     console.log(`Column ${idx} has been resized to ${width}`)
        // }
      />
    </div>
  );
};

export default DataGrid;
