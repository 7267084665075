import React from "react";
import { ConfirmationDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import "./StaticErrorFallback.scss";

const StaticErrorFallback = ({ error, onRetry, onHomepage }) => {
  const dialogProps = {
    type: "error",
    message: error.message,
    buttons: [
      {
        isSecondary: true,
        label: "Try Again",
        onClick: onRetry,
        "data-testid": "error-fallback-retry-btn",
      },
      {
        label: "Back to Homepage",
        onClick: onHomepage,
        "data-testid": "error-fallback-homepage-btn",
      },
    ],
    showCloseIcon: false,
  };

  return (
    <div className={"static-fallback-container"} data-testid={"error-fallback"}>
      <ConfirmationDialog {...dialogProps} />
    </div>
  );
};

export default StaticErrorFallback;
