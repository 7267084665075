import { useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    setShowCloseButton(true);
  };

  const openModal = (content = false) => {
    setIsOpen(true);
    if (content) {
      setModalContent(content);
    }
  };

  const hideCloseButton = () => {
    setShowCloseButton(false);
  };

  return {
    isOpen,
    openModal,
    closeModal,
    modalContent,
    hideCloseButton,
    showCloseButton,
  };
};

export default useModal;
