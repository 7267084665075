import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";

const UserEditableFields = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        name={"firstName"}
        defaultValue={null}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder={"Type first name"}
            type={"text"}
            label={"First Name"}
            inputStyle={"bomba-style"}
            labelTop
            size={"custom"}
            error={errors && errors.firstName ? errors.firstName : null}
            value={value}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={`first-name`}
          />
        )}
        rules={{ required: "Required" }}
        control={control}
      />
      <Controller
        name={"lastName"}
        defaultValue={""}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder={"Type last name"}
            type={"text"}
            label={"Last Name"}
            inputStyle={"bomba-style"}
            labelTop
            size={"custom"}
            error={errors && errors.lastName ? errors.lastName : null}
            value={value}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={`last-name`}
          />
        )}
        control={control}
      />
    </>
  );
};

export default UserEditableFields;
