import React, { useContext, useEffect, useState } from "react";
import "./InstanceActions.scss";
import Button from "../../../../Forms/Controls/Button/Button";
import SideDrawer from "../../../../Components/SideDrawer/SideDrawer";
import Instructions from "../../Instructions/Instructions";
import { instanceActions } from "../DataSourcesUtils";
import IntegrationCustomFields from "../../IntegrationCustomFields/IntegrationCustomFields";
import { useMutation } from "@apollo/client";
import {
  ADD_DATA_SOURCE,
  GET_DATA_SOURCE_DEFINITIONS,
  GET_DATA_SOURCES,
  UPDATE_DATA_SOURCE,
  UPDATE_DATASOURCE_DEFINITION,
} from "../DataSourcesApi";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";

const InstanceActions = ({ mode, IntegrationContext }) => {
  const {
    handleViewIntegration,
    handleBackToMainPage,
    currentIntegration: data,
  } = useContext(IntegrationContext);
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const [addDatasource] = useMutation(ADD_DATA_SOURCE, {
    refetchQueries: [
      { query: GET_DATA_SOURCES, variables: { with_last_received: true } },
    ],
  });
  const [updateDatasource] = useMutation(UPDATE_DATA_SOURCE, {
    refetchQueries: [
      { query: GET_DATA_SOURCES, variables: { with_last_received: true } },
    ],
  });
  const [updateDataSourceDefinition] = useMutation(
    UPDATE_DATASOURCE_DEFINITION,
    {
      refetchQueries: [{ query: GET_DATA_SOURCE_DEFINITIONS }],
    }
  );

  const onLeavingInstancesActions = () => {
    if (mode === instanceActions.EDIT) {
      delete data.editedInstance;
    }

    handleViewIntegration(data);
  };

  const handleAddition = (mutationData) => {
    mutationData.datasource_definitions_id = data?.id;
    return addDatasource({ variables: mutationData })
      .then((res) => {
        if (res.data?.add_datasource?.ok) {
          showToast({
            message: "Instance added",
          });
          handleViewIntegration(data);
        } else {
          showErrorDialog({ message: res.data?.add_datasource?.msg });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  const handleEditing = (mutationData) => {
    mutationData.id = data?.editedInstance?.id;
    return updateDatasource({ variables: mutationData })
      .then((res) => {
        if (res.data?.update_datasource?.ok) {
          showToast({
            message: `${mutationData.friendly_name} integration successfully updated`,
          });
          delete data.editedInstance;
          handleViewIntegration(data);
        } else {
          showErrorDialog({
            message: res.data?.update_datasource?.msg,
          });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  useEffect(() => {
    if (mode === instanceActions.CREATE) {
      setInstructionsOpen(true);
    }
  }, [mode]);

  const onSaveInstructions = (dataToSend) => {
    return updateDataSourceDefinition({
      variables: dataToSend,
    })
      .then((res) => {
        if (res?.data?.update_datasource_definition?.ok) {
          showToast({
            message: "Details Saved",
          });
        } else {
          showErrorDialog({ message: "Something went wrong." });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <div className={"ds-actions-wrapper"}>
      <div className={"ds-header-buttons"}>
        <Button
          wrapperClassName={"back-to-integrations"}
          data-testid={"back-to-btn"}
          type={"button"}
          leftIcon={"seem-icon-left-arrow"}
          label={
            data?.hasAtLeastOneInstance
              ? `Back to ${data?.name}`
              : `Back To All Data Sources`
          }
          textLink
          onClick={() => {
            data?.hasAtLeastOneInstance
              ? onLeavingInstancesActions()
              : handleBackToMainPage();
          }}
        />
        <Button
          wrapperClassName={"back-to-integrations"}
          data-testid={"back-to-btn"}
          type={"button"}
          label={"How To Setup?"}
          textLink
          onClick={() => setInstructionsOpen(!instructionsOpen)}
        />
      </div>
      <div className={"ds-actions-title instance-details"}>
        Instance Details
      </div>
      <IntegrationCustomFields
        IntegrationContext={IntegrationContext}
        submitBtnText={"Save"}
        OnSubmit={mode === "create" ? handleAddition : handleEditing}
        integrationDefinitionId={data?.id}
        integrationDefintionSections={data?.sections}
        fieldsValues={data?.editedInstance?.fieldsValues}
      />
      {instructionsOpen ? (
        <SideDrawer
          target={"sidebar-layout"}
          isFullScreen={false}
          shadow={"s"}
          width={"665px"}
          minWidth={"553px"}
        >
          <Instructions
            IntegrationContext={IntegrationContext}
            onSideDrawerClose={setInstructionsOpen}
            onSave={onSaveInstructions}
          />
        </SideDrawer>
      ) : null}
    </div>
  );
};

export default InstanceActions;
