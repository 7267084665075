import React from "react";
import "../SAMLSettings.scss";
import "./SAMLResources.scss";
import logo from "../../../icons/logos/logo-color-short.png";
import { useTranslation } from "react-i18next";

const SAMLResources = () => {
  const { t } = useTranslation();

  return (
    <div className={"saml-instructions-wrapper"}>
      <div className={"sub-title"}>{t("Resources")}</div>
      <a href={logo} className={"download"} download="Seemplicity-Logo.png">
        <i className={`seem-icon seem-icon-download`} />
        {t("Download Seemplicity Logo (.png)")}
      </a>
    </div>
  );
};

export default SAMLResources;
