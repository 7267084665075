import { findingsColumns } from "../Findings/FindingsUtils";

export const modeOptions = {
  CREATE: "create",
  EDIT: "edit",
  READ: "read",
};

export const findingsTicketsColumns = [
  findingsColumns.ACCOUNT,
  findingsColumns.TITLE,
  findingsColumns.SEVERITY,
  findingsColumns.STATUS,
  findingsColumns.TICKET_STATUS,
  findingsColumns.TICKET_ID,
  findingsColumns.SLA_STATUS,
];

export const findingsWithoutTicketsColumns = [
  findingsColumns.ACCOUNT,
  findingsColumns.TITLE,
  findingsColumns.SEVERITY,
  findingsColumns.STATUS,
  findingsColumns.SLA_STATUS,
];

export const queueStates = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  DELETED: "DELETED",
};

export const queuePriorities = {
  DEFAULT: "DEFAULT",
  SLA: "SLA",
};
