import { LOG } from "./LoggerApi";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../Auth/AuthContext";
import { useCallback, useContext } from "react";

const logLevels = {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  CRITICAL: "CRITICAL",
};

const useLogger = () => {
  const {
    authState: { username },
  } = useContext(AuthContext);
  const [log] = useMutation(LOG);

  const logError = useCallback(
    (error) => {
      log({
        variables: {
          levelname: logLevels.ERROR,
          msg: error?.message,
          stack_trace: error?.stack,
          username,
        },
      });
    },
    [username]
  );

  const logInfo = useCallback(
    (message) => {
      log({
        variables: {
          levelname: logLevels.INFO,
          msg: message,
          username,
        },
      });
    },
    [username]
  );

  return { logError, logInfo };
};

export default useLogger;
