import { gql } from "@apollo/client";

export const GET_PRE_SIGNED_UPLOAD_URLS = gql`
  query upload($number_of_files: Int!) {
    get_upload_presigned_urls(number_of_files: $number_of_files) {
      url
      fields {
        key
        value
      }
    }
  }
`;

export const GET_PRE_SIGNED_DOWNLOAD_URLS = gql`
  query download($attachment_ids: [String!]) {
    get_download_presigned_urls(attachment_ids: $attachment_ids) {
      url
      fields {
        key
        value
      }
    }
  }
`;

export const ATTACH_TO_FINDING = gql`
  mutation AttachToFinding(
    $finding_id: String!
    $attachments: [AttachmentInput]
  ) {
    new_attachments(finding_id: $finding_id, attachments: $attachments) {
      attachments {
        name
        id
        finding {
          title
        }
        finding_id
        size
        creation_time
        synced_to_ticket
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachments($finding_id: String!, $attachment_id: String!) {
    delete_attachment(finding_id: $finding_id, attachment_id: $attachment_id) {
      ok
    }
  }
`;

export const SYNC_ATTACHMENTS = gql`
  mutation Sync($finding_id: String!) {
    sync_attachments(finding_id: $finding_id) {
      ok
      attachments {
        name
        size
        creation_time
        id
        synced_to_ticket
      }
    }
  }
`;
