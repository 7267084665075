import React, { useContext, useState, useMemo, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import SelectInput from "../../../../Forms/Controls/SelectInput/SelectInput";
import Button from "../../../../Forms/Controls/Button/Button";
import "./ChangeStatus.scss";
import { useMutation } from "@apollo/client";
import {
  FINDING_UPDATE_STATUS_CACHE,
  UPDATE_STATUS_CACHE,
  UPDATE_STATUS_WITH_COMMENT,
} from "./UserStatusApi";
import TextEditor, {
  convertHtmlToPlainText,
} from "../../../../Components/TextEditor/TextEditor";
import ModalButtonsContainer from "../../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { findingStatus } from "./FindingStatusSelect";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import RadioButtonsList, {
  CUSTOM_EMPTY,
} from "../../../../Forms/Controls/RadioButtonsList/RadioButtonsList";
import moment from "moment";
import { findingsCheckType } from "../../../FindingsUtils";
import { capitalize } from "lodash";
import AlertIndicator from "../../../../Components/AlertIndicator/AlertIndicator";
import AsyncProgressMessage from "../../../FindingsData/FindingsList/FindingsActions/AsyncProgressMessage/AsyncProgressMessage";
import { taskNames } from "../../../../WebSocket/WebSocketUtils";

const findingStatusValues = {
  IGNORED: [
    {
      label: "False Positive",
      value: 31,
    },
    {
      label: "Exception",
      value: 33,
    },
  ],
};

const manualStatus = {
  OPEN: 14,
  REVIEWED: 15,
  FIXED: 23,
};

const LS_CHANGE_STATUS_TASK_ID = "change_status_task_id";

const ChangeStatus = ({
  filters_config,
  newStatus,
  findingsCount,
  onStatusChanged,
  state,
  findingIntId,
  findingId,
}) => {
  const {
    handleSubmit,
    setError,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {
      status: newStatus,
      subStatus: null,
      comment: "",
    },
  });

  const [displayProgress, setDisplayProgress] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const dueDateOptions = useMemo(
    () => [
      { label: "None", value: "", name: "none" },
      { label: "In 1 Month", value: "1", name: "1" },
      { label: "In 3 Months", value: "3", name: "3" },
      {
        customOption: {
          component: "DATE",
          minDate: new Date(),
          onError: () => {
            setError("duedate", {
              type: "error",
              message: "",
            });
          },
        },
        value: "custom",
        name: "custom",
      },
    ],
    []
  );

  const { closeModal, hideCloseButton } = useContext(ModalContext);
  const [updateStatusWithComment, { loading: isSavingStatusWithComment }] =
    useMutation(UPDATE_STATUS_WITH_COMMENT, {
      update(cache) {
        if (state === findingsCheckType.SINGLE) {
          const user_status_key =
            statusValue === "IGNORED"
              ? subStatusValue
              : manualStatus[statusValue];
          const original_id = btoa(`FindingsView:${findingIntId}`);
          cache.writeFragment({
            id: `FindingsView:${original_id}`,
            fragment: UPDATE_STATUS_CACHE,
            data: {
              actual_status: {
                key: user_status_key,
                category: statusValue,
                type: "USER",
              },
            },
          });

          cache.writeFragment({
            id: `Finding:${findingId}`,
            fragment: FINDING_UPDATE_STATUS_CACHE,
            data: {
              actual_status: {
                key: user_status_key,
              },
            },
          });
        }
      },
    });

  const statusValue = useWatch({ control, name: "status" });
  const subStatusValue = useWatch({ control, name: "subStatus" });

  const getExceptionMutationValue = (duedate) => {
    if (typeof duedate === "object" && duedate?.value.length) {
      return moment()
        .add(parseInt(duedate?.value), "months")
        .format("YYYY-MM-DD");
    } else if (duedate?.length) {
      return moment(duedate).format("YYYY-MM-DD");
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (isClosing) {
      onStatusChanged && onStatusChanged();
    }
  }, [isClosing]);

  const progressMessage = useMemo(() => {
    const statusText = capitalize(statusValue);
    return (
      <AsyncProgressMessage
        description={`The status of ${findingsCount?.toLocaleString()} Findings will be changed to “${statusText}”.`}
        taskName={taskNames.UPDATE_FINDING_STATUS}
        lsItem={LS_CHANGE_STATUS_TASK_ID}
        isClosing={isClosing}
        setIsClosing={setIsClosing}
      />
    );
  }, [statusValue, findingsCount, isClosing]);

  const onSubmit = (data) => {
    const user_status_key =
      statusValue === "IGNORED" ? data.subStatus : manualStatus[statusValue];

    const dueDate =
      dueDateOptions.find((option) => option.value === data?.duedate) ||
      data?.duedate;

    const reopen_date = getExceptionMutationValue(dueDate);

    const mutationData = {
      filters_config: filters_config,
      user_comment_html: data.comment,
      user_comment_plain: convertHtmlToPlainText(data.comment),
      user_status_key,
      reopen_date,
      apply_async: state === findingsCheckType.ALL,
    };

    updateStatusWithComment({ variables: mutationData })
      .then((res) => {
        if (mutationData.apply_async) {
          hideCloseButton();
          localStorage.setItem(
            LS_CHANGE_STATUS_TASK_ID,
            res?.data?.set_user_status_and_comment_v2?.task_id
          );
          setDisplayProgress(true);
        } else {
          onStatusChanged && onStatusChanged();
          closeModal();
          showToast({
            message:
              findingsCount === 1
                ? "Status changed"
                : `The status of ${findingsCount} findings was successfully changed`,
          });
        }
      })
      .catch((error) => showErrorDialog({ message: error.message }));
  };

  const resetComment = () => {
    setValue("comment", "");
  };

  return (
    <div className={`change-status-wrapper`}>
      {!displayProgress ? (
        <form className="change-status-form" onSubmit={handleSubmit(onSubmit)}>
          {findingsCount > 1 && (
            <AlertIndicator
              wrapperClassName={"change-status-count-indicator-wrapper"}
              content={
                <div className={`change-status-indicator-text`}>
                  {`The status of `}
                  <span
                    className={`findings-count-to-change`}
                  >{`${findingsCount?.toLocaleString()} Findings`}</span>
                  {` will be changed.`}
                </div>
              }
            />
          )}
          <div className="form-row">
            <Controller
              wrapperClassName={``}
              name={`status`}
              render={({ field: { onChange, value, onBlur } }) => (
                <SelectInput
                  labelTop
                  data-testid={"finding-status"}
                  label={"Status"}
                  name={`status`}
                  onBlur={onBlur}
                  size="xl"
                  menuWrapClassName="status-menu"
                  SelectInputStyle={"bomba-style"}
                  onChange={(selectedValue) => {
                    onChange(selectedValue.value);
                    resetComment();
                  }}
                  error={errors && !!errors.status ? errors.status : null}
                  placeholder={"Select..."}
                  options={findingStatus}
                  value={
                    findingStatus?.filter(
                      (selectedField) => selectedField.value === value
                    )?.[0] || null
                  }
                  defaultValue={null}
                />
              )}
              rules={{ required: "Required" }}
              control={control}
            />
          </div>

          {statusValue === "IGNORED" && (
            <>
              <div className="form-row">
                <Controller
                  wrapperClassName={``}
                  name={`subStatus`}
                  render={({ field: { onChange, value, onBlur } }) => {
                    return (
                      <SelectInput
                        labelTop
                        label={"Reason"}
                        data-testid={"finding-substatus"}
                        name={`subStatus`}
                        onBlur={onBlur}
                        size="xl"
                        menuWrapClassName="status-menu"
                        SelectInputStyle={"bomba-style"}
                        onChange={(selectedValue) => {
                          onChange(selectedValue.value);
                          resetComment();
                        }}
                        error={
                          errors && !!errors.subStatus ? errors.subStatus : null
                        }
                        placeholder={"Select Reason"}
                        options={findingStatusValues?.[statusValue]}
                        value={
                          findingStatusValues?.[statusValue]?.filter(
                            (selectedField) => selectedField.value === value
                          )?.[0] || null
                        }
                        defaultValue={null}
                      />
                    );
                  }}
                  rules={{ required: "Required" }}
                  control={control}
                />
              </div>
              {subStatusValue === 33 && (
                <div className="form-row">
                  <Controller
                    name={`duedate`}
                    shouldUnregister={true}
                    defaultValue={dueDateOptions[0].value}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RadioButtonsList
                          labelTop
                          inputStyle={"bomba-style"}
                          value={value}
                          data-testid={"duedate"}
                          onChange={(val) => {
                            onChange(val);
                            if (
                              dueDateOptions
                                .map((option) => option.value)
                                .includes(val) ||
                              val === CUSTOM_EMPTY
                            ) {
                              resetComment();
                            }
                          }}
                          label={"Due Date"}
                          options={dueDateOptions}
                          direction={"column"}
                          wrapperClassName={"due-date-options"}
                        />
                      );
                    }}
                    control={control}
                  />
                </div>
              )}
            </>
          )}

          <div className={`change-status-reason`}>
            <Controller
              name="comment"
              render={({ field: { onChange, value, onBlur } }) => (
                <TextEditor
                  label={"Comment"}
                  labelTop
                  data-testid={"change-status-reason"}
                  onChange={(data) => {
                    onChange(data);
                  }}
                  wrapperClassName={
                    "text-editor-area change-status-reason-wrap"
                  }
                  placeholder={"Add your reason for changing the status..."}
                  value={value}
                  size={"max"}
                  name={"comment"}
                  error={errors && !!errors.comment ? errors.comment : null}
                  onBlur={onBlur}
                  inputStyle={"bomba-style"}
                />
              )}
              rules={{ required: "Required" }}
              control={control}
            />
          </div>

          <ModalButtonsContainer>
            <Button
              data-testid={"change-status-submit"}
              type="submit"
              label={"Change"}
              isSubmitting={isSavingStatusWithComment}
              rightIcon={
                isSavingStatusWithComment ? "seem-icon-spinner spinner" : ""
              }
              isSecondary
              disabled={
                isSavingStatusWithComment ||
                (!!errors && Object.keys(errors)?.length) ||
                !isValid
              }
            />
          </ModalButtonsContainer>
        </form>
      ) : (
        <>{progressMessage}</>
      )}
    </div>
  );
};

export default ChangeStatus;
