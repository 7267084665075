import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import useDashboardNavigator from "../../useDashboardNavigator";
import {
  cloudProviderColors,
  groupByTypes,
} from "../../../Findings/FindingsUtils";
import { snakeCase } from "lodash";
import useSummary from "../../useSummary";

const FindingsNumberAndAvgScoreOfUserAccount = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
}) => {
  let cloudProviders = {};
  cloudProviders.aws = {
    name: "AWS",
    fill: cloudProviderColors.aws,
  };
  cloudProviders.sap = {
    name: "SAP",
    fill: cloudProviderColors.sap,
  };
  cloudProviders.gcp = {
    name: "GCP",
    fill: cloudProviderColors.gcp,
  };
  cloudProviders.azure = {
    name: "Azure",
    fill: cloudProviderColors.azure,
  };
  cloudProviders.ecr = {
    name: "ECR",
    fill: cloudProviderColors.ecr,
  };
  cloudProviders.acr = {
    name: "ACR",
    fill: cloudProviderColors.acr,
  };
  cloudProviders.github = {
    name: "Github",
    fill: cloudProviderColors.github,
  };
  cloudProviders.gitlab = {
    name: "Gitlab",
    fill: cloudProviderColors.gitlab,
  };
  cloudProviders.datacenter = {
    name: "Data Center",
    fill: cloudProviderColors.datacenter,
  };
  cloudProviders.docker_hub = {
    name: "Docker Hub",
    fill: cloudProviderColors.docker_hub,
  };
  cloudProviders.bitbucket_cloud = {
    name: "Bitbucket",
    fill: cloudProviderColors.bitbucket_cloud,
  };

  const colors = [
    "#5F9EA0",
    "#DC143C",
    "#E9967A",
    "#808080",
    "#1E90FF",
    "#FFE4C4",
    "#EE82EE",
    "#2E8B57",
    "#4B0082",
    "#DAA520",
  ];

  const getCloudColor = (index) => {
    return colors[index];
  };

  const [dashboardData, setDashboardData] = useState();
  const [cloudProvidersLegendData, setCloudProvidersLegendData] = useState({});
  const [maxFindingsInAccount, setMaxFindingsInAccount] = useState(300);
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id", "finding.score"],
      group_by: [
        `finding.${groupByTypes.CLOUD_PROVIDER}`,
        `finding.${groupByTypes.CLOUD_ACCOUNT}`,
      ],
      aggregate_functions: ["COUNT", "AVG"],
      round_functions: ["ROUND", "ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
      },
    },
  });
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();
  const { dashboardNavigator } = useDashboardNavigator();

  const createXAxis = (xAxis) => {
    xAxis.min = 0;
    xAxis.max = maxFindingsInAccount;
    xAxis.keepSelection = true;
    xAxis.renderer.grid.template.above = true;
    xAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    xAxis.renderer.labels.template.fontSize = 12;
    xAxis.title.text = "Number of Findings";
    xAxis.title.fontWeight = 600;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.grid.template.above = true;
    xAxis.maxPrecision = 0;
  };

  const createYAxis = (yAxis) => {
    yAxis.min = 0;
    yAxis.max = 10;
    yAxis.keepSelection = true;
    yAxis.renderer.grid.template.above = true;
    yAxis.renderer.minGridDistance = 20;
    yAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    yAxis.renderer.labels.template.fontSize = 12;
    yAxis.title.text = "Avg. Score";
    yAxis.title.valign = "top";
    yAxis.title.rotation = 0;
    yAxis.title.align = "center";
    yAxis.title.paddingBottom = 10;
    yAxis.title.dy = -30;
    yAxis.title.fontWeight = 600;
    yAxis.paddingLeft = 10;
    yAxis.paddingRight = 20;
    yAxis.layout = "absolute";
  };

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const total = Math.max.apply(
        Math,
        data.map(function (o) {
          return o[2];
        })
      );

      setMaxFindingsInAccount(total);

      let currentCloudProviders = {};

      let chartData = [];
      data.forEach((accountData) => {
        if (!currentCloudProviders[accountData[0]]) {
          const accountKey = snakeCase(accountData[0]).toLowerCase();
          if (Object.keys(cloudProviders).includes(accountKey)) {
            currentCloudProviders[accountKey] = cloudProviders[accountKey];
          } else {
            currentCloudProviders[accountKey] = {
              name: accountData[0],
              fill: undefined,
            };
          }
        }

        chartData.push({
          count: accountData[2],
          score: accountData[3],
          radius: 1,
          cloudProvider: accountData[0],
          cloudAccount: accountData[1],
        });
      });

      const providersObj = {};
      Object.keys(currentCloudProviders)
        .sort()
        .forEach((el, index) => {
          if (Object.keys(cloudProviders).includes(el)) {
            providersObj[el] = currentCloudProviders[el];
          } else if (currentCloudProviders[el].fill === undefined) {
            providersObj[el] = {
              name: el,
              fill: getCloudColor(index),
              index: index,
            };
          }
        });

      setCloudProvidersLegendData(providersObj);

      if (chartData?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (chartData?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(chartData);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      const scopeId = selectedScope?.value?.id;

      let [chart, legendContainer] = createChart(
        "bubbleDashboard",
        am4charts.XYChart,
        {
          isScrollable: true,
        }
      );

      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      createXAxis(xAxis);
      let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createYAxis(yAxis);

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueX = "count";
      series.dataFields.valueY = "score";
      series.dataFields.value = "radius";
      series.dataFields.name = "cloudAccount";
      series.strokeOpacity = 0;

      series.data = dashboardData;

      chart.legend.useDefaultMarker = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;
      let marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      chart.legend.background.fillOpacity = 0.05;
      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      chart.legend.data = Object.values(cloudProvidersLegendData);

      let bullet = series.bullets.push(new am4core.Circle());
      bullet.fill = am4core.color("#000000");
      bullet.strokeOpacity = 0.8;
      bullet.strokeWidth = 2;
      bullet.fillOpacity = 0.7;
      bullet.stroke = am4core.color("#ffffff");
      bullet.hiddenState.properties.opacity = 0;
      bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      bullet.tooltipText = `[bold]account[/]\n{name}`;
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = "white";
      const hoverState = bullet.states.create("hover");
      hoverState.properties.fillOpacity = 1;
      hoverState.properties.strokeOpacity = 1;

      bullet.adapter.add("fill", function (fill, target) {
        if (!target.dataItem) {
          return fill;
        }

        let values = target.dataItem.dataContext;
        return cloudProvidersLegendData[
          snakeCase(values.cloudProvider).toLowerCase()
        ].fill;
      });

      bullet.events.on("hit", (e) => {
        dashboardNavigator(
          null,
          scopeId,
          {
            groupIndex: 1,
            data: {
              cloudAccount: [
                e.target.dataItem.dataContext.cloudAccount,
                e.target.dataItem.dataContext.cloudProvider,
              ],
            },
          },
          filterToExecute
        );
      });

      series.heatRules.push({
        target: bullet,
        min: 8,
        max: 8,
        property: "radius",
      });

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, cloudProvidersLegendData, dataExists]);

  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"bubbleDashboard"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default FindingsNumberAndAvgScoreOfUserAccount;
