import cb from "cron-builder";
import { startCase } from "lodash";
import { formatTime } from "./Schedule";
import moment from "moment";

export const weekDays = {
  1: "sun",
  2: "mon",
  3: "tue",
  4: "wed",
  5: "thu",
  6: "fri",
  7: "sat",
};

const getDaySuffix = (day) => {
  switch (day) {
    case "1":
      return `${day}st`;

    case "2":
      return `${day}nd`;

    case "3":
      return `${day}rd`;

    default:
      return `${day}th`;
  }
};

export const getCronText = (cron) => {
  if (!!cron) {
    const cronExp = new cb(cron);
    const cronExpData = cronExp.getAll();

    let cronTime = "";
    if (cronExpData?.hour || cronExpData?.minute) {
      const localHour = moment(cronExpData?.hour.toString(), "hh:mm")
        .add(moment().utcOffset(), "minutes")
        .format("HH");
      cronTime = `${localHour}:${formatTime(cronExpData?.minute)}`;
    }

    if (cronExpData?.dayOfTheMonth?.[0] !== "*") {
      return `Every Month on ${cronExpData?.dayOfTheMonth?.[0]
        .split(",")
        ?.map((day) => getDaySuffix(day))
        ?.join(", ")} at ${cronTime}`;
    } else if (cronExpData?.dayOfTheWeek?.[0] !== "*") {
      return `Every Week on ${cronExpData?.dayOfTheWeek?.[0]
        ?.split(",")
        ?.map((day) => startCase(weekDays[day]))
        ?.join(", ")} at ${cronTime}`;
    } else if (cronExpData?.hour || cronExpData?.minute) {
      return `Every Day at ${cronTime}`;
    }
  }
};
