import React, { useContext, useEffect, useMemo, useState } from "react";
import { ModalContext } from "../../../../../Forms/Dialogs/Modal/ModalContext";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../../../Forms/Controls/TextInput/TextInput";
import ModalButtonsContainer from "../../../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../../../Forms/Controls/Button/Button";
import Tree from "../../../../../Components/Tree/Tree";
import useScopesData from "../../../../../AppView/ScopeManagement/useScopesData";
import { SYSTEM } from "../../../../../Findings/FindingsFilters/FilterUtils";
import "./ProfileActions.scss";
import { resourceTypes } from "../../../../../Findings/FindingsUtils";
import TagsSelect from "../../../../../Forms/Controls/TagsSelect/TagsSelect";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS } from "../../../../UsersList/UsersListApi";
import {
  ADD_PROFILE,
  EDIT_PROFILE,
  PROFILE_FRAGMENT,
} from "../../../PermissionsApi";
import { showToast } from "../../../../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";

const ProfileActions = ({ mode, data }) => {
  const { closeModal } = useContext(ModalContext);
  const methods = useForm({
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset: formReset,
  } = methods;

  const { data: usersData } = useQuery(GET_USERS, {
    variables: {
      filters: { not: { activity_status: "DELETED" } },
    },
  });
  const [addProfile] = useMutation(ADD_PROFILE);
  const [editProfile] = useMutation(EDIT_PROFILE);
  const [savedScopes, setSavedScopes] = useState([]);

  useEffect(() => {
    if (!!data) {
      const groups =
        data.scopes_groups?.map((item) => {
          return { id: item.id, type: item.group_type };
        }) || [];

      const scopes =
        data.scopes?.map((item) => {
          return { id: item.id, type: resourceTypes.SCOPE };
        }) || [];

      setSavedScopes([...groups, ...scopes]);

      const users = data.users?.map((item) => {
        return {
          id: item.id,
          name: !!(item.first_name || item.last_name)
            ? `${item.first_name} ${item.last_name}`
            : item.email,
        };
      });

      formReset({
        name: data.name,
        users,
      });
    }
  }, [data]);

  const { scopesData } = useScopesData();

  const treeData = useMemo(() => {
    if (!!scopesData) {
      const scopesList = JSON.parse(
        scopesData?.scope_hierarchy?.scope_hierarchy
      );

      const newRoot = { ...(!!scopesList ? scopesList[0] : null) };
      if (newRoot?.children?.length) {
        newRoot.children = newRoot?.children?.filter(
          (node) => node?.editable && node?.created_by !== SYSTEM
        );
      }

      if (Object.keys([newRoot][0]).length) {
        return [newRoot];
      } else {
        return [];
      }
    }
  }, [scopesData]);

  const handleAddition = (mutationData) => {
    addProfile({
      variables: {
        profile: {
          ...mutationData,
        },
      },
      refetchQueries: [{ query: GET_USERS }],
      update(cache, { data }) {
        cache.modify({
          fields: {
            profiles(existingProfiles) {
              if (data?.add_profile?.profile_id) {
                const newProfileRef = cache.writeFragment({
                  data: {
                    id: data?.add_profile?.profile_id,
                    name: mutationData.name,
                    scopes: {
                      edges: mutationData.scopes,
                    },
                    scope_groups: {
                      edges: mutationData.scope_groups,
                    },
                    users: { edges: mutationData.users },
                    last_updated: new Date(),
                    __typename: "Profile",
                  },
                  fragment: PROFILE_FRAGMENT,
                });

                return { edges: [...existingProfiles.edges, newProfileRef] };
              }
            },
          },
        });
      },
    })
      .then(() => {
        closeModal();
        showToast({ message: "Profile Added Successfully." });
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  const handleEditing = (id, mutationData) => {
    editProfile({
      variables: {
        profile: {
          ...mutationData,
        },
        profile_id: id,
      },
      refetchQueries: [{ query: GET_USERS }],
      update(cache, { data }) {
        cache.modify({
          fields: {
            profiles(existingProfiles, { readField }) {
              if (data?.edit_profile?.profile_id) {
                const editedProfileRef = cache.writeFragment({
                  id,
                  data: {
                    name: mutationData.name,
                    scopes: {
                      edges: mutationData.scopes,
                    },
                    scope_groups: {
                      edges: mutationData.scope_groups,
                    },
                    users: { edges: mutationData.users },
                    last_updated: new Date(),
                    __typename: "Profile",
                  },
                  fragment: PROFILE_FRAGMENT,
                });

                return {
                  edges: [
                    ...existingProfiles.edges.filter(
                      (profile) => id !== readField("id", profile)
                    ),
                    editedProfileRef,
                  ],
                };
              }
            },
          },
        });
      },
    })
      .then(() => {
        closeModal();
        showToast({ message: "Profile Changed Successfully." });
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  const onSubmit = (formData) => {
    const scopes =
      formData?.profile_scopes_selection
        ?.filter((scope) => scope.type === resourceTypes.SCOPE)
        .map((scope) => scope.id) || [];
    const scope_groups =
      formData?.profile_scopes_selection
        ?.filter(
          (scope) =>
            scope.type === resourceTypes.GROUP ||
            scope.type === resourceTypes.ROOT
        )
        .map((scope) => scope.id) || [];
    const users =
      formData?.users === "" ? [] : formData?.users?.map((user) => user.id);
    const name = formData.name;

    const mutationData = { name, scopes, users, scope_groups };

    switch (mode) {
      case "create":
        handleAddition(mutationData);
        break;
      case "edit":
        handleEditing(data.id, mutationData);
        break;
    }
  };

  return (
    <form className={"profile-actions-form"} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={"name"}
        defaultValue={null}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextInput
            placeholder={"Name of the profile"}
            type={"text"}
            label={"Name"}
            inputStyle={"bomba-style"}
            labelTop
            size={"custom"}
            error={errors && errors.name ? errors.name : null}
            value={value}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            data-testid={"profile-actions-name"}
          />
        )}
        control={control}
        rules={{ required: "Required" }}
      />
      <div className={`input-wrap bomba-style has-label-top`}>
        <label className={`input-label`}>Select Scopes For This Profile</label>
        <div className={`profile-scopes-container`}>
          <Controller
            wrapperClassName={``}
            name={`profile_scopes_selection`}
            render={({ field: { onChange } }) => (
              <Tree
                data={treeData}
                isSearchable
                isSelectableRoot
                tristate
                onChange={(nodes) =>
                  onChange(
                    nodes.map((node) => {
                      return { id: node.id, type: node.type };
                    })
                  )
                }
                nodeOperations={{
                  onEdit: () => {},
                  onSelect: () => {},
                  onWarning: () => {},
                }}
                data-testid={"profile-scopes-selection-tree"}
                withCheckbox
                preCheckedNodes={savedScopes}
                error={
                  errors && errors.profile_scopes_selection
                    ? errors.profile_scopes_selection
                    : null
                }
                placeholder={"Search Scopes..."}
              />
            )}
            control={control}
            rules={{
              validate: {
                atLeastOneScope: (value) =>
                  value?.length || `Please choose at least one scope.`,
              },
            }}
          />
        </div>
      </div>
      <Controller
        wrapperClassName={``}
        name={"users"}
        defaultValue={""}
        render={({ field: { onChange, value, onBlur, onFocus } }) => (
          <TagsSelect
            data-testid={`profile-users`}
            onChange={(tags) => onChange(tags)}
            value={value || ""}
            placeholder={`Search Users...`}
            suggestions={usersData?.users?.edges?.map((el) => {
              return {
                id: el.node.id,
                name: !!(el.node.first_name || el.node.last_name)
                  ? `${el.node.first_name} ${el.node.last_name}`
                  : el.node.email,
              };
            })}
            label={"Users"}
            error={errors && errors.users ? errors.users : null}
            inputStyle={"bomba-style"}
            labelTop
            onBlur={onBlur}
            onFocus={onFocus}
            name={"users"}
            size="max"
            minQueryLength={0}
            newTagTextPrefix={`+`}
          />
        )}
        control={control}
      />
      <ModalButtonsContainer>
        <Button
          type="button"
          data-testid={"user-actions-cancel"}
          label={"Cancel"}
          onClick={() => closeModal()}
        />
        <Button
          data-testid={"user-actions-submit"}
          type="submit"
          disabled={!isValid}
          label={"Save"}
          isSecondary
        />
      </ModalButtonsContainer>
    </form>
  );
};

export default ProfileActions;
