import { gql } from "@apollo/client";
export const CREATE_GROUP = gql`
  mutation CreateGroup(
    $name: String!
    $parent_group: String
    $child_scopes: [String]
    $child_groups: [String]
  ) {
    create_scope_group(
      name: $name
      parent_group: $parent_group
      child_scopes: $child_scopes
      child_groups: $child_groups
    ) {
      ok
      group_id
    }
  }
`;

export const EDIT_GROUP = gql`
  mutation EditGroup(
    $group_id: String!
    $name: String
    $parent_group: String
    $child_scopes: [String]
    $child_groups: [String]
    $overwrite: Boolean
  ) {
    edit_scope_group(
      group_id: $group_id
      name: $name
      parent_group: $parent_group
      child_scopes: $child_scopes
      child_groups: $child_groups
      overwrite: $overwrite
    ) {
      ok
      effected_queues
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($group_id: String!) {
    delete_scope_group(group_id: $group_id) {
      ok
    }
  }
`;

export const GET_PARENT_GROUPS = gql`
  query GetParentGroups($group_id: String) {
    scope_groups(group_id: $group_id) {
      scope_groups
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GetGroupById($group_id: String!) {
    scope_group_by_id(group_id: $group_id) {
      group_data
    }
  }
`;
