import React, { useContext, useState } from "react";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../Forms/Controls/Button/Button";
import CheckBox from "../../../Forms/Controls/CheckBox/CheckBox";
import TagsSelect from "../../../Forms/Controls/TagsSelect/TagsSelect";
import "./IntegrationCustomFields.scss";

const fieldTypesEnum = {
  STRING: "STRING",
  SECRET: "SECRET",
  BOOL: "BOOL",
  LIST: "LIST",
};

const fieldSizesEnum = {
  SMALL: "SMALL",
  LARGE: "LARGE",
};

const IntegrationCustomFields = ({
  integrationDefintionSections,
  fieldsValues,
  submitBtnText,
  shouldDisplayCancel = true,
  integrationDefinitionId,
  OnSubmit,
  IntegrationContext,
  buttonsWrapper,
  disable = false,
}) => {
  const { currentIntegration, handleViewIntegration, handleBackToMainPage } =
    useContext(IntegrationContext);

  const methods = useForm({ mode: "all", reValidateMode: "onChange" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;

  const [listTypeFields, setListTypeFields] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (formData) => {
    setIsSubmittingForm(true);
    const name = formData.friendly_name;
    delete formData?.friendly_name;

    const formattedListFields = formatListFields(formData);

    const data = {
      friendly_name: name,
      incoming_configuration: JSON.stringify({
        ...formData,
        ...formattedListFields,
      }),
    };

    OnSubmit(data).then(() => {
      setIsSubmittingForm(false);
    });
  };

  const generateSection = (section) => {
    return (
      <div
        className={"section"}
        key={`${integrationDefinitionId}-${section.name}`}
      >
        {section.name ? (
          <div className={"section-title"}> {section.name} </div>
        ) : (
          <></>
        )}
        {section.rows?.map((row) => generateRow(row))}
      </div>
    );
  };

  const generateRow = (row) => {
    return (
      <div className={"row"}>
        {row.fields?.map((field) => generateField(field, row.fields.length))}
      </div>
    );
  };

  const generateField = (field, numOfFieldsInRow) => {
    if (
      field.field_input_type === fieldTypesEnum.LIST &&
      !listTypeFields.includes(field.field_name)
    ) {
      setListTypeFields((oldListTypeFields) => [
        ...oldListTypeFields,
        field.field_name,
      ]);
    }

    return (
      <div
        className={
          numOfFieldsInRow === 1 &&
          field.field_input_size === fieldSizesEnum.SMALL
            ? "field-s"
            : "field-l"
        }
      >
        <Controller
          name={field.field_name}
          defaultValue={getFieldDefaultValueByType(field)}
          render={({ field: { value, onChange, onBlur } }) => {
            return generateInputElementByType(field, value, onChange, onBlur);
          }}
          control={control}
          rules={{
            required: Boolean(field.required) ? "Required" : "",
          }}
        />
      </div>
    );
  };

  const formatListFields = (data) => {
    let formattedListFields = {};
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        formattedListFields[key] = data[key].map((obj) => obj.name).join(",");
      }
    });

    return formattedListFields;
  };

  const getFieldDefaultValueByType = (field) => {
    let value =
      !!fieldsValues && !!fieldsValues[field.field_name]
        ? fieldsValues[field.field_name]
        : field.default_string_value;

    switch (field.field_input_type) {
      case fieldTypesEnum.BOOL: {
        return value === "TRUE";
      }
      case fieldTypesEnum.LIST: {
        return value
          ? value?.split(",").map((tag) => {
              return { name: tag, id: tag };
            })
          : [];
      }
      default:
        return value;
    }
  };

  const generateInputElementByType = (field, value, onChange, onBlur) => {
    switch (field.field_input_type) {
      case fieldTypesEnum.LIST: {
        return (
          <TagsSelect
            onChange={(tags) => onChange(tags)}
            value={value}
            allowNew
            suggestions={value ? value : []}
            label={field.display_name}
            error={errors[field.field_name] ? errors[field.field_name] : null}
            inputStyle={"bomba-style"}
            labelTop
            minQueryLength={1}
            name={field.field_name}
            data-testid={field.display_name}
            size={"max"}
            newTagTextPrefix={"Create"}
            disabled={disable}
          />
        );
      }
      case fieldTypesEnum.BOOL: {
        return (
          <CheckBox
            data-testid={field.field_name}
            checked={value}
            label={field.display_name}
            name={field.field_name}
            onChange={(val, newValue) => {
              onChange(newValue);
            }}
            disabled={disable}
          />
        );
      }
      default: {
        return (
          <TextInput
            type={
              field.field_input_type === fieldTypesEnum.SECRET
                ? "password"
                : "text"
            }
            value={value}
            labelTop
            data-testid={field.field_name}
            onBlur={onBlur}
            name={field.field_name}
            wrapperClassName={`filter-values-text-box`}
            className="filter-row-last"
            onChange={(e) => onChange(e)}
            label={field.display_name}
            size="max"
            inputStyle={"bomba-style"}
            disabled={disable}
            error={errors[field.field_name] ? errors[field.field_name] : null}
          />
        );
      }
    }
  };

  return (
    <div className={"integration-fields-wrap"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"integration-fields-wrap-grid"}>
          {integrationDefintionSections?.map((section) =>
            generateSection(section)
          )}
        </div>
        {!disable && (
          <div className={`integration-fields-buttons ${buttonsWrapper}`}>
            {shouldDisplayCancel && (
              <Button
                type="button"
                data-testid={"user-actions-cancel"}
                label={"Cancel"}
                onClick={() => {
                  if (currentIntegration?.instances?.length) {
                    delete currentIntegration?.editedInstance;
                    handleViewIntegration(currentIntegration);
                  } else {
                    handleBackToMainPage();
                  }
                }}
              />
            )}
            <Button
              data-testid={"user-actions-submit"}
              type="submit"
              disabled={!isValid || !isDirty || isSubmittingForm}
              label={submitBtnText}
              isSecondary
              rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
              isSubmitting={isSubmittingForm}
            />
          </div>
        )}
      </form>
    </div>
  );
};
export default IntegrationCustomFields;
