import useChart from "../../../Components/Chart/useChart";
import {
  aggregatedStatus,
  getFindingSeverity,
} from "../../../Findings/FindingsUtils";
import Chart from "../../../Components/Chart/Chart";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { getFormatByTimescale, timeScalesData } from "../../DashboardUtils";
import "./ExpectedWorkload.scss";
import { capitalize, groupBy } from "lodash";
import moment from "moment";
import useSummary from "../../useSummary";

const ExpectedWorkload = ({
  timeScale,
  filterToExecute,
  selectedScope,
  scopeToExecute,
}) => {
  const {
    summaryData: data,
    summaryDataError,
    summaryLoading,
  } = useSummary({
    variables: {
      name: "FINDINGS_VIEW",
      selection: ["finding.id"],
      group_by: ["finding.severity", "finding.sla_remaining_time"],
      aggregate_functions: ["COUNT"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute || null,
        scopesjson: scopeToExecute || null,
        scopesid: selectedScope?.value?.id,
        filters_fields_to_override: [
          JSON?.stringify({
            field: "actual_status",
            condition: "",
            value: {
              field: "key",
              condition: "in",
              value: aggregatedStatus.OPEN,
            },
          }),
        ],
      },
    },
  });
  const [dashboardData, setDashboardData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();

  const clearData = () => {
    setDashboardData(null);
  };

  useEffect(() => {
    if (!!summaryLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [summaryLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!summaryDataError) {
      setChartError(false);
      setDashboardData([]);
      stopLoadingChart();
    }
  }, [summaryDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const mappedData = data?.map((res, i) => {
        return {
          severity: getFindingSeverity(data[i][0]),
          time:
            data[i][1] <= 0
              ? 0
              : Math.floor(data[i][1] / timeScalesData?.[timeScale]?.days),
          value: data[i][2],
        };
      });

      const groupByTime = groupBy(mappedData, (item) => item.time);

      const expectedWorkload = Object.keys(groupByTime).map(
        (timeGroup, index) => {
          const timespanWorkload = groupByTime[timeGroup];
          const timespanWorkloadBySeverity = groupBy(
            timespanWorkload,
            (item) => item.severity
          );

          const dataObj = {};
          Object.keys(timespanWorkloadBySeverity)?.forEach((item) => {
            dataObj[item] = timespanWorkloadBySeverity[item].reduce(
              (x, y) => x + y.value,
              0
            );
          });

          const workloadTime = moment
            .utc()
            .startOf(timeScale?.toLowerCase())
            .add(
              parseInt(timeGroup),
              timeScalesData?.[timeScale]?.momentShorthand
            );
          return {
            time: getFormatByTimescale(timeScale, workloadTime, index),
            ...dataObj,
          };
        }
      );

      if (expectedWorkload.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (expectedWorkload.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setDashboardData(
        expectedWorkload?.slice(0, timeScalesData?.[timeScale]?.count)
      );
    }
  }, [data, timeScale]);

  const createCategoryAxis = (categoryAxis) => {
    categoryAxis.dataFields.category = "time";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 40;

    categoryAxis.events.on("sizechanged", function (ev) {
      let axis = ev.target;
      var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < 146) {
        axis.renderer.labels.template.wrap = true;
        categoryAxis.renderer.labels.template.maxWidth = 70;
      } else {
        categoryAxis.renderer.labels.template.maxWidth = 200;
      }
    });
  };

  const createValueAxis = (valueAxis) => {
    valueAxis.min = 0;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.fill = am4core.color("#9494a2");
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.paddingLeft = 10;
    valueAxis.paddingRight = 20;
    valueAxis.layout = "absolute";
    valueAxis.title.text = "Findings";
    valueAxis.title.fontSize = 14;
    valueAxis.title.fontWeight = 500;
    valueAxis.title.rotation = 0;
    valueAxis.title.dy = -30;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "top";
  };

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      let [chart, legendContainer] = createChart(
        "ExpectedWorkloadTrend",
        am4charts.XYChart
      );
      chart.data = dashboardData;

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      createCategoryAxis(categoryAxis);
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis);

      categoryAxis.renderer.grid.template.strokeDasharray = "3,3";
      valueAxis.renderer.grid.template.strokeDasharray = "3,3";

      chart.legend.useDefaultMarker = true;
      let markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 12;
      markerTemplate.height = 12;
      markerTemplate.strokeOpacity = 0;

      function createSeries(field, name) {
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.name = name;
        series.dataFields.valueY = field;
        series.dataFields.categoryX = "time";
        series.sequencedInterpolation = true;

        series.stacked = true;
        series.columns.template.width = am4core.percent(60);
        series.columns.template.maxWidth = 80;
        series.columns.template.tooltipText =
          "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = "white";
        series.columns.template.fillOpacity = 0.6;
        series.columns.template.strokeOpacity = 1;
        series.columns.template.stroke = am4core.color("#ffffff");
        series.columns.template.strokeWidth = "2px";

        return series;
      }

      chart.colors.list = [
        am4core.color("#21bc89"),
        am4core.color("#f7bb00"),
        am4core.color("#ff7506"),
        am4core.color("#ec2a3f"),
      ];

      createSeries("low", "Low");
      createSeries("medium", "Medium");
      createSeries("high", "High");
      createSeries("critical", "Critical");

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [dashboardData, dataExists]);

  return (
    <>
      <div className={`workload-description`}>
        {`Number of findings need to be fixed to meet the SLA - by ${capitalize(
          timeScale
        )}`}
      </div>
      <Chart
        chartLoading={chartLoading}
        chartName={"ExpectedWorkloadTrend"}
        chartError={dataError}
        chartData={dataExists}
      />
    </>
  );
};

export default ExpectedWorkload;
