import { useForm, Controller } from "react-hook-form";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../Forms/Controls/Button/Button";
import React, { useContext, useState } from "react";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import { useMutation } from "@apollo/client";
import { CHANGE_USER_PASSWORD } from "../CustomerProvisioningApi";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";

const ChangeUserPassword = ({ customerID, userID }) => {
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = methods;
  const [changeUserPassword] = useMutation(CHANGE_USER_PASSWORD, {
    refetchQueries: ["get_customers"],
  });
  const { closeModal } = useContext(ModalContext);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    changeUserPassword({
      variables: {
        customer_id: customerID,
        user_id: userID,
        new_password: data.newPassword,
      },
    })
      .then((res) => {
        setIsSubmittingForm(false);
        if (!!res.data?.set_user_password?.ok) {
          closeModal();
          showToast({
            message: res.data?.set_user_password?.msg,
          });
        } else {
          showErrorDialog({ message: res.data?.set_user_password?.msg });
        }
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <div>
      <form
        className={`change-password-wrapper`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name={"newPassword"}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <TextInput
                type={"text"}
                value={value}
                data-testid={"new-password"}
                labelTop
                onBlur={onBlur}
                name={"newPassword"}
                onChange={(e) => {
                  onChange(e);
                }}
                label={"New Password"}
                size="l"
                inputStyle={"bomba-style"}
                error={errors.newPassword ? errors.newPassword : null}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
          }}
        />
        <ModalButtonsContainer>
          <Button
            type="submit"
            label={"Change password"}
            data-testid={"change-password"}
            isSecondary
            rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
            isSubmitting={isSubmittingForm}
            disabled={!isValid || !isDirty || isSubmittingForm}
          />
        </ModalButtonsContainer>
      </form>
    </div>
  );
};
export default ChangeUserPassword;
