import { gql } from "@apollo/client";
import { groupByTypes } from "../../FindingsUtils";

export const GET_FINDINGS_BY_GROUP = gql`
  query GetFindingsByGroup(
    $groupBy: [String]
    $sort: [FindingsViewGroupSortEnum]
    $filters_config: FiltersConfigInput
  ) {
    findings_groups: findings_view_groups(
      group_by: $groupBy
      sort: $sort
      filters_config: $filters_config
    ) {
      total_count
      group_by {
        field
        value
      }
    }
  }
`;

export const generateGetFindingsByGroupMock = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let queryVars = {
    groupBy: variables?.groupBy || groupByTypes.SEVERITY,
    sort: variables?.sort || [undefined],
    filters_config: variables?.filters_config,
  };

  let getFindingsByGroupMock = {
    request: {
      query: GET_FINDINGS_BY_GROUP,
      variables: {
        ...queryVars,
      },
    },
  };

  if (error) {
    return {
      ...getFindingsByGroupMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getFindingsByGroupMock,
      result: { data: result },
    };
  }

  return {
    ...getFindingsByGroupMock,
    result: { data: { findings_groups: [{ total_count: 1 }] } },
  };
};
