import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../Forms/Controls/Button/Button";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import "./ScoringCustomizationList.scss";
import { Collapse } from "react-collapse";
import ScoringCustomizationItem from "../ScoringCustomizationItem/ScoringCustomizationItem";
import { dataSourceIcons } from "../../Findings/FindingsUtils";
import Spinner from "../../images/loader.svg";
import Loader from "../../Components/Loader/Loader";
import useIntegratedDataSources from "../../AppSettings/Integrations/DataSources/useIntegratedDataSources";

const SCORES_DATA = {
  Snyk: {
    original: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
    defaults: ["10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
  },
  Checkmarx: {
    original: ["HIGH", "MEDIUM", "LOW"],
    defaults: ["10", "6", "3"],
  },
  Aqua: {
    original: ["CRITICAL", "HIGH", "MODERATE", "LOW"],
    defaults: ["10", "8", "6", "3"],
  },
  Manual: {
    original: ["CRITICAL", "HIGH", "MEDIUM", "LOW"],
    defaults: ["10", "8", "6", "3"],
  },
  Wiz: {
    original: ["CRITICAL", "HIGH", "MEDIUM", "LOW"],
    defaults: ["10", "8", "6", "3"],
  },
};

const ScoringCustomization = () => {
  const { integratedDataSources } = useIntegratedDataSources();
  const [datasourcesList, setDatasourcesList] = useState(null);
  const [activeDatasources, setActiveDatasources] = useState(null);
  const methods = useForm({ mode: "all" });

  const relocateSnyk = (array) => {
    const snykIndex = array.findIndex((el) => el.name === "Snyk");
    const snykObject = array.find((el) => el.name === "Snyk");
    if (snykIndex > -1) {
      array.splice(snykIndex, 1);
      array.push(snykObject);
    }

    return array;
  };

  useEffect(() => {
    if (!!integratedDataSources) {
      let datasrcArray = integratedDataSources?.datasources?.edges
        ?.filter((el) =>
          Object.keys(SCORES_DATA).includes(
            el.node?.datasource_definition?.name.replace(/ .*/, "")
          )
        )
        .map((el) => {
          const datasourceName = el.node?.datasource_definition?.name.replace(
            / .*/,
            ""
          );
          const originalScores = SCORES_DATA[datasourceName]?.original;
          const defaultScores = SCORES_DATA[datasourceName]?.defaults;
          return {
            name: datasourceName,
            filePrefix: el.node?.datasource_definition?.file_prefix,
            id: el.node?.datasource_definition?.id,
            original_scores: originalScores,
            seem_scores: defaultScores,
          };
        });
      let sortedDatasrcArray = relocateSnyk(datasrcArray);
      setActiveDatasources([]);
      setDatasourcesList(sortedDatasrcArray);
    }
  }, [integratedDataSources]);

  const getDatasourceScores = (datasource) =>
    datasource.original_scores.map((el, index) => (
      <ScoringCustomizationItem
        datasourceName={datasource.name}
        originalScore={el}
        defaultScore={datasource.seem_scores[index]}
      />
    ));

  return datasourcesList ? (
    <div className={"customization-list-wrapper"}>
      <div className="modal-subtitle">
        Use this screen to customize the Seemplicity scores for your data
        sources
      </div>
      <FormProvider {...methods}>
        <form className="scoring-customization-form" onSubmit={() => {}}>
          <ul className="scoring-customization-form-content">
            {datasourcesList.map((el) => (
              <li className={"scoring-customization-item"} key={el.id}>
                <div
                  className="scoring-customization-item-toggle"
                  onClick={() => {
                    const activeArray = activeDatasources.slice();
                    if (activeArray.includes(el.id)) {
                      const index = activeArray.indexOf(el.id);
                      if (index > -1) {
                        activeArray.splice(index, 1);
                      }
                    } else {
                      activeArray.push(el.id);
                    }
                    setActiveDatasources(activeArray);
                  }}
                >
                  <div
                    className={`scoring-customization-item-toggle-icon-wrap ${
                      activeDatasources.includes(el.id)
                        ? "is-open"
                        : "is-closed"
                    }`}
                  >
                    <i className="seem-icon-right-arrow" />
                  </div>

                  <img src={dataSourceIcons[el.filePrefix]?.L} alt={el.name} />
                </div>

                <Collapse isOpened={activeDatasources.includes(el.id)}>
                  <div className="customization-item-wrapper-titles">
                    <div className="customization-item-wrapper-title-left">
                      Original Score
                    </div>
                    <div className="customization-item-wrapper-title-right">
                      Seemplicity Score
                    </div>
                  </div>
                  {getDatasourceScores(el)}
                </Collapse>
              </li>
            ))}
          </ul>
          <ModalButtonsContainer>
            <Button type="submit" label={"Save"} isSecondary disabled />
          </ModalButtonsContainer>
        </form>
      </FormProvider>
    </div>
  ) : (
    <div style={{ height: "495px" }}>
      <Loader center icon={Spinner} />
    </div>
  );
};

export default ScoringCustomization;
