import React, { useContext, useMemo } from "react";
import useWebSocket from "./useWebSocket";
import { AppPreferencesContext } from "../General/AppPreferencesProvider";

export const WebSocketContext = React.createContext({});

const WebSocketProvider = ({ children }) => {
  const { appPreferences } = useContext(AppPreferencesContext);

  const {
    value,
    sendMessage,
    messageHistory,
    status,
    log,
    closeSocket,
    readyState,
    reconnectSocket,
    socket,
    lastMessageUpdate,
  } = useWebSocket(
    appPreferences?.wsTicket,
    process.env.REACT_APP_WEBSOCKET_SERVER,
    [],
    {
      reconnectTimeout: null,
      maxReconnectAttempts: 0,
      keepAliveSocket: true,
      keepAliveTimeout: 56000,
    }
  );

  return (
    <WebSocketContext.Provider
      value={useMemo(
        () => ({
          value,
          sendMessage,
          messageHistory,
          status,
          log,
          closeSocket,
          readyState,
          reconnectSocket,
          socket,
          lastMessageUpdate,
        }),
        [
          value,
          sendMessage,
          messageHistory,
          status,
          log,
          closeSocket,
          readyState,
          reconnectSocket,
          socket,
          lastMessageUpdate,
        ]
      )}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export default React.memo(WebSocketProvider);
