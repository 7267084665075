import React, { useState } from "react";
import AppRouter from "../AppRouter/AppRouter";
import { AuthContext } from "./AuthContext";
import GeneralAppProvider from "../General/GeneralAppProvider";

export const AuthProvider = () => {
  const [authState, setAuthState] = useState({
    user: null,
    setUserData: () => {},
    status: null,
    challengeName: null,
    challengeResponse: null,
    getUserDisplayName: (username) => {
      return username
        ?.substr(0, username?.lastIndexOf("@"))
        .replace(/\./g, " ");
    },
  });

  const updateAuthState = (state) => {
    setAuthState(state);
  };

  return (
    <AuthContext.Provider value={{ authState, updateAuthState }}>
      <GeneralAppProvider>
        <AppRouter />
      </GeneralAppProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
