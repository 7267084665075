import React, { memo, useContext } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import { camelCase, startCase, isString } from "lodash";
import "./AdditionalData.scss";

const AdditionalData = () => {
  const {
    detailsPaneData: { additional_data },
  } = useContext(DetailsPaneContext);

  const additionalDataObj = !!additional_data
    ? JSON.parse(additional_data)
    : {};

  const getAdditionalDataItem = (el) => {
    const isExpectedContentType =
      Array.isArray(additionalDataObj[el]) || isString(additionalDataObj[el]);

    let content = Array.isArray(additionalDataObj[el])
      ? additionalDataObj[el]?.join(", ")
      : additionalDataObj[el];

    if (content === null || content === "" || !isExpectedContentType) {
      return;
    }

    if (content === true) {
      content = "true";
    } else if (content === false) {
      content = "false";
    }

    return (
      <div className={`summary-data-cell additional-data-cell`}>
        <div className={`summary-cell-title`}>{`${startCase(
          camelCase(el)
        )}: `}</div>
        <div title={content} className={`summary-cell-content-wrap`}>
          <div className="summary-cell-content">{content}</div>
        </div>
      </div>
    );
  };

  return Object.keys(additionalDataObj).length ? (
    <div className={`details-pane-section-data`}>
      <div className={`data-blocks`}>
        {Object.keys(additionalDataObj)
          .filter((key) => key !== "repositoryLink")
          .map((el) => getAdditionalDataItem(el))}
      </div>
    </div>
  ) : null;
};

export default memo(AdditionalData);
