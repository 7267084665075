import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCurrentSession } from "./Auth/AuthApi";
import AuthProvider from "./Auth/AuthProvider";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {},
      },
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});

const setAuthorizationLink = setContext((request) => {
  return getCurrentSession()
    .then((data) => {
      const token = data.getAccessToken().getJwtToken();
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    })
    .catch((err) => {
      console.log("Error getting user token ", err);
      return request;
    });
});

const client = new ApolloClient({
  link: setAuthorizationLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      KeyValuePair: { keyFields: ["id", "name"] },
      DatasourceDefinition: { keyFields: ["id", "name"] },
      Query: {
        fields: {
          findings_view: {
            keyArgs: ["filters_config", "sort"],
            merge(existing, incoming, options) {
              const { args, variables } = options;

              const endCursor = args.after;
              // prevents "refetch" from merging results
              if (incoming && !args?.after && !args?.before) {
                return Object.assign({}, incoming);
              }
              if (!existing && incoming) {
                return incoming;
              }
              if (!incoming) {
                return existing;
              }

              const totalCount = variables?.with_total_count
                ? existing.total_count
                : variables?.totalCount;

              let res = [...existing.edges];
              if (existing.edges.length < totalCount) {
                res = [
                  ...existing.edges,
                  ...new Array(totalCount - existing.edges.length).fill(null),
                ];
              }

              let resultStartIndex,
                cursor,
                index,
                pageInfo = {};

              cursor = atob(endCursor);
              index = parseInt(cursor.split(":")[1]);
              resultStartIndex = index + 1;
              pageInfo = {
                startCursor: existing.pageInfo.startCursor,
                hasPreviousPage: index > 0,
                hasNextPage: index < totalCount,
              };

              res.splice(
                resultStartIndex,
                incoming.edges.length,
                ...incoming.edges
              );

              return Object.assign({}, existing, {
                edges: res,
                pageInfo: { ...incoming.pageInfo, ...pageInfo },
              });
            },
          },
          users: {
            merge(existing, incoming) {
              return {
                edges: [...incoming?.edges],
              };
            },
          },
          profiles: {
            merge(existing, incoming) {
              return {
                edges: [...incoming?.edges],
              };
            },
          },
          preferences: {
            merge(existing, incoming) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});

const config = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  oauth: {
    domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
    scope: ["email", "openid"],
    redirectSignIn: `${process.env.REACT_APP_DOMAIN}`,
    redirectSignOut: `${process.env.REACT_APP_DOMAIN}/logout`,
    responseType: "code",
  },
};

Amplify.configure(config);

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <AuthProvider />
    </ApolloProvider>
    <ToastContainer
      containerId={`toast-root`}
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      transition={Flip}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    <div id="modal-root" />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
