import Popover from "react-popover";
import { findingsQueryParams } from "../../Findings/FindingsUtils";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { scopePanelMode, ScopesContext } from "./ScopeProvider/ScopeProvider";
import { ScopeManagementContext } from "../../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/useScope";
import { LS_SCOPE } from "../../Findings/FindingsFilters/FilterUtils";
import useFindingsQueryParam from "../../Findings/FindingsData/useFindingsQueryParam";
import "./ScopeManagement.scss";
import ScopeSettings from "./ScopeSettings/ScopeSettings";
import ScopeManagementMenu from "../../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/ScopeManagementMenu/ScopeManagementMenu";
import { useRouteMatch } from "react-router";
import useScopesData from "./useScopesData";
import {
  AppPreferencesContext,
  userActionsEnum,
} from "../../General/AppPreferencesProvider";
import ScopeManagementDropdownContainer from "./ScopeManagementDropdownContainer";
import { GeneralAppContext } from "../../General/GeneralAppProvider";

const ScopeManagement = ({
  editable,
  withTitle = true,
  readOnly = false,
  disabled = false,
}) => {
  const {
    scopesState: {
      selectedScope,
      scopesList,
      defaultScope,
      mode,
      isNewScope,
      isDirty,
    },
    updateScopeState,
    getScopeValueById,
  } = useContext(ScopesContext);
  let match = useRouteMatch("/:item");
  const { isAllowed } = useContext(AppPreferencesContext);

  const { isOpen, toggleIsOpen, setDefaultsForScopeAndFilter } = useContext(
    ScopeManagementContext
  );

  const { updateAppState } = useContext(GeneralAppContext);

  const { search } = useFindingsQueryParam(null);
  const { param: selectedScopeIdFromURL } = useFindingsQueryParam(
    findingsQueryParams.SCOPE_ID
  );

  const { scopesData, isLoadingScopesData } = useScopesData();

  const getScopes = useCallback(() => {
    if (!!scopesData) {
      const scopes = JSON.parse(scopesData?.scope_hierarchy?.scope_hierarchy);
      let SCOPE_ALL = null;

      const root = scopes?.[0];
      if (!!root) {
        SCOPE_ALL = {
          label: root?.name,
          value: {
            id: root?.id,
            editable: root?.editable,
          },
        };
      }

      return {
        scopesList: scopes,
        defaultScope: SCOPE_ALL,
      };
    }
  }, [scopesData]);

  useEffect(() => {
    const scopes = getScopes();

    updateScopeState(scopes);
  }, [getScopes]);

  const setFiltersFromURL = () => {
    const scopeExists = getScopeValueById(selectedScopeIdFromURL);
    const selectedScope = scopeExists ? scopeExists : defaultScope;

    updateScopeState({
      selectedScope: selectedScope,
      scopeToExecute: selectedScope.value.query,
    });
  };

  const setFiltersFromLocalStorage = () => {
    const lsScope = JSON.parse(localStorage.getItem(LS_SCOPE));
    const scopeExists = getScopeValueById(lsScope?.value?.id);
    const selectedScope = scopeExists ? lsScope : defaultScope;
    updateScopeState({
      selectedScope: selectedScope,
      scopeToExecute: selectedScope.value.query,
    });
  };

  useEffect(() => {
    if (!!scopesList && scopesList.length) {
      if (!!selectedScopeIdFromURL) {
        setFiltersFromURL();
        // if (
        //     !(
        //         selectedScopeIdFromURL === defaultScope?.value?.id &&
        //         selectedFilterIdFromURL === defaultFilter?.value?.id
        //     )
        // ) {
        //     if (reset) {
        //         reset();
        //     }
        // }
      } else if (
        !search &&
        setDefaultsForScopeAndFilter &&
        localStorage.getItem(LS_SCOPE)
      ) {
        setFiltersFromLocalStorage();
        // if (reset) {
        //     reset();
        // }
      } else {
        if (setDefaultsForScopeAndFilter) {
          let scope = {};

          if (!selectedScope && !!defaultScope) {
            scope = {
              selectedScope: defaultScope,
              scopeToExecute: defaultScope?.value?.query,
            };
          }

          //if (Object.keys(filtersObj).length) {
          updateScopeState(scope);
          //}
        }
      }
    }
  }, [selectedScopeIdFromURL, scopesList, defaultScope]);

  const popoverBody = useMemo(() => {
    switch (mode) {
      case scopePanelMode.MENU:
        updateAppState({ disableAppView: false });
        return (
          <ScopeManagementMenu
            editable={isAllowed(userActionsEnum.EDIT_SCOPE) && editable}
          />
        );

      case scopePanelMode.SETTINGS: {
        toggleIsOpen(true);
        updateAppState({ disableAppView: true });
        return <ScopeSettings />;
      }

      default:
        updateAppState({ disableAppView: false });
        return null;
    }
  }, [editable, mode]);

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[popoverBody]}
      enterExitTransitionDurationMs={0}
      className={`scope-popover-class-${match?.params?.item}`}
      onOuterAction={(e) => {
        const isFilterDropdown = e?.path?.filter((path) => {
          return (
            path?.className?.includes("group-location-select") ||
            path?.className?.trim()?.includes("filter-dropdown-wrap") ||
            path?.className?.includes("scope-management-action-menu") ||
            path?.className?.includes("tags-list") ||
            path?.className?.includes("save-scope-warning") ||
            path?.className?.includes("filter-save-as") ||
            path?.className?.includes("unsaved-filter")
          );
        });
        if (!isFilterDropdown?.length) {
          if (isNewScope) {
            updateScopeState({
              isNewScope: false,
              mode: scopePanelMode.CLOSED,
              isDirty: false,
            });
          } else {
            updateScopeState({ mode: scopePanelMode.CLOSED, isDirty: false });
          }

          setTimeout(() => toggleIsOpen(false), 200);
        }
      }}
    >
      <ScopeManagementDropdownContainer
        readOnly={readOnly}
        isLoadingScopesData={isLoadingScopesData}
        withTitle={withTitle}
        isNewScope={isNewScope}
        selectedScope={selectedScope}
        dropdownIndicator={"seem-icon-chevron-down"}
        onClick={(e) => {
          if (!readOnly && mode !== scopePanelMode.SETTINGS) {
            updateScopeState({
              mode: scopePanelMode.MENU,
              isNewScope: false,
              isDirty: false,
            });
            toggleIsOpen(!isOpen);
            e.stopPropagation();
          }
        }}
        isDirty={isDirty}
        disabled={disabled}
      />
    </Popover>
  );
};

export default ScopeManagement;
