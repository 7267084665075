import React, { useCallback } from "react";
import "./SidebarGroup.scss";
import { useParams, Link } from "react-router-dom";

const SidebarGroup = ({ data }) => {
  const { groupPath, itemPath } = useParams();

  const groupItemIsActive = useCallback(
    (itemReference) =>
      groupPath === data.reference && itemPath === itemReference,
    [data.reference, groupPath, itemPath]
  );

  return data.items?.length ? (
    <div className={"sidebar-group-wrapper"}>
      <div className={"side-group-header"}>
        <i className={`seem-icon seem-icon-${data.icon}`} />
        <div className={"sidebar-group-title"}>{data.title}</div>
      </div>
      <ul className={"sidebar-group-items"}>
        {data.items?.map((item, index) => (
          <li key={`${data.reference}-${index}`}>
            <Link
              to={`/settings/${data.reference}/${item.reference}`}
              className={`sidebar-group-item sidebar-group-item-${
                item.reference
              } ${groupItemIsActive(item.reference) ? "active" : ""}`}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default SidebarGroup;
