import queryString from "query-string";
import ReactTooltip from "react-tooltip";
import React, { useContext, useEffect, useState } from "react";
import { FiltersContext } from "../FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../ScopeManagement/ScopeProvider/ScopeProvider";
import "./ShareAppView.scss";
import Button from "../../Forms/Controls/Button/Button";

const ShareAppView = ({ enableSharing }) => {
  const [copyMsg, setCopyMsg] = useState("Copy URL");
  const { filtersState } = useContext(FiltersContext);
  const { selectedFilter } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope } = scopesState;

  useEffect(() => {
    setCopyMsg("Copy URL");
  }, [selectedScope, selectedFilter]);

  const getScopeFilterUrl = () => {
    const queryStringObj = {};
    if (!!selectedFilter) {
      queryStringObj.filterId = selectedFilter?.value?.id;
    }
    if (!!selectedScope) {
      queryStringObj.scopeId = selectedScope?.value?.id;
    }

    const queryStringURL = queryString.stringifyUrl({
      url: `${window.location.href}`,
      query: queryStringObj,
    });

    return queryStringURL;
  };
  return enableSharing ? (
    <>
      <Button
        data-testid={"share-item"}
        wrapperClassName={`header-icon-button`}
        type="button"
        isIcon="seem-icon-link"
        isClean
        data-tip
        data-for="copyScopeFilterTip"
        onClick={() => {
          setCopyMsg("Copied!");
          navigator.clipboard.writeText(getScopeFilterUrl());
        }}
        tooltipData={{
          dataFor: `copyScopeFilterTip`,
          dataTip: true,
        }}
      />
      <ReactTooltip
        id="copyScopeFilterTip"
        place="top"
        className={"copy-tooltip"}
      >
        {copyMsg}
      </ReactTooltip>
    </>
  ) : null;
};

export default ShareAppView;
