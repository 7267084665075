import { gql } from "@apollo/client";
import {
  oneSeriesTrendProperties,
  trendsFormat,
} from "../../Dashboard/TrendsDashboard/TrendsUtils";
import moment from "moment";
import { GET_TREND } from "../../Dashboard/TrendsDashboard/TrendsApi";

export const GET_FINDING_COMMENTS_COUNT_FRAGMENT = gql`
  fragment FindingFragment on Finding {
    comments {
      total_count
    }
  }
`;

export const GET_FINDING_BY_ID = gql`
  ${GET_FINDING_COMMENTS_COUNT_FRAGMENT}
  query get_finding_by_id($id: ID!) {
    finding(id: $id) {
      id
      id_int
      finding_id
      last_reported_time
      cloud_provider
      original_status
      discovered_time
      cloud_account
      datasource {
        friendly_name
        datasource_id
        datasource_definition {
          file_prefix
          name
          id
        }
      }
      resource_name
      resource_id
      resource_type
      main_resource {
        resource_name
        resource_id
      }
      resource_type
      title
      finding_score {
        score
        severity
        original_score
      }
      age
      description
      dictionary_category
      dictionary_sub_category
      remediation
      cloud_account_friendly_name
      additional_data
      original_finding_id
      due_date
      editable
      sla_status
      sla_remaining_time
      aggregation_group_id
      aggregation_group {
        findings_count
      }
      actual_status {
        key
        category
        type
      }
      tickets {
        edges {
          node {
            id
            status
            link
            external_id
            created_time
            created_by
            assignee
            ticket_endpoint {
              field_mapping
            }
            ticket_provider {
              id
              known_identifier
              type
            }
            sprint
            external_status
            sync_attachments
          }
        }
      }
      ...FindingFragment
      owner_email
      owner_name
      seem_tags
      dynamic {
        link
      }
      datasource_tags
      attachments {
        edges {
          node {
            name
            size
            creation_time
            id
            synced_to_ticket
          }
        }
      }
    }
  }
`;

export const GET_FINDING_DYNAMIC_LINK = gql`
  query get_dynamic_link($id: ID!) {
    finding(id: $id) {
      dynamic {
        link
      }
    }
  }
`;

export const GET_TICKET_LINK = gql`
  query get_ticket_link($id: ID!) {
    finding(id: $id) {
      tickets {
        edges {
          node {
            link
          }
        }
      }
    }
  }
`;

export const generateGetFindingByIdMock = ({
  variables = null,
  result = null,
  error = null,
}) => {
  let parameters = {
    name: oneSeriesTrendProperties.newFindingsByDiscoveryDate.queryNameVariable,
    start:
      variables?.parameters?.start ||
      moment().subtract(1, "weeks").format(trendsFormat),
    end: variables?.parameters?.end || moment().format(trendsFormat),
    timescale: variables?.parameters?.timescale || "WEEK",
    filtersjson: variables?.parameters?.filtersjson || "",
    scopesjson: variables?.parameters?.scopesjson || null,
    scope_id: variables?.parameters?.scope_id,
  };

  let getTrendMock = {
    request: {
      query: GET_TREND,
      variables: {
        parameters: {
          ...parameters,
        },
      },
    },
  };

  if (error) {
    return {
      ...getTrendMock,
      error: error,
    };
  }

  if (result) {
    return {
      ...getTrendMock,
      result: { data: result },
    };
  }

  return {
    ...getTrendMock,
    result: { data: { trend: { trend: [] } } },
  };
};
