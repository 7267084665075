import { gql } from "@apollo/client";

export const GET_RESOURCES = gql`
  query ($filters_config: FiltersConfigInput) {
    resources(filters_config: $filters_config) {
      edges {
        node {
          resource_id
          resource_name
          findings_count
          critical_findings_count
          resource_type
          last_modified
          seem_tags_values
          datasource_tags_values
          child_resources {
            edges {
              node {
                resource_id
                resource_name
                findings_count
                critical_findings_count
                resource_type
                last_modified
                seem_tags_values
                datasource_tags_values
                child_resources {
                  edges {
                    node {
                      resource_id
                      resource_name
                      findings_count
                      critical_findings_count
                      resource_type
                      last_modified
                      seem_tags_values
                      datasource_tags_values
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
