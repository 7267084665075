import React, { useContext, useEffect, useState } from "react";
import PopupErrorFallback from "./PopupErrorFallback";
import StaticErrorFallback from "./StaticErrorFallback";
import useLogger from "../../Logger/useLogger";
import { AuthContext } from "../../Auth/AuthContext";

const ErrorFallback = (props) => {
  const [formattedError, setFormattedError] = useState({});
  const { error, type, resetErrorBoundary } = props;
  const {
    authState: { user },
  } = useContext(AuthContext);

  const { logError } = useLogger();

  useEffect(() => {
    if (error) {
      if (!!user) {
        logError(error);
      }

      const errorCopy = {};
      errorCopy.stack = error.stack;
      errorCopy.message = error.message
        ? error.message
        : "Something went wrong";

      setFormattedError(errorCopy);
    }
  }, [error, user]);

  const retryHandler = () => {
    window.location.reload();
    resetErrorBoundary();
  };

  const homepageHandler = () => {
    window.location.replace("/");
    resetErrorBoundary();
  };

  const getFallbackComponent = () => {
    if (!!formattedError) {
      switch (type) {
        case "popup":
          return (
            <PopupErrorFallback
              error={formattedError}
              onRetry={retryHandler}
              onHomepage={homepageHandler}
            />
          );
        case "static":
          return (
            <StaticErrorFallback
              error={formattedError}
              onRetry={retryHandler}
              onHomepage={homepageHandler}
            />
          );
      }
    }
  };

  return getFallbackComponent();
};

export default ErrorFallback;
