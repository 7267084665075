import React, { memo, useContext } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import ReadMore from "../../../Components/ReadMore/ReadMore";

const Description = () => {
  const {
    detailsPaneData: { description, original_status },
  } = useContext(DetailsPaneContext);

  return (
    <div
      className={`details-pane-section-data`}
      data-testid="finding-description-data"
    >
      {original_status && `Original Status: ${original_status}`}
      <ReadMore lines={8} more="More.." less="Less">
        <div>
          {description?.split("\n")?.map((item) => {
            return (
              <span>
                {item}
                <br />
              </span>
            );
          })}
        </div>
      </ReadMore>
    </div>
  );
};

export default memo(Description);
