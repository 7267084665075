import Button from "../../../../Forms/Controls/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import IntegrationCustomFields from "../../IntegrationCustomFields/IntegrationCustomFields";
import "./NewTicketingSystemInstance.scss";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADD_TICKET_PROVIDER,
  GET_TICKET_PROVIDERS,
} from "../TicketingSystemsApi";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import Loader from "../../../../Components/Loader/Loader";
import Spinner from "../../../../images/loader.svg";
import SideDrawer from "../../../../Components/SideDrawer/SideDrawer";
import Instructions from "../../Instructions/Instructions";

const NewTicketingSystemInstance = ({ IntegrationContext }) => {
  const {
    handleViewIntegration,
    handleBackToMainPage,
    currentIntegration: data,
    setCurrentIntegration,
  } = useContext(IntegrationContext);
  const { t } = useTranslation();
  const [instructionsOpen, setInstructionsOpen] = useState(false);

  const [addTicketingSystem] = useMutation(ADD_TICKET_PROVIDER, {
    refetchQueries: [
      {
        query: GET_TICKET_PROVIDERS,
        variables: {
          filters: { not: { known_identifier: "seemplicity" } },
        },
      },
    ],
  });

  const [getTSInstances, { loading: loadingTSInstances }] = useLazyQuery(
    GET_TICKET_PROVIDERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (ticketProviderData) => {
        delete data.editedInstance;
        const ts = {
          ...data,
          instances: ticketProviderData?.ticket_providers?.edges?.map(
            (provider) => provider.node
          ),
        };
        handleViewIntegration(ts);
      },
    }
  );

  const onBackClick = () => {
    if (!data?.hasAtLeastOneInstance) {
      handleBackToMainPage();
    } else {
      getTSInstances({ variables: { filters: { type: data.type } } });
    }
  };

  const onSubmit = (newIntanceData) => {
    const newTicketingData = {
      ticket_provider_type: data.type,
      incoming_configuration: newIntanceData.incoming_configuration,
    };

    return addTicketingSystem({ variables: newTicketingData })
      .then((res) => {
        let confirmationMsg = t("Instance added.");

        if (!res.data?.new_ticket_provider?.ticket_provider?.is_installed) {
          confirmationMsg += t(
            " Please follow instructions for further configuration."
          );
        }
        showToast({
          message: confirmationMsg,
        });
        setCurrentIntegration({
          ...data,
          instances: [
            ...data.instances,
            res.data.new_ticket_provider.ticket_provider,
          ],
          editedInstance: res.data.new_ticket_provider.ticket_provider,
        });
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  useEffect(() => {
    if (
      !data.editedInstance &&
      data?.instructions &&
      data?.instructions.length
    ) {
      setInstructionsOpen(true);
    }
  }, [data.editedInstance, data.instructions]);

  return loadingTSInstances ? (
    <Loader center img={Spinner} />
  ) : (
    <>
      <div className={"new-ts-btns"}>
        <Button
          wrapperClassName={"back-to-ts-details"}
          data-testid={"back-to-btn"}
          type={"button"}
          leftIcon={"seem-icon-left-arrow"}
          label={
            data?.hasAtLeastOneInstance
              ? `Back to ${data?.name}`
              : `Back To Ticketing & Notifications`
          }
          textLink
          onClick={onBackClick}
        />
        {data?.instructions && data?.instructions.length && (
          <Button
            wrapperClassName={"back-to-ts-details"}
            data-testid={"back-to-btn"}
            type={"button"}
            label={"How To Setup?"}
            textLink
            onClick={() => setInstructionsOpen(!instructionsOpen)}
          />
        )}
      </div>
      <div className={"new-ts-instance-sub-title"}>Instance Details</div>
      <IntegrationCustomFields
        IntegrationContext={IntegrationContext}
        submitBtnText={"Confirm"}
        OnSubmit={onSubmit}
        shouldDisplayCancel={false}
        integrationDefinitionId={data.id}
        integrationDefintionSections={data.sections}
        fieldsValues={
          data.editedInstance
            ? JSON.parse(data.editedInstance.configuration)
            : undefined
        }
        buttonsWrapper={"ts-form-btns"}
        disable={!!data.editedInstance}
      />

      {data?.editedInstance?.instructions?.map((instructionItem) => (
        <>
          <div className={"new-ts-instance-sub-title"}>
            {instructionItem.title}
          </div>
          <div className={"ts-instruction-desc"}>
            {instructionItem.description}
          </div>
          {instructionItem.link_text && (
            <Button
              data-testid={"user-actions-submit"}
              label={instructionItem.link_text}
              isSecondary
              onClick={() => window.open(instructionItem.link, "_blank")}
            />
          )}
        </>
      ))}
      {instructionsOpen ? (
        <SideDrawer
          target={"sidebar-layout"}
          isFullScreen={false}
          shadow={"s"}
          width={"665px"}
          minWidth={"553px"}
        >
          <Instructions
            IntegrationContext={IntegrationContext}
            onSideDrawerClose={setInstructionsOpen}
          />
        </SideDrawer>
      ) : null}
    </>
  );
};
export default NewTicketingSystemInstance;
