import React, { useContext, useState, useMemo } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import TicketManagersAndProviders from "../TicketManagersAndProviders/TicketManagersAndProviders";
import TicketEndpointKeys from "../TicketEndpointKeys/TicketEndpointKeys";
import TicketEndpointFields from "../TicketEndpointFields/TicketEndpointFields";
import { ticketEndpointContextEnum } from "../TicketingUtils";
import "./ManualTicket.scss";
import Button from "../../Forms/Controls/Button/Button";
import ModalButtonsContainer from "../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import { MANUAL_TICKET } from "../TicketingAPI";
import { useMutation } from "@apollo/client";
import { showToast } from "../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ModalContext } from "../../Forms/Dialogs/Modal/ModalContext";
import { SYNC_ATTACHMENTS } from "../../Findings/FindingDetailsPane/Attachments/AttachmentsApi";
import useTicketEndpointMutationData from "../useTicketEndpointMutationData/useTicketEndpointMutationData";
import useKeysObject from "../useKeysObject/useKeysObject";

const ManualTicket = ({ findingsId, findingsSeverity }) => {
  const methods = useForm({
    mode: "all",
    defaultValues: {
      ticketManager: null,
      ticketProvider: null,
      keys: {},
      fields: {},
    },
  });
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = methods;

  const [keysFinalPhase, setKeysFinalPhase] = useState(false);
  const {
    setGetPropsFromField: setGetPropsFromKeyField,
    getMutationArray: getKeysMutationArray,
  } = useTicketEndpointMutationData();
  const { setGetPropsFromField, getMutationArray: getFieldsMutationArray } =
    useTicketEndpointMutationData();
  const {
    setGetPropsFromField: setGetPropsFromTicketManager,
    getPropsFromField: getTicketManagerProps,
  } = useTicketEndpointMutationData();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { closeModal } = useContext(ModalContext);
  const selectedTicketProvider = useWatch({
    control,
    name: `ticketProvider`,
  });

  const selectedTicketManager = useWatch({
    control,
    name: `ticketManager`,
  });

  const selectedFields = useWatch({ control, name: "fields" });

  const selectedKeys = useWatch({ control, name: "keys" });

  const { keysObject } = useKeysObject(selectedKeys);

  const queryArrayManualTicket = useMemo(() => {
    if (selectedTicketManager === "JIRA") {
      return ["GetFindings", "GetFindingsByGroup"];
    } else {
      return ["get_finding_by_id", "GetFindings", "GetFindingsByGroup"];
    }
  }, [selectedTicketManager]);

  const [createTicket] = useMutation(MANUAL_TICKET, {
    refetchQueries: queryArrayManualTicket,
  });

  const [syncAttachments] = useMutation(SYNC_ATTACHMENTS, {
    refetchQueries: ["get_finding_by_id"],
  });

  const onSubmit = (formData) => {
    setIsSubmitting(true);

    const duedate = formData?.fields?.duedate;

    const mutationData = JSON.stringify(
      getKeysMutationArray(formData?.keys)
        .concat(getFieldsMutationArray(formData?.fields))
        ?.reduce((acc, obj) => ({ ...acc, [obj.key]: obj.value }), {})
    );

    createTicket({
      variables: {
        finding_id: findingsId,
        duedate,
        value_mapping: mutationData,
        ticket_provider_id: selectedTicketProvider,
      },
    })
      .then((res) => {
        if (selectedTicketManager === "JIRA") {
          syncAttachments({
            variables: {
              finding_id: findingsId,
            },
          })
            .then((res) => {
              if (!res.data?.sync_attachments?.ok) {
                showErrorDialog({
                  message:
                    "Ticket was created successfully, but failed to sync attachments",
                });
              }
            })
            .catch((err) =>
              showErrorDialog({
                message: `Ticket was created successfully, but failed to sync attachments: ${err.message}`,
              })
            );
        }
        showToast({
          message: (
            <div>
              {`Ticket `}
              <a
                href={res?.data?.open_ticket?.ticket?.link}
                target="_blank"
                rel="noreferrer"
              >
                {res?.data?.open_ticket?.ticket?.external_id}
              </a>
              {` Saved To ${
                getTicketManagerProps(selectedTicketManager)?.friendlyName
              }`}
            </div>
          ),
        });
        closeModal();
      })
      .catch((err) => showErrorDialog({ message: err.message }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <FormProvider {...methods}>
      <form className={"manual-ticket-form"} onSubmit={handleSubmit(onSubmit)}>
        <TicketManagersAndProviders
          onFinalPhase={setKeysFinalPhase}
          context={ticketEndpointContextEnum.MANUAL_TICKET}
          onGetProps={setGetPropsFromTicketManager}
        />
        <TicketEndpointKeys
          onGetProps={setGetPropsFromKeyField}
          onFinalPhase={setKeysFinalPhase}
          keysReady={keysFinalPhase}
          keysObject={keysObject}
        />
        {keysFinalPhase && (
          <TicketEndpointFields
            {...methods}
            keysObject={keysObject}
            selectedKeys={selectedKeys}
            shouldUnregisterOnUnmount={false}
            selectedTicketProvider={selectedTicketProvider}
            onGetProps={setGetPropsFromField}
            findingsSeverity={findingsSeverity}
            findingsId={findingsId}
            context={ticketEndpointContextEnum.MANUAL_TICKET}
          />
        )}
        <ModalButtonsContainer>
          <Button
            data-testid={"manual-ticket-submit"}
            type="submit"
            label={"Create Ticket"}
            isSecondary
            rightIcon={isSubmitting ? "seem-icon-spinner spinner" : ""}
            disabled={
              !isValid ||
              isSubmitting ||
              (!!selectedFields && !Object.keys(selectedFields)?.length)
            }
          />
        </ModalButtonsContainer>
      </form>
    </FormProvider>
  );
};

export default ManualTicket;
