import React, { useContext, useEffect, useState } from "react";
import "./Statuses.scss";
import TicketStatus from "../../FindingsData/FindingsList/FindingsListItem/TicketStatus/TicketStatus";
import ChangeStatus from "./ChangeStatus/ChangeStatus";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import ManualTicket from "../../../Ticketing/ManualTicket/ManualTicket";
import FindingStatusSelect from "./ChangeStatus/FindingStatusSelect";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import { GET_PROVIDERS } from "./StatusesApi";
import { useApolloClient, useQuery } from "@apollo/client";
import Button from "../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import { getFindingIdFilter } from "../../FindingsData/FindingsList/FindingsMultiSelect/FindingsMultiSelectFilters";
import { findingsCheckType } from "../../FindingsUtils";

const Statuses = () => {
  const { openModal, isOpen } = useContext(ModalContext);
  const { t } = useTranslation();
  const { data: providersData } = useQuery(GET_PROVIDERS);
  const client = useApolloClient();

  const {
    detailsPaneData: {
      id,
      actual_status,
      findingTicket,
      ticketStatus,
      severity,
      id_int,
    },
  } = useContext(DetailsPaneContext);
  const [selectedStatus, setSelectedStatus] = useState(actual_status?.category);

  useEffect(() => {
    if (actual_status?.category) {
      setSelectedStatus(actual_status?.category);
    }
  }, [actual_status]);

  return (
    <div className={`details-pane-buttons-row`}>
      <div className={`details-pane-status-container`}>
        <div className={`actual-status status-data`}>
          <FindingStatusSelect
            data-testid={"finding_status"}
            onChange={(newStatus) => {
              setSelectedStatus(newStatus);
              openModal({
                title: "Change Finding Status",
                size: "s",
                overlay: true,
                className: "modal-change-status",
                component: (
                  <ChangeStatus
                    filters_config={{
                      filtersjson: JSON.stringify(getFindingIdFilter([id_int])),
                      scopesjson: "",
                      scopesid: null,
                    }}
                    currentStatus={actual_status}
                    newStatus={newStatus}
                    findingsCount={1}
                    state={findingsCheckType.SINGLE}
                    findingIntId={id_int}
                    findingId={id}
                    onStatusChanged={() => {
                      client.refetchQueries({
                        include: ["get_finding_by_id", "get_comments"],
                      });
                    }}
                  />
                ),
              });
            }}
            selectedStatus={
              isOpen
                ? selectedStatus
                : actual_status?.key === 15
                ? "REVIEWED"
                : actual_status?.category
            }
          />
        </div>

        {providersData?.ticket_providers?.edges?.[0]?.node ? (
          !ticketStatus ? (
            <div className="details-pane-button details-pane-button-create-ticket">
              <div
                className={`jira-status status-data`}
                onClick={() => {
                  openModal({
                    title: "Create Ticket",
                    className: "modal-manual-ticket",
                    overlay: true,
                    size: "l",
                    icon: "seem-icon-new-ticket",
                    component: (
                      <ManualTicket
                        findingsId={id}
                        findingsSeverity={severity}
                      />
                    ),
                  });
                }}
              >
                <Button
                  label={t("Create Ticket")}
                  data-testid={"modal-manual-ticket"}
                />
              </div>
            </div>
          ) : (
            <div className={"ticket-status-wrap"}>
              <a target={"_blank"} href={findingTicket?.link} rel="noreferrer">
                <TicketStatus status={ticketStatus} />
              </a>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Statuses;
