import "./AppHeader.scss";
import Logo from "../icons/logos/seemplicity-white.svg";
import AppMenu from "../AppMenu/AppMenu";
import AppSettings from "../AppSettings/AppSettings";

const AppHeader = () => (
  <div className={`app-header-wrapper`}>
    <div className="app-header-left">
      <div className={`logo-menu-container`}>
        <a className="logo-link" href={"/"} target="_self">
          <div
            className={"logo-container"}
            style={{ backgroundImage: `url(${Logo})` }}
          />
        </a>
      </div>
      <AppMenu />
    </div>
    <div className="app-header-right">
      <AppSettings />
    </div>
  </div>
);

export default AppHeader;
