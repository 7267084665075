import React, { useContext, useMemo } from "react";
import "./AggregatedFindings.scss";
import { dataSourceIcons, findingStatuses } from "../../FindingsUtils";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import { useTranslation } from "react-i18next";
import ViewMoreItemsButton from "../ViewMoreItemsButton/ViewMoreItemsButton";
import { AggregatedFindingsContext } from "./AggregatedFindingsProvider";
import Button from "../../../Forms/Controls/Button/Button";
import { useLazyQuery } from "@apollo/client";
import { GET_FINDING_DYNAMIC_LINK } from "../DetailsPaneApi";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import ReactTooltip from "react-tooltip";

const RELATED_FINDING_COUNT = 2;
const RELATED_FINDINGS_COUNT_FS = 6;
const AggregatedFindings = () => {
  const {
    detailsPaneData: { isFullScreen, id_int, aggregation_group },
  } = useContext(DetailsPaneContext);

  const {
    relatedFindings: data,
    totalCount,
    isLoadingFindings,
    aggregationGroup,
  } = useContext(AggregatedFindingsContext);

  const { t } = useTranslation();

  const [getDynamicLink] = useLazyQuery(GET_FINDING_DYNAMIC_LINK, {
    onCompleted: (data) => {
      const link = data?.finding?.dynamic?.link;
      window.open(link, "_blank");
    },
  });

  const getRow = (findingData, index) => (
    <div className={`data-table-row`} key={findingData.id}>
      {findingData.title && !isLoadingFindings ? (
        <>
          <div className={`data-table-cell`} title={"Data Source"}>
            <img
              className={`data-source-icon`}
              src={
                dataSourceIcons[
                  findingData.datasource?.datasource_definition?.file_prefix
                ]?.S
              }
              alt={findingData.datasource.friendly_name}
            />
          </div>
          <div className={`data-table-cell`} title={findingData.title}>
            {findingData.title}
          </div>
          <div className={`data-table-cell`} title={findingData.resource_name}>
            {findingData.resource_name}
          </div>
          <div
            className={`data-table-cell`}
            title={"Original Score"}
            data-testid={`data-table-cell-original-score-${index}`}
          >
            {findingData.original_score}
          </div>
          <div
            className={`data-table-cell`}
            title={"Status"}
            data-testid={`data-table-cell-status-${index}`}
          >
            {findingStatuses?.[findingData.actual_status?.key]}
          </div>
          <div
            className={`data-table-cell`}
            // title={"View"}
            data-testid={`data-table-cell-view-${index}`}
          >
            <div
              className={`view-icon-container ${
                !findingData?.datasource?.has_link ? "is-disabled" : ""
              }`}
            >
              <ReactTooltip id={`ds-link-na-${index}`} place="top">
                Link is not available
              </ReactTooltip>
              <Button
                isSecondary
                isClean
                data-testid={`view-finding-ds-${index}`}
                isIcon="seem-icon-expand"
                type={`button`}
                onClick={() => {
                  if (findingData?.datasource?.has_link) {
                    getDynamicLink({ variables: { id: findingData?.id } });
                  }
                }}
                tooltipData={
                  !findingData?.datasource?.has_link
                    ? {
                        dataFor: `ds-link-na-${index}`,
                        dataTip: true,
                      }
                    : {}
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`data-table-cell`} title={"Source"}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`} title={"Description"}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`} title={"Original Score"}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`} title={"Status"}>
            <div className="loading-row" />
          </div>
          <div className={`data-table-cell`} title={"View"}>
            <div className="loading-row" />
          </div>
        </>
      )}
    </div>
  );

  const getRowsData = () => {
    let findingsList = relatedFindingsWithoutParent;
    if (!isFullScreen) {
      findingsList = findingsList?.slice(0, 2);
    } else if (findingsList?.length > 10) {
      findingsList = findingsList?.slice(0, 10);
    }

    return findingsList?.map((findingData, index) =>
      getRow(findingData?.node, index)
    );
  };

  const relatedFindingsWithoutParent = useMemo(() => {
    return data?.filter((finding) => finding?.node.id_int !== id_int);
  }, [data]);

  const findingHeight = useMemo(() => {
    return !isFullScreen || totalCount <= RELATED_FINDINGS_COUNT_FS
      ? "100%"
      : `${(RELATED_FINDINGS_COUNT_FS - 1) * 50}px`;
  }, [isFullScreen, totalCount]);

  const fixedFindingsCount = useMemo(() => {
    const total = aggregationGroup?.findings_count || 0;
    const open = aggregationGroup?.open_findings_count || 0;

    return total - open;
  }, [aggregationGroup?.findings_count, aggregationGroup?.open_findings_count]);

  const fixedFindingsPercent = useMemo(() => {
    return (fixedFindingsCount * 100) / aggregation_group?.findings_count;
  }, [aggregation_group?.findings_count, fixedFindingsCount]);

  return (
    <>
      {aggregation_group?.findings_count > 1 && (
        <div
          className={`raw-findings-progress-bar-container`}
          data-testid={`raw-findings-progress-bar-container`}
        >
          <div className={`progress-bar-label`}>Status</div>
          <ProgressBar
            inputStyle={"bomba-style"}
            percent={fixedFindingsPercent}
            withLegend
            labelsData={{
              completed: {
                count: fixedFindingsCount,
                label: (
                  <>
                    <span>{`${fixedFindingsPercent?.toFixed(
                      1
                    )}% Fixed & Ignored`}</span>
                    <span
                      data-testid={`fixed-findings-count`}
                      className={`progress-label-count`}
                    >
                      {`(${fixedFindingsCount})`}
                    </span>
                  </>
                ),
              },
              total: {
                count: aggregationGroup?.open_findings_count,
                label: (
                  <>
                    <span>{`${(100 - fixedFindingsPercent)?.toFixed(
                      1
                    )}% Open`}</span>
                    <span
                      data-testid={`open-findings-count`}
                      className={`progress-label-count`}
                    >
                      {`(${aggregationGroup?.open_findings_count})`}
                    </span>
                  </>
                ),
              },
            }}
          />
        </div>
      )}
      <div className={`data-table finding-related-table`}>
        <div className="data-table-row data-table-header-row">
          <div className={`data-table-cell data-table-cell-header`}>
            {t("Source")}
          </div>
          <div className={`data-table-cell data-table-cell-header`}>
            {t("Description")}
          </div>
          <div className={`data-table-cell data-table-cell-header`}>
            {t("Resource Name")}
          </div>
          <div className={`data-table-cell data-table-cell-header`}>
            <div className={`score-cell-header`}>
              {t("Original Score")}
              <i className={`seem-icon seem-icon-down-arrow`} />
            </div>
          </div>
          <div className={`data-table-cell data-table-cell-header`}>
            {t("Status")}
          </div>
          <div className={`data-table-cell data-table-cell-header`}>
            {t("View")}
          </div>
        </div>
        <div
          style={{
            height: `${findingHeight}`,
          }}
        >
          <>{getRowsData()}</>
        </div>
      </div>
      <ViewMoreItemsButton
        total={totalCount}
        count={RELATED_FINDING_COUNT}
        isFullScreen={isFullScreen}
      />
    </>
  );
};

export default AggregatedFindings;
