import { findingsCheckType } from "../../../FindingsUtils";

export const getFindingIdFilter = (findingsList) => {
  return {
    operator: "and",
    operands: [
      {
        field: "id",
        condition: "in",
        value: findingsList,
      },
    ],
  };
};

export const getMultiSelectFilter = (
  checkedNodes,
  scopesjson,
  filtersjson,
  scopesid
) => {
  if (checkedNodes?.type === findingsCheckType.ALL) {
    return {
      scopesjson,
      filtersjson,
      scopesid,
    };
  } else {
    return {
      scopesjson: "",
      scopesid: null,
      filtersjson: JSON.stringify(
        getFindingIdFilter(checkedNodes?.findingsList)
      ),
    };
  }
};
