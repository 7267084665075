import React from "react";
export const AuthContext = React.createContext({
  user: null,
  setUserData: () => {},
  status: null,
  challengeName: null,
  challengeResponse: null,
  username: "",
  isDemoAccount: false,
  domain: "",
});
