import React, { useCallback } from "react";
import "./DsLastSynced.scss";
import useIntegratedDataSources from "../Integrations/DataSources/useIntegratedDataSources";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { dataSourceIcons } from "../../Findings/FindingsUtils";

const DsLastSynced = () => {
  const { t } = useTranslation();
  const { integratedDataSources, integratedDataSourcesError } =
    useIntegratedDataSources(true);

  const getLastSyncItem = (datasource) => {
    const lastUpdate = moment(datasource?.last_received_finding);
    return (
      <li key={datasource.id}>
        <div
          className={`data-table-row`}
          data-testid={`last-sync-row-${datasource.id}`}
        >
          <div
            className={`data-table-cell integration-content`}
            data-testid={`integration-row-${datasource?.datasource_definition?.file_prefix}`}
          >
            <img
              className={`data-source-icon`}
              src={
                dataSourceIcons[datasource?.datasource_definition?.file_prefix]
                  ?.S
              }
              alt={datasource?.datasource_definition?.name}
            />
            {datasource?.datasource_definition?.name}
          </div>
          <div
            className={`data-table-cell update-content`}
            data-testid={`update-row-${datasource?.datasource_definition?.file_prefix}`}
          >
            {`${lastUpdate?.fromNow()}, ${lastUpdate?.format("hh:mm a")}`}
          </div>
        </div>
      </li>
    );
  };

  const getContentByState = useCallback(() => {
    if (
      !!integratedDataSources &&
      integratedDataSources?.datasources?.edges?.length
    ) {
      const filteredArray = integratedDataSources?.datasources?.edges?.filter(
        (el) =>
          el?.node?.datasource_definition?.name !== "Manual" &&
          !!el?.node?.last_received_finding
      );

      const aggregatedByPrefixArray = filteredArray.reduce(
        (result, currDatasource) => {
          const index = result.findIndex(
            (el) =>
              el?.node?.datasource_definition?.file_prefix ===
              currDatasource?.node?.datasource_definition?.file_prefix
          );
          if (index === -1) {
            result?.push(currDatasource);
          } else {
            if (
              moment(currDatasource?.node?.last_received_finding) >=
              moment(result[index]?.node?.last_received_finding)
            ) {
              result[index] = currDatasource;
            }
          }
          return result;
        },
        []
      );

      return (
        <ul className={"last-sync-list"}>
          {aggregatedByPrefixArray
            .sort(
              (firstEl, secondEl) =>
                moment(secondEl?.node?.last_received_finding) -
                moment(firstEl?.node?.last_received_finding)
            )
            .map((el) => getLastSyncItem(el.node))}
        </ul>
      );
    } else {
      return (
        <>
          <div className={`data-table-row`}>
            <div className={`data-table-cell integration-content is-loading`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell update-content is-loading`}>
              <div className="loading-row" />
            </div>
          </div>
          <div className={`data-table-row`}>
            <div className={`data-table-cell integration-content is-loading`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell update-content is-loading`}>
              <div className="loading-row" />
            </div>
          </div>
          <div className={`data-table-row`}>
            <div className={`data-table-cell integration-content is-loading`}>
              <div className="loading-row" />
            </div>
            <div className={`data-table-cell update-content is-loading`}>
              <div className="loading-row" />
            </div>
          </div>
        </>
      );
    }
  }, [integratedDataSources]);

  return (
    <div className={`header-popover-menu last-sync-wrapper`}>
      {!integratedDataSourcesError ? (
        <div className={`data-table`}>
          <div className="data-table-row">
            <div
              className={`data-table-cell data-table-cell-header integration-header`}
            >
              {t("Data Source")}
            </div>
            <div
              className={`data-table-cell data-table-cell-header update-content`}
            >
              {t("Collection time")}
            </div>
          </div>
          {getContentByState()}
        </div>
      ) : !!integratedDataSources &&
        integratedDataSources?.datasources?.edges?.length === 0 ? (
        <div className={"last-sync-message"}>No Results</div>
      ) : integratedDataSourcesError ? (
        <div className={"last-sync-message"}>An error occurred.</div>
      ) : null}
    </div>
  );
};

export default DsLastSynced;
