import { useEffect, useState } from "react";
import {
  LS_RECENT_FILTERS,
  RECENT_LIST_SIZE,
} from "../../Findings/FindingsFilters/FilterUtils";

const useRecentFilters = () => {
  const [recentFilters, setRecentFilters] = useState([]);
  useEffect(() => {
    setRecentFilters(
      JSON?.parse(localStorage.getItem(LS_RECENT_FILTERS)) || []
    );
  }, []);

  const addFilterToRecent = (filterData) => {
    const currentRecentFiltersList = [...recentFilters];
    if (!currentRecentFiltersList?.includes(filterData)) {
      currentRecentFiltersList?.unshift(filterData);

      if (currentRecentFiltersList?.length > RECENT_LIST_SIZE) {
        currentRecentFiltersList.splice(
          RECENT_LIST_SIZE,
          currentRecentFiltersList?.length - RECENT_LIST_SIZE
        );
      }

      localStorage.setItem(
        LS_RECENT_FILTERS,
        JSON.stringify(currentRecentFiltersList)
      );

      setRecentFilters(currentRecentFiltersList);
    }
  };

  const removeFilterFromRecent = (filterId) => {
    const currentRecentFiltersList = [...recentFilters];
    const filterIndex = currentRecentFiltersList.findIndex(
      (filter) => filter === filterId
    );
    if (filterId >= 0) {
      currentRecentFiltersList.splice(filterIndex, 1);

      localStorage.setItem(
        LS_RECENT_FILTERS,
        JSON.stringify(currentRecentFiltersList)
      );

      setRecentFilters(currentRecentFiltersList);
    }
  };

  return {
    recentFilters,
    addFilterToRecent,
    removeFilterFromRecent,
  };
};

export default useRecentFilters;
