import React, { useContext } from "react";
import "../FindingsListItem/FindingsListItem.scss";
import "./FindingsListHeader.scss";
import { useTranslation } from "react-i18next";
import { findingsCheckType, findingsColumns } from "../../../FindingsUtils";
import { AppPreferencesContext } from "../../../../General/AppPreferencesProvider";
import CheckBox from "../../../../Forms/Controls/CheckBox/CheckBox";
import Button from "../../../../Forms/Controls/Button/Button";

const FindingsListHeader = ({
  columns,
  multiSelect,
  checkedFindingsType,
  onCheckFinding,
  totalCount,
}) => {
  const { t } = useTranslation();
  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);

  return (
    <>
      <div
        className={`findings-list-header-wrapper ${
          !columns?.includes(findingsColumns.ACCOUNT)
            ? "hasnt-finding-account-col"
            : ""
        }${
          !columns?.includes(findingsColumns.RESOURCES)
            ? "hasnt-finding-resources-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.TITLE)
            ? "hasnt-finding-title-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.SEVERITY)
            ? "hasnt-finding-score-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.STATUS)
            ? "hasnt-finding-status-col"
            : ""
        }  ${
          !columns?.includes(findingsColumns.SLA_STATUS)
            ? "hasnt-finding-sla-status-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.TICKET_STATUS)
            ? "hasnt-finding-ticket-status-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.TAGS)
            ? "hasnt-finding-tags-col"
            : ""
        } ${
          !columns?.includes(findingsColumns.TICKET_ID)
            ? "hasnt-finding-ticket-id"
            : ""
        }`}
      >
        <div className={`table-header-cell group-indicator-container `}>
          <div className="group-indicator" />
        </div>
        {columns?.includes(findingsColumns.ACCOUNT) && (
          <>
            <div className={`table-header-cell finding-account-col`}>
              {multiSelect && (
                <CheckBox
                  data-testid={`findings-table-checkbox`}
                  checked={checkedFindingsType === findingsCheckType.ALL}
                  className={"findings-table-checkbox"}
                  onChange={(val, newValue) => {
                    onCheckFinding({ id: val }, newValue);
                  }}
                  value={findingsCheckType.ALL}
                />
              )}
              {t("Account")}
            </div>
          </>
        )}
        {columns?.includes(findingsColumns.RESOURCES) && (
          <div className={`table-header-cell finding-resources-col`}>
            {t("Resources")}
          </div>
        )}
        {columns?.includes(findingsColumns.TITLE) && (
          <div className={`table-header-cell finding-title-col`}>
            <div className={`finding-title-col-data`}>{t("Title")}</div>
          </div>
        )}
        {columns?.includes(findingsColumns.STATUS) && (
          <div className={`table-header-cell finding-status-col`}>
            {t("Status")}
          </div>
        )}
        {columns?.includes(findingsColumns.SEVERITY) && (
          <div className={`table-header-cell finding-score-col`}>
            <div>{t("Severity")}</div>
            <i className="seem-icon seem-icon-down-arrow" />
          </div>
        )}

        {columns?.includes(findingsColumns.SLA_STATUS) && slaEnabled && (
          <div className={`table-header-cell finding-sla-status-col`}>
            {t("SLA Status")}
          </div>
        )}
        {columns?.includes(findingsColumns.SLA_STATUS) && !slaEnabled && (
          <div className={`table-header-cell finding-sla-status-col`}>
            {t("Age")}
          </div>
        )}
        {columns?.includes(findingsColumns.TICKET_STATUS) && (
          <div className={`table-header-cell finding-ticket-status-col`}>
            {t("Ticket Status")}
          </div>
        )}
        {columns?.includes(findingsColumns.TAGS) && (
          <div className={`table-header-cell finding-tags-col`}>
            {t("Tags")}
          </div>
        )}
        {columns?.includes(findingsColumns.TICKET_ID) && (
          <div className={`table-header-cell finding-ticket-id-col`}>
            {t("Ticket ID")}
          </div>
        )}
      </div>
      {multiSelect && checkedFindingsType === findingsCheckType.ALL && (
        <div
          className={"finding-list-item-table-row multi-select-indicator-row"}
        >
          <div className="group-indicator-container">
            <div className="group-indicator"></div>
          </div>
          <div className={`multi-select-indicator`}>
            <div className="multi-select-indicator-text">
              {`All ${totalCount} findings that match the selected filter.`}
            </div>
            <Button
              data-testid={"cancel-findings-selection"}
              type={"button"}
              label={"Cancel Selection"}
              textLink
              onClick={() => {
                onCheckFinding({ id: findingsCheckType.ALL }, false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FindingsListHeader;
