import React, { memo, useContext, useEffect, useState, useMemo } from "react";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import moment from "moment";
import { startCase, lowerCase } from "lodash";
import ReactTooltip from "react-tooltip";
import {
  getTicketManagerFriendlyName,
  ticketManagersEnum,
  ticketStatus as ticketStatusEnum,
} from "../../../Ticketing/TicketingUtils";
import { useQuery } from "@apollo/client";
import { GET_TICKET_DETAILS } from "./TicketApi";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import useChangeTooltipMsg from "../../../Components/Tooltip/useChangeTooltipMsg";

const Ticket = () => {
  const {
    detailsPaneData: { findingTicket, due_date },
  } = useContext(DetailsPaneContext);
  const { copyMsg, setCopyMsg } = useChangeTooltipMsg(
    "Copy ticket URL",
    "Copied!"
  );

  const [isLinkFocused, setIsLinkFocused] = useState(false);

  const { data: ticketDetailsData } = useQuery(GET_TICKET_DETAILS, {
    skip:
      !findingTicket ||
      findingTicket.status.toLowerCase() ===
        ticketStatusEnum.DELETED.text.toLowerCase() ||
      (findingTicket?.ticket_provider?.type !== ticketManagersEnum.JIRA &&
        findingTicket?.ticket_provider?.type !== ticketManagersEnum.FIBERY),
    variables: {
      ticket_id: findingTicket?.id,
    },
  });

  const ticketManager = findingTicket?.ticket_provider.type;
  const ticketDomain = findingTicket?.ticket_provider.known_identifier;
  const ticketEndpoint = findingTicket?.ticket_endpoint;
  const ticketId = findingTicket?.external_id;
  const ticketStatus = startCase(lowerCase(findingTicket?.status));
  const ticketObj = useMemo(() => {
    return {
      id: ticketId,
      ticketManager,
      sprint: findingTicket?.sprint,
      creationDate: moment(findingTicket?.created_time).format("MMMM Do YYYY"),
      creator: findingTicket?.created_by,
      link: findingTicket?.link,
    };
  }, [
    findingTicket?.created_by,
    findingTicket?.created_time,
    findingTicket?.sprint,
    ticketDomain,
    ticketEndpoint,
    ticketId,
    ticketManager,
  ]);

  useEffect(() => {
    if (!!ticketDetailsData) {
      try {
        if (ticketManager === ticketManagersEnum.JIRA) {
          ticketObj.jiraTicketProviderDuedate = JSON.parse(
            ticketDetailsData?.get_ticket_details
          )?.fields?.duedate;
          ticketObj.assignee = JSON.parse(
            ticketDetailsData?.get_ticket_details
          )?.fields?.assignee?.displayName;
        } else if (ticketManager === ticketManagersEnum.FIBERY) {
          const url = JSON.parse(ticketDetailsData?.get_ticket_details)?.url;
          const externalId = url?.substr(url.lastIndexOf("/") + 1);
          ticketObj.fiberyLink = url;
          ticketObj.fiberyId = externalId;
        }
      } catch (e) {
        if (ticketManager === ticketManagersEnum.JIRA) {
          ticketObj.jiraTicketProviderDuedate = "";
          ticketObj.assignee = "";
        } else if (ticketManager === ticketManagersEnum.FIBERY) {
          ticketObj.fiberyLink = "";
          ticketObj.fiberyId = "";
        }
      }
    }
  }, [ticketDetailsData, ticketManager, ticketObj]);

  return (
    <div className={`details-pane-section-data`}>
      <div className={`data-blocks`}>
        {!!ticketObj.ticketManager && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Ticket Manager: `}</div>
            <div
              title={getTicketManagerFriendlyName(ticketObj.ticketManager)}
              className={`summary-cell-content`}
            >
              {getTicketManagerFriendlyName(ticketObj.ticketManager)}
            </div>
          </div>
        )}
        {((ticketManager === ticketManagersEnum.FIBERY &&
          ticketObj?.fiberyLink &&
          ticketObj?.fiberyId &&
          !!ticketObj.id) ||
          (ticketManager !== ticketManagersEnum.FIBERY && !!ticketObj.id)) && (
          <div
            className={`summary-data-cell`}
            onMouseEnter={() => setIsLinkFocused(true)}
            onMouseLeave={() => setIsLinkFocused(false)}
          >
            <div className={`summary-cell-title`}>{`Ticket Number: `}</div>
            <div
              title={
                ticketManager === ticketManagersEnum.FIBERY
                  ? ticketObj.fiberyId
                  : ticketObj.id
              }
              className={`summary-cell-content summary-jira-number`}
            >
              <a
                className={`ticket-url`}
                alt="View ticket"
                title={`View Ticket`}
                rel="noreferrer"
                target="_blank"
                href={ticketObj?.link || ticketObj?.fiberyLink}
              >
                {ticketManager === ticketManagersEnum.FIBERY
                  ? ticketObj?.fiberyId
                  : ticketObj.id}
              </a>
              {isLinkFocused && (
                <div
                  className={`summary-jira-number-copy ${
                    copyMsg === "Copied!" ? "summary-jira-number-copy-bye" : ""
                  }`}
                  onClick={() => {
                    try {
                      setCopyMsg("Copied!");
                      navigator.clipboard.writeText(ticketObj.link);
                    } catch (err) {
                      showDialog({
                        title: "Error",
                        type: "error",
                        message: err.message,
                        buttons: [
                          {
                            isSecondary: true,
                            label: "Close",
                          },
                        ],
                      });
                    }
                  }}
                >
                  <i
                    data-tip
                    data-for="copyTip"
                    className="seem-icon seem-icon-link details-pane-jira-copy"
                  />
                  <ReactTooltip
                    id="copyTip"
                    place="top"
                    className={"copy-tooltip"}
                  >
                    {copyMsg}
                  </ReactTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {!!ticketStatus && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Status: `}</div>
            <div title={ticketStatus} className={`summary-cell-content`}>
              {ticketStatus}
            </div>
          </div>
        )}
        {ticketManager === ticketManagersEnum.JIRA && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Sprint: `}</div>
            <div
              title={ticketObj.sprint ?? "Backlog"}
              className={`summary-cell-content`}
            >
              {ticketObj.sprint ?? "Backlog"}
            </div>
          </div>
        )}
        {ticketManager === ticketManagersEnum.JIRA && !!ticketObj.creator && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Reporter: `}</div>
            <div title={ticketObj.creator} className={`summary-cell-content`}>
              {ticketObj.creator}
            </div>
          </div>
        )}
        {!!ticketObj.creationDate && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Created: `}</div>
            <div
              title={ticketObj.creationDate}
              className={`summary-cell-content`}
            >
              {ticketObj.creationDate}
            </div>
          </div>
        )}
        {!!ticketObj?.jiraTicketProviderDuedate && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Jira Due Date: `}</div>
            <div
              title={moment(ticketObj?.jiraTicketProviderDuedate).format(
                "MMMM Do YYYY"
              )}
              className={`summary-cell-content`}
            >
              {moment(ticketObj?.jiraTicketProviderDuedate).format(
                "MMMM Do YYYY"
              )}
            </div>
          </div>
        )}
        {!!due_date && (
          <div className={`summary-data-cell`}>
            <div
              className={`summary-cell-title`}
            >{`Seemplicity Due Date: `}</div>
            <div
              title={moment(due_date).format("MMMM Do YYYY")}
              className={`summary-cell-content`}
            >
              {moment(due_date).format("MMMM Do YYYY")}
            </div>
          </div>
        )}
        {ticketManager === ticketManagersEnum.JIRA && !!ticketObj?.assignee && (
          <div className={`summary-data-cell`}>
            <div className={`summary-cell-title`}>{`Assignee: `}</div>
            <div title={ticketObj?.assignee} className={`summary-cell-content`}>
              {ticketObj?.assignee}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Ticket);
