import React, { useEffect, useLayoutEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { capitalize } from "lodash";
import Chart from "../../../Components/Chart/Chart";
import useChart from "../../../Components/Chart/useChart";
import { createDateAxis, createValueAxis } from "../TrendsUtils";
import useTrend from "../useTrend";

const FindingsByDataSource = ({
  selectedScope,
  filterToExecute,
  scopeToExecute,
  timeScale,
}) => {
  const {
    trendData: data,
    trendDataError,
    trendLoading,
  } = useTrend({
    variables: {
      selection: ["open"],
      timescale: [timeScale],
      group_by: ["source"],
      aggregate_functions: ["SUM"],
      round_functions: ["ROUND"],
      filters_config: {
        filtersjson: filterToExecute,
        scopesjson: scopeToExecute,
        scopesid: selectedScope?.value?.id,
        filters_fields_to_exclude: ["actual_status"],
      },
    },
  });
  const [trendData, setTrendData] = useState(null);
  const {
    chartLoading,
    dataError,
    dataExists,
    setChartError,
    setChartDataExists,
    startLoadingChart,
    stopLoadingChart,
    createChart,
  } = useChart();

  const clearData = () => {
    setTrendData(null);
  };

  useEffect(() => {
    if (!!trendLoading) {
      startLoadingChart();
      clearData();
      setChartError(false);
      setChartDataExists(false);
    }
  }, [trendLoading, setChartDataExists, setChartError, startLoadingChart]);

  useEffect(() => {
    if (!!trendDataError) {
      setChartError(true);
      setTrendData({});
      stopLoadingChart();
    }
  }, [trendDataError, setChartError, stopLoadingChart]);

  useEffect(() => {
    if (!!data) {
      const dataObj = {};

      for (let i = 0; i < data.length; i += 1) {
        const category = data[i][1];
        if (!dataObj.hasOwnProperty(category)) {
          dataObj[category] = [];
        }

        dataObj[category].push({
          date: data[i][0],
          value: data[i][2],
        });
      }

      if (Object.keys(dataObj)?.length === 0) {
        stopLoadingChart();
        setChartDataExists(false);
      } else if (Object.keys(dataObj)?.length) {
        setChartError(false);
        stopLoadingChart();
        setChartDataExists(true);
      }

      setTrendData(dataObj);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (dataExists) {
      stopLoadingChart();

      let [chart, legendContainer] = createChart(
        "FindingsByDataSourceTrend",
        am4charts.XYChart,
        {
          isScrollable: true,
        }
      );

      chart.colors.list = [
        am4core.color("#41425a"),
        am4core.color("#607aff"),
        am4core.color("#b3bbf7"),
        am4core.color("#230051"),
      ];

      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      createDateAxis(dateAxis, timeScale);
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      createValueAxis(valueAxis, "Findings");
      valueAxis.getSeriesDataItem = function (series, position) {
        const key = this.axisFieldName + this.axisLetter;
        const value = this.positionToValue(position);
        const dataItem = series.dataItems.getIndex(
          series.dataItems.findClosestIndex(
            value,
            (x) => {
              return x[key] ? x[key] : undefined;
            },
            "any"
          )
        );
        return dataItem;
      };

      if (trendData) {
        for (const [key] of Object.entries(trendData)) {
          createSeries(key, capitalize(key));
        }
      }

      function createSeries(s, name) {
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.name = name;
        series.tensionX = 0.8;
        series.strokeWidth = 3;
        series.strokeOpacity = 0.6;
        series.tooltipText = "{name}: {value}";
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = "white";

        const bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 4;
        bullet.circle.fill = am4core.color("#fff");

        let segment = series.segments.template;
        segment.interactionsEnabled = true;

        let hoverState = segment.states.create("hover");
        hoverState.properties.strokeWidth = 3;

        let dimmed = segment.states.create("dimmed");
        dimmed.properties.stroke = am4core.color("#dadada");

        segment.events.on("over", function (event) {
          processOver(event.target.parent.parent.parent);
        });

        segment.events.on("out", function (event) {
          processOut(event.target.parent.parent.parent);
        });

        series.data = trendData[s];

        return series;
      }

      chart.legend.itemContainers.template.events.on("over", function (event) {
        processOver(event.target.dataItem.dataContext);
      });

      chart.legend.itemContainers.template.events.on("out", function (event) {
        processOut(event.target.dataItem.dataContext);
      });

      function processOver(hoveredSeries) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
          segment.setState("hover");
        });

        chart.series.each(function (series) {
          if (series !== hoveredSeries) {
            series.segments.each(function (segment) {
              segment.setState("dimmed");
            });
            series.bulletsContainer.setState("dimmed");
          }
        });
      }

      function processOut(hoveredSeries) {
        chart.series.each(function (series) {
          series.segments.each(function (segment) {
            segment.setState("default");
          });
          series.bulletsContainer.setState("default");
        });
      }

      return () => {
        chart.dispose();
        legendContainer.dispose();
      };
    }
  }, [trendData, dataExists]);
  return (
    <Chart
      chartLoading={chartLoading}
      chartName={"FindingsByDataSourceTrend"}
      chartError={dataError}
      chartData={dataExists}
    />
  );
};

export default FindingsByDataSource;
