import React, { useState, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import "./Attachments.scss";
import axios from "axios";
import FileInput from "../../../Forms/Controls/FileInput/FileInput";
import {
  ATTACH_TO_FINDING,
  DELETE_ATTACHMENT,
  GET_PRE_SIGNED_DOWNLOAD_URLS,
  GET_PRE_SIGNED_UPLOAD_URLS,
} from "./AttachmentsApi";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import AttachmentDataView from "./AttachmentDataView/AttachmentDataView";
import { DetailsPaneContext } from "../DetailsPaneProvider";
import FileSaver from "file-saver";
import { fileStatus } from "./AttachmentsUtils";
// import ActionsMenu from "../../../Components/ActionsMenu/ActionsMenu";
import { attachmentsActions, AttachmentsReducer } from "./AttachmentsReducer";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";

const Attachments = () => {
  const { t } = useTranslation();

  const {
    detailsPaneData: { attachments, id },
  } = useContext(DetailsPaneContext);

  const [filesInProcess, setFilesInProcess] = useState([]);
  const [attachToFinding] = useMutation(ATTACH_TO_FINDING);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);

  const [state, dispatch] = useReducer(AttachmentsReducer, []);

  useEffect(() => {
    const attachmentsList = attachments?.edges?.map((attachment) => {
      return { ...attachment.node, status: fileStatus.OK };
    });

    attachmentsList?.sort(function (a, b) {
      return new Date(b.creation_time) < new Date(a.creation_time) ? -1 : 1;
    });

    dispatch({ type: attachmentsActions.INIT, payload: attachmentsList });
  }, [attachments]);

  /*---------------------------------------------------------------------------*/
  const removeAttachment = (attachmentId) => {
    deleteAttachment({
      variables: { finding_id: id, attachment_id: attachmentId },
    })
      .then((res) => {
        if (res?.data?.delete_attachment?.ok) {
          dispatch({
            type: attachmentsActions.REMOVE_ATTACHMENT,
            payload: {
              id: attachmentId,
            },
          });
        } else {
          showDialog({
            title: "Error",
            type: "error",
            message: "Remove attachment failed",
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        }
      })
      .catch((error) => {
        showDialog({
          title: "Error",
          type: "error",
          message: error.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };
  /*---------------------------------------------------------------------------*/
  const uploadFile = (preSignedUrl, fileToUpload) => {
    const formData = new FormData();
    let fileKey = "";

    preSignedUrl.fields.forEach((field) => {
      if (field.key === "key") {
        fileKey = field.value;
      }
      formData.append(field.key, field.value);
    });
    formData.append("file", fileToUpload);

    axios
      .post(preSignedUrl.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        attachFileToFinding(fileToUpload, fileKey);
      })
      .catch((err) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "name",
            name: fileToUpload.name,
            fileData: { status: fileStatus.ERROR, errorMessage: err.message },
          },
        });
      })
      .finally(() => {
        removeFileFromProcessingList(fileToUpload.name);
      });
  };

  const [getPreSignedUploadUrl] = useLazyQuery(GET_PRE_SIGNED_UPLOAD_URLS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      data?.get_upload_presigned_urls.forEach((preSignedUrl, index) =>
        uploadFile(preSignedUrl, filesInProcess[index])
      );
    },
    onError: (err) => {
      //TODO: maybe handle bulk of files
      filesInProcess.forEach((file, index) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "name",
            name: file[index].name,
            fileData: { status: fileStatus.ERROR, errorMessage: err.message },
          },
        });
      });

      setFilesInProcess([]);
    },
  });

  const handleFileInput = (e) => {
    const files = e.target.files;
    setFilesInProcess([...files]);

    const newFiles = [...files].map((fileData) => {
      return {
        name: fileData.name,
        creation_time: moment().unix() * 1000,
        status: fileStatus.UPLOAD,
      };
    });

    dispatch({ type: attachmentsActions.ADD_ATTACHMENTS, payload: newFiles });
    getPreSignedUploadUrl({ variables: { number_of_files: files?.length } });
  };

  /*---------------------------------------------------------------------------*/
  const downloadFile = (preSignedUrl, fileToDownload) => {
    axios
      .get(preSignedUrl.url, { responseType: "blob" })
      .then((res) => {
        const file = URL.createObjectURL(new Blob([res.data]));
        FileSaver.saveAs(file, fileToDownload.name);

        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "id",
            id: fileToDownload.id,
            fileData: { status: fileStatus.OK },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "id",
            id: fileToDownload.id,
            fileData: { status: fileStatus.ERROR, errorMessage: err.message },
          },
        });
      })
      .finally(() => {
        removeFileFromProcessingList(fileToDownload.name);
      });
  };

  const [getPreSignedDownloadUrl] = useLazyQuery(GET_PRE_SIGNED_DOWNLOAD_URLS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      data?.get_download_presigned_urls.forEach((preSignedUrl, index) =>
        downloadFile(preSignedUrl, filesInProcess[index])
      );
    },
    onError: (err) => {
      //TODO: maybe handle bulk of files
      filesInProcess.forEach((file, index) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "id",
            id: file[index].id,
            fileData: { status: fileStatus.ERROR, errorMessage: err.message },
          },
        });
      });

      setFilesInProcess([]);
    },
  });

  const handleFileDownload = (files) => {
    const attachmentIds = files.map((file) => {
      dispatch({
        type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
        payload: {
          key: "id",
          id: file.id,
          fileData: { status: fileStatus.DOWNLOAD },
        },
      });
      return file.id;
    });

    setFilesInProcess(files);
    getPreSignedDownloadUrl({ variables: { attachment_ids: attachmentIds } });
  };
  /*---------------------------------------------------------------------------*/

  const attachFileToFinding = (fileToUpload, fileKey) => {
    const fileData = {
      finding_id: id,
      attachments: [
        {
          name: fileToUpload.name,
          size: fileToUpload.size,
          key: fileKey,
        },
      ],
    };

    attachToFinding({ variables: fileData })
      .then((res) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "name",
            name: fileToUpload.name,
            fileData: {
              status: fileStatus.OK,
              id: res?.data?.new_attachments?.attachments?.[0].id,
              synced_to_ticket:
                res?.data?.new_attachments?.attachments?.[0].synced_to_ticket,
            },
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: attachmentsActions.UPDATE_ATTACHMENT_DATA,
          payload: {
            key: "name",
            name: fileToUpload.name,
            fileData: { status: fileStatus.ERROR, errorMessage: err.message },
          },
        });
      });
  };

  const removeFileFromProcessingList = (fileName) => {
    const updatedList = filesInProcess.filter((file) => file.name === fileName);
    setFilesInProcess(updatedList);
  };

  return (
    <>
      <div className={`attachments-container`}>
        <FileInput
          data-testid={`upload-attachments`}
          handleFileInput={handleFileInput}
          isMulti={true}
          accept="video/*,image/*,.pdf"
        />

        {/*  <ActionsMenu*/}
        {/*    data-testid={`more-attachments-actions`}*/}
        {/*    menuItems={[*/}
        {/*      {*/}
        {/*        "data-testid": "download-all-attachments",*/}
        {/*        text: "Download All",*/}
        {/*        onClick: () => {*/}
        {/*          handleFileDownload(state);*/}
        {/*        },*/}
        {/*      },*/}
        {/*    ]}*/}
        {/*  />*/}
      </div>

      <div className={`data-table finding-attachments-table`}>
        {state?.length ? (
          <>
            <div className="data-table-row">
              <div className={`data-table-cell data-table-cell-header`}>
                {t("Name")}
              </div>
              <div className={`data-table-cell data-table-cell-header`}>
                {t("Date Added")}
              </div>
              <div className={`data-table-cell data-table-cell-header`} />
              <div className={`data-table-cell data-table-cell-header`} />
            </div>
            {state?.map((file, index) => (
              <AttachmentDataView
                file={file}
                handleFileDownload={handleFileDownload}
                removeAttachment={removeAttachment}
                rowIndex={index}
              />
            ))}
          </>
        ) : (
          <div className={`attachments-no-data`}>No Attachments</div>
        )}
      </div>
    </>
  );
};

export default Attachments;
