import React, { useMemo } from "react";
import FindingsListHeader from "../FindingsListHeader/FindingsListHeader";
import Loader from "../../../../Components/Loader/Loader";
import Spinner from "../../../../images/loader.svg";
import noResultsImg from "../../../../images/no-results.svg";
import FindingsRender from "../FindingsRender/FindingsRender";
import DetailsPaneProvider from "../../../FindingDetailsPane/DetailsPaneProvider";
import ManualFindings from "../../../ManualFindings/ManualFindings";
import FindingsActions from "../FindingsActions/FindingsActions";
import { findingsCheckType } from "../../../FindingsUtils";

const FindingsTable = ({
  isLoading,
  isError,
  findings,
  isLoadingFindings,
  groupBy,
  forwardedRef,
  findingsListData,
  hasSelectedItem,
  onSelectedFinding,
  handleScroll,
  columns,
  selectedFilterRow,
  enableRowSelection,
  className,
  showFindingsActions = false,
  checkedFindings = [],
  onCheckFinding,
  multiSelect,
  totalCount,
  filteringEnabled,
}) => {
  const actionsMenu = useMemo(() => {
    return (
      showFindingsActions && (
        <div className={`findings-operations-panel`}>
          <FindingsActions
            itemsCount={
              checkedFindings?.type === findingsCheckType.ALL
                ? "All"
                : checkedFindings?.findingsList?.length
            }
            checkedNodes={checkedFindings}
            totalCount={
              checkedFindings?.type === findingsCheckType.ALL
                ? totalCount
                : checkedFindings?.findingsList?.length
            }
          />
          <ManualFindings />
        </div>
      )
    );
  }, [checkedFindings, showFindingsActions, totalCount]);
  return (
    <>
      {isLoading ? (
        <div className={`findings-data-area ${isLoading ? "is-loading" : ""}`}>
          <FindingsListHeader columns={columns} />
          <Loader center img={Spinner} />
        </div>
      ) : isError && !findingsListData ? (
        <div className={`findings-data-area ${className ? className : ""}`}>
          {actionsMenu}
          <FindingsListHeader columns={columns} />
          <Loader center icon={noResultsImg} text="Error loading data" />
        </div>
      ) : !findings?.length ? (
        <div
          className={`findings-data-area ${
            !findings?.length ? "is-no-data" : ""
          } ${className ? className : ""}`}
        >
          {actionsMenu}
          <FindingsListHeader columns={columns} />
          <Loader center text="No data" />
        </div>
      ) : (
        <>
          <div
            className={`findings-data-area ${className ? className : ""} ${
              hasSelectedItem ? "findings-data-area-details-pane-open" : ""
            } ${
              checkedFindings?.findingsList?.length ||
              checkedFindings?.type === findingsCheckType.ALL
                ? "findings-data-area-checkbox-selected"
                : ""
            }`}
          >
            {actionsMenu}
            <FindingsListHeader
              columns={columns}
              multiSelect={multiSelect}
              checkedFindingsType={checkedFindings?.type}
              onCheckFinding={onCheckFinding}
              totalCount={totalCount?.toLocaleString()}
            />
            <div className={`findings-table-container`}>
              <div
                className={`findings-table`}
                style={{ height: "100%", overflowY: "auto" }}
              >
                <FindingsRender
                  findings={findings}
                  isLoadingFindings={isLoadingFindings}
                  groupBy={groupBy}
                  forwardedRef={forwardedRef}
                  columns={columns}
                  handleScroll={handleScroll}
                  onSelectedFinding={onSelectedFinding}
                  hasSelectedItem={hasSelectedItem}
                  selectedFilterRow={selectedFilterRow}
                  enableRowSelection={enableRowSelection}
                  checkedFindings={checkedFindings}
                  onCheckFinding={onCheckFinding}
                  multiSelect={multiSelect}
                  filteringEnabled={filteringEnabled}
                />
              </div>

              {hasSelectedItem && (
                <DetailsPaneProvider
                  isFullScreen={false}
                  closeDetailsPane={() => {
                    onSelectedFinding(null, false);
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FindingsTable;
