import { gql } from "@apollo/client";

export const CREATE_REMEDIATION_QUEUE = gql`
  mutation CreateRemediationQueue(
    $cron: String
    $endpoint_type: EndpointType
    $filters_config: FiltersConfigInput!
    $max_concurrent_opened_tickets: Int
    $state: RemediationQueueState
    $ticket_provider_id: String!
    $finding_filter: FindingFilterInput
    $title: String!
    $description: String!
    $value_mapping: JSONString!
  ) {
    create_remediation_queue(
      cron: $cron
      finding_filter: $finding_filter
      endpoint_type: $endpoint_type
      filters_config: $filters_config
      max_concurrent_opened_tickets: $max_concurrent_opened_tickets
      state: $state
      description: $description
      ticket_provider_id: $ticket_provider_id
      title: $title
      value_mapping: $value_mapping
    ) {
      remediation_queue {
        id
      }
    }
  }
`;

export const UPDATE_REMEDIATION_QUEUE = gql`
  mutation UpdateRemediationQueue(
    $cron: String
    $id: String!
    $max_concurrent_opened_tickets: Int
    $state: RemediationQueueState
    $title: String
    $value_mapping: JSONString
    $filters_config: FiltersConfigInput
  ) {
    update_remediation_queue(
      cron: $cron
      id: $id
      max_concurrent_opened_tickets: $max_concurrent_opened_tickets
      state: $state
      title: $title
      value_mapping: $value_mapping
      filters_config: $filters_config
    ) {
      ok
    }
  }
`;

export const SYNC_QUEUE = gql`
  mutation SyncQueue($remediation_queue_id: String!) {
    remediation_queue_sync_now(remediation_queue_id: $remediation_queue_id) {
      tickets_opened
      tickets_failed_to_open
    }
  }
`;
