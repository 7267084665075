import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_TICKET_ENDPOINT_KEYS } from "../TicketingAPI";
import useSections from "../useSections/useSections";

const useKeysSavedValues = (ticketProviderId, ticketEndpointId) => {
  const [fieldsSections, setFieldsSections] = useState(null);

  const { data: ticketEndpointFieldsData, error: ticketEndpointFieldsError } =
    useQuery(GET_TICKET_ENDPOINT_KEYS, {
      variables: {
        ticket_provider_id: ticketProviderId,
        ticket_endpoint_id: ticketEndpointId,
      },
      skip: !ticketProviderId || !ticketEndpointId,
    });

  const { defaultValues, getFieldPropsByFieldName } = useSections(
    fieldsSections,
    ticketEndpointFieldsError
  );

  useEffect(() => {
    if (!!ticketEndpointFieldsError) {
      setFieldsSections(null);
    }
  }, [ticketEndpointFieldsError]);

  useEffect(() => {
    if (!!ticketEndpointFieldsData) {
      setFieldsSections(
        ticketEndpointFieldsData?.endpoint_keys?.fields_sections
      );
    }
  }, [ticketEndpointFieldsData]);

  return {
    savedKeys: defaultValues,
    getKeyPropsByKeyName: getFieldPropsByFieldName,
  };
};

export default useKeysSavedValues;
