import PasswordValidator from "password-validator";
import React from "react";
import "./PasswordPolicy.scss";
import Popover from "react-popover";
import { useTranslation } from "react-i18next";

let schema = new PasswordValidator();
schema
  .is()
  .min(8)
  .has()
  .digits(1)
  .has()
  .uppercase(1)
  .has()
  .lowercase(1)
  .has()
  .symbols(1);

const PasswordPolicy = ({ password }) => {
  const { t } = useTranslation();
  const failedRulesList = schema.validate(password, { list: true });
  const isPasswordValid = !failedRulesList.length;

  const passwordPolicyRules = [
    {
      name: "min",
      message: "Contains at least 8 characters",
    },
    {
      name: "digits",
      message: "Requires at least 1 number",
    },
    {
      name: "uppercase",
      message: "Requires at least 1 uppercase letter",
    },
    {
      name: "lowercase",
      message: "Requires at least 1 lowercase letter",
    },
    {
      name: "symbols",
      message: "Requires at least 1 special character",
    },
  ];

  return (
    <Popover
      isOpen={!isPasswordValid}
      tipSize={0.01}
      place={"above"}
      body={[
        <div key={`password-policy-rule`} className={`password-policy-wrapper`}>
          {passwordPolicyRules.map((rule, index) => {
            const isValid = !failedRulesList.includes(rule.name);
            return (
              <div
                key={`password-policy-rule-${index}`}
                className={`password-rule-row ${isValid ? "valid" : ""}`}
              >
                {t(rule.message)}
              </div>
            );
          })}
        </div>,
      ]}
    >
      <div
        key={`error-message-tip-holder`}
        className={`error-message-tip-holder`}
      />
    </Popover>
  );
};

export default PasswordPolicy;
