import React, { useContext } from "react";
import Button from "../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import "./Actions.scss";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import ManualFinding from "../../ManualFindings/ManualFinding/ManualFinding";
import { manualFindingModes, entityTypes } from "../../FindingsUtils";
import { DetailsPaneContext } from "../../FindingDetailsPane/DetailsPaneProvider";
import ReactTooltip from "react-tooltip";

const Actions = ({
  isFullScreen,
  type,
  closePane,
  expandLink,
  editable = false,
}) => {
  const { t } = useTranslation();
  const { openModal } = useContext(ModalContext);
  const {
    detailsPaneData: {
      title,
      description,
      score,
      discovered_time,
      remediation,
      cloud_account,
      id,
      resource_name,
      resource_type,
    },
  } = useContext(DetailsPaneContext);

  return (
    <div
      className={`details-pane-actions ${
        !isFullScreen ? "is-not-full-screen" : ""
      }`}
    >
      {type === `${entityTypes.FINDING}s` && editable && (
        <>
          <Button
            data-testid={"edit-pane"}
            isClean
            isLarge
            tooltipData={{ dataFor: "edit-finding-btn", dataTip: true }}
            isIcon="seem-icon-edit"
            type={`button`}
            onClick={() =>
              openModal({
                title: "Edit Finding",
                icon: "seem-icon-new-ticket",
                size: "m",
                overlay: true,
                component: (
                  <ManualFinding
                    mode={manualFindingModes.EDIT}
                    findingData={{
                      title,
                      description,
                      id,
                      score,
                      date: discovered_time,
                      remediationText: remediation?.text,
                      remediationLink: remediation?.url,
                      resource_name,
                      resource_type,
                      cloud_account,
                    }}
                  />
                ),
              })
            }
            title={t("Edit")}
          />
          <ReactTooltip id="edit-finding-btn" place="top">
            Edit Finding
          </ReactTooltip>
        </>
      )}

      {!isFullScreen && (
        <>
          <a
            className="form-button-wrap"
            rel="noreferrer"
            target="_blank"
            href={expandLink}
          >
            <Button
              data-testid={"expand-pane"}
              isClean
              isLarge
              isIcon="seem-icon-expand"
              type={`button`}
              title={t("Expand")}
            />
          </a>

          <Button
            data-testid={"close-pane"}
            isClean
            isLarge
            isIcon="seem-icon-close"
            type={`button`}
            title={t("Close")}
            onClick={() => closePane()}
          />
        </>
      )}
    </div>
  );
};

export default Actions;
