import "./Suggestions.scss";
import { escapeForRegExp } from "../TagUtils";

const markIt = (input, query) => {
  const regex = RegExp(escapeForRegExp(query), "gi");

  return {
    __html: input.replace(regex, "<mark>$&</mark>"),
  };
};

/**
 * @return {null}
 */
function Suggestions({
  query = "",
  suggestions,
  listboxId,
  selectedIndex,
  addTag,
  expandable,
}) {
  const handleMouseDown = (item, e) => {
    // focus is shifted on mouse down but calling preventDefault prevents this
    e.preventDefault();
    addTag(item);
  };

  if (!expandable || !suggestions.length) {
    return null;
  }

  return (
    <div className="tags-select__suggestions">
      <ul role="listbox" id={listboxId}>
        {suggestions.map((item, i) => {
          const key = `${listboxId}-${i}`;
          const classNamesArray = [];

          if (selectedIndex === i) {
            classNamesArray.push("is-active");
          }

          if (item.disabled) {
            classNamesArray.push("is-disabled");
          }

          return (
            <li
              id={key}
              key={key}
              role="option"
              className={classNamesArray.join(" ")}
              aria-disabled={item?.disabled === true}
              onMouseDown={handleMouseDown.bind(this, item)}
            >
              {item?.prefix || item?.suffix ? (
                <span className="suggestion-name">{`${
                  item?.prefix ? item.prefix : ""
                } ${item?.name} ${item?.suffix ? item?.suffix : ""}`}</span>
              ) : (
                <span
                  className="suggestion-name"
                  dangerouslySetInnerHTML={markIt(item?.name, query)}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Suggestions;
