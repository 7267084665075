import React from "react";
import { snakeCase } from "lodash";
import { normalizeNumbers } from "../../../../../../Tools/numbersNormalization";
import FilterInOut from "../../../../FilterInOut/FilterInOut";
import {
  findingsColumns,
  getSeverityValueByDescription,
} from "../../../../../FindingsUtils";

const SeverityNavigationItem = ({
  group,
  groupName,
  groupBy,
  onGroupClick,
  groupRow,
}) => {
  return (
    <div
      className={`findings-carousel-item group-by-${groupBy}`}
      onClick={() => onGroupClick(groupRow)}
    >
      <div
        className={`findings-carousel-item-top`}
        data-testid={"findings-count"}
      >
        {groupName && (
          <div
            className={`sevirity-level sevirity-level-${snakeCase(groupName)}`}
          />
        )}
        {normalizeNumbers(group.total_count)}
      </div>

      <div className={`findings-carousel-item-bottom`}>
        <div className={`findings-carousel-item-bottom-name`}>
          {`${groupName} Findings`}
        </div>
      </div>

      <FilterInOut
        field={findingsColumns.SEVERITY}
        value={getSeverityValueByDescription(groupName)}
      />
    </div>
  );
};

export default SeverityNavigationItem;
