import { capitalize } from "lodash";
import { daySuffix } from "../Tools/numbersNormalization";
import { aggregatedStatus } from "../Findings/FindingsUtils";

export const getSeverityColor = (type) => {
  switch (type) {
    case "low":
      return "#23bb8a";
    case "medium":
      return "#f7bb00";
    case "high":
      return "#ff7507";
    default:
      return "#ec2a3f";
  }
};

export const getFilterToExecute = (data, status = "OPEN") =>
  `{"operator": "and", "operands": [{"field": "actual_status", "condition": "", "value": {"field": "key", "condition": "in", "value": ${JSON.stringify(
    aggregatedStatus[status]
  )}}}, {"value": ["${data.value}"], "field": "${
    data.type
  }", "condition": "in"}]}`;

export const DASHBOARD_TYPES = {
  ORGANIZATIONAL: "organizational",
  TRENDS: "trends",
  PROGRAM: "program",
  SLA: "sla",
};

export const timeScales = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
};

export const getTitleFromTimescale = (timeScale) => {
  if (timeScale === timeScales.DAY) {
    return "today";
  }

  return capitalize(timeScale);
};

export const getFormatByTimescale = (timeScale, momentDate, index) => {
  switch (timeScale) {
    case timeScales.DAY:
    case timeScales.WEEK: {
      return momentDate.format("MMM DD, yyyy");
    }

    case timeScales.MONTH:
      return momentDate?.format("MMM, yyyy");

    case timeScales.QUARTER: {
      const quarter = momentDate?.quarter();
      return `${quarter}${daySuffix(quarter)} Quarter ${momentDate?.format(
        "yyyy"
      )} ${index === 0 ? "(Current)" : ""}`;
    }

    default:
      return "";
  }
};

export const timeScalesData = {
  DAY: { days: 1, momentShorthand: "d", count: 12 },
  WEEK: { days: 7, momentShorthand: "w", count: 12 },
  MONTH: { days: 30, momentShorthand: "M", count: 12 },
  QUARTER: { days: 90, momentShorthand: "Q", count: 3 },
};
