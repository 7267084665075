import React from "react";
import PropTypes from "prop-types";
import "./FormFieldError.scss";
import Popover from "react-popover";

const getErrorType = (errorType) => {
  switch (errorType) {
    case "info":
      return "info";

    default:
      return "alert";
  }
};
const FormFieldError = ({
  errorMessage,
  errorType,
  isOpen,
  fieldName = "",
}) => {
  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"right"}
      preferPlace={"row"}
      body={[
        errorType !== "filterError" ? (
          <div
            data-testid={`${fieldName}-error`}
            className={`error-message-tip ${getErrorType(errorType)}`}
          >
            <i className={`seem-icon seem-icon-${getErrorType(errorType)}`} />
            <div>{errorMessage}</div>
          </div>
        ) : null,
      ]}
    >
      <div
        key={`error-message-tip-holder`}
        className={`error-message-tip-holder`}
      />
    </Popover>
  );
};

FormFieldError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  errorType: PropTypes.string,
  isOpen: PropTypes.bool,
};

FormFieldError.defaultProps = {
  errorMessage: "",
  errorType: "alert",
  isOpen: true,
};

export default FormFieldError;
