import React, { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "../Components/Tabs/Tabs";
import { useHistory, useParams } from "react-router-dom";
import "./Remediation.scss";
import ProtectedRoute from "../AppRouter/ProtectedRoute";
import Workflows from "./Workflows/Workflows";
import RemediationQueuesList from "../RemediationQueues/RemediationQueuesList/RemediationQueuesList";
import Button from "../Forms/Controls/Button/Button";
import { ReactFlowProvider } from "react-flow-renderer";
import {
  AppPreferencesContext,
  userActionsEnum,
} from "../General/AppPreferencesProvider";

const Remediation = () => {
  const { t } = useTranslation();
  let history = useHistory();
  let { id } = useParams();
  const { isAllowed } = useContext(AppPreferencesContext);

  const menuItems = useMemo(() => {
    return [
      ...(isAllowed(userActionsEnum.VIEW_WORKFLOWS)
        ? [
            {
              pageLink: "workflows",
              title: "Workflows",
            },
          ]
        : []),

      {
        pageLink: "monitoring",
        title: "Monitoring",
      },
    ];
  }, [isAllowed]);

  useEffect(() => {
    if (!id || (!!id && !menuItems.find((el) => el.pageLink === id))) {
      history.push(`/remediation/${menuItems[0].pageLink}`);
    }
  }, []);

  const onSelectTab = (item) => {
    history.push(`/remediation/${item.pageLink}`);
  };

  return (
    <div className={`remediation-queues-wrapper`}>
      <div className={`remediation-tabs-line`} />
      <div className={`rmq-title`}>{t("Remediation Queues")}</div>
      <div className={`workflows-header`}>
        <Tabs
          menuItems={menuItems}
          onSelectTab={onSelectTab}
          activeId={id}
          displayUnderline={false}
        />

        <Button
          data-testid={"create-rmq"}
          isSecondary
          label={t("+ New Queue")}
          isXl
          onClick={() => history.push("/remediationQueue")}
        />
      </div>
      <div className={`rmq-data-container`}>
        {isAllowed(userActionsEnum.VIEW_WORKFLOWS) && (
          <ProtectedRoute path={`/remediation/workflows`}>
            <ReactFlowProvider>
              <Workflows />
            </ReactFlowProvider>
          </ProtectedRoute>
        )}
        <ProtectedRoute path={`/remediation/monitoring`}>
          <RemediationQueuesList />
        </ProtectedRoute>
      </div>
    </div>
  );
};

export default Remediation;
