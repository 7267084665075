import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import ProtectedRoute from "./ProtectedRoute";
import App from "../App";
import AuthPage from "../Auth/AuthPage";
import AuthSAML from "../Auth/AuthSAML/AuthSAML";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../Components/ErrorFallback/ErrorFallback";
import Logo from "../icons/logos/logo-color.png";
import AppPreferencesProvider from "../General/AppPreferencesProvider";

const AppRouter = () => {
  return (
    <Router>
      <ErrorBoundary
        fallbackRender={(props) => (
          <div
            style={{
              width: "200px",
              margin: "60px auto 0 auto",
            }}
          >
            <a href={"/"} target="_self">
              <div
                style={{
                  backgroundImage: `url(${Logo})`,
                  width: "200px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </a>
            <ErrorFallback {...props} type={"static"} />
          </div>
        )}
      >
        <Switch>
          <Route exact path="/login">
            <AuthPage />
          </Route>
          <Route path="/saml/:provider?">
            <AuthSAML />
          </Route>
          <Route path="/changePassword">
            <AuthPage />
          </Route>
          <Route path="/mfa">
            <AuthPage />
          </Route>
          <Route path="/logout">
            <AuthPage />
          </Route>
          <ProtectedRoute path="/remediationQueue/:queueid?/:id?">
            <AppPreferencesProvider>
              <App />
            </AppPreferencesProvider>
          </ProtectedRoute>
          <ProtectedRoute path="/:item/:id?">
            <AppPreferencesProvider>
              <App />
            </AppPreferencesProvider>
          </ProtectedRoute>
          <Route path="/">
            <AuthPage />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
