import React from "react";
import "./Tag.scss";

const Tag = ({ tag, onDelete }) => {
  return (
    <div title={tag.name} className={`tag-wrapper`}>
      <span className={`tag-name`}>{tag.name}</span>
      <i className={`seem-icon seem-icon-close`} onClick={onDelete} />
    </div>
  );
};

export default Tag;
