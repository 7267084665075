import React, { useEffect, useState } from "react";
import "../SAMLSettings";
import "./SAMLSetup.scss";
import { useForm, Controller, useWatch } from "react-hook-form";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import RadioButtonsList from "../../../Forms/Controls/RadioButtonsList/RadioButtonsList";
import { useTranslation } from "react-i18next";
import UploadFile from "../../../Forms/Controls/UploadFile/UploadFile";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LOGIN_URL, UPDATE_SAML } from "../SAMLSettingsApi";
import { showErrorDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import ReactTooltip from "react-tooltip";
import useChangeTooltipMsg from "../../../Components/Tooltip/useChangeTooltipMsg";

const SETUP_OPTIONS_ENUM = {
  DOC: "DOC",
  URL: "URL",
};

const SAMLSetup = () => {
  const { t } = useTranslation();

  const SAML_SETUP_OPTIONS = {
    DOC: {
      label: t("Upload metadata document"),
      name: SETUP_OPTIONS_ENUM.DOC,
      value: SETUP_OPTIONS_ENUM.DOC,
    },
    URL: {
      label: t("Enter URL"),
      name: SETUP_OPTIONS_ENUM.URL,
      value: SETUP_OPTIONS_ENUM.URL,
    },
  };
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = methods;
  const selectedSetupOption = useWatch({
    control,
    name: `samlSetupOptions`,
    defaultValue: SETUP_OPTIONS_ENUM.URL,
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [loginURL, setLoginURL] = useState(false);
  const { copyMsg, setCopyMsg } = useChangeTooltipMsg("Copy URL", "Copied!");
  const [updateSAML] = useMutation(UPDATE_SAML);
  const { data: configedLoginURL } = useQuery(GET_LOGIN_URL);

  let fileReader;

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    if (selectedSetupOption === SETUP_OPTIONS_ENUM.URL) {
      applyUpdateSAML({ url: data.credentials });
    } else {
      fileReader = new FileReader();
      fileReader.onloadend = onFileRead;
      fileReader.readAsText(data.credentials);
    }
  };

  const onFileRead = () => {
    applyUpdateSAML({ file: fileReader.result });
  };

  const applyUpdateSAML = (credentials) => {
    updateSAML({ variables: { ...credentials, enabled: true } })
      .then((res) => {
        setIsSubmittingForm(false);

        if (!!res?.data?.update_saml?.ok) {
          setLoginURL(res?.data?.update_saml?.saml_url);
        } else {
          showErrorDialog({ message: res.data?.update_saml?.msg });
        }
      })
      .catch((err) => {
        setIsSubmittingForm(false);
        showErrorDialog({ message: err.message });
      });
  };

  useEffect(() => {
    if (!!configedLoginURL) {
      setLoginURL(configedLoginURL?.settings?.edges?.[0]?.node?.value);
    }
  }, [configedLoginURL]);

  return (
    <div className="saml-setup-wrap">
      <div className={"sub-title"}>Setup</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          defaultValue={SETUP_OPTIONS_ENUM.URL}
          name={"samlSetupOptions"}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <RadioButtonsList
                wrapperClassName={"saml-options"}
                options={Object.values(SAML_SETUP_OPTIONS)}
                name={`samlSetupOptions`}
                label={""}
                onBlur={onBlur}
                onChange={(val) => {
                  reset({ samlSetupOptions: val });
                  onChange(val);
                }}
                value={value}
                disabled={isSubmittingForm || loginURL}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
          }}
        />
        <Controller
          defaultValue={""}
          name={"credentials"}
          render={({ field: { value, onChange, onBlur, ref } }) => {
            return selectedSetupOption === SETUP_OPTIONS_ENUM.URL ? (
              <TextInput
                placeholder={t("Type your metadata document URL")}
                labelTop
                value={value}
                onBlur={onBlur}
                inputRef={ref}
                disabled={isSubmittingForm || loginURL}
                name={"credentials"}
                onChange={(e) => {
                  onChange(e);
                }}
                size="xl"
                inputStyle={"bomba-style"}
                error={errors.credentials ? errors.credentials : null}
              />
            ) : (
              <UploadFile
                accept={"text/xml"}
                disabled={isSubmittingForm || loginURL}
                data-testid={"url-input"}
                wrapperClassName={"file-uploader"}
                onChange={(e) => onChange(e)}
              />
            );
          }}
          control={control}
          rules={{
            required: "Required",
            validate: (value) => {
              if (
                selectedSetupOption === SETUP_OPTIONS_ENUM.URL &&
                !value.includes(".")
              ) {
                return "URL is Invalid";
              }
              return true;
            },
          }}
        />
        <ModalButtonsContainer>
          <Button
            wrapperClassName={"submit-saml"}
            type="submit"
            label={"Submit"}
            data-testid={"submit-saml"}
            isSecondary
            rightIcon={isSubmittingForm ? "seem-icon-spinner spinner" : ""}
            isSubmitting={isSubmittingForm}
            disabled={!isValid || !isDirty || isSubmittingForm || loginURL}
          />
        </ModalButtonsContainer>
      </form>
      {loginURL ? (
        <div className={"login-url-wrapper"}>
          <div className={"sub-title"}>Your Login URL</div>
          <div className={"url-description"}>
            {t(
              "Please use the following URL as your login URL.\n" +
                "You can also create a bookmark app or login URL in your SAML provider configuration.\n" +
                "Seemplicity logo can be downloaded from this page and be used in the SAML app."
            )}
          </div>
          <div className={"login-url"}>
            {loginURL}
            <span
              className={"copy"}
              onClick={() => {
                setCopyMsg("Copied!");
                navigator.clipboard.writeText(loginURL);
              }}
            >
              <i
                data-tip
                data-for="copyUrlTip"
                className="seem-icon seem-icon-link scope-filter-copy"
              />
              <ReactTooltip
                id="copyUrlTip"
                place="top"
                className={"copy-tooltip"}
              >
                {copyMsg}
              </ReactTooltip>
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default SAMLSetup;
