import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./useSections.scss";
import { fieldTypeOptions } from "../GenericField/GenericField";
import {
  getDefaultDueDateBySeverity,
  FieldMappingFieldsTemplate,
} from "../TicketingUtils";
import Loader from "../../Components/Loader/Loader";
import ReactTooltip from "react-tooltip";

const useSections = (
  fieldSections,
  error = null,
  onGetProps = () => {},
  getField = () => {},
  findingsSeverity = null,
  loader = null,
  noDataLoader = null,
  showFieldsToUse = false
) => {
  const [sections, setSections] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});

  const getDefaultValue = (field) => {
    if (field?.input === fieldTypeOptions.DYNAMIC_MAPPING) {
      let defaultMapping = [];
      field?.mapping?.rows?.forEach((field) => {
        if (!!field?.field_from?.default_value) {
          defaultMapping.push({
            field: field?.field_from?.name,
            value: getDefaultValue(field?.field_from),
          });
        }
      });
      if (!defaultMapping.length) {
        defaultMapping.push({ field: null, value: null });
      }
      return defaultMapping;
    } else if (field?.name === "duedate") {
      return getDefaultDueDateBySeverity(findingsSeverity);
    } else {
      try {
        return JSON.parse(field?.default_value).value;
      } catch (e) {
        return field?.default_value;
      }
    }
  };

  const getFieldData = (data) => ({
    label: data?.display_name,
    input: data?.field_input_type,
    required: data?.required,
    name: data?.field_name,
    isSkeleton: data?.skeleton,
    size: data?.field_input_size,
    icon: data?.icon,
    creatable: data?.options?.is_creatable,
    options: data?.options?.values,
    query: {
      ...(data?.query_data && {
        params: !!data?.query_data && JSON.parse(data?.query_data)?.params,
      }),
    },
  });

  const getFieldPropsByFieldName = useCallback(
    (name) => {
      let target;
      sections?.some((section) =>
        section.rows?.some((row) =>
          row.fields?.some((field) => {
            if (field?.name === name) {
              target = field;
              return true;
            }

            field?.mapping?.rows?.some((field) => {
              if (field?.field_from?.name === name) {
                target = field?.field_to;
                return true;
              } else {
                return false;
              }
            });

            return false;
          })
        )
      );

      return target;
    },
    [sections]
  );

  useEffect(() => {
    if (!!getFieldPropsByFieldName) {
      onGetProps(() => getFieldPropsByFieldName);
    }
  }, [getFieldPropsByFieldName]);

  useEffect(() => {
    if (!!fieldSections && fieldSections?.length) {
      let defaultValuesObject = {};

      const sectionsArray = fieldSections?.reduce((sectionsAcc, section) => {
        const rows = section?.rows?.reduce((rowsAcc, row) => {
          const fields = row?.fields?.reduce((fieldsAcc, field) => {
            const fieldObject = {
              ...getFieldData(field),
              descendants: field?.fieldDescendants,
              precedents: field?.fieldPrecedents,
              mapping: {
                ...(field?.mapping_data && {
                  columns: {
                    field_from: getFieldData(
                      field?.mapping_data?.columns?.field_from
                    ),
                    field_to: getFieldData(
                      field?.mapping_data?.columns?.field_to
                    ),
                  },
                  rows: field?.mapping_data?.rows?.map((row) => {
                    return {
                      field_from: {
                        ...getFieldData(row?.field_from),
                        default_value: row?.field_from?.default_value,
                      },
                      field_to: getFieldData(row?.field_to),
                    };
                  }),
                }),
              },
            };

            fieldObject.defaultValue = getDefaultValue({
              ...field,
              ...fieldObject,
            });
            defaultValuesObject[fieldObject?.name] = fieldObject?.defaultValue;

            fieldsAcc.push(fieldObject);

            return fieldsAcc;
          }, []);

          rowsAcc.push({ fields });
          return rowsAcc;
        }, []);

        sectionsAcc.push({ rows });
        return sectionsAcc;
      }, []);

      setDefaultValues(defaultValuesObject);
      setSections(sectionsArray);
    } else if (!!fieldSections && !fieldSections?.length) {
      setSections([]);
      setDefaultValues({});
    } else {
      setDefaultValues({});
      setSections(null);
    }
  }, [fieldSections]);

  const getRow = (row, key) => {
    return row?.fields?.map((field, index) => {
      return (
        <div
          className={`section-field section-field-${field?.size?.toLowerCase()}`}
          key={`${key}-field-${index}`}
        >
          {getField(field)}
        </div>
      );
    });
  };

  const getSection = (section, key) => {
    return section?.rows?.map((row, index) => {
      return (
        <div key={`${key}-row-${index}`}>
          <div className={"section-row"}>
            {getRow(row, `${key}-row-${index}`)}
          </div>
          {index + 1 === section?.rows?.length && (
            <div className={"sections-separator"} />
          )}
        </div>
      );
    });
  };

  const fieldsToUse = useMemo(
    () =>
      Object.keys(FieldMappingFieldsTemplate)
        .map((key) => FieldMappingFieldsTemplate[key])
        .join(", "),
    []
  );

  const getSections = () => {
    return !!sections && sections.length ? (
      <>
        {showFieldsToUse && (
          <>
            <div
              className={"endpoint-list-info"}
              data-tip
              data-for="fieldToUse"
            >
              <i className={"seem-icon seem-icon-help"} />
              {"Which fields can I use?"}
            </div>
            <ReactTooltip id="fieldToUse" place="top">
              {fieldsToUse}
            </ReactTooltip>
          </>
        )}
        <div className={"sections"}>
          {sections?.map((section, index) => (
            <div className={"section"} key={`section-${index}`}>
              {getSection(section, `section-${index}`)}
            </div>
          ))}
        </div>
      </>
    ) : !!sections && !sections.length ? (
      <div className={"sections-loader"}>{noDataLoader}</div>
    ) : error ? (
      <div className={"sections-loader error"}>
        <Loader center text="An error occurred" />
      </div>
    ) : (
      <div className={"sections-loader"}>{loader}</div>
    );
  };

  return {
    getSections,
    getFieldPropsByFieldName,
    sections,
    defaultValues,
    setDefaultValues,
  };
};

export default useSections;
