import React, { useEffect, useCallback, useState, createContext } from "react";
import Sidebar from "../Sidebar/Sidebar";
import SidebarDisplayArea from "../SidebarDisplayArea/SidebarDisplayArea";
import "./SidebarLayout.scss";
import ProtectedRoute from "../../AppRouter/ProtectedRoute";
import { useHistory, useParams } from "react-router-dom";

export const SidebarLayoutStateContext = createContext({
  changeDisplayAreaTitle: () => {},
});

const SidebarLayout = ({ menu }) => {
  const { groupPath, itemPath } = useParams();
  const history = useHistory();
  const [customTitle, setCustomTitle] = useState(null);

  const getCurrentItemTitle = useCallback(
    (groupRef, itemRef) => {
      const currGroup = menu?.items.find(
        (group) => group.reference === groupRef
      );
      const currItem = currGroup?.items.find(
        (item) => item.reference === itemRef
      );

      if (!!customTitle) {
        return (
          <div>
            <span>{currItem?.title}</span> <span>{customTitle}</span>
          </div>
        );
      }
      return currItem?.title;
    },
    [customTitle, menu?.items]
  );

  const getFirstGroupItemRef = (groupRef) => {
    const currGroup = menu?.items.find((group) => group.reference === groupRef);

    return currGroup.items[0].reference;
  };

  useEffect(() => {
    if (!groupPath && !itemPath) {
      history.push(
        `/settings/${menu?.items[0].reference}/${menu?.items[0].items[0].reference}`
      );
    } else if (groupPath && !itemPath) {
      history.push(`/settings/${groupPath}/${getFirstGroupItemRef(groupPath)}`);
    }
  }, [groupPath, itemPath]);

  return (
    !!menu && (
      <SidebarLayoutStateContext.Provider
        value={{ changeDisplayAreaTitle: setCustomTitle }}
      >
        <div className={"sidebar-layout-wrapper"} id={"sidebar-layout"}>
          <Sidebar data={menu} />
          <div className={"sidebar-layout-main"}>
            <SidebarDisplayArea
              title={getCurrentItemTitle(groupPath, itemPath)}
            >
              {menu?.items.map((group) => {
                return [
                  ...group.items.map((item) => (
                    <ProtectedRoute
                      key={item.reference}
                      path={`/settings/${group.reference}/${item.reference}`}
                    >
                      {item.component}
                    </ProtectedRoute>
                  )),
                ];
              })}
            </SidebarDisplayArea>
          </div>
        </div>
      </SidebarLayoutStateContext.Provider>
    )
  );
};

export default SidebarLayout;
