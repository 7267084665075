import SeemaOK from "../../../../../Forms/Dialogs/Toast/SeemaOK";
import ProgressBar from "../../../../../Components/ProgressBar/ProgressBar";
import Button from "../../../../../Forms/Controls/Button/Button";
import React, { useEffect, useState, useMemo } from "react";
import useWebSocketMessageHandler from "../../../../../WebSocket/useWebSocketMessageHandler";
import "./AsyncProgressMessage.scss";

const AsyncProgressMessage = ({
  description,
  taskName,
  lsItem,
  isClosing,
  setIsClosing,
  buttons = [],
  useDisabled = false,
  closeButtonProps = {},
}) => {
  const { wsMessage } = useWebSocketMessageHandler({
    messageTypes: [taskName],
  });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (
      !!wsMessage &&
      wsMessage?.task_name === taskName &&
      localStorage.getItem(lsItem) === wsMessage?.task_id
    ) {
      setProgress(wsMessage?.progress_percent);
    }
  }, [wsMessage]);

  const isDisabled = useMemo(() => {
    return progress !== 100 || isClosing;
  }, [progress, isClosing]);

  return (
    <div className={"async-progress-msg"}>
      <SeemaOK isLarge />
      <div className={`async-progress-description`}>{description}</div>
      <ProgressBar percent={progress} />
      <div className={`async-progress-indication`}>{`${progress}% Done`}</div>
      <div className={`form-button-group`}>
        <Button
          data-testid={`async-progress-close`}
          label={"Close"}
          rightIcon={isClosing ? "seem-icon-spinner spinner" : ""}
          onClick={() => {
            setIsClosing(true);
          }}
          disabled={isDisabled}
          {...closeButtonProps}
        />
        {buttons?.map((btn, index) => {
          const props = {
            ...btn,
            onClick: () => {
              if (!!btn?.onClick) {
                btn?.onClick();
              }
            },
            disabled: useDisabled ? isDisabled : false,
          };
          return (
            <Button
              data-testid={btn?.["data-testid"]}
              key={`dialog-btn-${index}`}
              {...props}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AsyncProgressMessage;
