import React, { useContext, useState, useMemo } from "react";
import moment from "moment";
import ProfileScopesList from "./ProfileScopesList/ProfileScopesList";
import Popover from "react-popover";
import Button from "../../../../Forms/Controls/Button/Button";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import "./ProfilesItem.scss";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";
import ProfileActions from "./ProfileActions/ProfileActions";
import {
  showDialog,
  showErrorDialog,
} from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { DELETE_PROFILE } from "../../PermissionsApi";
import { useMutation } from "@apollo/client";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import ShowMoreInlineList from "../../../../Components/ShowMoreInlineList/ShowMoreInlineList";

const MAX_SCOPES = 3;
const MAX_USERS = 3;

const ProfilesItem = ({ profile }) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useContext(ModalContext);
  const [deleteProfile] = useMutation(DELETE_PROFILE);
  const [isScopesPopoverOpen, toggleIsScopesPopoverOpen] = useState(false);

  const hasScopesOrGroups = useMemo(() => {
    if (
      !isNaN(profile.scopes?.length) &&
      !isNaN(profile.scopes_groups?.length)
    ) {
      return profile.scopes.length + profile.scopes_groups.length;
    } else if (!isNaN(profile.scopes?.length)) {
      return profile.scopes.length;
    } else if (!isNaN(profile.scopes_groups?.length)) {
      return profile.scopes_groups.length;
    }

    return false;
  }, [profile.scopes?.length, profile.scopes_groups?.length]);

  const handleDeletion = (id) => {
    deleteProfile({
      variables: { profile_id: profile.id },
      update(cache, { data }) {
        if (data?.delete_profile?.ok) {
          cache.modify({
            fields: {
              profiles(existingProfiles, { readField }) {
                return {
                  edges: [
                    ...existingProfiles.edges.filter(
                      (profile) => id !== readField("id", profile)
                    ),
                  ],
                };
              },
            },
          });
          cache.evict({ id: `Profile:${profile.id}` });
          cache.gc();
        }
      },
    })
      .then((res) => {
        if (res.data?.delete_profile?.ok) {
          showToast({ message: "Profile Deleted Successfully" });
        } else {
          showErrorDialog({ message: "Profile Could Not Be Deleted." });
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
      });
  };

  return (
    <div className={"permissions-list-item-wrapper"}>
      <div className={"permissions-list-cell profile-details"}>
        <div className={"details-name"}>{profile.name}</div>
        {profile.last_updated ? (
          <div
            className={"details-update"}
            data-testid={"profile-last-updated"}
          >{`Updated ${moment(profile.last_updated)?.format(
            "MMM Do YYYY, hh:mm a"
          )}`}</div>
        ) : (
          <div
            className={"details-update no-data"}
            data-testid={"profile-last-updated"}
          >
            N/A
          </div>
        )}
      </div>
      <div className={"permissions-list-cell profile-scopes"}>
        <div className={"profile-scopes-title"}>Scope</div>
        <div className={"profile-scopes-list"} data-testid={"profile-scopes"}>
          {hasScopesOrGroups ? (
            <>
              <ProfileScopesList
                scopes={profile.scopes}
                scopesGroups={profile.scopes_groups}
                max={MAX_SCOPES}
              />
              {hasScopesOrGroups > MAX_SCOPES && (
                <Popover
                  isOpen={isScopesPopoverOpen}
                  tipSize={0.01}
                  place={"below"}
                  onOuterAction={() => toggleIsScopesPopoverOpen(false)}
                  body={[
                    <div
                      key={"profile-scopes-popover"}
                      className={`popover-container scopes-popover-container`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ProfileScopesList
                        scopes={profile.scopes}
                        scopesGroups={profile.scopes_groups}
                      />
                    </div>,
                  ]}
                >
                  <div
                    data-testid={"show-more-profile-scopes"}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleIsScopesPopoverOpen(!isScopesPopoverOpen);
                    }}
                  >
                    <i className={`seem-icon seem-icon-more-actions`} />
                  </div>
                </Popover>
              )}
            </>
          ) : (
            <div className={"no-data"}>No Data</div>
          )}
        </div>
      </div>
      <div className={"permissions-list-cell profile-users-and-actions"}>
        <div className={"users"}>
          <div className={"users-title"}>Users</div>
          <div className={"users-list"} data-testid={"profile-users"}>
            {profile.users?.length ? (
              <ShowMoreInlineList
                list={profile.users.map((user) => {
                  return {
                    id: user.id,
                    name: !!(user.first_name || user.last_name)
                      ? `${user.first_name} ${user.last_name}`
                      : user.email,
                  };
                })}
                withListItemAvatar
                max={MAX_USERS}
                id={profile.id}
              />
            ) : (
              <div className={"no-data"}>No Data</div>
            )}
          </div>
        </div>
        <div className={"actions"}>
          <Button
            data-testid={"edit-profile"}
            isClean
            tooltipData={{
              dataFor: `edit-profile-btn-${profile.id}`,
              dataTip: true,
            }}
            isIcon="seem-icon-edit"
            type={`button`}
            onClick={() =>
              openModal({
                title: "Edit Profile",
                size: "s",
                overlay: true,
                component: (
                  <ProfileActions
                    mode={"edit"}
                    data={{
                      scopes: profile.scopes,
                      scopes_groups: profile.scopes_groups,
                      users: profile.users,
                      name: profile.name,
                      id: profile.id,
                    }}
                  />
                ),
              })
            }
            title={t("Edit")}
          />
          <ReactTooltip id={`edit-profile-btn-${profile.id}`} place="top">
            Edit Profile
          </ReactTooltip>
          <Button
            data-testid={"delete-profile"}
            isClean
            tooltipData={{
              dataFor: `delete-profile-btn-${profile.id}`,
              dataTip: true,
            }}
            isIcon="seem-icon-bin"
            type={`button`}
            onClick={() => {
              if (!profile.users.length) {
                showDialog({
                  title: "Delete Profile",
                  message: `Are you sure you want to delete this profile?`,
                  buttons: [
                    {
                      "data-testid": "cancel-delete",
                      label: "Cancel",
                    },
                    {
                      "data-testid": "confirm-delete",
                      isSecondary: true,
                      label: "Delete",
                      type: "submit",
                      onClick: () => handleDeletion(profile.id),
                    },
                  ],
                });
              } else {
                openModal({
                  title: "Delete Profile",
                  size: "xs",
                  overlay: true,
                  component: (
                    <div className={"delete-profile"}>
                      <div className={"delete-profile-users-description"}>
                        {t(`The following users are connected to this profile,
                              please change their profile and try to delete this
                              profile again.`)}
                      </div>
                      <div className={"delete-profile-users-title"}>
                        Connected users:
                      </div>
                      <div className={"delete-profile-connected-users"}>
                        <ShowMoreInlineList
                          list={profile.users.map((user) => {
                            return {
                              id: user.id,
                              name: !!(user.first_name || user.last_name)
                                ? `${user.first_name} ${user.last_name}`
                                : user.email,
                            };
                          })}
                          full
                          withListItemAvatar
                        />
                      </div>
                      <Button
                        onClick={() => closeModal()}
                        wrapperClassName={"delete-profile-close"}
                        data-testid={"delete-profile"}
                        label={"Close"}
                      />
                    </div>
                  ),
                });
              }
            }}
            title={t("Delete")}
          />
          <ReactTooltip id={`delete-profile-btn-${profile.id}`} place="top">
            Delete Profile
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default ProfilesItem;
