import React, { useContext, useEffect, useState } from "react";
import { RemediationQueueContext } from "../../RemediationQueueProvider";
import {
  modeOptions,
  queuePriorities,
  queueStates,
} from "../../../RemediationQueuesUtils";
import {
  ticketEndpointTypeEnum,
  ticketManagersEnum,
} from "../../../../Ticketing/TicketingUtils";
import "./QueueSettings.scss";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import TextInput from "../../../../Forms/Controls/TextInput/TextInput";
import Button from "../../../../Forms/Controls/Button/Button";
import { useTranslation } from "react-i18next";
import { useApolloClient, useMutation } from "@apollo/client";
import OutputPreferences from "./OutputPreferences/OutputPreferences";
import { useHistory, useLocation } from "react-router-dom";
import {
  CREATE_REMEDIATION_QUEUE,
  UPDATE_REMEDIATION_QUEUE,
  SYNC_QUEUE,
} from "./QueueSettingsApi";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { ScopesContext } from "../../../../AppView/ScopeManagement/ScopeProvider/ScopeProvider";
import useScope, {
  ScopeManagementContext,
} from "../../../../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/useScope";
import ScopeManagement from "../../../../AppView/ScopeManagement/ScopeManagement";
import FilterManagement from "../../../../AppView/FilterManagement/FilterManagement";
import { FiltersContext } from "../../../../AppView/FilterManagement/FilterProvider/FilterProvider";
import { AppPreferencesContext } from "../../../../General/AppPreferencesProvider";
import { EMPTY_FILTER } from "../../../../AppView/FilterManagement/FiltersMenu/FiltersMenu";
import useTicketEndpointMutationData from "../../../../Ticketing/useTicketEndpointMutationData/useTicketEndpointMutationData";
import { filterTypes } from "../../../../Findings/FindingsUtils";
import queryString from "query-string";
import { isEmpty } from "lodash";
import SLACustomization from "../../../../AppSettings/SLACustomization/SLACustomization";
import AlertIndicator from "../../../../Components/AlertIndicator/AlertIndicator";
import { ModalContext } from "../../../../Forms/Dialogs/Modal/ModalContext";

const QueueSettings = () => {
  const {
    remediationQueueData: {
      title,
      finding_filter,
      scope_id,
      filter_id,
      queueSize,
      id,
      cron,
      state,
    },
    updateMode,
    mode,
    updateRemediationQueueData,
    hasGeneralPrioritizeSetting,
  } = useContext(RemediationQueueContext);

  const { filtersState, updateFiltersState, getFilterValueById } =
    useContext(FiltersContext);
  const { scopesState, getScopeValueById, updateScopeState } =
    useContext(ScopesContext);
  const { scopesList, selectedScope } = scopesState;
  const { filtersList, selectedFilter, isEditFilter } = filtersState;
  const location = useLocation();
  const { search } = location;
  const [filterData, setFilterData] = useState(null);
  const client = useApolloClient();
  const [filterChange, setFilterChange] = useState(false);

  useEffect(() => {
    if (!!finding_filter && !isEditFilter) {
      let selectedFilter = getFilterValueById(finding_filter?.id);
      if (!selectedFilter) {
        selectedFilter = {
          label: finding_filter?.name,
          value: {
            id: finding_filter?.id,
            query: finding_filter?.filter_data,
            editable: false,
          },
        };
      }
      updateFiltersState({ selectedFilter });
    }
  }, [filtersList, finding_filter, search, isEditFilter]);

  useEffect(() => {
    if (!!scope_id) {
      const selectedScope = getScopeValueById(scope_id);
      updateScopeState({ selectedScope });
    }
  }, [scopesList, scope_id, search]);

  useEffect(() => {
    if (!!search) {
      const queryStringObj = queryString.parse(search);
      const filterObj = {};
      filterObj.filterToExecute = queryStringObj?.filter_data;
      filterObj.selectedFilter = {
        label: queryStringObj?.name,
        value: {
          editable: false,
          query: queryStringObj?.filter_data,
        },
      };
      const selectedScope = getScopeValueById(queryStringObj.scope_id);
      updateScopeState({ selectedScope });

      setFilterData({
        name: queryStringObj?.name,
        filter_data: queryStringObj?.filter_data,
      });
      updateFiltersState(filterObj);
    }
  }, [search, scopesList]);

  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);

  const { t } = useTranslation();

  const methods = useForm({ mode: "all" });

  const { control, formState, getValues, reset: formReset } = methods;
  const { isValid, isDirty, errors } = formState;
  const selectedTicketManager = useWatch({ control, name: "ticketManager" });
  const history = useHistory();
  const { isOpen, toggleIsOpen } = useScope();
  const {
    setGetPropsFromField: setGetPropsFromKeyField,
    getMutationArray: getKeysMutationArray,
  } = useTicketEndpointMutationData();
  const { setGetPropsFromField, getMutationArray: getFieldsMutationArray } =
    useTicketEndpointMutationData();
  const {
    setGetPropsFromField: setGetPropsFromTicketManager,
    getPropsFromField: getTicketManagerProps,
  } = useTicketEndpointMutationData();
  const [createRemediationQueue] = useMutation(CREATE_REMEDIATION_QUEUE);
  const [updateRemediationQueue] = useMutation(UPDATE_REMEDIATION_QUEUE, {
    refetchQueries: ["GetRemediationQueue"],
  });
  const [queueState, setQueueState] = useState(queueStates.ENABLED);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [syncQueue] = useMutation(SYNC_QUEUE);
  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (mode === modeOptions.EDIT) {
      formReset({
        ...getValues(),
        queueSize,
        schedule: cron,
        name: title,
      });
    }
  }, [mode]);

  const createAndSync = (id, provider) => {
    syncQueue({ variables: { remediation_queue_id: id } })
      .then(() => {
        showToast({ message: "Remediation Queue Created and Synced!" });
        history.push(`/remediationQueue/${id}`);
      })
      .catch(() => {
        showToast({
          message: `You’ve successfully created remediation queue, but there seems to be an issue with ${provider} syncing. Please try again later!`,
        });
        history.push(`/remediationQueue/${id}`);
      })
      .finally(() => setIsSubmittingForm(false));
  };

  const onSubmit = (formData) => {
    setIsSubmittingForm(true);
    let rmqData = {
      title: formData.name,
      state: queueState,
      description: formData.name,
      max_concurrent_opened_tickets: formData?.queueSize,
      cron: formData?.schedule,
      finding_filter: null,
      filters_config: {
        filtersid: selectedFilter?.value?.id,
        scopesid: selectedScope?.value?.id,
      },
    };

    if (mode === modeOptions.CREATE) {
      rmqData = {
        ...rmqData,
        ticket_provider_id: formData?.ticketProvider,
      };

      rmqData.value_mapping = JSON.stringify(
        getKeysMutationArray(formData?.keys)
          .concat(getFieldsMutationArray(formData?.fields))
          ?.reduce((acc, obj) => ({ ...acc, [obj.key]: obj.value }), {})
      );

      if (!rmqData?.filters_config?.filtersid) {
        filterData.name = `${filterData.name}#_${Date.now()}`;
        rmqData.finding_filter = {
          ...filterData,
          filter_type: filterTypes.FILTER,
        };
      }

      if (
        getTicketManagerProps(selectedTicketManager)?.type ===
        ticketEndpointTypeEnum.INTERNAL
      ) {
        rmqData.endpoint_type = ticketEndpointTypeEnum.INTERNAL;
        rmqData.max_concurrent_opened_tickets = 0;
        rmqData.ticket_provider_id = getTicketManagerProps(
          selectedTicketManager
        )?.providers[0].id;
      }

      createRemediationQueue({
        variables: rmqData,
      })
        .then((res) => {
          history.push(
            `/remediationQueue/${res.data.create_remediation_queue.remediation_queue.id}`
          );
          if (
            queueState === queueStates.ENABLED &&
            getTicketManagerProps(selectedTicketManager)?.type !==
              ticketEndpointTypeEnum.INTERNAL
          ) {
            createAndSync(
              res.data.create_remediation_queue.remediation_queue.id,
              getTicketManagerProps(selectedTicketManager)?.friendlyName
            );
          } else {
            showToast({ message: "Remediation Queue Created!" });
            history.push(
              `/remediationQueue/${res.data.create_remediation_queue.remediation_queue.id}`
            );
          }
        })
        .catch((err) => showErrorDialog({ message: err.message }))
        .finally(() => setIsSubmittingForm(false));
    } else if (mode === modeOptions.EDIT) {
      rmqData = {
        ...rmqData,
        id,
      };

      if (
        !isEmpty(formData?.fields) &&
        getTicketManagerProps(selectedTicketManager).showFieldsMapping
      ) {
        rmqData.value_mapping = JSON.stringify(
          getKeysMutationArray(formData?.keys)
            .concat(getFieldsMutationArray(formData?.fields))
            ?.reduce((acc, obj) => ({ ...acc, [obj.key]: obj.value }), {})
        );
      }

      updateRemediationQueue({
        variables: rmqData,
      })
        .then((res) => {
          if (res?.data?.update_remediation_queue?.ok) {
            updateMode(modeOptions.READ);
            updateRemediationQueueData({
              ...rmqData,
              queueSize: rmqData.max_concurrent_opened_tickets,
            });
            showToast({ message: "Remediation Queue Updated!" });
          } else {
            showErrorDialog({ message: "Remediation queue update failed" });
          }
        })
        .catch((err) => showErrorDialog({ message: err.message }))
        .finally(() => setIsSubmittingForm(false));
    }
  };

  const handleFilterSave = (savedFilter) => {
    updateRemediationQueueData({
      finding_filter: {
        name: savedFilter?.label,
        id: savedFilter?.value?.id,
        filter_data: savedFilter?.value?.query,
      },
    });
    setFilterChange(true);
  };

  return (
    <div className={"remediation-queue-container rmq-settings-wrapper"}>
      <FormProvider {...methods}>
        <form
          className="filters-form-wrapper filters-form-wrapper-FormProvider"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            render={({ field: { onChange, value, onBlur, ref } }) => (
              <TextInput
                data-testid={"rmq-name"}
                className={`rmq-name-input`}
                size="m"
                inputStyle={"bomba-style"}
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                inputRef={ref}
                placeholder={"Remediation Queue Name"}
                value={value}
                error={errors && errors.name ? errors.name : null}
                defaultValue={"Name"}
                label={"Name"}
                disabled={isEditFilter}
              />
            )}
            control={control}
            rules={{ required: "Required" }}
          />
          {slaEnabled &&
            mode === modeOptions.CREATE &&
            hasGeneralPrioritizeSetting?.value === queuePriorities.SLA && (
              <AlertIndicator
                wrapperClassName={"rmq-settings-prioritize-wrapper"}
                content={
                  "This queue sending order is based on the finding SLA."
                }
                additionalContent={
                  <Button
                    type={"button"}
                    label={"Customize SLA"}
                    className={"rmq-customize-sla"}
                    textLink
                    onClick={() =>
                      openModal({
                        title: "SLA Customization",
                        size: "s",
                        overlay: true,
                        component: <SLACustomization />,
                      })
                    }
                  />
                }
              />
            )}
          <div className={`rmq-settings-panel-wrapper`}>
            <div className={`rmq-settings-input-panel`}>
              <div className={`rmq-settings-panel-title`}>{`Input`}</div>
              <div className="old-rmq-scope-and-filter">
                <ScopeManagementContext.Provider
                  value={{
                    isOpen,
                    toggleIsOpen,
                    setDefaultsForScopeAndFilter: false,
                  }}
                >
                  <ScopeManagement editable={false} withTitle={false} />
                </ScopeManagementContext.Provider>
                <FilterManagement
                  onFilterSave={handleFilterSave}
                  effectedSingleQueueName={title}
                  setDefaultsForScopeAndFilter={false}
                  hasEditMode={true}
                />
              </div>
            </div>

            <div className="rmq-settings-separator-wrap">
              <div className="rmq-settings-separator-arrow">
                <i className="seem-icon-next-arrow" />
              </div>
              <div className="rmq-settings-separator" />
            </div>
            <div
              className={`rmq-settings-output-panel ${
                mode === modeOptions.CREATE &&
                (!selectedFilter ||
                  selectedFilter?.label === EMPTY_FILTER ||
                  !selectedScope ||
                  isEditFilter)
                  ? "rmq-settings-output-panel-disabled"
                  : null
              }`}
            >
              <div className={`rmq-settings-panel-title`}>{`Output`}</div>
              <OutputPreferences
                disabled={
                  mode === modeOptions.CREATE &&
                  (!selectedFilter ||
                    selectedFilter?.label === EMPTY_FILTER ||
                    !selectedScope ||
                    isEditFilter)
                }
                getPropsFromTicketManager={getTicketManagerProps}
                setGetPropsFromField={setGetPropsFromField}
                setGetPropsFromKeyField={setGetPropsFromKeyField}
                setGetPropsFromTicketManager={setGetPropsFromTicketManager}
              />
            </div>
          </div>
          <div className={`form-button-group rmq-form-btn-container`}>
            <Button
              data-testid={"cancel-queue-editing"}
              label={t("Cancel")}
              onClick={() =>
                mode === modeOptions.CREATE
                  ? history.push("/remediation/workflows")
                  : updateMode(modeOptions.READ)
              }
            />
            {id ? (
              <Button
                data-testid={"save-edited-rmq"}
                isSecondary
                label={t("Save")}
                onClick={() => {
                  setQueueState(state);
                  client.cache.evict({ fieldName: "endpoint_fields" });
                  client.refetchQueries({
                    include: ["GetRemediationQueue"],
                  });
                }}
                type={"submit"}
                disabled={
                  (!isDirty &&
                    selectedScope?.value?.id === scope_id &&
                    selectedFilter?.value?.id === filter_id &&
                    !filterChange) ||
                  isSubmittingForm
                }
              />
            ) : (
              <>
                {selectedTicketManager !== ticketManagersEnum.SEEMPLICITY && (
                  <Button
                    data-testid={"rmq-sync-later"}
                    label={t("Create Queue and Sync Later")}
                    type={"submit"}
                    onClick={() => setQueueState(queueStates.DISABLED)}
                    disabled={!isValid || isEditFilter}
                  />
                )}
                <Button
                  data-testid={"rmq-sync-now"}
                  isSecondary
                  label={
                    selectedTicketManager === ticketManagersEnum.SEEMPLICITY
                      ? "Save Queue"
                      : "Sync Now"
                  }
                  type={"submit"}
                  onClick={() =>
                    setQueueState(
                      getTicketManagerProps(selectedTicketManager)?.type ===
                        ticketEndpointTypeEnum.INTERNAL
                        ? queueStates.DISABLED
                        : queueStates.ENABLED
                    )
                  }
                  disabled={!isValid || isSubmittingForm || isEditFilter}
                />
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default QueueSettings;
