import React, { useEffect } from "react";

const Trow = ({
  row,
  prepareRow,
  onRowClick,
  "data-testid": dataTestId,
  onRowExpand,
}) => {
  prepareRow(row);

  useEffect(() => {
    if (row.isExpanded) {
      onRowExpand(row);
    }
  }, [row.isExpanded]);

  return (
    <tr
      {...row.getRowProps()}
      className={`tr ${row.depth === 0 ? "tr-root" : "tr-subrow"}`}
      onClick={() => {
        if (!!onRowClick && typeof onRowClick === "function") {
          onRowClick(row);
        }
      }}
      data-testid={dataTestId}
    >
      {row.cells.map((cell) => {
        return (
          <td
            className={`td ${
              cell?.column?.className ? cell.column.className : ""
            }`}
            {...cell.getCellProps()}
            data-testid={`${dataTestId}-${cell?.column?.id}`}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default Trow;
