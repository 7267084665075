import React, { useState, useRef } from "react";
import Popover from "react-popover";
import { useClickAway } from "react-use";
import Tags from "../../../../FindingDetailsPane/Resources/Tags/Tags";

const TagsPopover = ({
  tagsID,
  tipSize,
  "data-testid": dataTestId,
  seemTags,
  dsTags,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);
  const actionsMenuWrapperRef = useRef(null);

  useClickAway(
    actionsMenuWrapperRef,
    () => {
      toggleIsOpen(false);
    },
    ["click"]
  );

  return (
    <Popover
      tagsID={tagsID}
      isOpen={isOpen}
      tipSize={tipSize || 0.01}
      place={"above"}
      onOuterAction={() => {
        toggleIsOpen(false);
      }}
      body={[
        <div
          className={`tags-popover-container`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Tags
            showCollapsed={false}
            showHeader={false}
            maxTags={seemTags?.length + dsTags?.length}
            seem_tags={seemTags}
            datasource_tags={dsTags}
            displayKey={false}
          />
        </div>,
      ]}
    >
      <div
        ref={actionsMenuWrapperRef}
        style={{ position: "relative" }}
        className="tags-popover-wrapper"
        data-testid={dataTestId}
        onClick={(event) => {
          event.stopPropagation();
          toggleIsOpen(!isOpen);
        }}
      >
        <i className={`seem-icon seem-icon-more-actions`} />
      </div>
    </Popover>
  );
};

export default TagsPopover;
