import React, { useEffect, useMemo, useState } from "react";
import Tabs from "../../Components/Tabs/Tabs";
import Profiles from "./Profiles/Profiles";
import "./Permissions.scss";

const Permissions = ({ defaultPage = null }) => {
  const [activeItem, setActiveItem] = useState(defaultPage);

  const handleSelectTab = (item) => {
    setActiveItem(item);
  };

  const permissionsOptions = useMemo(() => {
    return [
      {
        title: "Profile Management",
        pageLink: "profiles",
        component: <Profiles />,
      },
    ];
  }, []);

  useEffect(() => {
    if (!defaultPage && !!permissionsOptions) {
      setActiveItem(permissionsOptions[0]);
    } else if (!!defaultPage && !!permissionsOptions) {
      setActiveItem(
        permissionsOptions.find((el) => el.pageLink === defaultPage)
      );
    }
  }, []);

  return (
    <div className={"permissions-wrapper"}>
      <Tabs
        menuItems={permissionsOptions}
        onSelectTab={handleSelectTab}
        activeId={activeItem?.pageLink}
      />
      {activeItem?.component}
    </div>
  );
};

export default Permissions;
