import { gql } from "@apollo/client";

export const GET_TICKET_PROVIDER_DEFINITIONS = gql`
  query GetTicketProviderDefinitions {
    ticket_provider_definitions {
      edges {
        node {
          id
          type
          instructions
          sections {
            name
            rows {
              fields {
                display_name
                field_name
                default_string_value
                field_input_type
                field_input_size
                required
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TICKET_PROVIDERS = gql`
  query GetTicketProviders($filters: TicketProviderFilter) {
    ticket_providers(filters: $filters) {
      edges {
        node {
          id
          type
          known_identifier
          is_installed
          instructions {
            title
            description
            link
            link_text
          }
          configuration
        }
      }
    }
  }
`;

export const ADD_TICKET_PROVIDER = gql`
  mutation AddTicketProvider(
    $ticket_provider_type: TicketProviderTypeEnum
    $incoming_configuration: JSONString!
  ) {
    new_ticket_provider(
      ticket_provider_type: $ticket_provider_type
      incoming_configuration: $incoming_configuration
    ) {
      ticket_provider {
        id
        known_identifier
        is_installed
        instructions {
          title
          description
          link
          link_text
        }
        configuration
      }
    }
  }
`;
