import React from "react";
import "./ActivityHeader.scss";
import ReactTooltip from "react-tooltip";

const ActivityHeader = () => {
  return (
    <div className={`queue-activity-header`}>
      <h3 className={"remediation-queue-title"}>Activity</h3>
      <div className={`activity-level-data`}>
        <span className={`level-of-activity-title`}>Activity Level</span>
        <div className={`level-of-activity-data`}>
          <div className={`level-value`}>High</div>
          <i
            className={`seem-icon seem-icon-help`}
            data-tip
            data-for="activityLevelTip"
          />
          <ReactTooltip
            id="activityLevelTip"
            place="top"
            className={"activity-level-tooltip"}
          >
            {"Represent the rhythm of changes in the Q"}
          </ReactTooltip>
        </div>
      </div>
    </div>
  );
};

export default ActivityHeader;
