import React, { useContext, useEffect, useState } from "react";
import "./InstancesItem.scss";
import CheckBox from "../../../../Forms/Controls/CheckBox/CheckBox";
import Button from "../../../../Forms/Controls/Button/Button";
import { GET_DATA_SOURCES, UPDATE_DATA_SOURCE } from "../DataSourcesApi";
import { useMutation } from "@apollo/client";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import moment from "moment";

const InstancesItem = ({
  name,
  lastSynced,
  id,
  enabled = null,
  IntegrationContext,
}) => {
  const { handleInstanceEditing } = useContext(IntegrationContext);

  const [updateDatasource] = useMutation(UPDATE_DATA_SOURCE, {
    refetchQueries: [
      { query: GET_DATA_SOURCES, variables: { with_last_received: false } },
    ],
  });
  const [isEnabled, setIsEnabled] = useState(null);

  useEffect(() => {
    if (isEnabled !== null) {
      updateDatasource({ variables: { enabled: isEnabled, id } })
        .then((res) => {
          if (res?.data?.update_datasource?.ok) {
            showToast({
              message: `${name} instance successfully updated`,
            });
          } else {
            showErrorDialog({
              message: res.data?.update_datasource?.msg,
            });
            setIsEnabled(null);
          }
        })
        .catch((err) => {
          showErrorDialog({ message: err.message });
          setIsEnabled(null);
        });
    }
  }, [isEnabled]);

  return (
    <div className={"ds-instance-wrapper"}>
      <div className={"ds-instance-cell ds-instance-name"} title={name}>
        {name}
      </div>
      <div className={"ds-instance-cell ds-instance-last-sync"}>
        {lastSynced ? (
          `Collected ${moment(lastSynced).format("MMM Do YYYY, hh:mm a")}`
        ) : (
          <span />
        )}
        <Button
          data-testid={"edit-instance"}
          isClean
          isLarge
          isIcon="seem-icon-edit"
          type={`button`}
          onClick={() => handleInstanceEditing(id)}
          title={"Edit"}
        />
      </div>

      <CheckBox
        data-testid={"integration_enable"}
        className={"ds-instance-cell"}
        isToggle
        checked={isEnabled === null ? enabled : isEnabled}
        onChange={(val, newValue) => setIsEnabled(newValue)}
      />
    </div>
  );
};

export default InstancesItem;
