import React, { useContext, useMemo } from "react";
import "./FindingsListItem.scss";
import {
  findingStatuses,
  cloudProviderIcons,
  findingsColumns,
  dataSourceIcons,
  slaStatuses,
  findingsCheckType,
} from "../../../FindingsUtils";
import TicketStatus from "./TicketStatus/TicketStatus";
import { snakeCase } from "lodash";
import {
  getTicketManagerFriendlyName,
  ticketStatus as ticketStatusEnum,
} from "../../../../Ticketing/TicketingUtils";
import TagsPopover from "./TagsPopover/TagsPopover";
import Tags from "../../../FindingDetailsPane/Resources/Tags/Tags";
import SLAStatus from "../../SLAStatus/SLAStatus";
import { SLA_STATUS_NA } from "../../../../Dashboard/SLADashboard/SLAUtils";
import { AppPreferencesContext } from "../../../../General/AppPreferencesProvider";
import CheckBox from "../../../../Forms/Controls/CheckBox/CheckBox";
import ProgressBar from "../../../../Components/ProgressBar/ProgressBar";
import ScoreTag from "../../../../Components/ScoreTag/ScoreTag";
import { useLazyQuery } from "@apollo/client";
import { GET_TICKET_LINK } from "../../../FindingDetailsPane/DetailsPaneApi";
import FilterInOut from "../../FilterInOut/FilterInOut";

const MAX_TAGS = 3;
const FindingsListItem = ({
  rowData,
  severity,
  onSelectFinding,
  isSelected,
  isChecked,
  hasSelected,
  groupId,
  groupBy,
  columns,
  enableRowSelection = true,
  checkedFindings = [],
  onCheckFinding,
  multiSelect,
  filteringEnabled = false,
}) => {
  const {
    id,
    id_int,
    cloud_provider,
    cloud_account,
    region,
    title,
    resource_name,
    score,
    actual_status,
    original_status,
    ticketStatus,
    cloud_account_friendly_name,
    ticketId,
    tickets,
    seem_tags_values,
    datasource_tags_values,
    sla_status,
    aggregated_findings_count,
    open_aggregated_findings_count,
    age,
    severity: findingSeverity,
    resource_count = 1,
  } = rowData;

  const {
    appPreferences: { slaEnabled },
  } = useContext(AppPreferencesContext);

  const [getTicketLink] = useLazyQuery(GET_TICKET_LINK, {
    onCompleted: (data) => {
      const link = data?.finding?.tickets?.edges?.[0]?.node?.link;
      window.open(link, "_blank");
    },
  });

  const getGroupColorsClass = useMemo(() => {
    if (groupBy === "severity") {
      return groupBy;
    } else {
      return "default";
    }
  }, [groupBy]);

  const seemTags = useMemo(() => {
    return (
      seem_tags_values?.map((tag) => {
        return [tag, tag];
      }) || []
    );
  }, [seem_tags_values]);

  const dsTags = useMemo(() => {
    return (
      datasource_tags_values?.map((tag) => {
        return [tag, tag];
      }) || []
    );
  }, [datasource_tags_values]);

  const fixedFindingsCount = useMemo(() => {
    const total = aggregated_findings_count;
    const open = open_aggregated_findings_count || 0;

    return total - open;
  }, [aggregated_findings_count, open_aggregated_findings_count]);

  const fixedFindingsPercent = useMemo(() => {
    return (fixedFindingsCount * 100) / aggregated_findings_count;
  }, [aggregated_findings_count]);

  const getStatusCell = (originalStatus, actualStatus) =>
    !!actualStatus
      ? `${findingStatuses[actualStatus?.key]}${
          originalStatus?.length ? ` - ${originalStatus}` : ""
        }`
      : "";

  return (
    <div
      className={`findings-list-item-wrapper table-row-group group-${getGroupColorsClass}-${groupId} ${
        !!hasSelected && !isSelected ? "not-selected" : ""
      } ${isSelected ? "is-selected" : ""} ${
        enableRowSelection ? "is-selectable" : ""
      }
      `}
      onClick={(e) => {
        onSelectFinding(e);
      }}
    >
      <div
        className={`finding-item-data table-row`}
        data-testid={"finding-row"}
      >
        {columns?.includes(findingsColumns.ACCOUNT) && (
          <div
            className={`finding-account-col ${
              !!region ? "with-region" : ""
            } table-cell`}
            title={`${
              !!cloud_account_friendly_name
                ? cloud_account_friendly_name
                : cloud_account
            } ${region ? region : ""}`}
            data-testid={"finding-cloud-account"}
          >
            {multiSelect && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                data-id={id_int}
                data-testid={`finding-row-id-${id_int}`}
              >
                <CheckBox
                  data-testid={`finding-checkbox-${id_int}`}
                  checked={isChecked}
                  className={"findings-table-checkbox"}
                  onChange={(val, newValue) => {
                    onCheckFinding(
                      { id: val, isMainFinding: aggregated_findings_count > 1 },
                      newValue
                    );
                  }}
                  value={id_int}
                  disabled={checkedFindings?.type === findingsCheckType.ALL}
                />
              </div>
            )}
            <div
              className="finding-cloud-provider-icon"
              title={`${cloud_provider}`}
              data-testid={"finding-cloud-provider"}
            >
              <img
                alt={`${cloud_provider}`}
                src={cloudProviderIcons[snakeCase(cloud_provider)]}
              />
            </div>
            <span className="finding-cloud-account table-cell-text">{`${
              !!cloud_account_friendly_name
                ? cloud_account_friendly_name
                : cloud_account
            }`}</span>
            {!!region && <span className="finding-region">{region}</span>}
          </div>
        )}

        {columns?.includes(findingsColumns.RESOURCES) && (
          <div
            className={`finding-resources-col table-cell ${
              resource_count > 1 ? "resource-tag" : "resource-name"
            }`}
            title={resource_name}
            data-testid={"finding-resource-name"}
          >
            {resource_count > 1 ? (
              <ScoreTag
                score={resource_count}
                severity={"resources"}
                isLargeWidth
              />
            ) : (
              resource_name
            )}
          </div>
        )}

        {columns?.includes(findingsColumns.TITLE) && (
          <div
            className="finding-title-col table-cell"
            title={`${title}`}
            data-testid={"finding-title"}
          >
            <>
              <div className={`finding-title-col-data table-cell-text`}>
                {title}
              </div>
              {filteringEnabled && (
                <FilterInOut
                  field={findingsColumns.TITLE}
                  inCondition={"likelist"}
                  exCondition={"notlikelist"}
                  value={title}
                />
              )}
            </>
          </div>
        )}

        {columns?.includes(findingsColumns.STATUS) && (
          <div
            className="finding-status-col table-cell"
            data-testid="finding-status"
          >
            <span
              className={"finding-status-col-value"}
              title={getStatusCell(original_status, actual_status)}
            >
              {getStatusCell(original_status, actual_status)}
            </span>
            {!!aggregated_findings_count && aggregated_findings_count > 1 && (
              <div
                onClick={(e) => e.stopPropagation()}
                className={"finding-status-col-progress"}
                data-testid={`progress-bar-container-${id}`}
                data-tip={`${fixedFindingsCount} Fixed & Ignored , ${open_aggregated_findings_count} Open`}
              >
                <ProgressBar percent={fixedFindingsPercent} />
              </div>
            )}
          </div>
        )}

        {columns?.includes(findingsColumns.SEVERITY) && (
          <div
            className={`finding-score-col table-cell`}
            title={score}
            data-testid={"finding-score"}
          >
            <ScoreTag severity={severity} score={score} />
            {filteringEnabled && (
              <FilterInOut
                field={findingsColumns.SEVERITY}
                value={findingSeverity.toString()}
              />
            )}
          </div>
        )}

        {columns?.includes(findingsColumns.SLA_STATUS) && slaEnabled && (
          <div
            className="finding-sla-status-col table-cell"
            title={slaStatuses?.[sla_status]?.title}
            data-testid="finding-sla-status"
          >
            {!!sla_status && sla_status !== SLA_STATUS_NA && (
              <>
                <SLAStatus sla_status={sla_status} />
                {filteringEnabled && (
                  <FilterInOut
                    field={findingsColumns.SLA_STATUS}
                    value={sla_status}
                  />
                )}
              </>
            )}
          </div>
        )}

        {columns?.includes(findingsColumns.SLA_STATUS) && !slaEnabled && (
          <div
            className="finding-sla-status-col table-cell"
            title={`${age} Days`}
            data-testid="finding-sla-status"
          >
            {`${age} Days`}
          </div>
        )}

        {columns?.includes(findingsColumns.TICKET_STATUS) && (
          <div
            className="finding-ticket-status-col table-cell"
            title={ticketStatusEnum?.[ticketStatus]?.text}
            data-testid={"finding-ticket-status"}
          >
            {!!ticketStatus && (
              <>
                <TicketStatus status={ticketStatus} />
                {filteringEnabled && (
                  <FilterInOut
                    field={findingsColumns.TICKET_STATUS}
                    value={ticketStatus}
                  />
                )}
              </>
            )}
          </div>
        )}

        {columns?.includes(findingsColumns.TAGS) &&
          (!!seem_tags_values?.length || !!datasource_tags_values?.length) && (
            <div
              className={`finding-tags-col table-cell`}
              data-testid={"finding-tags"}
            >
              <Tags
                tagsID={id}
                showCollapsed={false}
                showHeader={false}
                maxTags={3}
                seem_tags={seemTags}
                datasource_tags={dsTags}
                displayKey={false}
              />
              {seemTags?.length + dsTags?.length > MAX_TAGS && (
                <TagsPopover
                  tagsID={id}
                  seemTags={seemTags}
                  dsTags={dsTags}
                  data-testid={"tags-popover"}
                />
              )}
            </div>
          )}
        {columns?.includes(findingsColumns.TICKET_ID) && (
          <div className={`finding-ticket-id-col table-cell`}>
            {tickets?.edges?.length ? (
              <>
                <img
                  onClick={(e) => e.stopPropagation()}
                  data-tip={`${getTicketManagerFriendlyName(
                    tickets?.edges?.[0]?.node.ticket_provider?.type
                  )}`}
                  alt={`${tickets?.edges?.[0]?.node.ticket_provider?.type}`}
                  width="20"
                  src={
                    dataSourceIcons[
                      tickets?.edges?.[0]?.node.ticket_provider?.type
                    ]?.S
                  }
                />
                <a
                  href="#"
                  className={"ticket-url"}
                  alt="View ticket"
                  title={`View Ticket`}
                  onClick={(e) => {
                    e.preventDefault();
                    getTicketLink({ variables: { id } });
                    e.stopPropagation();
                  }}
                >
                  {ticketId}
                </a>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FindingsListItem;
