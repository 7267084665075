import React, { useContext, useEffect, useState } from "react";
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { filterTypes, resourceTypes } from "../../../Findings/FindingsUtils";
import {
  LS_SCOPE,
  processFilter,
} from "../../../Findings/FindingsFilters/FilterUtils";
import { scopePanelMode, ScopesContext } from "../ScopeProvider/ScopeProvider";
import "./ScopeSettings.scss";
import TextInput from "../../../Forms/Controls/TextInput/TextInput";
import ModalButtonsContainer from "../../../Forms/Dialogs/Modal/ModalButtonsContainer/ModalButtonsContainer";
import Button from "../../../Forms/Controls/Button/Button";
import ScopePanel from "./ScopePanel/ScopePanel";
import { GeneralAppContext } from "../../../General/GeneralAppProvider";
import { dismissToast, showToast } from "../../../Forms/Dialogs/Toast/Toast";
import { showDialog } from "../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "@apollo/client";
import {
  DELETE_FILTER,
  SAVE_FILTER,
  UPDATE_FILTER,
} from "../../../Findings/FindingsFilters/FindingsFilterApi";
import { ScopeManagementContext } from "../../../Findings/FindingsFilters/ScopeHierarchy/ScopeManagement/useScope";
import UnsavedDataToast from "../../../Forms/Dialogs/Toast/UnsavedDataToast";
import FilterSaveAs from "../../../Findings/FindingsFilters/FilterSaveAs/FilterSaveAs";
import { ModalContext } from "../../../Forms/Dialogs/Modal/ModalContext";
import { getQueuePluralsByCount } from "../../AppViewUtils";
import { useTranslation } from "react-i18next";
import LocationsTree from "../LocationsTree/LocationsTree";

const ScopeSettings = () => {
  const { t } = useTranslation();
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      scopeName: "",
      filterPanels: [],
      location: null,
      saveAsFilterName: "",
    },
  });

  const { openModal, closeModal } = useContext(ModalContext);

  const [updateScope] = useMutation(UPDATE_FILTER, {
    refetchQueries: ["scopes"],
  });

  const [createScope] = useMutation(SAVE_FILTER, {
    refetchQueries: ["scopes"],
  });

  const [deleteScope] = useMutation(DELETE_FILTER, {
    refetchQueries: ["scopes"],
  });

  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const { toggleIsOpen } = useContext(ScopeManagementContext);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
    reset,
    getValues,
    setValue,
    setError,
    setFocus,
  } = methods;
  const {
    scopesState: { scopesList, selectedScope, isNewScope },
    updateScopeState,
    searchTree,
  } = useContext(ScopesContext);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "filterPanels",
  });
  const saveAsFilterNameValue = useWatch({
    control,
    name: `saveAsFilterName`,
  });

  const watchFieldArray = useWatch({
    control,
    name: `filterPanels`,
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const [toastId, setToastId] = useState(null);

  useEffect(() => {
    updateScopeState({ isDirty: isDirty });
  }, [isDirty]);

  useEffect(() => {
    if ((isDirty || !selectedScope?.value?.id) && isValid && !toastId) {
      const id = showToast({
        position: "bottom-right",
        hideProgressBar: true,
        autoClose: false,
        className: "unsaved-filter",
        closeOnClick: false,
        component: (
          <UnsavedDataToast
            message={
              <>
                <div>{`The active scope is unsaved.`}</div>
                <Button
                  data-testid={`scope-save-toast`}
                  label={"Save to easily use again"}
                  onClick={() => {
                    isNewScope ? handleSubmit(onCreate)() : validateOnUpdate();
                    dismissToast(toastId);
                  }}
                  disabled={!isNewScope && !selectedScope?.value?.editable}
                />
              </>
            }
          />
        ),
      });

      setToastId(id);
    } else {
      dismissToast(toastId);
      setToastId(null);
    }
  }, [isDirty, isValid, selectedScope]);

  const getConditions = (filters) => {
    return filters?.map((filterPanel) => {
      const conditions = [];
      for (let i = 0; i < filterPanel?.conditions?.length; i++) {
        let conditionData = filterPanel?.conditions?.[i];
        if (conditionData?.operands) {
          conditions.push(conditionData?.operands?.[0]);
          conditions.push(conditionData?.operands?.[1]);
        } else {
          conditions.push(conditionData);
        }
      }

      return {
        ...filterPanel,
        conditions: conditions,
      };
    });
  };

  useEffect(() => {
    if (isNewScope) {
      let selectedLocation = scopesList?.[0];
      if (!!selectedScope) {
        const locationToSearch =
          selectedScope?.value?.type === resourceTypes.GROUP
            ? selectedScope?.value?.id
            : selectedScope?.parentId;
        selectedLocation = searchTree(scopesList?.[0], locationToSearch);
      }

      reset({
        ...getValues(),
        scopeName: "",
        filterPanels: [{}],
        location: selectedLocation || scopesList?.[0],
      });
    }
  }, [isNewScope, scopesList, selectedScope]);

  useEffect(() => {
    if (isNewScope) {
      setTimeout(() => {
        setFocus("scopeName");
      }, 0);
    }
  }, [setFocus, isNewScope]);

  useEffect(() => {
    if (!isNewScope) {
      if (!!selectedScope) {
        let selectedLocation = null;
        const filter =
          !!selectedScope && selectedScope?.value?.query
            ? JSON.parse(selectedScope?.value?.query)
            : null;
        let filterObj = !!filter ? processFilter(filter) : [];

        if (!!filter) {
          filterObj = getConditions(filterObj.filters);
        }

        selectedLocation = searchTree(scopesList?.[0], selectedScope?.parentId);

        reset({
          ...getValues(),
          scopeName: selectedScope?.label || "",
          filterPanels: filterObj,
          location: selectedLocation || scopesList?.[0],
        });
      }
    }
  }, [scopesList, selectedScope]);

  const getScopeJson = (data) => {
    let scopesJson = data?.filterPanels?.reduce((panelRes, item, index) => {
      const panelOperands = item.conditions.reduce(
        (res, current, conditionIndex) => {
          const operands = {
            value: current.value,
            field: current.field,
            condition: current.condition,
          };

          let jsonFilter;
          if (conditionIndex === 0) {
            jsonFilter = operands;
          } else {
            jsonFilter = { operator: "and", operands: [res, operands] };
          }

          return jsonFilter;
        },
        {}
      );

      let panelsFilter;
      if (index === 0) {
        panelsFilter = panelOperands;
      } else {
        panelsFilter = {
          operator: "or",
          operands: [panelRes, panelOperands],
        };
      }

      return panelsFilter;
    }, {});

    let scope = !!scopesJson ? JSON.stringify(scopesJson) : "";
    return scope;
  };

  const onSubmit = (data) => {
    //preview:
    const scopeToExecute = getScopeJson(data);

    updateScopeState({ scopeToExecute });
    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  const previewFilter = () => {
    handleSubmit(onSubmit)();
  };

  const onCreate = (data) => {
    if (!data?.scopeName) {
      setError("scopeName", {
        type: "error",
        message: t("Required"),
      });
      return;
    }
    let scopeMutationData = {};
    const scope = getScopeJson(data);

    scopeMutationData = {
      name: data?.saveAsFilterName || data?.scopeName,
      filterData: scope,
      filterType: filterTypes.SCOPE,
      scope_group: data?.location?.id,
    };

    createScope({ variables: scopeMutationData })
      .then((res) => {
        if (!!res.data?.new_finding_filter?.finding_filter) {
          const selectedScope = {
            label: scopeMutationData.name,
            value: {
              query: scope,
              id: res.data?.new_finding_filter?.finding_filter?.id,
              editable: true,
              type: filterTypes.SCOPE,
            },
            parentId: scopeMutationData?.scope_group,
          };

          updateScopeState({
            scopeToExecute: scope,
            selectedScope: selectedScope,
            isNewScope: false,
          });
          localStorage.setItem(LS_SCOPE, JSON.stringify(selectedScope));

          if (resetFindingsList) {
            resetFindingsList();
          }
          closeModal();
          showToast({ message: "Scope Created!" });
        } else {
          showDialog({
            title: "Error",
            type: "error",
            message: "Scope creation failed",
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        }
      })
      .catch((err) => {
        showDialog({
          title: "Error",
          type: "error",
          message: err.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };

  const validateOnUpdate = () => {
    handleSubmit((data) => onUpdate(data, false))();
  };

  const onUpdate = (data, overwrite) => {
    if (!data?.scopeName) {
      setError("scopeName", {
        type: "error",
        message: t("Required"),
      });
      return;
    }
    let scopeMutationData = {};
    const scope = getScopeJson(data);
    scopeMutationData = {
      name: data?.scopeName,
      filterData: scope,
      filterType: filterTypes.SCOPE,
      scope_group: data?.location?.id,
      id: selectedScope?.value?.id,
      overwrite,
    };

    updateScope({ variables: scopeMutationData })
      .then((res) => {
        if (
          !overwrite &&
          !res?.data?.update_finding_filter?.ok &&
          res?.data?.update_finding_filter?.effected_queues?.length
        ) {
          showDialog({
            title: "Save Scope",
            type: "warning",
            className: "save-scope-warning",
            description: `The scope "${
              data?.scopeName
            }" is part of an existing ${getQueuePluralsByCount(
              res?.data?.update_finding_filter?.effected_queues?.length
            )}.`,
            message: `Saving this scope might change the findings in the ${getQueuePluralsByCount(
              res?.data?.update_finding_filter?.effected_queues?.length
            )} "${res?.data?.update_finding_filter?.effected_queues?.join(
              ", "
            )}".`,
            buttons: [
              {
                isSecondary: false,
                label: "Cancel",
              },
              {
                isSecondary: false,
                label: "Save as a new scope",
                onClick: () => {
                  openSaveAsModal();
                },
              },
              {
                isSecondary: true,
                label: "Save and update queue",
                onClick: () => {
                  handleSubmit((data) => onUpdate(data, true))();
                },
              },
            ],
          });
        } else if (!!res.data?.update_finding_filter?.ok) {
          const selectedScope = {
            label: scopeMutationData.name,
            value: {
              query: scope,
              id: scopeMutationData.id,
              editable: true,
              type: filterTypes.SCOPE,
            },
            parentId: scopeMutationData?.scope_group,
          };

          updateScopeState({
            scopeToExecute: scope,
            selectedScope: selectedScope,
            isNewScope: false,
          });
          localStorage.setItem(LS_SCOPE, JSON.stringify(selectedScope));

          if (resetFindingsList) {
            resetFindingsList();
          }
          showToast({ message: "Scope Updated!" });
        } else {
          showDialog({
            title: "Error",
            type: "error",
            message: "Scope update failed",
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
        }
      })
      .catch((err) => {
        showDialog({
          title: "Error",
          type: "error",
          message: err.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };

  const openSaveAsModal = () => {
    openModal({
      title: "Save As",
      component: <FilterSaveAs setValue={setValue} />,
    });
  };

  useEffect(() => {
    if (!!saveAsFilterNameValue) {
      handleSubmit(onCreate)();
    }
  }, [saveAsFilterNameValue]);

  const removeScope = (id) => {
    deleteScope({ variables: { id: id } })
      .then((res) => {
        if (res?.errors) {
          showDialog({
            title: "Error",
            type: "error",
            message: res?.errors.message,
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
          return;
        }
        let newState = {};

        newState = {
          scopeToExecute: null,
          selectedScope: null,
          mode: scopePanelMode.MENU,
        };
        localStorage.removeItem(LS_SCOPE);

        toggleIsOpen(false);
        updateScopeState(newState);

        if (resetFindingsList) {
          resetFindingsList();
        }
        showToast({ message: "Scope Deleted!" });
      })
      .catch((err) => {
        showDialog({
          title: "Error",
          type: "error",
          message: err.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };

  return (
    <div className={`scope-settings-wrapper`}>
      <FormProvider {...methods}>
        <form
          className={`scopes-panel-form-wrapper scopes-panel-form-wrapper-FormProvider`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={`scope-settings-header`}>
            <Button
              isClean
              className={``}
              isIcon="seem-icon-exit-scope"
              type={`button`}
              title={""}
              onClick={() => {
                if (toastId) {
                  dismissToast(toastId);
                }
                updateScopeState({
                  mode: scopePanelMode.MENU,
                  isNewScope: false,
                  isDirty: false,
                });
              }}
            />
            <Controller
              wrapperClassName={``}
              name={`location`}
              render={({ field: { value, onChange, onBlur } }) => (
                <LocationsTree
                  data-testid={"scope-location"}
                  name={`location`}
                  onChange={(node) => {
                    onChange(node);
                  }}
                  error={errors?.location ? errors.location : null}
                  // label={""}
                  value={value}
                  root={scopesList}
                  size={"xs"}
                  popoverClassName={"tree-in-scope-menu"}
                />
              )}
              control={control}
            />
            <div className={`scope-separator`}>/</div>
            <Controller
              wrapperClassName={``}
              name={`scopeName`}
              render={({ field: { value, onChange, onBlur, ref } }) => (
                <TextInput
                  wrapperClassName={`scope-name-input`}
                  data-testid={"scope-name"}
                  onBlur={onBlur}
                  inputStyle={"bomba-style"}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  inputRef={ref}
                  placeholder={"Name your scope..."}
                  size="s"
                  value={value}
                  defaultValue={""}
                  error={errors?.scopeName ? errors.scopeName : null}
                />
              )}
              control={control}
            />

            <ModalButtonsContainer>
              <Button
                data-testid={`scope-delete`}
                label={"Delete"}
                onClick={() =>
                  showDialog({
                    title: "Delete Scope?",
                    message: "Are you sure you wish to delete this scope?",
                    buttons: [
                      {
                        "data-testid": "cancel-delete",
                        label: "Cancel",
                      },
                      {
                        "data-testid": "confirm-delete",
                        isSecondary: true,
                        label: "Delete",
                        type: "submit",
                        onClick: () => {
                          removeScope(selectedScope?.value?.id);
                        },
                      },
                    ],
                  })
                }
                disabled={isNewScope || !selectedScope?.value?.id}
              />
              <Button
                data-testid={`scope-save`}
                label={"Save"}
                isSecondary
                onClick={
                  isNewScope || !selectedScope?.value?.id
                    ? handleSubmit(onCreate)
                    : validateOnUpdate
                }
                disabled={
                  (!isNewScope &&
                    (!selectedScope?.value?.editable ||
                      !isValid ||
                      (selectedScope?.value?.id && !isDirty))) ||
                  (isNewScope && (!isValid || !isDirty))
                }
              />
            </ModalButtonsContainer>
          </div>
          <div className={`header-separator`} />
          <div className={`scopes-panel-data`}>
            {controlledFields?.map((field, index) => (
              <div className={`scope-condition-row`} key={field?.id}>
                {index > 0 && <div className={`or-mark`}>OR</div>}
                <div className={`filter-panel-data`}>
                  <ScopePanel
                    namePrefix={`filterPanels[${index}]`}
                    key={field?.id}
                    previewFilter={previewFilter}
                    rowIndex={index}
                    removeRow={remove}
                  />
                </div>
                {(index > 0 || controlledFields?.length > 1) && (
                  <Button
                    isClean
                    data-testid={"remove-filter-field"}
                    className={``}
                    isIcon="seem-icon-bin"
                    type={`button`}
                    title={""}
                    onClick={() => remove(index)}
                  />
                )}
              </div>
            ))}
            <Button
              data-testid={`scope-add-condition`}
              className={`scope-add-condition`}
              label={"+ Add Condition"}
              disabled={
                !isValid ||
                (isNewScope && !isDirty) ||
                !controlledFields?.[controlledFields?.length - 1].conditions
                  ?.length
              }
              onClick={(e) => {
                append({ conditions: [{ field: null }] });
              }}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ScopeSettings;
