import { gql } from "@apollo/client";

export const GET_CUSTOMERS = gql`
  query get_customers {
    get_customers {
      customers {
        id
        friendly_name
        domains {
          edges {
            node {
              id
              domain
            }
          }
        }
      }
    }
  }
`;

export const CNC_GET_CUSTOMER = gql`
  query cnc_get_customer($customer_id: String!) {
    cnc_customer(customer_id: $customer_id) {
      id
      schema
      domains {
        edges {
          node {
            id
            domain
          }
        }
      }
    }
  }
`;

export const CNC_GET_USERS = gql`
  query cnc_users($customer_id: String!) {
    cnc_users(
      customer_id: $customer_id
      filters: { not: { activity_status: DELETED } }
    ) {
      edges {
        node {
          id
          email
          first_name
          last_name
          profiles {
            edges {
              node {
                id
                name
                scopes {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                scope_groups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const CNC_INVITE_USER = gql`
  mutation cncInviteUser(
    $send_email: Boolean
    $user: UserInput!
    $customer_id: String!
  ) {
    cnc_invite_user(
      send_email: $send_email
      user: $user
      customer_id: $customer_id
    ) {
      ok
      msg
    }
  }
`;

export const CREATE_NEW_CUSTOMER = gql`
  mutation (
    $customer: CustomerInput!
    $send_email: Boolean
    $user: UserInput!
  ) {
    new_customer(customer: $customer, send_email: $send_email, user: $user) {
      ok
      msg
    }
  }
`;

export const CREATE_NEW_DOMAIN = gql`
  mutation cnc_add_domain($domain: String!, $customer_id: String!) {
    cnc_add_domain(domain: $domain, customer_id: $customer_id) {
      msg
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation delete_customer($customer_id: String!) {
    terminate_customer(customer_id: $customer_id) {
      ok
      msg
    }
  }
`;

export const DELETE_DOMAIN = gql`
  mutation cnc_delete_domain($domain: String!, $customer_id: String!) {
    cnc_delete_domain(domain: $domain, customer_id: $customer_id) {
      msg
    }
  }
`;

export const CNC_DELETE_USER = gql`
  mutation cncDeleteUser($user_id: String!, $customer_id: String!) {
    cnc_delete_user(user_id: $user_id, customer_id: $customer_id) {
      ok
      msg
    }
  }
`;

export const CNC_EDIT_USER = gql`
  mutation cncEditUser(
    $customer_id: String!
    $user_id: String!
    $first_name: String!
    $last_name: String!
    $role_id: String
    $profile_ids: [String]
  ) {
    cnc_change_user_fields(
      user_id: $user_id
      first_name: $first_name
      last_name: $last_name
      role_id: $role_id
      profile_ids: $profile_ids
      customer_id: $customer_id
    ) {
      ok
      msg
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation cnc_reset_user_password($customer_id: String!, $user_id: String!) {
    cnc_reset_user_password(customer_id: $customer_id, user_id: $user_id) {
      ok
      msg
    }
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation change_user_password(
    $customer_id: String!
    $user_id: String!
    $new_password: String!
  ) {
    set_user_password(
      customer_id: $customer_id
      user_id: $user_id
      new_password: $new_password
    ) {
      ok
      msg
    }
  }
`;
