import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { federatedSignIn, getAuthenticatedUser } from "../AuthApi";

const AuthSAML = () => {
  const { provider } = useParams();
  let history = useHistory();
  useEffect(() => {
    if (!!provider) {
      getAuthenticatedUser()
        .then(() => {
          history.push("/findings");
        })
        .catch(() => {
          federatedSignIn({ provider: provider });
        });
    }
  }, [provider]);

  return null;
};

export default AuthSAML;
