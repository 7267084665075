import { useFlags } from "launchdarkly-react-client-sdk";
import { useMutation } from "@apollo/client";
import { DOWNLOAD_FINDINGS } from "../../Findings/FindingsData/FindingsList/FindingsListApi";
import React, { useContext, useState } from "react";
import axios from "axios";
import { showErrorDialog } from "../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import ReactTooltip from "react-tooltip";
import FileSaver from "file-saver";
import { FiltersContext } from "../FilterManagement/FilterProvider/FilterProvider";
import { ScopesContext } from "../ScopeManagement/ScopeProvider/ScopeProvider";
import "./DownloadFindings.scss";
import Button from "../../Forms/Controls/Button/Button";

const DownloadFindings = ({ enableDownload }) => {
  const { downloadFindings } = useFlags();
  const [getFindingsFileUrl] = useMutation(DOWNLOAD_FINDINGS);
  const [downloadingFile, setIsDownloadingFile] = useState(false);
  const { filtersState } = useContext(FiltersContext);
  const { filterToExecute } = filtersState;

  const { scopesState } = useContext(ScopesContext);
  const { selectedScope, scopeToExecute } = scopesState;

  const downloadFile = (preSignedUrl, fileName) => {
    axios
      .get(preSignedUrl, { responseType: "blob" })
      .then((res) => {
        const file = URL.createObjectURL(new Blob([res.data]));
        FileSaver.saveAs(file, fileName);
        setIsDownloadingFile(false);
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
        setIsDownloadingFile(false);
      });
  };

  const downloadFindingsList = () => {
    setIsDownloadingFile(true);
    getFindingsFileUrl({
      variables: {
        scopesjson: scopeToExecute,
        filtersjson: filterToExecute,
        scope_id: selectedScope?.value?.id,
      },
    })
      .then((res) => {
        if (res?.data?.download_findings?.url) {
          const fileName = "findings.csv.gz";
          downloadFile(res?.data?.download_findings?.url, fileName);
        } else {
          showErrorDialog({ message: "Error downloading file" });
          setIsDownloadingFile(false);
        }
      })
      .catch((err) => {
        showErrorDialog({ message: err.message });
        setIsDownloadingFile(false);
      });
  };

  return !!downloadFindings && enableDownload ? (
    <>
      <Button
        data-testid={"download-findings"}
        wrapperClassName={`header-icon-button ${
          downloadingFile ? "animate-flicker" : ""
        }`}
        type="button"
        isIcon="seem-icon-download"
        isClean
        data-for="download-findings"
        onClick={() => downloadFindingsList()}
        tooltipData={{
          dataFor: `download-findings`,
          dataTip: true,
        }}
      />
      <ReactTooltip
        id="download-findings"
        place="top"
        className={"copy-tooltip"}
      >
        {`Download Findings`}
      </ReactTooltip>
    </>
  ) : null;
};

export default DownloadFindings;
