import React, { useMemo } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import "./ConfirmationDialog.scss";
import { getDialogButtons } from "../DialogUtils";
import ErrorAnimation from "./ErrorAnimation";
import Button from "../../Controls/Button/Button";
import ErrorIndicator from "./ErrorIndicator";

export const ConfirmationDialog = ({
  message,
  buttons,
  type,
  showCloseIcon = true,
  title,
  icon,
  overlay = true,
  description,
  className = "",
}) => {
  const getAnimation = useMemo(() => {
    switch (type) {
      case "error":
        return <ErrorAnimation />;

      case "warning":
        return <ErrorIndicator description={description} />;

      default:
        return null;
    }
  }, [type]);
  return (
    <>
      {overlay === false ? null : <div className={`popup-dialog-overlay`} />}
      <div className={`popup-dialog-wrapper ${className} ${type}`}>
        <div className={`popup-dialog-content`}>
          <div className={`confirmation-dialog-header`}>
            {title && (
              <div className={`confirmation-dialog-title`}>
                {icon ? <i className={icon} /> : null}
                {title}
              </div>
            )}
            {showCloseIcon && (
              <Button
                data-testid={"confirmation-dialog"}
                isClean
                isLarge
                isIcon="seem-icon-close"
                type={`button`}
                title={"Close"}
                onClick={() => removePopupDialog()}
              />
            )}
          </div>

          {getAnimation && (
            <div className={`confirmation-dialog-character-container ${type}`}>
              {getAnimation}
            </div>
          )}

          <div className="popup-dialog-text">{message}</div>
          <div className={`form-button-group popup-dialog-buttons`}>
            {getDialogButtons(buttons, removePopupDialog)}
          </div>
        </div>
      </div>
    </>
  );
};

const createPopupDialog = (props) => {
  let divTarget = document.getElementById("popup-dialog-container");
  if (divTarget) {
    render(<ConfirmationDialog {...props} />, divTarget);
  } else {
    divTarget = document.createElement("div");
    divTarget.id = "popup-dialog-container";
    divTarget.className = "popup-dialog-container";
    document.body.appendChild(divTarget);
    render(<ConfirmationDialog {...props} />, divTarget);
  }
};

const removePopupDialog = () => {
  const target = document.getElementById("popup-dialog-container");
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export const showDialog = (props) => {
  createPopupDialog(props);
};

export const showErrorDialog = (props) => {
  createPopupDialog({
    ...props,
    title: "Error",
    type: "error",
    buttons: props.buttons
      ? [...props.buttons, { isSecondary: true, label: "Close" }]
      : [{ isSecondary: true, label: "Close" }],
  });
};
