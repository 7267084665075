import React, { useContext } from "react";
import "./App.scss";
import { GeneralAppContext } from "./General/GeneralAppProvider";
import AppHeader from "./AppHeader/AppHeader";
import FindingsProvider from "./Findings/FindingsProvider";
import { Switch } from "react-router-dom";
import { AuthContext } from "./Auth/AuthContext";
import DataGrid from "./DataGrid/DataGrid";
import RemediationQueueProvider from "./RemediationQueues/RemediationQueue/RemediationQueueProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import ResourcesList from "./Resources/ResourcesList";
import Dashboard from "./Dashboard/Dashboard";
import ProtectedRoute from "./AppRouter/ProtectedRoute";
import Remediation from "./Remediation/Remediation";
import SettingsMenu from "./AppSettings/SettingsMenu/SettingsMenu";
import RemediationQueue from "./RemediationQueues/RemediationQueue/RemediationQueue";
import CustomerProvisioning from "./CustomerProvisioning/CustomerProvisioning";
import { AppPreferencesContext } from "./General/AppPreferencesProvider";

function App() {
  const { authState } = useContext(AuthContext);
  const { resourcesPage, customerProvisioning } = useFlags();
  const { appPreferencesAreSet } = useContext(AppPreferencesContext);

  const {
    appContext: { appClass, layoutClass, isFiltersToggleOpen },
  } = useContext(GeneralAppContext);

  return (
    appPreferencesAreSet && (
      <div
        id="layout"
        className={`layout ${layoutClass} ${
          isFiltersToggleOpen ? "filters-toggle-open" : "filters-toggle-closed"
        }`}
      >
        <AppHeader />
        <div id="app" className={`App ${appClass}`}>
          <Switch>
            <ProtectedRoute path={`/findings/:id?`}>
              <FindingsProvider />
            </ProtectedRoute>
            <ProtectedRoute path={`/remediationQueue/:queueid?/:id?`}>
              <RemediationQueueProvider>
                <RemediationQueue />
              </RemediationQueueProvider>
            </ProtectedRoute>
            <ProtectedRoute path={`/dashboard/:id?`}>
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path={`/monitoring`}>
              {authState.isDemoAccount && <DataGrid />}
            </ProtectedRoute>
            <ProtectedRoute path={`/resources`}>
              {resourcesPage && <ResourcesList />}
            </ProtectedRoute>
            <ProtectedRoute path={`/remediation/:id?`}>
              <Remediation />
            </ProtectedRoute>
            <ProtectedRoute path={"/settings/:groupPath?/:itemPath?"}>
              <SettingsMenu />
            </ProtectedRoute>
            <ProtectedRoute path={`/customerProvisioning`}>
              {customerProvisioning && <CustomerProvisioning />}
            </ProtectedRoute>
          </Switch>
        </div>
      </div>
    )
  );
}

export default App;
