import React, { useEffect, useState } from "react";
import TextInput from "../../../../Forms/Controls/TextInput/TextInput";
import "./DataSourcesHeader.scss";
import Tabs from "../../../../Components/Tabs/Tabs";

const DataSourcesHeader = ({ searchTerm, setSearchTerm, onModeChange }) => {
  const modeOptions = [
    {
      pageLink: "enabled",
      title: "Enabled",
    },
    {
      pageLink: "all",
      title: "All Data Sources",
    },
  ];

  const [activeItem, setActiveItem] = useState(modeOptions[0]);

  const handleSelectTab = (item) => {
    setActiveItem(item);
    onModeChange(item.pageLink);
  };

  useEffect(() => {
    onModeChange(activeItem.pageLink);
  }, []);

  return (
    <div className={`integration-header-wrap`}>
      <Tabs
        menuItems={modeOptions}
        onSelectTab={handleSelectTab}
        activeId={activeItem?.pageLink}
      />
      <TextInput
        wrapperClassName={"integration-header-search"}
        data-testid={"integrations-header-search"}
        placeholder={"Type to search..."}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};

export default DataSourcesHeader;
