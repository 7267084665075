import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./Button.scss";
import ClassNames from "classnames";

const Button = ({
  isSubmitting,
  label,
  onClick,
  className,
  wrapperClassName,
  isIcon,
  isIconGhost,
  leftIcon,
  rightIcon,
  isLarge,
  isSmall,
  isXl,
  isXs,
  isFullWidth,
  isClean,
  darkStyle,
  type,
  isInvert,
  isSecondary,
  isDanger,
  title,
  disabled,
  isSecondaryInvert,
  noBorders,
  sizeMax,
  textLink,
  isInForm,
  isInMenu,
  "data-testid": dataTestId,
  tooltipData,
}) => {
  const { t } = useTranslation();
  const classNameWrapper = ClassNames("form-button-wrap", {
    "is-submitting": isSubmitting,
    "has-label": label,
    "is-icon": isIcon,
    "has-left-icon": leftIcon,
    "has-right-icon": rightIcon,
    "is-large": isLarge,
    "is-small": isSmall,
    "is-xl": isXl,
    "is-xs": isXs,
    "is-fullwidth": isFullWidth,
    "is-clean": isClean,
    "is-disabled": disabled,
    "is-invert": isInvert,
    "dark-style": darkStyle,
    "is-secondary": isSecondary,
    "is-danger": isDanger,
    "is-secondary-invert": isSecondaryInvert,
    "has-no-borders": noBorders,
    "has-size-max": sizeMax,
    "is-textLink": textLink,
    "is-in-form": isInForm,
    "is-in-menu": isInMenu,
    "is-icon-ghost": isIconGhost,
  });

  return (
    <div
      className={`${classNameWrapper} ${
        wrapperClassName ? wrapperClassName : ""
      }`}
      data-for={tooltipData?.dataFor}
      data-tip={tooltipData?.dataTip}
    >
      <button
        data-testid={`${dataTestId}-btn`}
        className={`form-button ${className}`}
        type={type}
        title={title}
        disabled={disabled}
        onClick={(e) => onClick(e)}
      >
        {leftIcon ? (
          <i className={`btn-left-icon seem-icon ${leftIcon}`} />
        ) : (
          ""
        )}
        {isIcon ? (
          <i id={`btn-seem-icon`} className={`seem-icon ${isIcon}`} />
        ) : (
          t(label)
        )}
        {rightIcon ? (
          <i className={`btn-right-icon seem-icon ${rightIcon}`} />
        ) : (
          ""
        )}
      </button>
    </div>
  );
};

Button.defaultProps = {
  className: "",
  wrapperClassName: "",
  onClick: () => {},
  type: "button",
  tooltipData: {},
  textLink: false,
  isIconGhost: false,
};

Button.propTypes = {
  "data-testid": PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isIcon: PropTypes.string,
  isIconGhost: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  isLarge: PropTypes.bool,
  isSmall: PropTypes.bool,
  isXl: PropTypes.bool,
  isXs: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isClean: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDanger: PropTypes.bool,
  darkStyle: PropTypes.bool,
  type: PropTypes.string,
  isInvert: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  noBorders: PropTypes.bool,
  sizeMax: PropTypes.bool,
  textLink: PropTypes.bool,
  isInForm: PropTypes.bool,
  isInMenu: PropTypes.bool,
};

export default Button;
