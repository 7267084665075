import { aggregatedStatus, resourceTypes } from "../FindingsUtils";
import { getIconByType } from "../../Components/Tree/TreeUtils";

export const DEFAULT_FILTER = "ALL_FILTERS";
export const DEFAULT_SCOPE = "ALL_SCOPES";
export const SYSTEM = "seemplicity";
export const DEFAULT_SCOPE_NAME = "All Resources";
export const DEFAULT_FILTER_NAME = "Open Findings";
export const LS_FILTER = "findings-filter";
export const LS_SCOPE = "findings-scope";
export const LS_RECENT_FILTERS = "recent-filters";
export const RECENT_LIST_SIZE = 10;

export const getAllGroups = (node, groupId, acc = []) => {
  if (!node) {
    return [];
  }

  if (node.type === resourceTypes.GROUP && node.id !== groupId) {
    acc.push({
      label: node.name,
      value: node.id,
      icon: getIconByType(node.type),
    });
  }

  if (node?.children?.length) {
    for (let i = 0; i < node?.children?.length; i++) {
      getAllGroups(node.children[i], groupId, acc);
    }
  }

  return acc;
};

export const processFilter = (filter, obj = []) => {
  let lastStatus = aggregatedStatus.OPEN;
  if (filter?.operator === "or") {
    processFilter(filter?.operands?.[0], obj);
    processFilter(filter?.operands?.[1], obj);
  } else if (
    filter?.operator === "and" &&
    filter?.operands?.[0]?.field === "actual_status"
  ) {
    lastStatus = filter?.operands?.[0]?.value.value;
    processFilter(filter?.operands?.[1], obj);
  } else if (filter?.operator) {
    obj.push({ conditions: filter?.operands });
  } else {
    if (!!filter) {
      obj.push({ conditions: [filter] });
    } else {
      obj.push({});
    }
  }

  return { filters: obj, lastStatus };
};
