import React, { useContext, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ADD_COMMENT } from "../CommentsApi";
import { GET_FINDING_COMMENTS_COUNT_FRAGMENT } from "../../DetailsPaneApi";
import Button from "../../../../Forms/Controls/Button/Button";
import TextEditor, {
  convertHtmlToPlainText,
} from "../../../../Components/TextEditor/TextEditor";
import "./NewComment.scss";
import { DetailsPaneContext } from "../../DetailsPaneProvider";
import { AuthContext } from "../../../../Auth/AuthContext";
import {
  getTicketManagerFriendlyName,
  ticketManagersEnum,
  ticketStatus as ticketStatusEnum,
} from "../../../../Ticketing/TicketingUtils";
import Avatar from "../../../../Components/Avatar/Avatar";
import CheckBox from "../../../../Forms/Controls/CheckBox/CheckBox";
import { showErrorDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";

const NewComment = () => {
  const { t } = useTranslation();

  const methods = useForm({ defaultValues: { comment: "" } });
  const {
    detailsPaneData: { id, findingTicket, comments },
  } = useContext(DetailsPaneContext);
  const {
    authState: { username },
  } = useContext(AuthContext);
  const [addComment, { loading: isSavingComment }] = useMutation(ADD_COMMENT, {
    update(cache) {
      cache.writeFragment({
        id: `Finding:${id}`,
        fragment: GET_FINDING_COMMENTS_COUNT_FRAGMENT,
        data: {
          comments: {
            total_count: comments.total_count + 1,
          },
        },
      });
    },
    refetchQueries: ["get_comments", "get_comments_from_ticket_provider"],
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isDirty, errors },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ comment: "" });
    }
  }, [isSubmitSuccessful]);

  const onSubmit = (data) => {
    const mutationData = {
      finding_id: id,
      body: data?.comment,
      body_content: convertHtmlToPlainText(data?.comment),
    };

    if (data?.pushCommentToTicketProvider) {
      mutationData.ticket_id = findingTicket?.id;
    }

    addComment({ variables: mutationData }).catch((error) =>
      showErrorDialog({ message: error.message })
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        className={`add-comment-form-wrapper`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={`add-comment-form-controls`}>
          <Avatar name={username} />
          <Controller
            name="comment"
            render={({ field: { onChange, value, onBlur } }) => (
              <TextEditor
                data-testid={"add-comment"}
                wrapperClassName={"text-editor-area"}
                onChange={(data) => onChange(data)}
                placeholder={t("Add a comment...")}
                value={value || ""}
                name={"comment"}
                size="max"
                error={errors?.comment}
                onBlur={onBlur}
              />
            )}
            defaultValue={""}
            rules={{ required: "Required" }}
            control={control}
          />
        </div>

        <div
          className={"form-button-group align-end details-pane-comment-btns"}
        >
          {((findingTicket?.external_id &&
            findingTicket?.ticket_provider?.type === ticketManagersEnum.JIRA) ||
            findingTicket?.ticket_provider?.type ===
              ticketManagersEnum.SERVICENOW) &&
            findingTicket?.status?.toLowerCase() !==
              ticketStatusEnum.DELETED.text?.toLowerCase() && (
              <Controller
                name="pushCommentToTicketProvider"
                render={({ field: { onChange, value } }) => (
                  <CheckBox
                    checked={value}
                    label={`Push comment to ${getTicketManagerFriendlyName(
                      findingTicket?.ticket_provider?.type
                    )}`}
                    className={"push-comments-to-jira-checkbox"}
                    onChange={(val, newValue) => {
                      onChange(newValue);
                    }}
                  />
                )}
                defaultValue={""}
                control={control}
              />
            )}
          <Button
            wrapperClassName={`cancel-btn`}
            label={t("Cancel")}
            type={"button"}
            disabled={!isDirty}
            onClick={() => {
              reset({ comment: "" });
            }}
          />
          <Button
            data-testid={"save-comment"}
            wrapperClassName={`add-comment-btn`}
            isSecondary
            isSubmitting={isSavingComment}
            label={t("Save")}
            type={"submit"}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default NewComment;
