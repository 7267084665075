export const userActions = {
  CNC_CREATE: "cnc_create",
  CREATE: "create",
  EDIT: "edit",
};

export const roleOptions = [
  { label: "Admin", value: btoa("Role:admin") },
  { label: "Editor", value: btoa("Role:editor") },
];
