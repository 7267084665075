import React from "react";
import { useTranslation } from "react-i18next";
import "./Badge.scss";

const Badge = ({ text, icon, withLabel, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`badge-container`} title={text}>
      <div className={`badge-icon-container ${className}`}>
        <i className={`seem-icon seem-icon-${icon}`} />
      </div>
      {withLabel && <span className={`badge-text`}>{t(text)}</span>}
    </div>
  );
};

export default Badge;
