import { capitalize } from "lodash";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import TextInput from "../../Forms/Controls/TextInput/TextInput";
import CheckBox from "../../Forms/Controls/CheckBox/CheckBox";
import React from "react";

const SLAFieldRow = ({
  namePrefix,
  preference_name,
  rowKey,
  index,
  control,
  checkGroup,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const enabledTimeToRespond = useWatch({
    control,
    name: `${namePrefix}.enabled`,
  });

  const enabledPreferences = useWatch({
    control,
    name: `sla_group_enabled`,
  });

  return (
    <div className={`sla-item-row`} key={rowKey}>
      <div className={`sla-field-wrap sla-preference-name`}>
        <i
          className={`sla-field-indicator sla-field-indicator-${preference_name?.toLowerCase()}`}
        />
        <span className={`sla-field-text`}>{`${capitalize(
          preference_name
        )}`}</span>
      </div>

      <Controller
        name={`${namePrefix}.time_to_respond`}
        render={({ field: { onChange, value, onBlur, ref } }) => (
          <TextInput
            data-testid={`${namePrefix}.time_to_respond`}
            onChange={(e) => {
              onChange(e);
            }}
            inputRef={ref}
            onBlur={onBlur}
            value={value}
            error={errors?.slaPreferences?.[index]?.time_to_respond || null}
            min={1}
            defaultValue={1}
            type={"number"}
            size="custom"
            className="sla-preference-expected"
            inputStyle={"bomba-style"}
            disabled={!enabledTimeToRespond || !enabledPreferences}
            readOnly={!enabledTimeToRespond || !enabledPreferences}
          />
        )}
        defaultValue={1}
        control={control}
        rules={{
          required: "Required",
          pattern: {
            value: /^[1-9][0-9]*$/,
            message: "Invalid Time to Respond",
          },
        }}
      />
      <Controller
        name={`${namePrefix}.enabled`}
        render={({ field: { onChange, value, onBlur } }) => (
          <CheckBox
            data-testid={`${namePrefix}.enabled`}
            checked={value}
            value={value}
            name={`${namePrefix}.enabled`}
            isToggle
            className={"sla-enabled-toggle"}
            onChange={(val, newValue) => {
              onChange(newValue);
              checkGroup(preference_name, newValue);
            }}
          />
        )}
        defaultValue={true}
        control={control}
      />
    </div>
  );
};

export default React.memo(SLAFieldRow);
