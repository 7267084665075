import React, { useContext } from "react";
import "./IntegrationDetailsSummary.scss";
import moment from "moment";

const IntegrationDetailsSummary = ({ IntegrationContext }) => {
  const { currentIntegration } = useContext(IntegrationContext);
  const { imageShort, name, lastSynced } = currentIntegration;

  return (
    <div className={"details-summary-wrapper"}>
      <div className={"integration-logo"}>
        <img src={imageShort} alt={name} />
      </div>
      <div className={"details-summary-information"}>
        <div className={"integration-name"}>{name}</div>
        <div className={"integration-last-sync"}>
          {lastSynced
            ? `Collected ${moment(lastSynced)?.format("MMM Do YYYY, hh:mm a")}`
            : ""}
        </div>
      </div>
    </div>
  );
};

export default IntegrationDetailsSummary;
