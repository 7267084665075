import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_DASHBOARD } from "./DashboardApi";

const useSummary = ({ variables, skip = false }) => {
  const [summaryData, setSummaryData] = useState(null);

  const {
    data,
    error: summaryDataError,
    loading: summaryLoading,
  } = useQuery(GET_DASHBOARD, { variables: { ...variables }, skip: skip });

  useEffect(() => {
    if (!!data && !summaryLoading) {
      let parsedData = JSON.parse(data?.summary);
      setSummaryData(parsedData);
    }
  }, [data, summaryLoading]);

  return { summaryData, summaryDataError, summaryLoading };
};

export default useSummary;
