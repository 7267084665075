import Tabs from "../../../../Components/Tabs/Tabs";
import React, { useContext, useMemo, useState } from "react";
import "./FiltersMenuPopup.scss";
import { FiltersContext } from "../../FilterProvider/FilterProvider";
import { LS_FILTER } from "../../../../Findings/FindingsFilters/FilterUtils";
import { findingsQueryParams } from "../../../../Findings/FindingsUtils";
import useFindingsQueryParam from "../../../../Findings/FindingsData/useFindingsQueryParam";
import { GeneralAppContext } from "../../../../General/GeneralAppProvider";
import { showDialog } from "../../../../Forms/Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "@apollo/client";
import { DELETE_FILTER } from "../../../../Findings/FindingsFilters/FindingsFilterApi";
import { showToast } from "../../../../Forms/Dialogs/Toast/Toast";
import useRecentFilters from "../../useRecentFilters";
import noResultsImg from "../../../../../src/icons/images/no-saved-filters.svg";
import Loader from "../../../../Components/Loader/Loader";
import { capitalize } from "lodash";
import { filterTabs } from "../../../AppViewUtils";
import Button from "../../../../Forms/Controls/Button/Button";

const FiltersMenuPopup = ({ toggleIsOpen, setDefaultsForScopeAndFilter }) => {
  const {
    filtersState: { filtersList, selectedFilter, filtersMenuTab },
    updateFiltersState,
  } = useContext(FiltersContext);

  const { recentFilters } = useRecentFilters();

  const [deleteFilter] = useMutation(DELETE_FILTER, {
    refetchQueries: ["filters"],
  });

  const [selectedTab, setSelectedTab] = useState(filtersMenuTab);

  const { clearQueryParam, search } = useFindingsQueryParam(null);

  const {
    appContext: { resetFindingsList },
  } = useContext(GeneralAppContext);

  const removeFilter = (id) => {
    deleteFilter({ variables: { id: id } })
      .then((res) => {
        if (res?.errors) {
          showDialog({
            title: "Error",
            type: "error",
            message: res?.errors.message,
            buttons: [
              {
                isSecondary: true,
                label: "Close",
              },
            ],
          });
          return;
        }
        let newState = {};

        newState = {
          filterToExecute: null,
          selectedFilter: null,
        };
        localStorage.removeItem(LS_FILTER);

        updateFiltersState(newState);

        if (resetFindingsList) {
          resetFindingsList();
        }
        showToast({ message: "Filter Deleted!" });
      })
      .catch((err) => {
        showDialog({
          title: "Error",
          type: "error",
          message: err.message,
          buttons: [
            {
              isSecondary: true,
              label: "Close",
            },
          ],
        });
      });
  };

  const menuItems = useMemo(() => {
    return [
      {
        pageLink: filterTabs.SAVED,
        title: "Saved",
      },
      {
        pageLink: filterTabs.RECOMMENDED,
        title: "Recommended",
      },
      // {
      //   pageLink: filterTabs.RECENT,
      //   title: "Recent",
      // },
    ];
  }, []);

  const onSelectTab = (item) => {
    updateFiltersState({ filtersMenuTab: item?.pageLink });
    setSelectedTab(item.pageLink);
  };

  const filters = useMemo(() => {
    switch (selectedTab) {
      case filterTabs.SAVED: {
        return filtersList
          ?.filter((filter) => !filter?.meta?.system)
          ?.map((filter) => {
            return { ...filter, type: filterTabs.SAVED };
          })
          .sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
      }

      case filterTabs.RECENT: {
        return recentFilters?.map((filterData, index) => {
          return {
            label: index, //TODO: set name
            value: {
              query: filterData,
            },
          };
        });
      }

      case filterTabs.RECOMMENDED: {
        return filtersList
          ?.filter((filter) => filter?.meta?.system)
          .map((filter) => {
            return {
              ...filter,
              type: filterTabs.RECOMMENDED,
            };
          })
          .sort((a, b) => {
            return (
              b?.meta?.default - a?.meta?.default ||
              b?.meta?.popular - a?.meta?.popular ||
              a?.label.localeCompare(b?.label)
            );
          });
      }

      default:
        return [];
    }
  }, [filtersList, selectedTab]);

  const onSelectFilter = (selectedOption) => {
    toggleIsOpen(false);

    if (!!search) {
      clearQueryParam([
        findingsQueryParams.FILTER_ID,
        findingsQueryParams.GROUP_VALUE,
        findingsQueryParams.FILTER_TO_EXECUTE,
      ]);
    }
    if (setDefaultsForScopeAndFilter) {
      localStorage.setItem(LS_FILTER, JSON.stringify(selectedOption));
    }

    updateFiltersState({
      filterToExecute: selectedOption.value.query,
      selectedFilter: selectedOption,
    });
    if (resetFindingsList) {
      resetFindingsList();
    }
  };

  const emptyStateSubtitle = useMemo(() => {
    switch (selectedTab) {
      case filterTabs.SAVED: {
        return "Try to create filters and save them";
      }

      case filterTabs.RECENT: {
        return "Try to use filters";
      }

      default:
        return "";
    }
  }, [selectedTab]);

  return (
    <div className={`filters-menu-popup-wrapper`}>
      <div className={`filters-menu-title`}>Filter By</div>
      <Tabs
        menuItems={menuItems}
        onSelectTab={onSelectTab}
        activeId={selectedTab}
      />
      <div className={`filters-list-container`} data-testid="filter-list-table">
        {filters?.length ? (
          filters?.map((filter, index) => {
            return (
              <div
                data-testid={`filter-menu-row-${index}`}
                className={`filter-menu-row ${
                  selectedFilter?.value?.id === filter?.value?.id
                    ? "is-active"
                    : ""
                }`}
                onClick={() => onSelectFilter(filter)}
              >
                <div className={`filter-name-wrapper`}>
                  <i className={`seem-icon seem-icon-filter-${selectedTab}`} />
                  <div className={`filter-name`}>{filter.label}</div>
                  {filter?.meta?.popular && (
                    <div className={`popular-badge`}>POPULAR</div>
                  )}
                </div>
                {!filter?.meta?.system && (
                  <Button
                    data-testid={"delete-filter-item-btn"}
                    type="button"
                    isIcon="seem-icon-bin"
                    isClean
                    title={"Delete item"}
                    onClick={(e) => {
                      e.stopPropagation();
                      showDialog({
                        title: "Delete Filter?",
                        message: "Are you sure you wish to delete this filter?",
                        buttons: [
                          {
                            "data-testid": "cancel-delete",
                            label: "Cancel",
                          },
                          {
                            "data-testid": "confirm-delete",
                            isSecondary: true,
                            label: "Delete",
                            // type: "submit",
                            onClick: () => {
                              removeFilter(filter?.value?.id);
                            },
                          },
                        ],
                      });
                    }}
                  />
                )}
              </div>
            );
          })
        ) : (
          <Loader
            flexcenter
            img={noResultsImg}
            title={`No ${capitalize(selectedTab)} Filters`}
            subtitle={emptyStateSubtitle}
          />
        )}
      </div>
    </div>
  );
};

export default FiltersMenuPopup;
