import "./ErrorIndicator.scss";
const ErrorIndicator = ({ description }) => {
  return (
    <div className={`error-indicator-wrapper`}>
      <div className={`error-indicator-offset`} />
      <div className={`error-indication-data`}>
        <i className={`seem-icon seem-icon-warning error-indicator-icon`} />
        <span className={`error-description`}>{description}</span>
      </div>
    </div>
  );
};

export default ErrorIndicator;
