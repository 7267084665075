import React, { useCallback, useEffect, useMemo, useState } from "react";
import ClassNames from "classnames";
import "./TreeNode.scss";
import Highlighter from "react-highlight-words";
import { getIconByType, TristateStateEnum } from "../TreeUtils";
import { resourceTypes } from "../../../Findings/FindingsUtils";
import CheckBox from "../../../Forms/Controls/CheckBox/CheckBox";

const TreeNode = ({
  name,
  id,
  type,
  children,
  level,
  nodeData,
  nodeOperations,
  searchValue,
  parentId,
  isSelectableRoot,
  "data-testid": dataTestId,
  editableNodes,
  selectedNode,
  className,
  isLastChild,
  withCheckbox,
  checkedNodes,
  initialExpandLevel,
  expandedNodes,
}) => {
  const { onEdit, onSelect } = nodeOperations;
  const isExpandable = children?.length > 0;

  const getExpanded = useMemo(() => {
    if (searchValue) {
      return nodeData.expanded || true;
    } else if (expandedNodes?.includes(id)) {
      return true;
    } else if (initialExpandLevel) {
      return level < initialExpandLevel;
    } else {
      return true;
    }
  }, [initialExpandLevel, level, nodeData.expanded, searchValue]);

  const [expand, toggleExpand] = useState(getExpanded);

  const isNodeChecked = useCallback(
    (id) => {
      return (
        checkedNodes.find(
          (node) => node.id === id && node.checked === TristateStateEnum.CHECKED
        ) !== undefined
      );
    },
    [checkedNodes]
  );

  const isNodeIndeterminate = useCallback(
    (id) => {
      return (
        checkedNodes.find(
          (node) =>
            node.id === id && node.checked === TristateStateEnum.INDETERMINATE
        ) !== undefined
      );
    },
    [checkedNodes]
  );

  const hasWarning = useCallback(
    (id) => {
      if (nodeOperations.onWarning(id)) {
        nodeData.hasWarning = true;
        return true;
      }
    },
    [checkedNodes]
  );

  const classNameWrapper = ClassNames({
    "is-expandable": isExpandable,
    "is-expanded": expand,
    "is-collapsed": !expand,
  });

  useEffect(() => {
    toggleExpand(getExpanded);
  }, [searchValue]);

  const renderChildren = () => {
    if (isExpandable && expand) {
      return children.map((node, index) => {
        return (
          <TreeNode
            {...node}
            nodeData={node}
            parentId={node.parentId}
            nodeOperations={nodeOperations}
            level={level + 1}
            searchValue={searchValue}
            isSelectableRoot={isSelectableRoot}
            withCheckbox={withCheckbox}
            data-testid={`tree-node-${node.type}`}
            selectedNode={selectedNode}
            editableNodes={editableNodes}
            checkedNodes={checkedNodes}
            initialExpandLevel={initialExpandLevel}
            className={
              index === children?.length - 1 ? "node-branch-border-last" : ""
            }
            isLastChild={index === children?.length - 1}
            expandedNodes={expandedNodes}
          />
        );
      });
    }
  };

  const selectNode = () => {
    if (
      ((isSelectableRoot && type === resourceTypes.ROOT) ||
        type !== resourceTypes.ROOT) &&
      !!onSelect
    ) {
      onSelect(nodeData);
    }
  };

  return (
    <div
      className={`tree-node-wrapper ${
        selectedNode === id ? "is-selected" : ""
      }`}
      key={id}
      data-haschildren={!!children?.length}
    >
      <div className={`tree-node-wrapper-data-${level}`}>
        <div
          className={`tree-node tree-node-level-${level} ${classNameWrapper} ${
            selectedNode === id ? "is-selected" : ""
          }`}
        >
          {withCheckbox &&
            ((isSelectableRoot && type === resourceTypes.ROOT) ||
              type !== resourceTypes.ROOT) && (
              <CheckBox
                data-testid={`tree-node-${id}`}
                value={nodeData}
                checked={isNodeChecked(id)}
                indeterminate={isNodeIndeterminate(id)}
                name={`tree-node-${id}`}
                onChange={(node) =>
                  nodeOperations.onCheck([node], checkedNodes)
                }
              />
            )}
          <div
            className={`expandable-indicator`}
            title={"Expand group"}
            onClick={() => {
              if (isExpandable) {
                toggleExpand(!expand);
              } else {
                selectNode();
              }
            }}
          >
            {isExpandable ? (
              <i
                className={`seem-icon seem-icon-chevron-down ${
                  !expand ? "is-collapsed" : ""
                }`}
              />
            ) : null}
          </div>

          <div
            className={`tree-node-data ${
              type === resourceTypes.ROOT ? "is-root" : type
            } ${
              isSelectableRoot || type !== resourceTypes.ROOT
                ? "selectable"
                : "not-selectable"
            }`}
            data-testid={dataTestId}
          >
            <div
              className={`tree-node-label-container`}
              onClick={() => {
                if (
                  withCheckbox &&
                  ((isSelectableRoot && type === resourceTypes.ROOT) ||
                    type !== resourceTypes.ROOT)
                ) {
                  nodeOperations.onCheck([nodeData], checkedNodes);
                } else {
                  selectNode();
                }
              }}
            >
              {(!withCheckbox ||
                (!isSelectableRoot && type === resourceTypes.ROOT)) && (
                <i
                  className={`tree-node-icon seem-icon ${getIconByType(type)}`}
                />
              )}
              <div className={`tree-node-label`}>
                <Highlighter
                  highlightClassName="highlight-text-wrap"
                  searchWords={[searchValue]}
                  autoEscape={true}
                  textToHighlight={`${name}`}
                />
              </div>
              {withCheckbox && hasWarning(id) && (
                <i
                  className={`seem-icon seem-icon-warning node-warning-icon`}
                />
              )}
            </div>
            {editableNodes && nodeData?.editable && (
              <div
                className={`tree-node-edit`}
                datatest-id="edit-btn"
                onClick={() => onEdit(nodeData)}
              >
                <i
                  className={`seem-icon seem-icon-edit filter-panel-option-edit`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {expand && <div className="tree-node-children">{renderChildren()}</div>}
    </div>
  );
};

export default TreeNode;
