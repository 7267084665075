import Popover from "react-popover";
import React, { useEffect, useMemo, useState } from "react";
import { getRowOptions } from "../../../../../Findings/FindingsFilters/FilterRow/FilterRowUtils";
import "./FilterField.scss";
import ClassNames from "classnames";
import Button from "../../../../../Forms/Controls/Button/Button";
import { filterTypes } from "../../../../../Findings/FindingsUtils";

const FilterField = ({
  value,
  onChange,
  fieldsData,
  isEditable,
  removeFilter,
  itemIndex,
  selectedFields,
  setMenuOpen,
  context,
}) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const fieldOptions = useMemo(
    () => getRowOptions(fieldsData, "field"),
    [fieldsData]
  );

  const fieldOptionsFormatted = useMemo(() => {
    if (context === filterTypes.SCOPE) {
      return fieldOptions;
    }
    return fieldOptions?.filter((el) => !selectedFields?.includes(el.value));
  }, [fieldOptions, selectedFields]);

  const fieldData = useMemo(() => {
    if (value === "actual_status") {
      return {
        label: "Status",
        value: "actual_status",
      };
    }
    return (
      fieldOptions?.filter(
        (selectedField) => selectedField.value === value
      )?.[0] || null
    );
  }, [fieldOptions, value]);

  useEffect(() => {
    if (!value) {
      toggleIsOpen(true);
    }
  }, [value]);

  const classNameWrapper = ClassNames("field-selected-value", {
    "is-empty": !value,
    "is-active": isOpen,
  });

  const popoverTitle = useMemo(() => {
    return (
      <div
        className={`selected-filter-value`}
        title={fieldData?.label || "Choose Field"}
      >
        <div className={`selected-value-title`} data-testid="filter-field">
          {fieldData?.label || "Choose Field"}
        </div>
        {!value && (
          <Button
            wrapperClassName={`remove-filter-icon`}
            data-testid={"remove-filter-btn"}
            type="button"
            isIcon="seem-icon-close"
            isClean
            isXs
            title={"Remove condition"}
            onClick={() => {
              removeFilter(itemIndex);
            }}
          />
        )}
      </div>
    );
  }, [fieldData?.label, value]);

  useEffect(() => {
    if (!!setMenuOpen) {
      setMenuOpen({ field: isOpen });
    }
  }, [isOpen]);

  return (
    <Popover
      isOpen={isOpen}
      tipSize={0.01}
      place={"below"}
      body={[
        <div className={`filter-dropdown-wrap`}>
          {fieldOptionsFormatted?.map((field) => (
            <div
              className={"filter-dropdown-item-wrap"}
              onClick={(e) => {
                onChange(field);
                toggleIsOpen(false);
                e.stopPropagation();
              }}
              title={field.label}
            >
              <div className="filter-dropdown-item">{field.label}</div>
            </div>
          ))}
        </div>,
      ]}
      enterExitTransitionDurationMs={0}
      onOuterAction={() => setTimeout(() => toggleIsOpen(false), 200)}
    >
      <div
        className={`${classNameWrapper} field-selected-value ${
          !value ? "is-empty" : ""
        } ${
          !isEditable || fieldData?.value === "actual_status"
            ? "is-disabled"
            : ""
        } ${isOpen ? "is-active" : ""}`}
        onClick={() => {
          if (isEditable && fieldData?.value !== "actual_status") {
            toggleIsOpen(!isOpen);
          }
        }}
      >
        {popoverTitle}
      </div>
    </Popover>
  );
};

export default FilterField;
