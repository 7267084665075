import React, { useMemo } from "react";
import ActivityItem, {
  activityAttributes,
  activityEntities,
} from "./ActivityItem/ActivityItem";
import "./QueueActivity.scss";
import moment from "moment";
import Spinner from "../../../../images/loader.svg";
import Loader from "../../../../Components/Loader/Loader";
import ActivityHeader from "./ActivityHeader/ActivityHeader";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ACTIVITY } from "./QueueActivityApi";

const QueueActivity = () => {
  let { queueid } = useParams();

  const { loading, data, error } = useQuery(GET_ACTIVITY, {
    variables: { id: queueid },
    skip: !queueid,
  });

  const getActivityData = (data) => {
    return {
      finding: data?.finding?.id_int,
      findingId: data?.finding?.id,
      ticketId: data?.ticket?.external_id,
      time: moment.utc(data?.audit_record?.timestamp)?.fromNow(),
      entity_type: data?.audit_record?.entity_type,
      attribute: data?.audit_record?.attribute,
      old_value: data?.audit_record?.old_value,
      new_value: data?.audit_record?.new_value,
      assignee: data?.ticket?.assignee,
    };
  };

  const activityState = useMemo(() => {
    return loading
      ? "loading"
      : !data?.remediation_queue?.activity_log?.length
      ? "no-data"
      : "";
  }, [loading, data, queueid]);

  return (
    <div className={"remediation-queue-container remediation-queue-activity"}>
      <ActivityHeader />

      <div className={`remediation-queue-activity-list ${activityState}`}>
        {loading ? (
          <Loader center img={Spinner} />
        ) : error ? (
          <Loader center text={"An error occurred."} />
        ) : !data?.remediation_queue?.activity_log?.length ? (
          <Loader center text={"No Activities Yet"} />
        ) : (
          data?.remediation_queue?.activity_log?.map((activity) => {
            /* remove "new finding" audits */
            if (
              activity?.audit_record?.attribute === activityAttributes.STATUS &&
              activity?.audit_record?.entity_type ===
                activityEntities.FINDING &&
              !activity?.audit_record?.old_value
            ) {
              return null;
            }
            return (
              <ActivityItem
                key={activity?.finding?.id}
                activityData={getActivityData(activity)}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default QueueActivity;
