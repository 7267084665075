import SAMLSetup from "./SAMLSetup/SAMLSetup";
import SAMLInstructions from "./SAMLInstructions/SAMLInstructions";
import SAMLResources from "./SAMLResources/SAMLResources";
import "./SAMLSettings.scss";

const SAMLSettings = () => {
  return (
    <div className={"saml-settings-wrapper"}>
      <div className={"saml-setup"}>
        <SAMLSetup />
      </div>
      <div className={"saml-right-side"}>
        <SAMLInstructions />
        <SAMLResources />
      </div>
    </div>
  );
};

export default SAMLSettings;
