import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactFlow, {
  Controls,
  MiniMap,
  useZoomPanHelper,
} from "react-flow-renderer";
import "./Workflows.scss";
import workflowsBg from "../../images/workflows_bg_pattern.svg";
import RMQNode from "./RMQNode/RMQNode";
import MarkerDefinition from "./Marker/MarkerDefinition";
import Header from "./Header/Header";
import { useQuery } from "@apollo/client";
import { GET_REMEDIATION_QUEUES } from "../../RemediationQueues/RemediationQueueApi";
import {
  DEFAULT_FILTER,
  DEFAULT_FILTER_NAME,
  DEFAULT_SCOPE,
  DEFAULT_SCOPE_NAME,
  SYSTEM,
} from "../../Findings/FindingsFilters/FilterUtils";
import RMQEdge from "./RMQEdge/RMQEdge";
import ELK from "elkjs/lib/elk.bundled";
import { useEvent } from "react-use";
import { ticketManagersEnum } from "../../Ticketing/TicketingUtils";
import { uniqBy, difference } from "lodash";
import Loader from "../../Components/Loader/Loader";
import Spinner from "../../images/loader.svg";

export const sourceTypes = {
  DONE: "DONE",
  REJECTED: "REJECTED",
  FIXED: "FIXED",
  ADD: "ADD",
  FALSE_POSITIVE: "FALSE_POSITIVE",
  EXCEPTION: "EXCEPTION",
  OPEN_CONFIRMED: "OPEN_CONFIRMED",
};

export const edgeSourceTypes = {
  DONE: { value: "DONE", text: "Done" },
  REJECTED: { value: "REJECTED", text: "Rejected" },
  FIXED: { value: "FIXED", text: "Fixed" },
  ADD: { value: "ADD", text: "Add" },
  FALSE_POSITIVE: {
    value: "FALSE_POSITIVE",
    text: "False Positive",
    icon: "false-positive",
  },
  EXCEPTION: {
    value: "EXCEPTION",
    text: "Exception",
    icon: "risk-accepted",
  },
  OPEN_CONFIRMED: {
    value: "OPEN_CONFIRMED",
    text: "Reviewed",
    icon: "open-confirmed",
  },
};

const edgeTypes = {
  rmqedge: RMQEdge,
};

const NODE_WIDTH = 282;
const NODE_HEIGHT = 141;
const MENU_ITEMS = [
  sourceTypes.FALSE_POSITIVE,
  sourceTypes.EXCEPTION,
  sourceTypes.OPEN_CONFIRMED,
];

const Workflows = () => {
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState(null);
  const [data, setData] = useState(null);
  const [elkProcessor, setElkProcessor] = useState(null);
  const [isLoadingView, setIsLoadingView] = useState(true);

  const { transform } = useZoomPanHelper();

  const { data: remediationQueues } = useQuery(GET_REMEDIATION_QUEUES, {
    variables: { with_transitions: true },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    const elk = new ELK();
    setElkProcessor(elk);
  }, []);

  const getPosition = (node) => {
    const savedNodes = JSON?.parse(localStorage.getItem("flow"))?.nodes || {};
    if (savedNodes?.[node.id]) {
      return savedNodes[node.id].position;
    } else {
      return {
        x: node.x - NODE_WIDTH / 2 + Math.random() / 1000,
        y: node.y - NODE_HEIGHT / 2,
      };
    }
  };

  const setTransform = () => {
    const transformData =
      JSON?.parse(localStorage.getItem("flow"))?.transform || {};
    transform(transformData);
  };

  useEffect(() => {
    if (!!remediationQueues) {
      const listItems =
        remediationQueues?.remediation_queues?.edges?.map((data) => {
          const item = data.node;
          const findingScope = item?.finding_scope;
          let findingScopeName = item?.finding_scope?.name;

          if (!findingScope) {
            findingScopeName = item?.finding_scope_group?.name;
          } else {
            if (
              findingScope?.name === DEFAULT_SCOPE &&
              findingScope?.created_by === SYSTEM
            ) {
              findingScopeName = DEFAULT_SCOPE_NAME;
            }
          }

          const findingFilter = item?.finding_filter;
          let findingFilterName = item?.finding_filter?.name;
          if (
            findingFilter?.name === DEFAULT_FILTER &&
            findingFilter?.created_by === SYSTEM
          ) {
            findingFilterName = DEFAULT_FILTER_NAME;
          }

          return {
            ...item,
            filter: findingFilterName?.split(/\#\_/)?.[0] || findingFilterName,
            scope: findingScopeName,
          };
        }) || [];

      setData(listItems);
    }
  }, [remediationQueues]);

  const createLayoutFromData = () => {
    if (!!data) {
      const nodes = [];
      const edges = [];

      data?.forEach((rmq) => {
        const transitions = rmq?.transitions?.filter(
          (edge) => edge?.source !== sourceTypes.FIXED
        );

        nodes.push({
          id: rmq.id,
          type: "rmq", // input node
          data: {
            ...rmq,
            transitions: transitions,
          },
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        });

        transitions
          ?.filter((edge) => edge?.source !== sourceTypes.FIXED)
          ?.forEach((edge) => {
            edges.push({
              id: `e-${rmq.id}-${edge.source}-${edge.target}`,
              source: `${rmq.id}`,
              sourceHandle: `${edge.source}`,
              target: edge.target,
              className: `edge ${edge.source?.toLowerCase()}`,
              arrowHeadType: `${edge.source}`,
              type: "rmqedge",
              data: {
                type: `${edge.source}`,
                endpoint_type: rmq?.ticket_endpoint?.type,
              },
            });
          });

        const transitionTypes =
          uniqBy(transitions, "source")?.map((item) => item.source) || [];

        if (
          rmq?.ticket_endpoint?.ticket_provider?.type ===
            ticketManagersEnum.SEEMPLICITY &&
          !!difference(MENU_ITEMS, transitionTypes)?.length
        ) {
          // used for displaying the "add new queue" button
          edges.push({
            id: `e-${rmq.id}-add-queue`,
            source: `${rmq.id}`,
            sourceHandle: `add-queue-source`,
            target: `${rmq.id}`,
            targetHandle: `add-queue-target`,
            type: "rmqedge",
            data: {
              type: `ADD`,
              finding_filter: rmq.finding_filter,
              scope_id: rmq.scope_id,
              filter: rmq.filter,
              endpoint_type: rmq?.ticket_endpoint?.type,
              transitionTypes: transitionTypes,
            },
          });
        }
      });

      elkProcessor
        ?.layout(
          {
            id: "root",
            children: nodes,
            edges: edges,
          },
          {
            layoutOptions: {
              "elk.algorithm": "layered",
              "elk.direction": "RIGHT",
              "elk.alignment": "TOP",
              "elk.layered.spacing.edgeNodeBetweenLayers": "80",
            },
          }
        )
        .then((data) => {
          const newNodes = data?.children?.map((node) => {
            return {
              ...node,
              position: getPosition(node),
            };
          });
          setElements([...newNodes, ...data.edges]);
        });
    }
  };

  const handleResize = () => {
    if (reactFlowInstance) {
      createLayoutFromData();
    }
  };

  useEvent("resize", handleResize, window, { capture: true });

  useEffect(() => {
    if (!isLoadingView) {
      setTransform();
    }
  }, [isLoadingView]);

  useEffect(() => {
    setIsLoadingView(true);
    createLayoutFromData();
  }, [data]);

  useEffect(() => {
    if (reactFlowInstance && elements.length > 0) {
      reactFlowInstance.fitView({ padding: 0, includeHiddenNodes: false });
      setIsLoadingView(false);
    }
  }, [reactFlowInstance, elements]);

  const onLoad = useCallback(
    (rfi) => {
      if (!reactFlowInstance) {
        setReactFlowInstance(rfi);
      }
    },
    [reactFlowInstance]
  );

  const isEmptyPage = useMemo(() => {
    const editableQueues = data?.filter((rmq) => !rmq?.editable);
    return !!data && editableQueues?.length === data?.length;
  }, [data]);

  const onNodeDragStop = (event, node) => {
    const flow = JSON?.parse(localStorage.getItem("flow"));
    const savedNodes = flow?.nodes || {};

    savedNodes[node.id] = {
      position: node.position,
    };

    flow.nodes = savedNodes;

    localStorage.setItem("flow", JSON.stringify(flow));
  };

  const onMoveEnd = (flowTransform) => {
    if (!isLoadingView) {
      const flow = JSON?.parse(localStorage.getItem("flow")) || {};
      flow.transform = flowTransform;
      localStorage.setItem("flow", JSON.stringify(flow));
    }
  };

  return (
    <div
      className={`workflows-container ${isEmptyPage ? "is-empty" : ""}`}
      style={{ backgroundImage: `url(${workflowsBg})` }}
    >
      <Header isEmpty={isEmptyPage} />
      {!!elements?.length ? (
        <ReactFlow
          elements={elements}
          nodeTypes={{ rmq: RMQNode }}
          onLoad={onLoad}
          minZoom={0}
          snapGrid={true}
          edgeTypes={edgeTypes}
          onNodeDragStop={onNodeDragStop}
          nodesDraggable={true}
          onMoveEnd={onMoveEnd}
        >
          <MiniMap />
          <Controls />
          <MarkerDefinition
            id={`react-flow__${sourceTypes.DONE}`}
            color={"#2dbf9f"}
          />
          <MarkerDefinition
            id={`react-flow__${sourceTypes.REJECTED}`}
            color={"#ec2a3f"}
          />
          <MarkerDefinition
            id={`react-flow__${sourceTypes.EXCEPTION}`}
            color={"#008265"}
          />
          <MarkerDefinition
            id={`react-flow__${sourceTypes.FALSE_POSITIVE}`}
            color={"#4e495d"}
          />
          <MarkerDefinition
            id={`react-flow__${sourceTypes.OPEN_CONFIRMED}`}
            color={"#039DBE"}
          />
        </ReactFlow>
      ) : (
        <Loader center img={Spinner} />
      )}
    </div>
  );
};

export default Workflows;
