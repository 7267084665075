import { gql } from "@apollo/client";

export const GET_ACTIVITY = gql`
  query get_activity($id: ID!) {
    remediation_queue(id: $id) {
      id
      activity_log {
        finding {
          id_int
          id
          title
          actual_status {
            category
          }
        }
        ticket {
          id
          assignee
          external_id
          ticket_endpoint {
            field_mapping
          }
          ticket_provider {
            known_identifier
            type
          }
        }
        audit_record {
          timestamp
          entity_type
          attribute
          old_value
          new_value
        }
      }
    }
  }
`;
