import { snakeCase } from "lodash";
import { slaStatuses } from "../../FindingsUtils";
import React from "react";
import "./SLAStatus.scss";
import PropTypes from "prop-types";

const SLAStatus = ({ sla_status, isLarge }) => {
  return !!sla_status ? (
    <>
      <div
        className={`sla-status-icon-container ${
          isLarge ? "is-large" : ""
        } sla-${snakeCase(slaStatuses?.[sla_status]?.title)}`}
      >
        <i className={`seem-icon ${slaStatuses?.[sla_status]?.icon}`} />
      </div>
      <div className="sla-status-title-wrap">
        {slaStatuses?.[sla_status]?.title}
      </div>
    </>
  ) : (
    <></>
  );
};

SLAStatus.defaultProps = {
  isLarge: false,
};

SLAStatus.propTypes = {
  isLarge: PropTypes.bool,
};

export default SLAStatus;
